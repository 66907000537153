import React, { useState, useEffect, useRef } from "react";
import { Button, Drawer, Form, message, Input, Icon, Row, Spin, Checkbox } from "antd";
import { useFormik } from "formik";
import * as Yup from "yup";
import { useTranslation } from "react-i18next";
import DraftText from "../Meetings_Rooms/TextEditor/Draft";
import { EditorState } from "draft-js";
import { stateFromHTML } from "draft-js-import-html";
import { stateToHTML } from "draft-js-export-html";
import { useAppDispatch, useAppSelector } from "../../hooks/reduxHooks";
import { addTask } from "../../features/tasks/tasksSlice";
import moment from "moment";
import { uploadFile } from "../../db/supabase-storage";

interface Props {
  modalState: boolean;
  setModalState: React.Dispatch<React.SetStateAction<boolean>>;
  departmentId: string;
}
const projectSchema = Yup.object().shape({});

function AddNewTask(props: Props) {
  const { currentUser } = useAppSelector((state) => state.auth);
  const dispatch = useAppDispatch();

  const { t } = useTranslation();
  const { modalState, setModalState, departmentId } = props;
  const [loading, setLoading] = useState(false);
  const [uploadTaskFiles, setUploadTaskFiles] = useState([] as any);
  const [editorState, setEditorState] = useState<EditorState>(EditorState.createWithContent(stateFromHTML("")));
  const html = stateToHTML(editorState.getCurrentContent());

  const uid = currentUser.id as string;
  const fileInputRef = useRef(null);
  const handleButtonClick = () => {
    if (fileInputRef.current) {
      fileInputRef.current.click();
    }
  };
  const formik = useFormik({
    initialValues: {
      departmentId: "",
      code: "",
      title: "",
      description: "",
      attached_files: [],
      duration: 0,
      sharable: false,
    },
    validationSchema: projectSchema,
    onSubmit: async (values) => {
      setLoading(true);

      const promise = uploadJobDescription();

      promise.then((files) => {
        try {
          dispatch(
            addTask({
              departmentId: departmentId,
              code: values.code,
              title: values.title,
              description: html,
              attached_files: files ?? [],
              duration: values.duration,
              sharable: values.sharable,
              createdAt: moment() as any,
              by: uid,
            })
          ).then(() => {
            setModalState(false);
            setLoading(false);
            message.success("New Responsibility is added successfully!");
            formik.resetForm();
          });
        } catch (error) {
          setLoading(false);
          console.error("Error writing document: ", error);
          message.error("Failed");
        }
      });
    },
  });
  useEffect(() => {
    return () => {
      if (formik.isValid == false) {
        setLoading(false);
      }
    };
  }, [formik.isValid]);

  const handleFileUpload = async (files: any) => {
    try {
      const downloadURLs = await Promise.all(
        files.map(async (file: any) => {
          const filePath = `departments/${departmentId}/tasks/${file.name}`;
          try {
            const url = await uploadFile(file, filePath);

            if (url) {
              message.success("File uploaded successfully");

              return {
                name: file.name,
                url: url,
              };
            }
          } catch (error: any) {
            message.error(error.message);
          }
        })
      );
      return downloadURLs;
    } catch (error) {
      console.error("Error uploading files:", error);
      return [];
    }
  };

  const uploadJobDescription = async () => {
    setLoading(true);
    const promise = handleFileUpload(uploadTaskFiles);
    return promise;
  };

  const antIcon = <Icon type="loading" style={{ fontSize: 75 }} spin />;
  return (
    <Drawer
      title={t("general.addNewResponsibility")}
      width={700}
      height={350}
      visible={modalState}
      placement={"right"}
      onClose={() => setModalState(false)}
    >
      <Spin spinning={loading} size="large" indicator={antIcon}>
        <Form onSubmit={formik.handleSubmit}>
          <Form.Item label={t("general.code")}>
            <Input value={formik.values.code} onChange={(e) => formik.setFieldValue("code", e.target.value)} />
          </Form.Item>
          <Form.Item label={t("general.title")}>
            <Input value={formik.values.title} onChange={(e) => formik.setFieldValue("title", e.target.value)} />
          </Form.Item>
          <Form.Item label={t("general.description")}>
            <DraftText
              onValueChange={() => {}}
              onMentionChange={() => {}}
              html={""}
              editorState={editorState}
              setEditorState={setEditorState}
            />
          </Form.Item>
          <Form.Item label={t("general.sharable")}>
            <Checkbox
              value={formik.values.sharable}
              onChange={(e) => {
                formik.setFieldValue("sharable", e.target.checked);
              }}
            >
              Is this Responsibility sharable with other departments?
            </Checkbox>
          </Form.Item>
          <Form.Item label="Responsibility Files">
            <Row gutter={12}>
              <Button onClick={() => handleButtonClick()}>
                <Icon type="upload" /> {`Click to upload ${uploadTaskFiles.length}`}
                <input
                  ref={fileInputRef} // Attach the ref to the input
                  hidden
                  type="file"
                  multiple
                  onChange={async (e) => {
                    if (e.target.files) {
                      setUploadTaskFiles([...Object.values(e?.target?.files as any)]);
                    }
                  }}
                />
              </Button>
            </Row>
          </Form.Item>

          <div>
            <Button
              className="btn blue lighten-1 z-depth-0"
              style={{ marginTop: "2%" }}
              type="primary"
              htmlType="submit"
            >
              {t("general.create")}
            </Button>
          </div>
        </Form>
      </Spin>
    </Drawer>
  );
}

export default AddNewTask;
