import React, { useState } from "react";
import { RouteComponentProps } from "react-router-dom";
import { Form, Input, Button, Checkbox, message, Icon } from "antd";
import * as Yup from "yup";
import { useFormik } from "formik";
import logo from "./aiu-logo.png";
import "./style.css";
import { useAppDispatch } from "../../hooks/reduxHooks";
import { signIn } from "../../features/auth/authSlice";

const SignInSchema = Yup.object().shape({
  email: Yup.string().required("Required"),
  password: Yup.string().required("Required").min(6).max(15),
});

// const isValidEmail = (text: string) => {
//   const emailRegex = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;
//   return emailRegex.test(text);
// };

export default function SignIn(s: RouteComponentProps) {
  const [loading, setLoading] = useState(false);
  const dispatch = useAppDispatch();

  const formik = useFormik({
    initialValues: {
      email: "",
      password: "",
    },
    validationSchema: SignInSchema,
    onSubmit: (v) => handleSubmit(v),
  });

  const handleSubmit = (values: { email: string; password: string }) => {
    loginByEmailSupabase(values);
  };

  const loginByEmailSupabase = (values: { email: string; password: string }) => {
    try {
      dispatch(
        signIn({
          email: `${values.email}@ida-org.com`,
          password: values.password,
        })
      ).then((res: any) => {
        if (res.error.message == "Rejected") {
          message.error(res.payload);
        } else {
          message.success("Signed In Successfully!");
        }
        setLoading(false);
      });
    } catch (error: any) {
      var errorMessage = error.message;
      message.error(errorMessage);
      setLoading(false);
    }
  };

  const enterLoading = () => {
    setLoading(true);
  };

  return (
    <div className={"boxForm"}>
      <div
        style={{
          width: "450px",
          marginTop: "6%",
          padding: "2%",
          border: "solid",
          borderWidth: "thin",
          borderColor: "rgb(128 128 128 / 17%)",
          borderRadius: "11px",
          background: "rgb(245 245 245 / 36%)",
          boxShadow: "0px 1px 10px 2px rgba(0, 0, 0, 0.18)",
        }}
      >
        <img style={{ width: "50%", marginBottom: "8%", marginTop: "0%" }} src={logo} />

        <Form labelCol={{ span: 8 }} onSubmit={formik.handleSubmit} className="login-form">
          <Form.Item>
            <Input
              prefix={<Icon type="user" style={{ color: "rgba(0,0,0,.25)" }} />}
              placeholder="Email Address"
              value={formik.values.email}
              onChange={(e) => formik.setFieldValue("email", e.target.value)}
            />
          </Form.Item>
          <Form.Item>
            <Input.Password
              prefix={<Icon type="lock" style={{ color: "rgba(0,0,0,.25)" }} />}
              type="password"
              placeholder="Password"
              value={formik.values.password}
              onChange={(e) => formik.setFieldValue("password", e.target.value)}
            />
          </Form.Item>
          <Form.Item>
            <Checkbox>Remember me</Checkbox>
            <a className="login-form-forgot" href="/forget-password">
              Forgot password
            </a>
            <Button
              type="primary"
              htmlType="submit"
              className="login-form-button"
              style={{ width: "100%" }}
              onClick={enterLoading}
              loading={loading}
              disabled={!formik.values.email || !formik.values.password}
            >
              Log in
            </Button>
          </Form.Item>
        </Form>
        <div style={{ textAlign: "center" }}>
          <b>
            <h2 style={{ color: "grey" }}>Administration Software</h2>
          </b>
          <p style={{ color: "#1890ff" }}>Made by AIU: Omar Azzi</p>
        </div>
      </div>
    </div>
  );
}
