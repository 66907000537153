import { fetchAllDepartmentPositions } from "../features/departmentPositions/departmentPositionsSlice";
import { RootState, store } from "../store";

const initializeDepartmentPositionsData = async () => {
  const state: RootState = store.getState();
  const { loaded } = state.departmentPositions;

  // Only dispatch fetch if the data hasn't been loaded yet
  if (!loaded) {
    await store.dispatch(fetchAllDepartmentPositions());
  }
};

// Immediately call to ensure data is fetched
initializeDepartmentPositionsData();

export const getDepartmentPositionsData = () => {
  const state: RootState = store.getState();
  const { globalDepartmentPositionsData, loaded } = state.departmentPositions;
  // if (!loaded) {
  //   console.warn("Department Positions data is not loaded yet.");
  // }

  return {
    globalDepartmentPositionsData,
  };
};

// Initial export values, populated from the Redux store
export let globalDepartmentPositionsData = getDepartmentPositionsData().globalDepartmentPositionsData;

// Subscribe to store updates and dynamically update exports when the Redux state changes
store.subscribe(() => {
  const { globalDepartmentPositionsData: updatedGlobalDepartmentPositionsData } = getDepartmentPositionsData();

  // Update the exports with the latest values from the Redux store
  globalDepartmentPositionsData = updatedGlobalDepartmentPositionsData;
});
