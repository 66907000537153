import React, { useEffect } from "react";
import { Route, RouteComponentProps } from "react-router";
import { Row, Col } from "antd";
import Services from "./Services";
import { Container } from "@material-ui/core";
import RightBar from "./rightBar";
import MyDepartmentsList from "../orders/myDepartmentsList";
import { useAppDispatch, useAppSelector } from "../../hooks/reduxHooks";
import { fetchDepartmentsByHOD } from "../../features/departments/departmentsSlice";
import { subscribeToOrders } from "../../features/orders/ordersSlice";

export default function Dashboard() {
  const { HODDepartments: departmentsData } = useAppSelector((state) => state.departments);
  const { currentUser } = useAppSelector((state) => state.auth);
  const dispatch = useAppDispatch();

  const uid = currentUser?.id as string;

  const departments = departmentsData;

  useEffect(() => {
    dispatch(fetchDepartmentsByHOD(uid));

    const unsubscribeToOrders = subscribeToOrders(dispatch);
    return () => {
      unsubscribeToOrders();
    };
  }, [dispatch]);

  return (
    <div>
      <Container maxWidth="xl">
        <div>
          <Row gutter={24} style={{ alignItems: "center" }}>
            {departments.length > 0 && (
              <Col span={4}>
                <MyDepartmentsList departments={departments} />
              </Col>
            )}
            <Col span={12} style={{ background: "white", borderRadius: "5px", margin:"1%" }}>
              <Route exact path="/" component={(props: RouteComponentProps<{ id: string }>) => <RightBar />} />
            </Col>
            <Col span={7} style={{ borderRadius: "5px" }}>
              <Services />
            </Col>
          </Row>
        </div>
      </Container>
    </div>
  );
}
