import React, { useState, useEffect } from "react";
import Paper from "@material-ui/core/Paper";
import { Spin, Button, DatePicker, Select } from "antd";
const { Option } = Select;
import { useHistory } from "react-router-dom";
import moment from "moment";
import DevTable from "../../helpers/devex-table";
import { OFFICES_ID_NAME } from "../../global/officeList";
import { useAppDispatch, useAppSelector } from "../../hooks/reduxHooks";
import { fetchUsersByOfficeId, subscribeToUsers } from "../../features/users/usersSlice";
import { fetchVacationsByOfficeByDate } from "../../features/vacations/vacationsSlice";
import { fetchAccessByOfficeAndOperationDate } from "../../features/access/accessSlice";
import { globalUsersData } from "../../global/usersList";
import { users } from "../../db/interfaces";
import { setFilterValue } from "../../features/filters/filtersSlice";

export default function UsersAbsent() {
  const { filterValues } = useAppSelector((state) => state.filters);
  const { data: usersData, status: usersStatus, error: usersError } = useAppSelector((store) => store.users);
  const { data: accessData, status: accessStatus, error: accessError } = useAppSelector((store) => store.access);
  const {
    data: vacationsData,
    status: vacationsStatus,
    error: vacationsError,
  } = useAppSelector((store) => store.vacations);
  const dispatch = useAppDispatch();
  const [officeId, setOfficeId] = useState(filterValues["officeId"] ?? "Select an office...");

  const [now, setNow] = useState(moment().startOf("day"));
  const yesterday = moment(now).subtract(1, "days").endOf("day");
  const tomorrow = moment(now).add(1, "days").startOf("day");
  const history = useHistory();
  const [defaultHiddenColumnNames] = useState([]);

  useEffect(() => {
    dispatch(fetchUsersByOfficeId(officeId));
    dispatch(
      fetchVacationsByOfficeByDate({
        officeId,
        gt: yesterday.format("YYYY-MM-DD"),
        ls: tomorrow.add(1, "month").format("YYYY-MM-DD"),
        byField: "vacationEndOn",
      })
    );
    dispatch(
      fetchAccessByOfficeAndOperationDate({
        officeId,
        gt: moment(now).endOf("day").format("YYYY-MM-DD"),
        ls: tomorrow.format("YYYY-MM-DD"),
      })
    );

    const unsubscribe = subscribeToUsers(dispatch);
    return () => {
      unsubscribe();
    };
  }, [dispatch, officeId, now]);

  if (usersStatus == "loading" || accessStatus == "loading" || vacationsStatus == "loading") return <Spin />;

  const vacations = vacationsData.map((v) => {
    return { ...v, id: v.id };
  });

  const vacationsFiltered = vacations?.filter((vacation: any) => {
    const vacationStartOn = moment(vacation.vacationStartOn).startOf("day");
    const vacationEndOn = moment(vacation.vacationEndOn).endOf("day");

    return moment(now).isBetween(vacationStartOn, vacationEndOn, undefined, "[]");
  });

  const users = usersData
    ?.filter((u: any) => u.status != "disable")
    ?.map((user) => {
      return { ...user, uid: user.id };
    });

  const usersNotOnVacation = users.filter(
    (user) => vacationsFiltered.find((v: any) => v.employeeUID === user.uid) == undefined
  );

  const access = accessData.map((a) => {
    return { ...a, id: a.id };
  });

  const usersAbsent = usersNotOnVacation
    .filter((unv: any) => access.find((a: any) => a.uid == unv.uid) == undefined)
    .map((ub, index) => {
      return { ...ub, index: index + 1 };
    })
    .filter((u: any) => u.status != "disable");

  const columnsExport = [
    { name: "id", title: "UID" },
    { name: "fullName", title: "Full Name", getCellValue: (row: users) => globalUsersData[row.uid ?? ""]?.fullName },
    { name: "position", title: "Position" },
  ];
  const columns = [
    { name: "id", title: "UID" },
    { name: "fullName", title: "Full Name", getCellValue: (row: users) => globalUsersData[row.uid ?? ""]?.fullName },
    { name: "position", title: "Position" },
    {
      name: "fingerPrint",
      title: "Finger Print Records",
      getCellValue: (r: any) => {
        return (
          <div>
            <Button
              type="primary"
              onClick={() => {
                history.push(`/fingerPrint/${r.uid}`);
              }}
            >
              Finger Prints
            </Button>
          </div>
        );
      },
    },
  ];

  if (usersError || accessError || vacationsError)
    return (
      <p>
        {usersError}-{accessError}-{vacationsError}
      </p>
    );
  return (
    <div>
      <DatePicker
        placeholder="Select month"
        value={moment(now)}
        format={"DD-MM-YYYY"}
        onChange={(e) => setNow(moment(e).startOf("day"))}
      />

      <Select
        filterOption={(inputValue, option) => {
          return (option.props.children as any).toLowerCase().indexOf(inputValue.toLowerCase()) >= 0;
        }}
        size="default"
        placeholder="Search for Office!"
        style={{ width: "25%", paddingBottom: "1%", paddingRight: "1%" }}
        showSearch
        value={officeId}
        onChange={(e) => {
          setOfficeId(e);
          dispatch(setFilterValue({ key: "officeId", value: e }));
        }}
      >
        {OFFICES_ID_NAME.map((d) => {
          return <Option value={d.officeId}>{d.officeName}</Option>;
        })}
      </Select>

      <Paper>
        <DevTable
          data={usersAbsent}
          columns={columns}
          defaultHiddenColumnNames={defaultHiddenColumnNames}
          tableName="Absents"
          selected={false}
          typicalTable={false}
          columnsExport={columnsExport}
        />
      </Paper>
    </div>
  );
}
