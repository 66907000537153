import { createSlice, createAsyncThunk, PayloadAction } from "@reduxjs/toolkit";
import { supabase } from "../../supabaseClient"; // Adjust the import path as needed
import { Calendar } from "../../db/interfaces"; // Adjust the import path as needed

interface CalendarSliceState {
  data: Calendar[];
  originalMeetings: Calendar[];
  calendar: Calendar;
  status: "loading" | "succeeded" | "failed" | "idle";
  error: string | null;
}

const initialState: CalendarSliceState = {
  data: [],
  calendar: {} as Calendar,
  originalMeetings: [],
  status: "idle",
  error: null,
};

// Fetch all calendar events
export const fetchAllCalendarEvents = createAsyncThunk<Calendar[], void, { rejectValue: string }>(
  "calendar/fetchAllCalendarEvents",
  async (_, thunkApi) => {
    const { data, error } = await supabase.from("calendar").select("*");
    if (error) {
      return thunkApi.rejectWithValue(error.message);
    }
    return data as Calendar[];
  }
);

// Fetch calendar events by user
export const fetchCalendarEventsByUser = createAsyncThunk<Calendar[], string, { rejectValue: string }>(
  "calendar/fetchCalendarEventsByUser",
  async (userId, thunkApi) => {
    const { data, error } = await supabase.rpc("get_calendar_events_by_user", {
      user_id: userId,
    });

    if (error) {
      return thunkApi.rejectWithValue(error.message);
    }
    return data as Calendar[];
  }
);

export const fetchCalendarEventsByOriginalIdAndStartDate = createAsyncThunk<Calendar[], any, { rejectValue: string }>(
  "calendar/fetchCalendarEventsByOriginalIdAndStartDate",
  async ({ originalId, startDate }, thunkApi) => {
    const { data, error } = await supabase
      .from("calendar")
      .select("*")
      .eq("originalId", originalId) // Check for originalId
      .lt("startDate", startDate) // Check if startDate is less than the provided date
      .order("startDate", { ascending: false }) // Order by startDate in descending order
      .limit(3); // Limit to 3 results

    if (error) {
      return thunkApi.rejectWithValue(error.message);
    }
    return data as Calendar[];
  }
);

export const fetchCalendarEventsByOriginalId = createAsyncThunk<Calendar[], string, { rejectValue: string }>(
  "calendar/fetchCalendarEventsByOriginalId",
  async (originalId, thunkApi) => {
    const { data, error } = await supabase.from("calendar").select("*").eq("originalId", originalId); // Check for originalId

    if (error) {
      return thunkApi.rejectWithValue(error.message);
    }
    return data as Calendar[];
  }
);

// Fetch calendar event by id
export const fetchCalendarEventById = createAsyncThunk<Calendar, string, { rejectValue: string }>(
  "calendar/fetchCalendarEventById",
  async (eventId, thunkApi) => {
    const { data, error } = await supabase.from("calendar").select("*").eq("id", eventId).single();
    if (error) {
      return thunkApi.rejectWithValue(error.message);
    }
    return data as Calendar;
  }
);

// Define the async thunk for fetching calendar events within the date range
export const fetchCalendarEventsInRange = createAsyncThunk<
  Calendar[],
  { gt: string; ls: string },
  { rejectValue: string }
>("calendar/fetchEventsInRange", async ({ gt, ls }, thunkApi) => {
  try {
    // Execute Supabase query
    const { data, error } = await supabase.from("calendar").select("*").gt("startDate", gt).lte("startDate", ls);

    if (error) {
      return thunkApi.rejectWithValue(error.message);
    }

    return data; // Return fetched data
  } catch (error) {
    console.error("Unexpected error:", error);
    return thunkApi.rejectWithValue("Unexpected error occurred");
  }
});

// Fetch calendar events by date
export const fetchCalendarEventsByDate = createAsyncThunk<
  Calendar[],
  { gt: string; ls: string; byField: string },
  { rejectValue: string }
>("calendar/fetchCalendarEventsByDate", async ({ gt, ls, byField }, thunkApi) => {
  const { data, error } = await supabase.from("calendar").select("*").gt(byField, gt).lt(byField, ls);
  if (error) {
    return thunkApi.rejectWithValue(error.message);
  }
  return data as Calendar[];
});

// Add a new calendar event
export const addCalendarEvent = createAsyncThunk<Calendar, Calendar, { rejectValue: string }>(
  "calendar/addCalendarEvent",
  async (newEvent, thunkApi) => {
    const { data: insertData, error: insertError } = await supabase.from("calendar").insert(newEvent).select().single();

    if (insertError) {
      return thunkApi.rejectWithValue(insertError.message);
    }

    return insertData as Calendar;
  }
);

// Edit a calendar event
export const editCalendarEvent = createAsyncThunk<Calendar, Calendar, { rejectValue: string }>(
  "calendar/editCalendarEvent",
  async (updatedEvent, thunkApi) => {
    const { data: updateData, error: updateError } = await supabase
      .from("calendar")
      .update(updatedEvent)
      .eq("id", updatedEvent.id)
      .select()
      .single();

    if (updateError) {
      return thunkApi.rejectWithValue(updateError.message);
    }

    return updateData as Calendar;
  }
);

// Edit a calendar event
export const editCalendarEventsByOriginalId = createAsyncThunk<
  Calendar,
  { changes: Calendar; originalId: string },
  { rejectValue: string }
>("calendar/editCalendarEventsByOriginalId", async ({ changes, originalId }, thunkApi) => {
  const { data: updateData, error: updateError } = await supabase
    .from("calendar")
    .update(changes)
    .eq("originalId", originalId)
    .select();

  if (updateError) {
    return thunkApi.rejectWithValue(updateError.message);
  }

  // Ensure we are returning a single Calendar object
  if (updateData && updateData.length > 0) {
    return updateData[0] as Calendar;
  } else {
    return thunkApi.rejectWithValue("No data returned after update");
  }
});

// Delete a calendar event
export const deleteCalendarEvent = createAsyncThunk<string, string, { rejectValue: string }>(
  "calendar/deleteCalendarEvent",
  async (id, thunkApi) => {
    const { error } = await supabase.from("calendar").delete().eq("id", id);

    if (error) {
      return thunkApi.rejectWithValue(error.message);
    }

    return id;
  }
);

// Real-Time Actions
export const addCalendarEventRealTime = (newEvent: Calendar): PayloadAction<Calendar> => ({
  type: "calendar/addCalendarEventRealTime",
  payload: newEvent,
});

export const editCalendarEventRealTime = (updatedEvent: Calendar): PayloadAction<Calendar> => ({
  type: "calendar/editCalendarEventRealTime",
  payload: updatedEvent,
});

export const deleteCalendarEventRealTime = (id: string): PayloadAction<string> => ({
  type: "calendar/deleteCalendarEventRealTime",
  payload: id,
});

// Slice definition
const calendarSlice = createSlice({
  name: "calendar",
  initialState,
  reducers: {
    addCalendarEventRealTime(state, action: PayloadAction<Calendar>) {
      const exists = state.data.some((event) => event.id === action.payload.id);
      if (!exists) {
        state.data = [...state.data, action.payload];
      }
      state.error = null;
    },
    editCalendarEventRealTime(state, action: PayloadAction<Calendar>) {
      const index = state.data.findIndex((event) => event.id === action.payload.id);
      if (index !== -1) {
        const isDifferent = JSON.stringify(state.data[index]) !== JSON.stringify(action.payload);
        if (isDifferent) {
          state.data[index] = action.payload;
        }
      }
      state.error = null;

      if (state.calendar.id === action.payload.id) {
        state.calendar = action.payload;
      }
    },
    deleteCalendarEventRealTime(state, action: PayloadAction<string>) {
      const exists = state.data.some((event) => event.id === action.payload);
      if (exists) {
        state.data = state.data.filter((event) => event.id !== action.payload);
      }
      state.error = null;
    },
  },
  extraReducers: (builder) => {
    builder
      // Handle fetchAllCalendarEvents
      .addCase(fetchAllCalendarEvents.pending, (state) => {
        state.status = "loading";
      })
      .addCase(fetchAllCalendarEvents.fulfilled, (state, action: PayloadAction<Calendar[]>) => {
        state.status = "succeeded";
        state.data = action.payload;
        state.error = null;
      })
      .addCase(fetchAllCalendarEvents.rejected, (state, action: PayloadAction<string | undefined>) => {
        state.status = "failed";
        state.error = action.payload || "Failed to fetch calendar events.";
      })

      // Handle fetchCalendarEventsInRange
      .addCase(fetchCalendarEventsInRange.pending, (state) => {
        state.status = "loading";
      })
      .addCase(fetchCalendarEventsInRange.fulfilled, (state, action: PayloadAction<Calendar[]>) => {
        state.status = "succeeded";
        state.data = action.payload;
        state.error = null;
      })
      .addCase(fetchCalendarEventsInRange.rejected, (state, action: PayloadAction<string | undefined>) => {
        state.status = "failed";
        state.error = action.payload || "Failed to fetch calendar events.";
      })

      // Handle fetchCalendarEventById
      .addCase(fetchCalendarEventById.pending, (state) => {
        state.status = "loading";
      })
      .addCase(fetchCalendarEventById.fulfilled, (state, action: PayloadAction<Calendar>) => {
        state.status = "succeeded";
        state.calendar = action.payload;
        state.error = null;
      })
      .addCase(fetchCalendarEventById.rejected, (state, action: PayloadAction<string | undefined>) => {
        state.status = "failed";
        state.error = action.payload || "Failed to fetch calendar event.";
      })

      // Handle fetchCalendarEventsByUser
      .addCase(fetchCalendarEventsByUser.pending, (state) => {
        state.status = "loading";
      })
      .addCase(fetchCalendarEventsByUser.fulfilled, (state, action: PayloadAction<Calendar[]>) => {
        state.status = "succeeded";
        state.data = action.payload;
        state.error = null;
      })
      .addCase(fetchCalendarEventsByUser.rejected, (state, action: PayloadAction<string | undefined>) => {
        state.status = "failed";
        state.error = action.payload || "Failed to fetch calendar events by user.";
      })
      // Handle fetchCalendarEventsByOriginalIdAndStartDate
      .addCase(fetchCalendarEventsByOriginalIdAndStartDate.pending, (state) => {
        // state.status = "loading";
      })
      .addCase(fetchCalendarEventsByOriginalIdAndStartDate.fulfilled, (state, action: PayloadAction<Calendar[]>) => {
        // state.status = "succeeded";
        state.originalMeetings = action.payload;
        state.error = null;
      })
      .addCase(
        fetchCalendarEventsByOriginalIdAndStartDate.rejected,
        (state, action: PayloadAction<string | undefined>) => {
          // state.status = "failed";
          state.error = action.payload || "Failed to fetch calendar events by user.";
        }
      )

      // Handle fetchCalendarEventsByOriginalId
      .addCase(fetchCalendarEventsByOriginalId.pending, (state) => {
        // state.status = "loading";
      })
      .addCase(fetchCalendarEventsByOriginalId.fulfilled, (state, action: PayloadAction<Calendar[]>) => {
        // state.status = "succeeded";
        state.originalMeetings = action.payload;
        state.error = null;
      })
      .addCase(fetchCalendarEventsByOriginalId.rejected, (state, action: PayloadAction<string | undefined>) => {
        // state.status = "failed";
        state.error = action.payload || "Failed to fetch calendar events by user.";
      })

      // Handle fetchCalendarEventsByDate
      .addCase(fetchCalendarEventsByDate.pending, (state) => {
        state.status = "loading";
      })
      .addCase(fetchCalendarEventsByDate.fulfilled, (state, action: PayloadAction<Calendar[]>) => {
        state.status = "succeeded";
        state.data = action.payload;
        state.error = null;
      })
      .addCase(fetchCalendarEventsByDate.rejected, (state, action: PayloadAction<string | undefined>) => {
        state.status = "failed";
        state.error = action.payload || "Failed to fetch calendar events by date.";
      })

      // Handle addCalendarEvent
      .addCase(addCalendarEvent.pending, (state) => {})
      .addCase(addCalendarEvent.fulfilled, (state, action: PayloadAction<Calendar>) => {
        state.error = null;
      })
      .addCase(addCalendarEvent.rejected, (state, action: PayloadAction<string | undefined>) => {
        state.error = action.payload || "Failed to add calendar event.";
      })

      // Handle editCalendarEvent
      .addCase(editCalendarEvent.pending, (state) => {})
      .addCase(editCalendarEvent.fulfilled, (state, action: PayloadAction<Calendar>) => {
        state.error = null;
      })
      .addCase(editCalendarEvent.rejected, (state, action: PayloadAction<string | undefined>) => {
        state.status = "failed";
        state.error = action.payload || "Failed to edit calendar event.";
      })

      // Handle editCalendarEventsByOriginalId
      .addCase(editCalendarEventsByOriginalId.pending, (state) => {})
      .addCase(editCalendarEventsByOriginalId.fulfilled, (state, action: PayloadAction<Calendar>) => {
        state.error = null;
      })
      .addCase(editCalendarEventsByOriginalId.rejected, (state, action: PayloadAction<string | undefined>) => {
        state.status = "failed";
        state.error = action.payload || "Failed to edit calendar event.";
      })

      // Handle deleteCalendarEvent
      .addCase(deleteCalendarEvent.pending, (state) => {})
      .addCase(deleteCalendarEvent.fulfilled, (state, action: PayloadAction<string>) => {
        state.error = null;
      })
      .addCase(deleteCalendarEvent.rejected, (state, action: PayloadAction<string | undefined>) => {
        state.status = "failed";
        state.error = action.payload || "Failed to delete calendar event.";
      });
  },
});

// Real-time subscription function
export const subscribeToCalendarEvents = (dispatch: any) => {
  const subscription = supabase
    .channel("public:calendar")
    .on("postgres_changes", { event: "*", schema: "public", table: "calendar" }, (payload) => {
      const newRecord = payload.new as Calendar;
      const oldRecord = payload.old as Calendar;

      switch (payload.eventType) {
        case "INSERT":
          dispatch(addCalendarEventRealTime(newRecord));
          break;
        case "UPDATE":
          dispatch(editCalendarEventRealTime(newRecord));
          break;
        case "DELETE":
          dispatch(deleteCalendarEventRealTime(oldRecord.id as string));
          break;
        default:
          break;
      }
    })
    .subscribe();

  return () => {
    subscription.unsubscribe();
  };
};

export default calendarSlice.reducer;
