import React, { useState, useCallback, useRef, useEffect } from "react";
import { useHistory, useRouteMatch } from "react-router-dom";
import { Spin, Button, Descriptions, Badge, Popconfirm, message } from "antd";
import Discussion from "../../discussion/discussion";
import moment from "moment";
import { format } from "../../db/dateFormat";
import { useTranslation } from "react-i18next";
import { vacation, users } from "../../db/interfaces";
import EditVacation from "./VacationsModules/EditVacation";
import { globalOfficesData } from "../../global/officeList";
import VacationView from "./vacationView";
import { globalUsersData } from "../../global/usersList";
import { useAppDispatch, useAppSelector } from "../../hooks/reduxHooks";
import { deleteVacation, editVacation, fetchVacationById } from "../../features/vacations/vacationsSlice";
import { deleteDiscussionsByOrderId } from "../../features/discussions/discussionsSlice";
import { deleteNotificationsByOrderId } from "../../features/notifications/notificationsSlice";
import { editUser } from "../../features/users/usersSlice";

export default function VacationDetails() {
  const id = useRouteMatch<{ id: string }>().params.id;
  const { vacation, status: vacationStatus, error: vacationError } = useAppSelector((state) => state.vacations);
  const { currentUser } = useAppSelector((state) => state.auth);
  const uid = currentUser?.id as string;

  const { status: GlobalStatus } = useAppSelector((state) => state.users);
  const dispatch = useAppDispatch();
  const { t } = useTranslation();
  const history = useHistory();
  const [modalState, setModalState] = useState(false);

  const deleteOrder = async () => {
    dispatch(deleteNotificationsByOrderId(id));
    dispatch(deleteDiscussionsByOrderId(id));
    dispatch(deleteVacation(id)).then(() => history.push("/vacation"));
    message.success("Vacation has been deleted successfully!");
  };

  const print1CbRef = useRef<() => void>();
  const print = useCallback(() => {
    setTimeout(() => {
      print1CbRef.current();
    }, 1500);
  }, []);

  useEffect(() => {
    dispatch(fetchVacationById(id));
  }, [dispatch, id]);

  if (vacationStatus == "loading" || GlobalStatus == "loading") {
    return <Spin size="large" />;
  }
  const data = vacation;
  const userId = data?.employeeUID;
  const employeeData = globalUsersData[userId ?? ""] as users;
  const managerFullName = globalUsersData[employeeData?.managerUID ?? ""]?.fullName ?? "";
  const vacationId = id;
  const adminFullName = globalUsersData[data?.adminUID ?? ""]?.fullName ?? "";
  const HRFullName = globalUsersData[data?.HRUserUID ?? ""]?.fullName ?? "";

  const status = data?.status;
  const statusBadge =
    status == "Requested"
      ? "warning"
      : status == "Pending"
      ? "processing"
      : status == "Rejected" || status == "Canceled"
      ? "default"
      : status == "Accepted"
      ? "success"
      : "error";

  const dateFormat = "YYYY/MM/DD hh:mm:ss";
  const reChangeOnScore = () => {
    const returnedScore = data?.vacationPeriodDays;
    const userId = data?.employeeUID;
    switch (data?.vacationType) {
      case "Annual Leave":
        dispatch(
          editUser({
            id: userId,
            vacationAnnualScore: globalUsersData[userId ?? ""]?.vacationAnnualScore + returnedScore,
          })
        );
        break;

      case "Sick Leave":
        dispatch(
          editUser({
            id: userId,
            vacationSickScore: globalUsersData[userId ?? ""]?.vacationSickScore + returnedScore,
          })
        );
        break;

      case "Compensation Leave":
        dispatch(
          editUser({
            id: userId,
            vacationCompensationScore: globalUsersData[userId ?? ""]?.vacationCompensationScore + returnedScore,
          })
        );
        break;

      case "Death Leave":
        dispatch(
          editUser({
            id: userId,
            vacationDeathScore: globalUsersData[userId ?? ""]?.vacationDeathScore + returnedScore,
          })
        );
        break;

      case "Marriage Leave":
        dispatch(
          editUser({
            id: userId,
            vacationMarriageScore: globalUsersData[userId ?? ""]?.vacationMarriageScore + returnedScore,
          })
        );
        break;
      case "Paternity Leave":
        dispatch(
          editUser({
            id: userId,
            vacationPaternityScore: globalUsersData[userId ?? ""]?.vacationPaternityScore + returnedScore,
          })
        );
        break;

      case "Maternity Leave":
        dispatch(
          editUser({
            id: userId,
            vacationMaternityScore: globalUsersData[userId ?? ""]?.vacationMaternityScore + returnedScore,
          })
        );
        break;
      default:
        break;
    }
  };
  return vacationError ? (
    <p>{vacationError}</p>
  ) : (
    <div style={{ marginTop: "10px" }}>
      {modalState ? (
        <EditVacation
          modalState={modalState}
          setModalState={setModalState}
          vacationId={id}
          employeeUID={data?.employeeUID}
          vacation={vacation}
        />
      ) : null}

      {/* //------------------- */}
      <Descriptions
        size={"default"}
        title={`${data?.vacationType}`}
        layout="horizontal"
        bordered={true}
        column={{ xs: 8, sm: 6, md: 4 }}
      >
        <Descriptions.Item label={t("general.requestedAt")}>
          {moment(data.requestedAt).format(dateFormat)}
        </Descriptions.Item>
        <Descriptions.Item label={t("general.status")}>
          <Badge status={statusBadge} text={status} />
        </Descriptions.Item>
      </Descriptions>
      {/* //------------------- */}

      <Descriptions size={"default"} layout="horizontal" bordered={true} column={{ xs: 8, sm: 6, md: 4 }}>
        <Descriptions.Item label={t("general.fullName")} span={6}>
          {globalUsersData[data?.employeeUID ?? ""]?.fullName}
        </Descriptions.Item>
        <Descriptions.Item label={t("general.position")} span={2}>
          {globalUsersData[data?.employeeUID ?? ""]?.position}
        </Descriptions.Item>
        <Descriptions.Item label={t("general.manager")} span={2}>
          {managerFullName}
        </Descriptions.Item>
        <Descriptions.Item label={t("general.location")} span={2}>
          {globalOfficesData[data?.office ?? ""]?.officeName}
        </Descriptions.Item>

        <Descriptions.Item label={t("general.vacationType")} span={2}>
          {data.vacationType}
        </Descriptions.Item>

        <Descriptions.Item label={t("general.vacationPeriodDays")} span={2}>
          {data.vacationPeriodDays?.toFixed(1)}
        </Descriptions.Item>
        <Descriptions.Item label={t("general.vacationStartOn")} span={2}>
          {moment(data.vacationStartOn).format(format)}
        </Descriptions.Item>
        <Descriptions.Item label={t("general.vacationEndOn")} span={2}>
          {moment(data.vacationEndOn).format(format)}
        </Descriptions.Item>
        <Descriptions.Item label={t("general.startWorkOn")} span={2}>
          {moment(data.startWorkOn).format(format)}
        </Descriptions.Item>

        <Descriptions.Item label={t("general.vacationAnnualScore")} span={2}>
          {data.vacationAnnualScore?.toFixed(3)}
        </Descriptions.Item>
        <Descriptions.Item label={t("general.vacationSickScore")} span={2}>
          {data.vacationSickScore?.toFixed(1)}
        </Descriptions.Item>
        <Descriptions.Item label={t("general.vacationCompensationScore")} span={6}>
          {data.vacationCompensationScore?.toFixed(3)}
        </Descriptions.Item>
        {data.halfDay == "null" || data.halfDay == null || data.halfDay == "" ? null : (
          <Descriptions.Item label={t("general.halfDay")} span={6}>
            {data.halfDay}
          </Descriptions.Item>
        )}

        <Descriptions.Item label={t("general.employeeSignatureDate")} span={2}>
          {data.employeeSignatureDate == null ||
          data.employeeSignatureDate == "null" ||
          data.employeeSignatureDate == "" ? (
            <Button
              style={{ width: "150px" }}
              type="primary"
              onClick={() =>
                dispatch(
                  editVacation({
                    id: vacationId,
                    employeeSignatureDate: moment(),
                  } as any)
                )
              }
            >
              Approve
            </Button>
          ) : (
            <div>
              {data.employeeSignatureURL == "null" ||
              data.employeeSignatureURL == "" ||
              data.employeeSignatureURL == null ? null : (
                <img height={100} src={data.employeeSignatureURL} />
              )}
              <p> {moment(data?.employeeSignatureDate).format(dateFormat)} </p>
            </div>
          )}
        </Descriptions.Item>
        <Descriptions.Item label={t("general.managerSignatureDate")} span={2}>
          {data.managerSignatureDate == null ||
          data.managerSignatureDate == "null" ||
          data.managerSignatureDate == "" ? (
            data.managerUID == uid ? (
              <Button
                style={{ width: "150px", height: "50px" }}
                type="primary"
                onClick={() =>
                  dispatch(
                    editVacation({
                      id: vacationId,
                      managerSignatureDate: moment(),
                      managerSignatureURL: currentUser?.signatureURL ?? "",
                      status: "Pending",
                    } as any)
                  )
                }
              >
                Approve
              </Button>
            ) : null
          ) : (
            <div>
              {data.managerSignatureURL == "null" ||
              data.managerSignatureURL == "" ||
              data.managerSignatureURL == null ? null : (
                <img height={100} src={data.managerSignatureURL} />
              )}
              <p> {moment(data.managerSignatureDate).format(dateFormat)} </p>
            </div>
          )}
        </Descriptions.Item>

        <Descriptions.Item label={t("general.adminUser")} span={2}>
          {data.HRSignatureDate == "null" || data.HRSignatureDate == "" ? null : data.adminSignatureDate == null ||
            data.adminSignatureDate == "null" ||
            data.adminSignatureDate == "" ? (
            currentUser?.adminOffices.includes(data.office) ? (
              <Button
                style={{ width: "150px", height: "50px" }}
                type="primary"
                onClick={async () => {
                  dispatch(
                    editVacation({
                      id: vacationId,
                      adminSignatureDate: moment(),
                      adminSignatureURL: currentUser?.signatureURL ?? "",
                      status: "Accepted",
                      adminUID: uid,
                    } as any)
                  );
                }}
              >
                Approve
              </Button>
            ) : null
          ) : (
            <p> {adminFullName} </p>
          )}
        </Descriptions.Item>
        <Descriptions.Item label={t("general.HRUser")} span={2}>
          {data.managerSignatureDate == "null" || data.managerSignatureDate == "" ? null : data.HRSignatureDate ==
              null ||
            data.HRSignatureDate == "null" ||
            data.HRSignatureDate == "" ? (
            currentUser?.HROffices.includes(data.office) ? (
              <Button
                style={{ width: "150px", height: "50px" }}
                type="primary"
                onClick={() => {
                  dispatch(
                    editVacation({
                      id: vacationId,
                      HRSignatureDate: moment(),
                      HRSignatureURL: currentUser?.signatureURL ?? "",
                      HRUserUID: uid,
                    } as any)
                  );
                }}
              >
                Approve
              </Button>
            ) : null
          ) : (
            <p> {HRFullName} </p>
          )}
        </Descriptions.Item>

        <Descriptions.Item label={t("general.adminSignatureDate")} span={2}>
          {data.adminSignatureDate == "null" || data.adminSignatureDate == "" ? null : (
            <div>
              {data.adminSignatureURL == "null" ||
              data.adminSignatureURL == "" ||
              data.adminSignatureURL == null ? null : (
                <img height={100} src={data.adminSignatureURL} />
              )}
              <p>
                {" "}
                {moment(
                  data.adminSignatureDate == "null" || data.adminSignatureDate == "" ? "null" : data.adminSignatureDate
                ).format(dateFormat)}{" "}
              </p>
            </div>
          )}
        </Descriptions.Item>
        <Descriptions.Item label={t("general.HRSignatureDate")} span={2}>
          {data.HRSignatureDate == "null" || data.HRSignatureDate == "" ? null : (
            <div>
              {data.HRSignatureURL == "null" || data.HRSignatureURL == "" || data.HRSignatureURL == null ? null : (
                <img height={100} src={data.HRSignatureURL} />
              )}
              <p> {moment(data.HRSignatureDate).format(dateFormat)} </p>
            </div>
          )}
        </Descriptions.Item>
        <Descriptions.Item label={t("general.actions")} span={6}>
          <div>
            {data.status == "Pending" || data.status == "Requested" ? (
              <Button type="primary" style={{ margin: "1%" }} onClick={() => setModalState(true)}>
                Edit
              </Button>
            ) : null}

            {currentUser?.HROffices.includes(data.office) ? (
              <Button type="danger" style={{ margin: "1%" }} onClick={() => setModalState(true)}>
                Edit As HR
              </Button>
            ) : null}

            {(data.status == "Requested" || currentUser?.HROffices.includes(data.office)) &&
            data?.status != "Canceled" ? (
              <Popconfirm
                title={t("general.areUSure")}
                onConfirm={() => {
                  reChangeOnScore();
                  deleteOrder();
                }}
                onCancel={() => console.log("cancel")}
                okText="Yes"
                cancelText="No"
              >
                <Button style={{ margin: "1%" }} type="danger">
                  Delete
                </Button>
              </Popconfirm>
            ) : null}

            {!currentUser?.fullControlOffices?.includes(data.office) || data.status == "Canceled" ? null : (
              <Popconfirm
                style={{ margin: "1%" }}
                title={t("general.areUSure")}
                onConfirm={() => {
                  dispatch(
                    editVacation({
                      id: vacationId,
                      status: "Canceled",
                      canceledBy: uid,
                    } as any)
                  );
                  reChangeOnScore();
                }}
                onCancel={() => console.log("cancel")}
                okText="Yes"
                cancelText="No"
              >
                <Button style={{ margin: "1%" }} type="danger">
                  {" "}
                  Cancel
                </Button>
              </Popconfirm>
            )}

            {data.status == "Accepted" ? (
              <div>
                {data?.employeeSignatureURL &&
                data?.managerSignatureURL &&
                data?.adminSignatureURL &&
                data?.HRSignatureURL ? (
                  <Button
                    style={{ margin: "1%" }}
                    onClick={() => {
                      print();
                    }}
                  >
                    Print
                  </Button>
                ) : null}
              </div>
            ) : null}
          </div>
        </Descriptions.Item>
      </Descriptions>

      {/* //------------------- */}

      {vacation.employeeUID && (
        <Discussion
          userId={userId ?? "notYet"}
          orderId={vacationId ?? "notYet"}
          type={"vacation"}
          officeId={data?.office ?? "notYet"}
        />
      )}
      <br />
      <div style={{ display: "none" }}>
        <VacationView vacation={data as vacation} printFnRef={print1CbRef as any} />
      </div>
    </div>
  );
}
