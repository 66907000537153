import React, { useEffect, useState } from "react";
import { Button, Checkbox, Col, Form, message, Modal, Row, Statistic } from "antd";
import { users } from "../../db/interfaces";
import { useFormik } from "formik";
import { useAppDispatch } from "../../hooks/reduxHooks";
import { editUser } from "../../features/users/usersSlice";

interface Props {
  UID: string;
  modalState: boolean;
  setModalState: any;
  user: users;
}

function PermissionsDialog(props: Props) {
  const dispatch = useAppDispatch();
  const { UID, modalState, setModalState, user } = props;
  const userData = user;

  const formik = useFormik({
    initialValues: {
      cases: [],
    },
    onSubmit: async (values, form) => {
      const permissions = values;
      await dispatch(editUser({ id: UID, permissions: permissions })).then(() => {
        message.success("Saved Successfully!");
        setModalState(false);
      });
    },
  });

  const [loaded, setLoaded] = useState(true);
  useEffect(() => {
    if (user && loaded) {
      formik.setValues({
        cases: userData?.permissions ? userData?.permissions.cases : [],
      } as any);
      setLoaded(false);
    }
  }, [user, formik, loaded]);

  return (
    <Modal
      closable={true}
      width={800}
      visible={modalState}
      onCancel={async () => {
        setModalState(false);
      }}
      footer={[]}
    >
      <div>
        <Form onSubmit={formik.handleSubmit}>
          <Row>
            <Col span={20}>
              <Statistic title="First Name" value={userData?.firstName + " " + userData?.lastName} />
            </Col>
          </Row>

          <Form.Item label="Case">
            <Checkbox.Group onChange={(e) => formik.setFieldValue("cases", e)} value={formik.values.cases}>
              <Checkbox value="meal">Meal</Checkbox>
              <Checkbox value="program">Program</Checkbox>
              <Checkbox value="admin">Admin</Checkbox>
              <Checkbox value="CEO">CEO</Checkbox>
            </Checkbox.Group>
          </Form.Item>
          <Form.Item>
            <Button className="btn blue lighten-1 z-depth-0" onClick={() => {}} type="primary" htmlType="submit">
              Save
            </Button>
          </Form.Item>
        </Form>
      </div>
    </Modal>
  );
}

export default PermissionsDialog;
