import { createSlice, PayloadAction } from "@reduxjs/toolkit";

// Define the shape of the filter state
interface FiltersState {
  filterValues: {
    [key: string]: any | null; // key is the filter type (e.g., 'officeId'), value is the selected value
  };
}

// Initial state with empty filter values
const initialState: FiltersState = {
  filterValues: { dashboard: { roles: [], tags: [], task: "" } },
};

// Slice for managing filter values
const filtersSlice = createSlice({
  name: "filters",
  initialState,
  reducers: {
    // Action to set a filter value by key
    setFilterValue: (state, action: PayloadAction<{ key: string; value: string | null }>) => {
      const { key, value } = action.payload;
      state.filterValues[key] = value;
    },
    // Action to clear a specific filter value
    clearFilterValue: (state, action: PayloadAction<string>) => {
      const key = action.payload;
      state.filterValues[key] = null;
    },
    // Action to clear all filters
    clearAllFilters: (state) => {
      state.filterValues = {};
    },
  },
});

// Export actions to be used in components
export const { setFilterValue, clearFilterValue, clearAllFilters } = filtersSlice.actions;

// Selector to get a specific filter value
export const selectFilterValue = (state: any, key: string) => state.filters.filterValues[key] ?? null;

// Export the reducer to be combined in the store
export default filtersSlice.reducer;
