import { createSlice, createAsyncThunk, PayloadAction } from "@reduxjs/toolkit";
import { supabase } from "../../supabaseClient"; // Adjust the import path as needed
import { office } from "../../db/interfaces";

interface officesSliceState {
  data: office[];
  status: "loading" | "succeeded" | "failed" | "idle";
  error: string | null;
  globalOfficesData: Record<string, office>;
  OFFICES_ID_NAME: { officeId: string; officeName: string }[];
  loaded: boolean;
}

const initialState: officesSliceState = {
  data: [],
  status: "idle",
  error: null,
  globalOfficesData: {},
  OFFICES_ID_NAME: [],
  loaded: false,
};

// Fetch all offices in batches
export const fetchOfficesInBatches = createAsyncThunk(
  "offices/fetchOfficesInBatches",
  async (_, { rejectWithValue }) => {
    const allOffices: any[] = [];
    let from = 0;
    let to = 999;

    for (let i = 0; i < 2; i++) {
      const { data, error } = await supabase.from("offices").select("*").range(from, to);
      if (error) {
        return rejectWithValue(error.message as string);
      }

      if (data?.length) {
        allOffices.push(...data);
        from += 1000;
        to += 1000;
      } else {
        break;
      }
    }

    return allOffices;
  }
);

// Fetch all offices
export const fetchAllOffices = createAsyncThunk<office[], void, { rejectValue: string }>(
  "allocation/fetchAllOffices",
  async (_, thunkApi) => {
    const { data, error } = await supabase.from("offices").select();
    if (error) {
      return thunkApi.rejectWithValue(error.message);
    }
    return data as office[];
  }
);

// Add a new office
export const addOffice = createAsyncThunk<office, office, { rejectValue: string }>(
  "offices/addOffice",
  async (newOffice, thunkApi) => {
    const newOfficeForm = {
      office_name: newOffice.officeName,
      location: newOffice.location,
      admins: newOffice.admins,
      hrs: newOffice.HRs,
      hrprojectsmanagers: newOffice.HRProjectsManagers,
      viewers_json: newOffice.viewers,
      approval_ceo: newOffice.approvalCEO,
      work_start_time: newOffice.workStartTime,
      work_end_time: newOffice.workEndTime,
      region: newOffice.region,
      contact_person_uid: newOffice.contactPersonUID,
      mission_id: newOffice.missionId,
      cache_mission_name: newOffice.cacheMissionName,
      restricted_features: newOffice.restrictedFeatures,
      shift_vacation_per_hours: newOffice.shiftVacationPerHours,
    };
    const { data: insertData, error: insertError } = await supabase.rpc(
      "add_office_with_user_updates_permissions",
      newOfficeForm
    );

    console.log({ insertError });
    if (insertError) {
      return thunkApi.rejectWithValue(insertError.message);
    }

    return insertData as office;
  }
);

// Delete an office
export const deleteOffice = createAsyncThunk<string, string, { rejectValue: string }>(
  "offices/deleteOffice",
  async (id, thunkApi) => {
    const { error } = await supabase.from("offices").delete().eq("id", id);

    if (error) {
      return thunkApi.rejectWithValue(error.message);
    }

    return id;
  }
);

// Edit an office
export const editOffice = createAsyncThunk<office, office, { rejectValue: string }>(
  "offices/editOffice",
  async (updatedOffice, thunkApi) => {
    const { data: updateData, error: updateError } = await supabase
      .from("offices")
      .update(updatedOffice)
      .eq("id", updatedOffice.id)
      .select()
      .single();

    if (updateError) {
      return thunkApi.rejectWithValue(updateError.message);
    }

    return updateData as office;
  }
);

// Real-time actions
export const addOfficeRealTime = (newOffice: office): PayloadAction<office> => ({
  type: "offices/addOfficeRealTime",
  payload: newOffice,
});

export const editOfficeRealTime = (updatedOffice: office): PayloadAction<office> => ({
  type: "offices/editOfficeRealTime",
  payload: updatedOffice,
});

export const deleteOfficeRealTime = (id: string): PayloadAction<string> => ({
  type: "offices/deleteOfficeRealTime",
  payload: id,
});

// Slice definition
const officesSlice = createSlice({
  name: "offices",
  initialState,
  reducers: {
    addOfficeRealTime(state, action: PayloadAction<office>) {
      const exists = state.data.some((office) => office.id === action.payload.id);
      if (!exists) {
        state.data.push(action.payload);
      }
      state.error = null;
    },
    editOfficeRealTime(state, action: PayloadAction<office>) {
      const index = state.data.findIndex((office) => office.id === action.payload.id);
      if (index !== -1) {
        const isDifferent = JSON.stringify(state.data[index]) !== JSON.stringify(action.payload);
        if (isDifferent) {
          state.data[index] = action.payload;
        }
      }
      state.error = null;
    },
    deleteOfficeRealTime(state, action: PayloadAction<string>) {
      const exists = state.data.some((office) => office.id === action.payload);
      if (exists) {
        state.data = state.data.filter((office) => office.id !== action.payload);
      }
      state.error = null;
    },
  },
  extraReducers: (builder) => {
    builder

      // Handle fetchAll Offices
      .addCase(fetchAllOffices.pending, (state) => {
        state.status = "loading";
      })
      .addCase(fetchAllOffices.fulfilled, (state, action: PayloadAction<office[]>) => {
        state.status = "succeeded";
        state.data = action.payload;
        state.error = null;
      })
      .addCase(fetchAllOffices.rejected, (state, action: PayloadAction<string | undefined>) => {
        state.status = "failed";
        state.error = action.payload || "Failed to fetch allocations.";
      })

      // Handle fetchOfficesInBatches
      .addCase(fetchOfficesInBatches.pending, (state) => {
        state.status = "loading";
      })
      .addCase(fetchOfficesInBatches.fulfilled, (state, action) => {
        state.loaded = true;
        action.payload.forEach((office) => {
          state.globalOfficesData[office.id ?? ""] = office;
          state.OFFICES_ID_NAME.push({
            officeId: office.id ?? "",
            officeName: office.officeName,
          });
        });
      })
      .addCase(fetchOfficesInBatches.rejected, (state, action) => {
        state.status = "failed";
        state.error = (action.payload as string) || "Failed to fetch offices.";
      })

      // Handle addOffice
      .addCase(addOffice.fulfilled, (state, action: PayloadAction<office>) => {
        state.error = null;
      })
      .addCase(addOffice.rejected, (state, action: PayloadAction<string | undefined>) => {
        state.error = action.payload || "Failed to add office.";
      })

      // Handle deleteOffice
      .addCase(deleteOffice.fulfilled, (state, action: PayloadAction<string>) => {
        state.data = state.data.filter((office) => office.id !== action.payload);
        state.error = null;
      })
      .addCase(deleteOffice.rejected, (state, action: PayloadAction<string | undefined>) => {
        state.error = action.payload || "Failed to delete office.";
      })

      // Handle editOffice
      .addCase(editOffice.fulfilled, (state, action: PayloadAction<office>) => {
        state.error = null;
      })
      .addCase(editOffice.rejected, (state, action: PayloadAction<string | undefined>) => {
        state.error = action.payload || "Failed to edit office.";
      });
  },
});

// Real-time subscription function
export const subscribeToOffices = (dispatch: any) => {
  const subscription = supabase
    .channel("public:offices")
    .on("postgres_changes", { event: "*", schema: "public", table: "offices" }, (payload) => {
      const newRecord = payload.new as office;
      const oldRecord = payload.old as office;

      switch (payload.eventType) {
        case "INSERT":
          dispatch(addOfficeRealTime(newRecord));
          break;
        case "UPDATE":
          dispatch(editOfficeRealTime(newRecord));
          break;
        case "DELETE":
          dispatch(deleteOfficeRealTime(oldRecord.id ?? ""));
          break;
        default:
          break;
      }
    })
    .subscribe();

  return () => {
    subscription.unsubscribe();
  };
};

export default officesSlice.reducer;
