import { createSlice, createAsyncThunk, PayloadAction } from "@reduxjs/toolkit";
import { supabase } from "../../supabaseClient"; // Adjust the import path as needed
import { budgetLines } from "../../db/interfaces"; // Ensure your budgetLine interface is defined here

interface budgetLinesSliceState {
  data: budgetLines[];
  status: "loading" | "succeeded" | "failed" | "idle";
  error: string | null;
  globalBudgetLinesData: Record<string, budgetLines>;
  loaded: boolean;
}

const initialState: budgetLinesSliceState = {
  data: [],
  status: "idle",
  error: null,
  globalBudgetLinesData: {},
  loaded: false,
};

// Fetch all budget lines in batches
export const fetchBudgetLinesInBatches = createAsyncThunk(
  "budgetLines/fetchBudgetLinesInBatches",
  async (_, { rejectWithValue }) => {
    const allBudgetLines: any[] = [];
    let from = 0;
    let to = 999;

    for (let i = 0; i < 2; i++) {
      const { data, error } = await supabase.from("budgetLines").select("*").range(from, to);
      if (error) {
        return rejectWithValue(error.message as string);
      }

      if (data?.length) {
        allBudgetLines.push(...data);
        from += 1000;
        to += 1000;
      } else {
        break;
      }
    }

    return allBudgetLines;
  }
);

// Fetch budgetlines records for a specific office
export const fetchBudgetLinesByProjectId = createAsyncThunk<budgetLines[], string, { rejectValue: string }>(
  "allocation/fetchBudgetLinesByProjectId",
  async (projectId, thunkApi) => {
    const { data, error } = await supabase.from("budgetLines").select("*").eq("projectId", projectId);

    if (error) {
      return thunkApi.rejectWithValue(error.message);
    }

    return data as budgetLines[];
  }
);

// fetch all budgetLines
export const fetchAllBudgetLines = createAsyncThunk<budgetLines[], void, { rejectValue: string }>(
  "allocation/fetchAllBudgetLines",
  async (_, thunkApi) => {
    const { data, error } = await supabase.from("budgetLines").select();
    if (error) {
      return thunkApi.rejectWithValue(error.message);
    }
    return data as budgetLines[];
  }
);

// Add a new budget line
export const addBudgetLine = createAsyncThunk<budgetLines, budgetLines, { rejectValue: string }>(
  "budgetLines/addBudgetLine",
  async (newBudgetLine, thunkApi) => {
    const { data: insertData, error: insertError } = await supabase
      .from("budgetLines")
      .insert(newBudgetLine)
      .select()
      .single();

    if (insertError) {
      return thunkApi.rejectWithValue(insertError.message);
    }

    return insertData as budgetLines;
  }
);

// Delete a budget line
export const deleteBudgetLine = createAsyncThunk<string, string, { rejectValue: string }>(
  "budgetLines/deleteBudgetLine",
  async (id, thunkApi) => {
    const { error } = await supabase.from("budgetLines").delete().eq("id", id);

    if (error) {
      return thunkApi.rejectWithValue(error.message);
    }

    return id;
  }
);

// Edit a budget line
export const editBudgetLine = createAsyncThunk<budgetLines, budgetLines, { rejectValue: string }>(
  "budgetLines/editBudgetLine",
  async (updatedBudgetLine, thunkApi) => {
    const { data: updateData, error: updateError } = await supabase
      .from("budgetLines")
      .update(updatedBudgetLine)
      .eq("id", updatedBudgetLine.id)
      .select()
      .single();

    if (updateError) {
      return thunkApi.rejectWithValue(updateError.message);
    }

    return updateData as budgetLines;
  }
);

// Real-time actions
export const addBudgetLineRealTime = (newBudgetLine: budgetLines): PayloadAction<budgetLines> => ({
  type: "budgetLines/addBudgetLineRealTime",
  payload: newBudgetLine,
});

export const editBudgetLineRealTime = (updatedBudgetLine: budgetLines): PayloadAction<budgetLines> => ({
  type: "budgetLines/editBudgetLineRealTime",
  payload: updatedBudgetLine,
});

export const deleteBudgetLineRealTime = (id: string): PayloadAction<string> => ({
  type: "budgetLines/deleteBudgetLineRealTime",
  payload: id,
});

// Slice definition
const budgetLinesSlice = createSlice({
  name: "budgetLines",
  initialState,
  reducers: {
    addBudgetLineRealTime(state, action: PayloadAction<budgetLines>) {
      const exists = state.data.some((budgetLine) => budgetLine.id === action.payload.id);
      if (!exists) {
        state.data.push(action.payload);
      }
      state.error = null;
    },
    editBudgetLineRealTime(state, action: PayloadAction<budgetLines>) {
      const index = state.data.findIndex((budgetLine) => budgetLine.id === action.payload.id);
      if (index !== -1) {
        const isDifferent = JSON.stringify(state.data[index]) !== JSON.stringify(action.payload);
        if (isDifferent) {
          state.data[index] = action.payload;
        }
      }
      state.error = null;
    },
    deleteBudgetLineRealTime(state, action: PayloadAction<string>) {
      const exists = state.data.some((budgetLine) => budgetLine.id === action.payload);
      if (exists) {
        state.data = state.data.filter((budgetLine) => budgetLine.id !== action.payload);
      }
      state.error = null;
    },
  },
  extraReducers: (builder) => {
    builder
      // fetch all budgetLines
      .addCase(fetchAllBudgetLines.pending, (state) => {
        state.status = "loading";
      })
      .addCase(fetchAllBudgetLines.fulfilled, (state, action: PayloadAction<budgetLines[]>) => {
        state.status = "succeeded";
        state.data = action.payload;
        state.error = null;
      })
      .addCase(fetchAllBudgetLines.rejected, (state, action: PayloadAction<string | undefined>) => {
        state.status = "failed";
        state.error = action.payload || "Failed to fetch budgetLines.";
      })

      // Handle fetchBudgetLinesInBatches
      .addCase(fetchBudgetLinesInBatches.pending, (state) => {
        state.status = "loading";
      })
      .addCase(fetchBudgetLinesInBatches.fulfilled, (state, action) => {
        state.loaded = true;
        action.payload.forEach((budgetLine) => {
          state.globalBudgetLinesData[budgetLine.id ?? ""] = budgetLine;
        });
      })
      .addCase(fetchBudgetLinesInBatches.rejected, (state, action) => {
        state.status = "failed";
        state.error = (action.payload as string) || "Failed to fetch budget lines.";
      })

      // Handle fetchAllocationsByOffice
      .addCase(fetchBudgetLinesByProjectId.pending, (state) => {
        state.status = "loading";
      })
      .addCase(fetchBudgetLinesByProjectId.fulfilled, (state, action: PayloadAction<budgetLines[]>) => {
        state.status = "succeeded";
        state.data = action.payload;
        state.error = null;
      })
      .addCase(fetchBudgetLinesByProjectId.rejected, (state, action: PayloadAction<string | undefined>) => {
        state.status = "failed";
        state.error = action.payload || "Failed to fetch allocations by office.";
      })

      // Handle addBudgetLine
      .addCase(addBudgetLine.fulfilled, (state, action: PayloadAction<budgetLines>) => {
        state.data.push(action.payload);
        state.error = null;
      })
      .addCase(addBudgetLine.rejected, (state, action: PayloadAction<string | undefined>) => {
        state.error = action.payload || "Failed to add budget line.";
      })

      // Handle deleteBudgetLine
      .addCase(deleteBudgetLine.fulfilled, (state, action: PayloadAction<string>) => {
        state.data = state.data.filter((budgetLine) => budgetLine.id !== action.payload);
        state.error = null;
      })
      .addCase(deleteBudgetLine.rejected, (state, action: PayloadAction<string | undefined>) => {
        state.error = action.payload || "Failed to delete budget line.";
      })

      // Handle editBudgetLine
      .addCase(editBudgetLine.fulfilled, (state, action: PayloadAction<budgetLines>) => {
        const index = state.data.findIndex((budgetLine) => budgetLine.id === action.payload.id);
        if (index !== -1) {
          state.data[index] = action.payload;
        }
        state.error = null;
      })
      .addCase(editBudgetLine.rejected, (state, action: PayloadAction<string | undefined>) => {
        state.error = action.payload || "Failed to edit budget line.";
      });
  },
});

// Real-time subscription function
export const subscribeToBudgetLines = (dispatch: any) => {
  const subscription = supabase
    .channel("public:budgetLines")
    .on("postgres_changes", { event: "*", schema: "public", table: "budgetLines" }, (payload) => {
      const newRecord = payload.new as budgetLines;
      const oldRecord = payload.old as budgetLines;

      switch (payload.eventType) {
        case "INSERT":
          dispatch(addBudgetLineRealTime(newRecord));
          break;
        case "UPDATE":
          dispatch(editBudgetLineRealTime(newRecord));
          break;
        case "DELETE":
          dispatch(deleteBudgetLineRealTime(oldRecord.id ?? ""));
          break;
        default:
          break;
      }
    })
    .subscribe();

  return () => {
    subscription.unsubscribe();
  };
};

export default budgetLinesSlice.reducer;
