import { Button, Col, message } from "antd";
import "./signature.css";
import React, { useState } from "react";
import SignaturePad from "react-signature-canvas";
import { v4 as uuidv4 } from "uuid";
import { useAppDispatch, useAppSelector } from "../../hooks/reduxHooks";
import { uploadFile, uploadTrimmedSignature } from "../../db/supabase-storage";
import { editUser } from "../../features/users/usersSlice";

export default function SignatureTab() {
  const { currentUser } = useAppSelector((state) => state.auth);
  const dispatch = useAppDispatch();

  const [trimmedDataURL, setTrimmedDataURL] = useState("");
  const [sigPad, setSigPad] = useState({});
  const [saveButton, setSaveButton] = useState(false);
  const uid = currentUser.id as string;

  const userData = currentUser;

  const onTirm = () => {
    setTrimmedDataURL(sigPad?.getTrimmedCanvas().toDataURL("image/png"));
    setSaveButton(true);
  };

  const onSave = async () => {
    const filePath = `signatures/${uid}/${uuidv4()}-signature.png`;

    const downloadURL = await uploadTrimmedSignature(trimmedDataURL, filePath);

    dispatch(editUser({ id: uid, signatureURL: downloadURL })).then(() =>
      message.success("Your signature has been saved well!")
    );
  };

  const handleFileChange = async (e: React.ChangeEvent<HTMLInputElement>) => {
    if (e.target.files) {
      const file: File = e.target.files[0];
      if (!file) return;
      const filePath = `signatures/${uid}/${file.name}`;
      try {
        const url = await uploadFile(file, filePath);

        if (url) {
          message.success("File uploaded successfully");
          return url;
        }
      } catch (error: any) {
        message.error(error.message);
      }
    }
  };
  return (
    <div>
      <div>
        <b>You can design your signature (removed background) by visit</b>{" "}
        <a href="https://www.remove.bg/upload" target="_blank">
          here
        </a>
      </div>
      <div>
        <b>Then Upload your removed background signature by</b>{" "}
        <div>
          <input
            type="file"
            onChange={async (e) => {
              const url = await handleFileChange(e);
              dispatch(
                editUser({
                  id: uid,
                  signatureURL: url,
                } as any)
              ).then(() => message.success(" Uploaded successfully"));
            }}
          />

          <br />
        </div>
      </div>

      <Col span={30}>
        <div className={"containerSig"}>
          <b>You can draw you signature using the pad below:</b>

          <div className={"sigContainer"}>
            <SignaturePad canvasProps={{ className: "sigPad" }} ref={(ref: any) => setSigPad(ref)} />
          </div>
          <div>
            <Button
              className={"buttons"}
              onClick={() => {
                sigPad.clear();
                setSaveButton(false);
                setTrimmedDataURL("");
              }}
            >
              Clear
            </Button>
            <Button className={"buttons"} onClick={onTirm}>
              Trim
            </Button>
            {saveButton ? (
              <Button type="primary" className={"buttons"} onClick={onSave}>
                Save
              </Button>
            ) : null}
          </div>

          <div style={{ margin: "2%" }}>
            {trimmedDataURL ? <img className={"sigImage"} src={trimmedDataURL} /> : null}
            <img className={"sigImage"} src={userData?.signatureURL ?? ""} />
          </div>
        </div>
      </Col>
    </div>
  );
}
