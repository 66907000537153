import React, { useState } from "react";
import { Spin, Button, Tag } from "antd";
import DevTable from "../../helpers/devex-table";
import { globalUsersData } from "../../global/usersList";
import { globalDepartmentsData } from "../../global/departmentList";
import { globalTasksData } from "../../global/taskList";
import { globalDepartmentPositionsData } from "../../global/departmentPositionList";
import ViewTaskDialog from "./viewTaskDialog";
import { useAppSelector } from "../../hooks/reduxHooks";

interface Props {
  filters: any;
  taskIdsFiltered: any;
}
export default function AllTasks(props: Props) {
  const { data: positionTasksData, status: positionTasksStatus } = useAppSelector((state) => state.positionTasks);

  const { filters, taskIdsFiltered } = props;
  const [modalState, setModalState] = useState(false);
  const [taskData, setTaskData] = useState({});


  if (positionTasksStatus=="loading") return <Spin />;
  const data = positionTasksData

  const filteredData = data.filter((item) => {
    if (filters.task == "" && filters.tags.length == 0 && filters.roles.length == 0) {
      return true;
    } else {
      return taskIdsFiltered.includes(item.id);
    }
  });

  const COLORS = ["#00C49F", "#FFBB28", "#0088FE", "#FF8042", "#8B008B", "#FF1493", "#B22222", "#ADD8A9"];

  const columns = [
    {
      name: "code",
      title: "Code",
      getCellValue: (row: any) => globalTasksData[row.taskId]?.code,
    },
    {
      name: "departmentId",
      title: "Department",
      getCellValue: (row: any) => globalDepartmentsData[row.departmentId]?.departmentName,
    },
    {
      name: "title",
      title: "Responsibility Title",
      getCellValue: (row: any) => globalTasksData[row.taskId]?.title,
    },

    {
      name: "positionTitle",
      title: "Position",
      getCellValue: (row: any) => globalDepartmentPositionsData[row.positionId]?.title,
    },
    {
      name: "assignedTo",
      title: "Assigned To",
      getCellValue: (row: any) =>
        globalDepartmentPositionsData[row.positionId]?.uid
          ? globalUsersData[globalDepartmentPositionsData[row.positionId]?.uid ?? ""]?.fullName
          : "Vacant",
    },
    {
      name: "role",
      title: "Roles",
      getCellValue: (row: any) => {
        return row.role?.map((r: any, index: any) => (
          <Tag style={{ marginBottom: "1%", marginTop: "1%" }} color={COLORS[index]} ref={r}>
            {r}
          </Tag>
        ));
      },
    },
    {
      name: "tags",
      title: "Tags",
      getCellValue: (row: any) => {
        return row.tags?.map((r: any) => {
          const randomIndex = Math.floor(Math.random() * COLORS.length);
          return (
            <Tag style={{ marginBottom: "1%", marginTop: "1%" }} color={COLORS[randomIndex]} ref={r}>
              {r}
            </Tag>
          );
        });
      },
    },

    {
      name: "view",
      title: "View",
      getCellValue: (row: any) => {
        return (
          <Button
            onClick={() => {
              setModalState(true);
              setTaskData(row);
            }}
            type="link"
          >
            View
          </Button>
        );
      },
    },
  ];
  const columnsExport = [];

  return (
    <div style={{ paddingTop: "2%" }}>
      <div>
        {modalState && <ViewTaskDialog modalState={modalState} setModalState={setModalState} taskData={taskData} />}
        <Tag color={"#00C49F"}>R: Responsible </Tag>
        <Tag color="#FFBB28">A: Accountable </Tag>
        <Tag color="#0088FE">C: Consulted </Tag>
        <Tag color="#FF8042">I: Informed </Tag>

        <DevTable
          data={filteredData}
          columns={columns}
          defaultHiddenColumnNames={[]}
          defaultColumnWidths={[]}
          tableName="Tasks"
          selected={false}
          typicalTable={true}
          columnsExport={[]}
        />
      </div>
    </div>
  );
}
