import React, { useEffect } from "react";
import Paper from "@material-ui/core/Paper";
import { Spin, Button } from "antd";
import { Link } from "react-router-dom";
import DevTable from "../../../helpers/devex-table";
import { useAppDispatch, useAppSelector } from "../../../hooks/reduxHooks";
import { fetchOrdersByType, subscribeToOrders } from "../../../features/orders/ordersSlice";
import { globalUsersData } from "../../../global/usersList";
import moment from "moment";

interface Props {
  type: string;
}
export default function WorkPermitsTable(props: Props) {
  const { type } = props;
  const { data: workPermits, status, error } = useAppSelector((state) => state.orders);
  const dispatch = useAppDispatch();
  useEffect(() => {
    dispatch(fetchOrdersByType(type));
    const unsubscribe = subscribeToOrders(dispatch);
    return () => {
      unsubscribe();
    };
  }, [dispatch, type]);

  if (status == "loading") return <Spin />;

  const data = workPermits.map((d) => {
    return { ...d, id: d.id };
  });
  const columns = [
    {
      name: "requestedAt",
      title: "Requested At",
      getCellValue: (row) => {
        return moment(row.requestedAt).format("DD.MM.YYYY");
      },
    },
    { name: "status", title: "Status" },
    { name: "TC", title: "TC" },
    { name: "firstName", title: "First Name" },
    { name: "lastName", title: "Last Name" },
    { name: "fatherName", title: "Father Name" },
    { name: "motherName", title: "Mother Name" },
    { name: "gender", title: "Gender" },
    { name: "birthPlace", title: "Birth Place" },
    {
      name: "birthDate",
      title: "Birth Date",
      getCellValue: (row) => {
        return moment(row.birthDate).format("DD.MM.YYYY");
      },
    },
    { name: "marriedStatus", title: "Married Status" },
    { name: "nationality", title: "Nationality" },
    { name: "femaleChildrenNumber", title: "Female Children Number" },
    { name: "maleChildrenNumber", title: "Male Children Number" },
    { name: "wifeNationality", title: "Wife Nationality" },
    { name: "wifeFirstName", title: "Wife FirstName" },
    { name: "wifeLastName", title: "Wife LastName" },
    { name: "wifeTC", title: "Wife TC" },
    { name: "phone", title: "Phone" },
    { name: "email", title: "Email" },
    { name: "residenceAddress", title: "Residence Address" },
    { name: "universityNameAddress", title: "University Name Address" },
    { name: "degree", title: "Degree" },
    { name: "degreeField", title: "Degree Field" },
    { name: "degreeSpecialization", title: "Degree Specialization" },
    { name: "passportNO", title: "PassportNO" },
    {
      name: "passportExpiryDate",
      title: "Passport Expiry Date",
      getCellValue: (row) => {
        return moment(row.passportExpiryDate).format("DD.MM.YYYY");
      },
    },
    { name: "passportAuth", title: "Passport Auth" },
    { name: "position", title: "Position" },
    { name: "salary", title: "Salary" },
    {
      name: "manager",
      title: "Submitted By",
      getCellValue: (row) => globalUsersData[row.manager ?? ""]?.fullName,
    },
    {
      name: "startWorkDate",
      title: "Start Date",
      getCellValue: (row) => {
        return row.startWorkDate == undefined ? "Not Active Yet" : moment(row.startWorkDate).format("DD.MM.YYYY");
      },
    },
    {
      name: "endWorkDate",
      title: "Leave Date",
      getCellValue: (row) => {
        return row.endWorkDate == undefined ? null : moment(row.endWorkDate).format("DD.MM.YYYY");
      },
    },

    {
      name: "permitExpiryDate",
      title: "Permit Expiry Date",
      getCellValue: (row) => {
        return row.permitExpiryDate == undefined ? null : moment(row.permitExpiryDate).format("DD.MM.YYYY");
      },
    },
    {
      name: "control",
      title: "Control",
      getCellValue: (row) => {
        return <Link to={`/order/${row.id}`}>View</Link>;
      },
    },
    {
      name: "signedContractURL",
      title: "Signed Contract",
      getCellValue: (row) => {
        return row.signedContractURL == "" || row.signedContractURL == undefined ? (
          "Nothing Uploaded"
        ) : (
          <Button
            onClick={() => {
              var link = document.createElement("a");
              if (link.download !== undefined) {
                link.setAttribute("href", row.signedContractURL);
                link.setAttribute("target", "_blank");
                link.style.visibility = "hidden";
                document.body.appendChild(link);
                link.click();
                document.body.removeChild(link);
              }
            }}
          >
            Download
          </Button>
        );
      },
    },
    {
      name: "imgURL",
      title: "New Permit Card",
      getCellValue: (row) => {
        return row.imgURL == "" || row.imgURL == undefined ? (
          "Nothing Uploaded"
        ) : (
          <Button
            onClick={() => {
              var link = document.createElement("a");
              if (link.download !== undefined) {
                link.setAttribute("href", row.imgURL);
                link.setAttribute("target", "_blank");
                link.style.visibility = "hidden";
                document.body.appendChild(link);
                link.click();
                document.body.removeChild(link);
              }
            }}
          >
            Download
          </Button>
        );
      },
    },
    {
      name: "permitCardPhotoURL",
      title: "Previous Permit Card",
      getCellValue: (row) => {
        return row.permitCardPhotoURL == "" || row.permitCardPhotoURL == undefined ? (
          "Nothing Uploaded"
        ) : (
          <Button
            onClick={() => {
              var link = document.createElement("a");
              if (link.download !== undefined) {
                link.setAttribute("href", row.permitCardPhotoURL);
                link.setAttribute("target", "_blank");
                link.style.visibility = "hidden";
                document.body.appendChild(link);
                link.click();
                document.body.removeChild(link);
              }
            }}
          >
            Download
          </Button>
        );
      },
    },
    {
      name: "passportPhotoURL",
      title: "Passport",
      getCellValue: (row) => {
        return row.passportPhotoURL == "" || row.passportPhotoURL == undefined ? (
          "Nothing Uploaded"
        ) : (
          <Button
            onClick={() => {
              var link = document.createElement("a");
              if (link.download !== undefined) {
                link.setAttribute("href", row.passportPhotoURL);
                link.setAttribute("target", "_blank");
                link.style.visibility = "hidden";
                document.body.appendChild(link);
                link.click();
                document.body.removeChild(link);
              }
            }}
          >
            Download
          </Button>
        );
      },
    },
    {
      name: "diplomaURL",
      title: "Diploma",
      getCellValue: (row) => {
        return row.diplomaURL == "" || row.diplomaURL == undefined ? (
          "Nothing Uploaded"
        ) : (
          <Button
            onClick={() => {
              var link = document.createElement("a");
              if (link.download !== undefined) {
                link.setAttribute("href", row.diplomaURL);
                link.setAttribute("target", "_blank");
                link.style.visibility = "hidden";
                document.body.appendChild(link);
                link.click();
                document.body.removeChild(link);
              }
            }}
          >
            Download
          </Button>
        );
      },
    },
    {
      name: "personalPhoto",
      title: "Personal Photo",
      getCellValue: (row) => {
        return row.personalPhoto == "" || row.personalPhoto == undefined ? (
          "Nothing Uploaded"
        ) : (
          <Button
            onClick={() => {
              var link = document.createElement("a");
              if (link.download !== undefined) {
                link.setAttribute("href", row.personalPhoto);
                link.setAttribute("target", "_blank");
                link.style.visibility = "hidden";
                document.body.appendChild(link);
                link.click();
                document.body.removeChild(link);
              }
            }}
          >
            Download
          </Button>
        );
      },
    },
  ];
  const columnsExport = [
    {
      name: "requestedAt",
      title: "Requested At",
      getCellValue: (row) => {
        return row.requestedAt;
      },
    },
    { name: "status", title: "Status" },
    { name: "TC", title: "TC" },
    { name: "firstName", title: "First Name" },
    { name: "lastName", title: "Last Name" },
    { name: "fatherName", title: "Father Name" },
    { name: "motherName", title: "Mother Name" },
    { name: "gender", title: "Gender" },
    { name: "birthPlace", title: "Birth Place" },
    {
      name: "birthDate",
      title: "Birth Date",
      getCellValue: (row) => {
        return row.birthDate;
      },
    },
    { name: "marriedStatus", title: "Married Status" },
    { name: "nationality", title: "Nationality" },
    { name: "femaleChildrenNumber", title: "Female Children Number" },
    { name: "maleChildrenNumber", title: "Male Children Number" },
    { name: "wifeNationality", title: "Wife Nationality" },
    { name: "wifeFirstName", title: "Wife FirstName" },
    { name: "wifeLastName", title: "Wife LastName" },
    { name: "wifeTC", title: "Wife TC" },
    { name: "phone", title: "Phone" },
    { name: "email", title: "Email" },
    { name: "residenceAddress", title: "Residence Address" },
    { name: "universityNameAddress", title: "University Name Address" },
    { name: "degree", title: "Degree" },
    { name: "degreeField", title: "Degree Field" },
    { name: "degreeSpecialization", title: "Degree Specialization" },
    { name: "passportNO", title: "PassportNO" },
    {
      name: "passportExpiryDate",
      title: "Passport Expiry Date",
      getCellValue: (row) => {
        return row.passportExpiryDate;
      },
    },
    { name: "passportAuth", title: "Passport Auth" },
    { name: "position", title: "Position" },
    { name: "salary", title: "Salary" },

    {
      name: "manager",
      title: "Submitted By",
      getCellValue: (row) => globalUsersData[row.manager ?? ""]?.fullName,
    },

    {
      name: "startWorkDate",
      title: "Start Date",
      getCellValue: (row) => {
        return row.startWorkDate == undefined ? null : row.startWorkDate;
      },
    },
    {
      name: "endWorkDate",
      title: "Leave Date",
      getCellValue: (row) => {
        return row.endWorkDate == undefined ? null : row.endWorkDate;
      },
    },
    {
      name: "permitExpiryDate",
      title: "Permit Expiry Date",
      getCellValue: (row) => {
        return row.permitExpiryDate == undefined ? null : row.permitExpiryDate;
      },
    },
    {
      name: "signedContractURL",
      title: "Signed Contract",
    },
    {
      name: "imgURL",
      title: "New Permit Card",
    },
    {
      name: "permitCardPhotoURL",
      title: "Previous Permit Card",
    },
    {
      name: "passportPhotoURL",
      title: "Passport",
    },
    {
      name: "diplomaURL",
      title: "Diploma",
    },
    {
      name: "personalPhoto",
      title: "Personal Photo",
    },
  ];
  return error ? (
    <p>{error}</p>
  ) : (
    <Paper>
      <DevTable
        data={data}
        columns={columns}
        defaultHiddenColumnNames={[]}
        tableName={type}
        selected={false}
        typicalTable={false}
        columnsExport={columnsExport}
      />
    </Paper>
  );
}
