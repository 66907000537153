import React, { useState, useEffect, useRef } from "react";
import { Button, Drawer, Form, message, Select, Input, Icon, Row, Spin } from "antd";
const { Option } = Select;
import { useFormik } from "formik";
import * as Yup from "yup";
import { useTranslation } from "react-i18next";
import DraftText from "../Meetings_Rooms/TextEditor/Draft";
import { stateToHTML } from "draft-js-export-html";
import { stateFromHTML } from "draft-js-import-html";
import { EditorState } from "draft-js";
import { globalUsersData, USERS_ID_FULL_NAME } from "../../global/usersList";
import { useAppDispatch, useAppSelector } from "../../hooks/reduxHooks";
import { addDepartmentPosition } from "../../features/departmentPositions/departmentPositionsSlice";
import moment from "moment";
import { uploadFile } from "../../db/supabase-storage";

interface Props {
  modalState: boolean;
  setModalState: React.Dispatch<React.SetStateAction<boolean>>;
  departmentId: string;
}
const projectSchema = Yup.object().shape({
  title: Yup.string().required("Position title is required"),
});

function AddNewDepartmentPosition(props: Props) {
  const { departmentPositionsByDepartmentId: departmentPositionsData, status: departmentPositionsStatus } =
    useAppSelector((state) => state.departmentPositions);

  const { currentUser } = useAppSelector((state) => state.auth);
  const dispatch = useAppDispatch();

  const { t } = useTranslation();
  const { modalState, setModalState, departmentId } = props;
  const [loading, setLoading] = useState(false);
  const [uploadJobDescriptionFiles, setUploadJobDescriptionFiles] = useState([] as any);
  const [editorState, setEditorState] = useState<EditorState>(EditorState.createWithContent(stateFromHTML("")));
  const html = stateToHTML(editorState.getCurrentContent());

  const uid = currentUser.id as string;
  const fileInputRef = useRef(null);
  const handleButtonClick = () => {
    if (fileInputRef.current) {
      fileInputRef.current.click();
    }
  };

  const formik = useFormik({
    initialValues: {
      uid: null,
      title: "",
      referencePositionId: null,
      departmentId: "",
      overview: "",
      attached_job_description: [],
      positionTasks: [],
      orientational_video: "",
    },
    validationSchema: projectSchema,
    onSubmit: async (values) => {
      setLoading(true);
      const promise = uploadJobDescription();
      promise.then((files) => {
        try {
          dispatch(
            addDepartmentPosition({
              uid: values.uid ?? null,
              title: values.title,
              referencePositionId: values.referencePositionId ?? null,
              departmentId: departmentId,
              overview: html,
              attached_job_description: files ?? [],
              positionTasks: [],
              orientational_video: "",
              createdAt: moment() as any,
              by: uid,
            })
          ).then(() => {
            setModalState(false);
            setLoading(false);
            message.success("New Position is added successfully!");
            formik.resetForm();
          });
        } catch (error) {
          setLoading(false);
          console.error("Error writing document: ", error);
          message.error("Failed");
        }
      });
    },
  });
  useEffect(() => {
    return () => {
      if (formik.isValid == false) {
        setLoading(false);
      }
    };
  }, [formik.isValid]);

  const positions = departmentPositionsData;

  const handleFileUpload = async (files: any) => {
    try {
      const downloadURLs = await Promise.all(
        files.map(async (file: any) => {
          const filePath = `departments/${departmentId}/positions/${file.name}`;
          try {
            const url = await uploadFile(file, filePath);

            if (url) {
              message.success("File uploaded successfully");

              return {
                name: file.name,
                url: url,
              };
            }
          } catch (error: any) {
            message.error(error.message);
          }
        })
      );
      return downloadURLs;
    } catch (error) {
      console.error("Error uploading files:", error);
      return [];
    }
  };

  const uploadJobDescription = async () => {
    setLoading(true);
    const promise = handleFileUpload(uploadJobDescriptionFiles);
    return promise;
  };

  const antIcon = <Icon type="loading" style={{ fontSize: 75 }} spin />;
  return (
    <Drawer
      title={t("general.addNewPosition")}
      width={700}
      height={350}
      visible={modalState}
      placement={"right"}
      onClose={() => setModalState(false)}
    >
      <Spin spinning={loading} size="large" indicator={antIcon}>
        <Form onSubmit={formik.handleSubmit}>
          <Form.Item label={t("general.positionTitle")}>
            <Input value={formik.values.title} onChange={(e) => formik.setFieldValue("title", e.target.value)} />
          </Form.Item>
          <Form.Item label={t("general.referencePosition")}>
            <Select
              filterOption={(inputValue, option) => {
                return (option.props.children as any).toLowerCase().indexOf(inputValue.toLowerCase()) >= 0;
              }}
              allowClear
              size="default"
              placeholder="Parent Position"
              showSearch
              value={formik.values.referencePositionId}
              onChange={(e) => {
                formik.setFieldValue("referencePositionId", e);
              }}
            >
              {positions?.map((d) => {
                return (
                  <Option key={d.id} value={d.id}>{`${d.title} - ${
                    d.uid != null ? globalUsersData[d.uid ?? ""]?.fullName : "Vacant"
                  }`}</Option>
                );
              })}
            </Select>
          </Form.Item>
          <Form.Item label={t("general.overview")}>
            <DraftText
              onValueChange={() => {}}
              onMentionChange={() => {}}
              html={""}
              editorState={editorState}
              setEditorState={setEditorState}
            />
          </Form.Item>

          <Form.Item label={t("general.assignTo")}>
            <Select
              filterOption={(inputValue, option) => {
                return (option.props.children as any).toLowerCase().indexOf(inputValue.toLowerCase()) >= 0;
              }}
              allowClear
              placeholder="Assign to Employee"
              showSearch
              value={formik.values.uid}
              onChange={(e) => {
                formik.setFieldValue("uid", e);
              }}
            >
              {USERS_ID_FULL_NAME?.map((d) => {
                return <Option key={d.uid} value={d.uid}>{d.fullName}</Option>;
              })}
            </Select>
          </Form.Item>

          <Form.Item label="Job Description File">
            <Row gutter={12}>
              <Button onClick={() => handleButtonClick()}>
                <Icon type="upload" /> {`Click to upload ${uploadJobDescriptionFiles.length}`}
                <input
                  ref={fileInputRef} // Attach the ref to the input
                  hidden
                  type="file"
                  multiple
                  onChange={async (e) => {
                    if (e.target.files) {
                      setUploadJobDescriptionFiles([...Object.values(e?.target?.files as any)]);
                    }
                  }}
                />
              </Button>
            </Row>
          </Form.Item>

          <div>
            <Button
              className="btn blue lighten-1 z-depth-0"
              style={{ marginTop: "2%" }}
              type="primary"
              htmlType="submit"
            >
              {t("general.create")}
            </Button>
          </div>
        </Form>
      </Spin>
    </Drawer>
  );
}

export default AddNewDepartmentPosition;
