import { createSlice, createAsyncThunk, PayloadAction } from "@reduxjs/toolkit";
import { supabase } from "../../supabaseClient"; // Adjust the import path as needed
import { delay } from "../../db/interfaces"; // Adjust the import path as needed

interface DelaySliceState {
  data: delay[];
  status: "loading" | "succeeded" | "failed" | "idle";
  error: string | null;
}

const initialState: DelaySliceState = {
  data: [],
  status: "idle",
  error: null,
};

// Fetch all delay records
export const fetchAllDelays = createAsyncThunk<delay[], void, { rejectValue: string }>(
  "delay/fetchAllDelays",
  async (_, thunkApi) => {
    const { data, error } = await supabase.from("delays").select("*");
    if (error) {
      return thunkApi.rejectWithValue(error.message);
    }
    return data as delay[];
  }
);

// Fetch all delay by office

export const fetchDelaysByOffice = createAsyncThunk<delay[], string, { rejectValue: string }>(
  "delay/fetchDelaysByOffice",
  async (officeId, thunkApi) => {
    const { data, error } = await supabase.from("delays").select("*").eq("office", officeId);

    if (error) {
      return thunkApi.rejectWithValue(error.message);
    }

    return data as delay[];
  }
);

// Fetch all delay by office and month
export const fetchDelaysByOfficeAndMonth = createAsyncThunk<
  delay[],
  { officeId: string; month: string },
  { rejectValue: string }
>("workOffice/fetchDelaysByOfficeAndMonth", async ({ officeId, month }, thunkApi) => {
  const { data, error } = await supabase.from("delays").select("*").eq("office", officeId).eq("month", month);

  if (error) {
    return thunkApi.rejectWithValue(error.message);
  }

  return data as delay[];
});

// Fetch all delay by month
export const fetchDelaysByMonth = createAsyncThunk<delay[], { month: string }, { rejectValue: string }>(
  "workOffice/fetchDelaysByMonth",
  async ({ month }, thunkApi) => {
    const { data, error } = await supabase.from("delays").select("*").eq("month", month);

    if (error) {
      return thunkApi.rejectWithValue(error.message);
    }

    return data as delay[];
  }
);

// Fetch all delay by user and month
export const fetchDelaysByUserIdAndMonth = createAsyncThunk<
  delay[],
  { userId: string; month: string },
  { rejectValue: string }
>("workOffice/fetchDelaysByUserIdAndMonth", async ({ userId, month }, thunkApi) => {
  const { data, error } = await supabase.from("delays").select("*").eq("userUID", userId).eq("month", month);

  if (error) {
    return thunkApi.rejectWithValue(error.message);
  }

  return data as delay[];
});

// Fetch all delay by office and user id
export const fetchDelaysByUserId = createAsyncThunk<delay[], any, { rejectValue: string }>(
  "access/fetchDelaysByUserId",
  async (userId, thunkApi) => {
    const { data, error } = await supabase.from("delays").select("*").eq("userUID", userId);

    if (error) {
      return thunkApi.rejectWithValue(error.message);
    }

    return data as delay[];
  }
);

// Add a new delay record
export const addDelay = createAsyncThunk<delay, delay, { rejectValue: string }>(
  "delay/addDelay",
  async (newDelay, thunkApi) => {
    const { data: insertData, error: insertError } = await supabase.from("delays").insert(newDelay).select().single();

    if (insertError) {
      return thunkApi.rejectWithValue(insertError.message);
    }

    return insertData as delay;
  }
);

// Edit a delay record
export const editDelay = createAsyncThunk<delay, delay, { rejectValue: string }>(
  "delay/editDelay",
  async (updatedDelay, thunkApi) => {
    const { data: updateData, error: updateError } = await supabase
      .from("delays")
      .update(updatedDelay)
      .eq("id", updatedDelay.id)
      .select()
      .single();

    if (updateError) {
      return thunkApi.rejectWithValue(updateError.message);
    }

    return updateData as delay;
  }
);

// Delete a delay record
export const deleteDelay = createAsyncThunk<string, string, { rejectValue: string }>(
  "delay/deleteDelay",
  async (id, thunkApi) => {
    const { error } = await supabase.from("delays").delete().eq("id", id);

    if (error) {
      return thunkApi.rejectWithValue(error.message);
    }

    return id;
  }
);

// Real-Time Actions
export const addDelayRealTime = (newDelay: delay): PayloadAction<delay> => ({
  type: "delay/addDelayRealTime",
  payload: newDelay,
});

export const editDelayRealTime = (updatedDelay: delay): PayloadAction<delay> => ({
  type: "delay/editDelayRealTime",
  payload: updatedDelay,
});

export const deleteDelayRealTime = (id: string): PayloadAction<string> => ({
  type: "delay/deleteDelayRealTime",
  payload: id,
});

// Slice definition
const delaySlice = createSlice({
  name: "delay",
  initialState,
  reducers: {
    addDelayRealTime(state, action: PayloadAction<delay>) {
      const exists = state.data.some((delay) => delay.id === action.payload.id);
      if (!exists) {
        state.data.push(action.payload);
      }
      state.error = null;
    },
    editDelayRealTime(state, action: PayloadAction<delay>) {
      const index = state.data.findIndex((delay) => delay.id === action.payload.id);
      if (index !== -1) {
        const isDifferent = JSON.stringify(state.data[index]) !== JSON.stringify(action.payload);
        if (isDifferent) {
          state.data[index] = action.payload;
        }
      }
      state.error = null;
    },
    deleteDelayRealTime(state, action: PayloadAction<string>) {
      const exists = state.data.some((delay) => delay.id === action.payload);
      if (exists) {
        state.data = state.data.filter((delay) => delay.id !== action.payload);
      }
      state.error = null;
    },
  },
  extraReducers: (builder) => {
    builder
      // Handle fetchAllDelays
      .addCase(fetchAllDelays.pending, (state) => {
        state.status = "loading";
      })
      .addCase(fetchAllDelays.fulfilled, (state, action: PayloadAction<delay[]>) => {
        state.status = "succeeded";
        state.data = action.payload;
        state.error = null;
      })
      .addCase(fetchAllDelays.rejected, (state, action: PayloadAction<string | undefined>) => {
        state.status = "failed";
        state.error = action.payload || "Failed to fetch delays.";
      })
      // Handle fetchDelaysByOffice
      .addCase(fetchDelaysByOffice.pending, (state) => {
        state.status = "loading";
      })
      .addCase(fetchDelaysByOffice.fulfilled, (state, action: PayloadAction<delay[]>) => {
        state.status = "succeeded";
        state.data = action.payload;
        state.error = null;
      })
      .addCase(fetchDelaysByOffice.rejected, (state, action: PayloadAction<string | undefined>) => {
        state.status = "failed";
        state.error = action.payload || "Failed to fetch delays by office.";
      })

      // Handle addDelay
      .addCase(addDelay.pending, (state) => {})
      .addCase(addDelay.fulfilled, (state, action: PayloadAction<delay>) => {
        state.data.push(action.payload);
        state.error = null;
      })
      .addCase(addDelay.rejected, (state, action: PayloadAction<string | undefined>) => {
        state.error = action.payload || "Failed to add delay.";
      })

      // Handle editDelay
      .addCase(editDelay.pending, (state) => {})
      .addCase(editDelay.fulfilled, (state, action: PayloadAction<delay>) => {
        const index = state.data.findIndex((delay) => delay.id === action.payload.id);
        if (index !== -1) {
          state.data[index] = action.payload;
        }
        state.error = null;
      })
      .addCase(editDelay.rejected, (state, action: PayloadAction<string | undefined>) => {
        state.status = "failed";
        state.error = action.payload || "Failed to edit delay.";
      })

      // Handle deleteDelay
      .addCase(deleteDelay.pending, (state) => {})
      .addCase(deleteDelay.fulfilled, (state, action: PayloadAction<string>) => {
        state.data = state.data.filter((delay) => delay.id !== action.payload);
        state.error = null;
      })
      .addCase(deleteDelay.rejected, (state, action: PayloadAction<string | undefined>) => {
        state.error = action.payload || "Failed to delete delay.";
      })

      // Handle fetchDelaysByOfficeAndMonth
      .addCase(fetchDelaysByOfficeAndMonth.pending, (state) => {
        state.status = "loading";
      })
      .addCase(fetchDelaysByOfficeAndMonth.fulfilled, (state, action: PayloadAction<delay[]>) => {
        state.status = "succeeded";
        state.data = action.payload;
        state.error = null;
      })
      .addCase(fetchDelaysByOfficeAndMonth.rejected, (state, action: PayloadAction<string | undefined>) => {
        state.status = "failed";
        state.error = action.payload || "Failed to fetch delays by office.";
      })

      // Handle fetchDelaysByMonth
      .addCase(fetchDelaysByMonth.pending, (state) => {
        state.status = "loading";
      })
      .addCase(fetchDelaysByMonth.fulfilled, (state, action: PayloadAction<delay[]>) => {
        state.status = "succeeded";
        state.data = action.payload;
        state.error = null;
      })
      .addCase(fetchDelaysByMonth.rejected, (state, action: PayloadAction<string | undefined>) => {
        state.status = "failed";
        state.error = action.payload || "Failed to fetch delays by office.";
      })

      // Handle fetchDelaysByUserIdAndMonth
      .addCase(fetchDelaysByUserIdAndMonth.pending, (state) => {
        state.status = "loading";
      })
      .addCase(fetchDelaysByUserIdAndMonth.fulfilled, (state, action: PayloadAction<delay[]>) => {
        state.status = "succeeded";
        state.data = action.payload;
        state.error = null;
      })
      .addCase(fetchDelaysByUserIdAndMonth.rejected, (state, action: PayloadAction<string | undefined>) => {
        state.status = "failed";
        state.error = action.payload || "Failed to fetch delays by office.";
      })

      // Handle fetchDelaysByUserId
      .addCase(fetchDelaysByUserId.pending, (state) => {
        state.status = "loading";
      })
      .addCase(fetchDelaysByUserId.fulfilled, (state, action: PayloadAction<delay[]>) => {
        state.status = "succeeded";
        state.data = action.payload;
        state.error = null;
      })
      .addCase(fetchDelaysByUserId.rejected, (state, action: PayloadAction<string | undefined>) => {
        state.status = "failed";
        state.error = action.payload || "Failed to fetch delays by office.";
      });
  },
});

// Real-time subscription function
export const subscribeToDelays = (dispatch: any) => {
  const subscription = supabase
    .channel("public:delays")
    .on("postgres_changes", { event: "*", schema: "public", table: "delays" }, (payload) => {
      const newRecord = payload.new as delay;
      const oldRecord = payload.old as delay;
      switch (payload.eventType) {
        case "INSERT":
          dispatch(addDelayRealTime(newRecord));
          break;
        case "UPDATE":
          dispatch(editDelayRealTime(newRecord));
          break;
        case "DELETE":
          dispatch(deleteDelayRealTime(oldRecord.id ?? ""));
          break;
        default:
          break;
      }
    })
    .subscribe();

  return () => {
    subscription.unsubscribe();
  };
};

export default delaySlice.reducer;
