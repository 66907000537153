import React from "react";
import OrderList from "../orders/OrderList";
import { Route, RouteComponentProps } from "react-router";
import OrderDetails from "../orders/OrderDetails";
import { Row, Col } from "antd";
import { Container } from "@material-ui/core";

export default function OrderDashboard() {
  return (
    <div>
      <Container maxWidth="xl">
        <div>
          <Row gutter={24} style={{ alignItems: "center" }}>
            <Col span={4}>
              <OrderList />
            </Col>
            <Col span={14} style={{ margin: "1%" }}>
              <Route
                path="/orders/order/:id"
                component={(props: RouteComponentProps<{ id: string }>) => (
                  <OrderDetails key={props.match.params.id} {...props} />
                )}
              />
            </Col>
          </Row>
        </div>
      </Container>
    </div>
  );
}
