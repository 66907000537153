import React, { useEffect, useState } from "react";
import OrderSummary from "./OrderSummary";
import { Button, Select } from "antd";
import { sortBy, uniq } from "lodash";
import moment from "moment";
import { formatMMM } from "../../db/dateFormat";
import { useAppDispatch, useAppSelector } from "../../hooks/reduxHooks";
import { fetchAllOrders, subscribeToOrders } from "../../features/orders/ordersSlice";
const { Option } = Select;

export default function OrderList() {
  const { data: ordersData, status: ordersStatus, error: ordersError } = useAppSelector((state) => state.orders);
  const { currentUser } = useAppSelector((state) => state.auth);
  const dispatch = useAppDispatch();

  const uid = currentUser?.id as string;
  const [employeeFullName, setEmployeeFullName] = useState("Search here!");

  const userAdminLaw = currentUser.adminLaw;

  useEffect(() => {
    dispatch(fetchAllOrders());

    const unsubscribeToOrders = subscribeToOrders(dispatch);
    return () => {
      unsubscribeToOrders();
    };
  }, [dispatch]);

  if (userAdminLaw) {
    const ordersDataMap = ordersData.map((v: any) => {
      return { ...v, id: v.id, fullName: v.firstName + " " + v.lastName };
    });
    const sortedList = sortBy(ordersDataMap, (item) => item?.requestedAt).reverse();

    return (
      <div>
        <Select
          filterOption={(inputValue, option) => {
            return (option.props.children as any).toLowerCase().indexOf(inputValue.toLowerCase()) >= 0;
          }}
          size="default"
          placeholder="Search for Name!"
          style={{ width: "100%", marginTop: "4%" }}
          showSearch
          value={employeeFullName}
          onChange={(e: string) => {
            setEmployeeFullName(e);
          }}
        >
          {uniq(sortedList?.map((d) => d.fullName)).map((fullName) => {
            return <Option value={fullName}>{fullName}</Option>;
          })}
        </Select>
        {employeeFullName != "Search here!" && (
          <Button
            type="default"
            style={{ margin: "1%", width: "98%", alignItems: "center", background: "#ffa500", color: "white" }}
            onClick={() => {
              setEmployeeFullName("Search here!");
            }}
          >
            Show All
          </Button>
        )}
        {sortedList
          .filter((o) => {
            if (employeeFullName == "Search here!") {
              return true;
            } else {
              return o.fullName == employeeFullName;
            }
          })
          .map((doc: any) => {
            const data = doc;
            return (
              <OrderSummary
                key={doc.id}
                id={doc.id}
                firstName={doc.firstName}
                lastName={doc.lastName}
                permitExpiryDate={moment(data?.permitExpiryDate).format(formatMMM)}
                jobStartDate={moment(data?.jobStartDate as any).format(formatMMM)}
                status={doc.status}
                firstTime={doc.firstTime}
              />
            );
          })}
      </div>
    );
  } else {
    const valueSN = ordersData.filter((order) => order.manager == uid);
    const sortedList = sortBy(valueSN, (item) => item.requestedAt).reverse();

    return (
      <div>
        {sortedList.map((doc: any) => {
          const data = doc;
          return (
            <OrderSummary
              key={doc.id}
              id={doc.id}
              firstName={doc.firstName}
              lastName={doc.lastName}
              permitExpiryDate={moment(data?.permitExpiryDate).format(formatMMM)}
              jobStartDate={moment(data?.jobStartDate).format(formatMMM)}
              status={doc.status}
              firstTime={doc.firstTime}
            />
          );
        })}
      </div>
    );
  }
}
