import { fetchAllTasks, subscribeToTasks } from "../features/tasks/tasksSlice";
import { RootState, store } from "../store";

const initializeTasksData = async () => {
  const state: RootState = store.getState();
  const { loaded } = state.tasks;

  // Only dispatch fetch if the data hasn't been loaded yet
  if (!loaded) {
    await store.dispatch(fetchAllTasks());
  }
};

// Immediately call to ensure data is fetched
initializeTasksData();
subscribeToTasks(store.dispatch);

export const getTasksData = () => {
  const state: RootState = store.getState();
  const { globalTasksData, loaded } = state.tasks;
  // if (!loaded) {
  //   console.warn("Tasks data is not loaded yet.");
  // }

  return {
    globalTasksData,
  };
};

// Initial export values, populated from the Redux store
export let globalTasksData = getTasksData().globalTasksData;

// Subscribe to store updates and dynamically update exports when the Redux state changes
store.subscribe(() => {
  const { globalTasksData: updatedGlobalTasksData } = getTasksData();

  // Update the exports with the latest values from the Redux store
  globalTasksData = updatedGlobalTasksData;
});
