import React, { useEffect, useState } from "react";
import { Button, Spin, Icon, Modal, Popconfirm, Row, Col, Divider, message } from "antd";
import { useTranslation } from "react-i18next";
import SendMOM from "./SendMOMDialog";
import { Calendar } from "../../db/interfaces";
import Editor from "../Editor/Editor";
import { API, OutputData } from "@editorjs/editorjs";
import moment from "moment";
import { orderBy } from "lodash";
import { dataToHTML } from "../Editor/constants";
import { useAppDispatch, useAppSelector } from "../../hooks/reduxHooks";
import { editCalendarEvent, fetchCalendarEventsByOriginalIdAndStartDate } from "../../features/calendar/calendarSlice";
import { uploadFile } from "../../db/supabase-storage";
const antIcon = <Icon type="loading" style={{ fontSize: 24 }} spin />;

interface Props {
  modalStateMOM: boolean;
  setModalStateMOM: React.Dispatch<React.SetStateAction<boolean>>;
  doc: any;
  MOM: string;
}

function AddMOM(props: Props) {
  const { originalMeetings, status } = useAppSelector((state) => state.calendar);
  const { currentUser } = useAppSelector((state) => state.auth);
  const dispatch = useAppDispatch();

  const { t } = useTranslation();
  const { modalStateMOM, setModalStateMOM, doc } = props;
  const [modalStateSendMOM, setModalStateSendMOM] = useState(false);
  const [files, setFiles] = useState([]);
  const [downloadURLs, setDownloadURLs] = useState([]);
  const [mentions, setMentions] = useState(Array);
  const [uploadingAttach, setUploadingAttach] = useState(false);
  const [instance, setInstance] = useState({} as API);
  const [editorContent, setEditorContent] = useState(null as any);

  const [instancePrevious, setInstancePrevious] = useState({} as API);
  const [instanceAgenda, setInstanceAgenda] = useState({} as API);

  const uid = currentUser.id as string;
  const MOMObj = doc.MOMObj;
  const MOM = doc.MOM;
  const originalId = doc.originalId;
  useEffect(() => {
    dispatch(
      fetchCalendarEventsByOriginalIdAndStartDate({
        originalId: originalId,
        startDate: moment(doc.startDate).format("YYYY-MM-DD"),
      })
    );
  }, [dispatch]);

  const handleEditorSave = async () => {
    const outputData: OutputData = await instance?.saver?.save();
    setEditorContent(outputData);
  };

  const handlePreviousEditorSave = async () => {
    const outputData: OutputData = await instancePrevious?.saver?.save();
    if (lastMeetingWithMOM && outputData) {
      await dispatch(
        editCalendarEvent({
          id: lastMeetingWithMOM.id,
          MOM: dataToHTML(outputData as OutputData),
          MOMObj: outputData,
          MOMWriter: uid,
          sendMOM: false,
        } as any)
      );
    }
  };

  const handleAgendaEditorSave = async () => {
    const outputData: OutputData = await instanceAgenda?.saver?.save();
    if (outputData) {
      await dispatch(
        editCalendarEvent({
          id: doc.id,
          agenda: dataToHTML(outputData as OutputData),
          agendaObj: outputData,
        } as any)
      );
    }
  };

  if (status == "loading") {
    return <p>loadingmm</p>;
  }
  const allMeetingsDataFromOriginal = originalMeetings;

  const meetingsWithMOM = allMeetingsDataFromOriginal?.filter((meeting: any) => meeting.MOM !== undefined);

  const sortedMeetingsWithMOM = orderBy(meetingsWithMOM, ["startDate"], ["desc"]);
  const lastMeetingWithMOM = sortedMeetingsWithMOM?.[0] as Calendar;

  const attachments = doc.momAttach ?? [];

  const MOM_TO_EDITOR = (MOM: string, MOMObj: OutputData) => {
    if (MOMObj == undefined || MOMObj?.blocks?.length == 0 || MOMObj == null) {
      console.log("here");
      if (MOM == undefined || MOM == "" || MOM == null) {
        console.log("here1");

        const emptyMOM: OutputData = {
          blocks: [],
        };
        return emptyMOM;
      } else {
        const newMOM: OutputData = {
          blocks: [
            {
              type: "paragraph",
              data: {
                text: MOM,
              },
            },
          ],
        };
        return newMOM;
      }
    } else {
      return MOMObj;
    }
  };

  const handleFileChange = async (e: React.ChangeEvent<HTMLInputElement>) => {
    if (e.target.files) {
      const file: File = e.target.files[0];
      if (!file) return;
      const filePath = `meetings/${uid}/${file.name}`;
      try {
        console.log({ file });
        const url = await uploadFile(file, filePath);

        if (url) {
          message.success("File uploaded successfully");
          return { url, fileName: file.name };
        }
      } catch (error: any) {
        message.error(error.message);
      }
    }
  };
  return (
    <div>
      {modalStateSendMOM ? (
        <SendMOM
          setModalStateMOM={setModalStateMOM}
          modalStateSendMOM={modalStateSendMOM}
          setModalStateSendMOM={setModalStateSendMOM}
          files={files}
          downloadURLs={downloadURLs}
          MOM={editorContent}
          MOMWriter={uid}
          mentions={mentions}
          docId={doc.id}
        />
      ) : null}
      <Modal
        title={t("general.addMOM")}
        width={"60%"}
        visible={modalStateMOM}
        closable={false}
        footer={[
          <Popconfirm
            title={"Are you sure?"}
            okText="Yes"
            cancelText="No"
            onConfirm={() => {
              setModalStateMOM(false);
              window.location.reload();
            }}
          >
            <Button>Cancel</Button>
          </Popconfirm>,
          <Button
            type="primary"
            disabled={false}
            onClick={() => {
              if (instance?.saver) {
                handleEditorSave();
                setModalStateSendMOM(true);
                handleAgendaEditorSave();
                handlePreviousEditorSave();
              } else {
                console.log("here");
                handleAgendaEditorSave();
                handlePreviousEditorSave();
                setModalStateMOM(false);
              }
            }}
          >
            Submit
          </Button>,
        ]}
      >
        <div>
          <Row gutter={24}>
            <Divider orientation="left">
              <b>Current Meeting : {moment(doc?.startDate).format("DD / MM / YYYY hh:mm a").toString()}</b>
            </Divider>
            <Col
              span={9}
              style={{
                border: "solid 1px #31a0b5",
                borderRadius: "5px",
                margin: "1%",
                marginBottom: "2%",
                padding: "1%",
              }}
            >
              <div>
                <b>Agenda</b>
                <Row>
                  <Editor
                    holder={"agenda_meeting"}
                    data={MOM_TO_EDITOR(doc.agenda, doc.agendaObj)}
                    setInstance={setInstanceAgenda}
                    minHeight={100}
                  />
                </Row>
              </div>
            </Col>

            <Col
              span={14}
              style={{
                border: "1px solid rgb(51 193 51)",
                borderRadius: "5px",
                margin: "1%",
                marginBottom: "2%",
                padding: "1%",
              }}
            >
              <div>
                <b>MOM</b>
                <Row>
                  <Editor
                    holder={"current_meeting"}
                    data={MOM_TO_EDITOR(MOM, MOMObj)}
                    setInstance={setInstance}
                    minHeight={100}
                  />
                </Row>
              </div>
            </Col>
          </Row>

          {allMeetingsDataFromOriginal.length != 0 && (
            <div>
              <Divider orientation="left">
                <b>
                  Previous Meeting : {moment(lastMeetingWithMOM?.startDate).format("DD / MM / YYYY hh:mm a").toString()}
                </b>
              </Divider>
              <Row
                style={{
                  border: "solid 1px orange",
                  borderRadius: "5px",
                  margin: "1%",
                  marginBottom: "3%",
                  padding: "1%",
                }}
              >
                <Editor
                  holder={"previous_meeting"}
                  data={MOM_TO_EDITOR(lastMeetingWithMOM?.MOM, lastMeetingWithMOM?.MOMObj)}
                  setInstance={setInstancePrevious}
                  minHeight={100}
                />
              </Row>
            </div>
          )}

          <label
            style={{
              background: "rgb(100 181 246)",
              padding: "1%",
              marginRight: "1%",
              borderColor: "rgb(100 181 246)",
              borderRadius: "6px",
              color: "white",
            }}
          >
            <Icon type="upload" />
            <span style={{ padding: "2%" }}>{t("general.uploadAttach")}</span>
            {uploadingAttach ? <Spin indicator={antIcon} style={{ color: "#fff" }} /> : null}
            {doc.momAttach == undefined || doc.momAttach.length == 0 ? null : (
              <Icon type="check" style={{ color: "#fff", padding: "1%" }} />
            )}

            <input
              hidden
              type="file"
              onChange={async (e) => {
                const result = await handleFileChange(e);
                if (result) {
                  setFiles([...files, result.fileName] as any);
                  setDownloadURLs([...downloadURLs, result?.url] as any);
                  setUploadingAttach(false);
                }
              }}
            />
          </label>
          <label
            style={{
              background: "#fff",
              padding: "1%",
              marginRight: "1%",
              border: "solid thin",
              borderColor: "rgb(100 181 246)",
              borderRadius: "6px",
              color: "rgb(100 181 246)",
            }}
          >
            {`Uploaded File Number: ${[...attachments, ...downloadURLs].length}`}
          </label>
          <Button
            onClick={() => {
              dispatch(
                editCalendarEvent({
                  id: lastMeetingWithMOM.id,
                  momAttach: [],
                  filesNames: [],
                  sendMOM: false,
                } as any)
              );
              setFiles([]);
            }}
            type="danger"
          >
            Delete Attach
          </Button>
        </div>
      </Modal>
    </div>
  );
}

export default AddMOM;
