import { createSlice, createAsyncThunk, PayloadAction } from "@reduxjs/toolkit";
import { supabase } from "../../supabaseClient"; // Adjust the import path as needed
import { overtime } from "../../db/interfaces"; // Adjust the import path as needed

interface OvertimeSliceState {
  data: overtime[];
  overtime: overtime;
  status: "loading" | "succeeded" | "failed" | "idle";
  error: string | null;
}

const initialState: OvertimeSliceState = {
  data: [],
  overtime: {} as overtime,
  status: "idle",
  error: null,
};

// Fetch all overtime records
export const fetchAllOvertime = createAsyncThunk<overtime[], void, { rejectValue: string }>(
  "overtime/fetchAllOvertime",
  async (_, thunkApi) => {
    const { data, error } = await supabase.from("overtime").select("*");
    if (error) {
      return thunkApi.rejectWithValue(error.message);
    }
    return data as overtime[];
  }
);

// Fetch overtime by Office
export const fetchOvertimeByOffice = createAsyncThunk<overtime[], string, { rejectValue: string }>(
  "overtime/fetchOvertimeByOffice",
  async (officeId, thunkApi) => {
    const { data, error } = await supabase.from("overtime").select("*").eq("office", officeId);
    if (error) {
      return thunkApi.rejectWithValue(error.message);
    }
    return data as overtime[];
  }
);

// Fetch overtime by id
export const fetchOvertimeById = createAsyncThunk<overtime, string, { rejectValue: string }>(
  "overtime/fetchOvertimeById",
  async (overtimeId, thunkApi) => {
    const { data, error } = await supabase.from("overtime").select("*").eq("id", overtimeId).single();
    if (error) {
      return thunkApi.rejectWithValue(error.message);
    }
    return data as overtime;
  }
);

// Fetch overtime by Office and Date
export const fetchOvertimeByOfficeByDate = createAsyncThunk<
  overtime[],
  { officeId: string; gt: string; ls: string; byField: string },
  { rejectValue: string }
>("overtime/fetchOvertimeByOfficeByDate", async ({ officeId, gt, ls, byField }, thunkApi) => {
  const { data, error } = await supabase
    .from("overtime")
    .select("*")
    .eq("office", officeId)
    .gt(byField, gt)
    .lt(byField, ls);
  if (error) {
    return thunkApi.rejectWithValue(error.message);
  }
  return data as overtime[];
});

// Add a new overtime record
export const addOvertime = createAsyncThunk<overtime, overtime, { rejectValue: string }>(
  "overtime/addOvertime",
  async (newOvertime, thunkApi) => {
    const { data: insertData, error: insertError } = await supabase
      .from("overtime")
      .insert(newOvertime)
      .select()
      .single();


    if (insertError) {
      return thunkApi.rejectWithValue(insertError.message);
    }

    return insertData as overtime;
  }
);

// Edit an overtime record
export const editOvertime = createAsyncThunk<overtime, overtime, { rejectValue: string }>(
  "overtime/editOvertime",
  async (updatedOvertime, thunkApi) => {
    const { data: updateData, error: updateError } = await supabase
      .from("overtime")
      .update(updatedOvertime)
      .eq("id", updatedOvertime.id)
      .select()
      .single();

    if (updateError) {
      return thunkApi.rejectWithValue(updateError.message);
    }

    return updateData as overtime;
  }
);

// Delete an overtime record
export const deleteOvertime = createAsyncThunk<string, string, { rejectValue: string }>(
  "overtime/deleteOvertime",
  async (id, thunkApi) => {
    const { error } = await supabase.from("overtime").delete().eq("id", id);

    if (error) {
      return thunkApi.rejectWithValue(error.message);
    }

    return id;
  }
);

// Real-Time Actions
export const addOvertimeRealTime = (newOvertime: overtime): PayloadAction<overtime> => ({
  type: "overtime/addOvertimeRealTime",
  payload: newOvertime,
});

export const editOvertimeRealTime = (updatedOvertime: overtime): PayloadAction<overtime> => ({
  type: "overtime/editOvertimeRealTime",
  payload: updatedOvertime,
});

export const deleteOvertimeRealTime = (id: string): PayloadAction<string> => ({
  type: "overtime/deleteOvertimeRealTime",
  payload: id,
});

// Slice definition
const overtimeSlice = createSlice({
  name: "overtime",
  initialState,
  reducers: {
    addOvertimeRealTime(state, action: PayloadAction<overtime>) {
      const exists = state.data.some((overtime) => overtime.id === action.payload.id);
      if (!exists) {
        state.data = [...state.data, action.payload];
      }
      state.error = null;
    },
    editOvertimeRealTime(state, action: PayloadAction<overtime>) {
      const index = state.data.findIndex((overtime) => overtime.id === action.payload.id);
      if (index !== -1) {
        const isDifferent = JSON.stringify(state.data[index]) !== JSON.stringify(action.payload);
        if (isDifferent) {
          state.data[index] = action.payload;
        }
      }
      state.error = null;

      if (state.overtime.id == action.payload.id) {
        state.overtime = action.payload;
      }
    },
    deleteOvertimeRealTime(state, action: PayloadAction<string>) {
      const exists = state.data.some((overtime) => overtime.id === action.payload);
      if (exists) {
        state.data = state.data.filter((overtime) => overtime.id !== action.payload);
      }
      state.error = null;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchAllOvertime.pending, (state) => {
        state.status = "loading";
      })
      .addCase(fetchAllOvertime.fulfilled, (state, action: PayloadAction<overtime[]>) => {
        state.status = "succeeded";
        state.data = action.payload;
        state.error = null;
      })
      .addCase(fetchAllOvertime.rejected, (state, action: PayloadAction<string | undefined>) => {
        state.status = "failed";
        state.error = action.payload || "Failed to fetch overtime records.";
      })

      .addCase(fetchOvertimeById.pending, (state) => {
        state.status = "loading";
      })
      .addCase(fetchOvertimeById.fulfilled, (state, action: PayloadAction<overtime>) => {
        state.status = "succeeded";
        state.overtime = action.payload;
        state.error = null;
      })
      .addCase(fetchOvertimeById.rejected, (state, action: PayloadAction<string | undefined>) => {
        state.status = "failed";
        state.error = action.payload || "Failed to fetch overtime.";
      })

      .addCase(fetchOvertimeByOffice.pending, (state) => {
        state.status = "loading";
      })
      .addCase(fetchOvertimeByOffice.fulfilled, (state, action: PayloadAction<overtime[]>) => {
        state.status = "succeeded";
        state.data = action.payload;
        state.error = null;
      })
      .addCase(fetchOvertimeByOffice.rejected, (state, action: PayloadAction<string | undefined>) => {
        state.status = "failed";
        state.error = action.payload || "Failed to fetch overtime by office.";
      })

      .addCase(fetchOvertimeByOfficeByDate.pending, (state) => {
        state.status = "loading";
      })
      .addCase(fetchOvertimeByOfficeByDate.fulfilled, (state, action: PayloadAction<overtime[]>) => {
        state.status = "succeeded";
        state.data = action.payload;
        state.error = null;
      })
      .addCase(fetchOvertimeByOfficeByDate.rejected, (state, action: PayloadAction<string | undefined>) => {
        state.status = "failed";
        state.error = action.payload || "Failed to fetch overtime by date.";
      })

      .addCase(addOvertime.pending, (state) => {})
      .addCase(addOvertime.fulfilled, (state, action: PayloadAction<overtime>) => {
        state.error = null;
      })
      .addCase(addOvertime.rejected, (state, action: PayloadAction<string | undefined>) => {
        state.error = action.payload || "Failed to add overtime.";
      })

      .addCase(editOvertime.pending, (state) => {})
      .addCase(editOvertime.fulfilled, (state, action: PayloadAction<overtime>) => {
        state.error = null;
      })
      .addCase(editOvertime.rejected, (state, action: PayloadAction<string | undefined>) => {
        state.status = "failed";
        state.error = action.payload || "Failed to edit overtime.";
      })

      .addCase(deleteOvertime.pending, (state) => {})
      .addCase(deleteOvertime.fulfilled, (state, action: PayloadAction<string>) => {
        state.error = null;
      })
      .addCase(deleteOvertime.rejected, (state, action: PayloadAction<string | undefined>) => {
        state.error = action.payload || "Failed to delete overtime.";
      });
  },
});

export const subscribeToOvertime = (dispatch: any) => {
  const subscription = supabase
    .channel("public:overtime")
    .on("postgres_changes", { event: "*", schema: "public", table: "overtime" }, (payload) => {
      const newRecord = payload.new as overtime;
      const oldRecord = payload.old as overtime;

      switch (payload.eventType) {
        case "INSERT":
          dispatch(addOvertimeRealTime(newRecord));
          break;
        case "UPDATE":
          dispatch(editOvertimeRealTime(newRecord));
          break;
        case "DELETE":
          dispatch(deleteOvertimeRealTime(oldRecord.id as string));
          break;
        default:
          break;
      }
    })
    .subscribe();

  return () => {
    subscription.unsubscribe();
  };
};

export default overtimeSlice.reducer;
