import { Comment, Avatar, Form, Button, Input, Spin } from "antd";
import moment from "moment";
import { useEffect, useState } from "react";
import React from "react";
import { sortBy, uniq } from "lodash";
import { useTranslation } from "react-i18next";
import { CEOs } from "../db/admin";
import { useAppDispatch, useAppSelector } from "../hooks/reduxHooks";
import { addNotification } from "../features/notifications/notificationsSlice";
import { globalOfficesData } from "../global/officeList";
import { globalUsersData } from "../global/usersList";
import {
  addDiscussion,
  fetchDiscussionsByOrderId,
  subscribeToDiscussions,
} from "../features/discussions/discussionsSlice";

const { TextArea } = Input;

interface Props {
  orderId: string;
  userId: any;
  type: string;
  officeId?: string;
}

export default function Discussion(props: Props) {
  const { data: discussionsData, status, error } = useAppSelector((state) => state.discussions);
  const { currentUser } = useAppSelector((state) => state.auth);
  const dispatch = useAppDispatch();
  const { t } = useTranslation();
  const { userId, orderId, type, officeId } = props;

  const [submitting, setSubmitting] = useState(false);
  const [value, setValue] = useState("");
  const uid = currentUser?.id as string;

  useEffect(() => {
    dispatch(fetchDiscussionsByOrderId(orderId));
    const unsubscribe = subscribeToDiscussions(dispatch);
    return () => {
      unsubscribe();
    };
  }, [dispatch, orderId]);

  if (status == "loading") {
    return <Spin />;
  }
  const OfficeAdminWithCEO = uniq([
    ...globalOfficesData[officeId ?? ""]?.admins,
    ...globalOfficesData[officeId ?? ""]?.HRs,
  ]);

  const OfficeAdmins = OfficeAdminWithCEO.filter((id) => !CEOs.includes(id));
  const OfficeLaws = Object.values(globalUsersData ?? {}).filter((u) => u.adminLaw == true) ?? [];

  const handleSubmit = () => {
    setSubmitting(true);
    dispatch(
      addDiscussion({
        datetime: "few min",
        postedAt: moment() as any,
        author: uid,
        content: value,
        orderId: orderId,
        notifyTo: userId,
        avatar: currentUser.personalPhoto ?? "",
        type: type as any,
        cacheAuthor: currentUser.firstName + " " + currentUser.lastName,
      })
    )
      .then(() => {
        setSubmitting(false);
        setValue("");
      })

      .then(() => {
        userId != uid
          ? dispatch(
              addNotification({
                notifyTo: userId,
                notifyFrom: uid,
                orderId: orderId,
                read: false,
                kind:
                  type == "order"
                    ? "messageOrder"
                    : type == "vacation"
                    ? "messageVacation"
                    : type == "case"
                    ? "case"
                    : "messageOvertime",
                content: value,
                postedAt: moment() as any,
              })
            )
          : type == "order"
          ? OfficeLaws?.map((doc: any) => {
              const adminID = doc.id;

              dispatch(
                addNotification({
                  notifyTo: adminID, //admin
                  notifyFrom: uid,
                  orderId: orderId,
                  kind:
                    type == "order"
                      ? "messageOrder"
                      : type == "vacation"
                      ? "messageVacation"
                      : type == "case"
                      ? "case"
                      : "messageOvertime",
                  read: false,
                  content: value,
                  postedAt: moment() as any,
                })
              );
            })
          : OfficeAdmins?.map((id: any) => {
              const adminID = id;
              dispatch(
                addNotification({
                  notifyTo: adminID, //admin
                  notifyFrom: uid,
                  orderId: orderId,
                  kind:
                    type == "order"
                      ? "messageOrder"
                      : type == "vacation"
                      ? "messageVacation"
                      : type == "case"
                      ? "case"
                      : "messageOvertime",
                  read: false,
                  content: value,
                  postedAt: moment() as any,
                })
              );
            });
      });
  };

  const comments = discussionsData === undefined ? [] : discussionsData;

  const sortedCommentsSnapshot = sortBy(comments, (item) => item.postedAt);

  const dataSource = sortedCommentsSnapshot.map((item) => {
    const userData = globalUsersData[item.author ?? ""];

    return error ? (
      <p>{error}</p>
    ) : (
      <div
        style={{
          padding: "20px",
          color: "rgba(0, 0, 0, 0.65)",
          background: "#ffff",
          borderRadius: "19px",
          boxShadow: "6px 7px 17px 3px rgba(0, 0, 0, 0.09)",
          margin: "25px 0px",
        }}
        key={item.id}
      >
        <div
          style={{
            display: "flex",
            flexWrap: "wrap",
            WebkitBoxPack: "start",
            justifyContent: "flex-start",

            fontSize: "14px",
            alignItems: "center",
          }}
        >
          <span
            style={{
              fontSize: "14px",
              lineHeight: "14px",
              marginRight: "10px",
              fontFamily: "initial",
            }}
          >
            <span>
              <Avatar
                size="large"
                src={userData?.personalPhoto}
                style={{
                  backgroundColor: userData?.gender == "male" ? "#87d068" : "#db7093",
                }}
              >
                {userData?.personalPhoto ?? userData?.firstName.slice(0, 1) + userData?.lastName.slice(0, 1)}
              </Avatar>
            </span>{" "}
            <span>
              <a>{userData?.firstName} </a>
              <a>{userData?.lastName} </a>
            </span>
          </span>
          <span style={{ fontSize: "12px", color: "#ccc", whiteSpace: "nowrap" }}>
            <span>{item?.postedAt.toLocaleString()}</span>
          </span>
        </div>
        <div style={{ marginTop: 10 }} dangerouslySetInnerHTML={{ __html: item.content }} />
      </div>
    );
  });

  return (
    <div>
      {dataSource}
      <Comment
        avatar={
          <Avatar
            src={currentUser.personalPhoto}
            style={{
              backgroundColor: currentUser.gender == "male" ? "#87d068" : "#db7093",
            }}
          >
            {" "}
            {currentUser.personalPhoto ??
              currentUser.firstName.slice(0, 1) + currentUser.lastName.slice(0, 1)}
          </Avatar>
        }
        content={
          <div>
            <Form.Item>
              <TextArea rows={4} onChange={(e) => setValue(e.target.value)} value={value} />
            </Form.Item>
            <Form.Item>
              <Button htmlType="submit" loading={submitting} onClick={handleSubmit} type="primary">
                {t("general.addComment")}
              </Button>
            </Form.Item>
          </div>
        }
      />
    </div>
  );
}
