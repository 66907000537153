import { fetchOfficesInBatches } from "../features/offices/officesSlice";
import { RootState, store } from "../store";

// Fetch offices in batches when this file is first imported, but only if not already loaded
const initializeOfficesData = async () => {
  const state: RootState = store.getState();
  const { loaded } = state.offices;

  // Only dispatch fetch if the data hasn't been loaded yet
  if (!loaded) {
    await store.dispatch(fetchOfficesInBatches());
  }
};

// Immediately call to ensure data is fetched
initializeOfficesData();

// Define a getter function to access the latest offices data from the store
export const getOfficesData = () => {
  const state: RootState = store.getState();
  const { globalOfficesData, OFFICES_ID_NAME, loaded } = state.offices;
  const { currentUser } = state.auth;
  // if (!loaded) {
  //   console.warn("Offices data is not loaded yet.");
  // }

  return {
    globalOfficesData,
    OFFICES_ID_NAME: OFFICES_ID_NAME.filter((office) => {
      return currentUser?.fullControlOffices?.includes(office.officeId);
    }),
    ALL_OFFICES_ID_NAME: OFFICES_ID_NAME,
  };
};

// Initial export values, populated from the Redux store
export let globalOfficesData = getOfficesData().globalOfficesData;
export let OFFICES_ID_NAME = getOfficesData().OFFICES_ID_NAME;
export let ALL_OFFICES_ID_NAME = getOfficesData().ALL_OFFICES_ID_NAME;
// Subscribe to store updates and dynamically update exports when the Redux state changes
store.subscribe(() => {
  const {
    globalOfficesData: updatedGlobalOfficesData,
    OFFICES_ID_NAME: updatedOFFICES_ID_NAME,
    ALL_OFFICES_ID_NAME: updatedALL_OFFICES_ID_NAME,
  } = getOfficesData();

  // Update the exports with the latest values from the Redux store
  globalOfficesData = updatedGlobalOfficesData;
  OFFICES_ID_NAME = updatedOFFICES_ID_NAME;
  ALL_OFFICES_ID_NAME = updatedALL_OFFICES_ID_NAME;
});
