import React, { useEffect, useState } from "react";
import Paper from "@material-ui/core/Paper";
import { Button, Spin } from "antd";
import { useHistory } from "react-router-dom";
import { useTranslation } from "react-i18next";
import AddMOM from "./AddMOM";
import DevTable from "../../helpers/devex-table";
import { globalUsersData } from "../../global/usersList";
import { Calendar } from "../../db/interfaces";
import { useAppDispatch, useAppSelector } from "../../hooks/reduxHooks";
import moment from "moment";
import {
  editCalendarEvent,
  fetchCalendarEventsByUser,
  subscribeToCalendarEvents,
} from "../../features/calendar/calendarSlice";
import { fetchAllRooms } from "../../features/roomsList/roomsListSlice";

export default function MeetingsTable() {
  const {
    data: meetingsData,
    status: meetingsStatus,
    error: meetingsError,
  } = useAppSelector((state) => state.calendar);
  const { data: rooms, status: roomsStatus } = useAppSelector((state) => state.rooms);

  const { currentUser } = useAppSelector((state) => state.auth);
  const dispatch = useAppDispatch();

  const [rowDoc, setRowDoc] = useState({} as any);
  const history = useHistory();
  const { t } = useTranslation();
  const uid = currentUser.id as string;
  const [MOM, setMOM] = useState("");
  const [modalStateMOM, setModalStateMOM] = useState(false);
  const [defaultHiddenColumnNames] = useState(["agenda", "allDay", "endDate", "id"]);

  useEffect(() => {
    dispatch(fetchAllRooms());
    dispatch(fetchCalendarEventsByUser(uid));

    const unsubscribe = subscribeToCalendarEvents(dispatch);
    return () => {
      unsubscribe();
    };
  }, [dispatch]);

  if (meetingsStatus == "loading" || roomsStatus == "loading") return <Spin />;

  const data = meetingsData;
  const columns = [
    {
      name: "id",
      title: "ID",
    },

    { name: "title", title: "Title" },
    {
      name: "uid",
      title: "Organizer",
      getCellValue: (row: Calendar) => globalUsersData[row.uid ?? ""]?.fullName,
    },
    {
      name: "roomId",
      title: "Location",
      getCellValue: (row: any) => rooms.find((d) => d.id == row.roomId)?.roomName,
    },
    {
      name: "allDay",
      title: "All Day",
      getCellValue: (row: any) => (row.allDay == false ? "No" : "Yes"),
    },
    {
      name: "startDate",
      title: "Start Date",
      getCellValue: (row: any) => row.startDate.toLocaleString(),
    },
    {
      name: "endDate",
      title: "End Date",
      getCellValue: (row: any) => row.endDate.toLocaleString(),
    },
    {
      name: "attendees",
      title: "Attendees",
      getCellValue: (row: any) => {
        return row.members?.map((m: string) => {
          return globalUsersData[m ?? ""]?.fullName;
        });
      },
    },

    {
      name: "agenda",
      title: "Agenda",
    },

    {
      name: "more",
      title: "More Information",
      getCellValue: (row: any) => {
        return <Button onClick={() => history.push(`/calenderDetails/${row.id}`)}> {t("general.view")}</Button>;
      },
    },
    {
      name: "MOM",
      title: "MOM",
      getCellValue: (row: any) => {
        const now = moment();
        const oldDate = moment(row.startDate);
        if (now >= oldDate && (row.MOM == undefined || row.MOM == "")) {
          // MOMStatusChange(row.id);
          return (
            <Button
              onClick={() => {
                setRowDoc(row);
                setMOM(row.MOM);
                setModalStateMOM(true);
              }}
            >
              {t("general.addMOMButton")}
            </Button>
          );
        } else if (now >= oldDate && row.MOM !== undefined) {
          return (
            <div>
              <Button
                onClick={() => {
                  setRowDoc(row);
                  setModalStateMOM(true);
                }}
              >
                {t("general.addMOMButton")}
              </Button>
              <div style={{ color: "green", float: "right", paddingTop: "3%", paddingRight: "6%" }}>
                {" "}
                {t("general.submitted")}
              </div>
            </div>
          );
        } else {
          return "Not Yet";
        }
      },
    },
  ];
  const columnsExport = [
    { name: "title", title: "Title" },
    {
      name: "uid",
      title: "Organizer",
      getCellValue: (row: any) => globalUsersData[row.uid ?? ""]?.fullName,
    },
    {
      name: "roomId",
      title: "Location",
      getCellValue: (row: any) => rooms.find((d) => d.id == row.roomId)?.roomName,
    },
    {
      name: "allDay",
      title: "All Day",
      getCellValue: (row: any) => (row.allDay == false ? "No" : "Yes"),
    },
    {
      name: "startDate",
      title: "Start Date",
      getCellValue: (row: any) => row.startDate.toLocaleString(),
    },
    {
      name: "endDate",
      title: "End Date",
      getCellValue: (row: any) => row.endDate.toLocaleString(),
    },
    {
      name: "attendees",
      title: "Attendees",
      getCellValue: (row: any) => {
        return row.members.map((m: string) => {
          return globalUsersData[m ?? ""]?.fullName;
        });
      },
    },
  ];

  // const MOMStatusChange = (id: string) => {
  //   dispatch(editCalendarEvent({ id: id, MOMStatus: true } as any));
  // };

  return (
    <div>
      <div style={{ backgroundColor: "#fff", padding: "1%", marginBottom: "1%", border: "white", borderRadius: "4px" }}>
        <b>
          <h3>Meetings Table Details</h3>
        </b>
      </div>

      {modalStateMOM ? (
        <AddMOM modalStateMOM={modalStateMOM} setModalStateMOM={setModalStateMOM} doc={rowDoc} MOM={MOM} />
      ) : null}
      <div>
        <Paper>
          <DevTable
            data={data}
            columns={columns}
            defaultHiddenColumnNames={defaultHiddenColumnNames}
            tableName="My_Meetings"
            selected={false}
            typicalTable={false}
            columnsExport={columnsExport}
          />
        </Paper>
      </div>
    </div>
  );
}
