import { Card, Row, Col } from "antd";
import { useState } from "react";
import React from "react";
import { useTranslation } from "react-i18next";
import { useHistory } from "react-router-dom";
import AddNewOrder from "../models/AddNewOrder";
import { globalOfficesData } from "../../global/officeList";
const { Meta } = Card;
import structureIcon from "./images/AIU_Icons-01.png";
import workPermitIcon from "./images/AIU_Icons-02.png";
import calendarIcon from "./images/AIU_Icons-03.png";
import meetingsIcon from "./images/AIU_Icons-04.png";
import vacationIcon from "./images/AIU_Icons-05.png";
import overtimeIcon from "./images/AIU_Icons-06.png";
import timeSheetIcon from "./images/AIU_Icons-07.png";
import HIMSIcon from "./images/AIU_Icons-08.png";
import { useAppSelector } from "../../hooks/reduxHooks";

export default function Services() {
  const history = useHistory();
  const { currentUser } = useAppSelector((state) => state.auth);
  const [modalState, setModalState] = useState(false);
  const arrOfCon = globalOfficesData[currentUser.office ?? ""]?.restrictedFeatures ?? [];

  return (
    <div style={{ alignContent: "center", padding: "0.6%" }}>
      <AddNewOrder modalState={modalState} setModalState={setModalState} />

      <Row gutter={20} style={{ alignItems: "center", padding: "0.5%" }}>
        <Col
          span={5}
          style={{
            width: "auto",
            paddingLeft: "0.6%",
            paddingRight: "0.6%",
            paddingTop: "0.6%",
            paddingBottom: "0.6%",
          }}
        >
          <Card
            onClick={() => history.push("/organization-structure")}
            hoverable
            style={{
              width: 140,
              boxShadow: "rgba(0, 0, 0, 0.09) 1px -1px 17px 3px",
              borderRadius: "10px",
            }}
            cover={
              <img
                style={{ borderStartStartRadius: "10px", borderStartEndRadius: "10px" }}
                height="140"
                alt="example"
                src={structureIcon}
              />
            }
          >
            <Meta title={"Structure"} />
          </Card>
        </Col>

        {arrOfCon?.includes("workPermit") ? null : (
          <Col
            span={5}
            style={{
              width: "auto",
              paddingLeft: "0.6%",
              paddingRight: "0.6%",
              paddingTop: "0.6%",
              paddingBottom: "0.6%",
            }}
          >
            <Card
              onClick={() => setModalState(true)}
              hoverable
              style={{
                width: 140,
                boxShadow: "rgba(0, 0, 0, 0.09) 1px -1px 17px 3px",
                borderRadius: "10px",
              }}
              cover={
                <img
                  style={{ borderStartStartRadius: "10px", borderStartEndRadius: "10px" }}
                  height="140"
                  alt="example"
                  src={workPermitIcon}
                />
              }
            >
              <Meta title={"Apply"} />
            </Card>
          </Col>
        )}

        {arrOfCon?.includes("workPermit") ? null : (
          <Col
            span={5}
            style={{
              width: "auto",
              paddingLeft: "0.6%",
              paddingRight: "0.6%",
              paddingTop: "0.6%",
              paddingBottom: "0.6%",
            }}
          >
            <Card
              onClick={() => history.push("/orders")}
              hoverable
              style={{
                width: 140,
                boxShadow: "rgba(0, 0, 0, 0.09) 1px -1px 17px 3px",
                borderRadius: "10px",
              }}
              cover={
                <img
                  style={{ borderStartStartRadius: "10px", borderStartEndRadius: "10px" }}
                  height="140"
                  alt="example"
                  src={workPermitIcon}
                />
              }
            >
              <Meta title={"Work-Permit"} />
            </Card>
          </Col>
        )}

        <Col
          span={5}
          style={{
            width: "auto",
            paddingLeft: "0.6%",
            paddingRight: "0.6%",
            paddingTop: "0.6%",
            paddingBottom: "0.6%",
          }}
        >
          <Card
            onClick={() => history.push("/meeting-calendar")}
            hoverable
            style={{
              width: 140,
              boxShadow: "rgba(0, 0, 0, 0.09) 1px -1px 17px 3px",
              borderRadius: "10px",
            }}
            cover={
              <img
                style={{ borderStartStartRadius: "10px", borderStartEndRadius: "10px" }}
                height="140"
                alt="example"
                src={calendarIcon}
              />
            }
          >
            <Meta title={"Calendar"} />
          </Card>
        </Col>
        <Col
          span={5}
          style={{
            width: "auto",
            paddingLeft: "0.6%",
            paddingRight: "0.6%",
            paddingTop: "0.6%",
            paddingBottom: "0.6%",
          }}
        >
          <Card
            onClick={() => history.push("/meeting-table")}
            hoverable
            style={{
              width: 140,
              boxShadow: "rgba(0, 0, 0, 0.09) 1px -1px 17px 3px",
              borderRadius: "10px",
            }}
            cover={
              <img
                style={{ borderStartStartRadius: "10px", borderStartEndRadius: "10px" }}
                height="140"
                alt="example"
                src={meetingsIcon}
              />
            }
          >
            <Meta title={"Meetings"} />
          </Card>
        </Col>
        {arrOfCon?.includes("vacations") ? null : (
          <Col
            span={5}
            style={{
              width: "auto",
              paddingLeft: "0.6%",
              paddingRight: "0.6%",
              paddingTop: "0.6%",
              paddingBottom: "0.6%",
            }}
          >
            <Card
              onClick={() => history.push("/vacation")}
              hoverable
              style={{
                width: 140,
                boxShadow: "rgba(0, 0, 0, 0.09) 1px -1px 17px 3px",
                borderRadius: "10px",
              }}
              cover={
                <img
                  style={{ borderStartStartRadius: "10px", borderStartEndRadius: "10px" }}
                  height="140"
                  alt="example"
                  src={vacationIcon}
                />
              }
            >
              <Meta title={"Vacation"} />
            </Card>
          </Col>
        )}
        {arrOfCon?.includes("overtime") ? null : (
          <Col
            span={5}
            style={{
              width: "auto",
              paddingLeft: "0.6%",
              paddingRight: "0.6%",
              paddingTop: "0.6%",
              paddingBottom: "0.6%",
            }}
          >
            <Card
              onClick={() => history.push("/overtime")}
              hoverable
              style={{
                width: 140,
                boxShadow: "rgba(0, 0, 0, 0.09) 1px -1px 17px 3px",
                borderRadius: "10px",
              }}
              cover={
                <img
                  style={{ borderStartStartRadius: "10px", borderStartEndRadius: "10px" }}
                  height="140"
                  alt="example"
                  src={overtimeIcon}
                />
              }
            >
              <Meta title={"Overtime"} />
            </Card>
          </Col>
        )}
        <Col
          span={5}
          style={{
            width: "auto",
            paddingLeft: "0.6%",
            paddingRight: "0.6%",
            paddingTop: "0.6%",
            paddingBottom: "0.6%",
          }}
        >
          <Card
            onClick={() => history.push("/time-sheet")}
            hoverable
            style={{
              width: 140,
              boxShadow: "rgba(0, 0, 0, 0.09) 1px -1px 17px 3px",
              borderRadius: "10px",
            }}
            cover={
              <img
                style={{ borderStartStartRadius: "10px", borderStartEndRadius: "10px" }}
                height="140"
                alt="example"
                src={timeSheetIcon}
              />
            }
          >
            <Meta title={"TimeSheet"} />
          </Card>
        </Col>
        {/* <Col
          span={5}
          style={{
            width: "auto",
            paddingLeft: "0.6%",
            paddingRight: "0.6%",
            paddingTop: "0.6%",
            paddingBottom: "0.6%",
          }}
        >
          <Card
            onClick={() => window.open("https://www.logistics.ida-org.com", "_blank")}
            hoverable
            style={{ width: 180, boxShadow: "rgba(0, 0, 0, 0.09) 1px -1px 17px 3px" }}
            cover={
              <img
                height="115"
                alt="example"
                src="https://firebasestorage.googleapis.com/v0/b/administrationapp-cdc55.appspot.com/o/WhatsApp%20Image%202020-09-01%20at%2011.19.23%20AM.jpeg?alt=media&token=627891a2-802a-464c-bfdd-e73ec9d9489a"
              />
            }
          >
            <Meta title={t("dashboard.logisticsSoftware")} />
          </Card>
        </Col> */}
        <Col
          span={5}
          style={{
            width: "auto",
            paddingLeft: "0.6%",
            paddingRight: "0.6%",
            paddingTop: "0.6%",
            paddingBottom: "0.6%",
          }}
        >
          <Card
            onClick={() => window.open("https://www.hims.ida-org.com", "_blank")}
            hoverable
            style={{
              width: 140,
              boxShadow: "rgba(0, 0, 0, 0.09) 1px -1px 17px 3px",
              borderRadius: "10px",
            }}
            cover={
              <img
                height="140"
                style={{ borderStartStartRadius: "10px", borderStartEndRadius: "10px" }}
                alt="example"
                src={HIMSIcon}
              />
            }
          >
            <Meta title={"HIMS"} />
          </Card>
        </Col>
        {/* {arrOfCon?.includes("cars") ? null : (
          <Col
            span={5}
            style={{
              width: "auto",
              paddingLeft: "0.6%",
              paddingRight: "0.6%",
              paddingTop: "0.6%",
              paddingBottom: "0.6%",
            }}
          >
            <Card
              onClick={() => message.info("Processing")}
              hoverable
              style={{ width: 180, boxShadow: "rgba(0, 0, 0, 0.09) 1px -1px 17px 3px" }}
              cover={<img height="115" alt="example" src="https://scx1.b-cdn.net/csz/news/800a/2018/location.jpg" />}
            >
              <Meta title={t("dashboard.carsMovements")} />
            </Card>
          </Col>
        )}
        <Col
          span={5}
          style={{
            width: "auto",
            paddingLeft: "0.6%",
            paddingRight: "0.6%",
            paddingTop: "0.6%",
            paddingBottom: "0.6%",
          }}
        >
          <Card
            onClick={() => message.info("Processing")}
            hoverable
            style={{ width: 180, boxShadow: "rgba(0, 0, 0, 0.09) 1px -1px 17px 3px" }}
            cover={
              <img
                height="115"
                alt="example"
                src="https://prd-mp-images.azureedge.net/9dda7041-2870-4a96-bf1e-dd8342e86e7c/zy/b6d9bba9-8322-4853-9f6b-1fd107d5b8db/abhmhrqq/fixedheader.png"
              />
            }
          >
            <Meta title={t("dashboard.crossBorder")} />
          </Card>
        </Col> */}
      </Row>
    </div>
  );
}
