import React, { useState } from "react";
import { Tabs } from "antd";
import WorkPermitsTable from "./workPermitsTable";
const { TabPane } = Tabs;

export default function orderTable() {
  const [type, setType] = useState("Ikamet Kart");
  return (
    <Tabs onTabClick={(e) => setType(e)}>
      <TabPane tab={"Ikamet Kart"} key="Ikamet Kart">
        <WorkPermitsTable type={type} />
      </TabPane>
      <TabPane tab={"AFAD Kart"} key="AFAD Kart">
        <WorkPermitsTable type={type} />
      </TabPane>
      <TabPane tab={"Turk Vatandasi"} key="Turk Vatandasi">
        <WorkPermitsTable type={type} />
      </TabPane>
    </Tabs>
  );
}
