import { createSlice, createAsyncThunk, PayloadAction } from "@reduxjs/toolkit";
import { supabase } from "../../supabaseClient"; // Adjust the import path as needed
import { notification } from "../../db/interfaces"; // Adjust the import path as needed

interface NotificationSliceState {
  data: notification[];
  status: "loading" | "succeeded" | "failed" | "idle";
  error: string | null;
}

const initialState: NotificationSliceState = {
  data: [],
  status: "idle",
  error: null,
};

// Fetch all notification records
export const fetchAllNotifications = createAsyncThunk<notification[], void, { rejectValue: string }>(
  "notification/fetchAllNotifications",
  async (_, thunkApi) => {
    const { data, error } = await supabase.from("notifications").select("*");
    if (error) {
      return thunkApi.rejectWithValue(error.message);
    }
    return data as notification[];
  }
);

// Fetch notifications by user id
export const fetchNotificationsByOrderId = createAsyncThunk<notification[], string, { rejectValue: string }>(
  "notification/fetchNotificationsByOrderId",
  async (orderId, thunkApi) => {
    const { data, error } = await supabase.from("notifications").select("*").eq("orderId", orderId);

    if (error) {
      return thunkApi.rejectWithValue(error.message);
    }

    return data as notification[];
  }
);

// Fetch notifications by user id
export const fetchNotificationsByUid = createAsyncThunk<notification[], string, { rejectValue: string }>(
  "notification/fetchNotificationsByUid",
  async (uid, thunkApi) => {
    const { data, error } = await supabase.from("notifications").select("*").eq("notifyTo", uid).eq("read", false);

    if (error) {
      return thunkApi.rejectWithValue(error.message);
    }

    return data as notification[];
  }
);

// Add a new notification
export const addNotification = createAsyncThunk<notification, notification, { rejectValue: string }>(
  "notification/addNotification",
  async (newNotification, thunkApi) => {
    const { data: insertData, error: insertError } = await supabase
      .from("notifications")
      .insert(newNotification)
      .select()
      .single();
    if (insertError) {
      return thunkApi.rejectWithValue(insertError.message);
    }

    return insertData as notification;
  }
);

// Edit a notification
export const editNotification = createAsyncThunk<notification, notification, { rejectValue: string }>(
  "notification/editNotification",
  async (updatedNotification, thunkApi) => {
    const { data: updateData, error: updateError } = await supabase
      .from("notifications")
      .update(updatedNotification)
      .eq("id", updatedNotification.id)
      .select()
      .single();

    if (updateError) {
      return thunkApi.rejectWithValue(updateError.message);
    }

    return updateData as notification;
  }
);

export const editNotificationByOrderId = createAsyncThunk<notification, any, { rejectValue: string }>(
  "notification/editNotificationByOrderId",
  async ({ updatedNotification, orderId }, thunkApi) => {
    const { data: updateData, error: updateError } = await supabase
      .from("notifications")
      .update(updatedNotification)
      .eq("orderId", orderId)
      .select();

    if (updateError) {
      return thunkApi.rejectWithValue(updateError.message);
    }

    return updateData[0] as notification;
  }
);

// Delete a notification
export const deleteNotification = createAsyncThunk<string, string, { rejectValue: string }>(
  "notification/deleteNotification",
  async (id, thunkApi) => {
    const { error } = await supabase.from("notifications").delete().eq("id", id);

    if (error) {
      return thunkApi.rejectWithValue(error.message);
    }

    return id;
  }
);

// Delete all notifications by order id
export const deleteNotificationsByOrderId = createAsyncThunk<string, string, { rejectValue: string }>(
  "notification/deleteNotificationsByOrderId",
  async (orderId, thunkApi) => {
    const { error } = await supabase.from("notifications").delete().eq("orderId", orderId);

    if (error) {
      return thunkApi.rejectWithValue(error.message);
    }

    return orderId;
  }
);

// Real-Time Actions
export const addNotificationRealTime = (newNotification: notification): PayloadAction<notification> => ({
  type: "notification/addNotificationRealTime",
  payload: newNotification,
});

export const editNotificationRealTime = (updatedNotification: notification): PayloadAction<notification> => ({
  type: "notification/editNotificationRealTime",
  payload: updatedNotification,
});

export const deleteNotificationRealTime = (id: string): PayloadAction<string> => ({
  type: "notification/deleteNotificationRealTime",
  payload: id,
});

// Slice definition
const notificationSlice = createSlice({
  name: "notification",
  initialState,
  reducers: {
    addNotificationRealTime(state, action: PayloadAction<notification>) {
      const exists = state.data.some((notification) => notification.id === action.payload.id);
      if (!exists) {
        state.data.push(action.payload);
      }
      state.error = null;
    },
    editNotificationRealTime(state, action: PayloadAction<notification>) {
      const index = state.data.findIndex((notification) => notification.id === action.payload.id);
      if (index !== -1) {
        const isDifferent = JSON.stringify(state.data[index]) !== JSON.stringify(action.payload);
        if (isDifferent) {
          state.data[index] = action.payload;
        }
      }
      state.error = null;
    },
    deleteNotificationRealTime(state, action: PayloadAction<string>) {
      const exists = state.data.some((notification) => notification.id === action.payload);
      if (exists) {
        state.data = state.data.filter((notification) => notification.id !== action.payload);
      }
      state.error = null;
    },
  },
  extraReducers: (builder) => {
    builder
      // Handle fetchAllNotifications
      .addCase(fetchAllNotifications.pending, (state) => {
        state.status = "loading";
      })
      .addCase(fetchAllNotifications.fulfilled, (state, action: PayloadAction<notification[]>) => {
        state.status = "succeeded";
        state.data = action.payload;
        state.error = null;
      })
      .addCase(fetchAllNotifications.rejected, (state, action: PayloadAction<string | undefined>) => {
        state.status = "failed";
        state.error = action.payload || "Failed to fetch notifications.";
      })

      // Handle fetchNotificationsByOrderId
      .addCase(fetchNotificationsByOrderId.pending, (state) => {
        state.status = "loading";
      })
      .addCase(fetchNotificationsByOrderId.fulfilled, (state, action: PayloadAction<notification[]>) => {
        state.status = "succeeded";
        state.data = action.payload;
        state.error = null;
      })
      .addCase(fetchNotificationsByOrderId.rejected, (state, action: PayloadAction<string | undefined>) => {
        state.status = "failed";
        state.error = action.payload || "Failed to fetch notifications by user.";
      })

      // Handle fetchNotificationsByUid
      .addCase(fetchNotificationsByUid.pending, (state) => {
        state.status = "loading";
      })
      .addCase(fetchNotificationsByUid.fulfilled, (state, action: PayloadAction<notification[]>) => {
        state.status = "succeeded";
        state.data = action.payload;
        state.error = null;
      })
      .addCase(fetchNotificationsByUid.rejected, (state, action: PayloadAction<string | undefined>) => {
        state.status = "failed";
        state.error = action.payload || "Failed to fetch notifications by user.";
      })

      // Handle addNotification
      .addCase(addNotification.pending, (state) => {})
      .addCase(addNotification.fulfilled, (state, action: PayloadAction<notification>) => {
        state.data.push(action.payload);
        state.error = null;
      })
      .addCase(addNotification.rejected, (state, action: PayloadAction<string | undefined>) => {
        state.error = action.payload || "Failed to add notification.";
      })

      // Handle editNotification
      .addCase(editNotification.pending, (state) => {})
      .addCase(editNotification.fulfilled, (state, action: PayloadAction<notification>) => {
        const index = state.data.findIndex((notification) => notification.id === action.payload.id);
        if (index !== -1) {
          state.data[index] = action.payload;
        }
        state.error = null;
      })
      .addCase(editNotification.rejected, (state, action: PayloadAction<string | undefined>) => {
        state.status = "failed";
        state.error = action.payload || "Failed to edit notification.";
      })
      // Handle editNotificationByOrderId
      .addCase(editNotificationByOrderId.pending, (state) => {})
      .addCase(editNotificationByOrderId.fulfilled, (state, action: PayloadAction<notification>) => {
        const index = state.data.findIndex((notification) => notification.id === action.payload.id);
        if (index !== -1) {
          state.data[index] = action.payload;
        }
        state.error = null;
      })
      .addCase(editNotificationByOrderId.rejected, (state, action: PayloadAction<string | undefined>) => {
        state.status = "failed";
        state.error = action.payload || "Failed to edit notification.";
      })

      // Handle deleteNotification
      .addCase(deleteNotification.pending, (state) => {})
      .addCase(deleteNotification.fulfilled, (state, action: PayloadAction<string>) => {
        state.data = state.data.filter((notification) => notification.id !== action.payload);
        state.error = null;
      })
      .addCase(deleteNotification.rejected, (state, action: PayloadAction<string | undefined>) => {
        state.error = action.payload || "Failed to delete notification.";
      })

      // Handle deleteNotificationsByOrderId
      .addCase(deleteNotificationsByOrderId.pending, (state) => {})
      .addCase(deleteNotificationsByOrderId.fulfilled, (state, action: PayloadAction<string>) => {
        state.data = state.data.filter((notification) => notification.id !== action.payload);
        state.error = null;
      })
      .addCase(deleteNotificationsByOrderId.rejected, (state, action: PayloadAction<string | undefined>) => {
        state.error = action.payload || "Failed to delete notification.";
      });
  },
});

// Real-time subscription function
export const subscribeToNotifications = (dispatch: any) => {
  const subscription = supabase
    .channel("public:notifications")
    .on("postgres_changes", { event: "*", schema: "public", table: "notifications" }, (payload) => {
      const newRecord = payload.new as notification;
      const oldRecord = payload.old as notification;
      switch (payload.eventType) {
        case "INSERT":
          dispatch(addNotificationRealTime(newRecord));
          break;
        case "UPDATE":
          dispatch(editNotificationRealTime(newRecord));
          break;
        case "DELETE":
          dispatch(deleteNotificationRealTime(oldRecord.id ?? ""));
          break;
        default:
          break;
      }
    })
    .subscribe();

  return () => {
    subscription.unsubscribe();
  };
};

export default notificationSlice.reducer;
