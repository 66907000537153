import { fetchProjectsInBatches } from "../features/projects/projectsSlice";
import { RootState, store } from "../store";

const initializeProjectsData = async () => {
  const state: RootState = store.getState();
  const { loaded } = state.projects;

  // Only dispatch fetch if the data hasn't been loaded yet
  if (!loaded) {
    await store.dispatch(fetchProjectsInBatches());
  }
};

// Immediately call to ensure data is fetched
initializeProjectsData();

export const getProjectsData = () => {
  const state: RootState = store.getState();
  const { globalProjectsData, loaded } = state.projects;
  // if (!loaded) {
  //   console.warn("Projects data is not loaded yet.");
  // }

  return {
    globalProjectsData,
  };
};

// Initial export values, populated from the Redux store
export let globalProjectsData = getProjectsData().globalProjectsData;

// Subscribe to store updates and dynamically update exports when the Redux state changes
store.subscribe(() => {
  const { globalProjectsData: updatedGlobalProjectsData } = getProjectsData();

  // Update the exports with the latest values from the Redux store
  globalProjectsData = updatedGlobalProjectsData;
});
