import React, { useState, useEffect } from "react";
import Paper from "@material-ui/core/Paper";
import { Spin, Button, DatePicker, Select } from "antd";
const { Option } = Select;
import DelayModel from "../models/delayModel";
import { useHistory } from "react-router-dom";
import moment from "moment";
import { delay } from "../../db/interfaces";
import { groupBy, map, sumBy, uniq } from "lodash";
import DevTable from "../../helpers/devex-table";
import { OFFICES_ID_NAME } from "../../global/officeList";
import { globalUsersData } from "../../global/usersList";
import { useAppDispatch, useAppSelector } from "../../hooks/reduxHooks";
import { fetchDelaysByOfficeAndMonth, subscribeToDelays } from "../../features/delays/delaysSlice";
import { setFilterValue } from "../../features/filters/filtersSlice";
const { MonthPicker } = DatePicker;

export default function UsersDelay() {
  const { filterValues } = useAppSelector((state) => state.filters);
  const { data: delaysData, status, error } = useAppSelector((store) => store.delays);
  const { currentUser } = useAppSelector((state) => state.auth);
  const dispatch = useAppDispatch();
  const history = useHistory();
  const [UID, setUID] = useState("");
  const [modalState, setModalState] = useState(false);
  const [filterTime, setFilterTime] = useState(moment());
  const [defaultHiddenColumnNames] = useState(["managerUID", "gender", "department"]);
  const [officeId, setOfficeId] = useState(filterValues["officeId"] ?? "Select an office...");

  useEffect(() => {
    dispatch(fetchDelaysByOfficeAndMonth({ officeId, month: filterTime.format("MMM.YYYY").toString() }));
    const unsubscribe = subscribeToDelays(dispatch);
    return () => {
      unsubscribe();
    };
  }, [dispatch, officeId, filterTime]);

  if (status == "loading") return <Spin />;

  const groupedDelaysByUser = groupBy(
    delaysData.map((d, index) => {
      return {
        ...d,
        id: d.id,
        index: index + 1,
      };
    }),
    (g) => g.userUID
  );

  const data = map(groupedDelaysByUser, (delays, userUID) => {
    return {
      id: userUID,
      userUID,
      office: delays[0].office,
      totalDelays: sumBy(delays, (x) => x.min),
      todayDelay:
        sumBy(
          delays.filter((del) => {
            return moment(del.dayDate).format("DD-MM-YYYY").toString() == moment().format("DD-MM-YYYY").toString();
          }),
          (x) => x.min
        ) ?? 0,
    };
  });

  const columnsExport = [
    {
      name: "userUID",
      title: "UID",
    },
    {
      name: "fullName",
      title: "Employee Name",
      getCellValue: (row: delay) => globalUsersData[row.userUID ?? ""]?.fullName,
    },

    {
      name: "todayDelay",
      title: "Today Delay",
    },
    {
      name: "totalDelays",
      title: "Total Delays",
    },
  ];
  const columns = [
    {
      name: "userUID",
      title: "UID",
    },

    {
      name: "fullName",
      title: "Employee Name",
      getCellValue: (row: delay) => globalUsersData[row.userUID ?? ""]?.fullName,
    },

    {
      name: "todayDelay",
      title: "Today Delay",
    },
    {
      name: "totalDelays",
      title: "Total Delays",
    },

    {
      name: "actionView",
      title: "Details",
      getCellValue: (r: delay) => {
        return (
          <div>
            <Button
              type="primary"
              onClick={() => {
                history.push(`/user-delay/${r.userUID}`);
              }}
            >
              Delay Records
            </Button>
          </div>
        );
      },
    },
    {
      name: "fingerPrint",
      title: "Finger Print Records",
      getCellValue: (r: delay) => {
        return (
          <div>
            <Button
              type="primary"
              onClick={() => {
                history.push(`/fingerPrint/${r.userUID}`);
              }}
            >
              Finger Prints
            </Button>
          </div>
        );
      },
    },
    {
      name: "action",
      title: "Action",
      getCellValue: (r: delay) => {
        return currentUser?.fullControlOffices?.includes(r.office) ? (
          <Button
            type="danger"
            onClick={() => {
              setModalState(true);
              setUID(r.userUID);
            }}
          >
            Delay
          </Button>
        ) : null;
      },
    },
  ];

  return error ? (
    <p>{error}</p>
  ) : (
    <div>
      {modalState ? <DelayModel modalState={modalState} setModalState={setModalState} UID={UID} /> : null}
      <MonthPicker
        placeholder="Select month"
        value={filterTime}
        format={"MM-YYYY"}
        onChange={(e) => setFilterTime(moment(e))}
      />

      <Select
        filterOption={(inputValue, option) => {
          return (option.props.children as any).toLowerCase().indexOf(inputValue.toLowerCase()) >= 0;
        }}
        size="default"
        placeholder="Search for Office!"
        style={{ width: "25%", paddingBottom: "1%", paddingRight: "1%" }}
        showSearch
        value={officeId}
        onChange={(e) => {
          setOfficeId(e);
          dispatch(setFilterValue({ key: "officeId", value: e }));
        }}
      >
        {OFFICES_ID_NAME.map((d) => {
          return <Option value={d.officeId}>{d.officeName}</Option>;
        })}
      </Select>

      <Paper>
        <DevTable
          data={data}
          columns={columns}
          defaultHiddenColumnNames={defaultHiddenColumnNames}
          tableName="Users_Delays"
          selected={false}
          typicalTable={false}
          columnsExport={columnsExport}
        />
      </Paper>
    </div>
  );
}
