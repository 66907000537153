import React, { useState, useEffect } from "react";
import { Button, DatePicker, Drawer, Form, message, Select } from "antd";
import moment from "moment";
const { Option } = Select;
import { useFormik } from "formik";
import * as Yup from "yup";
import { useTranslation } from "react-i18next";
import { paymentType } from "../../db/interfaces";
import { globalUsersData, USERS_ID_FULL_NAME } from "../../global/usersList";
import { useAppDispatch, useAppSelector } from "../../hooks/reduxHooks";
import { editPaymentType } from "../../features/paymentTypes/paymentTypesSlice";

interface Props {
  paymentType: paymentType;
  modalState: boolean;
  setModalState: React.Dispatch<React.SetStateAction<boolean>>;
}
const projectSchema = Yup.object().shape({});

function EditPaymentType(props: Props) {
  const dispatch = useAppDispatch();
  const { currentUser } = useAppSelector((state) => state.auth);

  const { t } = useTranslation();
  const { modalState, setModalState, paymentType } = props;
  const [loading, setLoading] = useState(false);

  const uid = currentUser.id;
  const paymentTypeData = paymentType;

  const formik = useFormik({
    initialValues: {
      uid: "",
      office: "",
      startDate: moment(),
      endDate: moment(),
      paymentType: "",
    },
    validationSchema: projectSchema,
    onSubmit: async (values) => {
      try {
        await dispatch(
          editPaymentType({
            id: paymentType.id,
            startDate: values.startDate as any,
            endDate: moment().add(1, "years") as any,
            createdAt: moment() as any,
            by: uid as string,
            office: globalUsersData[uid ?? ""]?.office,
            uid: values.uid,
            paymentType: values.paymentType,
          })
        ).unwrap();
        message.success("Payment type updated successfully");
        setModalState(false);
        setLoading(false);
      } catch (error) {
        setLoading(false);
        console.error("Error writing document: ", error);
        message.error("Failed");
      }
    },
  });
  const [loaded, setLoaded] = useState(true);
  useEffect(() => {
    if (paymentType && loaded) {
      formik.setValues({
        uid: paymentTypeData.uid,
        paymentType: paymentTypeData.paymentType,
        startDate: moment(paymentTypeData.startDate),
        endDate: moment(paymentTypeData.endDate),
      } as any);
      setLoaded(false);
    }
  }, [paymentType, formik, loaded]);

  const enterLoading = () => {
    setLoading(true);
  };
  const dateFormat = "YYYY/MM/DD";

  return (
    <Drawer
      title={t("general.editPaymentType")}
      width={500}
      height={350}
      visible={modalState}
      placement={"right"}
      onClose={() => setModalState(false)}
    >
      <Form onSubmit={formik.handleSubmit}>
        <Form.Item label={t("general.employee")}>
          <Select
            filterOption={(inputValue, option) => {
              return (option.props.children as any).toLowerCase().indexOf(inputValue.toLowerCase()) >= 0;
            }}
            showSearch
            value={formik.values.uid}
            onChange={(e) => {
              formik.setFieldValue("uid", e);
            }}
          >
            {USERS_ID_FULL_NAME?.map((d) => {
              return <Option value={d.uid}>{d.fullName}</Option>;
            })}
          </Select>
        </Form.Item>

        <Form.Item label={t("general.paymentType")}>
          <Select
            placeholder="Select Payment Type"
            showSearch
            value={formik.values.paymentType}
            onChange={(e) => {
              formik.setFieldValue("paymentType", e);
            }}
          >
            <Option value={"transfer"}>Transfer</Option>
            <Option value={"cash"}>Cash</Option>
          </Select>
        </Form.Item>

        <Form.Item label={t("general.startDate")}>
          <DatePicker
            value={formik.values.startDate}
            format={dateFormat}
            onChange={(e) => formik.setFieldValue("startDate", e)}
          />{" "}
        </Form.Item>

        <div>
          <Button
            className="btn blue lighten-1 z-depth-0"
            onClick={() => {
              enterLoading();
            }}
            loading={loading}
            type="primary"
            htmlType="submit"
            disabled={!formik.isValid}
          >
            {t("general.save")}
          </Button>
        </div>
      </Form>
    </Drawer>
  );
}

export default EditPaymentType;
