import { createSlice, createAsyncThunk, PayloadAction } from "@reduxjs/toolkit";
import { supabase } from "../../supabaseClient"; // Adjust the import path as needed
import { users } from "../../db/interfaces";
import { defaultData } from "../../components/Editor/constants";
interface Statics {
  syria: number;
  turkey: number;
  caseNo: number;
}
interface usersSliceState {
  data: users[];
  allUsers: users[];
  status: "loading" | "succeeded" | "failed" | "idle";
  error: string | null;
  globalUsersData: Record<string, users>;
  USERS_ID_FULL_NAME: { uid: string; fullName: string }[];
  loaded: boolean;
  statics: Statics;
}

const initialState: usersSliceState = {
  data: [],
  allUsers: [],

  status: "idle",
  error: null,
  globalUsersData: {},
  USERS_ID_FULL_NAME: [],
  loaded: false,
  statics: { syria: 0, turkey: 0, caseNo: 0 },
};

// Fetch List and global users
export const fetchUsersInBatches = createAsyncThunk("users/fetchUsersInBatches", async (_, thunkApi) => {
  try {
    // const { data, error } = await supabase.rpc("fetch_users_by_offices_permissions");
    const { data, error } = await supabase.from("users").select("*");

    if (error) {
      throw new Error(error.message);
    }

    return data;
  } catch (err: any) {
    return thunkApi.rejectWithValue(err.message);
  }
});

// Thunk to fetch statics by id = 1
export const fetchStatics = createAsyncThunk<Statics, void, { rejectValue: string }>(
  "users/fetchStatics",
  async (_, thunkApi) => {
    const { data, error } = await supabase.from("statics").select("*");
    if (error) {
      return thunkApi.rejectWithValue(error.message);
    }

    return data[0] as Statics;
  }
);

// Async thunk to fetch users by officeId
export const fetchUsersByOfficeId = createAsyncThunk<users[], string, { rejectValue: string }>(
  "users/fetchUsersByOfficeId",
  async (officeId: string, thunkApi) => {
    const { data, error } = await supabase.from("users").select("*").eq("office", officeId);

    if (error) {
      return thunkApi.rejectWithValue(error.message);
    }

    return data as users[];
  }
);

// Add a new User record
export const addUser = createAsyncThunk<users, users, { rejectValue: string }>(
  "users/addUser",
  async (newUser, thunkApi) => {
    const supabaseUser = {
      _id: newUser.id,
      _uid: newUser.uid,
      _father_name: newUser.fatherName,
      _mother_name: newUser.motherName,
      _salary: newUser.salary,
      _currency: newUser.currency,
      _starting_date: newUser.startingDate,
      _ending_date: newUser.endingDate,
      _email: newUser.email,
      _password: newUser.password,
      _first_name: newUser.firstName,
      _last_name: newUser.lastName,
      _gender: newUser.gender,
      _arabic_full_name: newUser.arabicFullName,
      _date_of_birth: newUser.dateOfBirth,
      _place_of_birth: newUser.placeOfBirth,
      _permanent_address: newUser.permanentAddress,
      _current_address: newUser.currentAddress,
      _phone_number: newUser.phoneNumber,
      _identity_no: newUser.identityNo,
      _emergency_contact_1: newUser.emergencyContact1,
      _emergency_contact_2: newUser.emergencyContact2,
      _bank_account_name: newUser.bankAccountName,
      _bank_name: newUser.bankName,
      _iban: newUser.IBAN,
      _bank_account_number: newUser.bankAccountNumber,
      _bank_first_name: newUser.bankFirstName,
      _bank_last_name: newUser.bankLastName,
      _customer_number: newUser.customerNumber,
      _vacation_compensation_score: newUser.vacationCompensationScore,
      _vacation_death_score: newUser.vacationDeathScore,
      _vacation_marriage_score: newUser.vacationMarriageScore,
      _vacation_maternity_score: newUser.vacationMaternityScore,
      _vacation_paternity_score: newUser.vacationPaternityScore,
      _manager_uid: newUser.managerUID,
      _office: newUser.office,
      _position: newUser.position,
      _department: newUser.department,
      _vacation_annual_score: newUser.vacationAnnualScore,
      _vacation_sick_score: newUser.vacationSickScore,
      _status: newUser.status,
      _admin: newUser.admin,
      _admin_hr: newUser.adminHR,
      _admin_law: newUser.adminLaw,
      _ceo: newUser.CEO,
      _resource_no: newUser.resourceNO,
      _region: newUser.region,
      _ida_allocation_percentage: newUser.idaAllocationPercentage,
      _employment_type: newUser.employmentType,
      _part_time_schedule: newUser.partTimeSchedule,
      _payment_type: newUser.paymentType,
      _sign_in_type: newUser.signInType,
      _vacation_ability: newUser.vacationAbility,
      _fp_id: newUser.FPId,
      _signature_url: newUser.signatureURL,
      _token: newUser.token,
      _mobile_token: newUser.mobileToken,
      _personal_photo: newUser.personalPhoto,
      _firestore_id: newUser.firestore_id,
      _username: newUser.username,
      _viewers: newUser.viewers,
      _viewers_offices: newUser.viewersOffices,
      _admin_offices: newUser.adminOffices,
      _hr_offices: newUser.HROffices,
      _hr_projects_managers_offices: newUser.HRProjectsManagersOffices,
      _by: newUser.by,
      _outputdata: defaultData({ firstName: newUser.firstName }),
    };
    const { data: insertData, error: insertError } = await supabase.rpc("add_full_user_with_logs", supabaseUser);

    // const { error: authError } = await supabase.functions.invoke("create-account", {
    //   method: "POST",
    //   headers: {
    //     "Content-Type": "application/json",
    //     "Access-Control-Allow-Origin": "*",
    //   },
    //   body: JSON.stringify({
    //     email: `${supabaseUser._id}@ida-org.com`,
    //     password: "ida12345",
    //   }),
    // });

    // if (authError) {
    //   console.error("Error creating user:", authError.message);
    // } else {
    //   console.log("User created successfully");
    // }

    if (insertError) {
      return thunkApi.rejectWithValue(insertError.message);
    }

    try {
      const response = await fetch(`${process.env.VITE_SUPABASE_URL}/functions/v1/create-account`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${process.env.SUPABASE_SERVICE_ROLE_KEY}`,
        },
        body: JSON.stringify({
          email: `${supabaseUser._id}@ida-org.com`,
          password: "ida12345",
        }),
      });

      if (!response.ok) {
        const errorText = await response.text();
        console.log({ errorText });
        console.error("Error creating user in Supabase Auth:", errorText);
        throw new Error(errorText);
      }

      const createdUser = await response.json();
      console.log("User created in Supabase Auth successfully:", createdUser);
    } catch (error: any) {
      console.log({ error });

      console.error("Error calling Edge Function for user creation:", error.message);
      // Optionally, roll back the database insert if needed
    }

    return insertData as users;
  }
);

// Delete a User record
export const deleteUser = createAsyncThunk<string, string, { rejectValue: string }>(
  "users/deleteUser",
  async (_uid, thunkApi) => {
    const { error } = await supabase.rpc("delete_full_user_with_logs", { _uid });
    // TODO: disable email
    if (error) {
      return thunkApi.rejectWithValue(error.message);
    }
    return _uid;
  }
);

// Edit a User record
export const editUser = createAsyncThunk<any, any, { rejectValue: string }>(
  "users/editUser",
  async (updatedUser, thunkApi) => {
    const { data: updateData, error: updateError } = await supabase
      .from("users")
      .update(updatedUser)
      .eq("id", updatedUser.id)
      .select()
      .single();
    if (updateError) {
      return thunkApi.rejectWithValue(updateError.message);
    }

    return updateData as users;
  }
);

// Real-Time Actions
export const addUserRealTime = (newUser: users): PayloadAction<users> => ({
  type: "users/addUserRealTime",
  payload: newUser,
});

export const editUserRealTime = (updatedUser: users): PayloadAction<users> => ({
  type: "users/editUserRealTime",
  payload: updatedUser,
});

export const deleteUserRealTime = (id: string): PayloadAction<string> => ({
  type: "users/deleteUserRealTime",
  payload: id,
});

// Slice definition
const usersSlice = createSlice({
  name: "users",
  initialState,
  reducers: {
    addUserRealTime(state, action: PayloadAction<users>) {
      const newUser = action.payload;
      const exists = state.data.some((user) => user.id === newUser.id);
      if (!exists) {
        state.data.push(newUser);
        state.globalUsersData[newUser.id ?? ""] = {
          ...newUser,
          fullName: newUser.firstName + " " + newUser.lastName,
          fullControlOffices: [
            ...newUser.HROffices,
            ...newUser.adminOffices,
            ...newUser.HRProjectsManagersOffices,
            ...newUser.viewersOffices,
          ],
        };
        state.USERS_ID_FULL_NAME.push({
          uid: newUser.id ?? "",
          fullName: `${newUser.firstName} ${newUser.fatherName} ${newUser.lastName} _ ${newUser.arabicFullName} _ ${newUser.resourceNO}`,
        });
      }
      state.error = null;
    },
    editUserRealTime(state, action: PayloadAction<users>) {
      const updatedUser = action.payload;
      state.globalUsersData[updatedUser.id ?? ""] = {
        ...updatedUser,
        fullName: updatedUser.firstName + " " + updatedUser.lastName,
        fullControlOffices: [
          ...updatedUser.HROffices,
          ...updatedUser.adminOffices,
          ...updatedUser.HRProjectsManagersOffices,
          ...updatedUser.viewersOffices,
        ],
      };

      const index = state.data.findIndex((user) => user.id === updatedUser.id);
      if (index !== -1) {
        const isDifferent = JSON.stringify(state.data[index]) !== JSON.stringify(updatedUser);
        if (isDifferent) {
          state.data[index] = updatedUser;
        }
      }

      const indexForAllUsers = state.allUsers.findIndex((user) => user.id === updatedUser.id);
      if (indexForAllUsers !== -1) {
        const isDifferent = JSON.stringify(state.allUsers[indexForAllUsers]) !== JSON.stringify(updatedUser);
        if (isDifferent) {
          state.allUsers[indexForAllUsers] = updatedUser;
        }
      }

      const indexForUsersList = state.USERS_ID_FULL_NAME.findIndex((user) => user.uid === updatedUser.id);
      if (indexForUsersList !== -1) {
        const isDifferent = JSON.stringify(state.USERS_ID_FULL_NAME[indexForUsersList]) !== JSON.stringify(updatedUser);
        if (isDifferent) {
          state.USERS_ID_FULL_NAME[indexForUsersList] = {
            uid: updatedUser.id ?? "",
            fullName: `${updatedUser.firstName} ${updatedUser.fatherName} ${updatedUser.lastName} _ ${updatedUser.arabicFullName} _ ${updatedUser.resourceNO}`,
          };
        }
      }

      state.error = null;
    },
    deleteUserRealTime(state, action: PayloadAction<string>) {
      const exists = state.data.some((user) => user.id === action.payload);
      if (exists) {
        state.data = state.data.filter((user) => user.id !== action.payload);
      }
      state.error = null;
    },
  },
  extraReducers: (builder) => {
    builder
      // Handle fetchUsersByOfficeId
      .addCase(fetchUsersByOfficeId.pending, (state) => {
        state.status = "loading";
      })
      .addCase(fetchUsersByOfficeId.fulfilled, (state, action) => {
        state.status = "succeeded";
        state.data = action.payload;
        state.error = null;
      })
      .addCase(fetchUsersByOfficeId.rejected, (state, action: PayloadAction<string | undefined>) => {
        state.status = "failed";
        state.error = action.payload || "Failed to add user.";
      })
      // Handle fetchStatics
      .addCase(fetchStatics.pending, (state) => {})
      .addCase(fetchStatics.fulfilled, (state, action: PayloadAction<Statics>) => {
        state.statics = action.payload; // Update the statics in the state
        state.error = null;
      })
      .addCase(fetchStatics.rejected, (state, action: PayloadAction<string | undefined>) => {
        state.error = action.payload || "Failed to fetch statics.";
      })

      // Handle addUser
      .addCase(addUser.pending, (state) => {})
      .addCase(addUser.fulfilled, (state, action: PayloadAction<users>) => {
        state.error = null;
      })
      .addCase(addUser.rejected, (state, action: PayloadAction<string | undefined>) => {
        state.error = action.payload || "Failed to add user.";
      })
      // Handle deleteUser
      .addCase(deleteUser.pending, (state) => {})
      .addCase(deleteUser.fulfilled, (state, action: PayloadAction<string>) => {
        state.data = state.data.filter((user) => user.id !== action.payload);
        state.error = null;
      })
      .addCase(deleteUser.rejected, (state, action: PayloadAction<string | undefined>) => {
        state.error = action.payload || "Failed to delete user.";
      })
      // Handle editUser
      .addCase(editUser.pending, (state) => {})
      .addCase(editUser.fulfilled, (state, action: PayloadAction<users>) => {
        state.error = null;
      })
      .addCase(editUser.rejected, (state, action: PayloadAction<string | undefined>) => {
        state.status = "failed";
        state.error = action.payload || "Failed to edit user.";
      })
      // Handle fetchUsersInBatches
      .addCase(fetchUsersInBatches.fulfilled, (state, action) => {
        state.loaded = true;
        action.payload.forEach((user: any) => {
          state.globalUsersData[user.id] = {
            ...user,
            fullName: user.firstName + " " + user.lastName,
            fullControlOffices: [
              ...user.HROffices,
              ...user.adminOffices,
              ...user.HRProjectsManagersOffices,
              ...user.viewersOffices,
            ],
          };
        });
        state.USERS_ID_FULL_NAME = action.payload.map((user: any) => {
          return {
            uid: user.id,
            fullName: `${user.firstName} ${user.fatherName} ${user.lastName} _ ${user.arabicFullName} _ ${user.resourceNO}`,
          };
        });
        state.allUsers = action.payload;
      })
      .addCase(fetchUsersInBatches.rejected, (state, action) => {
        console.error("Error fetching users:", action.payload);
      });
  },
});

// Real-time subscription function
export const subscribeToUsers = (dispatch: any) => {
  const subscription = supabase
    .channel("public:users")
    .on("postgres_changes", { event: "*", schema: "public", table: "users" }, (payload) => {
      const newRecord = payload.new as users;
      const oldRecord = payload.old as users;
      switch (payload.eventType) {
        case "INSERT":
          dispatch(addUserRealTime(newRecord));
          break;
        case "UPDATE":
          dispatch(editUserRealTime(newRecord));
          break;
        case "DELETE":
          dispatch(deleteUserRealTime(oldRecord.id ?? ""));
          break;
        default:
          break;
      }
    })
    .subscribe();

  return () => {
    subscription.unsubscribe();
  };
};

export default usersSlice.reducer;
