import React from "react";
import { Button, message, Spin, Modal } from "antd";
import moment from "moment";
import * as Moment from "moment";
import { extendMoment } from "moment-range";
const moments = extendMoment(Moment);
import { useTranslation } from "react-i18next";
import { filter } from "lodash";
import { Calendar } from "../../db/interfaces";
import { RRule } from "rrule";
import { useAppDispatch, useAppSelector } from "../../hooks/reduxHooks";
import { supabase } from "../../supabaseClient";
import { AppDispatch } from "../../store";
import { editCalendarEvent, editCalendarEventsByOriginalId } from "../../features/calendar/calendarSlice";
import {  editNotificationByOrderId } from "../../features/notifications/notificationsSlice";

interface Props {
  modalState: boolean;
  setModalState: React.Dispatch<React.SetStateAction<boolean>>;
  docId: string;
  changedFields: any;
}

const testOverlapsRoomAndDates = async (
  docId: string,
  changedFields: any,
  eventDoc: Calendar,
  dispatch: AppDispatch
) => {
  const rRule = changedFields.rRule == undefined ? eventDoc.rRule : changedFields.rRule;

  const newStartDate = changedFields.startDate == undefined ? eventDoc.startDate : changedFields.startDate;
  const newEndDate = changedFields.endDate == undefined ? eventDoc.endDate : changedFields.endDate;

  const yesterday = moment(eventDoc.startDate).subtract(1, "days");
  const tomorrow = moment(eventDoc.startDate).add(1, "days");

  const { data, error } = await supabase
    .from("calendar")
    .select("*")
    .gt("startDate", moment(yesterday).format("YYYY-MM-DD"))
    .lt("startDate", moment(tomorrow).format("YYYY-MM-DD"))
    .eq("roomId", changedFields.roomId);

  if (error) throw error;

  let overlap = true;
  await data.forEach((doc) => {
    const oldStartDate = doc.startDate;
    const oldEndDate = doc.endDate;
    const dateOld: [Date, Date] = [oldStartDate, oldEndDate];
    const dateNew: [Date, Date] = [newStartDate, newEndDate];
    const rangeOld = moments.range(dateOld);
    const rangeNew = moments.range(dateNew);
    if (rangeOld.overlaps(rangeNew)) {
      overlap = false;
      console.log("overlaps-------", "dateOld:", dateOld, "dateNew:", dateNew);
    }
  });

  await data.forEach((d) => {
    if (d.id == docId) {
    } else {
      const oldStartDate = d.startDate;
      const oldEndDate = d.endDate;
      const dateOld: [Date, Date] = [oldStartDate, oldEndDate];
      const dateNew: [Date, Date] = [newStartDate, newEndDate];
      const rangeOld = moments.range(dateOld);
      const rangeNew = moments.range(dateNew);
      if (rangeOld.overlaps(rangeNew)) {
        overlap = false;
        console.log("overlaps-------", "dateOld:", dateOld, "dateNew:", dateNew);
      }
    }
  });
  if (overlap) {
    dispatch(
      editCalendarEvent({
        id: docId,
        ...changedFields,
        rRule: rRule,
      })
    ).then(() => message.success("Meeting Room and Date has been Updated Successfully!"));
  } else {
    alert("You can not edit the Meeting room and Date!!");
  }
  return overlap;
};
const testOverlapsRoom = async (docId: string, changedFields: any, eventDoc: Calendar, dispatch: AppDispatch) => {
  const rRule = changedFields.rRule == undefined ? eventDoc.rRule : changedFields.rRule;
  const newStartDate = moment(eventDoc.startDate).toDate();
  const newEndDate = moment(eventDoc.endDate).toDate();

  const yesterday = moment(eventDoc.startDate).subtract(1, "days");
  const tomorrow = moment(eventDoc.startDate).add(1, "days");

  const { data, error } = await supabase
    .from("calendar")
    .select("*")
    .gt("startDate", moment(yesterday).format("YYYY-MM-DD"))
    .lt("startDate", moment(tomorrow).format("YYYY-MM-DD"))
    .eq("roomId", changedFields.roomId);

  if (error) throw error;

  let overlap = true;
  data.forEach((doc) => {
    const oldStartDate = doc.startDate;
    const oldEndDate = doc.endDate;
    const dateOld: [Date, Date] = [oldStartDate, oldEndDate];
    const dateNew: [Date, Date] = [newStartDate, newEndDate];
    const rangeOld = moments.range(dateOld);
    const rangeNew = moments.range(dateNew);
    if (rangeOld.overlaps(rangeNew)) {
      overlap = false;
      console.log("overlaps-------", "dateOld:", dateOld, "dateNew:", dateNew);
    }
  });
  if (overlap) {
    dispatch(
      editCalendarEvent({
        id: docId,
        ...changedFields,
        rRule: rRule,
      })
    ).then(() => message.success("Meeting Info and Room have been Updated Successfully!"));
  } else {
    alert("You can not edit the Meeting room!!");
  }
  return overlap;
};
const testOverlapsDate = async (docId: string, changedFields: any, eventDoc: Calendar, dispatch: AppDispatch) => {
  const rRule = changedFields.rRule == undefined ? eventDoc.rRule : changedFields.rRule;
  const newStartDate = changedFields.startDate == undefined ? eventDoc.startDate : changedFields.startDate;
  const newEndDate = changedFields.endDate == undefined ? eventDoc.endDate : changedFields.endDate;
  const roomId = eventDoc.roomId;

  const yesterday = moment(eventDoc.startDate).subtract(1, "days");
  const tomorrow = moment(eventDoc.startDate).add(1, "days");

  const { data, error } = await supabase
    .from("calendar")
    .select("*")
    .gt("startDate", moment(yesterday).format("YYYY-MM-DD"))
    .lt("startDate", moment(tomorrow).format("YYYY-MM-DD"))
    .eq("roomId", roomId);

  if (error) throw error;

  let overlap = true;
  data.forEach((d) => {
    if (d.id == docId) {
    } else {
      const oldStartDate = d.startDate;
      const oldEndDate = d.endDate;
      const dateOld: [Date, Date] = [oldStartDate, oldEndDate];
      const dateNew: [Date, Date] = [newStartDate, newEndDate];
      const rangeOld = moments.range(dateOld);
      const rangeNew = moments.range(dateNew);
      if (rangeOld.overlaps(rangeNew)) {
        overlap = false;
        console.log("overlaps-------", "dateOld:", dateOld, "dateNew:", dateNew);
      }
    }
  });
  if (overlap) {
    dispatch(
      editCalendarEvent({
        id: docId,
        ...changedFields,
        rRule: rRule,
      })
    ).then(() => message.success("Meeting Info and Date have been Updated Successfully!"));
  } else {
    alert("You can not edit the Date!!");
  }
  return overlap;
};

function EditDialog(props: Props) {
  const { data: meetingsData, status: meetingsStatus } = useAppSelector((state) => state.calendar);
  const dispatch = useAppDispatch();

  const { t } = useTranslation();
  const { modalState, setModalState, docId, changedFields } = props;

  if (meetingsStatus == "loading") {
    return <Spin />;
  }

  const eventDoc = meetingsData?.find((meeting) => meeting.id == docId) as Calendar;
  const { rRule, endDate, isOriginal, originalId, isOnlyOneAppointment, startDate } = eventDoc as Calendar;

  const calendarSNObj = meetingsData.map((doc) => {
    return {
      originalId: doc.originalId,
      startDate: doc.startDate,
      id: doc.id,
      rRule: doc.rRule,
    };
  });
  const options = RRule.parseString(rRule);
  options.dtstart = new Date(moment(startDate).toDate());
  const rule = new RRule(options);

  const ruleDatesArray =
    rRule == undefined
      ? [{ startDate: moment(startDate), endDate: moment(endDate) }]
      : rule
          .all()
          .slice(0, rule.all().length < 50 ? rule.all().length : 50)
          .map((d) => {
            const day = d.getDate();
            const month = d.getMonth();
            const year = d.getFullYear();
            const startDateM: Date = new Date(moment(startDate).toDate());
            const endDateM: Date = new Date(moment(endDate).toDate());
            startDateM.setDate(day);
            startDateM.setMonth(month);
            startDateM.setFullYear(year);
            endDateM.setDate(day);
            endDateM.setMonth(month);
            endDateM.setFullYear(year);
            return { startDateM, endDateM };
          });

  const notificationEdit = async (calendarId: string) => {
    dispatch(
      editNotificationByOrderId({
        orderId: calendarId,
        updatedNotification: {
          orderId: calendarId,
          read: false,
          content: "The meeting you that have been invited to has been updated",
        },
      })
    );
  };

  if (isOnlyOneAppointment == false) {
    const editAll = async () => {
      if (changedFields.startDate || changedFields.endDate) {
        alert("You can't change date for All the Series, If you want to change date Please Click on (Only this)!");
      } else if (changedFields.roomId && !changedFields.startDate && !changedFields.endDate) {
        const yesterday = moment(startDate).subtract(300, "days");
        const tomorrow = moment(startDate).add(300, "days");

        const { data, error } = await supabase
          .from("calendar")
          .select("*")
          .gt("startDate", moment(yesterday).format("YYYY-MM-DD"))
          .lt("startDate", moment(tomorrow).format("YYYY-MM-DD"))
          .eq("roomId", changedFields.roomId);

        if (error) throw error;

        if (data && data.length === 0) {
          await dispatch(editCalendarEventsByOriginalId({ changes: changedFields, originalId: originalId }));
          await notificationEdit(originalId);
        } else {
          let overlap = true;
          data.forEach((e) => {
            const oldStartDate = e.startDate;
            const oldEndDate = e.endDate;
            ruleDatesArray.forEach((newObj: any) => {
              const newStartDate = newObj.startDateM;
              const newEndDate = newObj.endDateM;
              const dateOld: [Date, Date] = [oldStartDate, oldEndDate];
              const dateNew: [Date, Date] = [newStartDate, newEndDate];
              const rangeOld = moments.range(dateOld);
              const rangeNew = moments.range(dateNew);
              if (rangeOld.overlaps(rangeNew)) {
                overlap = false;
                console.log("overlaps-------", "dateOld:", dateOld, "dateNew:", dateNew);
              }
            });
          });
          if (overlap) {
            await dispatch(editCalendarEventsByOriginalId({ changes: changedFields, originalId: originalId }));
            await notificationEdit(originalId);
          } else {
            alert("Overlaps!!!, this time has been already reserved!");
          }
        }
        setModalState(false);
      } else {
        await dispatch(editCalendarEventsByOriginalId({ changes: changedFields, originalId: originalId }));
        await notificationEdit(originalId);
        setModalState(false);
      }
    };
    const editThisAndFollowing = async () => {
      const filteredArray = filter(calendarSNObj, (e) => e.startDate >= startDate);
      const filteredByOriginalId = filter(filteredArray, (e) => e.originalId === originalId);

      if (changedFields.startDate || changedFields.endDate) {
        alert("You can't change date for All the Series, If you want to change date Please Click on (Only this)!");
      } else if (changedFields.roomId && !changedFields.startDate && !changedFields.endDate) {
        const yesterday = moment(startDate).subtract(300, "days");
        const tomorrow = moment(startDate).add(300, "days");

        const { data, error } = await supabase
          .from("calendar")
          .select("*")
          .gt("startDate", moment(yesterday).format("YYYY-MM-DD"))
          .lt("startDate", moment(tomorrow).format("YYYY-MM-DD"))
          .eq("roomId", changedFields.roomId);

        if (error) throw error;

        if (data && data.length === 0) {
          await notificationEdit(originalId);
          await filteredByOriginalId.forEach(async (d) => {
            await dispatch(
              editCalendarEvent({
                id: d.id,
                ...changedFields,
                originalId: docId,
              })
            );
          });

          await dispatch(
            editCalendarEvent({
              id: docId,
              ...changedFields,
              isOriginal: true,
              originalId: docId,
            })
          );

          await notificationEdit(originalId);
        } else {
          let overlap = true;
          data.forEach((e) => {
            const oldStartDate = e.startDate;
            const oldEndDate = e.endDate;
            ruleDatesArray.forEach((newObj: any) => {
              const newStartDate = newObj.startDateM;
              const newEndDate = newObj.endDateM;
              const dateOld: [Date, Date] = [oldStartDate, oldEndDate];
              const dateNew: [Date, Date] = [newStartDate, newEndDate];
              const rangeOld = moments.range(dateOld);
              const rangeNew = moments.range(dateNew);
              if (rangeOld.overlaps(rangeNew)) {
                overlap = false;
                console.log("overlaps-------", "dateOld:", dateOld, "dateNew:", dateNew);
              }
            });
          });
          if (overlap) {
            await filteredByOriginalId.forEach(async (d) => {
              await dispatch(
                editCalendarEvent({
                  id: d.id,
                  ...changedFields,
                  originalId: docId,
                })
              );
            });
            await dispatch(
              editCalendarEvent({
                id: docId,
                ...changedFields,
                isOriginal: true,
                originalId: docId,
              })
            );

            await notificationEdit(originalId);
          } else {
            alert("Overlaps!!!, this time has been already reserved!");
          }
        }
        setModalState(false);
      } else {
        await filteredByOriginalId.forEach(async (d) => {
          const rRule = changedFields.rRule == undefined ? d.rRule : changedFields.rRule;

          await dispatch(
            editCalendarEvent({
              id: d.id,
              ...changedFields,
              originalId: docId,
              rRule: rRule,
            })
          );
        });
        await dispatch(
          editCalendarEvent({
            id: docId,
            ...changedFields,
            isOriginal: true,
            originalId: docId,
            rRule: rRule,
          })
        );

        await notificationEdit(docId);
      }
    };

    const editOnlyOne = async () => {
      if (changedFields.roomId && (changedFields.startDate || changedFields.endDate)) {
        const rRuleOrg = originalId == docId ? rRule : "";
        const test = testOverlapsRoomAndDates(docId, changedFields, eventDoc, dispatch);
        test.then(async (e) => {
          if (e) {
            setModalState(false);
            await dispatch(
              editCalendarEvent({
                id: docId,
                ...changedFields,
                isOriginal: true,
                originalId: docId,
                rRule: rRuleOrg,
                isOnlyOneAppointment: originalId == docId ? false : true,
              })
            );

            await notificationEdit(originalId);
          }
          setModalState(false);
        });
      } else if (changedFields.roomId && !changedFields.startDate && !changedFields.endDate) {
        const rRuleOrg = originalId == docId ? rRule : "";
        const test = testOverlapsRoom(docId, changedFields, eventDoc, dispatch);
        await test.then(async (e) => {
          if (e) {
            setModalState(false);
            await dispatch(
              editCalendarEvent({
                id: docId,
                ...changedFields,
                isOriginal: true,
                originalId: docId,
                rRule: rRuleOrg,
                isOnlyOneAppointment: originalId == docId ? false : true,
              })
            );

            await notificationEdit(originalId);
          }
          setModalState(false);
        });
      } else if ((changedFields.endDate || changedFields.startDate) && !changedFields.roomId) {
        const rRuleOrg = originalId == docId ? rRule : "";

        const test = testOverlapsDate(docId, changedFields, eventDoc, dispatch);
        test.then(async (e) => {
          if (e) {
            setModalState(false);
            await dispatch(
              editCalendarEvent({
                id: docId,
                ...changedFields,
                isOriginal: true,
                originalId: docId,
                rRule: rRuleOrg,
                isOnlyOneAppointment: originalId == docId ? false : true,
              })
            );
            await notificationEdit(originalId);
          }
          setModalState(false);
        });
      } else {
        const rRuleOrg = originalId == docId ? rRule : "";
        await dispatch(
          editCalendarEvent({
            id: docId,
            ...changedFields,
            originalId: docId,
            isOriginal: true,
            isOnlyOneAppointment: originalId == docId ? false : true,
            rRule: rRuleOrg,
          })
        ).then(async () => {
          await notificationEdit(originalId);
          message.success("Successfully Updated");
        });
      }
    };
    return (
      <Modal
        width={500}
        visible={modalState}
        onCancel={() => setModalState(false)}
        closable={true}
        footer={
          <div
            style={{
              textAlign: "right",
            }}
          >
            <Button type="danger" onClick={() => setModalState(false)} style={{ marginRight: 2, marginLeft: 0 }}>
              {t("general.close")}
            </Button>

            {changedFields.startDate || changedFields.endDate ? null : (
              <Button onClick={editThisAndFollowing} style={{ marginRight: 2, marginLeft: 0 }}>
                {t("general.thisAndFollowing")}
              </Button>
            )}
            {changedFields.startDate || changedFields.endDate ? null : (
              <Button onClick={editAll} style={{ marginRight: 2, marginLeft: 0 }}>
                {t("general.editAll")}{" "}
              </Button>
            )}
            <Button onClick={editOnlyOne} type="primary" style={{ marginLeft: 0 }}>
              {t("general.editOnlyThis")}
            </Button>
          </div>
        }
      >
        <div>{t("general.editSeries")}</div>
      </Modal>
    );
  } else {
    const editOnlyOne = async () => {
      if (changedFields.roomId && (changedFields.startDate || changedFields.endDate)) {
        testOverlapsRoomAndDates(docId, changedFields, eventDoc, dispatch);
      } else if (changedFields.roomId && !changedFields.startDate && !changedFields.endDate) {
        testOverlapsRoom(docId, changedFields, eventDoc, dispatch);
      } else if ((changedFields.endDate || changedFields.startDate) && !changedFields.roomId) {
        testOverlapsDate(docId, changedFields, eventDoc, dispatch);
      } else {
        const rRuleN = changedFields.rRule == undefined ? rRule : changedFields.rRule;
        if (changedFields.rRule) {
          await dispatch(
            editCalendarEvent({
              id: docId,
              ...changedFields,
              rRule: rRuleN,
            })
          ).then(() => message.success("Meeting and Rules Info have been Updated Successfully!"));
        } else {
          await dispatch(
            editCalendarEvent({
              id: docId,
              ...changedFields,
              rRule: rRuleN,
            })
          ).then(() => message.success("Meeting Info have been Updated Successfully!"));
        }
      }

      setModalState(false);
      await notificationEdit(originalId);
    };

    return (
      <Modal
        okText={t("general.yes")}
        cancelText={t("general.no")}
        width={500}
        visible={modalState}
        onCancel={() => setModalState(false)}
        onOk={editOnlyOne}
        closable={true}
      >
        <div>{t("general.editAppointment")}</div>
      </Modal>
    );
  }
}

export default EditDialog;
