import React, { useEffect, useState } from "react";
import { useHistory, useRouteMatch } from "react-router-dom";
import { Spin, Button, message, Descriptions, Badge, Popconfirm } from "antd";
import moment from "moment";
import { format } from "../../db/dateFormat";
import { useTranslation } from "react-i18next";
import EditOvertime from "./OverTimeModules/EditOvertime";
import { globalOfficesData } from "../../global/officeList";
import { globalMissionsData } from "../../global/missionList";
import { useAppDispatch, useAppSelector } from "../../hooks/reduxHooks";
import { deleteNotificationsByOrderId } from "../../features/notifications/notificationsSlice";
import { deleteDiscussionsByOrderId } from "../../features/discussions/discussionsSlice";
import { deleteOvertime, editOvertime, fetchOvertimeById } from "../../features/overtime/overtimeSlice";
import { globalUsersData } from "../../global/usersList";
import { editUser } from "../../features/users/usersSlice";
import Discussion from "../../discussion/discussion";

export default function OvertimeDetails() {
  const id = useRouteMatch<{ id: string }>().params.id;
  const { overtime, status: overtimeStatus, error: overtimeError } = useAppSelector((state) => state.overtime);
  const { currentUser } = useAppSelector((state) => state.auth);
  const uid = currentUser?.id as string;

  const dispatch = useAppDispatch();
  const { t } = useTranslation();
  const history = useHistory();
  const [modalState, setModalState] = useState(false);

  const deleteOrder = async () => {
    dispatch(deleteNotificationsByOrderId(id));
    dispatch(deleteDiscussionsByOrderId(id));
    dispatch(deleteOvertime(id)).then(() => history.push("/overtime"));
    message.success("Overtime has been deleted successfully!");
  };
  useEffect(() => {
    dispatch(fetchOvertimeById(id));
  }, [dispatch, id]);

  if (overtimeStatus == "loading") {
    return <Spin size="large" />;
  }

  const data = overtime;

  const userId = data?.employeeUID;
  const overtimeId = id;
  const approvalCEO = globalOfficesData[data.office ?? ""]?.approvalCEO;
  const employeeUID = data?.employeeUID;
  const managerUID = data?.managerUID;
  const adminUID = data?.adminUID;
  const HRUserUID = data?.HRUserUID;
  const CEOUID = data?.CEOUID;
  const officeId = data?.office;
  const status = data?.status;

  const empFullName = globalUsersData[employeeUID ?? ""]?.fullName;
  const managerFullName = globalUsersData[managerUID ?? ""]?.fullName;
  const adminFullName = globalUsersData[adminUID ?? ""]?.fullName;
  const HRFullName = globalUsersData[HRUserUID ?? ""]?.fullName;
  const ceoFUllName = globalUsersData[CEOUID ?? ""]?.fullName;

  const statusBadge =
    status == "Requested"
      ? "warning"
      : status == "Pending"
      ? "processing"
      : status == "Rejected" || status == "Canceled"
      ? "default"
      : status == "Accepted"
      ? "success"
      : "error";

  const dateFormat = "YYYY/MM/DD hh:mm:ss";
  const increment = (data.compensationHours * 1.5) / 8;

  const cancelOvertime = () => {
    dispatch(
      editUser({
        id: userId,
        vacationCompensationScore: globalUsersData[userId ?? ""]?.vacationCompensationScore - increment,
      })
    );
  };
  const addToVacationScore = async () => {
    dispatch(
      editUser({
        id: userId,
        vacationCompensationScore: globalUsersData[userId ?? ""]?.vacationCompensationScore + increment,
      })
    );
  };
  return overtimeError ? (
    <p>{overtimeError}</p>
  ) : (
    <div style={{ marginTop: "10px" }}>
      {modalState ? <EditOvertime modalState={modalState} setModalState={setModalState} overtimeId={id} overtime={overtime} /> : null}

      {/* //------------------- */}
      <Descriptions
        size={"default"}
        title={`Compensation Request`}
        layout="horizontal"
        bordered={true}
        column={{ xs: 8, sm: 6, md: 4 }}
      >
        <Descriptions.Item label={t("general.requestedAt")}>
          {moment(data.requestedAt).format(dateFormat)}
        </Descriptions.Item>
        <Descriptions.Item label={t("general.status")}>
          <Badge status={statusBadge} text={status} />
        </Descriptions.Item>
      </Descriptions>
      {/* //------------------- */}

      <Descriptions size={"default"} layout="horizontal" bordered={true} column={{ xs: 8, sm: 6, md: 4 }}>
        <Descriptions.Item label={t("general.fullName")} span={6}>
          {empFullName}
        </Descriptions.Item>
        <Descriptions.Item label={t("general.position")} span={2}>
          {data.position}{" "}
        </Descriptions.Item>
        <Descriptions.Item label={t("general.manager")} span={2}>
          {managerFullName}
        </Descriptions.Item>
        <Descriptions.Item label={t("general.location")} span={2}>
          {globalOfficesData[data.office ?? ""]?.officeName}
        </Descriptions.Item>
        <Descriptions.Item label={t("general.overtimeDate")} span={2}>
          {moment(data.overtimeDate).format(format)}{" "}
        </Descriptions.Item>
        <Descriptions.Item label={t("general.overtimeStartHours")} span={2}>
          {moment(data.overtimeStartHours).format("hh:mm a")}{" "}
        </Descriptions.Item>
        <Descriptions.Item label={t("general.overtimeEndHours")} span={2}>
          {moment(data.overtimeEndHours).format("hh:mm a")}{" "}
        </Descriptions.Item>
        <Descriptions.Item label={t("general.compensationHours")} span={2}>
          {data.compensationHours}{" "}
        </Descriptions.Item>
        <Descriptions.Item label={t("general.taskOvertime")} span={2}>
          {moment(data.taskOvertime).format(format)}{" "}
        </Descriptions.Item>

        <Descriptions.Item label={t("general.tasks")} span={6}>
          {data.tasks}{" "}
        </Descriptions.Item>

        <Descriptions.Item label={t("general.employeeSignatureDate")} span={2}>
          {data.employeeSignatureDate == null || data.employeeSignatureDate == "null"||data.employeeSignatureDate == ""  ? (
            <Button
              style={{ width: "150px" }}
              type="primary"
              onClick={() =>
                dispatch(
                  editOvertime({
                    id: overtimeId,
                    employeeSignatureDate: moment() as any,
                  } as any)
                )
              }
            >
              Approve
            </Button>
          ) : (
            <p> {moment(data.employeeSignatureDate).format(dateFormat)} </p>
          )}
        </Descriptions.Item>
        <Descriptions.Item label={t("general.managerSignatureDate")} span={2}>
          {data.managerSignatureDate == null || data.managerSignatureDate == "null"||data.managerSignatureDate == ""  ? (
            data.managerUID == uid ? (
              <Button
                style={{ width: "150px", height: "50px" }}
                type="primary"
                onClick={() =>
                  dispatch(
                    editOvertime({
                      id: overtimeId,
                      managerSignatureDate: moment() as any,
                      status: "Pending",
                    } as any)
                  )
                }
              >
                Approve
              </Button>
            ) : null
          ) : (
            <p> {moment(data.managerSignatureDate).format(dateFormat)} </p>
          )}
        </Descriptions.Item>

        <Descriptions.Item label={t("general.adminUser")} span={2}>
          {data.HRSignatureDate == "null" ||data.HRSignatureDate == ""  ? null : data.adminSignatureDate == null ||
            data.adminSignatureDate == "null" ||data.adminSignatureDate == ""  ? (
            currentUser?.adminOffices.includes(data.office) ? (
              <Button
                style={{ width: "150px", height: "50px" }}
                type="primary"
                onClick={async () => {
                  dispatch(
                    editOvertime({
                      id: overtimeId,
                      adminSignatureDate: moment() as any,
                      status: approvalCEO ? "Pending" : "Accepted",
                      adminUID: uid,
                      overtimeAccepted: data.compensationHours,
                    } as any)
                  ).then(() => {
                    if (!approvalCEO) {
                      addToVacationScore();
                    }
                  });
                }}
              >
                Approve
              </Button>
            ) : null
          ) : (
            <p> {adminFullName} </p>
          )}
        </Descriptions.Item>
        <Descriptions.Item label={t("general.HRUser")} span={2}>
          {data.managerSignatureDate == "null"  || data.managerSignatureDate == "" ? null : data.HRSignatureDate == null ||
            data.HRSignatureDate == "null"|| data.HRSignatureDate == "" ? (
            currentUser?.HROffices.includes(data.office) ? (
              <Button
                style={{ width: "150px", height: "50px" }}
                type="primary"
                onClick={() =>
                  dispatch(
                    editOvertime({
                      id: overtimeId,
                      HRSignatureDate: moment() as any,
                      HRUserUID: uid,
                    } as any)
                  )
                }
              >
                Approve
              </Button>
            ) : null
          ) : (
            <p> {HRFullName} </p>
          )}
        </Descriptions.Item>

        <Descriptions.Item label={t("general.adminSignatureDate")} span={2}>
          {data.adminSignatureDate == "null" ||data.adminSignatureDate == ""? null : <p> {moment(data.adminSignatureDate).format(dateFormat)} </p>}
        </Descriptions.Item>
        <Descriptions.Item label={t("general.HRSignatureDate")} span={2}>
          {data.HRSignatureDate == "null" ||data.HRSignatureDate == "" ? null : <p> {moment(data.HRSignatureDate).format(dateFormat)} </p>}
        </Descriptions.Item>

        {approvalCEO ? (
          <Descriptions.Item label={t("general.CEO")} span={2}>
            {data.adminSignatureDate == "null" ||data.adminSignatureDate == "" ? null : data.CEOSignatureDate == null ||
              data.CEOSignatureDate == "null" ||data.CEOSignatureDate == ""? (
              globalMissionsData[globalOfficesData[officeId].missionId]?.missionCEO.includes(uid) ? (
                <Button
                  style={{ width: "150px", height: "50px" }}
                  type="primary"
                  onClick={async () => {
                    dispatch(
                      editOvertime({
                        id: overtimeId,
                        CEOSignatureDate: moment() as any,
                        status: "Accepted",
                        CEOUID: uid,
                        overtimeAccepted: data.compensationHours,
                      } as any)
                    ).then(() => addToVacationScore());
                  }}
                >
                  Approve
                </Button>
              ) : null
            ) : (
              <p> {ceoFUllName} </p>
            )}
          </Descriptions.Item>
        ) : null}
        {approvalCEO ? (
          <Descriptions.Item label={t("general.CEOSignatureDate")} span={2}>
            {data.CEOSignatureDate == "null"||data.CEOSignatureDate == ""  || !data.CEOSignatureDate ? null : (
              <p> {moment(data.CEOSignatureDate).format(dateFormat)} </p>
            )}
          </Descriptions.Item>
        ) : null}

        <Descriptions.Item label={t("general.actions")} span={6}>
          <div>
            {data.status == "Requested" || data.status == "Pending" ? (
              <Button type="primary" style={{ margin: "1%" }} onClick={() => setModalState(true)}>
                Edit
              </Button>
            ) : null}
            {data.status == "Requested" || uid == "Jmlv0ajWF9RoGv4jWsvosBIBS1s1" ? (
              <Popconfirm
                title={t("general.areUSure")}
                onConfirm={() => {
                  deleteOrder();
                }}
                onCancel={() => console.log("cancel")}
                okText="Yes"
                cancelText="No"
              >
                <Button style={{ margin: "1%" }} type="danger">
                  Delete
                </Button>
              </Popconfirm>
            ) : null}
            {data.employeeUID == uid ? null : data.status == "Pending" || data.status == "Requested" ? (
              <Button
                style={{ margin: "1%" }}
                type="default"
                onClick={() => {
                  dispatch(
                    editOvertime({
                      id: overtimeId,
                      status: "Rejected",
                      rejectedBy: uid,
                    } as any)
                  );
                }}
              >
                Reject
              </Button>
            ) : null}
            {data.status == "Accepted" ? (
              <div>
                {!currentUser?.HROffices?.includes(data.office) ? null : (
                  <Popconfirm
                    title={t("general.areUSure")}
                    onConfirm={() => {
                      dispatch(
                        editOvertime({
                          id: overtimeId,
                          status: "Canceled",
                          canceledBy: uid,
                        } as any)
                      ).then(() => {
                        cancelOvertime();
                      });
                    }}
                    onCancel={() => console.log("cancel")}
                    okText="Yes"
                    cancelText="No"
                  >
                    <Button style={{ margin: "1%" }} type="danger">
                      Cancel
                    </Button>
                  </Popconfirm>
                )}
              </div>
            ) : null}
          </div>
        </Descriptions.Item>
      </Descriptions>
      {/* //------------------- */}

      {overtime.employeeUID && (
        <Discussion userId={userId} orderId={overtimeId} type={"overtime"} officeId={data.office} />
      )}
    </div>
  );
}
