import { createSlice, createAsyncThunk, PayloadAction } from "@reduxjs/toolkit";
import { supabase } from "../../supabaseClient";
import { accountant } from "../../db/interfaces";

interface AccountantSliceState {
  data: accountant[];
  status: "loading" | "succeeded" | "failed" | "idle";
  error: string | null;
}

const initialState: AccountantSliceState = {
  data: [],
  status: "idle",
  error: null,
};

// Fetch all accountant records
export const fetchAllAccountants = createAsyncThunk<accountant[], void, { rejectValue: string }>(
  "accountant/fetchAllAccountants",
  async (_, thunkApi) => {
    const { data, error } = await supabase.from("accountants").select("*");
    if (error) {
      return thunkApi.rejectWithValue(error.message);
    }
    return data as accountant[];
  }
);

// Fetch accountants by office
export const fetchAccountantsByOffice = createAsyncThunk<accountant[], string, { rejectValue: string }>(
  "accountant/fetchAccountantsByOffice",
  async (officeId, thunkApi) => {
    const { data, error } = await supabase.from("accountants").select("*").eq("office", officeId);
    if (error) {
      return thunkApi.rejectWithValue(error.message);
    }
    return data as accountant[];
  }
);

// Add a new accountant record
export const addAccountant = createAsyncThunk<accountant, accountant, { rejectValue: string }>(
  "accountant/addAccountant",
  async (newAccountant, thunkApi) => {
    const { data: insertData, error: insertError } = await supabase
      .from("accountants")
      .insert(newAccountant)
      .select()
      .single();

    if (insertError) {
      return thunkApi.rejectWithValue(insertError.message);
    }

    return insertData as accountant;
  }
);

// Edit an accountant record
export const editAccountant = createAsyncThunk<accountant, accountant, { rejectValue: string }>(
  "accountant/editAccountant",
  async (updatedAccountant, thunkApi) => {
    const { data: updateData, error: updateError } = await supabase
      .from("accountants")
      .update(updatedAccountant)
      .eq("id", updatedAccountant.id)
      .select()
      .single();

    if (updateError) {
      return thunkApi.rejectWithValue(updateError.message);
    }

    return updateData as accountant;
  }
);

// Delete an accountant record
export const deleteAccountant = createAsyncThunk<string, string, { rejectValue: string }>(
  "accountant/deleteAccountant",
  async (id, thunkApi) => {
    const { error } = await supabase.from("accountants").delete().eq("id", id);

    if (error) {
      return thunkApi.rejectWithValue(error.message);
    }

    return id;
  }
);

// Real-Time Actions
export const addAccountantRealTime = (newAccountant: accountant): PayloadAction<accountant> => ({
  type: "accountant/addAccountantRealTime",
  payload: newAccountant,
});

export const editAccountantRealTime = (updatedAccountant: accountant): PayloadAction<accountant> => ({
  type: "accountant/editAccountantRealTime",
  payload: updatedAccountant,
});

export const deleteAccountantRealTime = (id: string): PayloadAction<string> => ({
  type: "accountant/deleteAccountantRealTime",
  payload: id,
});

// Slice definition
const accountantSlice = createSlice({
  name: "accountant",
  initialState,
  reducers: {
    addAccountantRealTime(state, action: PayloadAction<accountant>) {
      const exists = state.data.some((accountant) => accountant.id === action.payload.id);
      if (!exists) {
        state.data.push(action.payload);
      }
      state.error = null;
    },
    editAccountantRealTime(state, action: PayloadAction<accountant>) {
      const index = state.data.findIndex((accountant) => accountant.id === action.payload.id);
      if (index !== -1) {
        const isDifferent = JSON.stringify(state.data[index]) !== JSON.stringify(action.payload);
        if (isDifferent) {
          state.data[index] = action.payload;
        }
      }
      state.error = null;
    },
    deleteAccountantRealTime(state, action: PayloadAction<string>) {
      const exists = state.data.some((accountant) => accountant.id === action.payload);
      if (exists) {
        state.data = state.data.filter((accountant) => accountant.id !== action.payload);
      }
      state.error = null;
    },
  },
  extraReducers: (builder) => {
    builder
      // Handle fetchAllAccountants
      .addCase(fetchAllAccountants.pending, (state) => {
        state.status = "loading";
      })
      .addCase(fetchAllAccountants.fulfilled, (state, action: PayloadAction<accountant[]>) => {
        state.status = "succeeded";
        state.data = action.payload;
        state.error = null;
      })
      .addCase(fetchAllAccountants.rejected, (state, action: PayloadAction<string | undefined>) => {
        state.status = "failed";
        state.error = action.payload || "Failed to fetch accountants.";
      })
      // Handle fetchAccountantsByOffice
      .addCase(fetchAccountantsByOffice.pending, (state) => {
        state.status = "loading";
      })
      .addCase(fetchAccountantsByOffice.fulfilled, (state, action: PayloadAction<accountant[]>) => {
        state.status = "succeeded";
        state.data = action.payload;
        state.error = null;
      })
      .addCase(fetchAccountantsByOffice.rejected, (state, action: PayloadAction<string | undefined>) => {
        state.status = "failed";
        state.error = action.payload || "Failed to fetch accountants by office.";
      })

      // Handle addAccountant
      .addCase(addAccountant.pending, (state) => {})
      .addCase(addAccountant.fulfilled, (state, action: PayloadAction<accountant>) => {
        state.data.push(action.payload);
        state.error = null;
      })
      .addCase(addAccountant.rejected, (state, action: PayloadAction<string | undefined>) => {
        state.error = action.payload || "Failed to add accountant.";
      })

      // Handle editAccountant
      .addCase(editAccountant.pending, (state) => {})
      .addCase(editAccountant.fulfilled, (state, action: PayloadAction<accountant>) => {
        const index = state.data.findIndex((accountant) => accountant.id === action.payload.id);
        if (index !== -1) {
          state.data[index] = action.payload;
        }
        state.error = null;
      })
      .addCase(editAccountant.rejected, (state, action: PayloadAction<string | undefined>) => {
        state.status = "failed";
        state.error = action.payload || "Failed to edit accountant.";
      })

      // Handle deleteAccountant
      .addCase(deleteAccountant.pending, (state) => {})
      .addCase(deleteAccountant.fulfilled, (state, action: PayloadAction<string>) => {
        state.data = state.data.filter((accountant) => accountant.id !== action.payload);
        state.error = null;
      })
      .addCase(deleteAccountant.rejected, (state, action: PayloadAction<string | undefined>) => {
        state.error = action.payload || "Failed to delete accountant.";
      });
  },
});

// Real-time subscription function
export const subscribeToAccountants = (dispatch: any) => {
  const subscription = supabase
    .channel("public:accountants")
    .on("postgres_changes", { event: "*", schema: "public", table: "accountants" }, (payload) => {
      const newRecord = payload.new as accountant;
      const oldRecord = payload.old as accountant;
      switch (payload.eventType) {
        case "INSERT":
          dispatch(addAccountantRealTime(newRecord));
          break;
        case "UPDATE":
          dispatch(editAccountantRealTime(newRecord));
          break;
        case "DELETE":
          dispatch(deleteAccountantRealTime(oldRecord.id ?? ""));
          break;
        default:
          break;
      }
    })
    .subscribe();

  return () => {
    subscription.unsubscribe();
  };
};

export default accountantSlice.reducer;
