import React, { useState, useEffect, useRef } from "react";
import { Button, Drawer, Form, message, Select, Input, Icon, Row, Col, List, Popconfirm, Spin } from "antd";
const { Option } = Select;
import { useFormik } from "formik";
import * as Yup from "yup";
import { useTranslation } from "react-i18next";
import { departmentPosition } from "../../db/interfaces";
import DraftText from "../Meetings_Rooms/TextEditor/Draft";
import { stateToHTML } from "draft-js-export-html";
import { EditorState } from "draft-js";
import { stateFromHTML } from "draft-js-import-html";
import { globalUsersData, USERS_ID_FULL_NAME } from "../../global/usersList";
import { useAppDispatch, useAppSelector } from "../../hooks/reduxHooks";
import { editDepartmentPosition } from "../../features/departmentPositions/departmentPositionsSlice";
import { deleteFile, uploadFile } from "../../db/supabase-storage";

interface Props {
  modalState: boolean;
  setModalState: React.Dispatch<React.SetStateAction<boolean>>;
  departmentPositionId: string;
  departmentId: string;
  departmentPosition: departmentPosition;
}
const projectSchema = Yup.object().shape({
  title: Yup.string().required("Title is required"),
});

function EditDepartmentPosition(props: Props) {
  const { departmentPositionsByDepartmentId: departmentPositionsData, status: departmentPositionsStatus } =
    useAppSelector((state) => state.departmentPositions);
  const dispatch = useAppDispatch();

  const { t } = useTranslation();
  const { modalState, setModalState, departmentPositionId, departmentId, departmentPosition } = props;
  const [loading, setLoading] = useState(false);
  const [uploadJobDescriptionFiles, setUploadJobDescriptionFiles] = useState([] as any);

  const departmentPositionData = departmentPosition;
  const [editorState, setEditorState] = useState<EditorState>(EditorState.createWithContent(stateFromHTML("")));
  const html = stateToHTML(editorState.getCurrentContent());
  const fileInputRef = useRef(null);
  const handleButtonClick = () => {
    if (fileInputRef.current) {
      fileInputRef.current.click();
    }
  };

  const formik = useFormik({
    initialValues: {
      uid: null,
      title: "",
      referencePositionId: null,
      departmentId: "",
      overview: "",
      attached_job_description: [],
      positionTasks: [],
      orientational_video: "",
    },
    validationSchema: projectSchema,
    onSubmit: async (values) => {
      setLoading(true);
      try {
        await dispatch(
          editDepartmentPosition({
            id: departmentPositionId,
            uid: values.uid ?? null,
            title: values.title,
            referencePositionId: values.referencePositionId ?? null,
            overview: html,
            attached_job_description: values.attached_job_description ?? [],
            positionTasks: [],
            orientational_video: "",
          } as any)
        ).then(() => {
          setModalState(false);
          setLoading(false);
          message.success("New Position is Updated successfully!");
          formik.resetForm();
        });
      } catch (error) {
        setLoading(false);
        console.error("Error writing document: ", error);
        message.error("Failed");
      }
    },
  });
  useEffect(() => {
    return () => {
      if (formik.isValid == false) {
        setLoading(false);
      }
    };
  }, [formik.isValid]);

  const [loaded, setLoaded] = useState(true);
  useEffect(() => {
    if (departmentPositionData && loaded) {
      formik.setValues({
        uid: departmentPositionData.uid ?? null,
        title: departmentPositionData.title,
        referencePositionId: departmentPositionData.referencePositionId ?? null,
        overview: departmentPositionData.overview,
        attached_job_description: departmentPositionData.attached_job_description ?? [],
        positionTasks: [],
        orientational_video: "",
      } as any);
      setLoaded(false);
      setEditorState(EditorState.createWithContent(stateFromHTML(departmentPositionData?.overview)));
    }
  }, [departmentPositionData, formik, loaded]);

  const positions = departmentPositionsData;

  const handleFileUpload = async (files: any) => {
    try {
      const downloadURLs = await Promise.all(
        files.map(async (file: any) => {
          const filePath = `departments/${departmentId}/positions/${file.name}`;
          try {
            const url = await uploadFile(file, filePath);

            if (url) {
              message.success("File uploaded successfully");

              return {
                name: file.name,
                url: url,
              };
            }
          } catch (error: any) {
            message.error(error.message);
          }
        })
      );
      return downloadURLs;
    } catch (error) {
      console.error("Error uploading files:", error);
      return [];
    }
  };

  const uploadJobDescription = async () => {
    setLoading(true);
    const promise = handleFileUpload(uploadJobDescriptionFiles);
    promise
      .then((newPositionFile) => {
        formik.setFieldValue("attached_job_description", [
          ...formik.values.attached_job_description,
          ...newPositionFile,
        ]);
      })
      .then(() => {
        setUploadJobDescriptionFiles([]);
        message.success("Files are uploaded successfully!");
        setLoading(false);
      });
    promise.catch(() => {
      message.error("Something went wrong!");
      setLoading(false);
    });
  };

  const handleDeleteFile = async (fileName: string, attachmentFolder: string) => {
    try {
      setLoading(true);
      await deleteFile(`departments/${departmentId}/positions/${fileName}`).then(async () => {
        const currentFiles = (formik.values as any)[attachmentFolder];
        const filteredCurrentFiles = currentFiles.filter((r: any) => r.name != fileName);
        formik.setFieldValue(attachmentFolder, filteredCurrentFiles);
        setLoading(false);
      });
    } catch (error) {
      console.error("Error deleting file:", error);
      setLoading(false);
    }
  };

  const antIcon = <Icon type="loading" style={{ fontSize: 75 }} spin />;
  return (
    <Drawer
      title={t("general.editPosition")}
      width={700}
      height={350}
      visible={modalState}
      placement={"right"}
      onClose={() => setModalState(false)}
    >
      <Spin spinning={loading} size="large" indicator={antIcon}>
        <Form onSubmit={formik.handleSubmit}>
          <Form.Item label={t("general.positionTitle")}>
            <Input value={formik.values.title} onChange={(e) => formik.setFieldValue("title", e.target.value)} />
          </Form.Item>
          <Form.Item label={t("general.referencePosition")}>
            <Select
              filterOption={(inputValue, option) => {
                return (option.props.children as any).toLowerCase().indexOf(inputValue.toLowerCase()) >= 0;
              }}
              allowClear
              size="default"
              placeholder="Parent Position"
              showSearch
              value={formik.values.referencePositionId}
              onChange={(e) => {
                formik.setFieldValue("referencePositionId", e);
              }}
            >
              {positions?.map((d) => {
                return (
                  <Option key={d.id} value={d.id}>{`${d.title} - ${
                    d.uid != null ? globalUsersData[d.uid ?? ""]?.fullName : "Vacant"
                  }`}</Option>
                );
              })}
            </Select>
          </Form.Item>
          <Form.Item label={t("general.overview")}>
            <DraftText
              onValueChange={() => {}}
              onMentionChange={() => {}}
              html={""}
              editorState={editorState}
              setEditorState={setEditorState}
            />
          </Form.Item>

          <Form.Item label={t("general.assignTo")}>
            <Select
              filterOption={(inputValue, option) => {
                return (option.props.children as any).toLowerCase().indexOf(inputValue.toLowerCase()) >= 0;
              }}
              allowClear
              placeholder="Assign to Employee"
              showSearch
              value={formik.values.uid}
              onChange={(e) => {
                formik.setFieldValue("uid", e);
              }}
            >
              {USERS_ID_FULL_NAME?.map((d) => {
                return <Option key={d.uid} value={d.uid}>{d.fullName}</Option>;
              })}
            </Select>
          </Form.Item>

          <Form.Item label="Job Description File">
            <Row gutter={12}>
              <Col span={6}>
                <Button onClick={() => handleButtonClick()}>
                  <Icon type="upload" /> {`Click to upload ${uploadJobDescriptionFiles.length}`}
                  <input
                    ref={fileInputRef} // Attach the ref to the input
                    hidden
                    type="file"
                    multiple
                    onChange={async (e) => {
                      if (e.target.files) {
                        setUploadJobDescriptionFiles([...Object.values(e?.target?.files as any)]);
                      }
                    }}
                  />
                </Button>
              </Col>
              <Col span={6}>
                <Button
                  onClick={uploadJobDescription}
                  disabled={uploadJobDescriptionFiles.length == 0}
                  className="btn blue lighten-1 z-depth-0"
                  type="primary"
                >
                  {t("general.upload")}
                </Button>
              </Col>
            </Row>
          </Form.Item>
          {formik.values?.attached_job_description?.length > 0 && (
            <div>
              <List
                itemLayout="horizontal"
                bordered
                dataSource={formik.values?.attached_job_description}
                renderItem={(file: any) => (
                  <List.Item>
                    <div>
                      <a href={file.url} target="_blank" rel="noopener noreferrer">
                        {file.name}
                      </a>
                      {/* <p style={{ margin: "0px" }}>{file.attachedDescription}</p> */}
                    </div>
                    <Popconfirm
                      title={"Are you sure?"}
                      onConfirm={() => handleDeleteFile(file.name, "attached_job_description")}
                      onCancel={() => console.log("cancel")}
                      okText="Yes"
                      cancelText="No"
                    >
                      <Button type="link" style={{ color: "red" }}>
                        <Icon type="delete" />
                      </Button>
                    </Popconfirm>
                  </List.Item>
                )}
              />
            </div>
          )}

          <div>
            <Button
              className="btn blue lighten-1 z-depth-0"
              style={{ marginTop: "2%" }}
              type="primary"
              htmlType="submit"
            >
              {t("general.save")}
            </Button>
          </div>
        </Form>
      </Spin>
    </Drawer>
  );
}

export default EditDepartmentPosition;
