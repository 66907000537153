import React, { useEffect, useState } from "react";
import Paper from "@material-ui/core/Paper";
import { Spin, Button, Popconfirm, Select, DatePicker, message } from "antd";
const { Option } = Select;
import { useTranslation } from "react-i18next";
import moment from "moment";
import DevTable from "../../helpers/devex-table";
import { globalOfficesData, OFFICES_ID_NAME } from "../../global/officeList";
import { globalUsersData } from "../../global/usersList";
import AddNewShift from "./addNewShiftDialog";
import EditShift from "./EditShiftDialog";
import { useAppDispatch, useAppSelector } from "../../hooks/reduxHooks";
import { deleteShift, fetchShiftsByOfficeAndMonth, subscribeToShifts } from "../../features/shifts/shfitsSlice";
import { shift } from "../../db/interfaces";
import { setFilterValue } from "../../features/filters/filtersSlice";
const { MonthPicker } = DatePicker;

export default function ShiftsTable() {
  const { filterValues } = useAppSelector((state) => state.filters);
  const { data: shiftsData, status, error } = useAppSelector((store) => store.shifts);
  const { currentUser } = useAppSelector((state) => state.auth);
  const dispatch = useAppDispatch();
  const [officeId, setOfficeId] = useState(filterValues["officeId"] ?? "Select an office...");


  const { t } = useTranslation();
  const [modalState, setModalState] = useState(false);
  const [month, setMonth] = useState(moment());
  const [modalStateEdit, setModalStateEdit] = useState(false);
  const [shift, setShift] = useState({} as shift);

  useEffect(() => {
    dispatch(fetchShiftsByOfficeAndMonth({ officeId, month: month.format("MM-YYYY").toString() }));
    const unsubscribe = subscribeToShifts(dispatch);
    return () => {
      unsubscribe();
    };
  }, [dispatch, officeId, month]);

  if (status == "loading") return <Spin />;

  const HR_MIX_Offices = [...currentUser.HROffices, ...currentUser.HRProjectsManagersOffices];
  const data = shiftsData
    .filter((d) => HR_MIX_Offices.includes(d.office))
    .map((d) => {
      return { ...d, id: d.id };
    });

  const columns = [
    {
      name: "uid",
      title: "UID",
    },
    {
      name: "name",
      title: "Name",
      getCellValue: (row: any) => globalUsersData[row.uid].fullName,
    },
    {
      name: "by",
      title: "Raised By",
      getCellValue: (row: any) => globalUsersData[row.by].fullName,
    },
    {
      name: "office",
      title: "Office",
      getCellValue: (row: any) => globalOfficesData[row.office].officeName,
    },

    {
      name: "month",
      title: "Month",
    },
    {
      name: "type",
      title: "Shift Type",
    },
    {
      name: "dates",
      title: "From - To",
      getCellValue: (row: any) => {
        const onlyDays = row.shiftDates
          .map((date: string) => parseInt(date.split(".")[0]))
          .sort((a: any, b: any) => a - b)
          .join(",");
        return onlyDays;
      },
    },
    {
      name: "actions",
      title: "Actions",
      getCellValue: (row: any) => {
        return (
          <Popconfirm
            title={t("general.areUSure")}
            onConfirm={() => {
              dispatch(deleteShift(row.id)).unwrap();
              message.success("Deleted Successfully!");
            }}
            onCancel={() => console.log("cancel")}
            okText="Yes"
            cancelText="No"
          >
            <Button type="link">Delete</Button>
          </Popconfirm>
        );
      },
    },
    {
      name: "edit",
      title: "Edit",
      getCellValue: (row: any) => {
        return (
          <Button
            onClick={() => {
              setModalStateEdit(true);
              setShift(row);
            }}
            type="primary"
          >
            Edit
          </Button>
        );
      },
    },
  ];
  const columnsExport = [
    {
      name: "uid",
      title: "UID",
    },
    {
      name: "name",
      title: "Name",
      getCellValue: (row: any) => globalUsersData[row.uid].fullName,
    },
    {
      name: "by",
      title: "Raised By",
      getCellValue: (row: any) => globalUsersData[row.by].fullName,
    },
    {
      name: "office",
      title: "Office",
      getCellValue: (row: any) => globalOfficesData[row.office].officeName,
    },

    {
      name: "month",
      title: "Month",
    },

    {
      name: "type",
      title: "Shift Type",
    },
    {
      name: "dates",
      title: "From - To",
      getCellValue: (row: any) => {
        const onlyDays = row.shiftDates
          .map((date: string) => parseInt(date.split(".")[0]))
          .sort((a: any, b: any) => a - b)
          .join(",");
        return onlyDays;
      },
    },
  ];

  return error ? (
    <p>{error}</p>
  ) : (
    <div>
      <MonthPicker placeholder="Select month" value={month} format={"MM-YYYY"} onChange={(e) => setMonth(moment(e))} />

      <Select
        filterOption={(inputValue, option) => {
          return (option.props.children as any).toLowerCase().indexOf(inputValue.toLowerCase()) >= 0;
        }}
        size="default"
        placeholder="Search for Office!"
        style={{ width: "25%", paddingBottom: "1%", paddingRight: "1%" }}
        showSearch
        value={officeId}
        onChange={(e) => {
          setOfficeId(e);
          dispatch(setFilterValue({ key: "officeId", value: e }));
        }}
      >
        {OFFICES_ID_NAME.map((d) => {
          return <Option value={d.officeId}>{d.officeName}</Option>;
        })}
      </Select>
      <AddNewShift
        modalState={modalState}
        setModalState={setModalState}
        month={month}
        setMonth={setMonth}
        shifts={data}
      />
      {modalStateEdit ? (
        <EditShift modalState={modalStateEdit} setModalState={setModalStateEdit} shift={shift} />
      ) : null}
      <Button style={{ marginBottom: "1%" }} type={"primary"} onClick={() => setModalState(true)}>
        {t("general.addNewShift")}
      </Button>
      <Paper>
        <DevTable
          data={data}
          columns={columns}
          defaultHiddenColumnNames={[]}
          tableName={`shits-${month.toString()}`}
          selected={false}
          typicalTable={false}
          columnsExport={columnsExport}
        />
      </Paper>
    </div>
  );
}
