import { createSlice, createAsyncThunk, PayloadAction } from "@reduxjs/toolkit";
import { supabase } from "../../supabaseClient"; // Adjust the import path as needed
import { paymentType } from "../../db/interfaces"; // Adjust the import path as needed

interface PaymentTypeSliceState {
  paymentTypes: paymentType[];
  data: paymentType[];
  status: "loading" | "succeeded" | "failed" | "idle";
  error: string | null;
}

const initialState: PaymentTypeSliceState = {
  paymentTypes: [],
  data: [],
  status: "idle",
  error: null,
};

// Fetch all paymentType records
export const fetchAllPaymentTypes = createAsyncThunk<paymentType[], void, { rejectValue: string }>(
  "paymentType/fetchAllPaymentTypes",
  async (_, thunkApi) => {
    const { data, error } = await supabase.from("paymentTypes").select();
    if (error) {
      return thunkApi.rejectWithValue(error.message);
    }
    return data as paymentType[];
  }
);

export const fetchPaymentTypesByOffice = createAsyncThunk<paymentType[], string, { rejectValue: string }>(
  "workOffice/fetchPaymentTypesByOffice",
  async (officeId, thunkApi) => {
    const { data, error } = await supabase.from("paymentTypes").select("*").eq("office", officeId);

    if (error) {
      return thunkApi.rejectWithValue(error.message);
    }

    return data as paymentType[];
  }
);

// Add a new paymentType record
export const addPaymentType = createAsyncThunk<paymentType, paymentType, { rejectValue: string }>(
  "paymentType/addPaymentType",
  async (newPaymentType, thunkApi) => {
    const { data: insertData, error: insertError } = await supabase
      .from("paymentTypes")
      .insert(newPaymentType)
      .select()
      .single();

    if (insertError) {
      return thunkApi.rejectWithValue(insertError.message);
    }

    return insertData as paymentType;
  }
);

// Edit a paymentType record
export const editPaymentType = createAsyncThunk<paymentType, paymentType, { rejectValue: string }>(
  "paymentType/editPaymentType",
  async (updatedPaymentType, thunkApi) => {
    const { data: updateData, error: updateError } = await supabase
      .from("paymentTypes")
      .update(updatedPaymentType)
      .eq("id", updatedPaymentType.id)
      .select()
      .single();

    if (updateError) {
      return thunkApi.rejectWithValue(updateError.message);
    }

    return updateData as paymentType;
  }
);

// Delete a paymentType record
export const deletePaymentType = createAsyncThunk<string, string, { rejectValue: string }>(
  "paymentType/deletePaymentType",
  async (id, thunkApi) => {
    const { error } = await supabase.from("paymentTypes").delete().eq("id", id);

    if (error) {
      return thunkApi.rejectWithValue(error.message);
    }

    return id;
  }
);

// Real-Time Actions
export const addPaymentTypeRealTime = (newPaymentType: paymentType): PayloadAction<paymentType> => ({
  type: "paymentType/addPaymentTypeRealTime",
  payload: newPaymentType,
});

export const editPaymentTypeRealTime = (updatedPaymentType: paymentType): PayloadAction<paymentType> => ({
  type: "paymentType/editPaymentTypeRealTime",
  payload: updatedPaymentType,
});

export const deletePaymentTypeRealTime = (id: string): PayloadAction<string> => ({
  type: "paymentType/deletePaymentTypeRealTime",
  payload: id,
});

// Slice definition
const paymentTypeSlice = createSlice({
  name: "paymentType",
  initialState,
  reducers: {
    addPaymentTypeRealTime(state, action: PayloadAction<paymentType>) {
      const exists = state.data.some((paymentType) => paymentType.id === action.payload.id);
      if (!exists) {
        state.data.push(action.payload);
      }
      state.error = null;
    },
    editPaymentTypeRealTime(state, action: PayloadAction<paymentType>) {
      const index = state.data.findIndex((paymentType) => paymentType.id === action.payload.id);
      if (index !== -1) {
        const isDifferent = JSON.stringify(state.data[index]) !== JSON.stringify(action.payload);
        if (isDifferent) {
          state.data[index] = action.payload;
        }
      }
      state.error = null;
    },
    deletePaymentTypeRealTime(state, action: PayloadAction<string>) {
      const exists = state.data.some((paymentType) => paymentType.id === action.payload);
      if (exists) {
        state.data = state.data.filter((paymentType) => paymentType.id !== action.payload);
      }
      state.error = null;
    },
  },
  extraReducers: (builder) => {
    builder
      // Handle fetchAllPaymentTypes
      .addCase(fetchAllPaymentTypes.pending, (state) => {
        state.status = "loading";
      })
      .addCase(fetchAllPaymentTypes.fulfilled, (state, action: PayloadAction<paymentType[]>) => {
        state.status = "succeeded";
        state.paymentTypes = action.payload;
        state.error = null;
      })
      .addCase(fetchAllPaymentTypes.rejected, (state, action: PayloadAction<string | undefined>) => {
        state.status = "failed";
        state.error = action.payload || "Failed to fetch payment types.";
      })

      // Handle fetchPaymentTypesByOffice
      .addCase(fetchPaymentTypesByOffice.pending, (state) => {
        state.status = "loading";
      })
      .addCase(fetchPaymentTypesByOffice.fulfilled, (state, action: PayloadAction<paymentType[]>) => {
        state.status = "succeeded";
        state.data = action.payload;
        state.error = null;
      })
      .addCase(fetchPaymentTypesByOffice.rejected, (state, action: PayloadAction<string | undefined>) => {
        state.status = "failed";
        state.error = action.payload || "Failed to fetch work offices by office.";
      })

      // Handle addPaymentType
      .addCase(addPaymentType.pending, (state) => {})
      .addCase(addPaymentType.fulfilled, (state, action: PayloadAction<paymentType>) => {
        state.data.push(action.payload);
        state.error = null;
      })
      .addCase(addPaymentType.rejected, (state, action: PayloadAction<string | undefined>) => {
        state.error = action.payload || "Failed to add payment type.";
      })

      // Handle editPaymentType
      .addCase(editPaymentType.pending, (state) => {})
      .addCase(editPaymentType.fulfilled, (state, action: PayloadAction<paymentType>) => {
        const index = state.data.findIndex((paymentType) => paymentType.id === action.payload.id);
        if (index !== -1) {
          state.data[index] = action.payload;
        }
        state.error = null;
      })
      .addCase(editPaymentType.rejected, (state, action: PayloadAction<string | undefined>) => {
        state.status = "failed";
        state.error = action.payload || "Failed to edit payment type.";
      })

      // Handle deletePaymentType
      .addCase(deletePaymentType.pending, (state) => {})
      .addCase(deletePaymentType.fulfilled, (state, action: PayloadAction<string>) => {
        state.data = state.data.filter((paymentType) => paymentType.id !== action.payload);
        state.error = null;
      })
      .addCase(deletePaymentType.rejected, (state, action: PayloadAction<string | undefined>) => {
        state.error = action.payload || "Failed to delete payment type.";
      });
  },
});

// Real-time subscription function
export const subscribeToPaymentTypes = (dispatch: any) => {
  const subscription = supabase
    .channel("public:paymentTypes")
    .on("postgres_changes", { event: "*", schema: "public", table: "paymentTypes" }, (payload) => {
      const newRecord = payload.new as paymentType;
      const oldRecord = payload.old as paymentType;
      switch (payload.eventType) {
        case "INSERT":
          dispatch(addPaymentTypeRealTime(newRecord));
          break;
        case "UPDATE":
          dispatch(editPaymentTypeRealTime(newRecord));
          break;
        case "DELETE":
          dispatch(deletePaymentTypeRealTime(oldRecord.id ?? ""));
          break;
        default:
          break;
      }
    })
    .subscribe();

  return () => {
    subscription.unsubscribe();
  };
};

export default paymentTypeSlice.reducer;
