import React, { useState, useRef, useCallback, useEffect } from "react";
import Paper from "@material-ui/core/Paper";
import { Spin, Button, Popconfirm, Select, DatePicker, message } from "antd";
const { Option } = Select;
import { useTranslation } from "react-i18next";
import moment from "moment";
import DevTable from "../../helpers/devex-table";
import AddNewDeduction from "./addNewDeductionDialog";
import { globalOfficesData, OFFICES_ID_NAME } from "../../global/officeList";
import { globalUsersData } from "../../global/usersList";
import { useAppDispatch, useAppSelector } from "../../hooks/reduxHooks";
import {
  deleteDeduction,
  fetchDeductionsByOfficeAndMonth,
  subscribeToDeductions,
} from "../../features/deductions/deductionsSlice";
import { setFilterValue } from "../../features/filters/filtersSlice";
const { MonthPicker } = DatePicker;

export default function DeductionTable() {
  const { filterValues } = useAppSelector((state) => state.filters);
  const { data: deductionsData, status, error } = useAppSelector((store) => store.deductions);
  const { currentUser } = useAppSelector((state) => state.auth);
  const dispatch = useAppDispatch();
  const [officeId, setOfficeId] = useState(filterValues["officeId"] ?? "Select an office...");

  const { t } = useTranslation();
  const [modalState, setModalState] = useState(false);
  const [month, setMonth] = useState(moment());

  useEffect(() => {
    dispatch(fetchDeductionsByOfficeAndMonth({ officeId, month: month.format("MM-YYYY").toString() }));
    const unsubscribe = subscribeToDeductions(dispatch);
    return () => {
      unsubscribe();
    };
  }, [dispatch, officeId, month]);

  if (status == "loading") return <Spin />;

  const data = deductionsData
    .filter((d) => currentUser.HRProjectsManagersOffices.includes(d.office))
    .map((d) => {
      return { ...d, id: d.id };
    });

  const columns = [
    {
      name: "uid",
      title: "UID",
    },
    {
      name: "name",
      title: "Name",
      getCellValue: (row: any) => globalUsersData[row.uid].fullName,
    },
    {
      name: "by",
      title: "Raised By",
      getCellValue: (row: any) => globalUsersData[row.by].fullName,
    },
    {
      name: "percentage",
      title: "Deduction %",
    },
    {
      name: "office",
      title: "Office",
      getCellValue: (row: any) => globalOfficesData[row.office].officeName,
    },

    {
      name: "month",
      title: "Month",
    },
    {
      name: "notes",
      title: "Notes",
    },
    {
      name: "actions",
      title: "Actions",
      getCellValue: (row: any) => {
        return (
          <Popconfirm
            title={t("general.areUSure")}
            onConfirm={() => {
              dispatch(deleteDeduction(row.id)).unwrap();
              message.success("Deleted Successfully!");
            }}
            onCancel={() => console.log("cancel")}
            okText="Yes"
            cancelText="No"
          >
            <Button type="link">Delete</Button>
          </Popconfirm>
        );
      },
    },
  ];
  const columnsExport = [
    {
      name: "uid",
      title: "UID",
    },
    {
      name: "name",
      title: "Name",
      getCellValue: (row: any) => globalUsersData[row.uid].fullName,
    },
    {
      name: "by",
      title: "Raised By",
      getCellValue: (row: any) => globalUsersData[row.by].fullName,
    },
    {
      name: "percentage",
      title: "Deduction %",
    },
    {
      name: "office",
      title: "Office",
      getCellValue: (row: any) => globalOfficesData[row.office].officeName,
    },

    {
      name: "month",
      title: "Month",
    },
    {
      name: "notes",
      title: "Notes",
    },
  ];

  return (
    <div>
      <MonthPicker placeholder="Select month" value={month} format={"MM-YYYY"} onChange={(e) => setMonth(moment(e))} />
      <Select
        filterOption={(inputValue, option) => {
          return (option.props.children as any).toLowerCase().indexOf(inputValue.toLowerCase()) >= 0;
        }}
        size="default"
        placeholder="Search for Office!"
        style={{ width: "25%", paddingBottom: "1%", paddingRight: "1%" }}
        showSearch
        value={officeId}
        onChange={(e) => {
          setOfficeId(e);
          dispatch(setFilterValue({ key: "officeId", value: e }));
        }}
      >
        {OFFICES_ID_NAME.map((d) => {
          return <Option value={d.officeId}>{d.officeName}</Option>;
        })}
      </Select>
      <AddNewDeduction modalState={modalState} setModalState={setModalState} />

      <Button style={{ marginBottom: "1%" }} type={"primary"} onClick={() => setModalState(true)}>
        {t("general.addNewDeduction")}
      </Button>
      <Paper>
        <DevTable
          data={data}
          columns={columns}
          defaultHiddenColumnNames={[]}
          tableName="deduction"
          selected={false}
          typicalTable={false}
          columnsExport={columnsExport}
        />
      </Paper>
    </div>
  );
}
