import { createSlice, createAsyncThunk, PayloadAction } from "@reduxjs/toolkit";
import { supabase } from "../../supabaseClient"; // Adjust the import path as needed
import { carRequest } from "../../db/interfaces"; // Adjust the import path as needed

interface CarRequestsSliceState {
  data: carRequest[];
  carRequest: carRequest;
  status: "loading" | "succeeded" | "failed" | "idle";
  error: string | null;
}

const initialState: CarRequestsSliceState = {
  data: [],
  carRequest: {} as carRequest,
  status: "idle",
  error: null,
};

// Fetch all car requests
export const fetchAllCarRequests = createAsyncThunk<carRequest[], void, { rejectValue: string }>(
  "carRequests/fetchAllCarRequests",
  async (_, thunkApi) => {
    const { data, error } = await supabase.from("carRequests").select("*");
    if (error) {
      return thunkApi.rejectWithValue(error.message);
    }
    return data as carRequest[];
  }
);

// Fetch car request by ID
export const fetchCarRequestById = createAsyncThunk<carRequest, string, { rejectValue: string }>(
  "carRequests/fetchCarRequestById",
  async (carRequestId, thunkApi) => {
    const { data, error } = await supabase.from("carRequests").select("*").eq("id", carRequestId).single();
    if (error) {
      return thunkApi.rejectWithValue(error.message);
    }
    return data as carRequest;
  }
);

// Fetch car requests by user
export const fetchCarRequestsByUser = createAsyncThunk<carRequest[], string, { rejectValue: string }>(
  "carRequests/fetchCarRequestsByUser",
  async (userId, thunkApi) => {
    const { data, error } = await supabase.from("carRequests").select("*").eq("user_id", userId);
    if (error) {
      return thunkApi.rejectWithValue(error.message);
    }
    return data as carRequest[];
  }
);

// Add a new car request
export const addCarRequest = createAsyncThunk<carRequest, carRequest, { rejectValue: string }>(
  "carRequests/addCarRequest",
  async (newCarRequest, thunkApi) => {
    const { data: insertData, error: insertError } = await supabase
      .from("carRequests")
      .insert(newCarRequest)
      .select()
      .single();

    if (insertError) {
      return thunkApi.rejectWithValue(insertError.message);
    }

    return insertData as carRequest;
  }
);

// Edit a car request
export const editCarRequest = createAsyncThunk<carRequest, carRequest, { rejectValue: string }>(
  "carRequests/editCarRequest",
  async (updatedCarRequest, thunkApi) => {
    const { data: updateData, error: updateError } = await supabase
      .from("carRequests")
      .update(updatedCarRequest)
      .eq("id", updatedCarRequest.id)
      .select()
      .single();

    if (updateError) {
      return thunkApi.rejectWithValue(updateError.message);
    }

    return updateData as carRequest;
  }
);

// Delete a car request
export const deleteCarRequest = createAsyncThunk<string, string, { rejectValue: string }>(
  "carRequests/deleteCarRequest",
  async (id, thunkApi) => {
    const { error } = await supabase.from("carRequests").delete().eq("id", id);

    if (error) {
      return thunkApi.rejectWithValue(error.message);
    }

    return id;
  }
);

// Real-Time Actions
export const addCarRequestRealTime = (newCarRequest: carRequest): PayloadAction<carRequest> => ({
  type: "carRequests/addCarRequestRealTime",
  payload: newCarRequest,
});

export const editCarRequestRealTime = (updatedCarRequest: carRequest): PayloadAction<carRequest> => ({
  type: "carRequests/editCarRequestRealTime",
  payload: updatedCarRequest,
});

export const deleteCarRequestRealTime = (id: string): PayloadAction<string> => ({
  type: "carRequests/deleteCarRequestRealTime",
  payload: id,
});

// Slice definition
const carRequestsSlice = createSlice({
  name: "carRequests",
  initialState,
  reducers: {
    addCarRequestRealTime(state, action: PayloadAction<carRequest>) {
      const exists = state.data.some((carRequest) => carRequest.id === action.payload.id);
      if (!exists) {
        state.data = [...state.data, action.payload];
      }
      state.error = null;
    },
    editCarRequestRealTime(state, action: PayloadAction<carRequest>) {
      const index = state.data.findIndex((carRequest) => carRequest.id === action.payload.id);
      if (index !== -1) {
        const isDifferent = JSON.stringify(state.data[index]) !== JSON.stringify(action.payload);
        if (isDifferent) {
          state.data[index] = action.payload;
        }
      }
      state.error = null;

      if (state.carRequest.id === action.payload.id) {
        state.carRequest = action.payload;
      }
    },
    deleteCarRequestRealTime(state, action: PayloadAction<string>) {
      const exists = state.data.some((carRequest) => carRequest.id === action.payload);
      if (exists) {
        state.data = state.data.filter((carRequest) => carRequest.id !== action.payload);
      }
      state.error = null;
    },
  },
  extraReducers: (builder) => {
    builder
      // Handle fetchAllCarRequests
      .addCase(fetchAllCarRequests.pending, (state) => {
        state.status = "loading";
      })
      .addCase(fetchAllCarRequests.fulfilled, (state, action: PayloadAction<carRequest[]>) => {
        state.status = "succeeded";
        state.data = action.payload;
        state.error = null;
      })
      .addCase(fetchAllCarRequests.rejected, (state, action: PayloadAction<string | undefined>) => {
        state.status = "failed";
        state.error = action.payload || "Failed to fetch car requests.";
      })

      // Handle fetchCarRequestById
      .addCase(fetchCarRequestById.pending, (state) => {
        state.status = "loading";
      })
      .addCase(fetchCarRequestById.fulfilled, (state, action: PayloadAction<carRequest>) => {
        state.status = "succeeded";
        state.carRequest = action.payload;
        state.error = null;
      })
      .addCase(fetchCarRequestById.rejected, (state, action: PayloadAction<string | undefined>) => {
        state.status = "failed";
        state.error = action.payload || "Failed to fetch car request.";
      })

      // Handle fetchCarRequestsByUser
      .addCase(fetchCarRequestsByUser.pending, (state) => {
        state.status = "loading";
      })
      .addCase(fetchCarRequestsByUser.fulfilled, (state, action: PayloadAction<carRequest[]>) => {
        state.status = "succeeded";
        state.data = action.payload;
        state.error = null;
      })
      .addCase(fetchCarRequestsByUser.rejected, (state, action: PayloadAction<string | undefined>) => {
        state.status = "failed";
        state.error = action.payload || "Failed to fetch car requests by user.";
      })

      // Handle addCarRequest
      .addCase(addCarRequest.pending, (state) => {})
      .addCase(addCarRequest.fulfilled, (state, action: PayloadAction<carRequest>) => {
        state.error = null;
      })
      .addCase(addCarRequest.rejected, (state, action: PayloadAction<string | undefined>) => {
        state.error = action.payload || "Failed to add car request.";
      })

      // Handle editCarRequest
      .addCase(editCarRequest.pending, (state) => {})
      .addCase(editCarRequest.fulfilled, (state, action: PayloadAction<carRequest>) => {
        state.error = null;
      })
      .addCase(editCarRequest.rejected, (state, action: PayloadAction<string | undefined>) => {
        state.status = "failed";
        state.error = action.payload || "Failed to edit car request.";
      })

      // Handle deleteCarRequest
      .addCase(deleteCarRequest.pending, (state) => {})
      .addCase(deleteCarRequest.fulfilled, (state, action: PayloadAction<string>) => {
        state.error = null;
      })
      .addCase(deleteCarRequest.rejected, (state, action: PayloadAction<string | undefined>) => {
        state.status = "failed";
        state.error = action.payload || "Failed to delete car request.";
      });
  },
});

// Real-time subscription function
export const subscribeToCarRequests = (dispatch: any) => {
  const subscription = supabase
    .channel("public:carRequests")
    .on("postgres_changes", { event: "*", schema: "public", table: "carRequests" }, (payload) => {
      const newRecord = payload.new as carRequest;
      const oldRecord = payload.old as carRequest;

      switch (payload.eventType) {
        case "INSERT":
          dispatch(addCarRequestRealTime(newRecord));
          break;
        case "UPDATE":
          dispatch(editCarRequestRealTime(newRecord));
          break;
        case "DELETE":
          dispatch(deleteCarRequestRealTime(oldRecord.id as string));
          break;
        default:
          break;
      }
    })
    .subscribe();

  return () => {
    subscription.unsubscribe();
  };
};

export default carRequestsSlice.reducer;
