import { createSlice, createAsyncThunk, PayloadAction } from "@reduxjs/toolkit";
import { supabase } from "../../supabaseClient"; // Adjust the import path as needed
import { shift } from "../../db/interfaces"; // Adjust the import path as needed

interface ShiftSliceState {
  data: shift[];
  status: "loading" | "succeeded" | "failed" | "idle";
  error: string | null;
}

const initialState: ShiftSliceState = {
  data: [],
  status: "idle",
  error: null,
};

// Fetch all shift records
export const fetchAllShifts = createAsyncThunk<shift[], void, { rejectValue: string }>(
  "shift/fetchAllShifts",
  async (_, thunkApi) => {
    const { data, error } = await supabase.from("shifts").select("*");
    if (error) {
      return thunkApi.rejectWithValue(error.message);
    }
    return data as shift[];
  }
);

export const fetchShiftsByOffice = createAsyncThunk<shift[], string, { rejectValue: string }>(
  "shift/fetchShiftsByOffice",
  async (officeId, thunkApi) => {
    const { data, error } = await supabase.from("shifts").select("*").eq("office", officeId);

    if (error) {
      return thunkApi.rejectWithValue(error.message);
    }

    return data as shift[];
  }
);

export const fetchShiftsByOfficeAndMonth = createAsyncThunk<
  shift[],
  { officeId: string; month: string },
  { rejectValue: string }
>("shift/fetchShiftsByOfficeAndMonth", async ({ officeId, month }, thunkApi) => {
  const { data, error } = await supabase.from("shifts").select("*").eq("office", officeId).eq("month", month);

  if (error) {
    return thunkApi.rejectWithValue(error.message);
  }

  return data as shift[];
});

export const fetchShiftsByMonth = createAsyncThunk<shift[], { month: string }, { rejectValue: string }>(
  "shift/fetchShiftsByMonth",
  async ({ month }, thunkApi) => {
    const { data, error } = await supabase.from("shifts").select("*").eq("month", month);

    if (error) {
      return thunkApi.rejectWithValue(error.message);
    }

    return data as shift[];
  }
);

export const fetchShiftsByUserIdAndMonth = createAsyncThunk<
  shift[],
  { userId: string; month: string },
  { rejectValue: string }
>("shift/fetchShiftsByUserIdAndMonth", async ({ userId, month }, thunkApi) => {
  const { data, error } = await supabase.from("shifts").select("*").eq("uid", userId).eq("month", month);

  if (error) {
    return thunkApi.rejectWithValue(error.message);
  }

  return data as shift[];
});

// Add a new shift record
export const addShift = createAsyncThunk<shift, shift, { rejectValue: string }>(
  "shift/addShift",
  async (newShift, thunkApi) => {
    const { data: insertData, error: insertError } = await supabase.from("shifts").insert(newShift).select().single();

    if (insertError) {
      return thunkApi.rejectWithValue(insertError.message);
    }

    return insertData as shift;
  }
);

// Edit a shift record
export const editShift = createAsyncThunk<shift, shift, { rejectValue: string }>(
  "shift/editShift",
  async (updatedShift, thunkApi) => {
    const { data: updateData, error: updateError } = await supabase
      .from("shifts")
      .update(updatedShift)
      .eq("id", updatedShift.id)
      .select()
      .single();

    if (updateError) {
      return thunkApi.rejectWithValue(updateError.message);
    }

    return updateData as shift;
  }
);

// Delete a shift record
export const deleteShift = createAsyncThunk<string, string, { rejectValue: string }>(
  "shift/deleteShift",
  async (id, thunkApi) => {
    const { error } = await supabase.from("shifts").delete().eq("id", id);

    if (error) {
      return thunkApi.rejectWithValue(error.message);
    }

    return id;
  }
);

// Real-Time Actions
export const addShiftRealTime = (newShift: shift): PayloadAction<shift> => ({
  type: "shift/addShiftRealTime",
  payload: newShift,
});

export const editShiftRealTime = (updatedShift: shift): PayloadAction<shift> => ({
  type: "shift/editShiftRealTime",
  payload: updatedShift,
});

export const deleteShiftRealTime = (id: string): PayloadAction<string> => ({
  type: "shift/deleteShiftRealTime",
  payload: id,
});

// Slice definition
const shiftSlice = createSlice({
  name: "shift",
  initialState,
  reducers: {
    addShiftRealTime(state, action: PayloadAction<shift>) {
      const exists = state.data.some((shift) => shift.id === action.payload.id);
      if (!exists) {
        state.data.push(action.payload);
      }
      state.error = null;
    },
    editShiftRealTime(state, action: PayloadAction<shift>) {
      const index = state.data.findIndex((shift) => shift.id === action.payload.id);
      if (index !== -1) {
        const isDifferent = JSON.stringify(state.data[index]) !== JSON.stringify(action.payload);
        if (isDifferent) {
          state.data[index] = action.payload;
        }
      }
      state.error = null;
    },
    deleteShiftRealTime(state, action: PayloadAction<string>) {
      const exists = state.data.some((shift) => shift.id === action.payload);
      if (exists) {
        state.data = state.data.filter((shift) => shift.id !== action.payload);
      }
      state.error = null;
    },
  },
  extraReducers: (builder) => {
    builder
      // Handle fetchAllShifts
      .addCase(fetchAllShifts.pending, (state) => {
        state.status = "loading";
      })
      .addCase(fetchAllShifts.fulfilled, (state, action: PayloadAction<shift[]>) => {
        state.status = "succeeded";
        state.data = action.payload;
        state.error = null;
      })
      .addCase(fetchAllShifts.rejected, (state, action: PayloadAction<string | undefined>) => {
        state.status = "failed";
        state.error = action.payload || "Failed to fetch shifts.";
      })

      // Handle fetchShiftsByOffice
      .addCase(fetchShiftsByOffice.pending, (state) => {
        state.status = "loading";
      })
      .addCase(fetchShiftsByOffice.fulfilled, (state, action: PayloadAction<shift[]>) => {
        state.status = "succeeded";
        state.data = action.payload;
        state.error = null;
      })
      .addCase(fetchShiftsByOffice.rejected, (state, action: PayloadAction<string | undefined>) => {
        state.status = "failed";
        state.error = action.payload || "Failed to fetch shifts by office.";
      })

      // Handle fetchShiftsByOfficeAndMonth
      .addCase(fetchShiftsByOfficeAndMonth.pending, (state) => {
        state.status = "loading";
      })
      .addCase(fetchShiftsByOfficeAndMonth.fulfilled, (state, action: PayloadAction<shift[]>) => {
        state.status = "succeeded";
        state.data = action.payload;
        state.error = null;
      })
      .addCase(fetchShiftsByOfficeAndMonth.rejected, (state, action: PayloadAction<string | undefined>) => {
        state.status = "failed";
        state.error = action.payload || "Failed to fetch shifts by office and month.";
      })

      // Handle fetchShiftsByMonth
      .addCase(fetchShiftsByMonth.pending, (state) => {
        state.status = "loading";
      })
      .addCase(fetchShiftsByMonth.fulfilled, (state, action: PayloadAction<shift[]>) => {
        state.status = "succeeded";
        state.data = action.payload;
        state.error = null;
      })
      .addCase(fetchShiftsByMonth.rejected, (state, action: PayloadAction<string | undefined>) => {
        state.status = "failed";
        state.error = action.payload || "Failed to fetch shifts by office and month.";
      })

      // Handle fetchShiftsByUserIdAndMonth
      .addCase(fetchShiftsByUserIdAndMonth.pending, (state) => {
        state.status = "loading";
      })
      .addCase(fetchShiftsByUserIdAndMonth.fulfilled, (state, action: PayloadAction<shift[]>) => {
        state.status = "succeeded";
        state.data = action.payload;
        state.error = null;
      })
      .addCase(fetchShiftsByUserIdAndMonth.rejected, (state, action: PayloadAction<string | undefined>) => {
        state.status = "failed";
        state.error = action.payload || "Failed to fetch shifts by office and month.";
      })

      // Handle addShift
      .addCase(addShift.pending, (state) => {})
      .addCase(addShift.fulfilled, (state, action: PayloadAction<shift>) => {
        state.data.push(action.payload);
        state.error = null;
      })
      .addCase(addShift.rejected, (state, action: PayloadAction<string | undefined>) => {
        state.error = action.payload || "Failed to add shift.";
      })

      // Handle editShift
      .addCase(editShift.pending, (state) => {})
      .addCase(editShift.fulfilled, (state, action: PayloadAction<shift>) => {
        const index = state.data.findIndex((shift) => shift.id === action.payload.id);
        if (index !== -1) {
          state.data[index] = action.payload;
        }
        state.error = null;
      })
      .addCase(editShift.rejected, (state, action: PayloadAction<string | undefined>) => {
        state.status = "failed";
        state.error = action.payload || "Failed to edit shift.";
      })

      // Handle deleteShift
      .addCase(deleteShift.pending, (state) => {})
      .addCase(deleteShift.fulfilled, (state, action: PayloadAction<string>) => {
        state.data = state.data.filter((shift) => shift.id !== action.payload);
        state.error = null;
      })
      .addCase(deleteShift.rejected, (state, action: PayloadAction<string | undefined>) => {
        state.error = action.payload || "Failed to delete shift.";
      });
  },
});

// Real-time subscription function
export const subscribeToShifts = (dispatch: any) => {
  const subscription = supabase
    .channel("public:shifts")
    .on("postgres_changes", { event: "*", schema: "public", table: "shifts" }, (payload) => {
      const newRecord = payload.new as shift;
      const oldRecord = payload.old as shift;
      switch (payload.eventType) {
        case "INSERT":
          dispatch(addShiftRealTime(newRecord));
          break;
        case "UPDATE":
          dispatch(editShiftRealTime(newRecord));
          break;
        case "DELETE":
          dispatch(deleteShiftRealTime(oldRecord.id ?? ""));
          break;
        default:
          break;
      }
    })
    .subscribe();

  return () => {
    subscription.unsubscribe();
  };
};

// Exports
export default shiftSlice.reducer;
