import { createSlice, createAsyncThunk, PayloadAction } from "@reduxjs/toolkit";
import { supabase } from "../../supabaseClient"; // Adjust the import path as needed
import { contractPercentage } from "../../db/interfaces"; // Adjust the import path as needed

interface ContractPercentageSliceState {
  contractPercentages: contractPercentage[];
  data: contractPercentage[];
  status: "loading" | "succeeded" | "failed" | "idle";
  error: string | null;
}

const initialState: ContractPercentageSliceState = {
  contractPercentages: [],
  data: [],
  status: "idle",
  error: null,
};

// Fetch all contractPercentage records
export const fetchAllContractPercentages = createAsyncThunk<contractPercentage[], void, { rejectValue: string }>(
  "contractPercentage/fetchAllContractPercentages",
  async (_, thunkApi) => {
    const { data, error } = await supabase.from("contractPercentage").select();
    if (error) {
      return thunkApi.rejectWithValue(error.message);
    }
    return data as contractPercentage[];
  }
);

export const fetchContractPercentageByOffice = createAsyncThunk<contractPercentage[], string, { rejectValue: string }>(
  "workOffice/fetchContractPercentageByOffice",
  async (officeId, thunkApi) => {
    const { data, error } = await supabase.from("contractPercentage").select("*").eq("office", officeId);

    if (error) {
      return thunkApi.rejectWithValue(error.message);
    }

    return data as contractPercentage[];
  }
);

// Add a new contractPercentage record
export const addContractPercentage = createAsyncThunk<contractPercentage, contractPercentage, { rejectValue: string }>(
  "contractPercentage/addContractPercentage",
  async (newContractPercentage, thunkApi) => {
    const { data: insertData, error: insertError } = await supabase
      .from("contractPercentage")
      .insert(newContractPercentage)
      .select()
      .single();

    if (insertError) {
      return thunkApi.rejectWithValue(insertError.message);
    }

    return insertData as contractPercentage;
  }
);

// Edit a contractPercentage record
export const editContractPercentage = createAsyncThunk<contractPercentage, contractPercentage, { rejectValue: string }>(
  "contractPercentage/editContractPercentage",
  async (updatedContractPercentage, thunkApi) => {
    const { data: updateData, error: updateError } = await supabase
      .from("contractPercentage")
      .update(updatedContractPercentage)
      .eq("id", updatedContractPercentage.id)
      .select()
      .single();

    if (updateError) {
      return thunkApi.rejectWithValue(updateError.message);
    }

    return updateData as contractPercentage;
  }
);

// Delete a contractPercentage record
export const deleteContractPercentage = createAsyncThunk<string, string, { rejectValue: string }>(
  "contractPercentage/deleteContractPercentage",
  async (id, thunkApi) => {
    const { error } = await supabase.from("contractPercentage").delete().eq("id", id);

    if (error) {
      return thunkApi.rejectWithValue(error.message);
    }

    return id;
  }
);

// Real-Time Actions
export const addContractPercentageRealTime = (
  newContractPercentage: contractPercentage
): PayloadAction<contractPercentage> => ({
  type: "contractPercentage/addContractPercentageRealTime",
  payload: newContractPercentage,
});

export const editContractPercentageRealTime = (
  updatedContractPercentage: contractPercentage
): PayloadAction<contractPercentage> => ({
  type: "contractPercentage/editContractPercentageRealTime",
  payload: updatedContractPercentage,
});

export const deleteContractPercentageRealTime = (id: string): PayloadAction<string> => ({
  type: "contractPercentage/deleteContractPercentageRealTime",
  payload: id,
});

// Slice definition
const contractPercentageSlice = createSlice({
  name: "contractPercentage",
  initialState,
  reducers: {
    addContractPercentageRealTime(state, action: PayloadAction<contractPercentage>) {
      const exists = state.data.some((percentage) => percentage.id === action.payload.id);
      if (!exists) {
        state.data.push(action.payload);
      }
      state.error = null;
    },
    editContractPercentageRealTime(state, action: PayloadAction<contractPercentage>) {
      const index = state.data.findIndex((percentage) => percentage.id === action.payload.id);
      if (index !== -1) {
        const isDifferent = JSON.stringify(state.data[index]) !== JSON.stringify(action.payload);
        if (isDifferent) {
          state.data[index] = action.payload;
        }
      }
      state.error = null;
    },
    deleteContractPercentageRealTime(state, action: PayloadAction<string>) {
      const exists = state.data.some((percentage) => percentage.id === action.payload);
      if (exists) {
        state.data = state.data.filter((percentage) => percentage.id !== action.payload);
      }
      state.error = null;
    },
  },
  extraReducers: (builder) => {
    builder
      // Handle fetchAllContractPercentages
      .addCase(fetchAllContractPercentages.pending, (state) => {
        state.status = "loading";
      })
      .addCase(fetchAllContractPercentages.fulfilled, (state, action: PayloadAction<contractPercentage[]>) => {
        state.status = "succeeded";
        state.contractPercentages = action.payload;
        state.error = null;
      })
      .addCase(fetchAllContractPercentages.rejected, (state, action: PayloadAction<string | undefined>) => {
        state.status = "failed";
        state.error = action.payload || "Failed to fetch contract percentages.";
      })

      // Handle fetchContractPercentageByOffice
      .addCase(fetchContractPercentageByOffice.pending, (state) => {
        state.status = "loading";
      })
      .addCase(fetchContractPercentageByOffice.fulfilled, (state, action: PayloadAction<contractPercentage[]>) => {
        state.status = "succeeded";
        state.data = action.payload;
        state.error = null;
      })
      .addCase(fetchContractPercentageByOffice.rejected, (state, action: PayloadAction<string | undefined>) => {
        state.status = "failed";
        state.error = action.payload || "Failed to fetch Contract Percentage by office.";
      })

      // Handle addContractPercentage
      .addCase(addContractPercentage.pending, (state) => {})
      .addCase(addContractPercentage.fulfilled, (state, action: PayloadAction<contractPercentage>) => {
        state.data.push(action.payload);
        state.error = null;
      })
      .addCase(addContractPercentage.rejected, (state, action: PayloadAction<string | undefined>) => {
        state.error = action.payload || "Failed to add contract percentage.";
      })

      // Handle editContractPercentage
      .addCase(editContractPercentage.pending, (state) => {})
      .addCase(editContractPercentage.fulfilled, (state, action: PayloadAction<contractPercentage>) => {
        const index = state.data.findIndex((percentage) => percentage.id === action.payload.id);
        if (index !== -1) {
          state.data[index] = action.payload;
        }
        state.error = null;
      })
      .addCase(editContractPercentage.rejected, (state, action: PayloadAction<string | undefined>) => {
        state.status = "failed";
        state.error = action.payload || "Failed to edit contract percentage.";
      })

      // Handle deleteContractPercentage
      .addCase(deleteContractPercentage.pending, (state) => {})
      .addCase(deleteContractPercentage.fulfilled, (state, action: PayloadAction<string>) => {
        state.data = state.data.filter((percentage) => percentage.id !== action.payload);
        state.error = null;
      })
      .addCase(deleteContractPercentage.rejected, (state, action: PayloadAction<string | undefined>) => {
        state.error = action.payload || "Failed to delete contract percentage.";
      });
  },
});

// Real-time subscription function
export const subscribeToContractPercentages = (dispatch: any) => {
  const subscription = supabase
    .channel("public:contractPercentage")
    .on("postgres_changes", { event: "*", schema: "public", table: "contractPercentage" }, (payload) => {
      const newRecord = payload.new as contractPercentage;
      const oldRecord = payload.old as contractPercentage;
      switch (payload.eventType) {
        case "INSERT":
          dispatch(addContractPercentageRealTime(newRecord));
          break;
        case "UPDATE":
          dispatch(editContractPercentageRealTime(newRecord));
          break;
        case "DELETE":
          dispatch(deleteContractPercentageRealTime(oldRecord.id ?? ""));
          break;
        default:
          break;
      }
    })
    .subscribe();

  return () => {
    subscription.unsubscribe();
  };
};

export default contractPercentageSlice.reducer;
