import { API, OutputData } from "@editorjs/editorjs";
import React, { useEffect, useState } from "react";
import Editor from "../Editor/Editor";
import { useAppDispatch, useAppSelector } from "../../hooks/reduxHooks";
import { editSheet, fetchSheetsByUid, subscribeToSheets } from "../../features/sheets/sheetsSlice";

export default function RightBar() {
  const { sheet, status, error } = useAppSelector((state) => state.sheets);
  const { currentUser } = useAppSelector((state) => state.auth);
  const dispatch = useAppDispatch();
  const uid = currentUser.id as string;

  const [instance, setInstance] = useState({} as API);
  const [timeoutId, setTimeoutId] = useState(null as any);

  const sheetData = sheet;

  useEffect(() => {
    dispatch(fetchSheetsByUid(uid));
    const unsubscribe = subscribeToSheets(dispatch);
    return () => {
      unsubscribe();
    };
  }, [dispatch, uid]);

  const onSaveSheetData = async (outputData: OutputData) => {
    if (outputData) {
      dispatch(
        editSheet({
          id: uid,
          uid,
          outputData: outputData,
        })
      );
    }
  };

  const handleSave = async () => {
    const outputData: any = await instance?.saver?.save();
    await onSaveSheetData(outputData);
  };

  useEffect(() => {
    clearTimeout(timeoutId);

    const id = setTimeout(() => {
      handleSave();
    }, 5000);

    setTimeoutId(id);

    return () => clearTimeout(timeoutId);
  }, [instance]);

  return (
    <div style={{ margin: "5%" }}>
      {status == "loading" ? (
        <>loading..</>
      ) : (
        <>
          <Editor holder="dashboard" data={sheetData?.outputData} setInstance={setInstance} minHeight={400} />
        </>
      )}
    </div>
  );
}
