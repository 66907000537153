import { createSlice, createAsyncThunk, PayloadAction } from "@reduxjs/toolkit";
import { supabase } from "../../supabaseClient"; // Adjust the import path as needed
import { departmentPosition } from "../../db/interfaces"; // Ensure your departmentPosition interface is defined here

interface departmentPositionsSliceState {
  data: departmentPosition[];
  departmentPositionsByDepartmentId: departmentPosition[];
  status: "loading" | "succeeded" | "failed" | "idle";
  error: string | null;
  globalDepartmentPositionsData: Record<string, departmentPosition>;
  loaded: boolean;
}

const initialState: departmentPositionsSliceState = {
  data: [],
  departmentPositionsByDepartmentId: [],
  status: "idle",
  error: null,
  globalDepartmentPositionsData: {},
  loaded: false,
};

// Fetch all departmentPositions in batches
export const fetchAllDepartmentPositions = createAsyncThunk(
  "departmentPositions/fetchAllDepartmentPositions",
  async (_, { rejectWithValue }) => {
    const { data, error } = await supabase.from("departmentPositions").select("*");
    if (error) {
      return rejectWithValue(error.message as string);
    }

    return data;
  }
);

// Fetch all departmentPositions in batches
export const fetchAllDepartmentPositionsByDepartmentId = createAsyncThunk<
  departmentPosition[],
  string,
  { rejectValue: string }
>("departmentPositions/fetchAllDepartmentPositionsByDepartmentId", async (departmentId, { rejectWithValue }) => {
  const { data, error } = await supabase.from("departmentPositions").select("*").eq("departmentId", departmentId);
  if (error) {
    return rejectWithValue(error.message as string);
  }

  return data;
});

// Add a new departmentPosition
export const addDepartmentPosition = createAsyncThunk<departmentPosition, departmentPosition, { rejectValue: string }>(
  "departmentPositions/addDepartmentPosition",
  async (newDepartmentPosition, thunkApi) => {
    const { data: insertData, error: insertError } = await supabase
      .from("departmentPositions")
      .insert(newDepartmentPosition)
      .select()
      .single();

    if (insertError) {
      return thunkApi.rejectWithValue(insertError.message);
    }

    return insertData as departmentPosition;
  }
);

// Delete a departmentPosition
export const deleteDepartmentPosition = createAsyncThunk<string, string, { rejectValue: string }>(
  "departmentPositions/deleteDepartmentPosition",
  async (id, thunkApi) => {
    const { error } = await supabase.from("departmentPositions").delete().eq("id", id);

    if (error) {
      return thunkApi.rejectWithValue(error.message);
    }

    return id;
  }
);

// Edit a departmentPosition
export const editDepartmentPosition = createAsyncThunk<departmentPosition, departmentPosition, { rejectValue: string }>(
  "departmentPositions/editDepartmentPosition",
  async (updatedDepartmentPosition, thunkApi) => {
    const { data: updateData, error: updateError } = await supabase
      .from("departmentPositions")
      .update(updatedDepartmentPosition)
      .eq("id", updatedDepartmentPosition.id)
      .select()
      .single();

    if (updateError) {
      return thunkApi.rejectWithValue(updateError.message);
    }

    return updateData as departmentPosition;
  }
);

// Real-time actions
export const addDepartmentPositionRealTime = (
  newDepartmentPosition: departmentPosition
): PayloadAction<departmentPosition> => ({
  type: "departmentPositions/addDepartmentPositionRealTime",
  payload: newDepartmentPosition,
});

export const editDepartmentPositionRealTime = (
  updatedDepartmentPosition: departmentPosition
): PayloadAction<departmentPosition> => ({
  type: "departmentPositions/editDepartmentPositionRealTime",
  payload: updatedDepartmentPosition,
});

export const deleteDepartmentPositionRealTime = (id: string): PayloadAction<string> => ({
  type: "departmentPositions/deleteDepartmentPositionRealTime",
  payload: id,
});

// Slice definition
const departmentPositionsSlice = createSlice({
  name: "departmentPositions",
  initialState,
  reducers: {
    addDepartmentPositionRealTime(state, action: PayloadAction<departmentPosition>) {
      const existsData = state.data.some((departmentPosition) => departmentPosition.id === action.payload.id);
      if (!existsData) {
        state.data.push(action.payload);
        state.departmentPositionsByDepartmentId.push(action.payload);
      }

      state.error = null;
    },
    editDepartmentPositionRealTime(state, action: PayloadAction<departmentPosition>) {
      const indexData = state.data.findIndex((departmentPosition) => departmentPosition.id === action.payload.id);
      if (indexData !== -1) {
        const isDifferent = JSON.stringify(state.data[indexData]) !== JSON.stringify(action.payload);
        if (isDifferent) {
          state.data[indexData] = action.payload;
        }
      }

      const indexByDepartment = state.departmentPositionsByDepartmentId.findIndex(
        (departmentPosition) => departmentPosition.id === action.payload.id
      );
      if (indexByDepartment !== -1) {
        const isDifferent =
          JSON.stringify(state.departmentPositionsByDepartmentId[indexByDepartment]) !== JSON.stringify(action.payload);
        if (isDifferent) {
          state.departmentPositionsByDepartmentId[indexByDepartment] = action.payload;
        }
      }

      state.error = null;
    },
    deleteDepartmentPositionRealTime(state, action: PayloadAction<string>) {
      const exists = state.data.some((departmentPosition) => departmentPosition.id === action.payload);
      if (exists) {
        state.data = state.data.filter((departmentPosition) => departmentPosition.id !== action.payload);
      }
      state.error = null;
    },
  },
  extraReducers: (builder) => {
    builder
      // Handle fetchAllDepartmentPositions
      .addCase(fetchAllDepartmentPositions.pending, (state) => {
        state.status = "loading";
      })
      .addCase(fetchAllDepartmentPositions.fulfilled, (state, action) => {
        state.loaded = true;
        state.data = action.payload;
        state.status = "succeeded";

        action.payload.forEach((departmentPosition) => {
          state.globalDepartmentPositionsData[departmentPosition.id ?? ""] = departmentPosition;
        });
      })
      .addCase(fetchAllDepartmentPositions.rejected, (state, action) => {
        state.status = "failed";
        state.error = (action.payload as string) || "Failed to fetch department positions.";
      })

      // Handle fetchAllDepartmentPositionsByDepartmentId
      .addCase(fetchAllDepartmentPositionsByDepartmentId.pending, (state) => {
        state.status = "loading";
      })
      .addCase(fetchAllDepartmentPositionsByDepartmentId.fulfilled, (state, action) => {
        state.departmentPositionsByDepartmentId = action.payload;
        state.status = "succeeded";
      })
      .addCase(fetchAllDepartmentPositionsByDepartmentId.rejected, (state, action) => {
        state.status = "failed";
        state.error = (action.payload as string) || "Failed to fetch department positions.";
      })

      // Handle addDepartmentPosition
      .addCase(addDepartmentPosition.fulfilled, (state, action: PayloadAction<departmentPosition>) => {
        state.error = null;
      })
      .addCase(addDepartmentPosition.rejected, (state, action: PayloadAction<string | undefined>) => {
        state.error = action.payload || "Failed to add department position.";
      })

      // Handle deleteDepartmentPosition
      .addCase(deleteDepartmentPosition.fulfilled, (state, action: PayloadAction<string>) => {
        state.data = state.data.filter((departmentPosition) => departmentPosition.id !== action.payload);
        state.departmentPositionsByDepartmentId = state.departmentPositionsByDepartmentId.filter(
          (departmentPosition) => departmentPosition.id !== action.payload
        );

        state.error = null;
      })
      .addCase(deleteDepartmentPosition.rejected, (state, action: PayloadAction<string | undefined>) => {
        state.error = action.payload || "Failed to delete department position.";
      })

      // Handle editDepartmentPosition
      .addCase(editDepartmentPosition.fulfilled, (state, action: PayloadAction<departmentPosition>) => {
        const index = state.data.findIndex((departmentPosition) => departmentPosition.id === action.payload.id);
        if (index !== -1) {
          state.data[index] = action.payload;
        }
        state.error = null;
      })
      .addCase(editDepartmentPosition.rejected, (state, action: PayloadAction<string | undefined>) => {
        state.error = action.payload || "Failed to edit department position.";
      });
  },
});

// Real-time subscription function
export const subscribeToDepartmentPositions = (dispatch: any) => {
  const subscription = supabase
    .channel("public:departmentPositions")
    .on("postgres_changes", { event: "*", schema: "public", table: "departmentPositions" }, (payload) => {
      const newRecord = payload.new as departmentPosition;
      const oldRecord = payload.old as departmentPosition;

      switch (payload.eventType) {
        case "INSERT":
          console.log("insert");
          dispatch(addDepartmentPositionRealTime(newRecord));

          break;
        case "UPDATE":
          dispatch(editDepartmentPositionRealTime(newRecord));
          break;
        case "DELETE":
          dispatch(deleteDepartmentPositionRealTime(oldRecord.id ?? ""));
          break;
        default:
          break;
      }
    })
    .subscribe();

  return () => {
    subscription.unsubscribe();
  };
};

export default departmentPositionsSlice.reducer;
