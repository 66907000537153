import React, { useEffect } from "react";
import { Button, message, Spin, Modal } from "antd";
import moment from "moment";
import { useTranslation } from "react-i18next";
import { filter } from "lodash";
import { Calendar } from "../../db/interfaces";
import { useAppDispatch, useAppSelector } from "../../hooks/reduxHooks";
import { deleteCalendarEvent, fetchCalendarEventsByOriginalId } from "../../features/calendar/calendarSlice";
import { deleteNotificationsByOrderId } from "../../features/notifications/notificationsSlice";

interface Props {
  modalState: boolean;
  setModalState: React.Dispatch<React.SetStateAction<boolean>>;
  docId: string;
  appointment: Calendar;
}

function DeleteDialog(props: Props) {
  const { originalMeetings, status } = useAppSelector((state) => state.calendar);
  const dispatch = useAppDispatch();

  const { t } = useTranslation();
  const { modalState, setModalState, docId, appointment } = props;
  const { originalId, isOnlyOneAppointment, startDate } = appointment;
  useEffect(() => {
    dispatch(fetchCalendarEventsByOriginalId(originalId));
  }, [dispatch, originalId, modalState]);

  const notificationDelete = async (calendarId: string) => {
    await dispatch(deleteNotificationsByOrderId(calendarId));
  };

  if (status == "loading") {
    return <Spin />;
  }
  if (isOnlyOneAppointment == false) {
    const deleteAll = async () => {
      let loop = 0;

      originalMeetings.forEach((meeting, index) => {
        dispatch(deleteCalendarEvent(meeting?.id as string)).then(() => notificationDelete(meeting?.id as string));

        loop = index + 1;
        return loop;
      });

      if (originalMeetings.length == loop) {
        message.success("Successfully Deleted!");
      }

      setModalState(false);
    };
    const deleteThisAndFollowing = () => {
      const filteredArray = filter(originalMeetings, (e) => moment(e.startDate) >= moment(startDate));
      const filteredByOriginalId = filteredArray;
      let loop = 0;

      filteredByOriginalId.forEach((meeting, index) => {
        dispatch(deleteCalendarEvent(meeting?.id as string)).then(() => notificationDelete(meeting?.id as string));

        loop = index + 1;
        return loop;
      });

      if (filteredByOriginalId.length == loop) {
        message.success("Successfully Deleted!");
      }

      setModalState(false);
    };
    const deleteOnlyOne = () => {
      dispatch(deleteCalendarEvent(docId as string))
        .then(() => notificationDelete(docId as string))
        .then(() => message.success("Successfully Deleted!!"));

      setModalState(false);
    };
    return  (
      <Modal
        width={500}
        visible={modalState}
        onCancel={() => setModalState(false)}
        closable={true}
        footer={
          <div
            style={{
              textAlign: "right",
            }}
          >
            <Button type="danger" onClick={() => setModalState(false)} style={{ marginRight: 8 }}>
              {t("general.close")}
            </Button>

            <Button onClick={deleteThisAndFollowing} style={{ marginRight: 8 }}>
              {t("general.thisAndFollowing")}
            </Button>
            <Button onClick={deleteAll} style={{ marginRight: 8 }}>
              {t("general.yesAll")}{" "}
            </Button>
            <Button onClick={deleteOnlyOne} type="primary">
              {t("general.onlyThis")}
            </Button>
          </div>
        }
      >
        <div>{t("general.deleteSeries")}</div>
      </Modal>
    );
  } else {
    const deleteOnlyOne = () => {
      dispatch(deleteCalendarEvent(docId as string))
        .then(() => notificationDelete(docId as string))
        .then(() => message.success("Successfully Deleted!!"));
      setModalState(false);
    };

    return (
      <Modal
        //   title={t("general.addMOM")}
        okText={t("general.yes")}
        cancelText={t("general.no")}
        width={500}
        visible={modalState}
        onCancel={() => setModalState(false)}
        onOk={deleteOnlyOne}
        closable={true}
      >
        <div>{t("general.deleteAppointment")}</div>
      </Modal>
    );
  }
}

export default DeleteDialog;
