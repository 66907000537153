import { createSlice, createAsyncThunk, PayloadAction } from "@reduxjs/toolkit";
import { supabase } from "../../supabaseClient"; // Adjust the import path as needed
import { allocation } from "../../db/interfaces"; // Adjust the import path as needed

interface allocationSliceState {
  data: allocation[];
  status: "loading" | "succeeded" | "failed" | "idle";
  error: string | null;
}

const initialState: allocationSliceState = {
  data: [],
  status: "idle",
  error: null,
};

// Fetch all Allocation records
export const fetchAllAllocations = createAsyncThunk<allocation[], void, { rejectValue: string }>(
  "allocation/fetchAllAllocations",
  async (_, thunkApi) => {
    const allAllocations: any[] = [];
    let from = 0;
    let to = 999;

    for (let i = 0; i < 10; i++) {
      const { data, error } = await supabase.from("allocations").select("*").range(from, to);
      if (error) {
        return thunkApi.rejectWithValue(error.message as string);
      }

      if (data?.length) {
        allAllocations.push(...data);
        from += 1000;
        to += 1000;
      } else {
        break;
      }
    }

    return allAllocations as allocation[];
  }
);

// Fetch Allocation records for a specific office
export const fetchAllocationsByOfficeId = createAsyncThunk<allocation[], string, { rejectValue: string }>(
  "allocation/fetchAllocationsByOffice",
  async (officeId, thunkApi) => {
    const { data, error } = await supabase.from("allocations").select("*").eq("office", officeId);
    if (error) {
      return thunkApi.rejectWithValue(error.message as string);
    }

    return data as allocation[];
  }
);
// Fetch Allocation records for a specific office and date
export const fetchAllocationsByOfficeIdAndDate = createAsyncThunk<
  allocation[],
  { officeId: string; gt: string; ls: string },
  { rejectValue: string }
>("allocation/fetchAllocationsByOfficeIdAndDate", async ({ officeId, gt, ls }, thunkApi) => {
  const { data, error } = await supabase
    .from("allocations")
    .select("*")
    .eq("office", officeId)
    .gt("endDate", gt)
    .lt("startDate", ls);
  if (error) {
    return thunkApi.rejectWithValue(error.message as string);
  }

  return data as allocation[];
});

// Fetch Allocation records for a specific project
export const fetchAllocationsByProjectId = createAsyncThunk<
  allocation[],
  { projectId: string },
  { rejectValue: string }
>("allocation/fetchAllocationsByProjectId", async ({ projectId }, thunkApi) => {
  const { data, error } = await supabase.from("allocations").select("*").eq("projectId", projectId);
  if (error) {
    return thunkApi.rejectWithValue(error.message as string);
  }

  return data as allocation[];
});

// Fetch Allocation records for a specific project
export const fetchAllocationsByProjectIdAndDate = createAsyncThunk<
  allocation[],
  { projectId: string; gt: string; ls: string },
  { rejectValue: string }
>("allocation/fetchAllocationsByProjectIdAndDate", async ({ projectId, gt, ls }, thunkApi) => {
  const { data, error } = await supabase
    .from("allocations")
    .select("*")
    .eq("projectId", projectId)
    .gt("endDate", gt)
    .lt("startDate", ls);
  if (error) {
    return thunkApi.rejectWithValue(error.message as string);
  }

  return data as allocation[];
});

// Fetch Allocation records for a specific user
export const fetchAllocationsByUserId = createAsyncThunk<allocation[], string, { rejectValue: string }>(
  "allocation/fetchAllocationsByUserId",
  async (userId, thunkApi) => {
    const { data, error } = await supabase.from("allocations").select("*").eq("uid", userId);
    if (error) {
      return thunkApi.rejectWithValue(error.message as string);
    }

    return data as allocation[];
  }
);

// Add a new Allocation record
export const addAllocation = createAsyncThunk<allocation, allocation, { rejectValue: string }>(
  "allocation/addAllocation",
  async (newAllocation, thunkApi) => {
    const { data: insertData, error: insertError } = await supabase
      .from("allocations")
      .insert(newAllocation)
      .select()
      .single();

    if (insertError) {
      return thunkApi.rejectWithValue(insertError.message);
    }

    return insertData as allocation;
  }
);

// Delete an Allocation record
export const deleteAllocation = createAsyncThunk<string, string, { rejectValue: string }>(
  "allocation/deleteAllocation",
  async (id, thunkApi) => {
    const { error } = await supabase.from("allocations").delete().eq("id", id);

    if (error) {
      return thunkApi.rejectWithValue(error.message);
    }

    return id;
  }
);

// Edit an Allocation record
export const editAllocation = createAsyncThunk<allocation, allocation, { rejectValue: string }>(
  "allocation/editAllocation",
  async (updatedAllocation, thunkApi) => {
    const { data: updateData, error: updateError } = await supabase
      .from("allocations")
      .update(updatedAllocation)
      .eq("id", updatedAllocation.id)
      .select()
      .single();

    if (updateError) {
      return thunkApi.rejectWithValue(updateError.message);
    }

    return updateData as allocation;
  }
);

// Real-Time Actions
export const addAllocationRealTime = (newAllocation: allocation): PayloadAction<allocation> => ({
  type: "allocation/addAllocationRealTime",
  payload: newAllocation,
});

export const editAllocationRealTime = (updatedAllocation: allocation): PayloadAction<allocation> => ({
  type: "allocation/editAllocationRealTime",
  payload: updatedAllocation,
});

export const deleteAllocationRealTime = (id: string): PayloadAction<string> => ({
  type: "allocation/deleteAllocationRealTime",
  payload: id,
});

// Slice definition
const allocationSlice = createSlice({
  name: "allocation",
  initialState,
  reducers: {
    addAllocationRealTime(state, action: PayloadAction<allocation>) {
      const exists = state.data.some((allocation) => allocation.id === action.payload.id);
      if (!exists) {
        state.data.push(action.payload);
      }
      state.error = null;
    },
    editAllocationRealTime(state, action: PayloadAction<allocation>) {
      const index = state.data.findIndex((allocation) => allocation.id === action.payload.id);
      if (index !== -1) {
        const isDifferent = JSON.stringify(state.data[index]) !== JSON.stringify(action.payload);
        if (isDifferent) {
          state.data[index] = action.payload;
        }
      }
      state.error = null;
    },
    deleteAllocationRealTime(state, action: PayloadAction<string>) {
      const exists = state.data.some((allocation) => allocation.id === action.payload);
      if (exists) {
        state.data = state.data.filter((allocation) => allocation.id !== action.payload);
      }
      state.error = null;
    },
  },
  extraReducers: (builder) => {
    builder
      // Handle fetchAllAllocations
      .addCase(fetchAllAllocations.pending, (state) => {
        state.status = "loading";
      })
      .addCase(fetchAllAllocations.fulfilled, (state, action: PayloadAction<allocation[]>) => {
        state.status = "succeeded";
        state.data = action.payload;
        state.error = null;
      })
      .addCase(fetchAllAllocations.rejected, (state, action: PayloadAction<string | undefined>) => {
        state.status = "failed";
        state.error = action.payload || "Failed to fetch allocations.";
      })

      // Handle fetchAllocationsByOffice
      .addCase(fetchAllocationsByOfficeId.pending, (state) => {
        state.status = "loading";
      })
      .addCase(fetchAllocationsByOfficeId.fulfilled, (state, action: PayloadAction<allocation[]>) => {
        state.status = "succeeded";
        state.data = action.payload;
        state.error = null;
      })
      .addCase(fetchAllocationsByOfficeId.rejected, (state, action: PayloadAction<string | undefined>) => {
        state.status = "failed";
        state.error = action.payload || "Failed to fetch allocations by office.";
      })

      // Handle fetchAllocationsByOfficeIdAndDate
      .addCase(fetchAllocationsByOfficeIdAndDate.pending, (state) => {
        state.status = "loading";
      })
      .addCase(fetchAllocationsByOfficeIdAndDate.fulfilled, (state, action: PayloadAction<allocation[]>) => {
        state.status = "succeeded";
        state.data = action.payload;
        state.error = null;
      })
      .addCase(fetchAllocationsByOfficeIdAndDate.rejected, (state, action: PayloadAction<string | undefined>) => {
        state.status = "failed";
        state.error = action.payload || "Failed to fetch allocations by office.";
      })

      // Handle fetchAllocationsByProject
      .addCase(fetchAllocationsByProjectId.pending, (state) => {
        state.status = "loading";
      })
      .addCase(fetchAllocationsByProjectId.fulfilled, (state, action: PayloadAction<allocation[]>) => {
        state.status = "succeeded";
        state.data = action.payload;
        state.error = null;
      })
      .addCase(fetchAllocationsByProjectId.rejected, (state, action: PayloadAction<string | undefined>) => {
        state.status = "failed";
        state.error = action.payload || "Failed to fetch allocations by office.";
      })

      // Handle fetchAllocationsByProjectIdAndDate
      .addCase(fetchAllocationsByProjectIdAndDate.pending, (state) => {
        state.status = "loading";
      })
      .addCase(fetchAllocationsByProjectIdAndDate.fulfilled, (state, action: PayloadAction<allocation[]>) => {
        state.status = "succeeded";
        state.data = action.payload;
        state.error = null;
      })
      .addCase(fetchAllocationsByProjectIdAndDate.rejected, (state, action: PayloadAction<string | undefined>) => {
        state.status = "failed";
        state.error = action.payload || "Failed to fetch allocations by office.";
      })

      // Handle fetchAllocationsByUser
      .addCase(fetchAllocationsByUserId.pending, (state) => {
        state.status = "loading";
      })
      .addCase(fetchAllocationsByUserId.fulfilled, (state, action: PayloadAction<allocation[]>) => {
        state.status = "succeeded";
        state.data = action.payload;
        state.error = null;
      })
      .addCase(fetchAllocationsByUserId.rejected, (state, action: PayloadAction<string | undefined>) => {
        state.status = "failed";
        state.error = action.payload || "Failed to fetch allocations by office.";
      })

      // Handle addAllocation
      .addCase(addAllocation.pending, (state) => {})
      .addCase(addAllocation.fulfilled, (state, action: PayloadAction<allocation>) => {
        state.data.push(action.payload);
        state.error = null;
      })
      .addCase(addAllocation.rejected, (state, action: PayloadAction<string | undefined>) => {
        state.error = action.payload || "Failed to add allocation.";
      })

      // Handle deleteAllocation
      .addCase(deleteAllocation.pending, (state) => {})
      .addCase(deleteAllocation.fulfilled, (state, action: PayloadAction<string>) => {
        state.data = state.data.filter((allocation) => allocation.id !== action.payload);
        state.error = null;
      })
      .addCase(deleteAllocation.rejected, (state, action: PayloadAction<string | undefined>) => {
        state.error = action.payload || "Failed to delete allocation.";
      })

      // Handle editAllocation
      .addCase(editAllocation.pending, (state) => {})
      .addCase(editAllocation.fulfilled, (state, action: PayloadAction<allocation>) => {
        state.status = "succeeded";
        const index = state.data.findIndex((allocation) => allocation.id === action.payload.id);
        if (index !== -1) {
          state.data[index] = action.payload;
        }
        state.error = null;
      })
      .addCase(editAllocation.rejected, (state, action: PayloadAction<string | undefined>) => {
        state.status = "failed";
        state.error = action.payload || "Failed to edit allocation.";
      });
  },
});

// Real-time subscription function
export const subscribeToAllocations = (dispatch: any) => {
  const subscription = supabase
    .channel("public:allocations")
    .on("postgres_changes", { event: "*", schema: "public", table: "allocations" }, (payload) => {
      const newRecord = payload.new as allocation;
      const oldRecord = payload.old as allocation;
      switch (payload.eventType) {
        case "INSERT":
          dispatch(addAllocationRealTime(newRecord));
          break;
        case "UPDATE":
          dispatch(editAllocationRealTime(newRecord));
          break;
        case "DELETE":
          dispatch(deleteAllocationRealTime(oldRecord.id ?? ""));
          break;
        default:
          break;
      }
    })
    .subscribe();

  return () => {
    subscription.unsubscribe();
  };
};

export default allocationSlice.reducer;
