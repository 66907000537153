import React, { useEffect, useState } from "react";
import Paper from "@material-ui/core/Paper";
import { Spin, Button, Popconfirm, Select, message } from "antd";
import { useTranslation } from "react-i18next";
import moment from "moment";
import DevTable from "../../helpers/devex-table";
import { globalOfficesData, OFFICES_ID_NAME } from "../../global/officeList";
import { globalUsersData } from "../../global/usersList";
import EditWorkOffice from "./editWorkOfficeDialog";
import { useAppDispatch, useAppSelector } from "../../hooks/reduxHooks";
import {
  deleteWorkOffice,
  subscribeToWorkOffices,
  fetchWorkOfficesByOffice,
} from "../../features/workOffice/workOfficeSlice";
import { setFilterValue } from "../../features/filters/filtersSlice";
const { Option } = Select;

export default function WorkOfficeTable() {
  const { filterValues } = useAppSelector((state) => state.filters);
  const { data: workOfficesData, status, error } = useAppSelector((store) => store.workOffices);
  const { currentUser } = useAppSelector((state) => state.auth);
  const dispatch = useAppDispatch();
  const [officeId, setOfficeId] = useState(filterValues["officeId"] ?? "Select an office...");

  const { t } = useTranslation();
  const [modalStateEdit, setModalStateEdit] = useState(false);
  const [workOfficeId, setWorkOfficeId] = useState("");
  const [workOffice, setWorkOffice] = useState({} as any);

  useEffect(() => {
    dispatch(fetchWorkOfficesByOffice(officeId));
    const unsubscribe = subscribeToWorkOffices(dispatch);
    return () => {
      unsubscribe();
    };
  }, [dispatch, officeId]);

  if (status == "loading") return <Spin />;

  const data = workOfficesData
    .map((d) => {
      return { ...d, id: d.id };
    })
    .filter((d: any) => currentUser?.fullControlOffices?.includes(d.office));

  const columns = [
    {
      name: "uid",
      title: "UID",
    },
    {
      name: "name",
      title: "Name",
      getCellValue: (row: any) => globalUsersData[row.uid]?.firstName + " " + globalUsersData[row.uid]?.lastName,
    },
    {
      name: "by",
      title: "Raised By",
      getCellValue: (row: any) => globalUsersData[row.by]?.firstName + " " + globalUsersData[row.by]?.lastName,
    },

    {
      name: "office",
      title: "Office",
      getCellValue: (row: any) => globalOfficesData[row.office]?.officeName,
    },

    {
      name: "startDate",
      title: "Start Date",
      getCellValue: (row: any) => moment(row.startDate).format("DD.MM.YYYY"),
    },

    {
      name: "actions",
      title: "Actions",
      getCellValue: (row: any) => {
        return (
          <Popconfirm
            title={t("general.areUSure")}
            onConfirm={() => {
              dispatch(deleteWorkOffice(row.id)).unwrap();
              message.success("Deleted Successfully!");
            }}
            onCancel={() => console.log("cancel")}
            okText="Yes"
            cancelText="No"
          >
            <Button type="link">Delete</Button>
          </Popconfirm>
        );
      },
    },

    {
      name: "edit",
      title: "Edit",
      getCellValue: (row: any) => {
        return (
          <Button
            onClick={() => {
              setModalStateEdit(true);
              setWorkOfficeId(row.id);
              setWorkOffice(row);
            }}
            type="primary"
          >
            Edit
          </Button>
        );
      },
    },
  ];
  const columnsExport = [
    {
      name: "uid",
      title: "UID",
    },
    {
      name: "name",
      title: "Name",
      getCellValue: (row: any) => globalUsersData[row.uid]?.firstName + " " + globalUsersData[row.uid]?.lastName,
    },
    {
      name: "by",
      title: "Raised By",
      getCellValue: (row: any) => globalUsersData[row.by]?.firstName + " " + globalUsersData[row.by]?.lastName,
    },

    {
      name: "office",
      title: "Office",
      getCellValue: (row: any) => globalOfficesData[row.office]?.officeName,
    },

    {
      name: "startDate",
      title: "Start Date",
      getCellValue: (row: any) => moment(row.startDate).format("DD.MM.YYYY"),
    },
    // {
    //   name: "endDate",
    //   title: "End Date",
    //   getCellValue: (row: any) => moment(row.endDate).format("DD.MM.YYYY"),
    // },
  ];

  if (error) {
    return <div>{error}</div>;
  }
  return (
    <div>
      <Select
        filterOption={(inputValue, option) => {
          return (option.props.children as any).toLowerCase().indexOf(inputValue.toLowerCase()) >= 0;
        }}
        size="default"
        placeholder="Search for Office!"
        style={{ width: "25%", paddingBottom: "1%", paddingRight: "1%" }}
        showSearch
        value={officeId}
        onChange={(e) => {
          setOfficeId(e);
          dispatch(setFilterValue({ key: "officeId", value: e }));
        }}
      >
        {OFFICES_ID_NAME.map((d) => {
          return <Option value={d.officeId}>{d.officeName}</Option>;
        })}
      </Select>

      {modalStateEdit ? (
        <EditWorkOffice
          modalState={modalStateEdit}
          setModalState={setModalStateEdit}
          workOfficeId={workOfficeId}
          workOffice={workOffice}
        />
      ) : null}

      <Paper>
        <DevTable
          data={data}
          columns={columns}
          defaultHiddenColumnNames={[]}
          tableName="deduction"
          selected={false}
          typicalTable={false}
          columnsExport={columnsExport}
        />
      </Paper>
    </div>
  );
}
