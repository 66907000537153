import { createSlice, createAsyncThunk, PayloadAction } from "@reduxjs/toolkit";
import { supabase } from "../../supabaseClient"; // Adjust the import path as needed
import { task } from "../../db/interfaces"; // Make sure your task interface is defined here

interface tasksSliceState {
  data: task[];
  status: "loading" | "succeeded" | "failed" | "idle";
  error: string | null;
  globalTasksData: Record<string, task>;
  loaded: boolean;
}

const initialState: tasksSliceState = {
  data: [],
  status: "idle",
  error: null,
  globalTasksData: {},
  loaded: false,
};

// Fetch all tasks in batches
export const fetchAllTasks = createAsyncThunk("tasks/fetchAllTasks", async (_, { rejectWithValue }) => {
  const { data, error } = await supabase.from("tasks").select("*");
  if (error) {
    return rejectWithValue(error.message as string);
  }

  return data;
});

// Fetch all position tasks from Supabase
export const fetchTasksByDepartmentId = createAsyncThunk<task[], string, { rejectValue: string }>(
  "positionTasks/fetchTasksByDepartmentId",
  async (departmentId, thunkApi) => {
    const { data, error } = await supabase.from("tasks").select("*").eq("departmentId", departmentId);

    if (error) {
      return thunkApi.rejectWithValue(error.message);
    }
    return data as task[];
  }
);
// Add a new task
export const addTask = createAsyncThunk<task, task, { rejectValue: string }>(
  "tasks/addTask",
  async (newTask, thunkApi) => {
    const { data: insertData, error: insertError } = await supabase.from("tasks").insert(newTask).select().single();

    if (insertError) {
      return thunkApi.rejectWithValue(insertError.message);
    }

    return insertData as task;
  }
);

// Delete a task
export const deleteTask = createAsyncThunk<string, string, { rejectValue: string }>(
  "tasks/deleteTask",
  async (id, thunkApi) => {
    const { error } = await supabase.from("tasks").delete().eq("id", id);

    if (error) {
      return thunkApi.rejectWithValue(error.message);
    }

    return id;
  }
);

// Edit a task
export const editTask = createAsyncThunk<task, task, { rejectValue: string }>(
  "tasks/editTask",
  async (updatedTask, thunkApi) => {
    const { data: updateData, error: updateError } = await supabase
      .from("tasks")
      .update(updatedTask)
      .eq("id", updatedTask.id)
      .select()
      .single();

    if (updateError) {
      return thunkApi.rejectWithValue(updateError.message);
    }

    return updateData as task;
  }
);

// Real-time actions
export const addTaskRealTime = (newTask: task): PayloadAction<task> => ({
  type: "tasks/addTaskRealTime",
  payload: newTask,
});

export const editTaskRealTime = (updatedTask: task): PayloadAction<task> => ({
  type: "tasks/editTaskRealTime",
  payload: updatedTask,
});

export const deleteTaskRealTime = (id: string): PayloadAction<string> => ({
  type: "tasks/deleteTaskRealTime",
  payload: id,
});

// Slice definition
const tasksSlice = createSlice({
  name: "tasks",
  initialState,
  reducers: {
    addTaskRealTime(state, action: PayloadAction<task>) {
      const newTask = action.payload;
      const exists = state.data.some((task) => task.id === action.payload.id);
      if (!exists) {
        state.data.push(action.payload);
        state.globalTasksData[newTask.id ?? ""] = newTask;
      }
      state.error = null;
    },
    editTaskRealTime(state, action: PayloadAction<task>) {
      const updatedTask = action.payload;
      state.globalTasksData[updatedTask.id ?? ""] = updatedTask;

      const index = state.data.findIndex((task) => task.id === action.payload.id);
      if (index !== -1) {
        const isDifferent = JSON.stringify(state.data[index]) !== JSON.stringify(action.payload);
        if (isDifferent) {
          state.data[index] = action.payload;
        }
      }
      state.error = null;
    },
    deleteTaskRealTime(state, action: PayloadAction<string>) {
      const exists = state.data.some((task) => task.id === action.payload);
      if (exists) {
        state.data = state.data.filter((task) => task.id !== action.payload);
      }
      state.error = null;
    },
  },
  extraReducers: (builder) => {
    builder
      // Handle fetchAllTasks
      .addCase(fetchAllTasks.pending, (state) => {
        state.status = "loading";
      })
      .addCase(fetchAllTasks.fulfilled, (state, action) => {
        state.data = action.payload;
        state.status = "succeeded";
        state.loaded = true;
        action.payload.forEach((task) => {
          state.globalTasksData[task.id ?? ""] = task;
        });
      })
      .addCase(fetchAllTasks.rejected, (state, action) => {
        state.status = "failed";
        state.error = (action.payload as string) || "Failed to fetch tasks.";
      })

      // Handle fetchTasksByDepartmentId
      .addCase(fetchTasksByDepartmentId.pending, (state) => {
        state.status = "loading";
      })
      .addCase(fetchTasksByDepartmentId.fulfilled, (state, action) => {
        state.data = action.payload;
        state.status = "succeeded";
        state.loaded = true;
      })
      .addCase(fetchTasksByDepartmentId.rejected, (state, action) => {
        state.status = "failed";
        state.error = (action.payload as string) || "Failed to fetch tasks.";
      })

      // Handle addTask
      .addCase(addTask.fulfilled, (state, action: PayloadAction<task>) => {
        state.error = null;
      })
      .addCase(addTask.rejected, (state, action: PayloadAction<string | undefined>) => {
        state.error = action.payload || "Failed to add task.";
      })

      // Handle deleteTask
      .addCase(deleteTask.fulfilled, (state, action: PayloadAction<string>) => {
        state.data = state.data.filter((task) => task.id !== action.payload);
        state.error = null;
      })
      .addCase(deleteTask.rejected, (state, action: PayloadAction<string | undefined>) => {
        state.error = action.payload || "Failed to delete task.";
      })

      // Handle editTask
      .addCase(editTask.fulfilled, (state, action: PayloadAction<task>) => {
        const index = state.data.findIndex((task) => task.id === action.payload.id);
        if (index !== -1) {
          state.data[index] = action.payload;
        }
        state.error = null;
      })
      .addCase(editTask.rejected, (state, action: PayloadAction<string | undefined>) => {
        state.error = action.payload || "Failed to edit task.";
      });
  },
});

// Real-time subscription function
export const subscribeToTasks = (dispatch: any) => {
  const subscription = supabase
    .channel("public:tasks")
    .on("postgres_changes", { event: "*", schema: "public", table: "tasks" }, (payload) => {
      const newRecord = payload.new as task;
      const oldRecord = payload.old as task;

      switch (payload.eventType) {
        case "INSERT":
          dispatch(addTaskRealTime(newRecord));
          break;
        case "UPDATE":
          dispatch(editTaskRealTime(newRecord));
          break;
        case "DELETE":
          dispatch(deleteTaskRealTime(oldRecord.id ?? ""));
          break;
        default:
          break;
      }
    })
    .subscribe();

  return () => {
    subscription.unsubscribe();
  };
};

export default tasksSlice.reducer;
