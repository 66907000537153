import React, { useEffect, useState } from "react";
import Paper from "@material-ui/core/Paper";
import { Spin, Button, Popconfirm, message, Select } from "antd";
import { useTranslation } from "react-i18next";
import moment from "moment";
import DevTable from "../../helpers/devex-table";
import { globalOfficesData, OFFICES_ID_NAME } from "../../global/officeList";
import { globalUsersData } from "../../global/usersList";
import EditSupervisor from "./editSupervisorDialog";
import { useAppDispatch, useAppSelector } from "../../hooks/reduxHooks";
import {
  deleteSupervisor,
  fetchSupervisorsByOffice,
  subscribeToSupervisors,
} from "../../features/supervisors/supervisorsSlice";
import { supervisor } from "../../db/interfaces";
import { setFilterValue } from "../../features/filters/filtersSlice";
const { Option } = Select;

export default function SupervisorsTable() {
  const { filterValues } = useAppSelector((state) => state.filters);
  const { data: supervisorsData, status, error } = useAppSelector((store) => store.supervisors);
  const { currentUser } = useAppSelector((state) => state.auth);
  const dispatch = useAppDispatch();
  const [officeId, setOfficeId] = useState(filterValues["officeId"] ?? "Select an office...");


  const { t } = useTranslation();
  const [modalStateEdit, setModalStateEdit] = useState(false);
  const [supervisorId, setSupervisorId] = useState("");
  const [supervisor, setSupervisor] = useState({} as supervisor);

  useEffect(() => {
    dispatch(fetchSupervisorsByOffice(officeId));
    const unsubscribe = subscribeToSupervisors(dispatch);
    return () => {
      unsubscribe();
    };
  }, [dispatch, officeId]);

  if (status == "loading") return <Spin />;

  const data = supervisorsData
    .map((d) => {
      return { ...d, id: d.id };
    })
    .filter((d: any) => currentUser.HRProjectsManagersOffices.includes(d.office));

  const columns = [
    {
      name: "uid",
      title: "UID",
    },
    {
      name: "name",
      title: "Name",
      getCellValue: (row: any) => globalUsersData[row.uid]?.firstName + " " + globalUsersData[row.uid]?.lastName,
    },
    {
      name: "office",
      title: "Office",
      getCellValue: (row: any) => globalOfficesData[row.office ?? ""]?.officeName,
    },

    {
      name: "supervisorUid",
      title: "Supervisor",
      getCellValue: (row: any) =>
        globalUsersData[row.supervisorUid]?.firstName + " " + globalUsersData[row.supervisorUid]?.lastName,
    },
    {
      name: "by",
      title: "Raised By",
      getCellValue: (row: any) => globalUsersData[row.by].firstName + " " + globalUsersData[row.by].lastName,
    },

    {
      name: "startDate",
      title: "Start Date",
      getCellValue: (row: any) => moment(row.startDate).format("DD.MM.YYYY"),
    },

    {
      name: "actions",
      title: "Actions",
      getCellValue: (row: any) => {
        return (
          <Popconfirm
            title={t("general.areUSure")}
            onConfirm={() => {
              dispatch(deleteSupervisor(row.id)).unwrap();
              message.success("Deleted Successfully!");
            }}
            onCancel={() => console.log("cancel")}
            okText="Yes"
            cancelText="No"
          >
            <Button type="link">Delete</Button>
          </Popconfirm>
        );
      },
    },
    {
      name: "edit",
      title: "Edit",
      getCellValue: (row: any) => {
        return (
          <Button
            onClick={() => {
              setModalStateEdit(true);
              setSupervisorId(row.id);
              setSupervisor(row);
            }}
            type="primary"
          >
            Edit
          </Button>
        );
      },
    },
  ];
  const columnsExport = [
    {
      name: "uid",
      title: "UID",
    },
    {
      name: "office",
      title: "Office",
      getCellValue: (row: any) => globalOfficesData[globalUsersData[row.uid]?.office ?? ""]?.officeName,
    },
    {
      name: "name",
      title: "Name",
      getCellValue: (row: any) => globalUsersData[row.uid]?.firstName + " " + globalUsersData[row.uid]?.lastName,
    },
    {
      name: "supervisorUid",
      title: "Supervisor",
      getCellValue: (row: any) =>
        globalUsersData[row.supervisorUid]?.firstName + " " + globalUsersData[row.supervisorUid]?.lastName,
    },
    {
      name: "by",
      title: "Raised By",
      getCellValue: (row: any) => globalUsersData[row.by].firstName + " " + globalUsersData[row.by].lastName,
    },

    {
      name: "startDate",
      title: "Start Date",
      getCellValue: (row: any) => moment(row.startDate).format("DD.MM.YYYY"),
    },
    // {
    //   name: "endDate",
    //   title: "End Date",
    //   getCellValue: (row: any) => moment(row.endDate).format("DD.MM.YYYY"),
    // },
  ];

  return error ? (
    <p>{error}</p>
  ) : (
    <div>
      <Select
        filterOption={(inputValue, option) => {
          return (option.props.children as any).toLowerCase().indexOf(inputValue.toLowerCase()) >= 0;
        }}
        size="default"
        placeholder="Search for Office!"
        style={{ width: "25%", paddingBottom: "1%", paddingRight: "1%" }}
        showSearch
        value={officeId}
        onChange={(e) => {
          setOfficeId(e);
          dispatch(setFilterValue({ key: "officeId", value: e }));
        }}
      >
        {OFFICES_ID_NAME.map((d) => {
          return <Option value={d.officeId}>{d.officeName}</Option>;
        })}
      </Select>

      {modalStateEdit ? (
        <EditSupervisor
          modalState={modalStateEdit}
          setModalState={setModalStateEdit}
          supervisorId={supervisorId}
          supervisor={supervisor}
        />
      ) : null}
      <Paper>
        <DevTable
          data={data}
          columns={columns}
          defaultHiddenColumnNames={[]}
          tableName="Supervisors"
          selected={false}
          typicalTable={false}
          columnsExport={columnsExport}
        />
      </Paper>
    </div>
  );
}
