import { fetchStatics, fetchUsersInBatches, subscribeToUsers } from "../features/users/usersSlice";
import { RootState, store } from "../store";

// Fetch users in batches when this file is first imported, but only if not already loaded
const initializeUsersData = async () => {
  const state: RootState = store.getState();
  const { loaded } = state.users;

  // Only dispatch fetch if the data hasn't been loaded yet
  if (!loaded) {
    await store.dispatch(fetchUsersInBatches());
    await store.dispatch(fetchStatics());
  }
};

// Immediately call to ensure data is fetched
initializeUsersData();

subscribeToUsers(store.dispatch);

// Define a getter function to access the latest users data from the store
export const getUsersData = () => {
  const state: RootState = store.getState();
  const { globalUsersData, USERS_ID_FULL_NAME, statics, loaded } = state.users;
  // if (!loaded) {
  //   console.warn("Users data is not loaded yet.");
  // }

  return { globalUsersData, USERS_ID_FULL_NAME, statics };
};

// Initial export values, populated from the Redux store
export let globalUsersData = getUsersData().globalUsersData;
export let USERS_ID_FULL_NAME = getUsersData().USERS_ID_FULL_NAME;
export let statics = getUsersData().statics;

// Subscribe to store updates and dynamically update exports when the Redux state changes
store.subscribe(() => {
  const {
    globalUsersData: updatedGlobalUsersData,
    USERS_ID_FULL_NAME: updatedUSERS_ID_FULL_NAME,
    statics: updatedStatics,
  } = getUsersData();

  // Update the exports with the latest values from the Redux store
  globalUsersData = updatedGlobalUsersData;
  USERS_ID_FULL_NAME = updatedUSERS_ID_FULL_NAME;
  statics = updatedStatics;
});
