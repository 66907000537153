import React, { useState, useEffect, useRef } from "react";
import { Button, Col, Divider, Form, Icon, Input, List, message, Popconfirm, Row, Select, Spin, Upload } from "antd";
const { Option } = Select;
import { useFormik } from "formik";
import * as Yup from "yup";
import { useTranslation } from "react-i18next";
import { useRouteMatch } from "react-router-dom";
import { Container } from "@material-ui/core";
import AddNewDepartmentPosition from "./addNewDepartmentPositionDialog";
import PositionsTable from "./departmentPositionsTable";
import ReactPlayer from "react-player";
import { admins } from "../../db/admin";
import DraftText from "../Meetings_Rooms/TextEditor/Draft";
import { stateFromHTML } from "draft-js-import-html";
import { EditorState } from "draft-js";
import { stateToHTML } from "draft-js-export-html";
import { USERS_ID_FULL_NAME } from "../../global/usersList";
import { useAppDispatch, useAppSelector } from "../../hooks/reduxHooks";
import { editDepartment, fetchAllDepartments } from "../../features/departments/departmentsSlice";
import {
  fetchAllDepartmentPositionsByDepartmentId,
  subscribeToDepartmentPositions,
} from "../../features/departmentPositions/departmentPositionsSlice";
import { globalDepartmentsData } from "../../global/departmentList";
const projectSchema = Yup.object().shape({});
import moment from "moment";
import { deleteFile, uploadFile } from "../../db/supabase-storage";
import { fetchAllPositionTasksByDepartmentId } from "../../features/positionTasks/positionTasksSlice";
import { fetchTags } from "../../features/tags/tagsSlice";

function DepartmentDashboard() {
  const { currentUser } = useAppSelector((state) => state.auth);
  const { data: departmentsData, status: departmentsStatus } = useAppSelector((state) => state.departments);
  const { departmentPositionsByDepartmentId: departmentPositionsData, status: departmentPositionsStatus } =
    useAppSelector((state) => state.departmentPositions);
  const { status: tasksByDepartmentIdStatus } = useAppSelector((state) => state.tasks);
  const { status: tagsStatus } = useAppSelector((state) => state.tags);

  const dispatch = useAppDispatch();

  const departmentId = useRouteMatch<{ id: string }>().params.id;

  const { t } = useTranslation();
  const [loading, setLoading] = useState(false);
  const [templateFileList, setTemplateFileList] = useState([] as any);
  const [policiesFileList, setPoliciesFileList] = useState([] as any);
  const [SOPsFileList, setSOPsFileList] = useState([] as any);
  const [videoFileList, setVideoFileList] = useState([] as any);

  const [templateAttachedDescription, setTemplateAttachedDescription] = useState("");
  const [policyAttachedDescription, setPolicyAttachedDescription] = useState("");
  const [SOPAttachedDescription, setSOPAttachedDescription] = useState("");
  const [videoDescription, setVideoDescription] = useState("");
  const [editorState, setEditorState] = useState<EditorState>(EditorState.createWithContent(stateFromHTML("")));
  const html = stateToHTML(editorState.getCurrentContent());
  const [newPositionModalState, setNewPositionModalState] = useState(false);
  const [loaded, setLoaded] = useState(true);

  const uid = currentUser.id as string;

  const templateFileInputRef = useRef(null);
  const policiesFileInputRef = useRef(null);
  const SOPsFileInputRef = useRef(null);
  const videosFileInputRef = useRef(null);

  const handleButtonClick = (fileInputRef: any) => {
    if (fileInputRef.current) {
      fileInputRef.current.click();
    }
  };

  useEffect(() => {
    dispatch(fetchAllDepartments());
    dispatch(fetchAllDepartmentPositionsByDepartmentId(departmentId));
    dispatch(fetchAllPositionTasksByDepartmentId(departmentId));
    dispatch(fetchTags());

    const unsubscribe = subscribeToDepartmentPositions(dispatch);
    return () => {
      unsubscribe();
    };
  }, [dispatch, departmentId]);

  const departmentData = globalDepartmentsData[departmentId ?? ""];

  const departmentPositions = departmentPositionsData;

  const departments = departmentsData;

  const formik = useFormik({
    initialValues: {
      departmentName: "",
      referenceDepartment: null,
      HOD: null,
      overview: "",
      templatesFiles: [],
      templates: [],
      policiesFiles: [],
      policies: [],
      SOPsFiles: [],
      SOPs: [],
      orientational_video: [],
      orientational_video_files: [],
    },
    validationSchema: projectSchema,
    onSubmit: async (values) => {
      try {
        setLoading(true);
        await dispatch(
          editDepartment({
            id: departmentId,
            departmentName: values.departmentName,
            overview: html,
            referenceDepartment: values.referenceDepartment ?? null,
            HOD: values.HOD ?? null,
            createdAt: moment() as any,
            by: uid,
          } as any)
        ).then(async () => {
          setLoading(false);
          message.success("Updated Successfully!");
        });
      } catch (error) {
        setLoading(false);
        console.error("Error writing document: ", error);
        message.error("Failed");
      }
    },
  });
  useEffect(() => {
    if (departmentData && loaded) {
      formik.setValues({
        departmentName: departmentData?.departmentName,
        overview: departmentData?.overview,
        referenceDepartment: departmentData?.referenceDepartment,
        HOD: departmentData?.HOD,
        templates: departmentData?.templates ?? [],
        policies: departmentData?.policies ?? [],
        SOPs: departmentData?.SOPs ?? [],
        orientational_video: departmentData?.orientational_video ?? [],
      } as any);
      setLoaded(false);
      setEditorState(EditorState.createWithContent(stateFromHTML(departmentData?.overview)));
    }
  }, [departmentData, formik, loaded, departmentId, dispatch]);

  const handleFileUpload = async (files: any, attachedDescription: string, attachmentFolder: string) => {
    try {
      const downloadURLs = await Promise.all(
        files.map(async (file: any) => {
          const filePath = `departments/${departmentId}/${attachmentFolder}/${file.name}`;
          try {
            const url = await uploadFile(file, filePath);

            if (url) {
              message.success("File uploaded successfully");
              return {
                name: file.name,
                url: url,
                attachedDescription: attachedDescription ?? "",
              };
            }
          } catch (error: any) {
            message.error(error.message);
          }
        })
      );
      return downloadURLs;
    } catch (error) {
      console.error("Error uploading files:", error);
      return [];
    }
  };

  const uploadTemplates = async () => {
    setLoading(true);
    const promise = handleFileUpload(formik.values.templatesFiles, templateAttachedDescription, "templates");
    promise.then((newTemplateFiles) => {
      const allTemplates = [...formik.values.templates, ...newTemplateFiles];
      formik.setFieldValue("templates", allTemplates);
      dispatch(
        editDepartment({
          id: departmentId,
          templates: allTemplates,
        } as any)
      );
    });
    promise.then(() => {
      setTemplateAttachedDescription("");
      setTemplateFileList([]);
      message.success("Files are uploaded successfully!");
      setLoading(false);
    });
    promise.catch(() => {
      message.error("Something went wrong!");
      setLoading(false);
    });
  };

  const uploadPolicies = async () => {
    setLoading(true);
    const promise = handleFileUpload(formik.values.policiesFiles, policyAttachedDescription, "policies");
    promise.then((newPolicyFiles) => {
      const allPolicies = [...formik.values.policies, ...newPolicyFiles];
      formik.setFieldValue("policies", allPolicies);

      dispatch(
        editDepartment({
          id: departmentId,
          policies: allPolicies,
        } as any)
      );
    });
    promise.then(() => {
      setPolicyAttachedDescription("");
      setPoliciesFileList([]);
      message.success("Files are uploaded successfully!");
      setLoading(false);
    });
    promise.catch(() => {
      message.error("Something went wrong!");
      setLoading(false);
    });
  };

  const uploadSOPs = async () => {
    setLoading(true);
    const promise = handleFileUpload(formik.values.SOPsFiles, SOPAttachedDescription, "SOPs");
    promise.then((newSOPFiles) => {
      const allSOPs = [...formik.values.SOPs, ...newSOPFiles];
      formik.setFieldValue("SOPs", allSOPs);

      dispatch(
        editDepartment({
          id: departmentId,
          SOPs: allSOPs,
        } as any)
      );
    });
    promise.then(() => {
      setSOPAttachedDescription("");
      setSOPsFileList([]);
      message.success("Files are uploaded successfully!");
      setLoading(false);
    });
    promise.catch(() => {
      message.error("Something went wrong!");
      setLoading(false);
    });
  };

  const uploadVideo = async () => {
    setLoading(true);
    const promise = handleFileUpload(formik.values.orientational_video_files, videoDescription, "orientational_video");
    promise.then((newVideoFiles) => {
      const allVideos = [...formik.values.orientational_video, ...newVideoFiles];
      formik.setFieldValue("orientational_video", allVideos);

      dispatch(
        editDepartment({
          id: departmentId,
          orientational_video: allVideos,
        } as any)
      );
    });
    promise.then(() => {
      setVideoFileList([]);
      message.success("Video is uploaded successfully!");
      setLoading(false);
    });
    promise.catch(() => {
      message.error("Something went wrong!");
      setLoading(false);
    });
  };

  const handleDeleteFile = async (fileName: string, attachmentFolder: string) => {
    try {
      setLoading(true);
      await deleteFile(`departments/${departmentId}/${attachmentFolder}/${fileName}`).then(async () => {
        const currentFiles = (formik.values as any)[attachmentFolder];
        const filteredCurrentFiles = currentFiles.filter((r: any) => r.name != fileName);

        await dispatch(
          editDepartment({
            id: departmentId,
            [attachmentFolder]: filteredCurrentFiles,
          } as any)
        );

        formik.setFieldValue(attachmentFolder, filteredCurrentFiles);
        setLoading(false);
      });
    } catch (error) {
      console.error("Error deleting file:", error);
      setLoading(false);
    }
  };
  const antIcon = <Icon type="loading" style={{ fontSize: 75 }} spin />;

  return (
    <Spin
      spinning={
        loading ||
        departmentPositionsStatus == "loading" ||
        departmentsStatus == "loading" ||
        tasksByDepartmentIdStatus == "loading" ||
        tagsStatus == "loading"
      }
      size="large"
      indicator={antIcon}
    >
      <AddNewDepartmentPosition
        departmentId={departmentId}
        modalState={newPositionModalState}
        setModalState={setNewPositionModalState}
      />
      <Container maxWidth="md" style={{ background: "#ffffffc7", borderRadius: "6px" }}>
        <div style={{ padding: "5%" }}>
          <Form onSubmit={formik.handleSubmit}>
            <Row gutter={20}>
              <Col span={8}>
                <Form.Item label={t("general.departmentName")}>
                  <Input
                    value={formik.values.departmentName}
                    onChange={(e) => formik.setFieldValue("departmentName", e.target.value)}
                  />
                </Form.Item>
              </Col>
              <Col span={8}>
                <Form.Item label={t("general.referenceDepartment")}>
                  <Select
                    filterOption={(inputValue, option) => {
                      return (option.props.children as any).toLowerCase().indexOf(inputValue.toLowerCase()) >= 0;
                    }}
                    allowClear
                    size="default"
                    placeholder="Parent Department"
                    showSearch
                    value={formik.values.referenceDepartment}
                    onChange={(e) => {
                      formik.setFieldValue("referenceDepartment", e);
                    }}
                  >
                    {departments?.map((d) => {
                      return <Option key={d.id} value={d.id}>{d.departmentName}</Option>;
                    })}
                  </Select>
                </Form.Item>
              </Col>
              <Col span={8}>
                <Form.Item label={t("general.HOD")}>
                  <Select
                    filterOption={(inputValue, option) => {
                      return (option.props.children as any).toLowerCase().indexOf(inputValue.toLowerCase()) >= 0;
                    }}
                    allowClear
                    disabled={!admins.includes(uid)}
                    placeholder="Head of department"
                    showSearch
                    value={formik.values.HOD}
                    onChange={(e) => {
                      formik.setFieldValue("HOD", e);
                    }}
                  >
                    {USERS_ID_FULL_NAME?.map((d) => {
                      return <Option key={d.uid} value={d.uid}>{d.fullName}</Option>;
                    })}
                  </Select>
                </Form.Item>
              </Col>
            </Row>
            <Row gutter={20}>
              <Col span={24}>
                <Form.Item label={t("general.overview")}>
                  <DraftText
                    onValueChange={() => {}}
                    onMentionChange={() => {}}
                    html={""}
                    editorState={editorState}
                    setEditorState={setEditorState}
                  />
                </Form.Item>
              </Col>
            </Row>
            <Divider orientation="center">Department Orientational Video</Divider>

            <Row gutter={20}>
              <Col span={5}>
                <Form.Item label="Upload Video">
                  <Button onClick={() => handleButtonClick(videosFileInputRef)}>
                    <Icon type="upload" /> {`Click to upload ${videoFileList.length}`}
                    <input
                      ref={videosFileInputRef} // Attach the ref to the input
                      hidden
                      type="file"
                      multiple
                      onChange={async (e) => {
                        if (e.target.files) {
                          setVideoFileList([...Object.values(e?.target?.files as any)]);
                          formik.setFieldValue("orientational_video_files", [
                            ...Object.values(e?.target?.files as any),
                          ]);
                        }
                      }}
                    />
                  </Button>
                </Form.Item>
              </Col>
              <Col span={15}>
                <Form.Item label="Description">
                  <Input onChange={(e) => setVideoDescription(e.target.value)} value={videoDescription} />
                </Form.Item>
              </Col>
              <Col span={4}>
                <Form.Item label="Confirm">
                  <Button
                    onClick={uploadVideo}
                    disabled={videoDescription == "" || videoFileList.length == 0}
                    className="btn blue lighten-1 z-depth-0"
                    type="primary"
                  >
                    {t("general.upload")}
                  </Button>
                </Form.Item>
              </Col>
            </Row>
            {formik.values?.orientational_video?.length > 0 &&
              formik.values?.orientational_video.map((file: any) => (
                <Row
                  gutter={20}
                  style={{
                    paddingTop: "2%",
                    paddingBottom: "2%",
                    marginTop: "2%",
                    borderRadius: "5px",
                    background: "white",
                  }}
                >
                  <Col span={9}>
                    <p>{file.attachedDescription}</p>
                    <Popconfirm
                      title={"Are you sure?"}
                      onConfirm={() => handleDeleteFile(file.name, "orientational_video")}
                      onCancel={() => console.log("cancel")}
                      okText="Yes"
                      cancelText="No"
                    >
                      <Button type="danger">
                        <Icon type="delete" /> Remove
                      </Button>
                    </Popconfirm>
                  </Col>
                  <Col span={10}>
                    <ReactPlayer width={"500px"} height={"284px"} controls={true} url={file.url} />
                  </Col>
                </Row>
              ))}
            <Divider orientation="center">Department Positions</Divider>
            <PositionsTable data={departmentPositions ?? []} departmentId={departmentId} department={departmentData} />

            <Button
              style={{ marginTop: "2%" }}
              className="btn blue lighten-1 z-depth-0"
              type="primary"
              onClick={() => {
                setNewPositionModalState(true);
              }}
            >
              Add New Position
            </Button>

            <Divider orientation="center">Templates Attachmentsss</Divider>

            <Row gutter={20}>
              <Col span={5}>
                <Form.Item label="Templates Attachments">
                  <Button onClick={() => handleButtonClick(templateFileInputRef)}>
                    <Icon type="upload" /> {`Click to upload ${templateFileList.length}`}
                    <input
                      ref={templateFileInputRef} // Attach the ref to the input
                      hidden
                      type="file"
                      multiple
                      onChange={async (e) => {
                        if (e.target.files) {
                          setTemplateFileList([...Object.values(e?.target?.files as any)]);
                          formik.setFieldValue("templatesFiles", [...Object.values(e?.target?.files as any)]);
                        }
                      }}
                    />
                  </Button>
                </Form.Item>
              </Col>
              <Col span={15}>
                <Form.Item label="Description">
                  <Input
                    onChange={(e) => setTemplateAttachedDescription(e.target.value)}
                    value={templateAttachedDescription}
                  />
                </Form.Item>
              </Col>
              <Col span={4}>
                <Form.Item label="Confirm">
                  <Button
                    onClick={uploadTemplates}
                    disabled={templateAttachedDescription == "" || templateFileList.length == 0}
                    className="btn blue lighten-1 z-depth-0"
                    type="primary"
                  >
                    {t("general.upload")}
                  </Button>
                </Form.Item>
              </Col>
            </Row>

            {formik.values?.templates?.length > 0 && (
              <div>
                <List
                  itemLayout="horizontal"
                  bordered
                  dataSource={formik.values?.templates}
                  renderItem={(file: any) => (
                    <List.Item>
                      <div>
                        <a href={file.url} target="_blank" rel="noopener noreferrer">
                          {file.name}
                        </a>
                        <p style={{ margin: "0px" }}>{file.attachedDescription}</p>
                      </div>
                      <Popconfirm
                        title={"Are you sure?"}
                        onConfirm={() => handleDeleteFile(file.name, "templates")}
                        onCancel={() => console.log("cancel")}
                        okText="Yes"
                        cancelText="No"
                      >
                        <Button type="link" style={{ color: "red" }}>
                          <Icon type="delete" />
                        </Button>
                      </Popconfirm>
                    </List.Item>
                  )}
                />
              </div>
            )}

            <Divider orientation="center">Policies Attachments</Divider>

            <Row gutter={20}>
              <Col span={5}>
                <Form.Item label="Policies Attachments">
                  <Button onClick={() => handleButtonClick(policiesFileInputRef)}>
                    <Icon type="upload" /> {`Click to upload ${policiesFileList.length}`}
                    <input
                      ref={policiesFileInputRef} // Attach the ref to the input
                      hidden
                      type="file"
                      multiple
                      onChange={async (e) => {
                        if (e.target.files) {
                          setPoliciesFileList([...Object.values(e?.target?.files as any)]);
                          formik.setFieldValue("policiesFiles", [...Object.values(e?.target?.files as any)]);
                        }
                      }}
                    />
                  </Button>
                </Form.Item>
              </Col>
              <Col span={15}>
                <Form.Item label="Description">
                  <Input
                    onChange={(e) => setPolicyAttachedDescription(e.target.value)}
                    value={policyAttachedDescription}
                  />
                </Form.Item>
              </Col>
              <Col span={4}>
                <Form.Item label="Confirm">
                  <Button
                    onClick={uploadPolicies}
                    disabled={policyAttachedDescription == "" || policiesFileList.length == 0}
                    className="btn blue lighten-1 z-depth-0"
                    type="primary"
                  >
                    {t("general.upload")}
                  </Button>
                </Form.Item>
              </Col>
            </Row>

            {formik.values?.policies?.length > 0 && (
              <div>
                <List
                  itemLayout="horizontal"
                  bordered
                  dataSource={formik.values?.policies}
                  renderItem={(file: any) => (
                    <List.Item>
                      <div>
                        <a href={file.url} target="_blank" rel="noopener noreferrer">
                          {file.name}
                        </a>
                        <p style={{ margin: "0px" }}>{file.attachedDescription}</p>
                      </div>
                      <Popconfirm
                        title={"Are you sure?"}
                        onConfirm={() => handleDeleteFile(file.name, "policies")}
                        onCancel={() => console.log("cancel")}
                        okText="Yes"
                        cancelText="No"
                      >
                        <Button type="link" style={{ color: "red" }}>
                          <Icon type="delete" />
                        </Button>
                      </Popconfirm>
                    </List.Item>
                  )}
                />
              </div>
            )}

            <Divider orientation="center">SOPs Attachments</Divider>

            <Row gutter={20}>
              <Col span={5}>
                <Form.Item label="SOPs Attachments">
                  <Button onClick={() => handleButtonClick(SOPsFileInputRef)}>
                    <Icon type="upload" /> {`Click to upload ${SOPsFileList.length}`}
                    <input
                      ref={SOPsFileInputRef} // Attach the ref to the input
                      hidden
                      type="file"
                      multiple
                      onChange={async (e) => {
                        if (e.target.files) {
                          setSOPsFileList([...Object.values(e?.target?.files as any)]);
                          formik.setFieldValue("SOPsFiles", [...Object.values(e?.target?.files as any)]);
                        }
                      }}
                    />
                  </Button>
                </Form.Item>
              </Col>
              <Col span={15}>
                <Form.Item label="Description">
                  <Input onChange={(e) => setSOPAttachedDescription(e.target.value)} value={SOPAttachedDescription} />
                </Form.Item>
              </Col>
              <Col span={4}>
                <Form.Item label="Confirm">
                  <Button
                    onClick={uploadSOPs}
                    disabled={SOPAttachedDescription == "" || SOPsFileList.length == 0}
                    className="btn blue lighten-1 z-depth-0"
                    type="primary"
                  >
                    {t("general.upload")}
                  </Button>
                </Form.Item>
              </Col>
            </Row>

            {formik.values?.SOPs?.length > 0 && (
              <div>
                <List
                  itemLayout="horizontal"
                  bordered
                  dataSource={formik.values?.SOPs}
                  renderItem={(file: any) => (
                    <List.Item>
                      <div>
                        <a href={file.url} target="_blank" rel="noopener noreferrer">
                          {file.name}
                        </a>
                        <p style={{ margin: "0px" }}>{file.attachedDescription}</p>
                      </div>
                      <Popconfirm
                        title={"Are you sure?"}
                        onConfirm={() => handleDeleteFile(file.name, "SOPs")}
                        onCancel={() => console.log("cancel")}
                        okText="Yes"
                        cancelText="No"
                      >
                        <Button type="link" style={{ color: "red" }}>
                          <Icon type="delete" />
                        </Button>
                      </Popconfirm>
                    </List.Item>
                  )}
                />
              </div>
            )}
            <Divider />

            <div>
              <Button
                className="btn blue lighten-1 z-depth-0"
                type="primary"
                htmlType="submit"
                disabled={!formik.isValid}
              >
                {t("general.save")}
              </Button>
            </div>
          </Form>
        </div>
      </Container>
    </Spin>
  );
}

export default DepartmentDashboard;
