import React, { useEffect } from "react";
import { BrowserRouter, Switch, Route, RouteComponentProps, useHistory } from "react-router-dom";
import Dashboard from "./components/dashboard/Dashboard";
import SignIn from "./components/auth/SignIn";
import "antd/dist/antd.css";
import { Spin, Icon } from "antd";
import table from "./components/User/usersTable";
import orderTable from "./components/orders/OrderTable/OrderTable";
import LayoutApp from "./components/layout/LayoutApp";
import arAnt from "antd/lib/locale-provider/ar_EG";
import enAnt from "antd/lib/locale-provider/en_US";
import i18n from "i18next";
import { I18nextProvider, initReactI18next } from "react-i18next";
import moment from "moment";
import en from "./containers/translations/en";
import ar from "./containers/translations/ar";
import tr from "./containers/translations/tr";
import PrintOrder from "./components/orders/printOrder";
import MeetingsRoomTable from "./components/Meetings_Rooms/MeetingRoomTable";
import MeetingCalender from "./components/Meetings_Rooms/MeetingCalendar";
import MeetingsTable from "./components/Meetings_Rooms/MeetingsTable";
import MeetingDetails from "./components/Meetings_Rooms/MeetingDetails";
import VacationDashboard from "./components/Vacation/vacationDashboard";
import VacationsTable from "./components/Vacation/VacationsTable";
import UsersDashboard from "./components/Vacation/usersDashboard";
import UsersDelay from "./components/Delay/usersDelay";
import userRecord from "./components/Delay/userRecord";
import OvertimeDashboard from "./components/Overtime/overtimeDashboard";
import ProjectsTable from "./components/projects/projectsTable";
import BudgetLinesTable from "./components/budgetLines/budgetLinesTable";
import TimeSheet from "./components/timeSheet/timeSheet";
import AllocationsTable from "./components/allocation/allocationsTable";
import OfficesTable from "./components/offices/officesTable";
import "./App.css";
import Analysis from "./analysis/analysis";
import MyProfile from "./components/User/myProfile";
import SignatureTab from "./components/User/signatureTab";
import VacationView from "./components/Vacation/vacationView";
import PositionsTable from "./components/positions/positionsTable";
import calendar from "./components/calenderTest/calendar";
// import ImportTable from "./components/shifts/importsTable";
import FingerPrintRecord from "./components/fingerPrint/fingerPrintRecord";
import UsersAbsent from "./components/Delay/usersAbsent";
import CasesTable from "./components/case/casesTable";
import MissionsTable from "./components/mission/missionsTable";
import "../src/global/missionList";
import "../src/global/projectList";
import "../src/global/budgetLineList";
import "../src/global/officeList";
// import CarsTable from "./components/cars/carsTable";
import UsersDashboardByProject from "./components/Vacation/usersDashboardByProject";
import TimeSheetByOffice from "./components/timeSheet/timeSheet-byOffice";
import TimeSheetByProject from "./components/timeSheet/timeSheet-byProject";
import DeductionTable from "./components/deduction/deductionTable";
import SalariesTable from "./components/salaries/salariesTable";
import WorkOfficeTable from "./components/workOffice/workOfficesTable";
import PayrollByProject from "./components/payroll/payrollByProject";
import PayrollByOffice from "./components/payroll/payrollByOffice";
import PayslipByOffice from "./components/payslip/payslipByOffice";
import AccountantsTable from "./components/accountant/accountantsTable";
import PayrollByOfficeCluster from "./components/payroll/payrollByOfficeCluster";
import ResetPassword from "./components/auth/ResetPassword";
import SupervisorsTable from "./components/supervisors/supervisorsTable";
import PaymentTypesTable from "./components/paymentType/paymentTypesTable";
import ContractPercentageTable from "./components/contractPercentage/contractPercentagesTable";
import DepartmentsTable from "./components/departments/departmentsTable";
import DepartmentDashboard from "./components/departments/departmentDashboard";
import StructureDashboard from "./components/structureDashboard/structureDashboard";
import Chart from "./components/structureDashboard/charts";
import ShiftsTable from "./components/shiftsManagment/shiftsTable";
import { RootState } from "./store";
import { useAppDispatch, useAppSelector } from "./hooks/reduxHooks";
import { checkAuthChange } from "./features/auth/authSlice";
import OrderDashboard from "./components/dashboard/OrderDashboard";

i18n.use(initReactI18next).init({
  fallbackLng: "en",
  lng: "en",
  resources: {
    en: {
      translation: en,
    },
    ar: {
      translation: ar,
    },
    tr: {
      translation: tr,
    },
  },
  interpolation: {
    escapeValue: false,
    format: (value, format) => {
      if (format === "uppercase") {
        return value.toUpperCase();
      }
      if (value instanceof Date) {
        return moment(value).format(format);
      }
      return value;
    },
  },
});

const antIcon = <Icon type="loading" style={{ fontSize: 100 }} spin />;

export default function App() {
  const dispatch = useAppDispatch();
  const { status, user, error, currentUser } = useAppSelector((state: RootState) => state.auth);
  const { loaded } = useAppSelector((state: RootState) => state.users);

  useEffect(() => {
    dispatch(checkAuthChange());
  }, [dispatch]);
  

  return (
    <I18nextProvider i18n={i18n}>
      <Spin spinning={status == "loading" || loaded == false} delay={500} indicator={antIcon}>
        <BrowserRouter>
          <Switch>
            <Route path="/analysis" component={Analysis} />
            <Route path="/print/:id" component={PrintOrder} />
            <Route path="/vacation-paper/:id" component={VacationView} />
            {status == "authenticated" ? (
              <div className="App">
                <LayoutApp>
                  <Switch>
                    {/* <Route path="/OneSignalSDK.sw.js" st={table} /> */}

                    <Route path="/t" component={table} />
                    <Route path="/order-table" component={orderTable} />
                    <Route path="/profile" component={MyProfile} />
                    <Route path="/signature" component={SignatureTab} />
                    <Route path="/cal" component={calendar} />
                    <Route path="/meeting-room" component={MeetingsRoomTable} />
                    <Route path="/meeting-calendar" component={MeetingCalender} />
                    <Route path="/meeting-table" component={MeetingsTable} />
                    <Route path="/vacations-table" component={VacationsTable} />
                    <Route path="/usersControl-table" component={UsersDashboard} />
                    <Route path="/usersControl-byProject-table" component={UsersDashboardByProject} />

                    <Route path="/delay-table" component={UsersDelay} />
                    <Route path="/absent-table" component={UsersAbsent} />
                    <Route path="/user-delay/:id" component={userRecord} />
                    <Route path="/fingerPrint/:id" component={FingerPrintRecord} />
                    <Route path="/calenderDetails/:id" component={MeetingDetails} />
                    <Route path="/vacation/" component={VacationDashboard} />
                    <Route path="/overtime/" component={OvertimeDashboard} />
                    <Route path="/project-table" component={ProjectsTable} />
                    <Route path="/budgetLines-table" component={BudgetLinesTable} />
                    <Route path="/allocations-table" component={AllocationsTable} />
                    <Route path="/positions-table" component={PositionsTable} />
                    <Route path="/deductions-table" component={DeductionTable} />
                    <Route path="/salary-table" component={SalariesTable} />
                    <Route path="/workOffice-table" component={WorkOfficeTable} />
                    <Route path="/supervisor-table" component={SupervisorsTable} />
                    <Route path="/payment-type-table" component={PaymentTypesTable} />
                    <Route path="/contract-percentage-table" component={ContractPercentageTable} />

                    <Route path="/accountants-table" component={AccountantsTable} />

                    <Route path="/shifts-table" component={ShiftsTable} />

                    <Route path="/office-table" component={OfficesTable} />
                    {/* <Route path="/car-table" component={CarsTable} /> */}

                    <Route path="/departments-table" component={DepartmentsTable} />
                    <Route path="/department/:id" component={DepartmentDashboard} />

                    <Route path="/case-table" component={CasesTable} />
                    <Route path="/missions" component={MissionsTable} />

                    {/* <Route path="/imports-table" component={ImportTable} /> */}
                    <Route path="/reset-password" component={ResetPassword} />

                    <Route path="/time-sheet" component={TimeSheet} />
                    <Route path="/timesheet-byOffice" component={TimeSheetByOffice} />
                    <Route path="/timesheet-byProject" component={TimeSheetByProject} />
                    <Route path="/payroll-byProject" component={PayrollByProject} />
                    <Route path="/payroll-byOffice" component={PayrollByOffice} />
                    <Route path="/payroll-byCluster" component={PayrollByOfficeCluster} />

                    {/* <Route path="/payslip-byProject" component={PayslipByProject} /> */}
                    <Route path="/payslip-byOffice" component={PayslipByOffice} />
                    <Route path="/organization-structure" component={Chart} />
                    <Route path="/organization-structure-details/:id" component={StructureDashboard} />

                    <Route path="/orders" component={OrderDashboard} />
                    <Route path="/" component={Dashboard} />
                  </Switch>
                </LayoutApp>
              </div>
            ) : (
              <Route path="/" component={SignIn} />
            )}
          </Switch>
        </BrowserRouter>
        //{" "}
      </Spin>
    </I18nextProvider>
  );
}
