import { createSlice, createAsyncThunk, PayloadAction } from "@reduxjs/toolkit";
import { supabase } from "../../supabaseClient"; // Adjust the import path as needed
import { discussion } from "../../db/interfaces"; // Adjust the import path as needed

interface DiscussionSliceState {
  data: discussion[];
  status: "loading" | "succeeded" | "failed" | "idle";
  error: string | null;
}

const initialState: DiscussionSliceState = {
  data: [],
  status: "idle",
  error: null,
};

// Fetch all discussion records
export const fetchAllDiscussions = createAsyncThunk<discussion[], void, { rejectValue: string }>(
  "discussion/fetchAllDiscussions",
  async (_, thunkApi) => {
    const { data, error } = await supabase.from("discussions").select("*");
    if (error) {
      return thunkApi.rejectWithValue(error.message);
    }
    return data as discussion[];
  }
);

// Fetch discussion by office
export const fetchDiscussionsByOrderId = createAsyncThunk<discussion[], string, { rejectValue: string }>(
  "discussion/fetchDiscussionsByOrderId",
  async (orderId, thunkApi) => {
    const { data, error } = await supabase.from("discussions").select("*").eq("orderId", orderId);

    if (error) {
      return thunkApi.rejectWithValue(error.message);
    }

    return data as discussion[];
  }
);

// Add a new discussion record
export const addDiscussion = createAsyncThunk<discussion, discussion, { rejectValue: string }>(
  "discussion/addDiscussion",
  async (newDiscussion, thunkApi) => {
    const { data: insertData, error: insertError } = await supabase
      .from("discussions")
      .insert(newDiscussion)
      .select()
      .single();

    if (insertError) {
      return thunkApi.rejectWithValue(insertError.message);
    }

    return insertData as discussion;
  }
);

// Edit a discussion record
export const editDiscussion = createAsyncThunk<discussion, discussion, { rejectValue: string }>(
  "discussion/editDiscussion",
  async (updatedDiscussion, thunkApi) => {
    const { data: updateData, error: updateError } = await supabase
      .from("discussions")
      .update(updatedDiscussion)
      .eq("id", updatedDiscussion.id)
      .select()
      .single();

    if (updateError) {
      return thunkApi.rejectWithValue(updateError.message);
    }

    return updateData as discussion;
  }
);

// Delete a discussion record
export const deleteDiscussion = createAsyncThunk<string, string, { rejectValue: string }>(
  "discussion/deleteDiscussion",
  async (id, thunkApi) => {
    const { error } = await supabase.from("discussions").delete().eq("id", id);

    if (error) {
      return thunkApi.rejectWithValue(error.message);
    }

    return id;
  }
);

// Delete a discussion record
export const deleteDiscussionsByOrderId = createAsyncThunk<string, string, { rejectValue: string }>(
  "discussion/deleteDiscussionsByOrderId",
  async (orderId, thunkApi) => {
    const { error } = await supabase.from("discussions").delete().eq("orderId", orderId);

    if (error) {
      return thunkApi.rejectWithValue(error.message);
    }

    return orderId;
  }
);

// Real-Time Actions
export const addDiscussionRealTime = (newDiscussion: discussion): PayloadAction<discussion> => ({
  type: "discussion/addDiscussionRealTime",
  payload: newDiscussion,
});

export const editDiscussionRealTime = (updatedDiscussion: discussion): PayloadAction<discussion> => ({
  type: "discussion/editDiscussionRealTime",
  payload: updatedDiscussion,
});

export const deleteDiscussionRealTime = (id: string): PayloadAction<string> => ({
  type: "discussion/deleteDiscussionRealTime",
  payload: id,
});

// Slice definition
const discussionSlice = createSlice({
  name: "discussion",
  initialState,
  reducers: {
    addDiscussionRealTime(state, action: PayloadAction<discussion>) {
      const exists = state.data.some((discussion) => discussion.id === action.payload.id);
      if (!exists) {
        state.data.push(action.payload);
      }
      state.error = null;
    },
    editDiscussionRealTime(state, action: PayloadAction<discussion>) {
      const index = state.data.findIndex((discussion) => discussion.id === action.payload.id);
      if (index !== -1) {
        state.data[index] = action.payload;
      }
      state.error = null;
    },
    deleteDiscussionRealTime(state, action: PayloadAction<string>) {
      state.data = state.data.filter((discussion) => discussion.id !== action.payload);
      state.error = null;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchAllDiscussions.pending, (state) => {
        state.status = "loading";
      })
      .addCase(fetchAllDiscussions.fulfilled, (state, action: PayloadAction<discussion[]>) => {
        state.status = "succeeded";
        state.data = action.payload;
        state.error = null;
      })
      .addCase(fetchAllDiscussions.rejected, (state, action: PayloadAction<string | undefined>) => {
        state.status = "failed";
        state.error = action.payload || "Failed to fetch discussions.";
      })

      .addCase(fetchDiscussionsByOrderId.pending, (state) => {
        state.status = "loading";
      })
      .addCase(fetchDiscussionsByOrderId.fulfilled, (state, action: PayloadAction<discussion[]>) => {
        state.status = "succeeded";
        state.data = action.payload;
        state.error = null;
      })
      .addCase(fetchDiscussionsByOrderId.rejected, (state, action: PayloadAction<string | undefined>) => {
        state.status = "failed";
        state.error = action.payload || "Failed to fetch discussions by office.";
      })

      .addCase(addDiscussion.pending, (state) => {})
      .addCase(addDiscussion.fulfilled, (state, action: PayloadAction<discussion>) => {
        state.data.push(action.payload);
        state.error = null;
      })
      .addCase(addDiscussion.rejected, (state, action: PayloadAction<string | undefined>) => {
        state.error = action.payload || "Failed to add discussion.";
      })

      .addCase(editDiscussion.pending, (state) => {})
      .addCase(editDiscussion.fulfilled, (state, action: PayloadAction<discussion>) => {
        const index = state.data.findIndex((discussion) => discussion.id === action.payload.id);
        if (index !== -1) {
          state.data[index] = action.payload;
        }
        state.error = null;
      })
      .addCase(editDiscussion.rejected, (state, action: PayloadAction<string | undefined>) => {
        state.status = "failed";
        state.error = action.payload || "Failed to edit discussion.";
      })

      .addCase(deleteDiscussion.pending, (state) => {})
      .addCase(deleteDiscussion.fulfilled, (state, action: PayloadAction<string>) => {
        state.data = state.data.filter((discussion) => discussion.id !== action.payload);
        state.error = null;
      })
      .addCase(deleteDiscussion.rejected, (state, action: PayloadAction<string | undefined>) => {
        state.error = action.payload || "Failed to delete discussion.";
      })

      // delete all Discussions by order id
      .addCase(deleteDiscussionsByOrderId.pending, (state) => {})
      .addCase(deleteDiscussionsByOrderId.fulfilled, (state, action: PayloadAction<string>) => {
        state.data = state.data.filter((discussion) => discussion.id !== action.payload);
        state.error = null;
      })
      .addCase(deleteDiscussionsByOrderId.rejected, (state, action: PayloadAction<string | undefined>) => {
        state.error = action.payload || "Failed to delete discussion.";
      });
  },
});

// Real-time subscription function
export const subscribeToDiscussions = (dispatch: any) => {
  const subscription = supabase
    .channel("public:discussions")
    .on("postgres_changes", { event: "*", schema: "public", table: "discussions" }, (payload) => {
      const newRecord = payload.new as discussion;
      const oldRecord = payload.old as discussion;
      switch (payload.eventType) {
        case "INSERT":
          dispatch(addDiscussionRealTime(newRecord));
          break;
        case "UPDATE":
          dispatch(editDiscussionRealTime(newRecord));
          break;
        case "DELETE":
          dispatch(deleteDiscussionRealTime(oldRecord.id ?? ""));
          break;
        default:
          break;
      }
    })
    .subscribe();

  return () => {
    subscription.unsubscribe();
  };
};

export default discussionSlice.reducer;
