import React, { useEffect } from "react";
const { TabPane } = Tabs;
import { Spin, Tabs, Icon, PageHeader } from "antd";
import { Container } from "@material-ui/core";
import Overview from "./overview";
import Structure from "./structure";
import Tasks from "./tasks";
import Policies from "./policies";
import SOPs from "./SOPs";
import Templates from "./templates";
import { useHistory, useRouteMatch } from "react-router-dom";
import { department } from "../../db/interfaces";
import { useAppDispatch, useAppSelector } from "../../hooks/reduxHooks";
import { fetchAllDepartmentPositionsByDepartmentId } from "../../features/departmentPositions/departmentPositionsSlice";
import { fetchDepartmentById, fetchDepartmentsByParentId } from "../../features/departments/departmentsSlice";
import { fetchAllPositionTasksByDepartmentId } from "../../features/positionTasks/positionTasksSlice";
const antIcon = <Icon type="loading" style={{ fontSize: 75 }} spin />;

export default function StructureDashboard() {
  const { department } = useAppSelector((state) => state.departments);
  const { data: referenceDepartmentsData, status: departmentsStatus } = useAppSelector((state) => state.departments);
  const { departmentPositionsByDepartmentId: departmentPositionsData, status: departmentPositionsStatus } =
    useAppSelector((state) => state.departmentPositions);
  const { status: positionsTasksByDepartmentIdStatus } = useAppSelector((state) => state.positionTasks);

  const dispatch = useAppDispatch();

  const departmentId = useRouteMatch<{ id: string }>().params.id as string;
  const history = useHistory();

  useEffect(() => {
    dispatch(fetchDepartmentById(departmentId));
    dispatch(fetchDepartmentsByParentId(departmentId));
    dispatch(fetchAllDepartmentPositionsByDepartmentId(departmentId));
    dispatch(fetchAllPositionTasksByDepartmentId(departmentId));
  }, [dispatch, departmentId]);

  const departmentData = department;

  const subDepartmentsData = referenceDepartmentsData;

  const departmentPositions = departmentPositionsData;

  return (
    <div>
      <Spin
        spinning={
          departmentPositionsStatus == "loading" ||
          departmentsStatus == "loading" ||
          positionsTasksByDepartmentIdStatus == "loading"
        }
        size="large"
        indicator={antIcon}
      >
        <PageHeader
          style={{
            border: "1px solid rgb(235, 237, 240)",
            background: "white",
            borderRadius: "6px",
            marginBottom: "1%",
          }}
          onBack={() => history.goBack()}
          title={departmentData?.shortcut}
          subTitle={departmentData?.departmentName}
        />
        <Container maxWidth="xl" style={{ background: "white", borderRadius: "6px", padding: "4%" }}>
          <Tabs defaultActiveKey="1">
            <TabPane tab="Overview" key="1">
              <Overview
                departmentData={departmentData ?? {}}
                subDepartmentsData={(subDepartmentsData ?? []) as department[]}
              />
            </TabPane>
            <TabPane tab="Structure" key="2">
              <Structure departmentPositions={departmentPositions ?? []} />
            </TabPane>
            <TabPane tab="Responsibilities" key="3">
              <Tasks />
            </TabPane>
            <TabPane tab="Policies" key="4">
              <Policies departmentData={departmentData ?? {}} />
            </TabPane>
            <TabPane tab="SOPs" key="5">
              <SOPs departmentData={departmentData ?? {}} />
            </TabPane>
            <TabPane tab="Templates and Forms" key="6">
              <Templates departmentData={departmentData ?? {}} />
            </TabPane>
          </Tabs>
        </Container>
      </Spin>
    </div>
  );
}
