import React, { useState, useEffect } from "react";
import { Button, DatePicker, Drawer, Form, message, Select, Input, Divider } from "antd";
import moment from "moment";
const { Option } = Select;
import { useFormik } from "formik";
import { useTranslation } from "react-i18next";
import { Case } from "../../db/interfaces";
import { globalUsersData, USERS_ID_FULL_NAME } from "../../global/usersList";
import { ALL_OFFICES_ID_NAME, globalOfficesData, OFFICES_ID_NAME } from "../../global/officeList";
import TextArea from "antd/lib/input/TextArea";
import Discussion from "../../discussion/discussion";
import { useAppDispatch, useAppSelector } from "../../hooks/reduxHooks";
import { editCase } from "../../features/cases/casesSlice";

interface Props {
  caseDoc: Case;
  modalState: boolean;
  uid?: string;
  setModalState: React.Dispatch<React.SetStateAction<boolean>>;
}

function EditCase(props: Props) {
  const { currentUser } = useAppSelector((state) => state.auth);
  const dispatch = useAppDispatch();

  const { t } = useTranslation();
  const { caseDoc, modalState, setModalState, uid } = props;
  const [loading, setLoading] = useState(false);
  const caseData = caseDoc;

  const formik = useFormik({
    initialValues: {
      caseDate: moment(),
      caseNO: "",
      region: "",
      officeId: "",
      findings: "",
      assignedTo: "",
      finalResoluation: "",
      status: "",
    },
    onSubmit: async (values) => {
      try {
        await dispatch(
          editCase({
            id: caseDoc.id,
            caseDate: values.caseDate as any,
            caseNO: values.caseNO,
            region: values.region,
            officeId: values.officeId,
            cacheOffice: globalOfficesData[values.officeId ?? ""]?.officeName,
            findings: values.findings,
            assignedTo: values.assignedTo,
            confirmedBy: (values.status == "confirmed" ? uid : "") as string,
            notConfirmedBy: (values.status == "notConfirmed" ? uid : "") as string,
            cacheAssignedTo: globalUsersData[values.assignedTo ?? ""]?.fullName,
            finalResoluation: values.finalResoluation ?? "",
            status: values.status as any,
            receivedDate:
              values.status == "received" ? (moment() as any) : caseData.receivedDate ? caseData.receivedDate : null,
            inProgressDate:
              values.status == "inProgress"
                ? (moment() as any)
                : caseData.inProgressDate
                ? caseData.inProgressDate
                : null,
            solvedDate:
              values.status == "solved" ? (moment() as any) : caseData.solvedDate ? caseData.solvedDate : null,
            confirmedDate:
              values.status == "confirmed" ? (moment() as any) : caseData.confirmedDate ? caseData.confirmedDate : null,
            notConfirmedDate:
              values.status == "notConfirmed"
                ? (moment() as any)
                : caseData.notConfirmedDate
                ? caseData.notConfirmedDate
                : null,
            suspendedDate:
              values.status == "suspended" ? (moment() as any) : caseData.suspendedDate ? caseData.suspendedDate : null,
          } as any)
        ).unwrap();
        message.success("Case updated successfully");
        setModalState(false);
        setLoading(false);
      } catch (error) {
        setLoading(false);
        console.error("Error writing document: ", error);
        message.error("Failed");
      }
    },
  });
  const [loaded, setLoaded] = useState(true);
  useEffect(() => {
    if (caseDoc && loaded) {
      formik.setValues({
        caseNO: caseData.caseNO,
        region: caseData.region,
        officeId: caseData.officeId,
        findings: caseData.findings,
        assignedTo: caseData.assignedTo,
        finalResoluation: caseData.finalResoluation,
        caseDate: moment(caseData.caseDate),
        status: caseData.status,
      } as any);
      setLoaded(false);
    }
  }, [caseDoc, formik, loaded]);

  const enterLoading = () => {
    setLoading(true);
  };
  const dateFormat = "YYYY/MM/DD";

  const formItemLayout = {
    labelCol: {
      xs: { span: 24 },
      sm: { span: 6 },
    },
    wrapperCol: {
      xs: { span: 24 },
      sm: { span: 16 },
    },
  };
  const tailFormItemLayout = {
    wrapperCol: {
      xs: {
        span: 24,
        offset: 0,
      },
      sm: {
        span: 16,
        offset: 8,
      },
    },
  };

  return (
    <Drawer
      title={caseDoc.id}
      width={700}
      height={350}
      visible={modalState}
      placement={"right"}
      onClose={() => setModalState(false)}
    >
      <Form onSubmit={formik.handleSubmit} {...formItemLayout}>
        <Form.Item label={t("general.caseDate")}>
          <DatePicker
            disabled={!currentUser.permissions.cases.includes("admin")}
            value={formik.values.caseDate}
            format={dateFormat}
            onChange={(e) => formik.setFieldValue("caseDate", e)}
          />
        </Form.Item>
        <Form.Item label={t("general.caseNO")}>
          <Input
            disabled={!currentUser.permissions.cases.includes("admin")}
            value={formik.values.caseNO}
            onChange={(e) => formik.setFieldValue("caseNO", e.target.value)}
          />
        </Form.Item>

        <Form.Item label={t("general.assignedTo")}>
          <Select
            filterOption={(inputValue, option) => {
              return (option.props.children as any).toLowerCase().indexOf(inputValue.toLowerCase()) >= 0;
            }}
            disabled={!currentUser.permissions.cases.includes("admin")}
            showSearch
            value={formik.values.assignedTo}
            onChange={(e) => {
              formik.setFieldValue("assignedTo", e);
            }}
          >
            {USERS_ID_FULL_NAME?.map((d) => {
              return <Option value={d.uid}>{d.fullName}</Option>;
            })}
          </Select>
        </Form.Item>

        <Form.Item label={t("general.office")}>
          <Select
            filterOption={(inputValue, option) => {
              return (option.props.children as any).toLowerCase().indexOf(inputValue.toLowerCase()) >= 0;
            }}
            size="default"
            showSearch
            value={formik.values.officeId}
            onChange={(e) => {
              formik.setFieldValue("officeId", e);
            }}
          >
            {ALL_OFFICES_ID_NAME.map((d) => {
              return <Option value={d.officeId}>{d.officeName}</Option>;
            })}
          </Select>
        </Form.Item>

        <Form.Item label={t("general.status")}>
          <Select
            disabled={!(currentUser.id == caseDoc.assignedTo || currentUser.permissions.cases.includes("admin"))}
            showSearch
            value={formik.values.status}
            onChange={(e) => {
              formik.setFieldValue("status", e);
            }}
          >
            <Option value={"received"}>{"Received"}</Option>
            <Option value={"inProgress"}>{"In Progress"}</Option>
            <Option value={"solved"}>{"Solved"}</Option>
            <Option value={"confirmed"}>{"Confirmed"}</Option>
            <Option value={"notConfirmed"}>{"Not Confirmed"}</Option>
            <Option value={"suspended"}>{"Suspended"}</Option>
          </Select>
        </Form.Item>

        <Form.Item label={t("general.findings")}>
          <TextArea
            disabled={!currentUser.permissions.cases.includes("admin")}
            value={formik.values.findings}
            onChange={(e) => formik.setFieldValue("findings", e.target.value)}
          />
        </Form.Item>

        <Form.Item label={t("general.region")}>
          <Input
            disabled={!currentUser.permissions.cases.includes("admin")}
            value={formik.values.region}
            onChange={(e) => formik.setFieldValue("region", e.target.value)}
          />
        </Form.Item>

        <Form.Item label={t("general.Resoluation")}>
          <TextArea
            disabled={!(currentUser.id == caseDoc.assignedTo || currentUser.permissions.cases.includes("admin"))}
            value={formik.values.finalResoluation}
            onChange={(e) => formik.setFieldValue("finalResoluation", e.target.value)}
          />
        </Form.Item>

        <div>
          <Form.Item {...tailFormItemLayout}>
            <Button
              className="btn blue lighten-1 z-depth-0"
              onClick={() => {
                enterLoading();
              }}
              loading={loading}
              type="primary"
              htmlType="submit"
              disabled={!formik.isValid}
            >
              {t("general.save")}
            </Button>
          </Form.Item>
        </div>
      </Form>

      <Divider />
      <Discussion userId={"noOne"} orderId={caseDoc?.id} type={"case"} officeId={caseDoc?.officeId} />
    </Drawer>
  );
}

export default EditCase;
