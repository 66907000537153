import React, { useState } from "react";
import { Modal } from "antd";
import { Case } from "../../db/interfaces";
import DevTable from "../../helpers/devex-table";
import EditCase from "./EditCase";

interface Props {
  casesData: Case[];
  barFilters: any;
  setBarFilters: any;
  columns: any[];
  columnsExport: any[];
  modalState: boolean;
  setModalState: any;
  startDate: any;
  endDate: any;
}

function TableBarDialog(props: Props) {
  const { barFilters, setBarFilters, columns, columnsExport, modalState, setModalState, casesData } = props;
  const [modalStateCase, setModalStateCase] = useState(false);
  const [caseDoc, setCaseDoc] = useState({} as Case);

  const data = casesData.filter((c) => {
    if (barFilters.assignedTo != "") {
      return c.cacheAssignedTo == barFilters.assignedTo && c.status == barFilters.status;
    } else if (barFilters.conductedBy != "") {
      return c.cacheConductedBy == barFilters.conductedBy && c.status == barFilters.status;
    } else {
      return c.cacheOffice == barFilters.office && c.status == barFilters.status;
    }
  });

  return (
    <Modal
      closable={true}
      width={1200}
      visible={modalState}
      onCancel={async () => {
        setModalState(false);

        setBarFilters({ status: "", assignedTo: "", office: "" });
      }}
    >
      <div>
        {modalStateCase ? (
          <EditCase modalState={modalStateCase} setModalState={setModalStateCase} caseDoc={caseDoc} />
        ) : null}
        <DevTable
          data={data}
          columns={columns}
          defaultHiddenColumnNames={[]}
          tableName="cases"
          selected={false}
          typicalTable={false}
          defaultColumnWidths={[]}
          columnsExport={columnsExport}
          rowClick={({ args, row }) => {
            setCaseDoc(row);
            setModalStateCase(true);
          }}
        />
      </div>
    </Modal>
  );
}

export default TableBarDialog;
