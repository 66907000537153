import { createSlice, createAsyncThunk, PayloadAction } from "@reduxjs/toolkit";
import { supabase } from "../../supabaseClient"; // Adjust the import path as needed
import { access } from "../../db/interfaces"; // Adjust the import path as needed

interface AccessSliceState {
  data: access[];
  status: "loading" | "succeeded" | "failed" | "idle";
  error: string | null;
}

const initialState: AccessSliceState = {
  data: [],
  status: "idle",
  error: null,
};

// Fetch all access records
export const fetchAllAccess = createAsyncThunk<access[], void, { rejectValue: string }>(
  "access/fetchAllAccess",
  async (_, thunkApi) => {
    const { data, error } = await supabase.from("access").select("*");
    if (error) {
      return thunkApi.rejectWithValue(error.message);
    }
    return data as access[];
  }
);

export const fetchAccessByOfficeAndOperationDate = createAsyncThunk<access[], any, { rejectValue: string }>(
  "access/fetchAccessByOfficeAndOperationDate",
  async ({ officeId, gt, ls }, thunkApi) => {
    const { data, error } = await supabase
      .from("access")
      .select("*")
      .eq("officeId", officeId)
      .gt("operationDate", gt)
      .lt("operationDate", ls);

    if (error) {
      return thunkApi.rejectWithValue(error.message);
    }

    return data as access[];
  }
);

export const fetchAccessByOperationDate = createAsyncThunk<access[], any, { rejectValue: string }>(
  "access/fetchAccessByOperationDate",
  async ({ gt, ls }, thunkApi) => {
    const { data, error } = await supabase.from("access").select("*").gt("operationDate", gt).lt("operationDate", ls);

    if (error) {
      return thunkApi.rejectWithValue(error.message);
    }

    return data as access[];
  }
);

export const fetchAccessByUserId = createAsyncThunk<access[], any, { rejectValue: string }>(
  "access/fetchAccessByUserId",
  async (userId, thunkApi) => {
    const { data, error } = await supabase.from("access").select("*").eq("uid", userId);

    if (error) {
      return thunkApi.rejectWithValue(error.message);
    }

    return data as access[];
  }
);

// Add a new access record
export const addAccess = createAsyncThunk<access, access, { rejectValue: string }>(
  "access/addAccess",
  async (newAccess, thunkApi) => {
    const { data: insertData, error: insertError } = await supabase.from("access").insert(newAccess).select().single();

    if (insertError) {
      return thunkApi.rejectWithValue(insertError.message);
    }

    return insertData as access;
  }
);

// Edit an access record
export const editAccess = createAsyncThunk<access, access, { rejectValue: string }>(
  "access/editAccess",
  async (updatedAccess, thunkApi) => {
    const { data: updateData, error: updateError } = await supabase
      .from("access")
      .update(updatedAccess)
      .eq("id", updatedAccess.id)
      .select()
      .single();

    if (updateError) {
      return thunkApi.rejectWithValue(updateError.message);
    }

    return updateData as access;
  }
);

// Delete an access record
export const deleteAccess = createAsyncThunk<string, string, { rejectValue: string }>(
  "access/deleteAccess",
  async (id, thunkApi) => {
    const { error } = await supabase.from("access").delete().eq("id", id);

    if (error) {
      return thunkApi.rejectWithValue(error.message);
    }

    return id;
  }
);

// Real-Time Actions
export const addAccessRealTime = (newAccess: access): PayloadAction<access> => ({
  type: "access/addAccessRealTime",
  payload: newAccess,
});

export const editAccessRealTime = (updatedAccess: access): PayloadAction<access> => ({
  type: "access/editAccessRealTime",
  payload: updatedAccess,
});

export const deleteAccessRealTime = (id: string): PayloadAction<string> => ({
  type: "access/deleteAccessRealTime",
  payload: id,
});

// Slice definition
const accessSlice = createSlice({
  name: "access",
  initialState,
  reducers: {
    addAccessRealTime(state, action: PayloadAction<access>) {
      const exists = state.data.some((access) => access.id === action.payload.id);
      if (!exists) {
        state.data.push(action.payload);
      }
      state.error = null;
    },
    editAccessRealTime(state, action: PayloadAction<access>) {
      const index = state.data.findIndex((access) => access.id === action.payload.id);
      if (index !== -1) {
        const isDifferent = JSON.stringify(state.data[index]) !== JSON.stringify(action.payload);
        if (isDifferent) {
          state.data[index] = action.payload;
        }
      }
      state.error = null;
    },
    deleteAccessRealTime(state, action: PayloadAction<string>) {
      const exists = state.data.some((access) => access.id === action.payload);
      if (exists) {
        state.data = state.data.filter((access) => access.id !== action.payload);
      }
      state.error = null;
    },
  },
  extraReducers: (builder) => {
    builder
      // Handle fetchAllAccess
      .addCase(fetchAllAccess.pending, (state) => {
        state.status = "loading";
      })
      .addCase(fetchAllAccess.fulfilled, (state, action: PayloadAction<access[]>) => {
        state.status = "succeeded";
        state.data = action.payload;
        state.error = null;
      })
      .addCase(fetchAllAccess.rejected, (state, action: PayloadAction<string | undefined>) => {
        state.status = "failed";
        state.error = action.payload || "Failed to fetch access.";
      })
      // Handle fetchAccessByOfficeAndOperationDate
      .addCase(fetchAccessByOfficeAndOperationDate.pending, (state) => {
        state.status = "loading";
      })
      .addCase(fetchAccessByOfficeAndOperationDate.fulfilled, (state, action: PayloadAction<access[]>) => {
        state.status = "succeeded";
        state.data = action.payload;
        state.error = null;
      })
      .addCase(fetchAccessByOfficeAndOperationDate.rejected, (state, action: PayloadAction<string | undefined>) => {
        state.status = "failed";
        state.error = action.payload || "Failed to fetch access by office.";
      })

      // Handle fetchAccessByOperationDate
      .addCase(fetchAccessByOperationDate.pending, (state) => {
        state.status = "loading";
      })
      .addCase(fetchAccessByOperationDate.fulfilled, (state, action: PayloadAction<access[]>) => {
        state.status = "succeeded";
        state.data = action.payload;
        state.error = null;
      })
      .addCase(fetchAccessByOperationDate.rejected, (state, action: PayloadAction<string | undefined>) => {
        state.status = "failed";
        state.error = action.payload || "Failed to fetch access by office.";
      })
      // Handle fetchAccessByUserId
      .addCase(fetchAccessByUserId.pending, (state) => {
        state.status = "loading";
      })
      .addCase(fetchAccessByUserId.fulfilled, (state, action: PayloadAction<access[]>) => {
        state.status = "succeeded";
        state.data = action.payload;
        state.error = null;
      })
      .addCase(fetchAccessByUserId.rejected, (state, action: PayloadAction<string | undefined>) => {
        state.status = "failed";
        state.error = action.payload || "Failed to fetch access by office.";
      })

      // Handle addAccess
      .addCase(addAccess.pending, (state) => {})
      .addCase(addAccess.fulfilled, (state, action: PayloadAction<access>) => {
        state.data.push(action.payload);
        state.error = null;
      })
      .addCase(addAccess.rejected, (state, action: PayloadAction<string | undefined>) => {
        state.error = action.payload || "Failed to add access.";
      })

      // Handle editAccess
      .addCase(editAccess.pending, (state) => {})
      .addCase(editAccess.fulfilled, (state, action: PayloadAction<access>) => {
        const index = state.data.findIndex((access) => access.id === action.payload.id);
        if (index !== -1) {
          state.data[index] = action.payload;
        }
        state.error = null;
      })
      .addCase(editAccess.rejected, (state, action: PayloadAction<string | undefined>) => {
        state.status = "failed";
        state.error = action.payload || "Failed to edit access.";
      })

      // Handle deleteAccess
      .addCase(deleteAccess.pending, (state) => {})
      .addCase(deleteAccess.fulfilled, (state, action: PayloadAction<string>) => {
        state.data = state.data.filter((access) => access.id !== action.payload);
        state.error = null;
      })
      .addCase(deleteAccess.rejected, (state, action: PayloadAction<string | undefined>) => {
        state.error = action.payload || "Failed to delete access.";
      });
  },
});

// Real-time subscription function
export const subscribeToAccess = (dispatch: any) => {
  const subscription = supabase
    .channel("public:access")
    .on("postgres_changes", { event: "*", schema: "public", table: "access" }, (payload) => {
      const newRecord = payload.new as access;
      const oldRecord = payload.old as access;
      switch (payload.eventType) {
        case "INSERT":
          dispatch(addAccessRealTime(newRecord));
          break;
        case "UPDATE":
          dispatch(editAccessRealTime(newRecord));
          break;
        case "DELETE":
          dispatch(deleteAccessRealTime(oldRecord.id ?? ""));
          break;
        default:
          break;
      }
    })
    .subscribe();

  return () => {
    subscription.unsubscribe();
  };
};

export default accessSlice.reducer;
