import { createSlice, createAsyncThunk, PayloadAction } from "@reduxjs/toolkit";
import { supabase } from "../../supabaseClient"; // Adjust the import path as needed
import { salary } from "../../db/interfaces"; // Adjust the import path as needed

interface SalarySliceState {
  salaries: salary[];
  data: salary[];
  status: "loading" | "succeeded" | "failed" | "idle";
  error: string | null;
}

const initialState: SalarySliceState = {
  salaries: [],
  data: [],
  status: "idle",
  error: null,
};

// Fetch all salary records
export const fetchAllSalaries = createAsyncThunk<salary[], void, { rejectValue: string }>(
  "salary/fetchAllSalaries",
  async (_, thunkApi) => {
    const { data, error } = await supabase.from("salaries").select();
    if (error) {
      return thunkApi.rejectWithValue(error.message);
    }
    return data as salary[];
  }
);

// Fetch salaries records for a specific office
export const fetchSalariesByOffice = createAsyncThunk<salary[], string, { rejectValue: string }>(
  "workOffice/fetchSalariesByOffice",
  async (officeId, thunkApi) => {
    const { data, error } = await supabase.from("salaries").select("*").eq("office", officeId);

    if (error) {
      return thunkApi.rejectWithValue(error.message);
    }

    return data as salary[];
  }
);

// Add a new salary record
export const addSalary = createAsyncThunk<salary, salary, { rejectValue: string }>(
  "salary/addSalary",
  async (newSalary, thunkApi) => {
    const { data: insertData, error: insertError } = await supabase
      .from("salaries")
      .insert(newSalary)
      .select()
      .single();

    if (insertError) {
      return thunkApi.rejectWithValue(insertError.message);
    }

    return insertData as salary;
  }
);

// Edit a salary record
export const editSalary = createAsyncThunk<salary, salary, { rejectValue: string }>(
  "salary/editSalary",
  async (updatedSalary, thunkApi) => {
    const { data: updateData, error: updateError } = await supabase
      .from("salaries")
      .update(updatedSalary)
      .eq("id", updatedSalary.id)
      .select()
      .single();

    if (updateError) {
      return thunkApi.rejectWithValue(updateError.message);
    }

    return updateData as salary;
  }
);

// Delete a salary record
export const deleteSalary = createAsyncThunk<string, string, { rejectValue: string }>(
  "salary/deleteSalary",
  async (id, thunkApi) => {
    const { error } = await supabase.from("salaries").delete().eq("id", id);

    if (error) {
      return thunkApi.rejectWithValue(error.message);
    }

    return id;
  }
);

// Real-Time Actions
export const addSalaryRealTime = (newSalary: salary): PayloadAction<salary> => ({
  type: "salary/addSalaryRealTime",
  payload: newSalary,
});

export const editSalaryRealTime = (updatedSalary: salary): PayloadAction<salary> => ({
  type: "salary/editSalaryRealTime",
  payload: updatedSalary,
});

export const deleteSalaryRealTime = (id: string): PayloadAction<string> => ({
  type: "salary/deleteSalaryRealTime",
  payload: id,
});

// Slice definition
const salarySlice = createSlice({
  name: "salary",
  initialState,
  reducers: {
    addSalaryRealTime(state, action: PayloadAction<salary>) {
      const exists = state.data.some((salary) => salary.id === action.payload.id);
      if (!exists) {
        state.data.push(action.payload);
      }
      state.error = null;
    },
    editSalaryRealTime(state, action: PayloadAction<salary>) {
      const index = state.data.findIndex((salary) => salary.id === action.payload.id);
      if (index !== -1) {
        const isDifferent = JSON.stringify(state.data[index]) !== JSON.stringify(action.payload);
        if (isDifferent) {
          state.data[index] = action.payload;
        }
      }
      state.error = null;
    },
    deleteSalaryRealTime(state, action: PayloadAction<string>) {
      const exists = state.data.some((salary) => salary.id === action.payload);
      if (exists) {
        state.data = state.data.filter((salary) => salary.id !== action.payload);
      }
      state.error = null;
    },
  },
  extraReducers: (builder) => {
    builder
      // Handle fetchAllSalaries
      .addCase(fetchAllSalaries.pending, (state) => {
        state.status = "loading";
      })
      .addCase(fetchAllSalaries.fulfilled, (state, action: PayloadAction<salary[]>) => {
        state.status = "succeeded";
        state.salaries = action.payload;
        state.error = null;
      })
      .addCase(fetchAllSalaries.rejected, (state, action: PayloadAction<string | undefined>) => {
        state.status = "failed";
        state.error = action.payload || "Failed to fetch salaries.";
      })
      // Handle fetchSalariesByOffice
      .addCase(fetchSalariesByOffice.pending, (state) => {
        state.status = "loading";
      })
      .addCase(fetchSalariesByOffice.fulfilled, (state, action: PayloadAction<salary[]>) => {
        state.status = "succeeded";
        state.data = action.payload;
        state.error = null;
      })
      .addCase(fetchSalariesByOffice.rejected, (state, action: PayloadAction<string | undefined>) => {
        state.status = "failed";
        state.error = action.payload || "Failed to fetch work salaries by office.";
      })

      // Handle addSalary
      .addCase(addSalary.pending, (state) => {})
      .addCase(addSalary.fulfilled, (state, action: PayloadAction<salary>) => {
        state.data.push(action.payload);
        state.error = null;
      })
      .addCase(addSalary.rejected, (state, action: PayloadAction<string | undefined>) => {
        state.error = action.payload || "Failed to add salary.";
      })

      // Handle editSalary
      .addCase(editSalary.pending, (state) => {})
      .addCase(editSalary.fulfilled, (state, action: PayloadAction<salary>) => {
        const index = state.data.findIndex((salary) => salary.id === action.payload.id);
        if (index !== -1) {
          state.data[index] = action.payload;
        }
        state.error = null;
      })
      .addCase(editSalary.rejected, (state, action: PayloadAction<string | undefined>) => {
        state.status = "failed";
        state.error = action.payload || "Failed to edit salary.";
      })

      // Handle deleteSalary
      .addCase(deleteSalary.pending, (state) => {})
      .addCase(deleteSalary.fulfilled, (state, action: PayloadAction<string>) => {
        state.data = state.data.filter((salary) => salary.id !== action.payload);
        state.error = null;
      })
      .addCase(deleteSalary.rejected, (state, action: PayloadAction<string | undefined>) => {
        state.error = action.payload || "Failed to delete salary.";
      });
  },
});

// Real-time subscription function
export const subscribeToSalaries = (dispatch: any) => {
  const subscription = supabase
    .channel("public:salaries")
    .on("postgres_changes", { event: "*", schema: "public", table: "salaries" }, (payload) => {
      const newRecord = payload.new as salary;
      const oldRecord = payload.old as salary;
      switch (payload.eventType) {
        case "INSERT":
          dispatch(addSalaryRealTime(newRecord));
          break;
        case "UPDATE":
          dispatch(editSalaryRealTime(newRecord));
          break;
        case "DELETE":
          dispatch(deleteSalaryRealTime(oldRecord.id ?? ""));
          break;
        default:
          break;
      }
    })
    .subscribe();

  return () => {
    subscription.unsubscribe();
  };
};

export default salarySlice.reducer;
