import React, { useState, useEffect } from "react";
import Paper from "@material-ui/core/Paper";
import { Spin, Button, Popconfirm, Select, message } from "antd";
const { Option } = Select;
import { useTranslation } from "react-i18next";
import moment from "moment";
import EditPosition from "./EditPosition";
import DevTable from "../../helpers/devex-table";
import { globalUsersData } from "../../global/usersList";
import { globalOfficesData, OFFICES_ID_NAME } from "../../global/officeList";
import { useAppDispatch, useAppSelector } from "../../hooks/reduxHooks";
import { deletePosition, fetchPositionsByOffice, subscribeToPositions } from "../../features/positions/positionsSlice";
import { setFilterValue } from "../../features/filters/filtersSlice";

export default function PositionsTable() {
  const { filterValues } = useAppSelector((state) => state.filters);
  const { data: positionsData, status, error } = useAppSelector((store) => store.positions);
  const { currentUser } = useAppSelector((state) => state.auth);
  const dispatch = useAppDispatch();
  const [officeId, setOfficeId] = useState(filterValues["officeId"] ?? "Select an office...");

  const { t } = useTranslation();
  const [modalStateEdit, setModalStateEdit] = useState(false);
  const [positionId, setPositionId] = useState("");
  const [position, setPosition] = useState({} as any);

  useEffect(() => {
    dispatch(fetchPositionsByOffice(officeId));
    const unsubscribe = subscribeToPositions(dispatch);
    return () => {
      unsubscribe();
    };
  }, [dispatch, officeId]);

  if (status == "loading") return <Spin />;

  const data = positionsData
    .map((d) => {
      return { ...d, id: d.id };
    })
    .filter((d: any) => currentUser.HRProjectsManagersOffices.includes(d.office));

  const columns = [
    {
      name: "uid",
      title: "UID",
    },
    {
      name: "name",
      title: "Name",
      getCellValue: (row: any) => globalUsersData[row.uid ?? ""]?.fullName,
    },
    {
      name: "office",
      title: "Office",
      getCellValue: (row: any) => globalOfficesData[row.office]?.officeName,
    },

    {
      name: "position",
      title: "Position",
    },

    {
      name: "startDate",
      title: "Start Date",
      getCellValue: (row: any) => moment(row.startDate).format("DD.MM.YYYY"),
    },

    {
      name: "actions",
      title: "Actions",
      getCellValue: (row: any) => {
        return (
          <Popconfirm
            title={t("general.areUSure")}
            onConfirm={() => {
              dispatch(deletePosition(row.id)).unwrap();
              message.success("Deleted Successfully!");
            }}
            onCancel={() => console.log("cancel")}
            okText="Yes"
            cancelText="No"
          >
            <Button type="link">Delete</Button>
          </Popconfirm>
        );
      },
    },
    {
      name: "edit",
      title: "Edit",
      getCellValue: (row: any) => {
        return (
          <Button
            onClick={() => {
              setModalStateEdit(true);
              setPositionId(row.id);
              setPosition(row);
            }}
            type="primary"
          >
            Edit
          </Button>
        );
      },
    },
  ];
  const columnsExport = [
    {
      name: "uid",
      title: "UID",
    },
    {
      name: "name",
      title: "Name",
      getCellValue: (row: any) => globalUsersData[row.uid ?? ""]?.fullName,
    },

    {
      name: "office",
      title: "Office",
      getCellValue: (row: any) => globalOfficesData[row.office ?? ""]?.officeName,
    },

    {
      name: "position",
      title: "Position",
    },
    {
      name: "startDate",
      title: "Start Date",
      getCellValue: (row: any) => moment(row.startDate).format("DD.MM.YYYY"),
    },
  ];

  return error ? (
    <p>{error}</p>
  ) : (
    <div>
      <Select
        filterOption={(inputValue, option) => {
          return (option.props.children as any).toLowerCase().indexOf(inputValue.toLowerCase()) >= 0;
        }}
        size="default"
        placeholder="Search for Office!"
        style={{ width: "25%", paddingBottom: "1%", paddingRight: "1%" }}
        showSearch
        value={officeId}
        onChange={(e) => {
          setOfficeId(e);
          dispatch(setFilterValue({ key: "officeId", value: e }));
        }}
      >
        {OFFICES_ID_NAME.map((d) => {
          return <Option value={d.officeId}>{d.officeName}</Option>;
        })}
      </Select>
      {modalStateEdit ? (
        <EditPosition
          modalState={modalStateEdit}
          setModalState={setModalStateEdit}
          positionId={positionId}
          position={position}
        />
      ) : null}{" "}
      <Paper>
        <DevTable
          data={data}
          columns={columns}
          defaultHiddenColumnNames={[]}
          tableName="Positions"
          selected={false}
          typicalTable={false}
          columnsExport={columnsExport}
        />
      </Paper>
    </div>
  );
}
