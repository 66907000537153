import React, { useState, useEffect } from "react";
import Paper from "@material-ui/core/Paper";
import { Spin, Button, Popconfirm, message } from "antd";
import { useTranslation } from "react-i18next";
import AddNewBudgetLine from "./AddNewBudgetLineModel";
import DevTable from "../../helpers/devex-table";
import { useAppDispatch, useAppSelector } from "../../hooks/reduxHooks";
import {
  deleteBudgetLine,
  fetchAllBudgetLines,
  subscribeToBudgetLines,
} from "../../features/budgetLines/budgetLinesSlice";
import { globalProjectsData } from "../../global/projectList";

const columnsExport = [
  { name: "budgetLine", title: "Budget Line" },
  { name: "projectCode", title: "Code" },
];

export default function BudgetLinesTable() {
  const { data: budgetLinesData, status, error } = useAppSelector((store) => store.budgetLines);
  const dispatch = useAppDispatch();
  const { t } = useTranslation();
  const [modalState, setModalState] = useState(false);

  useEffect(() => {
    dispatch(fetchAllBudgetLines());
    const unsubscribe = subscribeToBudgetLines(dispatch);
    return () => {
      unsubscribe();
    };
  }, [dispatch]);

  if (status == "loading") return <Spin />;

  const data = budgetLinesData.map((d) => {
    return { ...d, id: d.id };
  });

  const columns = [
    { name: "id", title: "ID" },

    { name: "budgetLine", title: "Budget Line" },
    {
      name: "projectId",
      title: "Project Code",
      getCellValue: (row) => {
        return globalProjectsData[row.projectId ?? ""]?.projectCode;
      },
    },

    {
      name: "actions",
      title: "Actions",
      getCellValue: (row: any) => {
        return (
          <Popconfirm
            title={t("general.areUSure")}
            onConfirm={() => {
              dispatch(deleteBudgetLine(row.id)).unwrap();
              message.success("Deleted Successfully!");
            }}
            onCancel={() => console.log("cancel")}
            okText="Yes"
            cancelText="No"
          >
            <Button type="link">Delete</Button>
          </Popconfirm>
        );
      },
    },
  ];

  return error ? (
    <p>{error}</p>
  ) : (
    <div>
      <AddNewBudgetLine modalState={modalState} setModalState={setModalState} />
      <Button style={{ marginBottom: "1%" }} type={"primary"} onClick={() => setModalState(true)}>
        {t("general.addNewBudgetLine")}
      </Button>
      <Paper>
        <DevTable
          data={data}
          columns={columns}
          defaultHiddenColumnNames={[]}
          tableName="BudgetLines"
          selected={false}
          typicalTable={false}
          columnsExport={columnsExport}
        />
      </Paper>
    </div>
  );
}
