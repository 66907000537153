import React, { useState, useEffect } from "react";
import { Button, DatePicker, Drawer, Form, message, Select, Input } from "antd";
import moment from "moment";
const { Option } = Select;
import { useFormik } from "formik";
import * as Yup from "yup";
import { useTranslation } from "react-i18next";
import { position } from "../../db/interfaces";
import { globalUsersData, USERS_ID_FULL_NAME } from "../../global/usersList";
import { useAppDispatch, useAppSelector } from "../../hooks/reduxHooks";
import { editPosition } from "../../features/positions/positionsSlice";

interface Props {
  position: position;
  positionId: string;
  modalState: boolean;
  setModalState: React.Dispatch<React.SetStateAction<boolean>>;
}
const projectSchema = Yup.object().shape({
  uid: Yup.string().required("*"),
  position: Yup.string().required("*"),
});

function EditPosition(props: Props) {
  const dispatch = useAppDispatch();
  const { currentUser } = useAppSelector((state) => state.auth);

  const { t } = useTranslation();
  const { modalState, setModalState, position } = props;
  const [loading, setLoading] = useState(false);

  const uid = currentUser.id;

  const positionData = position as position;

  const formik = useFormik({
    initialValues: {
      uid: "",
      position: "",
      startDate: moment(),
      endDate: moment(),
    },
    validationSchema: projectSchema,
    onSubmit: async (values) => {
      try {
        await dispatch(
          editPosition({
            id: position.id,
            uid: values.uid,
            office: globalUsersData[values.uid ?? ""]?.office,
            startDate: values.startDate as any,
            endDate: moment().add(1, "years") as any,
            by: uid,
            position: values.position,
            createdAt: moment() as any,
          })
        ).unwrap();
        message.success("Position updated successfully");
        setModalState(false);
        setLoading(false);
      } catch (error) {
        setLoading(false);
        console.error("Error writing document: ", error);
        message.error("Failed");
      }
    },
  });
  const [loaded, setLoaded] = useState(true);
  useEffect(() => {
    if (position && loaded) {
      formik.setValues({
        uid: positionData.uid,
        position: positionData.position,
        startDate: moment(positionData.startDate),
        endDate: moment(positionData.endDate),
      } as any);
      setLoaded(false);
    }
  }, [position, formik, loaded]);

  const enterLoading = () => {
    setLoading(true);
  };
  const dateFormat = "YYYY/MM/DD";

  return (
    <Drawer
      title={t("general.editNewPosition")}
      width={500}
      height={350}
      visible={modalState}
      placement={"right"}
      onClose={() => setModalState(false)}
    >
      <Form onSubmit={formik.handleSubmit}>
        <Form.Item label={t("general.employee")}>
          <Select
            filterOption={(inputValue, option) => {
              return (option.props.children as any).toLowerCase().indexOf(inputValue.toLowerCase()) >= 0;
            }}
            showSearch
            value={formik.values.uid}
            onChange={(e) => {
              formik.setFieldValue("uid", e);
            }}
          >
            {USERS_ID_FULL_NAME?.map((d) => {
              return <Option value={d.uid}>{d.fullName}</Option>;
            })}
          </Select>
        </Form.Item>
        <Form.Item label={t("general.position")}>
          <Input
            value={formik.values.position}
            onChange={(e) => {
              formik.setFieldValue("position", e.target.value);
            }}
          />
        </Form.Item>

        <Form.Item label={t("general.startDate")}>
          <DatePicker
            value={formik.values.startDate}
            format={dateFormat}
            onChange={(e) => formik.setFieldValue("startDate", e)}
          />{" "}
        </Form.Item>

        <div>
          <Button
            className="btn blue lighten-1 z-depth-0"
            onClick={() => {
              enterLoading();
            }}
            loading={loading}
            type="primary"
            htmlType="submit"
            disabled={!formik.isValid}
          >
            {t("general.save")}
          </Button>
        </div>
      </Form>
    </Drawer>
  );
}

export default EditPosition;
