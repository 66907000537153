import React from "react";
import "./style.css";
import ResetPasswordByUsername from "./ResetPasswordByUsername";

export default function ResetPassword() {

  return (
    <div className={"boxForm"}>
      <ResetPasswordByUsername />
    </div>
  );
}
