import React, { useState, useEffect } from "react";
import { useHistory } from "react-router-dom";
import { Button, DatePicker, Drawer, Form, InputNumber, message, Input, Descriptions, TimePicker } from "antd";
const { TextArea } = Input;
import moment from "moment";
import { useFormik } from "formik";
import * as Yup from "yup";
import { useTranslation } from "react-i18next";
import { useAppDispatch, useAppSelector } from "../../../hooks/reduxHooks";
import { addOvertime } from "../../../features/overtime/overtimeSlice";

interface Props {
  modalState: boolean;
  setModalState: React.Dispatch<React.SetStateAction<boolean>>;
}
const projectSchema = Yup.object().shape({
  overtimeDate: Yup.date().required("*"),
  compensationHours: Yup.number().min(1).max(9).required("*"),
});

function AddNewOvertime(props: Props) {
  const { currentUser } = useAppSelector((state) => state.auth);
  const dispatch = useAppDispatch();

  const { t } = useTranslation();
  const { modalState, setModalState } = props;
  const [loading, setLoading] = useState(false);
  const history = useHistory();
  const uid = currentUser?.id as string;

  const { position, managerUID, office } = currentUser;
  const userData = currentUser;
  const formik = useFormik({
    initialValues: {
      compensationHours: 1,
      overtimeDate: moment(),
      tasks: "",
      overtimeEndHours: moment(),
      overtimeStartHours: moment(),
      taskOvertime: moment(),
    },
    validationSchema: projectSchema,
    onSubmit: async (values, form) => {
      try {
        dispatch(
          addOvertime({
            employeeUID: uid,
            position: position ?? userData.position ?? "",
            managerUID: managerUID,
            employeeSignatureDate: moment() as any,
            managerSignatureDate: "",
            adminSignatureDate: "",
            HRSignatureDate: "",
            CEOSignatureDate: "",
            office: office,
            status: "Requested",
            requestedAt: moment() as any,
            tasks: values.tasks,
            overtimeDate: values.overtimeDate as any,
            overtimeStartHours: values.overtimeStartHours as any,
            overtimeEndHours: values.overtimeEndHours as any,
            taskOvertime: values.taskOvertime as any,
            compensationHours: values.compensationHours,
            overtimeAccepted: 0,
            adminUID: "",
            HRUserUID: "",
            CEOUID: "",
            canceledBy: "",
            rejectedBy: "",
            RFCURL: "",
            archivedRFC: "",
          })
        )
          .then((res: any) => {
            message.success("Your request has been submitted successfully");
            console.log("Document successfully written!");
            setModalState(false);
            setLoading(false);
            history.push(`/overtime/${res.payload.id}`);
            setLoading(false);
          })
          .then(() => form.resetForm());
      } catch (error) {
        console.error("Error writing document: ", error);
        message.error("Failed");
      }
    },
  });

  useEffect(() => {
    return () => {
      if (formik.isValid == false) {
        setLoading(false);
      }
    };
  }, [formik.isValid]);
  const enterLoading = () => {
    setLoading(true);
  };
  const dateFormat = "YYYY/MM/DD";
  const format = "hh:mm a";

  return (
    <Drawer title="Compensation Request" width={600} visible={modalState} onClose={() => setModalState(false)}>
      <Form onSubmit={formik.handleSubmit}>
        <div>
          <div>
            <Descriptions
              size={"default"}
              layout="horizontal"
              bordered
              column={{ xxl: 6, xl: 4, lg: 3, md: 3, sm: 2, xs: 1 }}
            >
              {/* --Over Time Request --- */}

              <Descriptions.Item label={t("general.overtimeDate")} span={6}>
                <DatePicker
                  value={formik.values.overtimeDate}
                  format={dateFormat}
                  onChange={(e) => formik.setFieldValue("overtimeDate", e)}
                />{" "}
              </Descriptions.Item>

              <Descriptions.Item label={t("general.overtimeStartHours")} span={6}>
                <TimePicker
                  use12Hours
                  format={format}
                  value={formik.values.overtimeStartHours}
                  onChange={(e) => formik.setFieldValue("overtimeStartHours", e)}
                />
              </Descriptions.Item>

              <Descriptions.Item label={t("general.overtimeEndHours")} span={6}>
                <TimePicker
                  use12Hours
                  value={formik.values.overtimeEndHours}
                  onChange={(e) => formik.setFieldValue("overtimeEndHours", e)}
                  format={format}
                />
              </Descriptions.Item>

              <Descriptions.Item label={t("general.compensationHours")} span={6}>
                <InputNumber
                  max={20}
                  min={0}
                  value={formik.values.compensationHours}
                  onChange={(e) => formik.setFieldValue("compensationHours", e)}
                />
              </Descriptions.Item>
              <Descriptions.Item label={t("general.taskOvertime")} span={6}>
                <DatePicker
                  value={formik.values.taskOvertime}
                  format={dateFormat}
                  onChange={(e) => formik.setFieldValue("taskOvertime", e)}
                />{" "}
              </Descriptions.Item>
              <Descriptions.Item label={t("general.tasks")} span={6}>
                <TextArea
                  value={formik.values.tasks}
                  autoSize={{ minRows: 8, maxRows: 20 }}
                  onChange={(e) => formik.setFieldValue("tasks", e.target.value)}
                />
              </Descriptions.Item>
            </Descriptions>
          </div>
        </div>
        <div>
          <Button
            style={{ margin: "1%" }}
            className="btn blue lighten-1 z-depth-0"
            onClick={() => {
              enterLoading();
            }}
            loading={loading}
            type="primary"
            htmlType="submit"
            disabled={!formik.isValid}
          >
            {t("general.create")}
          </Button>
        </div>

        {/* { this.state.loading ? <Spin/>: null} */}
      </Form>
    </Drawer>
  );
}

export default AddNewOvertime;
