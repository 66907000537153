import { globalUser } from "../db/interfaces";

export let currentUserData = {} as globalUser;

import { fetchAllContractPercentages } from "../features/contractPercentage/contractPercentageSlice";
import { fetchAllPaymentTypes } from "../features/paymentTypes/paymentTypesSlice";
import { fetchAllPositions } from "../features/positions/positionsSlice";
import { fetchAllSalaries } from "../features/salaries/salariesSlice";
import { fetchAllSupervisors } from "../features/supervisors/supervisorsSlice";
import { fetchAllWorkOffices } from "../features/workOffice/workOfficeSlice";
import { RootState, store } from "../store";

const initializeGlobalData = async () => {
  const state: RootState = store.getState();
  const { loaded } = state.users;

  // Only dispatch fetch if the data hasn't been loaded yet
  if (!loaded) {
    await store.dispatch(fetchAllPositions());
    await store.dispatch(fetchAllSalaries());
    await store.dispatch(fetchAllWorkOffices());
    await store.dispatch(fetchAllSupervisors());
    await store.dispatch(fetchAllContractPercentages());
    await store.dispatch(fetchAllPaymentTypes());
  }
};

initializeGlobalData();

export const getPositions = () => store.getState().positions.positions;
export const getSalaries = () => store.getState().salaries.salaries;
export const getOffices = () => store.getState().workOffices.workOffices;
export const getSupervisors = () => store.getState().supervisors.supervisors;
export const getPaymentTypes = () => store.getState().paymentTypes.paymentTypes;
export const getContractPercentages = () => store.getState().contractPercentage.contractPercentages;
