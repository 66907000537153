import moment, { Moment } from "moment";
import { getContractPercentages } from "./userGlobal";
import _ from "lodash";

export const currentContractPercentage = (userUID: string, date: Moment) => {
  const contractPercentages = getContractPercentages();
  // Filter the logs by the userUID using Lodash's _.filter
  const userLogs = _.filter(contractPercentages, { uid: userUID });

  // Sort the logs by the startDate using Lodash's _.sortBy
  const sortedLogs = _.sortBy(userLogs, (log) => log.startDate.valueOf());

  // Find the log that matches the targetDate using Lodash's _.findLast
  const contractPercentageLog = _.findLast(sortedLogs, (log) =>
    date.isSameOrAfter(moment(log.startDate).startOf("day"))
  );

  return contractPercentageLog ? contractPercentageLog?.contractPercentage : undefined;
};
