import { createSlice, createAsyncThunk, PayloadAction } from "@reduxjs/toolkit";
import { supabase } from "../../supabaseClient"; // Adjust the import path as needed
import { supervisor } from "../../db/interfaces"; // Adjust the import path as needed

interface SupervisorSliceState {
  supervisors: supervisor[];
  data: supervisor[];
  status: "loading" | "succeeded" | "failed" | "idle";
  error: string | null;
}

const initialState: SupervisorSliceState = {
  supervisors: [],
  data: [],
  status: "idle",
  error: null,
};

// Fetch all supervisor records
export const fetchAllSupervisors = createAsyncThunk<supervisor[], void, { rejectValue: string }>(
  "supervisor/fetchAllSupervisors",
  async (_, thunkApi) => {
    const { data, error } = await supabase.from("supervisors").select("*");
    if (error) {
      return thunkApi.rejectWithValue(error.message);
    }
    return data as supervisor[];
  }
);

export const fetchSupervisorsByOffice = createAsyncThunk<supervisor[], string, { rejectValue: string }>(
  "workOffice/fetchSupervisorsByOffice",
  async (officeId, thunkApi) => {
    const { data, error } = await supabase.from("supervisors").select("*").eq("office", officeId);

    if (error) {
      return thunkApi.rejectWithValue(error.message);
    }

    return data as supervisor[];
  }
);

// Add a new supervisor record
export const addSupervisor = createAsyncThunk<supervisor, supervisor, { rejectValue: string }>(
  "supervisor/addSupervisor",
  async (newSupervisor, thunkApi) => {
    const { data: insertData, error: insertError } = await supabase
      .from("supervisors")
      .insert(newSupervisor)
      .select()
      .single();

    if (insertError) {
      return thunkApi.rejectWithValue(insertError.message);
    }

    return insertData as supervisor;
  }
);

// Edit a supervisor record
export const editSupervisor = createAsyncThunk<supervisor, supervisor, { rejectValue: string }>(
  "supervisor/editSupervisor",
  async (updatedSupervisor, thunkApi) => {
    const { data: updateData, error: updateError } = await supabase
      .from("supervisors")
      .update(updatedSupervisor)
      .eq("id", updatedSupervisor.id)
      .select()
      .single();

    if (updateError) {
      return thunkApi.rejectWithValue(updateError.message);
    }

    return updateData as supervisor;
  }
);

// Delete a supervisor record
export const deleteSupervisor = createAsyncThunk<string, string, { rejectValue: string }>(
  "supervisor/deleteSupervisor",
  async (id, thunkApi) => {
    const { error } = await supabase.from("supervisors").delete().eq("id", id);

    if (error) {
      return thunkApi.rejectWithValue(error.message);
    }

    return id;
  }
);

// Real-Time Actions
export const addSupervisorRealTime = (newSupervisor: supervisor): PayloadAction<supervisor> => ({
  type: "supervisor/addSupervisorRealTime",
  payload: newSupervisor,
});

export const editSupervisorRealTime = (updatedSupervisor: supervisor): PayloadAction<supervisor> => ({
  type: "supervisor/editSupervisorRealTime",
  payload: updatedSupervisor,
});

export const deleteSupervisorRealTime = (id: string): PayloadAction<string> => ({
  type: "supervisor/deleteSupervisorRealTime",
  payload: id,
});

// Slice definition
const supervisorSlice = createSlice({
  name: "supervisor",
  initialState,
  reducers: {
    addSupervisorRealTime(state, action: PayloadAction<supervisor>) {
      const exists = state.data.some((supervisor) => supervisor.id === action.payload.id);
      if (!exists) {
        state.data.push(action.payload);
      }
      state.error = null;
    },
    editSupervisorRealTime(state, action: PayloadAction<supervisor>) {
      const index = state.data.findIndex((supervisor) => supervisor.id === action.payload.id);
      if (index !== -1) {
        const isDifferent = JSON.stringify(state.data[index]) !== JSON.stringify(action.payload);
        if (isDifferent) {
          state.data[index] = action.payload;
        }
      }
      state.error = null;
    },
    deleteSupervisorRealTime(state, action: PayloadAction<string>) {
      const exists = state.data.some((supervisor) => supervisor.id === action.payload);
      if (exists) {
        state.data = state.data.filter((supervisor) => supervisor.id !== action.payload);
      }
      state.error = null;
    },
  },
  extraReducers: (builder) => {
    builder
      // Handle fetchAllSupervisors
      .addCase(fetchAllSupervisors.pending, (state) => {
        state.status = "loading";
      })
      .addCase(fetchAllSupervisors.fulfilled, (state, action: PayloadAction<supervisor[]>) => {
        state.status = "succeeded";
        state.supervisors = action.payload;
        state.error = null;
      })
      .addCase(fetchAllSupervisors.rejected, (state, action: PayloadAction<string | undefined>) => {
        state.status = "failed";
        state.error = action.payload || "Failed to fetch supervisors.";
      })
      // Handle fetchSupervisorsByOffice
      .addCase(fetchSupervisorsByOffice.pending, (state) => {
        state.status = "loading";
      })
      .addCase(fetchSupervisorsByOffice.fulfilled, (state, action: PayloadAction<supervisor[]>) => {
        state.status = "succeeded";
        state.data = action.payload;
        state.error = null;
      })
      .addCase(fetchSupervisorsByOffice.rejected, (state, action: PayloadAction<string | undefined>) => {
        state.status = "failed";
        state.error = action.payload || "Failed to fetch work offices by office.";
      })

      // Handle addSupervisor
      .addCase(addSupervisor.pending, (state) => {})
      .addCase(addSupervisor.fulfilled, (state, action: PayloadAction<supervisor>) => {
        state.data.push(action.payload);
        state.error = null;
      })
      .addCase(addSupervisor.rejected, (state, action: PayloadAction<string | undefined>) => {
        state.error = action.payload || "Failed to add supervisor.";
      })

      // Handle editSupervisor
      .addCase(editSupervisor.pending, (state) => {})
      .addCase(editSupervisor.fulfilled, (state, action: PayloadAction<supervisor>) => {
        const index = state.data.findIndex((supervisor) => supervisor.id === action.payload.id);
        if (index !== -1) {
          state.data[index] = action.payload;
        }
        state.error = null;
      })
      .addCase(editSupervisor.rejected, (state, action: PayloadAction<string | undefined>) => {
        state.status = "failed";
        state.error = action.payload || "Failed to edit supervisor.";
      })

      // Handle deleteSupervisor
      .addCase(deleteSupervisor.pending, (state) => {})
      .addCase(deleteSupervisor.fulfilled, (state, action: PayloadAction<string>) => {
        state.data = state.data.filter((supervisor) => supervisor.id !== action.payload);
        state.error = null;
      })
      .addCase(deleteSupervisor.rejected, (state, action: PayloadAction<string | undefined>) => {
        state.error = action.payload || "Failed to delete supervisor.";
      });
  },
});

// Real-time subscription function
export const subscribeToSupervisors = (dispatch: any) => {
  const subscription = supabase
    .channel("public:supervisors")
    .on("postgres_changes", { event: "*", schema: "public", table: "supervisors" }, (payload) => {
      const newRecord = payload.new as supervisor;
      const oldRecord = payload.old as supervisor;
      switch (payload.eventType) {
        case "INSERT":
          dispatch(addSupervisorRealTime(newRecord));
          break;
        case "UPDATE":
          dispatch(editSupervisorRealTime(newRecord));
          break;
        case "DELETE":
          dispatch(deleteSupervisorRealTime(oldRecord.id ?? ""));
          break;
        default:
          break;
      }
    })
    .subscribe();

  return () => {
    subscription.unsubscribe();
  };
};

export default supervisorSlice.reducer;
