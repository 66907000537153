import React from "react";
import DepartmentCard from "./DepartmentCard";
import { department } from "../../db/interfaces";

interface Props {
  departments: department[];
}
export default function MyDepartmentsList(props: Props) {
  return (
    <div>
      {true &&
        props.departments?.map((department) => {
          return <DepartmentCard departmentId={department.id as any} departmentName={department.departmentName} />;
        })}
    </div>
  );
}
