import React, { useEffect, useState } from "react";
import Paper from "@material-ui/core/Paper";
import { Spin, Button, Popconfirm, message } from "antd";
import { useTranslation } from "react-i18next";
import DevTable from "../../helpers/devex-table";
import { globalOfficesData } from "../../global/officeList";
import { globalUsersData } from "../../global/usersList";
import AddNewAccountant from "./addNewAccountantDialog";
import {
  deleteAccountant,
  fetchAllAccountants,
  subscribeToAccountants,
} from "../../features/accountants/accountantsSlice";
import { useAppDispatch, useAppSelector } from "../../hooks/reduxHooks";

export default function AccountantsTable() {
  const { data: accountantsData, status, error } = useAppSelector((store) => store.accountants);
  const dispatch = useAppDispatch();
  const { t } = useTranslation();
  const [modalState, setModalState] = useState(false);

  useEffect(() => {
    dispatch(fetchAllAccountants());
    const unsubscribe = subscribeToAccountants(dispatch);
    return () => {
      unsubscribe();
    };
  }, [dispatch]);

  if (status == "loading") return <Spin />;

  const data = accountantsData.map((d) => {
    return { ...d, id: d.id };
  });

  const columns = [
    {
      name: "uid",
      title: "Name",
      getCellValue: (row: any) => globalUsersData[row.uid ?? ""]?.fullName,
    },

    {
      name: "office",
      title: "Office",
      getCellValue: (row: any) => globalOfficesData[row.office ?? ""]?.officeName,
    },
    {
      name: "actions",
      title: "Actions",
      getCellValue: (row: any) => {
        return (
          <Popconfirm
            title={t("general.areUSure")}
            onConfirm={() => {
              dispatch(deleteAccountant(row.id)).unwrap();
              message.success("Deleted Successfully!");
            }}
            onCancel={() => console.log("cancel")}
            okText="Yes"
            cancelText="No"
          >
            <Button type="link">Delete</Button>
          </Popconfirm>
        );
      },
    },
  ];
  const columnsExport = [
    {
      name: "uid",
      title: "Name",
      getCellValue: (row: any) => globalUsersData[row.uid ?? ""]?.fullName,
    },

    {
      name: "office",
      title: "Office",
      getCellValue: (row: any) => globalOfficesData[row.office ?? ""]?.officeName,
    },
  ];

  return error ? (
    <p>{error}</p>
  ) : (
    <div>
      <AddNewAccountant modalState={modalState} setModalState={setModalState} />

      <Button style={{ marginBottom: "1%" }} type={"primary"} onClick={() => setModalState(true)}>
        {t("general.addNewAccountant")}
      </Button>
      <Paper>
        <DevTable
          data={data}
          columns={columns}
          defaultHiddenColumnNames={[]}
          tableName="accountants"
          selected={false}
          typicalTable={false}
          columnsExport={columnsExport}
        />
      </Paper>
    </div>
  );
}
