import { message } from "antd"; // Assuming you use Ant Design for messages
import { supabase } from "../../supabaseClient";
import moment, * as Moment from "moment";
import { extendMoment } from "moment-range";
const moments = extendMoment(Moment);

/**
 * Function to add or update calendar events
 * @param yesterday - The date representing yesterday
 * @param lastDateQuery - The date up to which the query is made
 * @param roomId - The room ID
 * @param ruleDatesArray - Array of date objects for recurring rules
 * @param title - The title of the calendar event
 * @param rRule - The recurrence rule
 * @param members - Array of members involved
 * @param allDay - Boolean indicating if the event lasts all day
 * @param agenda - Agenda for the event
 * @param agendaObj - Object containing agenda details
 * @param uid - User ID
 * @param exDate - Excluded dates for recurrence
 * @param agendaAttach - Attachments for the agenda
 * @param agendaFileNames - Names of the agenda files
 * @param isOnlyOneAppointment - Boolean for appointment status
 * @param setSpinning - Function to handle loading spinner
 */
export const addOrUpdateCalendarEvents = async (
  yesterday: Date,
  lastDateQuery: Date,
  roomId: string,
  ruleDatesArray: any[],
  title: string,
  rRule: string,
  members: string[],
  allDay: boolean,
  agenda: string,
  agendaObj: any,
  MOM: string,
  MOMObj: any,
  uid: string,
  exDate: any,
  agendaAttach: any,
  agendaFileNames: string[],
  isOnlyOneAppointment: boolean,
  setSpinning: (loading: boolean) => void
) => {
  let result = { added: false, docId: null };
  try {
    // Query to check for overlapping events
    const { data, error } = await supabase
      .from("calendar")
      .select("*")
      .gt("startDate", moment(yesterday).format("YYYY-MM-DD"))
      .lt("startDate", moment(lastDateQuery).format("YYYY-MM-DD"))
      .eq("roomId", roomId);

    if (error) throw error;

    if (data && data.length === 0) {
      // No overlapping events, proceed to insert
      const originalId = crypto.randomUUID(); // Generating unique ID (replace with your method if needed)

      ruleDatesArray.forEach(async (obj, index) => {
        const id = index === 0 ? originalId : crypto.randomUUID();
        const eventData = {
          id,
          startDate: obj.startDate,
          endDate: obj.endDate,
          createdAt: moment() as any,
          title,
          rRule,
          members,
          allDay,
          agenda,
          agendaObj,
          MOM,
          MOMObj,
          roomId: roomId === "" ? "3Vcicn7y8M5I996bYwSS" : roomId,
          uid,
          exDate,
          agendaAttach,
          agendaFileNames,
          isOriginal: index === 0,
          originalId,
          MOMStatus: false,
          MOMWriter: "",
          isOnlyOneAppointment,
          sendMOM: false,
        };

        // Insert event into Supabase
        const { error: insertError } = await supabase.from("calendar").insert(eventData);
        if (insertError) throw insertError;
      });

      message.success("Added Successfully!");
      
      result = { added: true, docId: originalId as any };
    } else {
      // Check for overlapping events
      let overlap = true;

      data.forEach((e) => {
        const oldStartDate: Date = new Date(e.startDate);
        const oldEndDate: Date = new Date(e.endDate);

        ruleDatesArray.forEach((newObj: any) => {
          const newStartDate: Date = newObj.startDate;
          const newEndDate: Date = newObj.endDate;

          // Check for overlap using moment.js
          const rangeOld = moments.range(oldStartDate, oldEndDate);
          const rangeNew = moments.range(newStartDate, newEndDate);
          if (rangeOld.overlaps(rangeNew)) {
            overlap = false;
            console.log("Overlaps found:", { oldStartDate, oldEndDate, newStartDate, newEndDate });
            result = { added: false, docId: null };
          }
        });
      });
      //   for (let e of data) {

      //   }

      if (overlap) {
        // No overlap, proceed to insert events
        setSpinning(true);
        let saveCount = 0;
        const originalId = crypto.randomUUID();

        ruleDatesArray.forEach(async (obj, index) => {
          const id = index === 0 ? originalId : crypto.randomUUID();

          const eventData = {
            id,
            startDate: obj.startDate,
            endDate: obj.endDate,
            createdAt: moment() as any,
            title,
            rRule,
            members,
            allDay,
            agenda,
            agendaObj,
            MOM,
            MOMObj,
            roomId: roomId === "" ? "3Vcicn7y8M5I996bYwSS" : roomId,
            uid,
            exDate,
            isOriginal: index === 0,
            originalId,
            MOMStatus: false,
            MOMWriter: "",
            isOnlyOneAppointment,
            agendaAttach,
            agendaFileNames,
            sendMOM: false,
          };

          // Insert each event
          const { error: insertError } = await supabase.from("calendar").insert(eventData);
          if (insertError) throw insertError;
          saveCount += 1;
        });

        if (saveCount === ruleDatesArray.length) {
          setSpinning(false);
          message.success("Added Successfully!");
        }
        result = { added: true, docId: originalId as any };
      } else {
        alert("Overlaps detected! This time slot is already reserved.");
        result = { added: false, docId: null };
      }
    }
  } catch (err) {
    console.error("Error processing calendar events:", err);
    message.error("An error occurred while adding events.");
    result = { added: false, docId: null };
  }
  return result;
};
