import { fetchMissionsInBatches } from "../features/missions/missionsSlice";
import { RootState, store } from "../store";

const initializeMissionsData = async () => {
  const state: RootState = store.getState();
  const { loaded } = state.missions;

  // Only dispatch fetch if the data hasn't been loaded yet
  if (!loaded) {
    await store.dispatch(fetchMissionsInBatches());
  }
};

initializeMissionsData();

export const getMissionsData = () => {
  const state: RootState = store.getState();
  const { globalMissionsData, loaded } = state.missions;
  // if (!loaded) {
  //   console.warn("Projects data is not loaded yet.");
  // }

  return {
    globalMissionsData,
  };
};

// Initial export values, populated from the Redux store
export let globalMissionsData = getMissionsData().globalMissionsData;

// Subscribe to store updates and dynamically update exports when the Redux state changes
store.subscribe(() => {
  const { globalMissionsData: updatedGlobalMissionsData } = getMissionsData();

  // Update the exports with the latest values from the Redux store
  globalMissionsData = updatedGlobalMissionsData;
});
