import { createSlice, createAsyncThunk, PayloadAction } from "@reduxjs/toolkit";
import { supabase } from "../../supabaseClient"; // Adjust the import path as needed
import { sheet } from "../../db/interfaces"; // Adjust the import path as needed

interface SheetSliceState {
  data: sheet[];
  sheet: sheet;
  status: "loading" | "succeeded" | "failed" | "idle";
  error: string | null;
}

const initialState: SheetSliceState = {
  data: [],
  sheet: null as any,
  status: "idle",
  error: null,
};

// Fetch all sheets records
export const fetchAllSheets = createAsyncThunk<sheet[], void, { rejectValue: string }>(
  "sheet/fetchAllSheets",
  async (_, thunkApi) => {
    const { data, error } = await supabase.from("sheets").select("*");
    if (error) {
      return thunkApi.rejectWithValue(error.message);
    }
    return data as sheet[];
  }
);

// Fetch sheets by uid
export const fetchSheetsByUid = createAsyncThunk<sheet, string, { rejectValue: string }>(
  "sheet/fetchSheetsByUid",
  async (uid, thunkApi) => {
    const { data, error } = await supabase.from("sheets").select("*").eq("id", uid).single();
    if (error) {
      return thunkApi.rejectWithValue(error.message);
    }
    return data as sheet;
  }
);

// Add a new sheet record
export const addSheet = createAsyncThunk<sheet, sheet, { rejectValue: string }>(
  "sheet/addSheet",
  async (newSheet, thunkApi) => {
    const { data: insertData, error: insertError } = await supabase.from("sheets").insert(newSheet).select().single();

    if (insertError) {
      return thunkApi.rejectWithValue(insertError.message);
    }

    return insertData as sheet;
  }
);

// Edit a sheet record
export const editSheet = createAsyncThunk<sheet, sheet, { rejectValue: string }>(
  "sheet/editSheet",
  async (updatedSheet, thunkApi) => {
    const { data: updateData, error: updateError } = await supabase
      .from("sheets")
      .update(updatedSheet)
      .eq("id", updatedSheet.id)
      .select()
      .single();

    if (updateError) {
      return thunkApi.rejectWithValue(updateError.message);
    }

    return updateData as sheet;
  }
);

// Delete a sheet record
export const deleteSheet = createAsyncThunk<string, string, { rejectValue: string }>(
  "sheet/deleteSheet",
  async (id, thunkApi) => {
    const { error } = await supabase.from("sheets").delete().eq("id", id);

    if (error) {
      return thunkApi.rejectWithValue(error.message);
    }

    return id;
  }
);

// Real-Time Actions
export const addSheetRealTime = (newSheet: sheet): PayloadAction<sheet> => ({
  type: "sheet/addSheetRealTime",
  payload: newSheet,
});

export const editSheetRealTime = (updatedSheet: sheet): PayloadAction<sheet> => ({
  type: "sheet/editSheetRealTime",
  payload: updatedSheet,
});

export const deleteSheetRealTime = (id: string): PayloadAction<string> => ({
  type: "sheet/deleteSheetRealTime",
  payload: id,
});

// Slice definition
const sheetSlice = createSlice({
  name: "sheet",
  initialState,
  reducers: {
    addSheetRealTime(state, action: PayloadAction<sheet>) {
      const exists = state.data.some((sheet) => sheet.id === action.payload.id);
      if (!exists) {
        state.data = [...state.data, action.payload];
      }
      state.error = null;
    },
    editSheetRealTime(state, action: PayloadAction<sheet>) {
      const index = state.data.findIndex((sheet) => sheet.id === action.payload.id);
      if (index !== -1) {
        const isDifferent = JSON.stringify(state.data[index]) !== JSON.stringify(action.payload);
        if (isDifferent) {
          state.data[index] = action.payload;
        }
      }
      state.error = null;

      if (state.sheet.id == action.payload.id) {
        state.sheet = action.payload;
      }
    },
    deleteSheetRealTime(state, action: PayloadAction<string>) {
      const exists = state.data.some((sheet) => sheet.id === action.payload);
      if (exists) {
        state.data = state.data.filter((sheet) => sheet.id !== action.payload);
      }
      state.error = null;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchAllSheets.pending, (state) => {
        state.status = "loading";
      })
      .addCase(fetchAllSheets.fulfilled, (state, action: PayloadAction<sheet[]>) => {
        state.status = "succeeded";
        state.data = action.payload;
        state.error = null;
      })
      .addCase(fetchAllSheets.rejected, (state, action: PayloadAction<string | undefined>) => {
        state.status = "failed";
        state.error = action.payload || "Failed to fetch sheets.";
      })
      .addCase(fetchSheetsByUid.pending, (state) => {
        state.status = "loading";
      })
      .addCase(fetchSheetsByUid.fulfilled, (state, action: PayloadAction<sheet>) => {
        state.status = "succeeded";
        state.sheet = action.payload;
        state.error = null;
      })
      .addCase(fetchSheetsByUid.rejected, (state, action: PayloadAction<string | undefined>) => {
        state.status = "failed";
        state.error = action.payload || "Failed to fetch sheet.";
      })
      .addCase(addSheet.pending, (state) => {})
      .addCase(addSheet.fulfilled, (state, action: PayloadAction<sheet>) => {
        state.error = null;
      })
      .addCase(addSheet.rejected, (state, action: PayloadAction<string | undefined>) => {
        state.error = action.payload || "Failed to add sheet.";
      })
      .addCase(editSheet.pending, (state) => {})
      .addCase(editSheet.fulfilled, (state, action: PayloadAction<sheet>) => {
        state.error = null;
      })
      .addCase(editSheet.rejected, (state, action: PayloadAction<string | undefined>) => {
        state.status = "failed";
        state.error = action.payload || "Failed to edit sheet.";
      })
      .addCase(deleteSheet.pending, (state) => {})
      .addCase(deleteSheet.fulfilled, (state, action: PayloadAction<string>) => {
        state.error = null;
      })
      .addCase(deleteSheet.rejected, (state, action: PayloadAction<string | undefined>) => {
        state.error = action.payload || "Failed to delete sheet.";
      });
  },
});

// Real-time subscription function
export const subscribeToSheets = (dispatch: any) => {
  const subscription = supabase
    .channel("public:sheets")
    .on("postgres_changes", { event: "*", schema: "public", table: "sheets" }, (payload) => {
      const newRecord = payload.new as sheet;
      const oldRecord = payload.old as sheet;

      switch (payload.eventType) {
        case "INSERT":
          dispatch(addSheetRealTime(newRecord));
          break;
        case "UPDATE":
          dispatch(editSheetRealTime(newRecord));
          break;
        case "DELETE":
          dispatch(deleteSheetRealTime(oldRecord.id as string));
          break;
        default:
          break;
      }
    })
    .subscribe();

  return () => {
    subscription.unsubscribe();
  };
};

export default sheetSlice.reducer;
