import React, { useRef, useState } from "react";
import { Button, Popconfirm, Icon, message, List, Tag } from "antd";
import TasksTable from "./tasksTable";
import { department, departmentPosition } from "../../db/interfaces";
import EditDepartmentPosition from "./editDepartmentPositionDialog";
import { globalUsersData } from "../../global/usersList";
import { useAppDispatch } from "../../hooks/reduxHooks";
import { deleteDepartmentPosition } from "../../features/departmentPositions/departmentPositionsSlice";
import { Card, CardActions, CardContent, Collapse, Paper, Typography } from "@material-ui/core";

interface Props {
  data: departmentPosition[];
  departmentId: string;
  department: department;
}

export default function PositionsTable(props: Props) {
  const dispatch = useAppDispatch();
  const [openCardId, setOpenCardId] = useState<string | null>(null);

  const { data, departmentId, department } = props;
  const [modalState, setModalState] = useState(false);
  const [positionId, setPositionId] = useState("");
  const [departmentPosition, setDepartmentPosition] = useState<departmentPosition | null>();


  const handleToggle = (id: string) => {
    setOpenCardId((prevId) => (prevId === id ? null : id));
  };

  const handlePositionDelete = (id: any) => {
    dispatch(deleteDepartmentPosition(id)).then(() => message.success("Deleted Successfully!"));
  };

  const header = (position: departmentPosition) => {
    return (
      <>
        <b>{position.title}</b> <a>{globalUsersData[position?.uid ?? ""]?.fullName}</a>
      </>
    );
  };


  return (
    <div>
      {modalState ? (
        <EditDepartmentPosition
          departmentId={departmentId}
          departmentPositionId={positionId}
          departmentPosition={departmentPosition as departmentPosition}
          modalState={modalState}
          setModalState={setModalState}
        />
      ) : null}
      {data?.map((position) => {
        const isOpen = openCardId === position.id;
        return (
          <div key={position.id}>
            <Card variant="outlined" onClick={() => handleToggle(position.id as string)}>
              <CardContent>
                <Typography component="div">
                  <div>{header(position)}</div>
                </Typography>

                <Typography variant="caption">
                  <div style={{ margin: "1%" }} dangerouslySetInnerHTML={{ __html: position.overview }} />
                </Typography>

                <Collapse key={position.id} in={isOpen}>
                  <Paper elevation={1} style={{ padding: "16px", marginTop: "8px" }}>
                    <Typography variant="body1">
                      {position.attached_job_description.length > 0 && (
                        <List
                          size="small"
                          itemLayout="vertical"
                          dataSource={position.attached_job_description}
                          renderItem={(file: any) => (
                            <List.Item key={file.name}>
                              <div>
                                <a href={file.url} target="_blank" rel="noopener noreferrer">
                                  {file.name}
                                </a>
                              </div>
                            </List.Item>
                          )}
                        />
                      )}

                      <TasksTable departmentId={departmentId} department={department} position={position} />
                    </Typography>
                  </Paper>
                </Collapse>
              </CardContent>

              <CardActions style={{ float: "right" }}>
                <div onClick={(event) => event.stopPropagation()}>
                  <Button size="small" type="link" onClick={() => handleToggle(position.id as string)}>
                    Show More
                  </Button>
                  <Button
                    onClick={(event) => {
                      event.stopPropagation();
                      setPositionId(position.id as string);
                      setDepartmentPosition(position);
                      setModalState(true);
                    }}
                    type="link"
                  >
                    Edit
                  </Button>
                  <Popconfirm
                    title={"Are you sure?"}
                    onConfirm={() => handlePositionDelete(position.id)}
                    onCancel={() => console.log("cancel")}
                    okText="Yes"
                    cancelText="No"
                  >
                    <Button type="link" style={{ color: "red" }}>
                      <Icon type="delete" />
                    </Button>
                  </Popconfirm>
                </div>
              </CardActions>
            </Card>
          </div>
        );
      })}
      <div style={{ padding: "2%" }}>
        <Tag color={"#00C49F"}>R: Responsible</Tag>
        <Tag color="#FFBB28">A: Accountable </Tag>
        <Tag color="#0088FE">C: Consulted </Tag>
        <Tag color="#FF8042">I: Informed </Tag>
      </div>
    </div>
  );
}
