import React, { useState, useEffect } from "react";
import { Button, DatePicker, Drawer, Form, message, Input } from "antd";
import moment from "moment";
import { useFormik } from "formik";
import * as Yup from "yup";
import { useTranslation } from "react-i18next";
import { useAppDispatch, useAppSelector } from "../../hooks/reduxHooks";
import { addProject } from "../../features/projects/projectsSlice";

interface Props {
  modalState: boolean;
  setModalState: React.Dispatch<React.SetStateAction<boolean>>;
}
const projectSchema = Yup.object().shape({
  projectCode: Yup.string().required("*"),
});

function AddNewProject(props: Props) {
  const dispatch = useAppDispatch();
  const { currentUser } = useAppSelector((state) => state.auth);
  const { t } = useTranslation();
  const { modalState, setModalState } = props;
  const [loading, setLoading] = useState(false);

  const formik = useFormik({
    initialValues: {
      projectName: "",
      projectCode: "",
      projectStart: moment(),
      projectEnd: moment(),
      donor: "",
    },
    validationSchema: projectSchema,
    onSubmit: async (values) => {
      try {
        await dispatch(
          addProject({
            projectName: values.projectName,
            projectCode: values.projectCode,
            projectStart: values.projectStart.toDate() as any,
            projectEnd: values.projectEnd.toDate() as any,
            donor: values.donor ?? "",
            createdAt: moment() as any,
            by: currentUser.id as string,
          })
        ).unwrap();
        message.success("Project added successfully");
        setModalState(false);
        setLoading(false);
      } catch (error) {
        setLoading(false);
        console.error("Error writing document: ", error);
        message.error("Failed");
      }
    },
  });
  useEffect(() => {
    return () => {
      if (formik.isValid == false) {
        setLoading(false);
      }
    };
  }, [formik.isValid]);

  const enterLoading = () => {
    setLoading(true);
  };
  const dateFormat = "YYYY/MM/DD";

  return (
    <Drawer
      title={t("general.addNewProject")}
      width={500}
      height={350}
      visible={modalState}
      placement={"right"}
      onClose={() => setModalState(false)}
    >
      <Form onSubmit={formik.handleSubmit}>
        <Form.Item label={t("general.projectName")}>
          <Input
            value={formik.values.projectName}
            onChange={(e) => formik.setFieldValue("projectName", e.target.value)}
          />
        </Form.Item>
        <Form.Item label={t("general.projectCode")}>
          <Input
            value={formik.values.projectCode}
            onChange={(e) => formik.setFieldValue("projectCode", e.target.value)}
          />
        </Form.Item>
        <Form.Item label={t("general.donor")}>
          <Input value={formik.values.donor} onChange={(e) => formik.setFieldValue("donor", e.target.value)} />
        </Form.Item>
        <Form.Item label={t("general.projectStart")}>
          <DatePicker
            value={formik.values.projectStart}
            format={dateFormat}
            onChange={(e) => formik.setFieldValue("projectStart", e)}
          />{" "}
        </Form.Item>
        <Form.Item label={t("general.projectEnd")}>
          <DatePicker
            value={formik.values.projectEnd}
            format={dateFormat}
            onChange={(e) => formik.setFieldValue("projectEnd", e)}
          />{" "}
        </Form.Item>

        <div>
          <Button
            className="btn blue lighten-1 z-depth-0"
            onClick={() => {
              enterLoading();
            }}
            loading={loading}
            type="primary"
            htmlType="submit"
            disabled={!formik.isValid}
          >
            {t("general.create")}
          </Button>
        </div>
      </Form>
    </Drawer>
  );
}

export default AddNewProject;
