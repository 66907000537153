import React, { useState, useEffect } from "react";
import { Button, Form, message, Select, Input, Descriptions, DatePicker, Avatar } from "antd";
const { Option } = Select;
import { useFormik } from "formik";
import * as Yup from "yup";
import { useTranslation } from "react-i18next";
import moment from "moment";
import { useAppDispatch, useAppSelector } from "../../hooks/reduxHooks";
import { editUser } from "../../features/users/usersSlice";
import { uploadFile } from "../../db/supabase-storage";

const userSchema = Yup.object().shape({
  email: Yup.string().email("Invalid email").required(),
});

export default function MyProfile() {
  const { currentUser } = useAppSelector((state) => state.auth);
  const dispatch = useAppDispatch();

  const { t } = useTranslation();
  const [loading, setLoading] = useState(false);
  const uid = currentUser.id as string;
  const data = currentUser;

  const formik = useFormik({
    initialValues: {
      fatherName: "",
      motherName: "",
      startingDate: moment(),
      endingDate: moment(),
      email: "",
      firstName: "",
      lastName: "",
      arabicFullName: "",
      dateOfBirth: moment(),
      placeOfBirth: "",
      permanentAddress: "",
      currentAddress: "",
      phoneNumber: "",
      identityNo: "",
      emergencyContact1: "",
      emergencyContact2: "",
      bankAccountName: "",
      bankName: "",
      IBAN: "",
      bankAccountNumber: "",
    },
    validationSchema: userSchema,
    onSubmit: async (values, form) => {
      try {
        await dispatch(
          editUser({
            id: uid,
            fatherName: values.fatherName,
            motherName: values.motherName,
            email: values.email,
            firstName: values.firstName,
            lastName: values.lastName,
            arabicFullName: values.arabicFullName,
            dateOfBirth: values.dateOfBirth as any,
            placeOfBirth: values.placeOfBirth,
            permanentAddress: values.permanentAddress,
            currentAddress: values.currentAddress,
            phoneNumber: values.phoneNumber,
            identityNo: values.identityNo,
            emergencyContact1: values.emergencyContact1,
            emergencyContact2: values.emergencyContact2,
            bankAccountName: values.bankAccountName,
            bankName: values.bankName,
            IBAN: values.IBAN,
            bankAccountNumber: values.bankAccountNumber,
          })
        ).then(() => {
          message.success("Done!");
          console.log("Document successfully written!");
          setLoading(false);
        });
      } catch (error) {
        console.error("Error writing document: ", error);
        message.error("Failed");
      }
    },
  });

  const [loaded, setLoaded] = useState(true);
  useEffect(() => {
    if (loaded) {
      formik.setValues({
        ...data,
        startingDate: moment(data.startingDate),
        endingDate: moment(data.endingDate),
        dateOfBirth: moment(data.dateOfBirth),
        fatherName: data.fatherName,
        motherName: data.motherName,
        email: data.email,
        firstName: data.firstName,
        lastName: data.lastName,
        arabicFullName: data.arabicFullName,
        placeOfBirth: data.placeOfBirth,
        permanentAddress: data.permanentAddress,
        currentAddress: data.currentAddress,
        phoneNumber: data.phoneNumber,
        identityNo: data.identityNo,
        emergencyContact1: data.emergencyContact1,
        emergencyContact2: data.emergencyContact2,
        bankAccountName: data.bankAccountName,
        bankName: data.bankName,
        IBAN: data.IBAN,
        bankAccountNumber: data.bankAccountNumber,
      } as any);
      setLoaded(false);
    }
  }, [formik, loaded]);
  const enterLoading = () => {
    setLoading(true);
  };
  const dateFormat = "YYYY/MM/DD";

  const handleFileChange = async (e: React.ChangeEvent<HTMLInputElement>) => {
    if (e.target.files) {
      const file: File = e.target.files[0];
      if (!file) return;
      const filePath = `personal_photo/${uid}/${file.name}`;
      try {
        const url = await uploadFile(file, filePath);

        if (url) {
          message.success("File uploaded successfully");
          return url;
        }
      } catch (error: any) {
        message.error(error.message);
      }
    }
  };

  return (
    <div>
      <div style={{ textAlign: "center", paddingBottom: "1%" }}>
        <label style={{ cursor: "pointer" }}>
          <Avatar shape="circle" size={225} icon="user" src={currentUser?.personalPhoto} />

          <input
            hidden
            type="file"
            onChange={async (e) => {
              const url = await handleFileChange(e);
              dispatch(
                editUser({
                  id: uid,
                  personalPhoto: url,
                } as any)
              ).then(() => message.success(" Uploaded successfully"));
            }}
          />
        </label>
      </div>
      <div>
        <Form onSubmit={formik.handleSubmit}>
          <div style={{ textAlign: "-webkit-center" }}>
            <div>
              <Descriptions
                style={{ width: "55%" }}
                size={"middle"}
                layout="horizontal"
                bordered
                column={{ xxl: 6, xl: 4, lg: 3, md: 3, sm: 2, xs: 1 }}
              >
                <Descriptions.Item label={t("general.firstName")} span={3}>
                  <Input
                    disabled={data?.firstName != ""}
                    value={formik.values.firstName}
                    onChange={(e) => formik.setFieldValue("firstName", e.target.value)}
                  />
                </Descriptions.Item>
                <Descriptions.Item label={t("general.lastName")} span={3}>
                  <Input
                    disabled={data?.lastName != ""}
                    value={formik.values.lastName}
                    onChange={(e) => formik.setFieldValue("lastName", e.target.value)}
                  />
                </Descriptions.Item>
                <Descriptions.Item label={t("general.email")} span={3}>
                  <Input
                    disabled={data?.signInType == "byEmail"}
                    value={formik.values.email}
                    onChange={(e) => formik.setFieldValue("email", e.target.value)}
                  />
                </Descriptions.Item>
                <Descriptions.Item label={t("general.identityNo")} span={3}>
                  <Input
                    disabled={false}
                    type="text"
                    id="identityNo"
                    value={formik.values.identityNo}
                    onChange={(e) => formik.setFieldValue("identityNo", e.target.value)}
                  />
                </Descriptions.Item>

                <Descriptions.Item label={t("general.arabicFullName")} span={3}>
                  <Input
                    disabled={false}
                    type="text"
                    id="arabicFullName"
                    value={formik.values.arabicFullName}
                    onChange={(e) => formik.setFieldValue("arabicFullName", e.target.value)}
                  />
                </Descriptions.Item>
                <Descriptions.Item label={t("general.dateOfBirth")} span={3}>
                  <DatePicker
                    disabled={false}
                    value={formik.values.dateOfBirth}
                    format={dateFormat}
                    onChange={(e: any) => {
                      formik.setFieldValue("dateOfBirth", e);
                    }}
                  />
                </Descriptions.Item>
                <Descriptions.Item label={t("general.placeOfBirth")} span={6}>
                  <Input
                    disabled={false}
                    type="text"
                    id="placeOfBirth"
                    value={formik.values.placeOfBirth}
                    onChange={(e) => formik.setFieldValue("placeOfBirth", e.target.value)}
                  />
                </Descriptions.Item>
                <Descriptions.Item label={t("general.permanentAddress")} span={6}>
                  <Input
                    disabled={false}
                    type="text"
                    id="permanentAddress"
                    value={formik.values.permanentAddress}
                    onChange={(e) => formik.setFieldValue("permanentAddress", e.target.value)}
                  />
                </Descriptions.Item>
                <Descriptions.Item label={t("general.currentAddress")} span={6}>
                  <Input
                    disabled={false}
                    type="text"
                    id="currentAddress"
                    value={formik.values.currentAddress}
                    onChange={(e) => formik.setFieldValue("currentAddress", e.target.value)}
                  />
                </Descriptions.Item>

                <Descriptions.Item label={t("general.fatherName")} span={3}>
                  <Input
                    disabled={false}
                    type="text"
                    id="fatherName"
                    value={formik.values.fatherName}
                    onChange={(e) => formik.setFieldValue("fatherName", e.target.value)}
                  />
                </Descriptions.Item>
                <Descriptions.Item label={t("general.motherName")} span={3}>
                  <Input
                    disabled={false}
                    type="text"
                    id="motherName"
                    value={formik.values.motherName}
                    onChange={(e) => formik.setFieldValue("motherName", e.target.value)}
                  />
                </Descriptions.Item>

                <Descriptions.Item label={t("general.phoneNumber")} span={3}>
                  <Input
                    disabled={false}
                    type="text"
                    id="phoneNumber"
                    value={formik.values.phoneNumber}
                    onChange={(e) => formik.setFieldValue("phoneNumber", e.target.value)}
                  />
                </Descriptions.Item>
                <Descriptions.Item label={t("general.emergencyContact1")} span={3}>
                  <Input
                    type="text"
                    id="emergencyContact1"
                    value={formik.values.emergencyContact1}
                    onChange={(e) => formik.setFieldValue("emergencyContact1", e.target.value)}
                  />
                </Descriptions.Item>
                <Descriptions.Item label={t("general.emergencyContact2")} span={3}>
                  <Input
                    type="text"
                    id="emergencyContact2"
                    value={formik.values.emergencyContact2}
                    onChange={(e) => formik.setFieldValue("emergencyContact2", e.target.value)}
                  />
                </Descriptions.Item>

                <Descriptions.Item label={t("general.bankAccountName")} span={3}>
                  <Input
                    type="text"
                    id="bankAccountName"
                    value={formik.values.bankAccountName}
                    onChange={(e) => formik.setFieldValue("bankAccountName", e.target.value)}
                  />
                </Descriptions.Item>
                <Descriptions.Item label={t("general.bankName")} span={3}>
                  <Input
                    type="text"
                    id="bankName"
                    value={formik.values.bankName}
                    onChange={(e) => formik.setFieldValue("bankName", e.target.value)}
                  />
                </Descriptions.Item>
                <Descriptions.Item label={t("general.IBAN")} span={3}>
                  <Input
                    type="text"
                    id="IBAN"
                    value={formik.values.IBAN}
                    onChange={(e) => formik.setFieldValue("IBAN", e.target.value)}
                  />
                </Descriptions.Item>
                <Descriptions.Item label={t("general.bankAccountNumber")} span={3}>
                  <Input
                    type="text"
                    id="bankAccountNumber"
                    value={formik.values.bankAccountNumber}
                    onChange={(e) => formik.setFieldValue("bankAccountNumber", e.target.value)}
                  />
                </Descriptions.Item>
              </Descriptions>

              <div>
                <Button
                  style={{ margin: "1%", width: "25%" }}
                  className="btn blue lighten-1 z-depth-0"
                  onClick={() => {
                    enterLoading();
                  }}
                  loading={loading}
                  type="primary"
                  htmlType="submit"
                  disabled={!formik.isValid}
                >
                  {t("general.save")}
                </Button>
              </div>
            </div>
          </div>
        </Form>
      </div>
    </div>
  );
}
