import React, { useState, useEffect } from "react";
import { Button, DatePicker, Drawer, Form, InputNumber, message, Select, Spin, Input, Descriptions, Icon } from "antd";
import moment from "moment";
const { Option } = Select;
import { useFormik } from "formik";
import * as Yup from "yup";
import { useTranslation } from "react-i18next";
import { useAppDispatch, useAppSelector } from "../../hooks/reduxHooks";
import { addOrder } from "../../features/orders/ordersSlice";
import { uploadFile } from "../../db/supabase-storage";
import { useHistory } from "react-router-dom";
import { addNotification } from "../../features/notifications/notificationsSlice";
import { globalUsersData } from "../../global/usersList";

interface Props {
  modalState: boolean;
  setModalState: React.Dispatch<React.SetStateAction<boolean>>;
}
const projectSchema = Yup.object().shape({
  TC: Yup.number().required("*"),
  firstName: Yup.string().required("*"),
  lastName: Yup.string().required("*"),
  femaleChildrenNumber: Yup.number().min(0).max(20).required("*"),
  maleChildrenNumber: Yup.number().min(0).max(20).required("*"),
});

function AddNewOrder(props: Props) {
  const { currentUser } = useAppSelector((state) => state.auth);
  const dispatch = useAppDispatch();

  const { t } = useTranslation();
  const history = useHistory();

  const [IDURL, setIDURL] = useState("");
  const [permitCardURL, setPermitCardURL] = useState("");
  const [passportPhoto, setPassportPhoto] = useState("");
  const [diplomaURL, setDiplomaURL] = useState("");
  const [personalPhoto, setPersonalPhoto] = useState("");
  //-----upload status ends

  const [orderType, setOrderType] = useState("Ikamet Kart");
  const [expiryDate, setExpiryDate] = useState(moment());
  const [jobStart, setJobStart] = useState(moment());
  const [firstTime, setFirstTime] = useState("yes");
  const { modalState, setModalState } = props;
  const [loading, setLoading] = useState(false);
  const uid = currentUser?.id as string;

  const handleFileChange = async (
    e: React.ChangeEvent<HTMLInputElement>,
    setURLValue: React.Dispatch<React.SetStateAction<string>>
  ) => {
    if (e.target.files) {
      const file: File = e.target.files[0];
      if (!file) return;
      const filePath = `work-permit-documents/${uid}/${file.name}`;
      try {
        const url = await uploadFile(file, filePath);

        if (url) {
          setURLValue(url as any);
          message.success("File uploaded successfully");
        }
      } catch (error: any) {
        message.error(error.message);
      }
    }
  };
  const usersAdminSN = Object.values(globalUsersData)
    .filter((user) => user.adminLaw == true)
    .map((user) => user.id);

  const formik = useFormik({
    initialValues: {
      TC: "",
      firstName: "",
      lastName: "",
      fatherName: "",
      motherName: "",
      gender: "",
      birthPlace: "",
      birthDate: moment(),
      marriedStatus: "",
      nationality: "",
      femaleChildrenNumber: 0,
      maleChildrenNumber: 0,
      wifeNationality: "",
      wifeFirstName: "",
      wifeLastName: "",
      wifeTC: "",
      phone: "",
      email: "",
      residenceAddress: "",
      universityNameAddress: "",
      degree: "",
      degreeField: "",
      degreeFaculty: "",
      degreeSpecialization: "",
      passportNO: "",
      passportExpiryDate: moment(),
      passportAuth: "",
      position: "",
      salary: 0,
      cityInSyria: "",
      durationInTurkey: "",
      language: "",
      startWorkDate: moment(),
      endWorkDate: moment(),
    },
    validationSchema: projectSchema,
    onSubmit: async (values, form) => {
      if (formik.isValid == false) {
      }

      try {
        dispatch(
          addOrder({
            personalPhoto: personalPhoto,
            IDURL: IDURL,
            passportPhotoURL: passportPhoto,
            permitCardPhotoURL: permitCardURL,
            diplomaURL: diplomaURL,
            requestedAt: moment() as any,
            manager: uid,
            orderType: orderType,
            status: "Submitted",
            imgURL: "",
            TC: values.TC,
            firstTime: firstTime,
            permitExpiryDate: expiryDate as any,
            jobStartDate: jobStart as any,
            firstName: values.firstName,
            lastName: values.lastName,
            fatherName: values.fatherName,
            motherName: values.motherName,
            gender: values.gender,
            birthPlace: values.birthPlace,
            birthDate: values.birthDate as any,
            marriedStatus: values.marriedStatus,
            nationality: values.nationality,
            femaleChildrenNumber: values.femaleChildrenNumber,
            maleChildrenNumber: values.maleChildrenNumber,
            wifeNationality: values.wifeNationality,
            wifeFirstName: values.wifeFirstName,
            wifeLastName: values.wifeLastName,
            wifeTC: values.wifeTC,
            phone: values.phone,
            email: values.email,
            residenceAddress: values.residenceAddress,
            universityNameAddress: values.universityNameAddress,
            degree: values.degree,
            degreeField: values.degreeField,
            degreeFaculty: values.degreeFaculty,
            degreeSpecialization: values.degreeSpecialization,
            passportNO: values.passportNO,
            passportExpiryDate: values.passportExpiryDate as any,
            passportAuth: values.passportAuth,
            position: values.position,
            salary: values.salary,
            cityInSyria: values.cityInSyria,
            durationInTurkey: values.durationInTurkey,
            language: values.language,
            trackingNumber: "",
            activeDate: "null",
            renewalRequestDate: "null",
            receivedDate: "null",
            sendDocDate: "null",
            rejectedDate: "null",
            approvedDate: "null",
            canceledDate: "null",
            startFreezeDate: "null",
            endFreezeDate: "null",
            leaveJobDate: "null",
            contractURL: "",
            signedContractURL: "",
            signedContractDate: "",
          })
        )
          .then((res: any) => {
            history.push(`/orders/order/${res.payload.id}`);
            message.success("Your request has been submitted successfully");
            setModalState(false);
            setLoading(false);
            setIDURL("");
            setPersonalPhoto("");
            setPermitCardURL("");
            setPassportPhoto("");
            setDiplomaURL("");
            setOrderType("Ikamet Kart");
            setExpiryDate(moment());
            setJobStart(moment());
            setFirstTime("yes");
            setLoading(false);
          })
          .then(async (res: any) => {
            await usersAdminSN?.map((uid: any) => {
              const adminID = uid;
              dispatch(
                addNotification({
                  notifyTo: adminID,
                  notifyFrom: currentUser?.id as string,
                  orderId: res.payload.id,
                  read: false,
                  kind: "newRequest",
                  content: "New order has been requested!",
                  postedAt: moment() as any,
                })
              );
            });
          })

          .then(() => form.resetForm());
      } catch (error) {
        console.error("Error writing document: ", error);
        message.error("Failed");
      }
    },
  });

  useEffect(() => {
    return () => {
      if (formik.isValid == false) {
        setLoading(false);
      }
    };
  }, [formik.isValid]);
  const enterLoading = () => {
    setLoading(true);
  };
  const dateFormat = "YYYY/MM/DD";
  return (
    <Drawer title="Add New Request" width={1000} visible={modalState} onClose={() => setModalState(false)}>
      <Form onSubmit={formik.handleSubmit}>
        <div>
          <div>
            <Descriptions
              size={"small"}
              layout="horizontal"
              bordered
              column={{ xxl: 6, xl: 4, lg: 3, md: 3, sm: 2, xs: 1 }}
            >
              <Descriptions.Item label={t("general.selectYourRC")} span={1}>
                <Select value={orderType} onChange={(e) => setOrderType(e)}>
                  <Option value={"AFAD Kart"}>AFAD Kart</Option>
                  <Option value={"Ikamet Kart"}>Ikamet Kart</Option>
                  <Option value={"Turk Vatandasi"}>Turk Vatandasi</Option>
                </Select>
              </Descriptions.Item>
            </Descriptions>

            {orderType != "" ? (
              <div>
                <Descriptions
                  size={"small"}
                  layout="horizontal"
                  bordered
                  column={{ xxl: 6, xl: 4, lg: 3, md: 3, sm: 2, xs: 1 }}
                >
                  <Descriptions.Item label={t("general.firstTime")} span={2}>
                    <Select defaultValue={"Yes"} onChange={(e) => setFirstTime(e)}>
                      <Option value={"Yes"}>Yes</Option>
                      <Option value={"No"}>No</Option>
                    </Select>
                  </Descriptions.Item>
                  {firstTime == "No" ? (
                    <Descriptions.Item label={t("general.permitExpiryDate")} span={2}>
                      <DatePicker value={expiryDate} format={dateFormat} onChange={(e) => setExpiryDate(e)} />
                    </Descriptions.Item>
                  ) : (
                    <Descriptions.Item label={t("general.workStartOn")} span={2}>
                      <DatePicker value={expiryDate} format={dateFormat} onChange={(e) => setJobStart(e)} />
                    </Descriptions.Item>
                  )}
                  {/* //------------------- */}
                  <Descriptions.Item label={t("general.TC")} span={2}>
                    <Input value={formik.values.TC} onChange={(e) => formik.setFieldValue("TC", e.target.value)} />
                  </Descriptions.Item>
                  <Descriptions.Item label={t("general.firstName")} span={2}>
                    <Input
                      value={formik.values.firstName}
                      onChange={(e) => formik.setFieldValue("firstName", e.target.value)}
                    />
                  </Descriptions.Item>
                  <Descriptions.Item label={t("general.lastName")} span={2}>
                    <Input
                      value={formik.values.lastName}
                      onChange={(e) => formik.setFieldValue("lastName", e.target.value)}
                    />
                  </Descriptions.Item>
                  <Descriptions.Item label={t("general.fatherName")} span={2}>
                    <Input
                      value={formik.values.fatherName}
                      onChange={(e) => formik.setFieldValue("fatherName", e.target.value)}
                    />
                  </Descriptions.Item>
                  <Descriptions.Item label={t("general.motherName")} span={2}>
                    <Input
                      value={formik.values.motherName}
                      onChange={(e) => formik.setFieldValue("motherName", e.target.value)}
                    />
                  </Descriptions.Item>
                  <Descriptions.Item label={t("general.gender")} span={2}>
                    <Select value={formik.values.gender} onChange={(e) => formik.setFieldValue("gender", e)}>
                      <Option value={"male"}>Male</Option>
                      <Option value={"female"}>Female</Option>
                    </Select>
                  </Descriptions.Item>
                  <Descriptions.Item label={t("general.birthPlace")} span={2}>
                    <Input
                      value={formik.values.birthPlace}
                      onChange={(e) => formik.setFieldValue("birthPlace", e.target.value)}
                    />
                  </Descriptions.Item>
                  <Descriptions.Item label={t("general.birthDate")} span={2}>
                    <DatePicker
                      value={formik.values.birthDate}
                      format={dateFormat}
                      onChange={(e) => formik.setFieldValue("birthDate", e)}
                    />
                  </Descriptions.Item>
                  <Descriptions.Item label={t("general.marriedStatus")} span={2}>
                    <Select
                      value={formik.values.marriedStatus}
                      onChange={(e) => formik.setFieldValue("marriedStatus", e)}
                    >
                      <Option value={"Evli"}>Married</Option>
                      <Option value={"Bekar"}>Single</Option>
                    </Select>
                  </Descriptions.Item>
                  {orderType != "Turk Vatandasi" ? (
                    <Descriptions.Item label={t("general.nationality")} span={2}>
                      <Input
                        value={formik.values.nationality}
                        onChange={(e) => formik.setFieldValue("nationality", e.target.value)}
                      />
                    </Descriptions.Item>
                  ) : null}
                  {/* //------------------- */}
                  {orderType != "AFAD Kart" ? (
                    <Descriptions.Item label={t("general.femaleChildrenNumber")} span={2}>
                      <InputNumber
                        value={formik.values.femaleChildrenNumber}
                        onChange={(e) => formik.setFieldValue("femaleChildrenNumber", e)}
                      />
                    </Descriptions.Item>
                  ) : null}
                  {orderType != "AFAD Kart" ? (
                    <Descriptions.Item label={t("general.maleChildrenNumber")} span={2}>
                      <InputNumber
                        value={formik.values.maleChildrenNumber}
                        onChange={(e) => formik.setFieldValue("maleChildrenNumber", e)}
                      />
                    </Descriptions.Item>
                  ) : null}
                  <Descriptions.Item label={t("general.wifeNationality")} span={2}>
                    <Input
                      value={formik.values.wifeNationality}
                      onChange={(e) => formik.setFieldValue("wifeNationality", e.target.value)}
                    />
                  </Descriptions.Item>
                  <Descriptions.Item label={t("general.wifeFirstName")} span={2}>
                    <Input
                      value={formik.values.wifeFirstName}
                      onChange={(e) => formik.setFieldValue("wifeFirstName", e.target.value)}
                    />
                  </Descriptions.Item>
                  <Descriptions.Item label={t("general.wifeLastName")} span={2}>
                    <Input
                      value={formik.values.wifeLastName}
                      onChange={(e) => formik.setFieldValue("wifeLastName", e.target.value)}
                    />
                  </Descriptions.Item>
                  <Descriptions.Item label={t("general.wifeTC")} span={2}>
                    <Input
                      value={formik.values.wifeTC}
                      onChange={(e) => formik.setFieldValue("wifeTC", e.target.value)}
                    />
                  </Descriptions.Item>
                  {/* //------------------- */}
                  <Descriptions.Item label={t("general.phone")} span={2}>
                    <Input
                      value={formik.values.phone}
                      onChange={(e) => formik.setFieldValue("phone", e.target.value)}
                    />
                  </Descriptions.Item>
                  <Descriptions.Item label={t("general.email")} span={2}>
                    <Input
                      value={formik.values.email}
                      onChange={(e) => formik.setFieldValue("email", e.target.value)}
                    />
                  </Descriptions.Item>
                  {/* //------------------- */}
                  {orderType != "AFAD Kart" ? (
                    <Descriptions.Item label={t("general.universityNameAddress")} span={2}>
                      <Input
                        value={formik.values.universityNameAddress}
                        onChange={(e) => formik.setFieldValue("universityNameAddress", e.target.value)}
                      />
                    </Descriptions.Item>
                  ) : null}{" "}
                  {orderType != "AFAD Kart" ? (
                    <Descriptions.Item label={t("general.degree")} span={2}>
                      <Input
                        value={formik.values.degree}
                        onChange={(e) => formik.setFieldValue("degree", e.target.value)}
                      />
                    </Descriptions.Item>
                  ) : null}
                  {orderType != "AFAD Kart" ? (
                    <Descriptions.Item label={t("general.degreeField")} span={2}>
                      <Input
                        value={formik.values.degreeField}
                        onChange={(e) => formik.setFieldValue("degreeField", e.target.value)}
                      />
                    </Descriptions.Item>
                  ) : null}
                  {orderType != "AFAD Kart" ? (
                    <Descriptions.Item label={t("general.degreeSpecialization")} span={2}>
                      <Input
                        value={formik.values.degreeSpecialization}
                        onChange={(e) => formik.setFieldValue("degreeSpecialization", e.target.value)}
                      />
                    </Descriptions.Item>
                  ) : null}
                  {/* //------------------- */}
                  {orderType == "Ikamet Kart" ? (
                    <Descriptions.Item label={t("general.passportNO")} span={2}>
                      <Input
                        value={formik.values.passportNO}
                        onChange={(e) => formik.setFieldValue("passportNO", e.target.value)}
                      />
                    </Descriptions.Item>
                  ) : null}
                  {orderType == "Ikamet Kart" ? (
                    <Descriptions.Item label={t("general.passportExpiryDate")} span={2}>
                      <DatePicker
                        value={formik.values.passportExpiryDate}
                        onChange={(e) => formik.setFieldValue("passportExpiryDate", e)}
                      />
                    </Descriptions.Item>
                  ) : null}
                  {orderType == "Ikamet Kart" ? (
                    <Descriptions.Item label={t("general.passportAuth")} span={2}>
                      <Input
                        value={formik.values.passportAuth}
                        onChange={(e) => formik.setFieldValue("passportAuth", e.target.value)}
                      />
                    </Descriptions.Item>
                  ) : null}
                  <Descriptions.Item label={t("general.position")} span={2}>
                    <Input
                      value={formik.values.position}
                      onChange={(e) => formik.setFieldValue("position", e.target.value)}
                    />
                  </Descriptions.Item>
                  <Descriptions.Item label={t("general.salary")} span={2}>
                    <InputNumber value={formik.values.salary} onChange={(e) => formik.setFieldValue("salary", e)} />
                  </Descriptions.Item>
                  {orderType == "AFAD Kart" || orderType == "Ikamet Kart" ? (
                    <Descriptions.Item label={t("general.cityInSyria")} span={2}>
                      <Input
                        value={formik.values.cityInSyria}
                        onChange={(e) => formik.setFieldValue("cityInSyria", e.target.value)}
                      />
                    </Descriptions.Item>
                  ) : null}
                  {orderType == "AFAD Kart" ? (
                    <Descriptions.Item label={t("general.degree")} span={2}>
                      <Input
                        value={formik.values.degree}
                        onChange={(e) => formik.setFieldValue("degree", e.target.value)}
                      />
                    </Descriptions.Item>
                  ) : null}
                  {orderType == "AFAD Kart" ? (
                    <Descriptions.Item label={t("general.universityNameAddress")} span={2}>
                      <Input
                        value={formik.values.universityNameAddress}
                        onChange={(e) => formik.setFieldValue("universityNameAddress", e.target.value)}
                      />
                    </Descriptions.Item>
                  ) : null}
                  {orderType == "AFAD Kart" ? (
                    <Descriptions.Item label={t("general.degreeFaculty")} span={2}>
                      <Input
                        value={formik.values.degreeFaculty}
                        onChange={(e) => formik.setFieldValue("degreeFaculty", e.target.value)}
                      />
                    </Descriptions.Item>
                  ) : null}
                  {orderType == "AFAD Kart" ? (
                    <Descriptions.Item label={t("general.degreeField")} span={2}>
                      <Input
                        value={formik.values.degreeField}
                        onChange={(e) => formik.setFieldValue("degreeField", e.target.value)}
                      />
                    </Descriptions.Item>
                  ) : null}
                  {orderType == "AFAD Kart" ? (
                    <Descriptions.Item label={t("general.durationInTurkey")} span={2}>
                      <Input
                        value={formik.values.durationInTurkey}
                        onChange={(e) => formik.setFieldValue("durationInTurkey", e.target.value)}
                      />
                    </Descriptions.Item>
                  ) : null}
                  {orderType == "AFAD Kart" ? (
                    <Descriptions.Item label={t("general.femaleChildrenNumber")} span={2}>
                      <InputNumber
                        value={formik.values.femaleChildrenNumber}
                        onChange={(e) => formik.setFieldValue("femaleChildrenNumber", e)}
                      />
                    </Descriptions.Item>
                  ) : null}
                  {orderType == "AFAD Kart" ? (
                    <Descriptions.Item label={t("general.maleChildrenNumber")} span={2}>
                      <InputNumber
                        value={formik.values.maleChildrenNumber}
                        onChange={(e) => formik.setFieldValue("maleChildrenNumber", e)}
                      />
                    </Descriptions.Item>
                  ) : null}
                  {orderType == "AFAD Kart" ? (
                    <Descriptions.Item label={t("general.language")} span={2}>
                      <Input
                        value={formik.values.language}
                        onChange={(e) => formik.setFieldValue("language", e.target.value)}
                      />
                    </Descriptions.Item>
                  ) : null}
                </Descriptions>
                <Descriptions
                  size={"small"}
                  layout="horizontal"
                  bordered
                  column={{ xxl: 6, xl: 4, lg: 3, md: 3, sm: 2, xs: 1 }}
                >
                  <Descriptions.Item label={t("general.address")} span={4}>
                    <Input
                      value={formik.values.residenceAddress}
                      onChange={(e) => formik.setFieldValue("residenceAddress", e.target.value)}
                    />
                  </Descriptions.Item>
                </Descriptions>
              </div>
            ) : null}
          </div>
        </div>
        <div>
          <Button
            style={{ margin: "1%" }}
            className="btn blue lighten-1 z-depth-0"
            onClick={() => {
              enterLoading();
            }}
            loading={loading}
            type="primary"
            htmlType="submit"
            disabled={!formik.isValid}
          >
            {t("general.create")}
          </Button>
        </div>
        <span>{t("general.attachments")}:</span>
        <Descriptions
          size={"small"}
          layout="horizontal"
          bordered
          column={{ xxl: 6, xl: 4, lg: 3, md: 3, sm: 2, xs: 1 }}
        >
          {orderType == "Ikamet Kart" ? (
            <Descriptions.Item label={t("attachments.prevPermitCardPhoto")} span={3}>
              <div>
                <input type="file" onChange={(e) => handleFileChange(e, setPermitCardURL)} />
                {permitCardURL && (
                  <div>
                    <p>File uploaded successfully!</p>
                    <a href={permitCardURL} target="_blank" rel="noopener noreferrer">
                      View File
                    </a>
                  </div>
                )}
              </div>
            </Descriptions.Item>
          ) : null}
          {orderType == "Ikamet Kart" ? (
            <Descriptions.Item label={t("attachments.passportPhoto")} span={3}>
              <div>
                <input type="file" onChange={(e) => handleFileChange(e, setPassportPhoto)} />
                {passportPhoto && (
                  <div>
                    <p>File uploaded successfully!</p>
                    <a href={passportPhoto} target="_blank" rel="noopener noreferrer">
                      View File
                    </a>
                  </div>
                )}
              </div>
            </Descriptions.Item>
          ) : null}
          {orderType == "Ikamet Kart" ? (
            <Descriptions.Item label={t("attachments.diploma")} span={3}>
              <div>
                <input type="file" onChange={(e) => handleFileChange(e, setDiplomaURL)} />
                {diplomaURL && (
                  <div>
                    <p>File uploaded successfully!</p>
                    <a href={diplomaURL} target="_blank" rel="noopener noreferrer">
                      View File
                    </a>
                  </div>
                )}
              </div>
            </Descriptions.Item>
          ) : null}
          {orderType != "Ikamet Kart" ? (
            <Descriptions.Item label={t("attachments.ID")} span={3}>
              <div>
                <input type="file" onChange={(e) => handleFileChange(e, setIDURL)} />
                {IDURL && (
                  <div>
                    <p>File uploaded successfully!</p>
                    <a href={IDURL} target="_blank" rel="noopener noreferrer">
                      View File
                    </a>
                  </div>
                )}
              </div>
            </Descriptions.Item>
          ) : null}

          <Descriptions.Item label={t("attachments.personalPhoto")} span={3}>
            <div>
              <input type="file" onChange={(e) => handleFileChange(e, setPersonalPhoto)} />
              {personalPhoto && (
                <div>
                  <p>File uploaded successfully!</p>
                  <a href={personalPhoto} target="_blank" rel="noopener noreferrer">
                    View File
                  </a>
                </div>
              )}
            </div>
          </Descriptions.Item>
        </Descriptions>
        {orderType == "Ikamet Kart" ? (
          <div>
            <p style={{ textAlign: "left", margin: 2 }}>{t("attachments.permitCardPhoto")}</p>
            <p style={{ textAlign: "left", margin: 2 }}>{t("attachments.passportPhoto")}</p>
            <p style={{ textAlign: "left", margin: 2 }}>{t("attachments.personalPhoto")}</p>
            <p style={{ textAlign: "left", margin: 2 }}>{t("attachments.diplomaNotarized")}</p>
          </div>
        ) : null}
        {orderType == "AFAD Kart" ? (
          <div>
            <p style={{ textAlign: "left", margin: 2 }}>{t("attachments.IDandPhoto")}</p>
          </div>
        ) : null}
        {orderType == "Turk Vatandasi" ? (
          <div>
            <p style={{ textAlign: "left", margin: 2 }}>{t("attachments.ID")}</p>
          </div>
        ) : null}
      </Form>
    </Drawer>
  );
}

export default AddNewOrder;
