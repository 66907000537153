import React, { useState, useEffect } from "react";
import { Button, DatePicker, Drawer, Form, InputNumber, message, Input, Descriptions, TimePicker } from "antd";
const { TextArea } = Input;
import moment from "moment";
import { useFormik } from "formik";
import * as Yup from "yup";
import { useTranslation } from "react-i18next";
import { overtime } from "../../../db/interfaces";
import { useAppDispatch, useAppSelector } from "../../../hooks/reduxHooks";
import { editOvertime } from "../../../features/overtime/overtimeSlice";

interface Props {
  modalState: boolean;
  setModalState: React.Dispatch<React.SetStateAction<boolean>>;
  overtimeId: string;
  overtime: overtime;
}
const projectSchema = Yup.object().shape({
  overtimeDate: Yup.date().required("*"),
});

function EditOvertime(props: Props) {
  const { currentUser } = useAppSelector((state) => state.auth);
  const dispatch = useAppDispatch();
  const { t } = useTranslation();
  const { modalState, setModalState, overtime, overtimeId } = props;

  const [loading, setLoading] = useState(false);

  const { position, managerUID, office } = currentUser;
  const overtimeData = overtime as overtime;

  const formik = useFormik({
    initialValues: {
      compensationHours: 0,
      overtimeDate: moment(),
      tasks: "",
      overtimeEndHours: moment(),
      overtimeStartHours: moment(),
      taskOvertime: moment(),
    },
    validationSchema: projectSchema,
    onSubmit: async (values, form) => {
      dispatch(
        editOvertime({
          id: overtimeId,
          position: position,
          managerUID: managerUID,
          employeeSignatureDate: moment() as any,
          managerSignatureDate: "",
          adminSignatureDate: "",
          HRSignatureDate: "",
          CEOSignatureDate: "",
          office: office,
          RFCURL: "",
          status: "Requested",
          requestedAt: moment() as any,
          tasks: values.tasks,
          overtimeDate: values.overtimeDate as any,
          overtimeStartHours: values.overtimeStartHours as any,
          overtimeEndHours: values.overtimeEndHours as any,
          taskOvertime: values.taskOvertime as any,
          compensationHours: values.compensationHours,
        } as any)
      )
        .then(() => {
          message.success("Your request has been submitted successfully");
          console.log("Document successfully written!");
          setModalState(false);
          setLoading(false);
          setLoading(false);
        })

        .then(() => form.resetForm())
        .catch((error) => {
          console.error("Error writing document: ", error);
          message.error("Failed");
        });
    },
  });

  const [loaded, setLoaded] = useState(true);
  useEffect(() => {
    if (overtime && loaded) {
      formik.setValues({
        compensationHours: overtimeData?.compensationHours,
        overtimeDate: moment(overtimeData?.overtimeDate),
        tasks: overtimeData?.tasks,
        overtimeEndHours: moment(overtimeData?.overtimeEndHours),
        overtimeStartHours: moment(overtimeData?.overtimeStartHours),
        taskOvertime: moment(overtimeData?.taskOvertime),
      } as any);
      setLoaded(false);
    }
  }, [overtime, formik, loaded]);
  const enterLoading = () => {
    setLoading(true);
  };
  const format = "hh:mm a";

  const dateFormat = "YYYY/MM/DD";
  return (
    <Drawer title="Compensation Request" width={600} visible={modalState} onClose={() => setModalState(false)}>
      <Form onSubmit={formik.handleSubmit}>
        <div>
          <div>
            <Descriptions
              size={"default"}
              layout="horizontal"
              bordered
              column={{ xxl: 6, xl: 4, lg: 3, md: 3, sm: 2, xs: 1 }}
            >
              {/* --Over Time Request --- */}

              <Descriptions.Item label={t("general.overtimeDate")} span={6}>
                <DatePicker
                  value={formik.values.overtimeDate}
                  format={dateFormat}
                  onChange={(e) => formik.setFieldValue("overtimeDate", e)}
                />{" "}
              </Descriptions.Item>

              <Descriptions.Item label={t("general.overtimeStartHours")} span={6}>
                <TimePicker
                  use12Hours
                  format={format}
                  value={formik.values.overtimeStartHours}
                  onChange={(e) => formik.setFieldValue("overtimeStartHours", e)}
                />
              </Descriptions.Item>

              <Descriptions.Item label={t("general.overtimeEndHours")} span={6}>
                <TimePicker
                  use12Hours
                  value={formik.values.overtimeEndHours}
                  onChange={(e) => formik.setFieldValue("overtimeEndHours", e)}
                  format={format}
                />
              </Descriptions.Item>

              <Descriptions.Item label={t("general.compensationHours")} span={6}>
                <InputNumber
                  max={20}
                  min={0}
                  value={formik.values.compensationHours}
                  onChange={(e) => formik.setFieldValue("compensationHours", e)}
                />
              </Descriptions.Item>
              <Descriptions.Item label={t("general.taskOvertime")} span={6}>
                <DatePicker
                  value={formik.values.taskOvertime}
                  format={dateFormat}
                  onChange={(e) => formik.setFieldValue("taskOvertime", e)}
                />{" "}
              </Descriptions.Item>
              <Descriptions.Item label={t("general.tasks")} span={6}>
                <TextArea
                  value={formik.values.tasks}
                  autoSize={{ minRows: 8, maxRows: 20 }}
                  onChange={(e) => formik.setFieldValue("tasks", e.target.value)}
                />
              </Descriptions.Item>
            </Descriptions>
          </div>
        </div>
        <div>
          <Button
            style={{ margin: "1%" }}
            className="btn blue lighten-1 z-depth-0"
            onClick={() => {
              enterLoading();
            }}
            loading={loading}
            type="primary"
            htmlType="submit"
            disabled={!formik.isValid}
          >
            {t("general.create")}
          </Button>
        </div>
      </Form>
    </Drawer>
  );
}

export default EditOvertime;
