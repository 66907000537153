import React, { useState, useEffect } from "react";
import Paper from "@material-ui/core/Paper";
import { Spin, Button, Popconfirm, Select } from "antd";
const { Option } = Select;
import { useTranslation } from "react-i18next";
import moment from "moment";
import AddNewAllocation from "./AddNewAllocationModel";
import EditAllocation from "./EditAllocation";
import DevTable from "../../helpers/devex-table";
import { globalUsersData } from "../../global/usersList";
import { globalProjectsData } from "../../global/projectList";
import { globalBudgetLinesData } from "../../global/budgetLineList";
import { useAppDispatch, useAppSelector } from "../../hooks/reduxHooks";
import {
  deleteAllocation,
  fetchAllocationsByOfficeId,
  subscribeToAllocations,
} from "../../features/allocations/allocationsSlice";
import { OFFICES_ID_NAME } from "../../global/officeList";
import { allocation } from "../../db/interfaces";
import { setFilterValue } from "../../features/filters/filtersSlice";

export default function AllocationsTable() {
  const { data: allocationsData, status, error } = useAppSelector((store) => store.allocations);
  const { currentUser } = useAppSelector((state) => state.auth);

  const dispatch = useAppDispatch();
  const { filterValues } = useAppSelector((state) => state.filters);
  const [officeId, setOfficeId] = useState(filterValues["officeId"] ?? "Select an office...");

  const { t } = useTranslation();
  const [modalState, setModalState] = useState(false);
  const [modalStateEdit, setModalStateEdit] = useState(false);
  const [allocation, setAllocation] = useState({} as allocation);

  useEffect(() => {
    dispatch(fetchAllocationsByOfficeId(officeId));
    const unsubscribe = subscribeToAllocations(dispatch);
    return () => {
      unsubscribe();
    };
  }, [dispatch, officeId]);

  if (status == "loading") return <Spin />;

  const data = allocationsData
    .filter((d) => currentUser?.HRProjectsManagersOffices?.includes(d.office))
    .map((d) => {
      return { ...d, id: d.id };
    });
  const columns = [
    {
      name: "uid",
      title: "UID",
    },
    {
      name: "name",
      title: "Name",
      getCellValue: (row: any) => {
        const userData = globalUsersData[row.uid ?? ""];

        return `${userData?.firstName} ${userData?.fatherName} ${userData?.lastName}`;
      },
    },

    {
      name: "projectId",
      title: "Project Code",
      getCellValue: (row: any) => {
        return globalProjectsData[row.projectId ?? ""]?.projectCode;
      },
    },
    {
      name: "budgetLineId",
      title: "Budget Line",
      getCellValue: (row: any) => {
        return globalBudgetLinesData[row.budgetLineId ?? ""]?.budgetLine;
      },
    },
    { name: "percentage", title: "Allocation %", getCellValue: (row: any) => row.percentage + "%" },
    {
      name: "idaAllocationPercentage",
      title: "% of All",
      getCellValue: (row: any) => row.idaAllocationPercentage + "%",
    },

    {
      name: "startDate",
      title: "Start Date",
      getCellValue: (row: any) => moment(row.startDate).format("DD.MM.YYYY"),
    },
    {
      name: "endDate",
      title: "End Date",
      getCellValue: (row: any) => moment(row.endDate).format("DD.MM.YYYY"),
    },

    {
      name: "actions",
      title: "Actions",
      getCellValue: (row: any) => {
        return (
          <Popconfirm
            title={t("general.areUSure")}
            onConfirm={() => {
              dispatch(deleteAllocation(row.id));
            }}
            onCancel={() => console.log("cancel")}
            okText="Yes"
            cancelText="No"
          >
            <Button type="link">Delete</Button>
          </Popconfirm>
        );
      },
    },
    {
      name: "edit",
      title: "Edit",
      getCellValue: (row: any) => {
        return (
          <Button
            onClick={() => {
              setModalStateEdit(true);
              setAllocation(row);
            }}
            type="primary"
          >
            Edit
          </Button>
        );
      },
    },
  ];
  const columnsExport = [
    {
      name: "uid",
      title: "Name",
      getCellValue: (row: any) => globalUsersData[row.uid ?? ""]?.fullName,
    },
    {
      name: "projectId",
      title: "Project Code",
      getCellValue: (row: any) => {
        return globalProjectsData[row.projectId ?? ""]?.projectCode;
      },
    },
    {
      name: "budgetLineId",
      title: "Budget Line",
      getCellValue: (row: any) => {
        return globalBudgetLinesData[row.budgetLineId ?? ""]?.budgetLine;
      },
    },
    { name: "percentage", title: "Allocation %", getCellValue: (row: any) => row.percentage + "%" },
    {
      name: "idaAllocationPercentage",
      title: "% of All",
      getCellValue: (row: any) => row.idaAllocationPercentage + "%",
    },

    {
      name: "startDate",
      title: "Start Date",
      getCellValue: (row: any) => moment(row.startDate).format("DD.MM.YYYY"),
    },
    {
      name: "endDate",
      title: "End Date",
      getCellValue: (row: any) => moment(row.endDate).format("DD.MM.YYYY"),
    },
  ];

  return error ? (
    <p>{error}</p>
  ) : (
    <div>
      <AddNewAllocation modalState={modalState} setModalState={setModalState} />
      {modalStateEdit ? (
        <EditAllocation modalState={modalStateEdit} setModalState={setModalStateEdit} allocation={allocation} />
      ) : null}
      <Button style={{ marginBottom: "1%" }} type={"primary"} onClick={() => setModalState(true)}>
        {t("general.addNewAllocation")}
      </Button>
      <Select
        filterOption={(inputValue, option) => {
          return (option.props.children as any).toLowerCase().indexOf(inputValue.toLowerCase()) >= 0;
        }}
        size="default"
        placeholder="Search for Office!"
        style={{ width: "25%", paddingBottom: "0.5%", margin: "1%" }}
        showSearch
        value={officeId}
        onChange={(e) => {
          setOfficeId(e);
          dispatch(setFilterValue({ key: "officeId", value: e }));
        }}
      >
        {OFFICES_ID_NAME.map((d) => {
          return <Option value={d.officeId}>{d.officeName}</Option>;
        })}
      </Select>
      <Paper>
        <DevTable
          data={data}
          columns={columns}
          defaultHiddenColumnNames={[]}
          tableName="Allocations"
          selected={false}
          typicalTable={false}
          columnsExport={columnsExport}
        />
      </Paper>
    </div>
  );
}
