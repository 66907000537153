import React, { useEffect, useState } from "react";
import Paper from "@material-ui/core/Paper";
import { Spin, Button, Popconfirm } from "antd";
import { useTranslation } from "react-i18next";
import DevTable from "../../helpers/devex-table";
import { globalUsersData } from "../../global/usersList";
import AddNewDepartment from "./addNewDepartmentDialog";
import EditDepartment from "./editDepartmentDialog";
import { globalDepartmentsData } from "../../global/departmentList";
import { useAppDispatch, useAppSelector } from "../../hooks/reduxHooks";
import {
  deleteDepartment,
  fetchAllDepartments,
  subscribeToDepartments,
} from "../../features/departments/departmentsSlice";
import { department } from "../../db/interfaces";

export default function DepartmentsTable() {
  const { data: departmentsData, status } = useAppSelector((state) => state.departments);
  const dispatch = useAppDispatch();

  const { t } = useTranslation();
  const [modalState, setModalState] = useState(false);
  const [modalStateEdit, setModalStateEdit] = useState(false);
  const [departmentId, setDepartmentId] = useState("");
  const [department, setDepartment] = useState({} as department);

  useEffect(() => {
    dispatch(fetchAllDepartments());

    const unsubscribe = subscribeToDepartments(dispatch);
    return () => {
      unsubscribe();
    };
  }, [dispatch]);

  if (status == "loading") return <Spin />;

  const data = departmentsData;

  const columns = [
    {
      name: "departmentName",
      title: "Department Name",
    },
    {
      name: "shortcut",
      title: "Shortcut",
    },
    {
      name: "HOD",
      title: "HOD",
      getCellValue: (row: any) => globalUsersData[row.HOD]?.firstName + " " + globalUsersData[row.HOD]?.lastName,
    },

    {
      name: "referenceDepartment",
      title: "Parent Department",
      getCellValue: (row: any) => globalDepartmentsData[row.referenceDepartment]?.departmentName,
    },
    {
      name: "by",
      title: "Raised By",
      getCellValue: (row: any) => globalUsersData[row.by]?.firstName + " " + globalUsersData[row.by]?.lastName,
    },
    {
      name: "actions",
      title: "Actions",
      getCellValue: (row: any) => {
        return (
          <Popconfirm
            title={t("general.areUSure")}
            onConfirm={() => {
              dispatch(deleteDepartment(row.id));
            }}
            onCancel={() => console.log("cancel")}
            okText="Yes"
            cancelText="No"
          >
            <Button type="link">Delete</Button>
          </Popconfirm>
        );
      },
    },

    {
      name: "edit",
      title: "Edit",
      getCellValue: (row: any) => {
        return (
          <Button
            onClick={() => {
              setModalStateEdit(true);
              setDepartmentId(row.id);
              setDepartment(row);
            }}
            type="primary"
          >
            Edit
          </Button>
        );
      },
    },
  ];
  const columnsExport = [
    {
      name: "departmentName",
      title: "Department Name",
    },
    {
      name: "shortcut",
      title: "Shortcut",
    },
    {
      name: "HOD",
      title: "HOD",
      getCellValue: (row: any) => globalUsersData[row.HOD]?.firstName + " " + globalUsersData[row.HOD]?.lastName,
    },
    {
      name: "by",
      title: "Raised By",
      getCellValue: (row: any) => globalUsersData[row.by]?.firstName + " " + globalUsersData[row.by]?.lastName,
    },

    {
      name: "referenceDepartment",
      title: "Parent Department",
      getCellValue: (row: any) => globalDepartmentsData[row.referenceDepartment]?.departmentName,
    },
  ];

  return (
    <div>
      <AddNewDepartment modalState={modalState} setModalState={setModalState} />
      {modalStateEdit ? (
        <EditDepartment
          modalState={modalStateEdit}
          setModalState={setModalStateEdit}
          departmentId={departmentId}
          department={department}
        />
      ) : null}
      <Button style={{ marginBottom: "1%" }} type={"primary"} onClick={() => setModalState(true)}>
        {t("general.addNewDepartment")}
      </Button>
      <Paper>
        <DevTable
          data={data}
          columns={columns}
          defaultHiddenColumnNames={[]}
          tableName="deduction"
          selected={false}
          typicalTable={false}
          columnsExport={columnsExport}
        />
      </Paper>
    </div>
  );
}
