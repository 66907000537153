import React, { useEffect, useState } from "react";
import {
  Button,
  Drawer,
  Form,
  InputNumber,
  message,
  Select,
  Input,
  Descriptions,
  DatePicker,
  Radio,
  Row,
  Checkbox,
  TimePicker,
  Alert,
} from "antd";
const { Option } = Select;
import { useFormik } from "formik";
import * as Yup from "yup";
import { useTranslation } from "react-i18next";
import moment from "moment";
import { globalUsersData, statics, USERS_ID_FULL_NAME } from "../../global/usersList";
import { ALL_OFFICES_ID_NAME, globalOfficesData } from "../../global/officeList";
import { useAppDispatch, useAppSelector } from "../../hooks/reduxHooks";
import { addUser, fetchStatics } from "../../features/users/usersSlice";
import { users } from "../../db/interfaces";

interface Props {
  modalState: boolean;
  setModalState: React.Dispatch<React.SetStateAction<boolean>>;
}
const projectSchema = Yup.object().shape({
  password: Yup.string().required().min(6).max(15),
  firstName: Yup.string().required(),
  lastName: Yup.string().required(),
  office: Yup.string().required(),
  gender: Yup.string().required(),
  startingDate: Yup.date().required(),
  endingDate: Yup.date().required(),
  currency: Yup.string().required(),
  position: Yup.string().required(),
  region: Yup.string().required(),
  arabicFullName: Yup.string().required(),
  salary: Yup.number().min(1).required(),
  managerUID: Yup.string().required(),
  employmentType: Yup.string().required(),
  vacationAnnualScore: Yup.number().min(0).required(),
  email: Yup.string().email("Invalid email").required(),
});

function NewUserByUsername(props: Props) {
  const { currentUser } = useAppSelector((state) => state.auth);
  const dispatch = useAppDispatch();

  const { t } = useTranslation();
  const { modalState, setModalState } = props;
  const [loading, setLoading] = useState(false);
  const [partTimeSchedule, setPartTimeSchedule] = useState({
    saturday: { status: false, start: moment(), end: moment() },
    sunday: { status: false, start: moment(), end: moment() },
    monday: { status: false, start: moment(), end: moment() },
    tuesday: { status: false, start: moment(), end: moment() },
    wednesday: { status: false, start: moment(), end: moment() },
    thursday: { status: false, start: moment(), end: moment() },
    friday: { status: false, start: moment(), end: moment() },
  });

  const staticResourceData = statics;
  const formik = useFormik({
    initialValues: {
      pay_no: "EMP",
      fatherName: "",
      motherName: "",
      salary: 0,
      currency: "",
      startingDate: moment(),
      endingDate: moment(),
      email: "",
      password: "ida12345",
      firstName: "",
      lastName: "",
      gender: "",
      office: "",
      arabicFullName: "",
      dateOfBirth: moment(),
      placeOfBirth: "",
      permanentAddress: "",
      currentAddress: "",
      phoneNumber: "",
      identityNo: "",
      emergencyContact1: "",
      emergencyContact2: "",
      bankAccountName: "",
      bankName: "",
      IBAN: "",
      bankAccountNumber: "",
      bankFirstName: "",
      bankLastName: "",
      customerNumber: "",
      managerUID: "",
      position: "",
      department: "",
      vacationAnnualScore: 1.167,
      vacationCompensationScore: 0,
      vacationSickScore: 3,
      vacationDeathScore: 3,
      vacationMarriageScore: 3,
      vacationMaternityScore: 0,
      vacationPaternityScore: 0,
      region: "",
      employmentType: "fullTime",
      idaAllocationPercentage: 0,
      paymentType: "transfer",
    },
    validationSchema: projectSchema,
    onSubmit: (values, form) => {
      createByUsername(values, form);
    },
  });
  useEffect(() => {
    dispatch(fetchStatics());
  }, [modalState]);
  const createByUsername = async (values: any, form: { resetForm: () => void }) => {
    const uid =
      values.region == "syria"
        ? `S${("00000" + (staticResourceData.syria + 1).toString()).slice(-5)}`
        : `T${("00000" + (staticResourceData.turkey + 1).toString()).slice(-5)}`;

    const newUser = {
      pay_no: values.fatherName,
      id: uid,
      uid: uid,
      fatherName: values.fatherName,
      motherName: values.motherName,
      salary: values.salary,
      currency: values.currency,
      startingDate: values.startingDate as any,
      endingDate: values.endingDate as any,
      email: values.email,
      password: values.password,
      firstName: values.firstName,
      lastName: values.lastName,
      gender: values.gender,
      arabicFullName: values.arabicFullName,
      dateOfBirth: values.dateOfBirth as any,
      placeOfBirth: values.placeOfBirth,
      permanentAddress: values.permanentAddress,
      currentAddress: values.currentAddress,
      phoneNumber: values.phoneNumber,
      identityNo: values.identityNo,
      emergencyContact1: values.emergencyContact1,
      emergencyContact2: values.emergencyContact2,
      bankAccountName: values.bankAccountName,
      bankName: values.bankName,
      IBAN: values.IBAN,
      bankAccountNumber: values.bankAccountNumber,
      bankFirstName: values.bankFirstName,
      bankLastName: values.bankLastName,
      customerNumber: values.customerNumber,
      vacationCompensationScore: values.vacationCompensationScore,
      vacationDeathScore: values.vacationDeathScore,
      vacationMarriageScore: values.vacationMarriageScore,
      vacationMaternityScore:
        values.gender == "female" && values.vacationMaternityScore == 0 ? 112 : values.vacationMaternityScore,
      vacationPaternityScore:
        values.gender == "male" && values.vacationPaternityScore == 0 ? 2 : values.vacationPaternityScore,
      managerUID: values.managerUID,
      office: values.office,
      position: values.position,
      department: values.department,
      vacationAnnualScore: values.vacationAnnualScore,
      vacationSickScore: values.vacationSickScore,
      status: "active",
      admin: false,
      adminHR: false,
      adminLaw: false,
      CEO: false,
      resourceNO: uid,
      region: values.region,
      idaAllocationPercentage: values.idaAllocationPercentage ?? 100,
      employmentType: values.employmentType,
      partTimeSchedule: {
        saturday: {
          ...partTimeSchedule.saturday,
          start: moment(partTimeSchedule.saturday.start),
          end: moment(partTimeSchedule.saturday.end),
        },
        sunday: {
          ...partTimeSchedule.sunday,
          start: moment(partTimeSchedule.sunday.start),
          end: moment(partTimeSchedule.sunday.end),
        },
        monday: {
          ...partTimeSchedule.monday,
          start: moment(partTimeSchedule.monday.start),
          end: moment(partTimeSchedule.monday.end),
        },
        tuesday: {
          ...partTimeSchedule.tuesday,
          start: moment(partTimeSchedule.tuesday.start),
          end: moment(partTimeSchedule.tuesday.end),
        },
        wednesday: {
          ...partTimeSchedule.wednesday,
          start: moment(partTimeSchedule.wednesday.start),
          end: moment(partTimeSchedule.wednesday.end),
        },
        thursday: {
          ...partTimeSchedule.thursday,
          start: moment(partTimeSchedule.thursday.start),
          end: moment(partTimeSchedule.thursday.end),
        },
        friday: {
          ...partTimeSchedule.friday,
          start: moment(partTimeSchedule.friday.start),
          end: moment(partTimeSchedule.friday.end),
        },
      },
      paymentType: values.paymentType,
      signInType: "byUsername",
      vacationAbility: true,
      FPId: "",
      signatureURL: "",
      token: [],
      mobileToken: [],
      personalPhoto: "",
      firestore_id: uid,
      username: uid,
      viewers: [],
      viewersOffices: [],
      adminOffices: [],
      HROffices: [],
      HRProjectsManagersOffices: [],
      by: currentUser?.id ?? "",
    } as users | any;

    try {
      await dispatch(addUser(newUser as users)).unwrap();
      message.success("Added Employee Successfully!");
      form.resetForm();
      setModalState(false);
      setLoading(false);
    } catch (error) {
      setLoading(false);
      console.error("Error writing document: ", error);
      message.error(error as any);
    }
  };

  const enterLoading = () => {
    setLoading(true);
  };
  const dateFormat = "YYYY/MM/DD";
  const format = "HH:mm";

  const isIDAlreadyExist = globalUsersData[`S${("00000" + (staticResourceData?.syria + 1).toString()).slice(-5)}`];
  return (
    <Drawer
      title={`New User by username- ${`S${("00000" + (staticResourceData?.syria + 1).toString()).slice(-5)}`}`}
      placement={"left"}
      width={1000}
      visible={modalState}
      onClose={() => {
        setModalState(false);
        formik.resetForm();
      }}
    >
      <Form onSubmit={formik.handleSubmit}>
        <div style={{ marginBottom: "2%" }}>
          {isIDAlreadyExist ? (
            <Alert
              message={`Same ID already exist with name: ${
                globalUsersData[`S${("00000" + (staticResourceData?.syria + 1).toString()).slice(-5)}`]?.fullName
              } `}
              type="error"
            />
          ) : (
            <Alert
              message={`New user ID: ${`S${("00000" + (staticResourceData?.syria + 1).toString()).slice(
                -5
              )}`} for Syria`}
              type="success"
            />
          )}
        </div>
        <div style={{ marginBottom: "2%" }}>
          {isIDAlreadyExist ? (
            <Alert
              message={`Same ID already exist with name: ${
                globalUsersData[`T${("00000" + (staticResourceData?.turkey + 1).toString()).slice(-5)}`]?.fullName
              }`}
              type="error"
            />
          ) : (
            <Alert
              message={`New user ID: ${`T${("00000" + (staticResourceData?.turkey + 1).toString()).slice(
                -5
              )} for Turkey`}`}
              type="info"
            />
          )}
        </div>
        <div>
          <div>
            <Descriptions
              size={"small"}
              layout="horizontal"
              bordered
              column={{ xxl: 6, xl: 4, lg: 3, md: 3, sm: 2, xs: 1 }}
            >
              <Descriptions.Item label={t("general.firstName")} span={3}>
                <Form.Item validateStatus={formik.errors.firstName ? "error" : ""}>
                  <Input
                    value={formik.values.firstName}
                    onChange={(e) => formik.setFieldValue("firstName", e.target.value)}
                  />
                </Form.Item>
              </Descriptions.Item>
              <Descriptions.Item label={t("general.lastName")} span={3}>
                <Form.Item validateStatus={formik.errors.lastName ? "error" : ""}>
                  <Input
                    value={formik.values.lastName}
                    onChange={(e) => formik.setFieldValue("lastName", e.target.value)}
                  />
                </Form.Item>
              </Descriptions.Item>
              <Descriptions.Item label={t("general.identityNo")} span={3}>
                <Input
                  type="text"
                  id="identityNo"
                  value={formik.values.identityNo}
                  onChange={(e) => formik.setFieldValue("identityNo", e.target.value)}
                />
              </Descriptions.Item>

              <Descriptions.Item label={t("general.arabicFullName")} span={3}>
                <Form.Item validateStatus={formik.errors.arabicFullName ? "error" : ""}>
                  <Input
                    type="text"
                    id="arabicFullName"
                    value={formik.values.arabicFullName}
                    onChange={(e) => formik.setFieldValue("arabicFullName", e.target.value)}
                  />
                </Form.Item>
              </Descriptions.Item>

              <Descriptions.Item label={t("general.dateOfBirth")} span={3}>
                <Form.Item validateStatus={formik.errors.dateOfBirth ? "error" : ""} hasFeedback>
                  <DatePicker
                    value={formik.values.dateOfBirth}
                    format={dateFormat}
                    onChange={(e: any) => {
                      formik.setFieldValue("dateOfBirth", e);
                    }}
                  />
                </Form.Item>
              </Descriptions.Item>
              <Descriptions.Item label={t("general.placeOfBirth")} span={3}>
                <Input
                  type="text"
                  id="placeOfBirth"
                  value={formik.values.placeOfBirth}
                  onChange={(e) => formik.setFieldValue("placeOfBirth", e.target.value)}
                />
              </Descriptions.Item>
              <Descriptions.Item label={t("general.permanentAddress")} span={6}>
                <Input
                  type="text"
                  id="permanentAddress"
                  value={formik.values.permanentAddress}
                  onChange={(e) => formik.setFieldValue("permanentAddress", e.target.value)}
                />
              </Descriptions.Item>
              <Descriptions.Item label={t("general.currentAddress")} span={6}>
                <Form.Item validateStatus={formik.errors.currentAddress ? "error" : ""}>
                  <Input
                    type="text"
                    id="currentAddress"
                    value={formik.values.currentAddress}
                    onChange={(e) => formik.setFieldValue("currentAddress", e.target.value)}
                  />
                </Form.Item>
              </Descriptions.Item>
              <Descriptions.Item label={t("general.gender")} span={3}>
                <Form.Item validateStatus={formik.errors.gender ? "error" : ""}>
                  <Select
                    id="gender"
                    showSearch
                    value={formik.values.gender}
                    onChange={(e) => {
                      formik.setFieldValue("gender", e);
                    }}
                  >
                    <Option value="male">Male</Option>
                    <Option value="female">Female</Option>
                  </Select>
                </Form.Item>
              </Descriptions.Item>
              <Descriptions.Item label={t("general.fatherName")} span={3}>
                <Form.Item validateStatus={formik.errors.fatherName ? "error" : ""}>
                  <Input
                    type="text"
                    id="fatherName"
                    value={formik.values.fatherName}
                    onChange={(e) => formik.setFieldValue("fatherName", e.target.value)}
                  />
                </Form.Item>
              </Descriptions.Item>
              <Descriptions.Item label={t("general.motherName")} span={3}>
                <Form.Item validateStatus={formik.errors.motherName ? "error" : ""}>
                  <Input
                    type="text"
                    id="motherName"
                    value={formik.values.motherName}
                    onChange={(e) => formik.setFieldValue("motherName", e.target.value)}
                  />
                </Form.Item>
              </Descriptions.Item>

              <Descriptions.Item label={t("general.phoneNumber")} span={3}>
                <Form.Item validateStatus={formik.errors.phoneNumber ? "error" : ""}>
                  <Input
                    type="text"
                    id="phoneNumber"
                    value={formik.values.phoneNumber}
                    onChange={(e) => formik.setFieldValue("phoneNumber", e.target.value)}
                  />
                </Form.Item>
              </Descriptions.Item>
              <Descriptions.Item label={t("general.emergencyContact1")} span={3}>
                <Input
                  type="text"
                  id="emergencyContact1"
                  value={formik.values.emergencyContact1}
                  onChange={(e) => formik.setFieldValue("emergencyContact1", e.target.value)}
                />
              </Descriptions.Item>
              <Descriptions.Item label={t("general.emergencyContact2")} span={3}>
                <Input
                  type="text"
                  id="emergencyContact2"
                  value={formik.values.emergencyContact2}
                  onChange={(e) => formik.setFieldValue("emergencyContact2", e.target.value)}
                />
              </Descriptions.Item>
              <Descriptions.Item label={t("general.startingDate")} span={3}>
                <Form.Item validateStatus={formik.errors.startingDate ? "error" : ""}>
                  <DatePicker
                    value={formik.values.startingDate}
                    format={dateFormat}
                    onChange={(e: any) => {
                      formik.setFieldValue("startingDate", e);
                    }}
                  />
                </Form.Item>
              </Descriptions.Item>
              <Descriptions.Item label={t("general.endingDate")} span={3}>
                <Form.Item validateStatus={formik.errors.endingDate ? "error" : ""}>
                  <DatePicker
                    value={formik.values.endingDate}
                    format={dateFormat}
                    onChange={(e: any) => {
                      formik.setFieldValue("endingDate", e);
                    }}
                  />
                </Form.Item>
              </Descriptions.Item>
              <Descriptions.Item label={t("general.salary")} span={3}>
                <Form.Item validateStatus={formik.errors.salary ? "error" : ""}>
                  <InputNumber
                    min={0}
                    value={formik.values.salary}
                    onChange={(e) => formik.setFieldValue("salary", e)}
                  />
                </Form.Item>
              </Descriptions.Item>
              <Descriptions.Item label={t("general.currency")} span={3}>
                <Form.Item validateStatus={formik.errors.currency ? "error" : ""}>
                  <Select
                    showSearch
                    value={formik.values.currency}
                    onChange={(e) => formik.setFieldValue("currency", e)}
                  >
                    <Option value={"USD"}>USD</Option>
                    <Option value={"EURO"}>EURO</Option>
                    <Option value={"TL"}>TL</Option>
                  </Select>
                </Form.Item>
              </Descriptions.Item>
              <Descriptions.Item label={t("general.bankAccountName")} span={3}>
                <Input
                  type="text"
                  id="bankAccountName"
                  value={formik.values.bankAccountName}
                  onChange={(e) => formik.setFieldValue("bankAccountName", e.target.value)}
                />
              </Descriptions.Item>

              <Descriptions.Item label={t("general.bankFirstName")} span={3}>
                <Input
                  type="text"
                  id="bankFirstName"
                  value={formik.values.bankFirstName}
                  onChange={(e) => formik.setFieldValue("bankFirstName", e.target.value)}
                />
              </Descriptions.Item>
              <Descriptions.Item label={t("general.bankLastName")} span={3}>
                <Input
                  type="text"
                  id="bankLastName"
                  value={formik.values.bankLastName}
                  onChange={(e) => formik.setFieldValue("bankLastName", e.target.value)}
                />
              </Descriptions.Item>

              <Descriptions.Item label={t("general.bankName")} span={3}>
                <Input
                  type="text"
                  id="bankName"
                  value={formik.values.bankName}
                  onChange={(e) => formik.setFieldValue("bankName", e.target.value)}
                />
              </Descriptions.Item>
              <Descriptions.Item label={t("general.IBAN")} span={3}>
                <Input
                  type="text"
                  id="IBAN"
                  value={formik.values.IBAN}
                  onChange={(e) => formik.setFieldValue("IBAN", e.target.value)}
                />
              </Descriptions.Item>
              <Descriptions.Item label={t("general.bankAccountNumber")} span={3}>
                <Input
                  type="text"
                  id="bankAccountNumber"
                  value={formik.values.bankAccountNumber}
                  onChange={(e) => formik.setFieldValue("bankAccountNumber", e.target.value)}
                />
              </Descriptions.Item>
              <Descriptions.Item label={t("general.customerNumber")} span={3}>
                <Input
                  type="text"
                  id="customerNumber"
                  value={formik.values.customerNumber}
                  onChange={(e) => formik.setFieldValue("customerNumber", e.target.value)}
                />
              </Descriptions.Item>

              <Descriptions.Item label={t("general.supervisor")} span={3}>
                <Form.Item validateStatus={formik.errors.managerUID ? "error" : ""}>
                  <Select
                    filterOption={(inputValue, option) => {
                      return (option.props.children as any).toLowerCase().indexOf(inputValue.toLowerCase()) >= 0;
                    }}
                    showSearch
                    value={formik.values.managerUID}
                    onChange={(e) => {
                      formik.setFieldValue("managerUID", e);
                    }}
                  >
                    {USERS_ID_FULL_NAME?.map((d) => {
                      return <Option value={d.uid}>{d.fullName}</Option>;
                    })}
                  </Select>
                </Form.Item>
              </Descriptions.Item>
              <Descriptions.Item label={t("general.office")} span={3}>
                <Form.Item validateStatus={formik.errors.office ? "error" : ""}>
                  <Select
                    filterOption={(inputValue, option) => {
                      return (option.props.children as any).toLowerCase().indexOf(inputValue.toLowerCase()) >= 0;
                    }}
                    showSearch
                    value={formik.values.office}
                    onChange={(e) => {
                      formik.setFieldValue("office", e);
                      const officeData = globalOfficesData[e ?? ""];
                      setPartTimeSchedule({
                        saturday: {
                          status: false,
                          start: moment(officeData.workStartTime),
                          end: moment(officeData.workEndTime),
                        },
                        sunday: {
                          status: false,
                          start: moment(officeData.workStartTime),
                          end: moment(officeData.workEndTime),
                        },
                        monday: {
                          status: false,
                          start: moment(officeData.workStartTime),
                          end: moment(officeData.workEndTime),
                        },
                        tuesday: {
                          status: false,
                          start: moment(officeData.workStartTime),
                          end: moment(officeData.workEndTime),
                        },
                        wednesday: {
                          status: false,
                          start: moment(officeData.workStartTime),
                          end: moment(officeData.workEndTime),
                        },
                        thursday: {
                          status: false,
                          start: moment(officeData.workStartTime),
                          end: moment(officeData.workEndTime),
                        },
                        friday: {
                          status: false,
                          start: moment(officeData.workStartTime),
                          end: moment(officeData.workEndTime),
                        },
                      });
                    }}
                  >
                    {ALL_OFFICES_ID_NAME?.map((d) => {
                      return <Option value={d.officeId}>{d.officeName}</Option>;
                    })}
                  </Select>
                </Form.Item>
              </Descriptions.Item>
              <Descriptions.Item label={t("general.position")} span={3}>
                <Form.Item validateStatus={formik.errors.position ? "error" : ""}>
                  <Input
                    value={formik.values.position}
                    onChange={(e) => formik.setFieldValue("position", e.target.value)}
                  />
                </Form.Item>
              </Descriptions.Item>
              <Descriptions.Item label={t("general.department")} span={3}>
                <Input
                  value={formik.values.department}
                  onChange={(e) => formik.setFieldValue("department", e.target.value)}
                />
              </Descriptions.Item>
              <Descriptions.Item label={t("general.email")} span={3}>
                <Form.Item validateStatus={formik.errors.email ? "error" : ""}>
                  <Input value={formik.values.email} onChange={(e) => formik.setFieldValue("email", e.target.value)} />
                  <h5>{formik.errors.email}</h5>
                </Form.Item>
              </Descriptions.Item>
              <Descriptions.Item label={t("general.password")} span={3}>
                <Form.Item validateStatus={formik.errors.password ? "error" : ""}>
                  <Input
                    disabled
                    value={formik.values.password}
                    onChange={(e) => formik.setFieldValue("password", e.target.value)}
                  />
                  <h5>{formik.errors.password}</h5>
                </Form.Item>
              </Descriptions.Item>
              <Descriptions.Item label={t("general.vacationAnnualScore")} span={3}>
                <InputNumber
                  min={0}
                  value={formik.values.vacationAnnualScore}
                  onChange={(e) => formik.setFieldValue("vacationAnnualScore", e)}
                />
              </Descriptions.Item>
              <Descriptions.Item label={t("general.vacationSickScore")} span={3}>
                <InputNumber
                  min={0}
                  value={formik.values.vacationSickScore}
                  onChange={(e) => formik.setFieldValue("vacationSickScore", e)}
                />
              </Descriptions.Item>
              <Descriptions.Item label={t("general.vacationCompensationScore")} span={3}>
                <InputNumber
                  min={0}
                  value={formik.values.vacationCompensationScore}
                  onChange={(e) => formik.setFieldValue("vacationCompensationScore", e)}
                />
              </Descriptions.Item>
              <Descriptions.Item label={t("general.vacationDeathScore")} span={3}>
                <InputNumber
                  min={0}
                  value={formik.values.vacationDeathScore}
                  onChange={(e) => formik.setFieldValue("vacationDeathScore", e)}
                />
              </Descriptions.Item>
              <Descriptions.Item label={t("general.vacationMarriageScore")} span={3}>
                <InputNumber
                  min={0}
                  value={formik.values.vacationMarriageScore}
                  onChange={(e) => formik.setFieldValue("vacationMarriageScore", e)}
                />
              </Descriptions.Item>
              <Descriptions.Item label={t("general.vacationMaternityScore")} span={3}>
                <InputNumber
                  min={0}
                  value={formik.values.vacationMaternityScore}
                  onChange={(e) => formik.setFieldValue("vacationMaternityScore", e)}
                />
              </Descriptions.Item>
              <Descriptions.Item label={t("general.vacationPaternityScore")} span={3}>
                <InputNumber
                  min={0}
                  value={formik.values.vacationPaternityScore}
                  onChange={(e) => formik.setFieldValue("vacationPaternityScore", e)}
                />
              </Descriptions.Item>

              <Descriptions.Item label={t("general.region")} span={3}>
                <Form.Item validateStatus={formik.errors.region ? "error" : ""}>
                  <Select
                    id="region"
                    showSearch
                    value={formik.values.region}
                    onChange={(e) => {
                      formik.setFieldValue("region", e);
                    }}
                  >
                    <Option value="syria">Syria</Option>
                    <Option value="turkey">Turkey</Option>
                  </Select>
                </Form.Item>
              </Descriptions.Item>
              <Descriptions.Item label={t("general.paymentType")} span={3}>
                <Form.Item validateStatus={formik.errors.paymentType ? "error" : ""}>
                  <Select
                    id="region"
                    showSearch
                    value={formik.values.paymentType}
                    onChange={(e) => {
                      formik.setFieldValue("paymentType", e);
                    }}
                  >
                    <Option value="cash">Cash</Option>
                    <Option value="transfer">Transfer</Option>
                  </Select>
                </Form.Item>
              </Descriptions.Item>
              <Descriptions.Item label={t("general.idaAllocationPercentage")} span={3}>
                <Form.Item validateStatus={formik.errors.idaAllocationPercentage ? "error" : ""}>
                  <InputNumber
                    min={0}
                    value={formik.values.idaAllocationPercentage}
                    onChange={(e) => formik.setFieldValue("idaAllocationPercentage", e)}
                  />
                </Form.Item>
              </Descriptions.Item>
              <Descriptions.Item label={"Payroll-NO"} span={3}>
                <Form.Item validateStatus={formik.errors.pay_no ? "error" : ""}>
                  <Input
                    value={formik.values.pay_no}
                    onChange={(e) => formik.setFieldValue("pay_no", e.target.value)}
                  />
                </Form.Item>
              </Descriptions.Item>
              <Descriptions.Item label={t("general.employmentType")} span={6}>
                <Form.Item validateStatus={formik.errors.employmentType ? "error" : ""}>
                  <Radio.Group
                    onChange={(e) => {
                      formik.setFieldValue("employmentType", e.target.value);
                    }}
                    value={formik.values.employmentType}
                  >
                    <Radio value={"fullTime"}>Full-Time</Radio>
                    <Radio value={"partTime"}>Part-Time</Radio>
                    <Radio value={"shift"}>Shift</Radio>
                  </Radio.Group>
                </Form.Item>
              </Descriptions.Item>
              {formik.values.employmentType == "partTime" ? (
                <Descriptions.Item label={t("general.partTimeSchedule")} span={6}>
                  <Form.Item>
                    <Row>
                      <Checkbox
                        checked={partTimeSchedule.saturday.status}
                        onChange={(e) => {
                          setPartTimeSchedule({
                            ...partTimeSchedule,
                            saturday: { ...partTimeSchedule.saturday, status: e.target.checked },
                          });
                        }}
                      >
                        Saturday
                      </Checkbox>
                      <span>
                        {"- Start: "}
                        <TimePicker
                          allowClear={false}
                          format={format}
                          onChange={(e) =>
                            setPartTimeSchedule({
                              ...partTimeSchedule,
                              saturday: { ...partTimeSchedule.saturday, start: moment(e) as any },
                            })
                          }
                          value={partTimeSchedule?.saturday?.start}
                        />
                      </span>
                      <span> {"End: "}</span>
                      <TimePicker
                        allowClear={false}
                        format={format}
                        onChange={(e) =>
                          setPartTimeSchedule({
                            ...partTimeSchedule,
                            saturday: { ...partTimeSchedule.saturday, end: moment(e).toDate() as any },
                          })
                        }
                        value={moment(partTimeSchedule.saturday.end)}
                      />
                    </Row>
                    <Row>
                      <Checkbox
                        checked={partTimeSchedule.sunday.status}
                        onChange={(e) => {
                          setPartTimeSchedule({
                            ...partTimeSchedule,
                            sunday: { ...partTimeSchedule.sunday, status: e.target.checked },
                          });
                        }}
                      >
                        Sunday
                      </Checkbox>
                      <span>
                        {"- Start: "}
                        <TimePicker
                          allowClear={false}
                          format={format}
                          onChange={(e) =>
                            setPartTimeSchedule({
                              ...partTimeSchedule,
                              sunday: { ...partTimeSchedule.sunday, start: moment(e).toDate() as any },
                            })
                          }
                          value={moment(partTimeSchedule.sunday.start)}
                        />
                      </span>
                      <span> {"End: "}</span>
                      <TimePicker
                        allowClear={false}
                        format={format}
                        onChange={(e) =>
                          setPartTimeSchedule({
                            ...partTimeSchedule,
                            sunday: { ...partTimeSchedule.sunday, end: moment(e).toDate() as any },
                          })
                        }
                        value={moment(partTimeSchedule.sunday.end)}
                      />
                    </Row>
                    <Row>
                      <Checkbox
                        checked={partTimeSchedule.monday.status}
                        onChange={(e) => {
                          setPartTimeSchedule({
                            ...partTimeSchedule,
                            monday: { ...partTimeSchedule.monday, status: e.target.checked },
                          });
                        }}
                      >
                        Monday
                      </Checkbox>
                      <span>
                        {"- Start: "}
                        <TimePicker
                          allowClear={false}
                          format={format}
                          onChange={(e) =>
                            setPartTimeSchedule({
                              ...partTimeSchedule,
                              monday: { ...partTimeSchedule.monday, start: moment(e).toDate() as any },
                            })
                          }
                          value={moment(partTimeSchedule.monday.start)}
                        />
                      </span>
                      <span> {"End: "}</span>
                      <TimePicker
                        allowClear={false}
                        format={format}
                        onChange={(e) =>
                          setPartTimeSchedule({
                            ...partTimeSchedule,
                            monday: { ...partTimeSchedule.monday, end: moment(e).toDate() as any },
                          })
                        }
                        value={moment(partTimeSchedule.monday.end)}
                      />
                    </Row>
                    <Row>
                      <Checkbox
                        checked={partTimeSchedule.tuesday.status}
                        onChange={(e) => {
                          setPartTimeSchedule({
                            ...partTimeSchedule,
                            tuesday: { ...partTimeSchedule.tuesday, status: e.target.checked },
                          });
                        }}
                      >
                        Tuesday
                      </Checkbox>
                      <span>
                        {"- Start: "}
                        <TimePicker
                          allowClear={false}
                          format={format}
                          onChange={(e) =>
                            setPartTimeSchedule({
                              ...partTimeSchedule,
                              tuesday: { ...partTimeSchedule.tuesday, start: moment(e).toDate() as any },
                            })
                          }
                          value={moment(partTimeSchedule.tuesday.start)}
                        />
                      </span>
                      <span> {"End: "}</span>
                      <TimePicker
                        allowClear={false}
                        format={format}
                        onChange={(e) =>
                          setPartTimeSchedule({
                            ...partTimeSchedule,
                            tuesday: { ...partTimeSchedule.tuesday, end: moment(e).toDate() as any },
                          })
                        }
                        value={moment(partTimeSchedule.tuesday.end)}
                      />
                    </Row>
                    <Row>
                      <Checkbox
                        checked={partTimeSchedule.wednesday.status}
                        onChange={(e) => {
                          setPartTimeSchedule({
                            ...partTimeSchedule,
                            wednesday: { ...partTimeSchedule.wednesday, status: e.target.checked },
                          });
                        }}
                      >
                        Wednesday
                      </Checkbox>
                      <span>
                        {"- Start: "}
                        <TimePicker
                          allowClear={false}
                          format={format}
                          onChange={(e) =>
                            setPartTimeSchedule({
                              ...partTimeSchedule,
                              wednesday: { ...partTimeSchedule.wednesday, start: moment(e).toDate() as any },
                            })
                          }
                          value={moment(partTimeSchedule.wednesday.start)}
                        />
                      </span>
                      <span> {"End: "}</span>
                      <TimePicker
                        allowClear={false}
                        format={format}
                        onChange={(e) =>
                          setPartTimeSchedule({
                            ...partTimeSchedule,
                            wednesday: { ...partTimeSchedule.wednesday, end: moment(e).toDate() as any },
                          })
                        }
                        value={moment(partTimeSchedule.wednesday.end)}
                      />
                    </Row>
                    <Row>
                      <Checkbox
                        checked={partTimeSchedule.thursday.status}
                        onChange={(e) => {
                          setPartTimeSchedule({
                            ...partTimeSchedule,
                            thursday: { ...partTimeSchedule.thursday, status: e.target.checked },
                          });
                        }}
                      >
                        Thursday
                      </Checkbox>
                      <span>
                        {"- Start: "}
                        <TimePicker
                          allowClear={false}
                          format={format}
                          onChange={(e) =>
                            setPartTimeSchedule({
                              ...partTimeSchedule,
                              thursday: { ...partTimeSchedule.thursday, start: moment(e).toDate() as any },
                            })
                          }
                          value={moment(partTimeSchedule.thursday.start)}
                        />
                      </span>
                      <span> {"End: "}</span>
                      <TimePicker
                        allowClear={false}
                        format={format}
                        onChange={(e) =>
                          setPartTimeSchedule({
                            ...partTimeSchedule,
                            thursday: { ...partTimeSchedule.thursday, end: moment(e).toDate() as any },
                          })
                        }
                        value={moment(partTimeSchedule.thursday.end)}
                      />
                    </Row>
                    <Row>
                      <Checkbox
                        checked={partTimeSchedule.friday.status}
                        onChange={(e) => {
                          setPartTimeSchedule({
                            ...partTimeSchedule,
                            friday: { ...partTimeSchedule.friday, status: e.target.checked },
                          });
                        }}
                      >
                        Friday
                      </Checkbox>
                      <span>
                        {"- Start: "}
                        <TimePicker
                          allowClear={false}
                          format={format}
                          onChange={(e) =>
                            setPartTimeSchedule({
                              ...partTimeSchedule,
                              friday: { ...partTimeSchedule.friday, start: moment(e).toDate() as any },
                            })
                          }
                          value={moment(partTimeSchedule.friday.start)}
                        />
                      </span>
                      <span> {"End: "}</span>
                      <TimePicker
                        allowClear={false}
                        format={format}
                        onChange={(e) =>
                          setPartTimeSchedule({
                            ...partTimeSchedule,
                            friday: { ...partTimeSchedule.friday, end: moment(e).toDate() as any },
                          })
                        }
                        value={moment(partTimeSchedule.friday.end)}
                      />
                    </Row>
                  </Form.Item>
                </Descriptions.Item>
              ) : null}
            </Descriptions>
          </div>
        </div>
        <div>
          <Button
            style={{ margin: "1%" }}
            className="btn blue lighten-1 z-depth-0"
            onClick={() => {
              enterLoading();
            }}
            loading={loading}
            type="primary"
            htmlType="submit"
            disabled={!formik.isValid || (isIDAlreadyExist ? true : false)}
          >
            {t("general.save")}
          </Button>
        </div>
      </Form>
    </Drawer>
  );
}

export default NewUserByUsername;
