import { createSlice, createAsyncThunk, PayloadAction } from "@reduxjs/toolkit";
import { supabase } from "../../supabaseClient"; // Adjust the import path as needed
import { department } from "../../db/interfaces"; // Ensure your department interface is defined here

interface departmentsSliceState {
  data: department[];
  department: department;
  HODDepartments: department[];
  status: "loading" | "succeeded" | "failed" | "idle";
  error: string | null;
  globalDepartmentsData: Record<string, department>;
  loaded: boolean;
}

const initialState: departmentsSliceState = {
  data: [],
  HODDepartments: [],
  department: {} as department,
  status: "idle",
  error: null,
  globalDepartmentsData: {},
  loaded: false,
};

// Fetch all departments in batches
export const fetchAllDepartments = createAsyncThunk(
  "departments/fetchAllDepartments",
  async (_, { rejectWithValue }) => {
    const { data, error } = await supabase.from("departments").select("*");
    if (error) {
      return rejectWithValue(error.message as string);
    }

    return data;
  }
);

// Fetch all departments in batches
export const fetchDepartmentsByHOD = createAsyncThunk<department[], string, { rejectValue: string }>(
  "departments/fetchDepartmentsByHOD",
  async (HOD, { rejectWithValue }) => {
    const { data, error } = await supabase.from("departments").select("*").eq("HOD", HOD);
    if (error) {
      return rejectWithValue(error.message as string);
    }

    return data;
  }
);

// Fetch all departments in batches
export const fetchDepartmentById = createAsyncThunk<department, string, { rejectValue: string }>(
  "departments/fetchDepartmentById",
  async (id, { rejectWithValue }) => {
    const { data, error } = await supabase.from("departments").select("*").eq("id", id).single();
    if (error) {
      return rejectWithValue(error.message as string);
    }

    return data;
  }
);

// Fetch all departments in batches
export const fetchDepartmentsByParentId = createAsyncThunk<department[], string, { rejectValue: string }>(
  "departments/fetchDepartmentsByParentId",
  async (referenceDepartment, { rejectWithValue }) => {
    const { data, error } = await supabase
      .from("departments")
      .select("*")
      .eq("referenceDepartment", referenceDepartment);
    if (error) {
      return rejectWithValue(error.message as string);
    }

    return data;
  }
);

// Add a new department
export const addDepartment = createAsyncThunk<department, department, { rejectValue: string }>(
  "departments/addDepartment",
  async (newDepartment, thunkApi) => {
    const { data: insertData, error: insertError } = await supabase
      .from("departments")
      .insert(newDepartment)
      .select()
      .single();

    if (insertError) {
      return thunkApi.rejectWithValue(insertError.message);
    }

    return insertData as department;
  }
);

// Delete a department
export const deleteDepartment = createAsyncThunk<string, string, { rejectValue: string }>(
  "departments/deleteDepartment",
  async (id, thunkApi) => {
    const { error } = await supabase.from("departments").delete().eq("id", id);

    if (error) {
      return thunkApi.rejectWithValue(error.message);
    }

    return id;
  }
);

// Edit a department
export const editDepartment = createAsyncThunk<department, department, { rejectValue: string }>(
  "departments/editDepartment",
  async (updatedDepartment, thunkApi) => {
    const { data: updateData, error: updateError } = await supabase
      .from("departments")
      .update(updatedDepartment)
      .eq("id", updatedDepartment.id)
      .select()
      .single();

    if (updateError) {
      return thunkApi.rejectWithValue(updateError.message);
    }

    return updateData as department;
  }
);

// Real-time actions
export const addDepartmentRealTime = (newDepartment: department): PayloadAction<department> => ({
  type: "departments/addDepartmentRealTime",
  payload: newDepartment,
});

export const editDepartmentRealTime = (updatedDepartment: department): PayloadAction<department> => ({
  type: "departments/editDepartmentRealTime",
  payload: updatedDepartment,
});

export const deleteDepartmentRealTime = (id: string): PayloadAction<string> => ({
  type: "departments/deleteDepartmentRealTime",
  payload: id,
});

// Slice definition
const departmentsSlice = createSlice({
  name: "departments",
  initialState,
  reducers: {
    addDepartmentRealTime(state, action: PayloadAction<department>) {
      const exists = state.data.some((department) => department.id === action.payload.id);
      if (!exists) {
        state.data.push(action.payload);
      }
      state.error = null;
    },
    editDepartmentRealTime(state, action: PayloadAction<department>) {
      const index = state.data.findIndex((department) => department.id === action.payload.id);
      if (index !== -1) {
        const isDifferent = JSON.stringify(state.data[index]) !== JSON.stringify(action.payload);
        if (isDifferent) {
          state.data[index] = action.payload;
        }
      }
      state.error = null;
    },
    deleteDepartmentRealTime(state, action: PayloadAction<string>) {
      const exists = state.data.some((department) => department.id === action.payload);
      if (exists) {
        state.data = state.data.filter((department) => department.id !== action.payload);
      }
      state.error = null;
    },
  },
  extraReducers: (builder) => {
    builder
      // Handle fetchAllDepartments
      .addCase(fetchAllDepartments.pending, (state) => {
        state.status = "loading";
      })
      .addCase(fetchAllDepartments.fulfilled, (state, action) => {
        state.loaded = true;
        state.data = action.payload;
        action.payload.forEach((department) => {
          state.globalDepartmentsData[department.id ?? ""] = department;
        });
        state.status = "succeeded";
      })
      .addCase(fetchAllDepartments.rejected, (state, action) => {
        state.status = "failed";
        state.error = (action.payload as string) || "Failed to fetch departments.";
      })

      // Handle fetchDepartmentsByHOD
      .addCase(fetchDepartmentsByHOD.pending, (state) => {
        state.status = "loading";
      })
      .addCase(fetchDepartmentsByHOD.fulfilled, (state, action) => {
        state.HODDepartments = action.payload;
        state.status = "succeeded";
      })
      .addCase(fetchDepartmentsByHOD.rejected, (state, action) => {
        state.status = "failed";
        state.error = (action.payload as string) || "Failed to fetch departments.";
      })

      // Handle fetchDepartmentById
      .addCase(fetchDepartmentById.pending, (state) => {
        state.status = "loading";
      })
      .addCase(fetchDepartmentById.fulfilled, (state, action) => {
        state.department = action.payload;
        state.status = "succeeded";
      })
      .addCase(fetchDepartmentById.rejected, (state, action) => {
        state.status = "failed";
        state.error = (action.payload as string) || "Failed to fetch departments.";
      })

      // Handle fetchDepartmentsByParentId
      .addCase(fetchDepartmentsByParentId.pending, (state) => {
        state.status = "loading";
      })
      .addCase(fetchDepartmentsByParentId.fulfilled, (state, action) => {
        state.data = action.payload;
        state.status = "succeeded";
      })
      .addCase(fetchDepartmentsByParentId.rejected, (state, action) => {
        state.status = "failed";
        state.error = (action.payload as string) || "Failed to fetch departments.";
      })

      // Handle addDepartment
      .addCase(addDepartment.fulfilled, (state, action: PayloadAction<department>) => {
        state.data.push(action.payload);
        state.error = null;
      })
      .addCase(addDepartment.rejected, (state, action: PayloadAction<string | undefined>) => {
        state.error = action.payload || "Failed to add department.";
      })

      // Handle deleteDepartment
      .addCase(deleteDepartment.fulfilled, (state, action: PayloadAction<string>) => {
        state.data = state.data.filter((department) => department.id !== action.payload);
        state.error = null;
      })
      .addCase(deleteDepartment.rejected, (state, action: PayloadAction<string | undefined>) => {
        state.error = action.payload || "Failed to delete department.";
      })

      // Handle editDepartment
      .addCase(editDepartment.fulfilled, (state, action: PayloadAction<department>) => {
        const index = state.data.findIndex((department) => department.id === action.payload.id);
        if (index !== -1) {
          state.data[index] = action.payload;
        }
        state.error = null;
      })
      .addCase(editDepartment.rejected, (state, action: PayloadAction<string | undefined>) => {
        state.error = action.payload || "Failed to edit department.";
      });
  },
});

// Real-time subscription function
export const subscribeToDepartments = (dispatch: any) => {
  const subscription = supabase
    .channel("public:departments")
    .on("postgres_changes", { event: "*", schema: "public", table: "departments" }, (payload) => {
      const newRecord = payload.new as department;
      const oldRecord = payload.old as department;

      switch (payload.eventType) {
        case "INSERT":
          dispatch(addDepartmentRealTime(newRecord));
          break;
        case "UPDATE":
          dispatch(editDepartmentRealTime(newRecord));
          break;
        case "DELETE":
          dispatch(deleteDepartmentRealTime(oldRecord.id ?? ""));
          break;
        default:
          break;
      }
    })
    .subscribe();

  return () => {
    subscription.unsubscribe();
  };
};

export default departmentsSlice.reducer;
