import React, { useState, useEffect } from "react";
import { Button, DatePicker, Drawer, Form, Input, InputNumber, message, Select } from "antd";
import moment from "moment";
const { Option } = Select;
import { useFormik } from "formik";
import * as Yup from "yup";
import { useTranslation } from "react-i18next";
import { globalUsersData, USERS_ID_FULL_NAME } from "../../global/usersList";
import { salary } from "../../db/interfaces";
import { useAppDispatch, useAppSelector } from "../../hooks/reduxHooks";
import { editSalary } from "../../features/salaries/salariesSlice";

interface Props {
  salary: salary;
  salaryId: string;
  modalState: boolean;
  setModalState: React.Dispatch<React.SetStateAction<boolean>>;
}
const projectSchema = Yup.object().shape({});

function EditSalary(props: Props) {
  const dispatch = useAppDispatch();
  const { currentUser } = useAppSelector((state) => state.auth);

  const { t } = useTranslation();
  const { modalState, setModalState, salary, salaryId } = props;
  const [loading, setLoading] = useState(false);

  const uid = currentUser?.id;
  const salaryData = salary as salary;

  const formik = useFormik({
    initialValues: {
      uid: "",
      salary: 0,
      currency: "",
      startDate: moment(),
      endDate: moment(),
    },
    validationSchema: projectSchema,
    onSubmit: async (values) => {
      try {
        await dispatch(
          editSalary({
            id: salaryId,
            salary: values.salary,
            currency: values.currency,
            startDate: values.startDate as any,
            endDate: moment().add(1, "years") as any,
            createdAt: moment() as any,
            by: uid as string,
            office: globalUsersData[uid ?? ""]?.office ?? "",
            uid: values.uid,
          })
        ).unwrap();
        message.success("Salary updated successfully");
        setModalState(false);
        setLoading(false);
      } catch (error) {
        setLoading(false);
        console.error("Error writing document: ", error);
        message.error("Failed");
      }
    },
  });
  const [loaded, setLoaded] = useState(true);
  useEffect(() => {
    if (salary && loaded) {
      formik.setValues({
        uid: salaryData.uid,
        salary: salaryData.salary,
        currency: salaryData.currency ?? "",
        startDate: moment(salaryData.startDate),
        endDate: moment(salaryData.endDate),
      } as any);
      setLoaded(false);
    }
  }, [salary, formik, loaded]);

  const enterLoading = () => {
    setLoading(true);
  };
  const dateFormat = "YYYY/MM/DD";

  return (
    <Drawer
      title={t("general.addNewSalary")}
      width={500}
      height={350}
      visible={modalState}
      placement={"right"}
      onClose={() => setModalState(false)}
    >
      <Form onSubmit={formik.handleSubmit}>
        <Form.Item label={t("general.employee")}>
          <Select
            filterOption={(inputValue, option) => {
              return (option.props.children as any).toLowerCase().indexOf(inputValue.toLowerCase()) >= 0;
            }}
            showSearch
            value={formik.values.uid}
            onChange={(e) => {
              formik.setFieldValue("uid", e);
            }}
          >
            {USERS_ID_FULL_NAME?.map((d) => {
              return <Option value={d.uid}>{d.fullName}</Option>;
            })}
          </Select>
        </Form.Item>
        <Form.Item label={t("general.salary")}>
          <InputNumber
            value={formik.values.salary}
            onChange={(e) => {
              formik.setFieldValue("salary", e);
            }}
          />
        </Form.Item>
        <Form.Item label={t("general.currency")}>
          <Input
            value={formik.values.currency}
            onChange={(e) => {
              formik.setFieldValue("currency", e.target.value);
            }}
          />
        </Form.Item>

        <Form.Item label={t("general.startDate")}>
          <DatePicker
            value={formik.values.startDate}
            format={dateFormat}
            onChange={(e) => formik.setFieldValue("startDate", e)}
          />{" "}
        </Form.Item>

        <div>
          <Button
            className="btn blue lighten-1 z-depth-0"
            onClick={() => {
              enterLoading();
            }}
            loading={loading}
            type="primary"
            htmlType="submit"
            disabled={!formik.isValid}
          >
            {t("general.save")}
          </Button>
        </div>
      </Form>
    </Drawer>
  );
}

export default EditSalary;
