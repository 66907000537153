import { createSlice, createAsyncThunk, PayloadAction } from "@reduxjs/toolkit";
import { supabase } from "../../supabaseClient"; // Adjust the import path as needed
import { workOffice } from "../../db/interfaces"; // Adjust the import path as needed

interface workOfficeSliceState {
  workOffices: workOffice[];
  data: workOffice[];
  status: "loading" | "succeeded" | "failed" | "idle";
  error: string | null;
}

const initialState: workOfficeSliceState = {
  workOffices: [],
  data: [],
  status: "idle",
  error: null,
};

// Fetch all WorkOffice records
export const fetchAllWorkOffices = createAsyncThunk<workOffice[], void, { rejectValue: string }>(
  "workOffice/fetchAllWorkOffices",
  async (_, thunkApi) => {
    const { data, error } = await supabase.from("workOffices").select();
    if (error) {
      return thunkApi.rejectWithValue(error.message);
    }
    return data as workOffice[];
  }
);

// Fetch WorkOffice records for a specific office
export const fetchWorkOfficesByOffice = createAsyncThunk<workOffice[], string, { rejectValue: string }>(
  "workOffice/fetchWorkOfficesByOffice",
  async (officeId, thunkApi) => {
    const { data, error } = await supabase.from("workOffices").select("*").eq("office", officeId);

    if (error) {
      return thunkApi.rejectWithValue(error.message);
    }

    return data as workOffice[];
  }
);

// Add a new WorkOffice record
export const addWorkOffice = createAsyncThunk<workOffice, workOffice, { rejectValue: string }>(
  "workOffice/addWorkOffice",
  async (newWorkOffice, thunkApi) => {
    const { data: insertData, error: insertError } = await supabase
      .from("workOffices")
      .insert(newWorkOffice)
      .select()
      .single();

    if (insertError) {
      return thunkApi.rejectWithValue(insertError.message);
    }

    return insertData as workOffice;
  }
);

// Delete a WorkOffice record
export const deleteWorkOffice = createAsyncThunk<string, string, { rejectValue: string }>(
  "workOffice/deleteWorkOffice",
  async (id, thunkApi) => {
    const { error } = await supabase.from("workOffices").delete().eq("id", id);

    if (error) {
      return thunkApi.rejectWithValue(error.message);
    }

    return id;
  }
);

// Edit a WorkOffice record
export const editWorkOffice = createAsyncThunk<workOffice, workOffice, { rejectValue: string }>(
  "workOffice/editWorkOffice",
  async (updatedWorkOffice, thunkApi) => {
    const { data: updateData, error: updateError } = await supabase
      .from("workOffices")
      .update(updatedWorkOffice)
      .eq("id", updatedWorkOffice.id)
      .select()
      .single();

    if (updateError) {
      return thunkApi.rejectWithValue(updateError.message);
    }

    return updateData as workOffice;
  }
);

// Real-Time Actions
export const addWorkOfficeRealTime = (newWorkOffice: workOffice): PayloadAction<workOffice> => ({
  type: "workOffice/addWorkOfficeRealTime",
  payload: newWorkOffice,
});

export const editWorkOfficeRealTime = (updatedWorkOffice: workOffice): PayloadAction<workOffice> => ({
  type: "workOffice/editWorkOfficeRealTime",
  payload: updatedWorkOffice,
});

export const deleteWorkOfficeRealTime = (id: string): PayloadAction<string> => ({
  type: "workOffice/deleteWorkOfficeRealTime",
  payload: id,
});

// Slice definition
const workOfficeSlice = createSlice({
  name: "workOffice",
  initialState,
  reducers: {
    addWorkOfficeRealTime(state, action: PayloadAction<workOffice>) {
      const exists = state.data.some((office) => office.id === action.payload.id);
      if (!exists) {
        state.data.push(action.payload);
      }
      state.error = null;
    },
    editWorkOfficeRealTime(state, action: PayloadAction<workOffice>) {
      const index = state.data.findIndex((office) => office.id === action.payload.id);
      if (index !== -1) {
        const isDifferent = JSON.stringify(state.data[index]) !== JSON.stringify(action.payload);
        if (isDifferent) {
          state.data[index] = action.payload;
        }
      }
      state.error = null;
    },
    deleteWorkOfficeRealTime(state, action: PayloadAction<string>) {
      const exists = state.data.some((office) => office.id === action.payload);
      if (exists) {
        state.data = state.data.filter((office) => office.id !== action.payload);
      }
      state.error = null;
    },
  },
  extraReducers: (builder) => {
    builder
      // Handle fetchAllWorkOffices
      .addCase(fetchAllWorkOffices.pending, (state) => {
        state.status = "loading";
      })
      .addCase(fetchAllWorkOffices.fulfilled, (state, action: PayloadAction<workOffice[]>) => {
        state.status = "succeeded";
        state.workOffices = action.payload;
        state.error = null;
      })
      .addCase(fetchAllWorkOffices.rejected, (state, action: PayloadAction<string | undefined>) => {
        state.status = "failed";
        state.error = action.payload || "Failed to fetch work offices.";
      })

      // Handle fetchWorkOfficesByOffice
      .addCase(fetchWorkOfficesByOffice.pending, (state) => {
        state.status = "loading";
      })
      .addCase(fetchWorkOfficesByOffice.fulfilled, (state, action: PayloadAction<workOffice[]>) => {
        state.status = "succeeded";
        state.data = action.payload;
        state.error = null;
      })
      .addCase(fetchWorkOfficesByOffice.rejected, (state, action: PayloadAction<string | undefined>) => {
        state.status = "failed";
        state.error = action.payload || "Failed to fetch work offices by office.";
      })
      // Handle addWorkOffice
      .addCase(addWorkOffice.pending, (state) => {})
      .addCase(addWorkOffice.fulfilled, (state, action: PayloadAction<workOffice>) => {
        state.data.push(action.payload);
        state.error = null;
      })
      .addCase(addWorkOffice.rejected, (state, action: PayloadAction<string | undefined>) => {
        state.error = action.payload || "Failed to add work office.";
      })
      // Handle deleteWorkOffice
      .addCase(deleteWorkOffice.pending, (state) => {})
      .addCase(deleteWorkOffice.fulfilled, (state, action: PayloadAction<string>) => {
        state.data = state.data.filter((office) => office.id !== action.payload);
        state.error = null;
      })
      .addCase(deleteWorkOffice.rejected, (state, action: PayloadAction<string | undefined>) => {
        state.error = action.payload || "Failed to delete work office.";
      })
      // Handle editWorkOffice
      .addCase(editWorkOffice.pending, (state) => {})
      .addCase(editWorkOffice.fulfilled, (state, action: PayloadAction<workOffice>) => {
        state.status = "succeeded";
        const index = state.data.findIndex((office) => office.id === action.payload.id);
        if (index !== -1) {
          state.data[index] = action.payload;
        }
        state.error = null;
      })
      .addCase(editWorkOffice.rejected, (state, action: PayloadAction<string | undefined>) => {
        state.status = "failed";
        state.error = action.payload || "Failed to edit work office.";
      });
  },
});

// Real-time subscription function
export const subscribeToWorkOffices = (dispatch: any) => {
  const subscription = supabase
    .channel("public:workOffices")
    .on("postgres_changes", { event: "*", schema: "public", table: "workOffices" }, (payload) => {
      const newRecord = payload.new as workOffice;
      const oldRecord = payload.old as workOffice;
      switch (payload.eventType) {
        case "INSERT":
          dispatch(addWorkOfficeRealTime(newRecord));
          break;
        case "UPDATE":
          dispatch(editWorkOfficeRealTime(newRecord));
          break;
        case "DELETE":
          dispatch(deleteWorkOfficeRealTime(oldRecord.id ?? ""));
          break;
        default:
          break;
      }
    })
    .subscribe();

  return () => {
    subscription.unsubscribe();
  };
};

export default workOfficeSlice.reducer;
