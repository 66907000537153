import React, { useState, useEffect, useRef } from "react";
import { Button, Drawer, Form, message, Input, Icon, Row, Spin, Checkbox, List, Popconfirm, Col } from "antd";
import { useFormik } from "formik";
import * as Yup from "yup";
import { useTranslation } from "react-i18next";
import { task } from "../../db/interfaces";
import DraftText from "../Meetings_Rooms/TextEditor/Draft";
import { stateToHTML } from "draft-js-export-html";
import { EditorState } from "draft-js";
import { stateFromHTML } from "draft-js-import-html";
import { useAppDispatch, useAppSelector } from "../../hooks/reduxHooks";
import { editTask } from "../../features/tasks/tasksSlice";
import moment from "moment";
import { deleteFile, uploadFile } from "../../db/supabase-storage";
interface Props {
  modalState: boolean;
  setModalState: React.Dispatch<React.SetStateAction<boolean>>;
  departmentId: string;
  task: task;
}
const projectSchema = Yup.object().shape({});

function EditTask(props: Props) {
  const { currentUser } = useAppSelector((state) => state.auth);
  const dispatch = useAppDispatch();

  const { t } = useTranslation();
  const { modalState, setModalState, departmentId, task } = props;
  const [loading, setLoading] = useState(false);
  const [uploadTaskFiles, setUploadTaskFiles] = useState([] as any);
  const [editorState, setEditorState] = useState<EditorState>(EditorState.createWithContent(stateFromHTML("")));
  const html = stateToHTML(editorState.getCurrentContent());

  const uid = currentUser.id as string;
  const fileInputRef = useRef(null);
  const handleButtonClick = () => {
    if (fileInputRef.current) {
      fileInputRef.current.click();
    }
  };

  const formik = useFormik({
    initialValues: {
      departmentId: "",
      code: "",
      title: "",
      description: "",
      attached_files: [],
      duration: 0,
      sharable: false,
    },
    validationSchema: projectSchema,
    onSubmit: async (values) => {
      setLoading(true);
      try {
        dispatch(
          editTask({
            id: task.id,
            departmentId: departmentId,
            code: values.code,
            title: values.title,
            description: html,
            attached_files: values.attached_files ?? [],
            duration: values.duration,
            sharable: values.sharable,
            createdAt: moment() as any,
            by: uid,
          })
        ).then(() => {
          setModalState(false);
          setLoading(false);
          message.success("Task is Updated successfully!");
          formik.resetForm();
        });
      } catch (error) {
        setLoading(false);
        console.error("Error writing document: ", error);
        message.error("Failed");
      }
    },
  });
  useEffect(() => {
    return () => {
      if (formik.isValid == false) {
        setLoading(false);
      }
    };
  }, [formik.isValid]);

  const [loaded, setLoaded] = useState(true);
  useEffect(() => {
    if (task && loaded) {
      formik.setValues({
        departmentId: task.departmentId,
        code: task.code,
        title: task.title,
        description: task.description,
        attached_files: task.attached_files,
        duration: task.duration,
        sharable: task.sharable,
      } as any);
      setLoaded(false);
      setEditorState(EditorState.createWithContent(stateFromHTML(task.description)));
    }
  }, [task, formik, loaded]);

  const handleFileUpload = async (files: any) => {
    try {
      const downloadURLs = await Promise.all(
        files.map(async (file: any) => {
          const filePath = `departments/${departmentId}/tasks/${file.name}`;
          try {
            const url = await uploadFile(file, filePath);

            if (url) {
              message.success("File uploaded successfully");

              return {
                name: file.name,
                url: url,
              };
            }
          } catch (error: any) {
            message.error(error.message);
          }
        })
      );
      return downloadURLs;
    } catch (error) {
      console.error("Error uploading files:", error);
      return [];
    }
  };

  const uploadTask = async () => {
    setLoading(true);
    const promise = handleFileUpload(uploadTaskFiles);
    promise
      .then((newTaskFiles) => {
        formik.setFieldValue("attached_files", [...formik.values.attached_files, ...newTaskFiles]);
      })
      .then(() => {
        setUploadTaskFiles([]);
        message.success("Files are uploaded successfully!");
        setLoading(false);
      });
    promise.catch(() => {
      message.error("Something went wrong!");
      setLoading(false);
    });
  };

  const handleDeleteFile = async (fileName: string, attachmentFolder: string) => {
    try {
      setLoading(true);
      await deleteFile(`departments/${departmentId}/tasks/${fileName}`).then(async () => {
        const currentFiles = (formik.values as any)[attachmentFolder];
        const filteredCurrentFiles = currentFiles.filter((r: any) => r.name != fileName);
        formik.setFieldValue(attachmentFolder, filteredCurrentFiles);
        setLoading(false);
      });
    } catch (error) {
      console.error("Error deleting file:", error);
      setLoading(false);
    }
  };

  const antIcon = <Icon type="loading" style={{ fontSize: 75 }} spin />;
  return (
    <Drawer
      title={t("general.editResponsibility")}
      width={700}
      height={350}
      visible={modalState}
      placement={"right"}
      onClose={() => setModalState(false)}
    >
      <Spin spinning={loading} size="large" indicator={antIcon}>
        <Form onSubmit={formik.handleSubmit}>
          <Form.Item label={t("general.code")}>
            <Input value={formik.values.code} onChange={(e) => formik.setFieldValue("code", e.target.value)} />
          </Form.Item>
          <Form.Item label={t("general.title")}>
            <Input value={formik.values.title} onChange={(e) => formik.setFieldValue("title", e.target.value)} />
          </Form.Item>
          <Form.Item label={t("general.description")}>
            <DraftText
              onValueChange={() => {}}
              onMentionChange={() => {}}
              html={""}
              editorState={editorState}
              setEditorState={setEditorState}
            />
          </Form.Item>
          <Form.Item label={t("general.sharable")}>
            <Checkbox
              checked={formik.values.sharable}
              onChange={(e) => {
                formik.setFieldValue("sharable", e.target.checked);
              }}
            >
              Is this Responsibility sharable with other departments?
            </Checkbox>
          </Form.Item>
          <Form.Item label="Responsibility Files">
            <Row gutter={12}>
              <Col span={6}>
                <Button onClick={() => handleButtonClick()}>
                  <Icon type="upload" /> {`Click to upload ${uploadTaskFiles.length}`}
                  <input
                    ref={fileInputRef} // Attach the ref to the input
                    hidden
                    type="file"
                    multiple
                    onChange={async (e) => {
                      if (e.target.files) {
                        setUploadTaskFiles([...Object.values(e?.target?.files as any)]);
                      }
                    }}
                  />
                </Button>
              </Col>
              <Col span={6}>
                <Button
                  onClick={uploadTask}
                  disabled={uploadTaskFiles.length == 0}
                  className="btn blue lighten-1 z-depth-0"
                  type="primary"
                >
                  {t("general.upload")}
                </Button>
              </Col>
            </Row>
          </Form.Item>

          {formik.values?.attached_files?.length > 0 && (
            <div>
              <List
                itemLayout="horizontal"
                bordered
                dataSource={formik.values?.attached_files}
                renderItem={(file: any) => (
                  <List.Item>
                    <div>
                      <a href={file.url} target="_blank" rel="noopener noreferrer">
                        {file.name}
                      </a>
                      {/* <p style={{ margin: "0px" }}>{file.attachedDescription}</p> */}
                    </div>
                    <Popconfirm
                      title={"Are you sure?"}
                      onConfirm={() => handleDeleteFile(file.name, "attached_files")}
                      onCancel={() => console.log("cancel")}
                      okText="Yes"
                      cancelText="No"
                    >
                      <Button type="link" style={{ color: "red" }}>
                        <Icon type="delete" />
                      </Button>
                    </Popconfirm>
                  </List.Item>
                )}
              />
            </div>
          )}

          <div>
            <Button
              className="btn blue lighten-1 z-depth-0"
              style={{ marginTop: "2%" }}
              type="primary"
              htmlType="submit"
            >
              {t("general.save")}
            </Button>
          </div>
        </Form>
      </Spin>
    </Drawer>
  );
}

export default EditTask;
