import React, { useState } from "react";
import Paper from "@material-ui/core/Paper";
import { Button } from "antd";
import PermissionsDialog from "./permissionsDialog";
import { users } from "../../db/interfaces";
import DevTable from "../../helpers/devex-table";
import { globalOfficesData } from "../../global/officeList";
import { globalUsersData } from "../../global/usersList";
import { useAppDispatch, useAppSelector } from "../../hooks/reduxHooks";
import { editUser } from "../../features/users/usersSlice";

export default function table() {
  const dispatch = useAppDispatch();
  const { allUsers } = useAppSelector((state) => state.users);
  const [modalState, setModalState] = useState(false);
  const data = allUsers;

  const [UID, setUID] = useState("");
  const [user, setUser] = useState<users>({} as users);

  const [columnsExport] = useState([
    { name: "id", title: "UID" },

    { name: "resourceNO", title: "ID" },
    { name: "firstName", title: "First Name" },
    { name: "lastName", title: "Last Name" },
    { name: "office", title: "Office", getCellValue: (row) => globalOfficesData[row.office ?? ""]?.officeName },
    { name: "managerUID", title: "Supervisor", getCellValue: (row) => globalUsersData[row.managerUID ?? ""]?.fullName },
    { name: "email", title: "Email" },
    { name: "admin", title: "Admin", getCellValue: (e) => `Admin:${e.admin}, HR:${e.adminHR}, Law:${e.adminLaw}` },
  ]);
  const [columns] = useState([
    { name: "id", title: "UID" },
    { name: "resourceNO", title: "ID" },

    { name: "firstName", title: "First Name" },
    { name: "lastName", title: "Last Name" },
    { name: "office", title: "Office", getCellValue: (row) => globalOfficesData[row.office ?? ""]?.officeName },
    { name: "managerUID", title: "Supervisor", getCellValue: (row) => globalUsersData[row.managerUID ?? ""]?.fullName },
    { name: "email", title: "Email" },
    { name: "admin", title: "Admin", getCellValue: (e) => `Admin:${e.admin}, HR:${e.adminHR}, Law:${e.adminLaw}` },
    {
      name: "action",
      title: "Action",
      getCellValue: (r) => {
        return r.admin == true ? (
          <Button
            onClick={() => {
              dispatch(editUser({ id: r.id, admin: false }));
            }}
          >
            Not Admin
          </Button>
        ) : (
          <Button
            type="primary"
            onClick={() => {
              dispatch(editUser({ id: r.id, admin: true }));
            }}
          >
            Admin
          </Button>
        );
      },
    },
    {
      name: "actionHR",
      title: "HR Action",
      getCellValue: (r) => {
        return r.adminHR == true ? (
          <Button
            onClick={() => {
              dispatch(editUser({ id: r.id, adminHR: false }));
            }}
          >
            Not Admin HR
          </Button>
        ) : (
          <Button
            type="primary"
            onClick={() => {
              dispatch(editUser({ id: r.id, adminHR: true }));
            }}
          >
            Admin HR
          </Button>
        );
      },
    },
    {
      name: "actionLaw",
      title: "Lawyer Action",
      getCellValue: (r) => {
        return r.adminLaw == true ? (
          <Button
            onClick={() => {
              dispatch(editUser({ id: r.id, adminLaw: false }));
            }}
          >
            Not Admin Law.
          </Button>
        ) : (
          <Button
            type="primary"
            onClick={() => {
              dispatch(editUser({ id: r.id, adminLaw: true }));
            }}
          >
            Admin Law
          </Button>
        );
      },
    },
    {
      name: "actionCEO",
      title: "CEO",
      getCellValue: (r) => {
        return r.CEO == true ? (
          <Button
            onClick={() => {
              dispatch(editUser({ id: r.id, CEO: false }));
            }}
          >
            Not CEO
          </Button>
        ) : (
          <Button
            type="primary"
            onClick={() => {
              dispatch(editUser({ id: r.id, CEO: true }));
            }}
          >
            CEO
          </Button>
        );
      },
    },
    {
      name: "managePermissions",
      title: "Permissions",
      getCellValue: (r: any) => {
        return (
          <Button
            onClick={() => {
              setUID(r.id);
              setUser(r);
              setModalState(true);
            }}
          >
            Manage
          </Button>
        );
      },
    },
  ]);

  return (
    <div>
      {modalState ? (
        <PermissionsDialog UID={UID} user={user} setModalState={setModalState} modalState={modalState} />
      ) : null}
      <Paper>
        <DevTable
          data={data}
          columns={columns}
          defaultHiddenColumnNames={[]}
          tableName="Users"
          selected={false}
          typicalTable={false}
          columnsExport={columnsExport}
        />
      </Paper>
    </div>
  );
}
