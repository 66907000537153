import React from "react";
import { Form, message, Modal } from "antd";
import { delay } from "../../db/interfaces";
import { useFormik } from "formik";
import TextArea from "antd/lib/input/TextArea";
import moment from "moment";
import { useAppDispatch, useAppSelector } from "../../hooks/reduxHooks";
import { editDelay } from "../../features/delays/delaysSlice";

interface Props {
  delay: delay;
  modalState: boolean;
  setModalState: any;
}

function DelayDisableDialog(props: Props) {
  const { delay, modalState, setModalState } = props;
  const { currentUser } = useAppSelector((state) => state.auth);
  const dispatch = useAppDispatch();
  const uid = currentUser.id ?? "";

  const formik = useFormik({
    initialValues: {
      notes: "",
    },
    onSubmit: async (values, form) => {
      try {
        dispatch(
          editDelay({
            id: delay.id,
            notes: values.notes,
            canceledBy: uid,
            canceledDate: moment() as any,
            status: "disabled",
          } as any)
        );
        message.success("Done!");
        setModalState(false);
      } catch (error: any) {
        message.error(error.message);
      }
    },
  });

  return (
    <Modal
      closable={true}
      width={800}
      visible={modalState}
      onCancel={async () => {
        setModalState(false);
      }}
      onOk={() => {
        formik.submitForm();
      }}
    >
      <div>
        <Form onSubmit={formik.handleSubmit}>
          <Form.Item label="Notes">
            <TextArea placeholder={"Notes"} onChange={(e) => formik.setFieldValue("notes", e.target.value)} />
          </Form.Item>
        </Form>
      </div>
    </Modal>
  );
}

export default DelayDisableDialog;
