import { Layout, Menu, Icon, Spin, Badge, Dropdown, Avatar, Alert, Divider, Descriptions, message } from "antd";
import React, { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import logoIDA from "./logo.png";
import navbarBackgroundImg from "./AIU_Header-01-1.png";
import { sortBy } from "lodash";
import { useTranslation } from "react-i18next";
import { admins, tempAdmins } from "../../db/admin";
import { delay, users } from "../../db/interfaces";
import SubMenu from "antd/lib/menu/SubMenu";
import { Container } from "@material-ui/core";
import { useAppDispatch, useAppSelector } from "../../hooks/reduxHooks";
import { RootState } from "../../store";
import { signOut } from "../../features/auth/authSlice";
import { globalUsersData } from "../../global/usersList";
import {
  editNotification,
  fetchNotificationsByUid,
  subscribeToNotifications,
} from "../../features/notifications/notificationsSlice";
import moment from "moment";

import { fetchDelaysByUserIdAndMonth } from "../../features/delays/delaysSlice";
import { uploadFile } from "../../db/supabase-storage";
import { editUser } from "../../features/users/usersSlice";
import OneSignal from "react-onesignal";
export const TitleContext = React.createContext<[string, (title: string) => void]>(["Not Feedback", () => {}]);
const { Header, Sider, Footer, Content } = Layout;
const antIcon = <Icon type="loading" style={{ fontSize: 1 }} spin />;

export interface Props {
  children: React.ReactNode;
}

function LayoutApp({ children }: Props) {
  const {
    data: notificationsData,
    status: notificationsStatus,
    error,
  } = useAppSelector((state) => state.notifications);
  const { currentUser, currentUserStatus } = useAppSelector((state: RootState) => state.auth);
  const { data: delaysData, status: delaysStatus, error: delaysError } = useAppSelector((store) => store.delays);

  const dispatch = useAppDispatch();
  const { t } = useTranslation();

  const uid = currentUser.id as string;

  const [collapsed, setCollapsed] = useState(false);
  const history = useHistory();
  const [title, setTitle] = useState("IDA");
  const [closeAlert, setCloseAlert] = useState(false);
  const [closeAlertSig, setCloseAlertSig] = useState(false);
  const [personalPhotoUrl, setPersonalPhotoUrl] = useState(currentUser.personalPhoto);

  useEffect(() => {
    dispatch(fetchNotificationsByUid(uid));
    dispatch(fetchDelaysByUserIdAndMonth({ userId: uid, month: moment().format("MMM.YYYY").toString() }));

    const unsubscribe = subscribeToNotifications(dispatch);
    return () => {
      unsubscribe();
    };
  }, [dispatch, uid]);

  useEffect(() => {
    if (currentUser.id) {
      OneSignal.init({
        appId: "bbb9050e-3322-4593-8fe0-d0dc8171a8a2",
        notifyButton: {
          enable: true,
        },
        allowLocalhostAsSecureOrigin: true, // Required for local development
      })
        .then(async () => {
          console.log("OneSignal initialized successfully.");
          OneSignal.Slidedown.promptPush();
          await OneSignal.login(currentUser?.id as string)
            .then(() => console.log("signed im to onesignal"))
            .catch((e) => console.log("error", e));
          await OneSignal.User.addEmail(currentUser.email ?? ("" as string));
          await OneSignal.User.addAlias(currentUser.fullName as string, currentUser.id as string);
        })
        .catch((error) => {
          console.error("OneSignal initialization failed:", error);
        });
    }
  }, [currentUser.id]);

  if (notificationsStatus == "loading" || currentUserStatus == "loading") {
    return (
      <div style={{ textAlign: "center", marginTop: "25%" }}>
        <Spin size="large" indicator={antIcon} />
      </div>
    );
  }

  const userData = currentUser as users;
  const badgeSignature = !userData.signatureURL;
  const badgeStatue =
    !userData.fatherName ||
    !userData.motherName ||
    !userData.arabicFullName ||
    !userData.dateOfBirth ||
    !userData.placeOfBirth ||
    !userData.currentAddress ||
    !userData.phoneNumber;

  const notificationsLength =
    notificationsData
      .filter((notification) => notification.notifyTo == uid)
      .filter((notification) => notification.read == false).length ?? 0;

  const {
    firstName: userFname,
    lastName: userLname,
    CEO,
    adminLaw: LawAdmin,
    token: token,
    adminOffices: ADMIN_OFFICER_OFFICES,
    HROffices: HR_OFFICER_OFFICES,
    HRProjectsManagersOffices: HR_PM_OFFICES,
    viewersOffices: VIEWER,
  } = userData;

  const delays = delaysData.filter((d) => d.status != "disabled");
  let total = 0;
  delays?.map((d: delay, index: any) => {
    return (total = total + d.min);
  });

  let totalDay = 0;
  delays
    ?.filter((d: delay) => {
      const docDate = moment(d.dayDate).format("DD-MM-YYYY").toString();
      const today = moment().format("DD-MM-YYYY").toString();
      return docDate == today;
    })
    .map((d: delay, index: any) => {
      return (totalDay = totalDay + d.min);
    });

  const notifications = notificationsData
    .filter((notification) => notification.notifyTo == uid)
    .filter((notification) => notification.read == false);
  const sortedNotifications = sortBy(notifications, (item) => item.postedAt).reverse();
  const removeNotification = (id: string) => {
    dispatch(editNotification({ id: id, read: true } as any));
  };
  const notificationMenu =
    sortedNotifications.length == 0 ? (
      <Menu>
        <Menu.Item>
          <span>{t("general.noNotifications")}</span>
        </Menu.Item>
        <Menu.Divider />
        <Menu.Item onClick={() => history.push(`/notification`)}>
          <span>{t("general.showAll")}</span>
        </Menu.Item>
      </Menu>
    ) : (
      <Menu>
        {sortedNotifications.slice(0, 10).map((item) => {
          const rout =
            item.kind == "mention" || item.kind == "calendarInvitation"
              ? "calenderDetails"
              : item.kind == "newRequestVacation"
              ? "vacation"
              : item.kind == "messageVacation"
              ? "vacation"
              : item.content == "New Compensation request is waiting for your Approval!"
              ? "overtime"
              : item.kind == "messageOvertime" || item.kind == "newRequestOvertime"
              ? "overtime"
              : item.kind == "newRequest" || item.kind == "messageOrder" || item.kind == ""
              ? "order"
              : item.kind;
          return item.kind == "newRequest" ? (
            <Menu.Item
              onClick={() => {
                history.push(`/${rout}/${item.orderId}`);
                removeNotification(item.id as any);
              }}
            >
              <span style={{ color: "#01aced" }}>{globalUsersData[item.notifyFrom ?? ""]?.fullName}</span>
              <span style={{ fontSize: "12px", color: "#ccc" }}>{item.postedAt.toLocaleString()}</span>
              <br />
              <div
                style={{
                  marginTop: 10,
                }}
                dangerouslySetInnerHTML={{ __html: item.content }}
              />
            </Menu.Item>
          ) : item.kind == "messageOrder" ? (
            <Menu.Item
              onClick={() => {
                history.push(`/order/${item.orderId}`);
                removeNotification(item.id as any);
              }}
            >
              <span style={{ color: "#01aced" }}>{globalUsersData[item.notifyFrom ?? ""]?.fullName}</span>
              <span> {t("general.sentNewMessage")} </span>
              <br />
              <span style={{ fontSize: "12px", color: "#ccc" }}>{item.postedAt.toLocaleString()}</span>
              <br />
              <div
                style={{
                  marginTop: 10,
                }}
                dangerouslySetInnerHTML={{ __html: item.content }}
              />
            </Menu.Item>
          ) : item.kind == "mention" || item.kind == "calendarInvitation" ? (
            <Menu.Item
              onClick={() => {
                history.push(`/calenderDetails/${item.orderId}`);
                removeNotification(item.id as any);
              }}
            >
              <span style={{ color: "#01aced" }}>{globalUsersData[item.notifyFrom ?? ""]?.fullName}</span>
              {item?.kind == "mention" ? <span> {t("general.mentioned")} </span> : <span>{item?.content} </span>} <br />
              <span style={{ fontSize: "12px", color: "#ccc" }}>{item.postedAt.toLocaleString()}</span>
            </Menu.Item>
          ) : item.kind == "newRequestOvertime" || item.kind == "messageOvertime" ? (
            <Menu.Item
              onClick={() => {
                history.push(`/overtime/${item.orderId}`);
                removeNotification(item.id as any);
              }}
            >
              <span style={{ color: "#01aced" }}>{globalUsersData[item.notifyFrom ?? ""]?.fullName}</span>
              <span style={{ fontSize: "12px", color: "#ccc" }}>{item.postedAt.toLocaleString()}</span>
              <br />
              <div
                style={{
                  marginTop: 10,
                }}
                dangerouslySetInnerHTML={{ __html: item.content }}
              />
            </Menu.Item>
          ) : (
            <Menu.Item
              onClick={() => {
                history.push(`/${rout}/${item.orderId}`);
                removeNotification(item.id as any);
              }}
            >
              <span style={{ color: "#01aced" }}>{globalUsersData[item.notifyFrom ?? ""]?.fullName}</span>
              <span style={{ fontSize: "12px", color: "#ccc" }}>{item.postedAt.toLocaleString()}</span>
              <br />
              <div
                style={{
                  marginTop: 10,
                }}
                dangerouslySetInnerHTML={{ __html: item.content }}
              />
            </Menu.Item>
          );
        })}

        <Menu.Divider />
        <Menu.Item onClick={() => history.push(`/notification`)}>
          <span>{t("general.showAll")}</span>
        </Menu.Item>
        <Menu.Divider />
        <Menu.Item onClick={() => notificationReadAll()}>
          <span style={{ color: "red" }}>{t("general.readAll")}</span>
        </Menu.Item>
      </Menu>
    );

  const menu = (
    <Menu style={{ width: "300px" }}>
      <Menu.Item>
        <Icon type="user" />
        <span>
          {userFname + " " + userLname}
          {""}
        </span>
      </Menu.Item>
      <Menu.Item
        key="6"
        onClick={() => {
          history.push(`/profile`);
        }}
      >
        <Badge dot={badgeStatue}>
          <Icon type="user" />

          <span>{t("general.profile")}</span>
        </Badge>
      </Menu.Item>
      <Menu.Item
        key="8"
        onClick={() => {
          history.push(`/signature`);
        }}
      >
        <Badge dot={badgeSignature}>
          <Icon type="edit" />

          <span>{t("general.mysignature")}</span>
        </Badge>
      </Menu.Item>
      <Menu.Divider />
      <Menu.Item
        key="7"
        onClick={() => {
          signedout();
          // history.push(`/signin`);
        }}
      >
        <Icon type="logout" />
        <span>{t("general.logOut")}</span>
      </Menu.Item>
    </Menu>
  );
  const toggle = () => {
    setCollapsed(!collapsed);
  };

  const signedout = () => {
    dispatch(signOut());
    OneSignal.logout();
  };

  const notificationReadAll = () => {
    notificationsData
      .filter((notification) => notification.notifyTo == uid)
      .map((notification) => {
        dispatch(
          editNotification({
            id: notification.id,
            read: true,
          } as any)
        );
      });
  };

  const convertToHours = (num: number) => {
    const hours = Math.floor(num / 60);
    const min = num % 60;
    return ("00" + hours).slice(-2) + ":" + ("00" + min).slice(-2);
  };

  const handleFileChange = async (e: React.ChangeEvent<HTMLInputElement>) => {
    if (e.target.files) {
      const file: File = e.target.files[0];
      if (!file) return;
      const filePath = `personal_photo/${uid}/${file.name}`;
      try {
        const url = await uploadFile(file, filePath);

        if (url) {
          message.success("File uploaded successfully");
          return url;
        }
      } catch (error: any) {
        message.error(error.message);
      }
    }
  };

  return (
    <div>
      <Layout>
        <Header
          style={{
            backgroundImage: `url(${navbarBackgroundImg})`,
            backgroundPosition: "center",
            position: "sticky",
            top: 0,
            zIndex: +3,
            width: "100%",
          }}
        >
          <img style={{ width: "90px", marginBottom: "7px" }} src={logoIDA} />

          <div style={{ float: "right" }}>
            <div style={{ width: "120px" }}>
              <Dropdown overlay={notificationMenu} placement="bottomRight">
                <Badge count={notificationsLength}>
                  <Avatar size="large" style={{ background: "#33b2f5" }}>
                    <Icon
                      type="bell"
                      style={{
                        fontSize: "18px",
                        color: "#ffff",
                        marginRight: "9%",
                      }}
                    />
                  </Avatar>
                </Badge>
              </Dropdown>

              <Dropdown overlay={menu} placement="bottomRight">
                <Badge dot={badgeStatue}>
                  <Avatar
                    size="large"
                    src={userData.personalPhoto}
                    style={{
                      backgroundColor: userData.gender == "male" ? "#87d068" : "#db7093",
                      marginLeft: "14%",
                    }}
                  >
                    {userData.personalPhoto ?? userFname.slice(0, 1) + userLname.slice(0, 1)}
                  </Avatar>
                </Badge>
              </Dropdown>
            </div>
          </div>
        </Header>

        <Layout>
          <Sider
            collapsible
            collapsed={collapsed}
            width={225}
            onCollapse={toggle}
            style={{
              backgroundColor: "white",
            }}
          >
            {!collapsed && (
              <div style={{ background: "white", padding: "3%", borderRadius: "2%" }}>
                <div style={{ textAlign: "center" }}>
                  <label style={{ cursor: "pointer" }}>
                    <Avatar shape="circle" size={120} icon="user" src={personalPhotoUrl ?? currentUser.personalPhoto} />
                    <input
                      hidden
                      type="file"
                      onChange={async (e) => {
                        const url = await handleFileChange(e);
                        dispatch(
                          editUser({
                            id: uid,
                            personalPhoto: url,
                          } as any)
                        ).then(() => setPersonalPhotoUrl(url as string));
                      }}
                    />
                  </label>
                </div>

                <div style={{ textAlign: "center" }}>
                  <h3>
                    <b>
                      {currentUser.firstName} {currentUser.lastName}
                    </b>
                  </h3>
                  <h5>
                    <b>{currentUser.id}</b>
                  </h5>

                  <h5>{currentUser.position}</h5>

                  <h5>{globalUsersData[currentUser.managerUID]?.fullName}</h5>
                  <h5>{currentUser.department}</h5>
                  <h5>
                    <b>{`Annual Score: ${currentUser.vacationAnnualScore.toFixed(3)}`}</b>
                  </h5>
                  <h5>
                    <b>{`Sick Score: ${currentUser.vacationSickScore.toFixed(3)}`}</b>
                  </h5>
                  <h5>
                    <b>{`Compensation Score: ${currentUser.vacationCompensationScore.toFixed(3)}`}</b>
                  </h5>
                  <h5>
                    <b>{`Monthly Delays: ${convertToHours(total)}`}</b>
                  </h5>

                  <h5>
                    <b>{`Today's Delays: ${convertToHours(totalDay)}`}</b>
                  </h5>
                  <h5 style={{ color: "green" }}>
                    {currentUser.status == undefined
                      ? "Not Registered Yet"
                      : currentUser.status == "active"
                      ? "Active"
                      : "Disabled"}
                  </h5>
                </div>
                <Divider />
              </div>
            )}

            <Menu theme="light" mode="inline" defaultSelectedKeys={["1"]} style={{ background: "white" }}>
              <Menu.Item
                key="4"
                onClick={() => {
                  history.push(`/`);
                }}
              >
                <Icon type="home" />
                <span>{t("general.home")}</span>
              </Menu.Item>

              {LawAdmin ? (
                <Menu.Item
                  key="2"
                  onClick={() => {
                    history.push(`/order-table`);
                  }}
                >
                  <Icon type="folder" />
                  <span>{t("general.workPermit")}</span>
                </Menu.Item>
              ) : null}
              <Menu.Item
                key="15"
                onClick={() => {
                  history.push(`/vacation`);
                }}
              >
                <Icon type="form" />
                <span>{t("general.vacationRequest")}</span>
              </Menu.Item>
              <Menu.Item
                key="14"
                onClick={() => {
                  history.push(`/vacations-table`);
                }}
              >
                <Icon type="exception" />
                <span>{t("general.vacationsTable")}</span>
              </Menu.Item>
              {HR_OFFICER_OFFICES?.length != 0 || HR_PM_OFFICES?.length != 0 ? (
                <SubMenu
                  key="sub2"
                  title={
                    <div>
                      <Icon type="team" /> <span>{t("general.usersDashboard")}</span>
                    </div>
                  }
                >
                  <Menu.Item
                    key="30"
                    onClick={() => {
                      history.push(`/usersControl-table`);
                    }}
                  >
                    <span>{"By Office"}</span>
                  </Menu.Item>
                  <Menu.Item
                    key="31"
                    onClick={() => {
                      history.push(`/usersControl-byProject-table`);
                    }}
                  >
                    <span>{"By Project"}</span>
                  </Menu.Item>
                </SubMenu>
              ) : null}
              {HR_OFFICER_OFFICES?.length != 0 || HR_PM_OFFICES?.length != 0 || ADMIN_OFFICER_OFFICES?.length != 0 ? (
                <SubMenu
                  key="sub3"
                  title={
                    <div>
                      <Icon type="team" /> <span>{t("general.usersTimeSheet")}</span>
                    </div>
                  }
                >
                  <Menu.Item
                    key="40"
                    onClick={() => {
                      history.push(`/timesheet-byOffice`);
                    }}
                  >
                    <span>{"By Office"}</span>
                  </Menu.Item>
                  <Menu.Item
                    key="41"
                    onClick={() => {
                      history.push(`/timesheet-byProject`);
                    }}
                  >
                    <span>{"By Project"}</span>
                  </Menu.Item>
                </SubMenu>
              ) : null}

              {admins.includes(uid) ? ( // TODO:
                <Menu.Item
                  key="8"
                  onClick={() => {
                    history.push(`/meeting-room`);
                  }}
                >
                  <Icon type="build" />
                  <span>{t("general.meetingRoom")}</span>
                </Menu.Item>
              ) : null}

              {CEO ||
              HR_OFFICER_OFFICES?.length != 0 ||
              HR_PM_OFFICES?.length != 0 ||
              ADMIN_OFFICER_OFFICES?.length != 0 ||
              VIEWER.length != 0 ? (
                <SubMenu
                  key="sub1"
                  title={
                    <div>
                      <Icon type="clock-circle" /> <span>{t("general.attendees")}</span>
                    </div>
                  }
                >
                  <Menu.Item
                    key="absent"
                    onClick={() => {
                      history.push(`/absent-table`);
                    }}
                  >
                    <span>{t("general.absentTable")}</span>
                  </Menu.Item>

                  <Menu.Item
                    key="delay"
                    onClick={() => {
                      history.push(`/delay-table`);
                    }}
                  >
                    <span>{t("general.delayWindow")}</span>
                  </Menu.Item>
                </SubMenu>
              ) : null}

              {CEO ||
              HR_OFFICER_OFFICES?.length != 0 ||
              HR_PM_OFFICES?.length != 0 ||
              ADMIN_OFFICER_OFFICES?.length != 0 ||
              VIEWER.length != 0 ? null : (
                <Menu.Item
                  key="18"
                  onClick={() => {
                    history.push(`/user-delay/${uid}`);
                  }}
                >
                  <Icon type="clock-circle" />
                  <span>{t("general.myDelays")}</span>
                </Menu.Item>
              )}
              {CEO ||
              HR_OFFICER_OFFICES?.length != 0 ||
              HR_PM_OFFICES?.length != 0 ||
              ADMIN_OFFICER_OFFICES?.length != 0 ||
              VIEWER.length != 0 ? null : (
                <Menu.Item
                  key="myFingerPrint"
                  onClick={() => {
                    history.push(`/fingerPrint/${uid}`);
                  }}
                >
                  <Icon type="paper-clip" />
                  <span>{t("general.myFingerPrint")}</span>
                </Menu.Item>
              )}

              {admins.includes(uid) ? ( // TODO:
                <Menu.Item
                  key="5"
                  onClick={() => {
                    history.push(`/t`);
                  }}
                >
                  <Icon type="table" />
                  <span>{t("general.users")}</span>
                </Menu.Item>
              ) : null}
              <Menu.Item
                key="10"
                onClick={() => {
                  history.push(`/meeting-table`);
                }}
              >
                <Icon type="filter" />
                <span>{t("general.myMeetings")}</span>
              </Menu.Item>
              <Menu.Item
                key="9"
                onClick={() => {
                  history.push(`/meeting-calendar`);
                }}
              >
                <Icon type="calendar" />
                <span>{t("general.calendar")}</span>
              </Menu.Item>

              <br />
              {HR_PM_OFFICES?.length != 0 || HR_OFFICER_OFFICES?.length != 0 ? (
                <SubMenu
                  key="sub5"
                  title={
                    <div>
                      <Icon type="team" /> <span>{t("general.employeeManagement")}</span>
                    </div>
                  }
                >
                  {HR_PM_OFFICES?.length != 0 && (
                    <Menu.Item
                      key="42"
                      onClick={() => {
                        history.push(`/deductions-table`);
                      }}
                    >
                      <Icon type="database" />
                      <span>{t("general.deductions")}</span>
                    </Menu.Item>
                  )}
                  {HR_PM_OFFICES?.length != 0 && (
                    <Menu.Item
                      key="43"
                      onClick={() => {
                        history.push(`/salary-table`);
                      }}
                    >
                      <Icon type="database" />
                      <span>{t("general.salaries")}</span>
                    </Menu.Item>
                  )}
                  {HR_PM_OFFICES?.length != 0 && (
                    <Menu.Item
                      key="44"
                      onClick={() => {
                        history.push(`/workOffice-table`);
                      }}
                    >
                      <Icon type="database" />
                      <span>{t("general.workOffices")}</span>
                    </Menu.Item>
                  )}
                  {HR_PM_OFFICES?.length != 0 && (
                    <Menu.Item
                      key="45"
                      onClick={() => {
                        history.push(`/supervisor-table`);
                      }}
                    >
                      <Icon type="database" />
                      <span>{t("general.supervisors")}</span>
                    </Menu.Item>
                  )}
                  {HR_PM_OFFICES?.length != 0 && (
                    <Menu.Item
                      key="26"
                      onClick={() => {
                        history.push(`/positions-table`);
                      }}
                    >
                      <Icon type="interaction" />
                      <span>{t("general.positionsTable")}</span>
                    </Menu.Item>
                  )}{" "}
                  {HR_PM_OFFICES?.length != 0 && (
                    <Menu.Item
                      key="46"
                      onClick={() => {
                        history.push(`/payment-type-table`);
                      }}
                    >
                      <Icon type="database" />
                      <span>{t("general.paymentTypes")}</span>
                    </Menu.Item>
                  )}
                  {HR_PM_OFFICES?.length != 0 && (
                    <Menu.Item
                      key="47"
                      onClick={() => {
                        history.push(`/contract-percentage-table`);
                      }}
                    >
                      <Icon type="database" />
                      <span>{t("general.contractPercentages")}</span>
                    </Menu.Item>
                  )}
                  {HR_PM_OFFICES?.length != 0 && (
                    <Menu.Item
                      key="51"
                      onClick={() => {
                        history.push(`/accountants-table`);
                      }}
                    >
                      <Icon type="interaction" />
                      <span>{t("general.accountants")}</span>
                    </Menu.Item>
                  )}
                  <Menu.Item
                    key="52"
                    onClick={() => {
                      history.push(`/shifts-table`);
                    }}
                  >
                    <Icon type="calendar" />
                    <span>{t("general.shiftsManagement")}</span>
                  </Menu.Item>
                </SubMenu>
              ) : null}
              {HR_PM_OFFICES?.length != 0 ? (
                <SubMenu
                  key="sub6"
                  title={
                    <div>
                      <Icon type="database" /> <span>{t("general.allocationManagement")}</span>
                    </div>
                  }
                >
                  <Menu.Item
                    key="19"
                    onClick={() => {
                      history.push(`/project-table`);
                    }}
                  >
                    <Icon type="database" />
                    <span>{t("general.projects")}</span>
                  </Menu.Item>
                  <Menu.Item
                    key="20"
                    onClick={() => {
                      history.push(`/budgetLines-table`);
                    }}
                  >
                    <Icon type="database" />
                    <span>{t("general.budgetLines")}</span>
                  </Menu.Item>
                  <Menu.Item
                    key="21"
                    onClick={() => {
                      history.push(`/allocations-table`);
                    }}
                  >
                    <Icon type="folder-open" />
                    <span>{t("general.allocationTable")}</span>
                  </Menu.Item>
                </SubMenu>
              ) : null}

              {admins.includes(uid) || tempAdmins.includes(uid) ? ( // TODO:
                <Menu.Item
                  key="22"
                  onClick={() => {
                    history.push(`/office-table`);
                  }}
                >
                  <Icon type="bank" />
                  <span>{t("general.offices")}</span>
                </Menu.Item>
              ) : null}

              {HR_PM_OFFICES?.length != 0 ? (
                <SubMenu
                  key="sub7"
                  title={
                    <div>
                      <Icon type="database" /> <span>{t("general.payroll")}</span>
                    </div>
                  }
                >
                  <Menu.Item
                    key="49"
                    onClick={() => {
                      history.push(`/payroll-byOffice`);
                    }}
                  >
                    <Icon type="database" />
                    <span>{t("general.byOffice")}</span>
                  </Menu.Item>
                  <Menu.Item
                    key="50"
                    onClick={() => {
                      history.push(`/payroll-byProject`);
                    }}
                  >
                    <Icon type="database" />
                    <span>{t("general.byProject")}</span>
                  </Menu.Item>
                  <Menu.Item
                    key="53"
                    onClick={() => {
                      history.push(`/payroll-byCluster`);
                    }}
                  >
                    <Icon type="database" />
                    <span>{t("general.byCluster")}</span>
                  </Menu.Item>
                </SubMenu>
              ) : null}

              {HR_PM_OFFICES?.length != 0 ? (
                <SubMenu
                  key="sub9"
                  title={
                    <div>
                      <Icon type="database" /> <span>{t("general.payslip")}</span>
                    </div>
                  }
                >
                  <Menu.Item
                    key="49"
                    onClick={() => {
                      history.push(`/payslip-byOffice`);
                    }}
                  >
                    <Icon type="database" />
                    <span>{t("general.byOffice")}</span>
                  </Menu.Item>
                </SubMenu>
              ) : null}
              {admins.includes(uid) ? ( // TODO:
                <SubMenu
                  key="sub8"
                  title={
                    <div>
                      <Icon type="database" /> <span>{t("general.SOPs")}</span>
                    </div>
                  }
                >
                  <Menu.Item
                    key="49"
                    onClick={() => {
                      history.push(`/departments-table`);
                    }}
                  >
                    <Icon type="database" />
                    <span>{t("general.departments")}</span>
                  </Menu.Item>
                </SubMenu>
              ) : null}
              {userData?.permissions?.cases.includes("admin") ||
              userData?.permissions?.cases.includes("program") ||
              userData?.permissions?.cases.includes("CEO") ? (
                <Menu.Item
                  key="31"
                  onClick={() => {
                    history.push(`/case-table`);
                  }}
                >
                  <Icon type="diff" />
                  <span>{t("general.cases")}</span>
                </Menu.Item>
              ) : null}

              {admins.includes(uid) ? ( // TODO:
                <Menu.Item
                  key="32"
                  onClick={() => {
                    history.push(`/missions`);
                  }}
                >
                  <Icon type="apartment" />
                  <span>{t("general.missions")}</span>
                </Menu.Item>
              ) : null}
              {/* {admins.includes(uid) ? ( // TODO:
                <Menu.Item
                  key="33"
                  onClick={() => {
                    history.push(`/car-table`);
                  }}
                >
                  <Icon type="car" />
                  <span>{t("general.cars")}</span>
                </Menu.Item>
              ) : null} */}
              <Divider />
              <Menu.Item
                key="81"
                onClick={() => {
                  history.push(`/reset-password`);
                }}
              >
                <Icon type="lock" />
                <span>{t("general.resetPassword")}</span>
              </Menu.Item>
              <Menu.Item
                key="7"
                onClick={() => {
                  signedout();
                }}
              >
                <Icon type="logout" />
                <span>{t("general.logOut")}</span>
              </Menu.Item>
            </Menu>
          </Sider>

          <Container maxWidth="xl">
            <Layout
              style={{
                marginLeft: collapsed ? 0 : 0,
                // marginBottom: "0%",
                transition: "all 0.2s",
                marginTop: 0,
                padding: 20,
              }}
            >
              <Content>
                <TitleContext.Provider value={[title, setTitle]}>
                  {!closeAlert && badgeStatue ? (
                    <div
                      onClick={() => {
                        history.push("/profile");
                        setCloseAlert(true);
                      }}
                    >
                      <Alert
                        showIcon
                        banner={false}
                        message="Please Complete your profile information"
                        description="Go to your profile by clicking here!"
                        style={{ width: "50%" }}
                        type="warning"
                        closable={true}
                      />
                    </div>
                  ) : null}
                  {!closeAlertSig && badgeSignature ? (
                    <div
                      onClick={() => {
                        history.push("/signature");
                        setCloseAlertSig(true);
                      }}
                    >
                      <Alert
                        showIcon
                        banner={false}
                        message="Please Upload your Signature"
                        description="Go to your Signature Panel by clicking here!"
                        style={{ width: "50%" }}
                        type="error"
                        closable={true}
                      />
                    </div>
                  ) : null}

                  <div style={{ marginTop: 16, minHeight: "1000px" }}>{children}</div>
                </TitleContext.Provider>
              </Content>
              <Footer style={{ textAlign: "center" }}>{t("general.footer")}</Footer>
            </Layout>
          </Container>
        </Layout>
      </Layout>
    </div>
  );
}

export default LayoutApp;
