import React, { useState, useEffect } from "react";
import { Button, Drawer, Form, InputNumber, message, Input, Row, Col } from "antd";
const { TextArea } = Input;
import moment from "moment";
import { useFormik } from "formik";
import * as Yup from "yup";

import { useTranslation } from "react-i18next";
import { useAppDispatch } from "../../hooks/reduxHooks";
import { addRoom } from "../../features/roomsList/roomsListSlice";

interface Props {
  modalState: boolean;
  setModalState: React.Dispatch<React.SetStateAction<boolean>>;
}
const projectSchema = Yup.object().shape({
  roomName: Yup.string().required("*"),
});

function AddNewRoomModel(props: Props) {
  const dispatch = useAppDispatch();
  const { t } = useTranslation();

  const { modalState, setModalState } = props;
  const [loading, setLoading] = useState(false);

  const formik = useFormik({
    initialValues: {
      roomName: "",
      location: "",
      capacity: 0,
      contains: [],
    },
    validationSchema: projectSchema,
    onSubmit: async (values) => {
      dispatch(
        addRoom({
          roomName: values.roomName,
          location: values.location,
          capacity: values.capacity,
          contains: values.contains as any,
          createdAt: moment() as any,
        })
      )
        .then(() => {
          setModalState(false);
          setLoading(false);
        })
        .catch((error) => {
          setLoading(false);
          console.error("Error writing document: ", error);
          message.error("Failed");
        });
    },
  });
  useEffect(() => {
    return () => {
      if (formik.isValid == false) {
        setLoading(false);
      }
    };
  }, [formik.isValid]);

  const enterLoading = () => {
    setLoading(true);
  };

  return (
    <Drawer
      title={t("general.addNewRoom")}
      width={1000}
      height={350}
      visible={modalState}
      placement={"bottom"}
      onClose={() => setModalState(false)}
    >
      <Form onSubmit={formik.handleSubmit}>
        <Row gutter={16}>
          <Col span={10}>
            <Form.Item label={t("general.roomName")}>
              <Input
                value={formik.values.roomName}
                onChange={(e) => formik.setFieldValue("roomName", e.target.value)}
              />
            </Form.Item>
          </Col>
          <Col span={10}>
            <Form.Item label={t("general.location")}>
              <Input
                value={formik.values.location}
                onChange={(e) => formik.setFieldValue("location", e.target.value)}
              />
            </Form.Item>
          </Col>
          <Col span={4}>
            <Form.Item label={t("general.capacitySits")}>
              <InputNumber value={formik.values.capacity} onChange={(e) => formik.setFieldValue("capacity", e)} />
            </Form.Item>
          </Col>
        </Row>
        <Row gutter={16}>
          <Col span={16}>
            <Form.Item label={t("general.contains")}>
              <TextArea
                value={formik.values.contains}
                onChange={(e) => formik.setFieldValue("contains", e.target.value)}
              />
            </Form.Item>
          </Col>
        </Row>

        <div>
          <Button
            className="btn blue lighten-1 z-depth-0"
            onClick={() => {
              enterLoading();
            }}
            loading={loading}
            type="primary"
            htmlType="submit"
            disabled={!formik.isValid}
          >
            {t("general.create")}
          </Button>
        </div>
      </Form>
    </Drawer>
  );
}

export default AddNewRoomModel;
