import React, { useEffect, useState } from "react";
import { Form, Input, message, Modal, Select } from "antd";
import { mission } from "../../db/interfaces";
import { useFormik } from "formik";
import moment from "moment";
import { useAppDispatch, useAppSelector } from "../../hooks/reduxHooks";
import { USERS_ID_FULL_NAME } from "../../global/usersList";
import { addMission, editMission } from "../../features/missions/missionsSlice";
const { Option } = Select;

interface Props {
  mission?: mission;
  modalState: boolean;
  setModalState: any;
}

function MissionDialog(props: Props) {
  const { currentUser } = useAppSelector((state) => state.auth);
  const dispatch = useAppDispatch();

  const { mission, modalState, setModalState } = props;
  const uid = currentUser.id as string;

  const formik = useFormik({
    initialValues: {
      missionName: "",
      missionLocation: "",
      weekendDays: [],
      holidayDates: [],
      logo: "",
      style: "",
      missionCEO: [],
    },
    onSubmit: async (values, form) => {
      if (mission) {
        await dispatch(
          editMission({
            id: mission.id,
            missionName: values.missionName as string,
            missionLocation: values.missionLocation as string,
            weekendDays: values.weekendDays as string[],
            holidayDates: values.holidayDates as string[],
            createdBy: uid,
            createdAt: moment() as any,
            missionCEO: values.missionCEO as string[],
          } as any)
        ).then(() => {
          message.success("Updated Successfully!");
          setModalState(false);
        });
      } else {
        await dispatch(
          addMission({
            missionName: values.missionName as string,
            missionLocation: values.missionLocation as string,
            weekendDays: values.weekendDays as string[],
            holidayDates: values.holidayDates as string[],
            createdBy: uid,
            createdAt: moment() as any,
            missionCEO: values.missionCEO as string[],
            logo: "",
            style: {},
          })
        ).then(() => {
          message.success("Added Successfully!");
          setModalState(false);
        });
      }
    },
  });

  const [loaded, setLoaded] = useState(true);
  useEffect(() => {
    if (mission && loaded) {
      formik.setValues({
        missionName: mission.missionName,
        missionLocation: mission.missionLocation,
        weekendDays: mission.weekendDays,
        holidayDates: mission.holidayDates,
        missionCEO: mission.missionCEO as string[],
      } as any);
      setLoaded(false);
    }
  }, [mission, formik, loaded]);

  return (
    <Modal
      closable={true}
      width={1200}
      visible={modalState}
      onCancel={async () => {
        setModalState(false);
      }}
      onOk={() => {
        formik.submitForm();
      }}
    >
      <div>
        <Form onSubmit={formik.handleSubmit}>
          <Form.Item label={"Mission CEO"} {...formItemLayout} required={false}>
            <Select
              filterOption={(inputValue, option) => {
                return (option.props.children as any).toLowerCase().indexOf(inputValue.toLowerCase()) >= 0;
              }}
              mode="multiple"
              showSearch
              value={formik.values.missionCEO}
              onChange={(e: any) => {
                formik.setFieldValue("missionCEO", e);
              }}
            >
              {USERS_ID_FULL_NAME?.map((d) => {
                return (
                  <Option key={d.uid} value={d.uid}>
                    {d.fullName}
                  </Option>
                );
              })}
            </Select>
          </Form.Item>

          <Form.Item label={"Mission Name"} {...formItemLayout} required={false}>
            <Input
              disabled={mission != undefined}
              value={formik.values.missionName}
              placeholder={"Mission Name"}
              onChange={(e) => formik.setFieldValue("missionName", e.target.value)}
            />
          </Form.Item>
          <Form.Item label={"Mission Location"} {...formItemLayout} required={false}>
            <Input
              value={formik.values.missionLocation}
              placeholder={"Mission Location"}
              onChange={(e) => formik.setFieldValue("missionLocation", e.target.value)}
            />
          </Form.Item>
          <Form.Item label={"Weekend Days"} {...formItemLayout} required={false}>
            <Select
              mode="multiple"
              showSearch
              value={formik.values.weekendDays}
              onChange={(e: any) => formik.setFieldValue("weekendDays", e)}
            >
              <Option value={"Sa"}>Saturday</Option>
              <Option value={"Su"}>Sunday</Option>
              <Option value={"Mo"}>Monday</Option>
              <Option value={"Tu"}>Tuesday</Option>
              <Option value={"We"}>Wednesday</Option>
              <Option value={"Th"}>Thursday</Option>
              <Option value={"Fr"}>Friday</Option>
            </Select>
          </Form.Item>

          <Form.Item label={"Official Holidays"} {...formItemLayout} required={false}>
            <Select
              mode="tags"
              value={formik.values.holidayDates}
              onChange={(e: any) => formik.setFieldValue("holidayDates", e)}
            />
          </Form.Item>
        </Form>
      </div>
    </Modal>
  );
}

export default MissionDialog;

const formItemLayout = {
  labelCol: {
    xs: { span: 24 },
    sm: { span: 4 },
  },
  wrapperCol: {
    xs: { span: 24 },
    sm: { span: 15 },
  },
};
