import { fetchBudgetLinesInBatches } from "../features/budgetLines/budgetLinesSlice";
import { RootState, store } from "../store";

const initializeBudgetLinesData = async () => {
  const state: RootState = store.getState();
  const { loaded } = state.budgetLines;

  if (!loaded) {
    await store.dispatch(fetchBudgetLinesInBatches());
  }
};

// Immediately call to ensure data is fetched
initializeBudgetLinesData();

export const getBudgetLinesData = () => {
  const state: RootState = store.getState();
  const { globalBudgetLinesData, loaded } = state.budgetLines;
  // if (!loaded) {
  //   console.warn("Department Positions data is not loaded yet.");
  // }

  return {
    globalBudgetLinesData,
  };
};

// Initial export values, populated from the Redux store
export let globalBudgetLinesData = getBudgetLinesData().globalBudgetLinesData;

// Subscribe to store updates and dynamically update exports when the Redux state changes
store.subscribe(() => {
  const { globalBudgetLinesData: updatedGlobalBudgetLinesData } = getBudgetLinesData();

  // Update the exports with the latest values from the Redux store
  globalBudgetLinesData = updatedGlobalBudgetLinesData;
});
