import { createSlice, createAsyncThunk, PayloadAction } from "@reduxjs/toolkit";
import { supabase } from "../../supabaseClient"; // Adjust the import path as needed
import { project } from "../../db/interfaces"; // Make sure your project interface is defined here

interface projectsSliceState {
  data: project[];
  status: "loading" | "succeeded" | "failed" | "idle";
  error: string | null;
  globalProjectsData: Record<string, project>;
  loaded: boolean;
}

const initialState: projectsSliceState = {
  data: [],
  status: "idle",
  error: null,
  globalProjectsData: {},
  loaded: false,
};

// Fetch all projects in batches
export const fetchProjectsInBatches = createAsyncThunk(
  "projects/fetchProjectsInBatches",
  async (_, { rejectWithValue }) => {
    const { data, error } = await supabase.from("projects").select("*");
    if (error) {
      return rejectWithValue(error.message as string);
    }

    return data;
  }
);

export const fetchAllProjects = createAsyncThunk<project[], void, { rejectValue: string }>(
  "allocation/fetchAllProjects",
  async (_, thunkApi) => {
    const { data, error } = await supabase.from("projects").select();
    if (error) {
      return thunkApi.rejectWithValue(error.message);
    }
    return data as project[];
  }
);

// Add a new project
export const addProject = createAsyncThunk<project, project, { rejectValue: string }>(
  "projects/addProject",
  async (newProject, thunkApi) => {
    const { data: insertData, error: insertError } = await supabase
      .from("projects")
      .insert(newProject)
      .select()
      .single();

    if (insertError) {
      return thunkApi.rejectWithValue(insertError.message);
    }

    return insertData as project;
  }
);

// Delete a project
export const deleteProject = createAsyncThunk<string, string, { rejectValue: string }>(
  "projects/deleteProject",
  async (id, thunkApi) => {
    const { error } = await supabase.from("projects").delete().eq("id", id);

    if (error) {
      return thunkApi.rejectWithValue(error.message);
    }

    return id;
  }
);

// Edit a project
export const editProject = createAsyncThunk<project, project, { rejectValue: string }>(
  "projects/editProject",
  async (updatedProject, thunkApi) => {
    const { data: updateData, error: updateError } = await supabase
      .from("projects")
      .update(updatedProject)
      .eq("id", updatedProject.id)
      .select()
      .single();

    if (updateError) {
      return thunkApi.rejectWithValue(updateError.message);
    }

    return updateData as project;
  }
);

// Real-time actions
export const addProjectRealTime = (newProject: project): PayloadAction<project> => ({
  type: "projects/addProjectRealTime",
  payload: newProject,
});

export const editProjectRealTime = (updatedProject: project): PayloadAction<project> => ({
  type: "projects/editProjectRealTime",
  payload: updatedProject,
});

export const deleteProjectRealTime = (id: string): PayloadAction<string> => ({
  type: "projects/deleteProjectRealTime",
  payload: id,
});

// Slice definition
const projectsSlice = createSlice({
  name: "projects",
  initialState,
  reducers: {
    addProjectRealTime(state, action: PayloadAction<project>) {
      const exists = state.data.some((project) => project.id === action.payload.id);
      if (!exists) {
        state.data.push(action.payload);
      }
      state.error = null;
    },
    editProjectRealTime(state, action: PayloadAction<project>) {
      const index = state.data.findIndex((project) => project.id === action.payload.id);
      if (index !== -1) {
        const isDifferent = JSON.stringify(state.data[index]) !== JSON.stringify(action.payload);
        if (isDifferent) {
          state.data[index] = action.payload;
        }
      }
      state.error = null;
    },
    deleteProjectRealTime(state, action: PayloadAction<string>) {
      const exists = state.data.some((project) => project.id === action.payload);
      if (exists) {
        state.data = state.data.filter((project) => project.id !== action.payload);
      }
      state.error = null;
    },
  },
  extraReducers: (builder) => {
    builder

      .addCase(fetchAllProjects.pending, (state) => {
        state.status = "loading";
      })
      .addCase(fetchAllProjects.fulfilled, (state, action: PayloadAction<project[]>) => {
        state.status = "succeeded";
        state.data = action.payload;
        state.error = null;
      })
      .addCase(fetchAllProjects.rejected, (state, action: PayloadAction<string | undefined>) => {
        state.status = "failed";
        state.error = action.payload || "Failed to fetch allocations.";
      })

      // Handle fetchProjectsInBatches
      .addCase(fetchProjectsInBatches.pending, (state) => {
        state.status = "loading";
      })
      .addCase(fetchProjectsInBatches.fulfilled, (state, action) => {
        state.loaded = true;
        action.payload.forEach((project) => {
          state.globalProjectsData[project.id ?? ""] = project;
        });
      })
      .addCase(fetchProjectsInBatches.rejected, (state, action) => {
        state.status = "failed";
        state.error = (action.payload as string) || "Failed to fetch projects.";
      })

      // Handle addProject
      .addCase(addProject.fulfilled, (state, action: PayloadAction<project>) => {
        state.data.push(action.payload);
        state.error = null;
      })
      .addCase(addProject.rejected, (state, action: PayloadAction<string | undefined>) => {
        state.error = action.payload || "Failed to add project.";
      })

      // Handle deleteProject
      .addCase(deleteProject.fulfilled, (state, action: PayloadAction<string>) => {
        state.data = state.data.filter((project) => project.id !== action.payload);
        state.error = null;
      })
      .addCase(deleteProject.rejected, (state, action: PayloadAction<string | undefined>) => {
        state.error = action.payload || "Failed to delete project.";
      })

      // Handle editProject
      .addCase(editProject.fulfilled, (state, action: PayloadAction<project>) => {
        const index = state.data.findIndex((project) => project.id === action.payload.id);
        if (index !== -1) {
          state.data[index] = action.payload;
        }
        state.error = null;
      })
      .addCase(editProject.rejected, (state, action: PayloadAction<string | undefined>) => {
        state.error = action.payload || "Failed to edit project.";
      });
  },
});

// Real-time subscription function
export const subscribeToProjects = (dispatch: any) => {
  const subscription = supabase
    .channel("public:projects")
    .on("postgres_changes", { event: "*", schema: "public", table: "projects" }, (payload) => {
      const newRecord = payload.new as project;
      const oldRecord = payload.old as project;

      switch (payload.eventType) {
        case "INSERT":
          dispatch(addProjectRealTime(newRecord));
          break;
        case "UPDATE":
          dispatch(editProjectRealTime(newRecord));
          break;
        case "DELETE":
          dispatch(deleteProjectRealTime(oldRecord.id ?? ""));
          break;
        default:
          break;
      }
    })
    .subscribe();

  return () => {
    subscription.unsubscribe();
  };
};

export default projectsSlice.reducer;
