import { createSlice, createAsyncThunk, PayloadAction } from "@reduxjs/toolkit";
import { supabase } from "../../supabaseClient"; // Adjust the import path as needed
import { car } from "../../db/interfaces"; // Adjust the import path as needed

interface CarsSliceState {
  data: car[];
  car: car;
  status: "loading" | "succeeded" | "failed" | "idle";
  error: string | null;
}

const initialState: CarsSliceState = {
  data: [],
  car: {} as car,
  status: "idle",
  error: null,
};

// Fetch all cars
export const fetchAllCars = createAsyncThunk<car[], void, { rejectValue: string }>(
  "cars/fetchAllCars",
  async (_, thunkApi) => {
    const { data, error } = await supabase.from("cars").select("*");
    if (error) {
      return thunkApi.rejectWithValue(error.message);
    }
    return data as car[];
  }
);

// Fetch car by ID
export const fetchCarById = createAsyncThunk<car, string, { rejectValue: string }>(
  "cars/fetchCarById",
  async (carId, thunkApi) => {
    const { data, error } = await supabase.from("cars").select("*").eq("id", carId).single();
    if (error) {
      return thunkApi.rejectWithValue(error.message);
    }
    return data as car;
  }
);

// Fetch cars by user
export const fetchCarsByUser = createAsyncThunk<car[], string, { rejectValue: string }>(
  "cars/fetchCarsByUser",
  async (userId, thunkApi) => {
    const { data, error } = await supabase.from("cars").select("*").eq("user_id", userId);
    if (error) {
      return thunkApi.rejectWithValue(error.message);
    }
    return data as car[];
  }
);

// Add a new car
export const addCar = createAsyncThunk<car, car, { rejectValue: string }>(
  "cars/addCar",
  async (newCar, thunkApi) => {
    const { data: insertData, error: insertError } = await supabase
      .from("cars")
      .insert(newCar)
      .select()
      .single();

    if (insertError) {
      return thunkApi.rejectWithValue(insertError.message);
    }

    return insertData as car;
  }
);

// Edit a car
export const editCar = createAsyncThunk<car, car, { rejectValue: string }>(
  "cars/editCar",
  async (updatedCar, thunkApi) => {
    const { data: updateData, error: updateError } = await supabase
      .from("cars")
      .update(updatedCar)
      .eq("id", updatedCar.id)
      .select()
      .single();

    if (updateError) {
      return thunkApi.rejectWithValue(updateError.message);
    }

    return updateData as car;
  }
);

// Delete a car
export const deleteCar = createAsyncThunk<string, string, { rejectValue: string }>(
  "cars/deleteCar",
  async (id, thunkApi) => {
    const { error } = await supabase.from("cars").delete().eq("id", id);

    if (error) {
      return thunkApi.rejectWithValue(error.message);
    }

    return id;
  }
);

// Real-Time Actions
export const addCarRealTime = (newCar: car): PayloadAction<car> => ({
  type: "cars/addCarRealTime",
  payload: newCar,
});

export const editCarRealTime = (updatedCar: car): PayloadAction<car> => ({
  type: "cars/editCarRealTime",
  payload: updatedCar,
});

export const deleteCarRealTime = (id: string): PayloadAction<string> => ({
  type: "cars/deleteCarRealTime",
  payload: id,
});

// Slice definition
const carsSlice = createSlice({
  name: "cars",
  initialState,
  reducers: {
    addCarRealTime(state, action: PayloadAction<car>) {
      const exists = state.data.some((car) => car.id === action.payload.id);
      if (!exists) {
        state.data = [...state.data, action.payload];
      }
      state.error = null;
    },
    editCarRealTime(state, action: PayloadAction<car>) {
      const index = state.data.findIndex((car) => car.id === action.payload.id);
      if (index !== -1) {
        const isDifferent = JSON.stringify(state.data[index]) !== JSON.stringify(action.payload);
        if (isDifferent) {
          state.data[index] = action.payload;
        }
      }
      state.error = null;

      if (state.car.id === action.payload.id) {
        state.car = action.payload;
      }
    },
    deleteCarRealTime(state, action: PayloadAction<string>) {
      const exists = state.data.some((car) => car.id === action.payload);
      if (exists) {
        state.data = state.data.filter((car) => car.id !== action.payload);
      }
      state.error = null;
    },
  },
  extraReducers: (builder) => {
    builder
      // Handle fetchAllCars
      .addCase(fetchAllCars.pending, (state) => {
        state.status = "loading";
      })
      .addCase(fetchAllCars.fulfilled, (state, action: PayloadAction<car[]>) => {
        state.status = "succeeded";
        state.data = action.payload;
        state.error = null;
      })
      .addCase(fetchAllCars.rejected, (state, action: PayloadAction<string | undefined>) => {
        state.status = "failed";
        state.error = action.payload || "Failed to fetch cars.";
      })

      // Handle fetchCarById
      .addCase(fetchCarById.pending, (state) => {
        state.status = "loading";
      })
      .addCase(fetchCarById.fulfilled, (state, action: PayloadAction<car>) => {
        state.status = "succeeded";
        state.car = action.payload;
        state.error = null;
      })
      .addCase(fetchCarById.rejected, (state, action: PayloadAction<string | undefined>) => {
        state.status = "failed";
        state.error = action.payload || "Failed to fetch car.";
      })

      // Handle fetchCarsByUser
      .addCase(fetchCarsByUser.pending, (state) => {
        state.status = "loading";
      })
      .addCase(fetchCarsByUser.fulfilled, (state, action: PayloadAction<car[]>) => {
        state.status = "succeeded";
        state.data = action.payload;
        state.error = null;
      })
      .addCase(fetchCarsByUser.rejected, (state, action: PayloadAction<string | undefined>) => {
        state.status = "failed";
        state.error = action.payload || "Failed to fetch cars by user.";
      })

      // Handle addCar
      .addCase(addCar.pending, (state) => {})
      .addCase(addCar.fulfilled, (state, action: PayloadAction<car>) => {
        state.error = null;
      })
      .addCase(addCar.rejected, (state, action: PayloadAction<string | undefined>) => {
        state.error = action.payload || "Failed to add car.";
      })

      // Handle editCar
      .addCase(editCar.pending, (state) => {})
      .addCase(editCar.fulfilled, (state, action: PayloadAction<car>) => {
        state.error = null;
      })
      .addCase(editCar.rejected, (state, action: PayloadAction<string | undefined>) => {
        state.status = "failed";
        state.error = action.payload || "Failed to edit car.";
      })

      // Handle deleteCar
      .addCase(deleteCar.pending, (state) => {})
      .addCase(deleteCar.fulfilled, (state, action: PayloadAction<string>) => {
        state.error = null;
      })
      .addCase(deleteCar.rejected, (state, action: PayloadAction<string | undefined>) => {
        state.status = "failed";
        state.error = action.payload || "Failed to delete car.";
      });
  },
});

// Real-time subscription function
export const subscribeToCars = (dispatch: any) => {
  const subscription = supabase
    .channel("public:cars")
    .on("postgres_changes", { event: "*", schema: "public", table: "cars" }, (payload) => {
      const newRecord = payload.new as car;
      const oldRecord = payload.old as car;

      switch (payload.eventType) {
        case "INSERT":
          dispatch(addCarRealTime(newRecord));
          break;
        case "UPDATE":
          dispatch(editCarRealTime(newRecord));
          break;
        case "DELETE":
          dispatch(deleteCarRealTime(oldRecord.id as string));
          break;
        default:
          break;
      }
    })
    .subscribe();

  return () => {
    subscription.unsubscribe();
  };
};

export default carsSlice.reducer;
