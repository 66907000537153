import React, { useState } from "react";
import { Modal } from "antd";
import { Case } from "../../db/interfaces";
import DevTable from "../../helpers/devex-table";
import EditCase from "./EditCase";

interface Props {
  status: string;
  office: string;
  assignedTo: string;
  setStatus: any;
  setOffice: any;
  setAssignedTo: any;
  columns: any[];
  columnsExport: any[];
  modalState: boolean;
  setModalState: any;
  startDate: any;
  endDate: any;
  loading: boolean;
  setLoading: any;
  casesData: Case[];
}

function TableDialog(props: Props) {
  const {
    setStatus,
    setOffice,
    setAssignedTo,
    status,
    office,
    assignedTo,
    columns,
    columnsExport,
    modalState,
    setModalState,
    casesData,
  } = props;
  const [modalStateCase, setModalStateCase] = useState(false);
  const [caseDoc, setCaseDoc] = useState({} as Case);

  const data = casesData.filter((c) => {
    if (office != "") {
      return c.cacheOffice == office;
    } else if (assignedTo != "") {
      return c.cacheAssignedTo == assignedTo;
    } else {
      return c.status == status;
    }
  });

  return (
    <Modal
      closable={true}
      width={1200}
      visible={modalState}
      onCancel={async () => {
        setModalState(false);
        setStatus("");
        setOffice("");
        setAssignedTo("");
      }}
    >
      <div>
        {modalStateCase ? (
          <EditCase modalState={modalStateCase} setModalState={setModalStateCase} caseDoc={caseDoc} />
        ) : null}
        <DevTable
          data={data}
          columns={columns}
          defaultHiddenColumnNames={[]}
          tableName="cases"
          selected={false}
          typicalTable={false}
          defaultColumnWidths={[]}
          columnsExport={columnsExport}
          rowClick={({ args, row }) => {
            setCaseDoc(row);
            setModalStateCase(true);
          }}
        />
      </div>
    </Modal>
  );
}

export default TableDialog;
