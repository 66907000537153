// authSlice.ts

import { createSlice, createAsyncThunk, PayloadAction } from "@reduxjs/toolkit";
import { supabase } from "../../supabaseClient"; // Adjust the import path as needed
import { User } from "@supabase/supabase-js"; // Import User type from Supabase
import { users } from "../../db/interfaces";
import { fetchUsersInBatches } from "../users/usersSlice";

interface AuthState {
  user: User | null;
  currentUser: users;
  status: "idle" | "loading" | "authenticated" | "unauthenticated" | "failed";
  currentUserStatus: "idle" | "loading" | "userDocSuccess" | "success" | "failed";
  error: string | null;
}

const initialState: AuthState = {
  user: null,
  currentUser: {} as users,
  status: "idle",
  currentUserStatus: "idle",
  error: null,
};

// Check for authentication changes

export const checkAuthChange = createAsyncThunk<void, void, { rejectValue: string }>(
  "auth/checkAuthChange",
  async (_, thunkApi) => {
    try {
      const {
        data: { session },
      } = await supabase.auth.getSession();

      if (session?.user) {
        thunkApi.dispatch(authSlice.actions.setAuthenticated(session.user));
        thunkApi.dispatch(fetchUserDocById(session.user.email?.split("@")[0]?.toUpperCase() ?? ""));
        thunkApi.dispatch(fetchUsersInBatches());
      } else {
        thunkApi.dispatch(authSlice.actions.setUnauthenticated());
      }
    } catch (error: any) {
      console.error("Error in checkAuthChange:", error.message);
      return thunkApi.rejectWithValue(error.message);
    }
  }
);

// Sign in a user
export const signIn = createAsyncThunk<User, { email: string; password: string }, { rejectValue: string }>(
  "auth/signIn",
  async ({ email, password }, thunkApi) => {
    try {
      const {
        data: { session, user },
        error,
      } = await supabase.auth.signInWithPassword({ email, password });

      if (session?.user) {
        thunkApi.dispatch(authSlice.actions.setAuthenticated(session?.user));
        thunkApi.dispatch(fetchUserDocById(session?.user.email?.split("@")[0]?.toUpperCase() ?? ""));
      } else {
        thunkApi.dispatch(authSlice.actions.setUnauthenticated());
      }
      if (error) {
        return thunkApi.rejectWithValue(error.message);
      }

      return user!;
    } catch (error: any) {
      console.log({ error });
      return thunkApi.rejectWithValue(error.message);
    }
  }
);

// Sign out a user
export const signOut = createAsyncThunk<void, void, { rejectValue: string }>("auth/signOut", async (_, thunkApi) => {
  const { error } = await supabase.auth.signOut();
  if (error) {
    return thunkApi.rejectWithValue(error.message);
  }
});

// Reset a user's password to a default value ("ida12345")
export const resetPassword = createAsyncThunk<{ userId: string }, string, { rejectValue: string }>(
  "auth/resetPassword",
  async (userId: string, thunkApi) => {
    try {
      // Define the new default password
      const newPassword = "ida12345";
      // Update the password to the default one using the user ID
      const { error: updateError } = await supabase.auth.updateUser({
        email: `${userId}@ida-org.com`,
        password: newPassword,
      });

      if (updateError) {
        return thunkApi.rejectWithValue(updateError.message);
      }

      return { userId, newPassword };
    } catch (error: any) {
      return thunkApi.rejectWithValue(error.message);
    }
  }
);

export const resetMyPassword = createAsyncThunk<
  { userId: string },
  { userId: string; newPassword: string },
  { rejectValue: string }
>("auth/resetMyPassword", async ({ userId, newPassword }, thunkApi) => {
  try {
    const { error: updateError } = await supabase.auth.updateUser({
      email: `${userId}@ida-org.com`,
      password: newPassword,
    });

    if (updateError) {
      return thunkApi.rejectWithValue(updateError.message);
    }

    return { userId, newPassword };
  } catch (error: any) {
    return thunkApi.rejectWithValue(error.message);
  }
});

// Fetch user document by ID
export const fetchUserDocById = createAsyncThunk<any, string, { rejectValue: string }>(
  "auth/fetchUserDocById",
  async (userId, thunkApi) => {
    try {
      const { data, error } = await supabase.from("users").select("*").eq("id", userId).single();
      if (error) {
        return thunkApi.rejectWithValue(error.message);
      }

      return data;
    } catch (error: any) {
      return thunkApi.rejectWithValue(error.message);
    }
  }
);

// Real-Time Actions
export const addUserRealTime = (newUser: users): PayloadAction<users> => ({
  type: "users/addUserRealTime",
  payload: newUser,
});

export const editUserRealTime = (updatedUser: users): PayloadAction<users> => ({
  type: "users/editUserRealTime",
  payload: updatedUser,
});

export const deleteUserRealTime = (id: string): PayloadAction<string> => ({
  type: "users/deleteUserRealTime",
  payload: id,
});

const authSlice = createSlice({
  name: "auth",
  initialState,
  reducers: {
    setAuthenticated(state, action: PayloadAction<User>) {
      state.user = action.payload;
      state.status = "authenticated";
      state.error = null;
    },
    setUnauthenticated(state) {
      state.user = null;
      state.status = "unauthenticated";
      state.error = null;
    },
    setAuthError(state, action: PayloadAction<string>) {
      state.status = "failed";
      state.error = action.payload;
    },
  },
  extraReducers: (builder) => {
    // Handle checkAuthChange
    builder
      .addCase(checkAuthChange.pending, (state) => {
        state.status = "idle";
      })
      .addCase(checkAuthChange.fulfilled, (state) => {
        // Status will be set by onAuthStateChange subscription
      })
      .addCase(checkAuthChange.rejected, (state, action) => {
        state.status = "failed";
        state.error = action.payload || "Failed to check authentication.";
      })

      // Handle signIn
      .addCase(signIn.pending, (state) => {
        state.status = "loading";
      })
      .addCase(signIn.fulfilled, (state, action: PayloadAction<User>) => {})
      .addCase(signIn.rejected, (state, action) => {
        state.status = "failed";
        state.error = action.payload || "Failed to sign in.";
      })

      // Handle signOut
      .addCase(signOut.pending, (state) => {
        state.status = "loading";
      })
      .addCase(signOut.fulfilled, (state) => {
        state.user = null;
        state.status = "unauthenticated";
        state.error = null;
      })
      .addCase(signOut.rejected, (state, action) => {
        state.status = "failed";
        state.error = action.payload || "Failed to sign out.";
      })

      // Handle fetchUserDocById
      .addCase(fetchUserDocById.pending, (state) => {
        state.currentUserStatus = "loading";
      })
      .addCase(fetchUserDocById.fulfilled, (state, action) => {
        const user = action.payload;
        state.currentUser = {
          ...user,
          fullName: user.firstName + " " + user.lastName,
          fullControlOffices: [
            ...user.HROffices,
            ...user.adminOffices,
            ...user.HRProjectsManagersOffices,
            ...user.viewersOffices,
          ],
        };
        state.currentUserStatus = "userDocSuccess";
        state.error = null;
      })
      .addCase(fetchUserDocById.rejected, (state, action) => {
        state.currentUserStatus = "failed";
        state.error = action.payload || "Failed to fetch user document.";
      })

      // Handle resetPassword
      .addCase(resetPassword.pending, (state) => {
        // state.status = "loading";
      })
      .addCase(resetPassword.fulfilled, (state, action) => {
        // state.status = "succeeded";
        state.error = null;
        console.log(`Password for user ${action.payload.userId} has been reset`);
      })
      .addCase(resetPassword.rejected, (state, action) => {
        // state.status = "failed";
        state.error = action.payload || "Failed to reset password.";
      })

      // Handle resetMyPassword
      .addCase(resetMyPassword.pending, (state) => {
        // state.status = "loading";
      })
      .addCase(resetMyPassword.fulfilled, (state, action) => {
        // state.status = "succeeded";
        state.error = null;
        console.log(`Password for user ${action.payload.userId} has been reset`);
      })
      .addCase(resetMyPassword.rejected, (state, action) => {
        // state.status = "failed";
        state.error = action.payload || "Failed to reset password.";
      });
  },
});

export const { setAuthenticated, setUnauthenticated, setAuthError } = authSlice.actions;
export default authSlice.reducer;
