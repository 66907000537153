import { configureStore } from "@reduxjs/toolkit";
import workOfficeReducer from "../src/features/workOffice/workOfficeSlice";
import authReducer from "../src/features/auth/authSlice";
import usersReducer from "../src/features/users/usersSlice";
import officesReducer from "../src/features/offices/officesSlice";
import tasksReducer from "../src/features/tasks/tasksSlice";
import projectsReducer from "../src/features/projects/projectsSlice";
import missionsReducer from "../src/features/missions/missionsSlice";
import budgetLinesReducer from "../src/features/budgetLines/budgetLinesSlice";
import departmentsReducer from "../src/features/departments/departmentsSlice";
import departmentPositionsReducer from "../src/features/departmentPositions/departmentPositionsSlice";
import allocationsReducer from "../src/features/allocations/allocationsSlice";
import positionsReducer from "../src/features/positions/positionsSlice";
import contractPercentageReducer from "../src/features/contractPercentage/contractPercentageSlice";
import salariesReducer from "../src/features/salaries/salariesSlice";
import paymentTypesReducer from "../src/features/paymentTypes/paymentTypesSlice";
import supervisorsReducer from "../src/features/supervisors/supervisorsSlice";
import deductionsReducer from "../src/features/deductions/deductionsSlice";
import accountantsReducer from "../src/features/accountants/accountantsSlice";
import shiftsReducer from "../src/features/shifts/shfitsSlice";
import vacationsReducer from "../src/features/vacations/vacationsSlice";
import delaysReducer from "../src/features/delays/delaysSlice";
import accessReducer from "../src/features/access/accessSlice";
import discussionsReducer from "../src/features/discussions/discussionsSlice";
import notificationsReducer from "../src/features/notifications/notificationsSlice";
import filtersReducer from "../src/features/filters/filtersSlice";
import overtimeReducer from "../src/features/overtime/overtimeSlice";
import casesReducer from "../src/features/cases/casesSlice";
import ordersReducer from "../src/features/orders/ordersSlice";
import roomsListReducer from "../src/features/roomsList/roomsListSlice";
import calendarReducer from "../src/features/calendar/calendarSlice";
import carsReducer from "../src/features/cars/carsSlice";
import carRequestsReducer from "../src/features/carRequests/carRequestsSlice";
import outdoorTasksReducer from "../src/features/outdoorTasks/outdoorTasksSlice";
import sheetsReducer from "../src/features/sheets/sheetsSlice";
import tagsReducer from "../src/features/tags/tagsSlice";
import positionTasksReducer from "../src/features/positionTasks/positionTasksSlice";

export const store = configureStore({
  reducer: {
    auth: authReducer,
    users: usersReducer,
    offices: officesReducer,
    tasks: tasksReducer,
    projects: projectsReducer,
    missions: missionsReducer,
    departments: departmentsReducer,
    departmentPositions: departmentPositionsReducer,
    budgetLines: budgetLinesReducer,
    allocations: allocationsReducer,
    workOffices: workOfficeReducer,
    positions: positionsReducer,
    contractPercentage: contractPercentageReducer,
    paymentTypes: paymentTypesReducer,
    supervisors: supervisorsReducer,
    salaries: salariesReducer,
    deductions: deductionsReducer,
    accountants: accountantsReducer,
    shifts: shiftsReducer,
    vacations: vacationsReducer,
    delays: delaysReducer,
    access: accessReducer,
    discussions: discussionsReducer,
    notifications: notificationsReducer,
    filters: filtersReducer,
    overtime: overtimeReducer,
    cases: casesReducer,
    orders: ordersReducer,
    rooms: roomsListReducer,
    calendar: calendarReducer,
    cars: carsReducer,
    carRequests: carRequestsReducer,
    outdoorTasks: outdoorTasksReducer,
    sheets: sheetsReducer,
    tags: tagsReducer,
    positionTasks:positionTasksReducer
  },
});

export type AppDispatch = typeof store.dispatch;
export type RootState = ReturnType<typeof store.getState>;
