import { createSlice, createAsyncThunk, PayloadAction } from "@reduxjs/toolkit";
import { supabase } from "../../supabaseClient"; // Adjust the import path as needed
import { position } from "../../db/interfaces"; // Adjust the import path as needed

interface positionSliceState {
  positions: position[];
  data: position[];
  status: "loading" | "succeeded" | "failed" | "idle";
  error: string | null;
}

const initialState: positionSliceState = {
  positions: [],
  data: [],
  status: "idle",
  error: null,
};

// Fetch all positions
export const fetchAllPositions = createAsyncThunk<position[], void, { rejectValue: string }>(
  "position/fetchAllPositions",
  async (_, thunkApi) => {
    console.log("fetching here")
    const { data, error } = await supabase.from("positions").select();
    if (error) {
      return thunkApi.rejectWithValue(error.message);
    }
    return data as position[];
  }
);

export const fetchPositionsByOffice = createAsyncThunk<position[], string, { rejectValue: string }>(
  "workOffice/fetchPositionsByOffice",
  async (officeId, thunkApi) => {
    const { data, error } = await supabase.from("positions").select("*").eq("office", officeId);

    if (error) {
      return thunkApi.rejectWithValue(error.message);
    }

    return data as position[];
  }
);

// Fetch positions by ID
export const fetchPositionsById = createAsyncThunk<position[], string, { rejectValue: string }>(
  "position/fetchPositionsById",
  async (positionId, thunkApi) => {
    const { data, error } = await supabase.from("positions").select("*").eq("id", positionId);

    if (error) {
      return thunkApi.rejectWithValue(error.message);
    }

    return data as position[];
  }
);

// Add a new position
export const addPosition = createAsyncThunk<position, position, { rejectValue: string }>(
  "position/addPosition",
  async (newPosition, thunkApi) => {
    const { data: insertData, error: insertError } = await supabase
      .from("positions")
      .insert(newPosition)
      .select()
      .single();

    if (insertError) {
      return thunkApi.rejectWithValue(insertError.message);
    }

    return insertData as position;
  }
);

// Delete a position
export const deletePosition = createAsyncThunk<string, string, { rejectValue: string }>(
  "position/deletePosition",
  async (id, thunkApi) => {
    const { error } = await supabase.from("positions").delete().eq("id", id);

    if (error) {
      return thunkApi.rejectWithValue(error.message);
    }

    return id;
  }
);

// Edit a position
export const editPosition = createAsyncThunk<position, position, { rejectValue: string }>(
  "position/editPosition",
  async (updatedPosition, thunkApi) => {
    const { data: updateData, error: updateError } = await supabase
      .from("positions")
      .update(updatedPosition)
      .eq("id", updatedPosition.id)
      .select()
      .single();

    if (updateError) {
      return thunkApi.rejectWithValue(updateError.message);
    }

    return updateData as position;
  }
);

// Real-Time Actions
export const addPositionRealTime = (newPosition: position): PayloadAction<position> => ({
  type: "position/addPositionRealTime",
  payload: newPosition,
});

export const editPositionRealTime = (updatedPosition: position): PayloadAction<position> => ({
  type: "position/editPositionRealTime",
  payload: updatedPosition,
});

export const deletePositionRealTime = (id: string): PayloadAction<string> => ({
  type: "position/deletePositionRealTime",
  payload: id,
});

// Slice definition
const positionSlice = createSlice({
  name: "position",
  initialState,
  reducers: {
    addPositionRealTime(state, action: PayloadAction<position>) {
      const exists = state.data.some((position) => position.id === action.payload.id);
      if (!exists) {
        state.data.push(action.payload);
      }
      state.error = null;
    },
    editPositionRealTime(state, action: PayloadAction<position>) {
      const index = state.data.findIndex((position) => position.id === action.payload.id);
      if (index !== -1) {
        const isDifferent = JSON.stringify(state.data[index]) !== JSON.stringify(action.payload);
        if (isDifferent) {
          state.data[index] = action.payload;
        }
      }
      state.error = null;
    },
    deletePositionRealTime(state, action: PayloadAction<string>) {
      const exists = state.data.some((position) => position.id === action.payload);
      if (exists) {
        state.data = state.data.filter((position) => position.id !== action.payload);
      }
      state.error = null;
    },
  },
  extraReducers: (builder) => {
    builder
      // Handle fetchAllPositions
      .addCase(fetchAllPositions.pending, (state) => {
        state.status = "loading";
      })
      .addCase(fetchAllPositions.fulfilled, (state, action: PayloadAction<position[]>) => {
        state.status = "succeeded";
        state.positions = action.payload;
        state.error = null;
      })
      .addCase(fetchAllPositions.rejected, (state, action: PayloadAction<string | undefined>) => {
        state.status = "failed";
        state.error = action.payload || "Failed to fetch positions.";
      })
      // Handle fetchPositionsByOffice
      .addCase(fetchPositionsByOffice.pending, (state) => {
        state.status = "loading";
      })
      .addCase(fetchPositionsByOffice.fulfilled, (state, action: PayloadAction<position[]>) => {
        state.status = "succeeded";
        state.data = action.payload;
        state.error = null;
      })
      .addCase(fetchPositionsByOffice.rejected, (state, action: PayloadAction<string | undefined>) => {
        state.status = "failed";
        state.error = action.payload || "Failed to fetch positions by office.";
      })

      // Handle fetchPositionsById
      .addCase(fetchPositionsById.pending, (state) => {
        state.status = "loading";
      })
      .addCase(fetchPositionsById.fulfilled, (state, action: PayloadAction<position[]>) => {
        state.status = "succeeded";
        state.data = action.payload;
        state.error = null;
      })
      .addCase(fetchPositionsById.rejected, (state, action: PayloadAction<string | undefined>) => {
        state.status = "failed";
        state.error = action.payload || "Failed to fetch positions by ID.";
      })
      // Handle addPosition
      .addCase(addPosition.pending, (state) => {})
      .addCase(addPosition.fulfilled, (state, action: PayloadAction<position>) => {
        state.data.push(action.payload);
        state.error = null;
      })
      .addCase(addPosition.rejected, (state, action: PayloadAction<string | undefined>) => {
        state.error = action.payload || "Failed to add position.";
      })
      // Handle deletePosition
      .addCase(deletePosition.pending, (state) => {})
      .addCase(deletePosition.fulfilled, (state, action: PayloadAction<string>) => {
        state.data = state.data.filter((position) => position.id !== action.payload);
        state.error = null;
      })
      .addCase(deletePosition.rejected, (state, action: PayloadAction<string | undefined>) => {
        state.error = action.payload || "Failed to delete position.";
      })
      // Handle editPosition
      .addCase(editPosition.pending, (state) => {})
      .addCase(editPosition.fulfilled, (state, action: PayloadAction<position>) => {
        state.status = "succeeded";
        const index = state.data.findIndex((position) => position.id === action.payload.id);
        if (index !== -1) {
          state.data[index] = action.payload;
        }
        state.error = null;
      })
      .addCase(editPosition.rejected, (state, action: PayloadAction<string | undefined>) => {
        state.status = "failed";
        state.error = action.payload || "Failed to edit position.";
      });
  },
});

// Real-time subscription function
export const subscribeToPositions = (dispatch: any) => {
  const subscription = supabase
    .channel("public:positions")
    .on("postgres_changes", { event: "*", schema: "public", table: "positions" }, (payload) => {
      const newRecord = payload.new as position;
      const oldRecord = payload.old as position;
      switch (payload.eventType) {
        case "INSERT":
          dispatch(addPositionRealTime(newRecord));
          break;
        case "UPDATE":
          dispatch(editPositionRealTime(newRecord));
          break;
        case "DELETE":
          dispatch(deletePositionRealTime(oldRecord.id ?? ""));
          break;
        default:
          break;
      }
    })
    .subscribe();

  return () => {
    subscription.unsubscribe();
  };
};

export default positionSlice.reducer;
