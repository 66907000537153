import React from "react";
import { createRoot } from "react-dom/client";
import "./index.css";
import App from "./App";
import "./global/usersList";
import "./db/userGlobal";

// import "./import/importVacation"
// import "./import/importUsers"
// import "./import/importAllocations"
// import "./import/importMonthlyAnnualVacationScores"
// import "./import/importYearlySickVacationScores"
// import "./import/EditUsersProps"
// import "./import/import-auth-supabase"

import { Provider } from "react-redux";
import { store } from "./store";

const container = document.getElementById("root");
const root = createRoot(container as any); // createRoot(container!) if you use TypeScript

root.render(
  <Provider store={store}>
    <App />
  </Provider>
);

