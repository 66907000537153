import React from "react";
import EditorJs from "@natterstefan/react-editor-js";
import { defaultData, EDITOR_JS_TOOLS } from "./constants";
import { API, OutputData } from "@editorjs/editorjs";
import "./editor.css";
import { useAppSelector } from "../../hooks/reduxHooks";
interface Props {
  data: OutputData;
  setInstance: (args: API) => void;
  minHeight: number;
  holder: string;
}
function Editor(props: Props) {
  const { data, setInstance, minHeight, holder } = props;
  const { currentUser } = useAppSelector((state) => state.auth);
  const user = currentUser;
  return (
    <div>
      <EditorJs
        // initialBlock="omar"
        holder={holder}
        placeholder="Write here..."
        minHeight={minHeight}
        tools={EDITOR_JS_TOOLS}
        onChange={(instanceEdit) => setInstance(instanceEdit)}
        data={data ?? defaultData({ firstName: user.firstName })}
      />
    </div>
  );
}

export default Editor;
