import { createSlice, createAsyncThunk, PayloadAction } from "@reduxjs/toolkit";
import { supabase } from "../../supabaseClient"; // Adjust the import path as needed
import { mission } from "../../db/interfaces"; // Ensure your mission interface is defined here

interface missionsSliceState {
  data: mission[];
  status: "loading" | "succeeded" | "failed" | "idle";
  error: string | null;
  globalMissionsData: Record<string, mission>;
  loaded: boolean;
}

const initialState: missionsSliceState = {
  data: [],
  status: "idle",
  error: null,
  globalMissionsData: {},
  loaded: false,
};

// Fetch all missions in batches
export const fetchMissionsInBatches = createAsyncThunk(
  "missions/fetchMissionsInBatches",
  async (_, { rejectWithValue }) => {
    const { data, error } = await supabase.from("missions").select("*");
    if (error) {
      return rejectWithValue(error.message as string);
    }

    return data;
  }
);

// Add a new mission
export const addMission = createAsyncThunk<mission, mission, { rejectValue: string }>(
  "missions/addMission",
  async (newMission, thunkApi) => {
    const { data: insertData, error: insertError } = await supabase
      .from("missions")
      .insert(newMission)
      .select()
      .single();

    if (insertError) {
      return thunkApi.rejectWithValue(insertError.message);
    }

    return insertData as mission;
  }
);

// Delete a mission
export const deleteMission = createAsyncThunk<string, string, { rejectValue: string }>(
  "missions/deleteMission",
  async (id, thunkApi) => {
    const { error } = await supabase.from("missions").delete().eq("id", id);

    if (error) {
      return thunkApi.rejectWithValue(error.message);
    }

    return id;
  }
);

// Edit a mission
export const editMission = createAsyncThunk<mission, mission, { rejectValue: string }>(
  "missions/editMission",
  async (updatedMission, thunkApi) => {
    const { data: updateData, error: updateError } = await supabase
      .from("missions")
      .update(updatedMission)
      .eq("id", updatedMission.id)
      .select()
      .single();

    if (updateError) {
      return thunkApi.rejectWithValue(updateError.message);
    }

    return updateData as mission;
  }
);

// Real-time actions
export const addMissionRealTime = (newMission: mission): PayloadAction<mission> => ({
  type: "missions/addMissionRealTime",
  payload: newMission,
});

export const editMissionRealTime = (updatedMission: mission): PayloadAction<mission> => ({
  type: "missions/editMissionRealTime",
  payload: updatedMission,
});

export const deleteMissionRealTime = (id: string): PayloadAction<string> => ({
  type: "missions/deleteMissionRealTime",
  payload: id,
});

// Slice definition
const missionsSlice = createSlice({
  name: "missions",
  initialState,
  reducers: {
    addMissionRealTime(state, action: PayloadAction<mission>) {
      const exists = state.data.some((mission) => mission.id === action.payload.id);
      if (!exists) {
        state.data.push(action.payload);
      }
      state.error = null;
    },
    editMissionRealTime(state, action: PayloadAction<mission>) {
      const index = state.data.findIndex((mission) => mission.id === action.payload.id);
      if (index !== -1) {
        const isDifferent = JSON.stringify(state.data[index]) !== JSON.stringify(action.payload);
        if (isDifferent) {
          state.data[index] = action.payload;
        }
      }
      state.error = null;
    },
    deleteMissionRealTime(state, action: PayloadAction<string>) {
      const exists = state.data.some((mission) => mission.id === action.payload);
      if (exists) {
        state.data = state.data.filter((mission) => mission.id !== action.payload);
      }
      state.error = null;
    },
  },
  extraReducers: (builder) => {
    builder
      // Handle fetchMissionsInBatches
      .addCase(fetchMissionsInBatches.pending, (state) => {
        state.status = "loading";
      })
      .addCase(fetchMissionsInBatches.fulfilled, (state, action) => {
        state.loaded = true;
        action.payload.forEach((mission) => {
          state.globalMissionsData[mission.id ?? ""] = mission;
        });
        state.data = action.payload;

        state.status = "succeeded";
      })
      .addCase(fetchMissionsInBatches.rejected, (state, action) => {
        state.status = "failed";
        state.error = (action.payload as string) || "Failed to fetch missions.";
      })

      // Handle addMission
      .addCase(addMission.fulfilled, (state, action: PayloadAction<mission>) => {
        state.data.push(action.payload);
        state.error = null;
      })
      .addCase(addMission.rejected, (state, action: PayloadAction<string | undefined>) => {
        state.error = action.payload || "Failed to add mission.";
      })

      // Handle deleteMission
      .addCase(deleteMission.fulfilled, (state, action: PayloadAction<string>) => {
        state.data = state.data.filter((mission) => mission.id !== action.payload);
        state.error = null;
      })
      .addCase(deleteMission.rejected, (state, action: PayloadAction<string | undefined>) => {
        state.error = action.payload || "Failed to delete mission.";
      })

      // Handle editMission
      .addCase(editMission.fulfilled, (state, action: PayloadAction<mission>) => {
        const index = state.data.findIndex((mission) => mission.id === action.payload.id);
        if (index !== -1) {
          state.data[index] = action.payload;
        }
        state.error = null;
      })
      .addCase(editMission.rejected, (state, action: PayloadAction<string | undefined>) => {
        state.error = action.payload || "Failed to edit mission.";
      });
  },
});

// Real-time subscription function
export const subscribeToMissions = (dispatch: any) => {
  const subscription = supabase
    .channel("public:missions")
    .on("postgres_changes", { event: "*", schema: "public", table: "missions" }, (payload) => {
      const newRecord = payload.new as mission;
      const oldRecord = payload.old as mission;

      switch (payload.eventType) {
        case "INSERT":
          dispatch(addMissionRealTime(newRecord));
          break;
        case "UPDATE":
          dispatch(editMissionRealTime(newRecord));
          break;
        case "DELETE":
          dispatch(deleteMissionRealTime(oldRecord.id ?? ""));
          break;
        default:
          break;
      }
    })
    .subscribe();

  return () => {
    subscription.unsubscribe();
  };
};

export default missionsSlice.reducer;
