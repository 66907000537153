import React, { useState, useEffect } from "react";
import { Button, DatePicker, Drawer, Form, InputNumber, message, Select, Input, Descriptions } from "antd";
const { TextArea } = Input;
import moment from "moment";
const { Option } = Select;
import { useFormik } from "formik";
import * as Yup from "yup";
import { useTranslation } from "react-i18next";
import { users, vacation } from "../../../db/interfaces";
import { useAppDispatch, useAppSelector } from "../../../hooks/reduxHooks";
import { globalUsersData } from "../../../global/usersList";
import { editUser } from "../../../features/users/usersSlice";
import { editVacation } from "../../../features/vacations/vacationsSlice";

interface Props {
  modalState: boolean;
  setModalState: React.Dispatch<React.SetStateAction<boolean>>;
  vacationId: string;
  employeeUID: string;
  vacation: vacation;
}
const projectSchema = Yup.object().shape({
  vacationStartOn: Yup.date().required("*"),
});

function EditVacation(props: Props) {
  const { currentUser } = useAppSelector((state) => state.auth);
  const dispatch = useAppDispatch();

  const { t } = useTranslation();
  const [vacationType, setVacationType] = useState("");
  const [vacationPeriod, setVacationPeriod] = useState(0);
  const { modalState, setModalState, employeeUID, vacation, vacationId } = props;

  const [loading, setLoading] = useState(false);
  const [halfDay, setHalfDay] = useState("");

  const uid = currentUser?.id as string;

  const employeeData = globalUsersData[employeeUID ?? ""] as users;
  const vacationData = vacation as vacation;

  const changeOnScore = ({
    oldType,
    currentType,
    oldDays,
    currentDays,
  }: {
    oldType: string;
    currentType: string;
    oldDays: number;
    currentDays: number;
  }) => {
    const userId = uid;

    if (oldType != currentType) {
      switch (currentType) {
        case "Annual Leave":
          dispatch(
            editUser({
              id: userId,
              vacationAnnualScore: globalUsersData[userId ?? ""]?.vacationAnnualScore - currentDays,
            })
          );
          break;

        case "Sick Leave":
          dispatch(
            editUser({
              id: userId,
              vacationSickScore: globalUsersData[userId ?? ""]?.vacationSickScore - currentDays,
            })
          );
          break;

        case "Compensation Leave":
          dispatch(
            editUser({
              id: userId,
              vacationCompensationScore: globalUsersData[userId ?? ""]?.vacationCompensationScore - currentDays,
            })
          );
          break;

        case "Death Leave":
          dispatch(
            editUser({
              id: userId,
              vacationDeathScore: globalUsersData[userId ?? ""]?.vacationDeathScore - currentDays,
            })
          );
          break;

        case "Marriage Leave":
          dispatch(
            editUser({
              id: userId,
              vacationMarriageScore: globalUsersData[userId ?? ""]?.vacationMarriageScore - currentDays,
            })
          );
          break;
        case "Paternity Leave":
          dispatch(
            editUser({
              id: userId,
              vacationPaternityScore: globalUsersData[userId ?? ""]?.vacationPaternityScore - currentDays,
            })
          );
          break;

        case "Maternity Leave":
          dispatch(
            editUser({
              id: userId,
              vacationMaternityScore: globalUsersData[userId ?? ""]?.vacationMaternityScore - currentDays,
            })
          );
          break;
        default:
          break;
      }
      switch (oldType) {
        case "Annual Leave":
          dispatch(
            editUser({
              id: userId,
              vacationAnnualScore: globalUsersData[userId ?? ""]?.vacationAnnualScore + oldDays,
            })
          );
          break;

        case "Sick Leave":
          dispatch(
            editUser({
              id: userId,
              vacationSickScore: globalUsersData[userId ?? ""]?.vacationSickScore + oldDays,
            })
          );
          break;

        case "Compensation Leave":
          dispatch(
            editUser({
              id: userId,
              vacationCompensationScore: globalUsersData[userId ?? ""]?.vacationCompensationScore + oldDays,
            })
          );
          break;

        case "Death Leave":
          dispatch(
            editUser({
              id: userId,
              vacationDeathScore: globalUsersData[userId ?? ""]?.vacationDeathScore + oldDays,
            })
          );
          break;

        case "Marriage Leave":
          dispatch(
            editUser({
              id: userId,
              vacationMarriageScore: globalUsersData[userId ?? ""]?.vacationMarriageScore + oldDays,
            })
          );
          break;
        case "Paternity Leave":
          dispatch(
            editUser({
              id: userId,
              vacationPaternityScore: globalUsersData[userId ?? ""]?.vacationPaternityScore + oldDays,
            })
          );
          break;

        case "Maternity Leave":
          dispatch(
            editUser({
              id: userId,
              vacationMaternityScore: globalUsersData[userId ?? ""]?.vacationMaternityScore + oldDays,
            })
          );
          break;
        default:
          break;
      }
    }

    if (oldType == currentType) {
      const takenScore = currentDays - oldDays;
      switch (vacationType) {
        case "Annual Leave":
          console.log("Annual", globalUsersData[userId ?? ""]?.vacationAnnualScore - takenScore);

          dispatch(
            editUser({
              id: userId,
              vacationAnnualScore: globalUsersData[userId ?? ""]?.vacationAnnualScore - takenScore,
            })
          );
          break;

        case "Sick Leave":
          console.log("Sick", globalUsersData[userId ?? ""]?.vacationSickScore - takenScore);
          dispatch(
            editUser({
              id: userId,
              vacationSickScore: globalUsersData[userId ?? ""]?.vacationSickScore - takenScore,
            })
          );
          break;

        case "Compensation Leave":
          dispatch(
            editUser({
              id: userId,
              vacationCompensationScore: globalUsersData[userId ?? ""]?.vacationCompensationScore - takenScore,
            })
          );
          break;

        case "Death Leave":
          dispatch(
            editUser({
              id: userId,
              vacationDeathScore: globalUsersData[userId ?? ""]?.vacationDeathScore - takenScore,
            })
          );
          break;

        case "Marriage Leave":
          dispatch(
            editUser({
              id: userId,
              vacationMarriageScore: globalUsersData[userId ?? ""]?.vacationMarriageScore - takenScore,
            })
          );
          break;
        case "Paternity Leave":
          dispatch(
            editUser({
              id: userId,
              vacationPaternityScore: globalUsersData[userId ?? ""]?.vacationPaternityScore - takenScore,
            })
          );
          break;

        case "Maternity Leave":
          dispatch(
            editUser({
              id: userId,
              vacationMaternityScore: globalUsersData[userId ?? ""]?.vacationMaternityScore - takenScore,
            })
          );
          break;
        default:
          break;
      }
    }
  };

  const formik = useFormik({
    initialValues: {
      vacationType: "",
      notes: "",
      vacationStartOn: moment(),
      vacationEndOn: moment(),
      startWorkOn: moment(),
      vacationPeriodDays: 0,
    },
    validationSchema: projectSchema,
    onSubmit: async (values, form) => {
      const typeScore =
        vacationType == "Annual Leave"
          ? employeeData.vacationAnnualScore
          : vacationType == "Sick Leave"
          ? employeeData.vacationSickScore
          : 120;
      if (values.vacationPeriodDays > typeScore + vacationPeriod || values.vacationPeriodDays == 0) {
        alert(`Your ${vacationType} Score is not enough as requested!`);
        setLoading(false);
      } else {
        dispatch(
          editVacation({
            id: vacationId,
            vacationStartOn: values.vacationStartOn as any,
            vacationEndOn: values.vacationEndOn as any,
            startWorkOn: values.startWorkOn as any,
            employeeSignatureDate: moment() as any,
            managerSignatureDate: currentUser.HROffices?.includes(employeeData.office)
              ? vacationData.managerSignatureDate
              : "",
            adminSignatureDate: currentUser.HROffices?.includes(employeeData.office)
              ? vacationData.adminSignatureDate
              : "",
            HRSignatureDate: currentUser.HROffices?.includes(employeeData.office)
              ? vacationData.HRSignatureDate
              : "",
            vacationType: vacationType as any,
            vacationPeriodDays: values.vacationPeriodDays,
            status: currentUser.HROffices?.includes(employeeData.office) ? vacationData.status : "Requested",
            notes: values.notes,
            halfDay: halfDay,
            version: 2,
          } as any)
        )
          .then(() => {
            changeOnScore({
              oldType: vacationData.vacationType,
              currentType: vacationType,
              oldDays: vacationData.vacationPeriodDays,
              currentDays: values.vacationPeriodDays,
            });
          })
          .then(() => {
            message.success("Your request has been submitted successfully");
            console.log("Document successfully written!");
            setModalState(false);
            setLoading(false);
            setLoading(false);
          })

          .then(() => form.resetForm())
          .catch((error) => {
            console.error("Error writing document: ", error);
            message.error("Failed");
          });
      }
    },
  });

  const [loaded, setLoaded] = useState(true);
  useEffect(() => {
    if (vacation && loaded) {
      formik.setValues({
        vacationType: vacationData.vacationType,
        vacationStartOn: moment(vacationData.vacationStartOn),
        vacationEndOn: moment(vacationData.vacationEndOn),
        startWorkOn: moment(vacationData.startWorkOn),
        vacationPeriodDays: vacationData.vacationPeriodDays,
        notes: vacationData.notes,
      } as any);
      setVacationPeriod(vacationData.vacationPeriodDays);
      setLoaded(false);
      setVacationType(vacationData.vacationType);
      setHalfDay(vacationData.halfDay);
    }
  }, [vacation, formik, loaded]);
  const enterLoading = () => {
    setLoading(true);
  };
  const dateFormat = "YYYY/MM/DD";
  return (
    <Drawer title="Vacation Request" width={600} visible={modalState} onClose={() => setModalState(false)}>
      <Form onSubmit={formik.handleSubmit}>
        <div>
          <div>
            <Descriptions
              size={"default"}
              layout="horizontal"
              bordered
              column={{ xxl: 6, xl: 4, lg: 3, md: 3, sm: 2, xs: 1 }}
            >
              <Descriptions.Item label={t("general.vacationType")} span={6}>
                <Select value={vacationType} onChange={(e) => setVacationType(e)}>
                  <Option value={"Annual Leave"}>Annual Leave</Option>
                  <Option value={"Unpaid Leave"}>Unpaid Leave</Option>
                  <Option value={"Sick Leave"}>Sick Leave</Option>
                  <Option value={"Death Leave"}>Death Leave</Option>
                  <Option value={"Marriage Leave"}>Marriage Leave</Option>
                  <Option value={"Paternity Leave"}>Paternity Leave</Option>
                  <Option value={"Maternity Leave"}>Maternity Leave</Option>
                  <Option value={"Compensation Leave"}>Compensation Leave</Option>
                </Select>
              </Descriptions.Item>
              <Descriptions.Item label={t("general.vacationStartOn")} span={6}>
                <DatePicker
                  value={formik.values.vacationStartOn}
                  format={dateFormat}
                  onChange={(e) => {
                    if (currentUser?.fullControlOffices?.length != 0) {
                      formik.setFieldValue("vacationStartOn", e);
                    } else {
                      if (vacationType == "Sick Leave") {
                        formik.setFieldValue("vacationStartOn", e);
                      } else {
                        if (moment(e) >= moment()) {
                          formik.setFieldValue("vacationStartOn", e);
                        } else {
                          formik.setFieldValue("vacationStartOn", moment());
                        }
                      }
                    }
                  }}
                />{" "}
              </Descriptions.Item>
              <Descriptions.Item label={t("general.vacationEndOn")} span={6}>
                <DatePicker
                  value={formik.values.vacationEndOn}
                  format={dateFormat}
                  onChange={(e) => {
                    if (currentUser?.fullControlOffices?.length != 0) {
                      formik.setFieldValue("vacationEndOn", e);
                    } else {
                      if (vacationType == "Sick Leave") {
                        formik.setFieldValue("vacationEndOn", e);
                      } else {
                        if (moment(e) >= moment()) {
                          formik.setFieldValue("vacationEndOn", e);
                        } else {
                          formik.setFieldValue("vacationEndOn", moment());
                        }
                      }
                    }
                  }}
                />{" "}
              </Descriptions.Item>
              <Descriptions.Item label={t("general.startWorkOn")} span={6}>
                <DatePicker
                  value={formik.values.startWorkOn}
                  format={dateFormat}
                  onChange={(e) => formik.setFieldValue("startWorkOn", e)}
                />{" "}
              </Descriptions.Item>

              <Descriptions.Item label={t("general.vacationPeriodDays")} span={6}>
                <InputNumber
                  max={
                    vacationType == "Annual Leave"
                      ? (employeeData.vacationAnnualScore + vacationPeriod).toFixed(1)
                      : vacationType == "Sick Leave"
                      ? employeeData.vacationSickScore.toFixed(1)
                      : 120
                  }
                  min={0}
                  value={formik.values.vacationPeriodDays}
                  onChange={(e: any) => {
                    formik.setFieldValue("vacationPeriodDays", e);
                  }}
                />
              </Descriptions.Item>
              {formik.values.vacationPeriodDays == 0.5 ? (
                <Descriptions.Item label={t("general.halfDay")} span={6}>
                  <Select value={halfDay} onChange={(e) => setHalfDay(e)}>
                    <Option value={"morning"}>Morning</Option>
                    <Option value={"evening"}>Evening</Option>
                  </Select>
                </Descriptions.Item>
              ) : null}
              <Descriptions.Item label={t("general.notes")} span={6}>
                <TextArea
                  value={formik.values.notes}
                  autoSize={{ minRows: 3, maxRows: 5 }}
                  onChange={(e) => formik.setFieldValue("notes", e.target.value)}
                />
              </Descriptions.Item>
            </Descriptions>
          </div>
        </div>
        <div>
          <Button
            style={{ margin: "1%" }}
            className="btn blue lighten-1 z-depth-0"
            onClick={() => {
              enterLoading();
            }}
            loading={loading}
            type="primary"
            htmlType="submit"
            disabled={!formik.isValid}
          >
            {t("general.save")}
          </Button>
        </div>
      </Form>
    </Drawer>
  );
}

export default EditVacation;
