import React, { useEffect, useState } from "react";
import moment from "moment";
import { DatePicker, Spin, Button, Select, Checkbox } from "antd";
const { MonthPicker } = DatePicker;
import { users } from "../../db/interfaces";
import printJS from "print-js";
import _ from "lodash";
const { Option } = Select;
import "./style.css";
import TimeSheetComponent from "./timeSheetComponent";
import { globalUsersData, USERS_ID_FULL_NAME } from "../../global/usersList";
import { useAppDispatch, useAppSelector } from "../../hooks/reduxHooks";
import { fetchVacationsByUserIdByDate } from "../../features/vacations/vacationsSlice";
import { fetchAllocationsByUserId } from "../../features/allocations/allocationsSlice";
import { fetchDelaysByUserIdAndMonth } from "../../features/delays/delaysSlice";
import { fetchShiftsByUserIdAndMonth } from "../../features/shifts/shfitsSlice";

export default function TimeSheet() {
  const { currentUser } = useAppSelector((state) => state.auth);
  const {
    data: vacationsData,
    status: vacationsStatus,
    error: vacationsError,
  } = useAppSelector((state) => state.vacations);
  const {
    data: allocationsData,
    status: allocationsStatus,
    error: allocationsError,
  } = useAppSelector((store) => store.allocations);
  const { data: delaysData, status: delaysStatus, error: delaysError } = useAppSelector((store) => store.delays);
  const { data: shiftsData, status: shiftsStatus, error: shiftsError } = useAppSelector((store) => store.shifts);

  const dispatch = useAppDispatch();

  const [month, setMonth] = useState(moment().subtract(1, "months"));
  const [uid, setUid] = useState(currentUser?.id as string);
  const [activeDelay, setActiveDelay] = useState(true);

  useEffect(() => {
    dispatch(
      fetchVacationsByUserIdByDate({
        userId: uid,
        gt: month.startOf("month").format("YYYY-MM-DD"),
        ls: month.endOf("month").format("YYYY-MM-DD"),
        byField: "vacationStartOn",
      })
    );

    dispatch(fetchAllocationsByUserId(uid));
    dispatch(fetchDelaysByUserIdAndMonth({ userId: uid, month: month.format("MMM.YYYY").toString() }));
    dispatch(fetchShiftsByUserIdAndMonth({ userId: uid, month: month.format("MM-YYYY").toString() }));
  }, [dispatch, uid, month]);

  if (
    vacationsStatus == "loading" ||
    allocationsStatus == "loading" ||
    delaysStatus == "loading" ||
    shiftsStatus == "loading"
  )
    return <Spin />;

  const userData = globalUsersData[uid ?? ""] as users;


  const print = async () => {
    await printJS({
      printable: "timeSheet",
      type: "html",
      targetStyles: ["*"],
      scanStyles: true,
      font_size: "15",
      documentTitle: `aiu.ida-org.com/print-timeSheet/${uid}/true/${moment(month).format("MM-YYYY")}`,
      showModal: true,
    });
  };
  return vacationsError || allocationsError || delaysError || shiftsError ? (
    <p>
      {vacationsError}
      {allocationsError}
      {delaysError}
      {shiftsError}
    </p>
  ) : (
    <div>
      <MonthPicker
        style={{ marginBottom: "1%", marginRight: "1%" }}
        placeholder="Select month"
        value={month}
        format={"MM-YYYY"}
        onChange={(e: any) => {
          setMonth(moment(e));
        }}
      />
      {currentUser?.fullControlOffices?.length != 0 ? (
        <Select
          filterOption={(inputValue, option) => {
            return (option.props.children as any).toLowerCase().indexOf(inputValue.toLowerCase()) >= 0;
          }}
          showSearch
          style={{ width: "20%", paddingBottom: "1%" }}
          value={uid}
          onChange={(e: string) => {
            setUid(e);
          }}
        >
          {USERS_ID_FULL_NAME?.map((d: any) => {
            return <Option value={d.uid}>{d.fullName}</Option>;
          })}
        </Select>
      ) : null}
      {currentUser?.fullControlOffices?.length != 0 ? (
        <Checkbox
          style={{ marginLeft: "1%" }}
          value={activeDelay}
          checked={activeDelay}
          onChange={(e) => {
            setActiveDelay(!activeDelay);
          }}
        >
          Active
        </Checkbox>
      ) : null}

      <Button
        style={{ marginLeft: "1%" }}
        type="primary"
        onClick={async () => {
          print();
        }}
        htmlType="submit"
      >
        Print
      </Button>

      <div id="timeSheet">
        <TimeSheetComponent
          month={month}
          uid={uid}
          userData={userData as users}
          vacationsData={vacationsData}
          allocationsData={allocationsData}
          delaysData={delaysData}
          shiftsData={shiftsData}
          activeDelay={activeDelay}
        />
        <br />
      </div>
    </div>
  );
}
