import React, { useEffect, useState } from "react";
import { Col, Icon, Input, Row, Select, Spin, Tabs } from "antd";
const { Option } = Select;
import { Container } from "@material-ui/core";
import DepartmentChart from "./department-chart/department-chart";
import PositionChart from "./position-chart/position-chart";
import AllTasks from "./allTasks";
const { TabPane } = Tabs;
import moment from "moment";
import AllPolicies from "./allPolicies";
import AllTemplates from "./allTemplates";
import AllSOPs from "./allSOPs";
import { useAppDispatch, useAppSelector } from "../../hooks/reduxHooks";
import { fetchAllDepartments } from "../../features/departments/departmentsSlice";
import { fetchTags } from "../../features/tags/tagsSlice";
import { fetchVacationsByDate } from "../../features/vacations/vacationsSlice";
import { fetchOutdoorTasksByDate } from "../../features/outdoorTasks/outdoorTasksSlice";
import { fetchPositionTasks } from "../../features/positionTasks/positionTasksSlice";
import { setFilterValue } from "../../features/filters/filtersSlice";

const antIcon = <Icon type="loading" style={{ fontSize: 75 }} spin />;

const Chart = () => {
  const { filterValues } = useAppSelector((state) => state.filters);

  const { data: departmentsData, status: departmentsStatus } = useAppSelector((state) => state.departments);
  const { tags: tagsData } = useAppSelector((state) => state.tags);
  const { data: positionTasksData, status: positionTasksStatus } = useAppSelector((state) => state.positionTasks);
  const { data: vacationsData, status: vacationsStatus } = useAppSelector((state) => state.vacations);
  const { data: outdoorTasksData, status: outdoorTasksStatus } = useAppSelector((state) => state.outdoorTasks);

  const dispatch = useAppDispatch();

  const [filters, setFilters] = useState(filterValues["dashboard"] ?? { roles: [], tags: [], task: "" });

  useEffect(() => {
    dispatch(fetchAllDepartments());
    dispatch(fetchTags());
    dispatch(fetchPositionTasks());
    dispatch(
      fetchVacationsByDate({
        gt: moment().startOf("month").format("YYYY-MM-DD"),
        ls: moment().endOf("month").format("YYYY-MM-DD"),
        byField: "vacationStartOn",
      })
    );
    dispatch(
      fetchOutdoorTasksByDate({
        gt: moment().startOf("month").format("YYYY-MM-DD"),
        ls: moment().endOf("month").format("YYYY-MM-DD"),
        byField: "startDate",
      })
    );
  }, [dispatch]);

  const vacationList = vacationsData;
  const outdoorTaskList = outdoorTasksData;
  const departmentData = departmentsData;
  // if (
  //   departmentsStatus == "loading" ||
  //   positionTasksStatus == "loading" ||
  //   vacationsStatus == "loading" ||
  //   outdoorTasksStatus == "loading"
  // )
  //   return <Spin />;

  const todaysVacations = vacationList
    ?.filter((v: any) => {
      const vacationStartOn = moment(v.vacationStartOn).startOf("day");
      const vacationEndOn = moment(v.vacationEndOn).endOf("day");
      return moment().isBetween(vacationStartOn, vacationEndOn, undefined, "[]");
    })
    .map((v: any) => {
      return { uid: v.employeeUID, type: "vacation" };
    });

  const todaysOutdoorTasks = outdoorTaskList
    ?.filter((v: any) => {
      const startDate = moment(v.startDate).startOf("day");
      const endDate = moment(v.endDate).endOf("day");
      return moment().isBetween(startDate, endDate, undefined, "[]");
    })
    .map((v: any) => {
      return { uid: v.employeeUID, type: "outdoorTask" };
    });

  const todaysStatusStaff: any = [...(todaysVacations ?? ([] as any)), ...(todaysOutdoorTasks ?? ([] as any))];
  const tagsArray = tagsData.map((tag) => {
    return { id: tag.id, tag: tag.tag };
  });

  const tasksArray = positionTasksData;

  const filteredData = tasksArray?.filter((item) => {
    const titleLower = item.task.title.toLowerCase();
    const descriptionLower = item.task.description.toLowerCase();
    const searchStringLower = filters.task.toLowerCase();

    const taskMatches = titleLower.includes(searchStringLower) || descriptionLower?.includes(searchStringLower);
    const tagsMatch = filters.tags.length === 0 || filters.tags.some((tag) => item.tags?.includes(tag));

    const rolesMatch = filters.roles.length === 0 || filters.roles.some((rol) => item.role?.includes(rol));

    const filtersApplied = filters.task || filters.tags.length > 0 || filters.roles.length > 0;

    return filtersApplied && taskMatches && tagsMatch && rolesMatch;
  });
  const departmentIdsFiltered = filteredData?.map((task) => task.departmentId);
  const positionIdsFiltered = filteredData?.map((task) => task.positionId);
  const taskIdsFiltered = filteredData?.map((task) => task.id);
  const filtersApplied: any = filters.task || filters.tags.length > 0 || filters.roles.length > 0;

  return (
    <div>
      <Spin
        spinning={
          departmentsStatus == "loading" ||
          positionTasksStatus == "loading" ||
          vacationsStatus == "loading" ||
          outdoorTasksStatus == "loading"
        }
        size="large"
        indicator={antIcon}
      >
        <Container maxWidth="xl" style={{ background: "white", borderRadius: "6px", padding: "3%" }}>
          <div style={{ paddingBottom: "2%" }}>
            <Row>
              <Col span={6}>
                <Input
                  placeholder="Responsibility Filter"
                  value={filters.task as string}
                  onChange={(e) => {
                    setFilters({ ...filters, task: e.target.value });
                    dispatch(setFilterValue({ key: "dashboard", value: { ...filters, task: e.target.value } }));
                  }}
                  style={{ width: "96%" }}
                />
              </Col>
              <Col span={6}>
                <Select
                  mode="tags"
                  style={{ width: "96%" }}
                  placeholder="Tags Filter"
                  value={filters.tags}
                  onChange={(e) => {
                    setFilters({ ...filters, tags: e });
                    dispatch(setFilterValue({ key: "dashboard", value: { ...filters, tags: e } }));
                  }}
                >
                  {tagsArray?.map((task) => {
                    return <Option value={task.tag}>{task.tag}</Option>;
                  })}
                </Select>
              </Col>
              <Col span={6}>
                <Select
                  mode="multiple"
                  style={{ width: "96%" }}
                  placeholder="Roles Filter"
                  value={filters.roles}
                  onChange={(e) => {
                    setFilters({ ...filters, roles: e });
                    dispatch(setFilterValue({ key: "dashboard", value: { ...filters, roles: e } }));
                  }}
                >
                  <Option value="R">Responsible</Option>
                  <Option value="A">Accountable</Option>
                  <Option value="C">Consulted</Option>
                  <Option value="I">Informed</Option>
                </Select>
              </Col>
            </Row>
          </div>
          <Tabs defaultActiveKey="1">
            <TabPane tab="Departments" key="1">
              <DepartmentChart filters={departmentIdsFiltered ?? []} filtersApplied={filtersApplied} />
            </TabPane>
            <TabPane tab="Positions" key="2">
              <PositionChart
                filters={positionIdsFiltered ?? []}
                todaysStatusStaff={todaysStatusStaff ?? []}
                filtersApplied={filtersApplied}
              />
            </TabPane>
            <TabPane tab="Responsibilities" key="3">
              <AllTasks filters={filters} taskIdsFiltered={taskIdsFiltered ?? []} />
            </TabPane>
            <TabPane tab="Policies" key="4">
              <AllPolicies filters={filters} departmentData={departmentData ?? []} />
            </TabPane>
            <TabPane tab="Templates" key="5">
              <AllTemplates filters={filters} departmentData={departmentData ?? []} />
            </TabPane>
            <TabPane tab="SOPs" key="6">
              <AllSOPs filters={filters} departmentData={departmentData ?? []} />
            </TabPane>
          </Tabs>
        </Container>
      </Spin>
    </div>
  );
};

export default Chart;
