import React, { useState, useEffect } from "react";
import Paper from "@material-ui/core/Paper";
import { Spin, Button, Popconfirm, message } from "antd";
import { useTranslation } from "react-i18next";
import AddNewProject from "./AddNewProjectModel";
import moment from "moment";
import DevTable from "../../helpers/devex-table";
import { useAppDispatch, useAppSelector } from "../../hooks/reduxHooks";
import { deleteProject, fetchAllProjects, subscribeToProjects } from "../../features/projects/projectsSlice";

const columnsExport = [
  { name: "projectName", title: "Project Name" },
  { name: "projectCode", title: "Code" },
  { name: "projectStart", title: "Start Date" },
  { name: "projectEnd", title: "End Date" },
];

export default function ProjectsTable() {
  const { data: projectsData, status, error } = useAppSelector((store) => store.projects);
  const dispatch = useAppDispatch();
  const { t } = useTranslation();
  const [modalState, setModalState] = useState(false);

  useEffect(() => {
    dispatch(fetchAllProjects());
    const unsubscribe = subscribeToProjects(dispatch);
    return () => {
      unsubscribe();
    };
  }, [dispatch]);

  if (status == "loading") return <Spin />;

  const data = projectsData.map((d) => {
    return { ...d, id: d.id };
  });

  const columns = [
    { name: "id", title: "ID" },

    { name: "projectName", title: "Project Name" },
    { name: "projectCode", title: "Code" },
    {
      name: "projectStart",
      title: "Start Date",
      getCellValue: (row: any) => moment(row.projectStart).format("DD.MM.YYYY"),
    },

    {
      name: "projectEnd",
      title: "End Date",
      getCellValue: (row: any) => moment(row.projectEnd).format("DD.MM.YYYY"),
    },
    {
      name: "actions",
      title: "Actions",
      getCellValue: (row: any) => {
        return (
          <Popconfirm
            title={t("general.areUSure")}
            onConfirm={() => {
              dispatch(deleteProject(row.id)).unwrap();
              message.success("Deleted Successfully!");
            }}
            onCancel={() => console.log("cancel")}
            okText="Yes"
            cancelText="No"
          >
            <Button type="link">Delete</Button>
          </Popconfirm>
        );
      },
    },
  ];

  return error ? (
    <p>{error}</p>
  ) : (
    <div>
      <AddNewProject modalState={modalState} setModalState={setModalState} />
      <Button style={{ marginBottom: "1%" }} type={"primary"} onClick={() => setModalState(true)}>
        {t("general.addNewProject")}
      </Button>
      <Paper>
        <DevTable
          data={data}
          columns={columns}
          defaultHiddenColumnNames={[]}
          tableName="Projects"
          selected={false}
          typicalTable={false}
          columnsExport={columnsExport}
        />
      </Paper>
    </div>
  );
}
