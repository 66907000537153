import React, { useState, useEffect } from "react";
import { Button, DatePicker, Drawer, Form, InputNumber, message, Select } from "antd";
import moment from "moment";
const { Option } = Select;
import { useFormik } from "formik";
import * as Yup from "yup";
import { useTranslation } from "react-i18next";
import { globalUsersData, USERS_ID_FULL_NAME } from "../../global/usersList";
import { useAppDispatch, useAppSelector } from "../../hooks/reduxHooks";
import { addAllocation } from "../../features/allocations/allocationsSlice";
import { fetchBudgetLinesByProjectId, subscribeToBudgetLines } from "../../features/budgetLines/budgetLinesSlice";
import { fetchAllProjects, subscribeToProjects } from "../../features/projects/projectsSlice";

interface Props {
  modalState: boolean;
  setModalState: React.Dispatch<React.SetStateAction<boolean>>;
}
const projectSchema = Yup.object().shape({
  projectId: Yup.string().required("*"),
  uid: Yup.string().required("*"),
  percentage: Yup.number().required("*"),
  budgetLineId: Yup.string().required("*"),
});

function AddNewAllocation(props: Props) {
  const { data: budgetLinesData } = useAppSelector((store) => store.budgetLines);
  const { data: projectsData } = useAppSelector((store) => store.projects);
  const { currentUser } = useAppSelector((store) => store.auth);

  const dispatch = useAppDispatch();

  const { t } = useTranslation();
  const [projectCodeId, setProjectCodeId] = useState("");
  const { modalState, setModalState } = props;
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    dispatch(fetchBudgetLinesByProjectId(projectCodeId));
    dispatch(fetchAllProjects());
  }, [dispatch, projectCodeId]);

  const projectsMap = projectsData.map((d) => {
    return {
      id: d.id,
      code: d.projectCode,
    };
  });

  const budgetLineMap = budgetLinesData
    .filter((d) => {
      return d.projectId == projectCodeId;
    })
    .map((d) => {
      return {
        id: d.id,
        budgetLine: d.budgetLine,
      };
    });
  const formik = useFormik({
    initialValues: {
      uid: "",
      projectId: "",
      budgetLineId: "",
      percentage: 0,
      idaAllocationPercentage: 0,
      startDate: moment(),
      endDate: moment(),
    },
    validationSchema: projectSchema,
    onSubmit: async (values) => {
      try {
        await dispatch(
          addAllocation({
            uid: values.uid,
            office: globalUsersData[values.uid ?? ""]?.office,
            projectId: values.projectId,
            budgetLineId: values.budgetLineId,
            percentage: values.percentage,
            idaAllocationPercentage: globalUsersData[values.uid]?.idaAllocationPercentage ?? 0,
            startDate: values.startDate as any,
            endDate: values.endDate as any,
            createdAt: new Date() as any,
            by: currentUser?.id ?? "",
          })
        ).unwrap();
        message.success("Added Successfully!");
        setModalState(false);
        setLoading(false);
      } catch (error) {
        setLoading(false);
        console.error("Error writing document: ", error);
        message.error(error as any);
      }
    },
  });

  useEffect(() => {
    return () => {
      if (formik.isValid == false) {
        setLoading(false);
      }
    };
  }, [formik.isValid]);

  const enterLoading = () => {
    setLoading(true);
  };
  const dateFormat = "YYYY/MM/DD";

  return (
    <Drawer
      title={t("general.addNewAllocation")}
      width={500}
      height={350}
      visible={modalState}
      placement={"right"}
      onClose={() => setModalState(false)}
    >
      <Form onSubmit={formik.handleSubmit}>
        <Form.Item label={t("general.employee")}>
          <Select
            filterOption={(inputValue, option) => {
              return (option.props.children as any).toLowerCase().indexOf(inputValue.toLowerCase()) >= 0;
            }}
            showSearch
            value={formik.values.uid}
            onChange={(e) => {
              formik.setFieldValue("uid", e);
            }}
          >
            {USERS_ID_FULL_NAME?.map((d) => {
              return <Option value={d.uid}>{d.fullName}</Option>;
            })}
          </Select>
        </Form.Item>

        <Form.Item label={t("general.projectCode")}>
          <Select
            filterOption={(inputValue, option) => {
              return (option.props.children as any).toLowerCase().indexOf(inputValue.toLowerCase()) >= 0;
            }}
            showSearch
            value={formik.values.projectId}
            onChange={(e) => {
              formik.setFieldValue("projectId", e);
              setProjectCodeId(e);
            }}
          >
            {projectsMap?.map((d) => {
              return <Option value={d.id}>{d.code}</Option>;
            })}
          </Select>
        </Form.Item>

        <Form.Item label={t("general.budgetLine")}>
          <Select
            filterOption={(inputValue, option) => {
              return (option.props.children as any).toLowerCase().indexOf(inputValue.toLowerCase()) >= 0;
            }}
            showSearch
            value={formik.values.budgetLineId}
            onChange={(e) => formik.setFieldValue("budgetLineId", e)}
          >
            {budgetLineMap?.map((d) => {
              return <Option value={d.id}>{d.budgetLine}</Option>;
            })}
          </Select>
        </Form.Item>
        <Form.Item label={t("general.allocation")}>
          <InputNumber
            min={0}
            value={formik.values.percentage}
            onChange={(e) => {
              formik.setFieldValue("percentage", e);
            }}
          />
        </Form.Item>
        {/* <Form.Item label={"Contract Percentage"}>
          <InputNumber
            disabled={true}
            min={0}
            value={formik.values.idaAllocationPercentage}
            onChange={(e) => {
              formik.setFieldValue("idaAllocationPercentage", e);
            }}
          />
        </Form.Item> */}
        <Form.Item label={t("general.startDate")}>
          <DatePicker
            value={formik.values.startDate}
            format={dateFormat}
            onChange={(e) => formik.setFieldValue("startDate", e)}
          />{" "}
        </Form.Item>
        <Form.Item label={t("general.endDate")}>
          <DatePicker
            value={formik.values.endDate}
            format={dateFormat}
            onChange={(e) => formik.setFieldValue("endDate", e)}
          />{" "}
        </Form.Item>

        <div>
          <Button
            className="btn blue lighten-1 z-depth-0"
            onClick={() => {
              enterLoading();
            }}
            loading={loading}
            type="primary"
            htmlType="submit"
            disabled={!formik.isValid}
          >
            {t("general.create")}
          </Button>
        </div>
      </Form>
    </Drawer>
  );
}

export default AddNewAllocation;
