import React, { useState, useEffect } from "react";
import { Button, Drawer, Form, InputNumber, message, Select, Input, Row, Switch, TimePicker, Checkbox } from "antd";
import moment from "moment";
import { useFormik } from "formik";
import * as Yup from "yup";
const { Option } = Select;
import { useTranslation } from "react-i18next";
import { mission, office } from "../../db/interfaces";
import { globalMissionsData } from "../../global/missionList";
import { useAppDispatch } from "../../hooks/reduxHooks";
import { editOffice } from "../../features/offices/officesSlice";
import { globalUsersData, USERS_ID_FULL_NAME } from "../../global/usersList";
import { difference, uniq } from "lodash";
import { editUser } from "../../features/users/usersSlice";

interface Props {
  office: office;
  modalState: boolean;
  setModalState: React.Dispatch<React.SetStateAction<boolean>>;
}
const projectSchema = Yup.object().shape({});

function ManagePermissions(props: Props) {
  const dispatch = useAppDispatch();

  const { t } = useTranslation();

  const { modalState, setModalState, office } = props;
  const [loading, setLoading] = useState(false);

  const officeData = office as office;

  const MISSIONS = Object.values(globalMissionsData).map((u) => {
    return { ...u, id: u.id } as mission;
  });

  const formik = useFormik({
    initialValues: {
      officeName: "",
      location: "",
      admins: [],
      HRs: [],
      HRProjectsManagers: [],
      viewers: [],
      approvalCEO: false,
      workStartTime: moment(),
      workEndTime: moment(),
      region: "",
      contactPersonUID: "",
      cacheMissionName: "",
      missionId: "",
      restrictedFeatures: [],
      shiftVacationPerHours: 8,
    },
    validationSchema: projectSchema,

    onSubmit: async (values) => {
      try {
        await dispatch(
          editOffice({
            id: office.id,
            officeName: values.officeName,
            location: values.location,
            admins: values.admins,
            HRs: values.HRs,
            HRProjectsManagers: values.HRProjectsManagers,
            viewers: values.viewers,
            approvalCEO: values.approvalCEO,
            workStartTime: values.workStartTime as any,
            workEndTime: values.workEndTime as any,
            region: values.region,
            contactPersonUID: values.contactPersonUID,
            missionId: values.missionId,
            cacheMissionName: globalMissionsData[values.missionId].missionName,
            restrictedFeatures: values.restrictedFeatures ?? [],
            shiftVacationPerHours: values.shiftVacationPerHours,
          })
        )
          .unwrap()
          .then(() => {
            values.admins.forEach(async (uid) => {
              dispatch(editUser({ id: uid, adminOffices: uniq([...globalUsersData[uid]?.adminOffices, office.id]) }));
            });
          })

          .then(() => {
            values.HRs.forEach(async (uid) =>
              dispatch(editUser({ id: uid, HROffices: uniq([...globalUsersData[uid]?.HROffices, office.id]) }))
            );
          })
          .then(() => {
            values.HRProjectsManagers.forEach(async (uid) =>
              dispatch(
                editUser({
                  id: uid,
                  HRProjectsManagersOffices: uniq([...globalUsersData[uid]?.HRProjectsManagersOffices, office.id]),
                })
              )
            );
          })
          .then(() => {
            values.viewers.forEach(async (uid) =>
              dispatch(
                editUser({ id: uid, viewersOffices: uniq([...globalUsersData[uid]?.viewersOffices, office.id]) })
              )
            );
          })

          .then(() => {
            const removedUsers = difference(officeData.admins, values.admins);
            removedUsers.forEach(async (uid) => {
              dispatch(
                editUser({
                  id: uid,
                  adminOffices: globalUsersData[uid]?.adminOffices.filter((officeId) => officeId != office.id),
                })
              );
            });
          })
          .then(() => {
            const removedUsers = difference(officeData.HRs, values.HRs);
            removedUsers.forEach(async (uid) => {
              dispatch(
                editUser({
                  id: uid,
                  HROffices: globalUsersData[uid]?.HROffices.filter((officeId) => officeId != office.id),
                })
              );
            });
          })
          .then(() => {
            const removedUsers = difference(officeData.HRProjectsManagers, values.HRProjectsManagers);
            removedUsers.forEach(async (uid) => {
              dispatch(
                editUser({
                  id: uid,
                  HRProjectsManagersOffices: globalUsersData[uid]?.HRProjectsManagersOffices.filter(
                    (officeId) => officeId != office.id
                  ),
                })
              );
            });
          })
          .then(() => {
            const removedUsers = difference(officeData.viewers, values.viewers);
            removedUsers.forEach(async (uid) => {
              dispatch(
                editUser({
                  id: uid,
                  viewersOffices: globalUsersData[uid]?.viewersOffices.filter((officeId) => officeId != office.id),
                })
              );
            });
          });

        message.success("Office updated successfully");
        setModalState(false);
        setLoading(false);
      } catch (error) {
        setLoading(false);
        console.error("Error writing document: ", error);
        message.error("Failed");
      }

      // promise
    },
  });
  const [loaded, setLoaded] = useState(true);
  useEffect(() => {
    if (officeData && loaded) {
      formik.setValues({
        officeName: officeData.officeName,
        location: officeData.location,
        admins: officeData.admins ?? [],
        HRs: officeData.HRs ?? [],
        HRProjectsManagers: officeData.HRProjectsManagers ?? [],
        viewers: officeData.viewers ?? [],
        approvalCEO: officeData.approvalCEO,
        workStartTime: officeData.workStartTime && moment(officeData.workStartTime),
        workEndTime: officeData.workEndTime && moment(officeData.workEndTime),
        region: officeData.region,
        contactPersonUID: officeData.contactPersonUID,
        missionId: officeData.missionId,
        cacheMissionName: officeData.cacheMissionName,
        restrictedFeatures: officeData.restrictedFeatures,
        shiftVacationPerHours: officeData.shiftVacationPerHours ?? 0,
      } as any);
      setLoaded(false);
    }
  }, [officeData, formik, loaded]);

  const enterLoading = () => {
    setLoading(true);
  };
  const format = "hh:mm:ss a";

  return (
    <Drawer
      title={t("general.officePermissions")}
      width={500}
      height={350}
      visible={modalState}
      placement={"right"}
      onClose={() => setModalState(false)}
    >
      <Form onSubmit={formik.handleSubmit}>
        <Form.Item label={t("general.officeName")}>
          <Input
            value={formik.values.officeName}
            onChange={(e) => formik.setFieldValue("officeName", e.target.value)}
          />
        </Form.Item>
        <Form.Item label={t("general.location")}>
          <Input value={formik.values.location} onChange={(e) => formik.setFieldValue("location", e.target.value)} />
        </Form.Item>
        <Form.Item label={t("general.admins")}>
          <Select
            filterOption={(inputValue, option) => {
              return (option.props.children as any).toLowerCase().indexOf(inputValue.toLowerCase()) >= 0;
            }}
            mode="multiple"
            showSearch
            value={formik.values.admins}
            onChange={(e: any) => {
              formik.setFieldValue("admins", e);
            }}
          >
            {USERS_ID_FULL_NAME?.map((d) => {
              return (
                <Option key={d.uid} value={d.uid}>
                  {`${d.fullName}`}
                </Option>
              );
            })}
          </Select>
        </Form.Item>

        <Form.Item label={t("general.HRs")}>
          <Select
            filterOption={(inputValue, option) => {
              return (option.props.children as any).toLowerCase().indexOf(inputValue.toLowerCase()) >= 0;
            }}
            mode="multiple"
            showSearch
            value={formik.values.HRs}
            onChange={(e: any) => {
              formik.setFieldValue("HRs", e);
            }}
          >
            {USERS_ID_FULL_NAME?.map((d) => {
              return (
                <Option key={d.uid} value={d.uid}>
                  {`${d.fullName}`}
                </Option>
              );
            })}
          </Select>
        </Form.Item>

        <Form.Item label={t("general.HRProjectsManagers")}>
          <Select
            filterOption={(inputValue, option) => {
              return (option.props.children as any).toLowerCase().indexOf(inputValue.toLowerCase()) >= 0;
            }}
            mode="multiple"
            showSearch
            value={formik.values.HRProjectsManagers}
            onChange={(e: any) => {
              formik.setFieldValue("HRProjectsManagers", e);
            }}
          >
            {USERS_ID_FULL_NAME?.map((d) => {
              return (
                <Option key={d.uid} value={d.uid}>
                  {`${d.fullName}`}
                </Option>
              );
            })}
          </Select>
        </Form.Item>

        <Form.Item label={t("general.viewers")}>
          <Select
            filterOption={(inputValue, option) => {
              return (option.props.children as any).toLowerCase().indexOf(inputValue.toLowerCase()) >= 0;
            }}
            mode="multiple"
            showSearch
            value={formik.values.viewers}
            onChange={(e: any) => {
              formik.setFieldValue("viewers", e);
            }}
          >
            {USERS_ID_FULL_NAME?.map((d) => {
              return (
                <Option key={d.uid} value={d.uid}>
                  {`${d.fullName}`}
                </Option>
              );
            })}
          </Select>
        </Form.Item>

        <Form.Item label={t("general.startWork")}>
          <TimePicker
            use12Hours
            format={format}
            value={formik.values.workStartTime}
            onChange={(e) => formik.setFieldValue("workStartTime", e)}
          />
        </Form.Item>
        <Form.Item label={t("general.endWork")}>
          <TimePicker
            use12Hours
            format={format}
            value={formik.values.workEndTime}
            onChange={(e) => formik.setFieldValue("workEndTime", e)}
          />
        </Form.Item>
        <Form.Item labelAlign="right" label={t("general.approvalCEO")}>
          <Switch checked={formik.values.approvalCEO} onChange={(e) => formik.setFieldValue("approvalCEO", e)} />
        </Form.Item>

        <Form.Item label={t("general.contactPerson")}>
          <Select
            // mode="multiple"
            filterOption={(inputValue, option) => {
              return (option.props.children as any).toLowerCase().indexOf(inputValue.toLowerCase()) >= 0;
            }}
            showSearch
            value={formik.values.contactPersonUID}
            onChange={(e: any) => {
              formik.setFieldValue("contactPersonUID", e);
            }}
          >
            {USERS_ID_FULL_NAME?.map((d) => {
              return (
                <Option key={d.uid} value={d.uid}>
                  {d.fullName}
                </Option>
              );
            })}
          </Select>
        </Form.Item>
        <Form.Item label={t("general.shiftVacationPerHours")}>
          <InputNumber
            value={formik.values.shiftVacationPerHours}
            onChange={(e) => formik.setFieldValue("shiftVacationPerHours", e)}
          />
        </Form.Item>
        <Form.Item label={t("general.region")}>
          <Input value={formik.values.region} onChange={(e) => formik.setFieldValue("region", e.target.value)} />
        </Form.Item>
        <Form.Item label={t("general.mission")}>
          <Select
            showSearch
            value={globalMissionsData[formik.values.missionId]?.missionName}
            onChange={(e: any) => {
              formik.setFieldValue("missionId", e);
            }}
          >
            {MISSIONS?.map((d) => {
              return (
                <Option key={d.id} value={d.id}>
                  {d.missionName}
                </Option>
              );
            })}
          </Select>
        </Form.Item>
        <Form.Item label={t("Restricted Features")}>
          <Checkbox.Group
            disabled={false}
            onChange={(d) => formik.setFieldValue("restrictedFeatures", d)}
            value={formik.values.restrictedFeatures ?? []}
          >
            <Row>
              <Checkbox value="vacations">Vacations</Checkbox>
              <Checkbox value="overtime">Overtime</Checkbox>
              <Checkbox value="delays">Delays</Checkbox>
              <Checkbox value="cars">Cars</Checkbox>
              <Checkbox value="tasks">Tasks</Checkbox>
              <Checkbox value="workPermit">Work Permit</Checkbox>
            </Row>
          </Checkbox.Group>
        </Form.Item>
        <div>
          <Button
            className="btn blue lighten-1 z-depth-0"
            onClick={() => {
              enterLoading();
            }}
            loading={loading}
            type="primary"
            htmlType="submit"
          >
            {t("general.save")}
          </Button>
        </div>
      </Form>
    </Drawer>
  );
}

export default ManagePermissions;
