import React, { useState, useEffect } from "react";
import Paper from "@material-ui/core/Paper";
import { Spin, Button, Popconfirm, Select, message } from "antd";
const { Option } = Select;
import { useTranslation } from "react-i18next";
import moment from "moment";
import DevTable from "../../helpers/devex-table";
import { globalOfficesData, OFFICES_ID_NAME } from "../../global/officeList";
import { globalUsersData } from "../../global/usersList";
import EditSalary from "./editSalaryDialog";
import { useAppDispatch, useAppSelector } from "../../hooks/reduxHooks";
import { deleteSalary, fetchSalariesByOffice, subscribeToSalaries } from "../../features/salaries/salariesSlice";
import { salary } from "../../db/interfaces";
import { setFilterValue } from "../../features/filters/filtersSlice";

export default function SalariesTable() {
  const { filterValues } = useAppSelector((state) => state.filters);
  const { data: salariesData, status, error } = useAppSelector((store) => store.salaries);
  const { currentUser } = useAppSelector((state) => state.auth);
  const dispatch = useAppDispatch();
  const { t } = useTranslation();
  const [officeId, setOfficeId] = useState(filterValues["officeId"] ?? "Select an office...");

  const [modalStateEdit, setModalStateEdit] = useState(false);
  const [salaryId, setSalaryId] = useState("");
  const [salary, setSalary] = useState({} as salary);

  useEffect(() => {
    dispatch(fetchSalariesByOffice(officeId));
    const unsubscribe = subscribeToSalaries(dispatch);
    return () => {
      unsubscribe();
    };
  }, [dispatch, officeId]);

  if (status == "loading") return <Spin />;

  const data = salariesData
    .filter((d) => currentUser.HRProjectsManagersOffices.includes(d.office))
    .map((d) => {
      return { ...d, id: d.id };
    });

  const columns = [
    {
      name: "uid",
      title: "UID",
    },
    {
      name: "name",
      title: "Name",
      getCellValue: (row: any) => globalUsersData[row.uid]?.firstName + " " + globalUsersData[row.uid]?.lastName,
    },
    {
      name: "by",
      title: "Raised By",
      getCellValue: (row: any) => globalUsersData[row.by]?.firstName + " " + globalUsersData[row.by]?.lastName,
    },
    {
      name: "salary",
      title: "Salary",
    },
    {
      name: "currency",
      title: "Currency",
    },
    {
      name: "office",
      title: "Office",
      getCellValue: (row: any) => globalOfficesData[row.office]?.officeName,
    },

    {
      name: "startDate",
      title: "Start Date",
      getCellValue: (row: any) => moment(row.startDate).format("DD.MM.YYYY"),
    },

    {
      name: "actions",
      title: "Actions",
      getCellValue: (row: any) => {
        return (
          <Popconfirm
            title={t("general.areUSure")}
            onConfirm={() => {
              dispatch(deleteSalary(row.id)).unwrap();
              message.success("Deleted Successfully!");
            }}
            onCancel={() => console.log("cancel")}
            okText="Yes"
            cancelText="No"
          >
            <Button type="link">Delete</Button>
          </Popconfirm>
        );
      },
    },
    {
      name: "edit",
      title: "Edit",
      getCellValue: (row: any) => {
        return (
          <Button
            onClick={() => {
              setModalStateEdit(true);
              setSalaryId(row.id);
              setSalary(row);
            }}
            type="primary"
          >
            Edit
          </Button>
        );
      },
    },
  ];
  const columnsExport = [
    {
      name: "uid",
      title: "UID",
    },
    {
      name: "name",
      title: "Name",
      getCellValue: (row: any) => globalUsersData[row.uid]?.firstName + " " + globalUsersData[row.uid]?.lastName,
    },
    {
      name: "by",
      title: "Raised By",
      getCellValue: (row: any) => globalUsersData[row.by]?.firstName + " " + globalUsersData[row.by]?.lastName,
    },
    {
      name: "salary",
      title: "Salary",
    },
    {
      name: "salary",
      title: "Salary",
    },
    {
      name: "office",
      title: "Office",
      getCellValue: (row: any) => globalOfficesData[row.office]?.officeName,
    },

    {
      name: "startDate",
      title: "Start Date",
      getCellValue: (row: any) => moment(row.startDate).format("DD.MM.YYYY"),
    },
    // {
    //   name: "endDate",
    //   title: "End Date",
    //   getCellValue: (row: any) => moment(row.endDate).format("DD.MM.YYYY"),
    // },
  ];

  return error ? (
    <p>{error}</p>
  ) : (
    <div>
      <Select
        filterOption={(inputValue, option) => {
          return (option.props.children as any).toLowerCase().indexOf(inputValue.toLowerCase()) >= 0;
        }}
        size="default"
        placeholder="Search for Office!"
        style={{ width: "25%", paddingBottom: "1%", paddingRight: "1%" }}
        showSearch
        value={officeId}
        onChange={(e) => {
          setOfficeId(e);
          dispatch(setFilterValue({ key: "officeId", value: e }));
        }}
      >
        {OFFICES_ID_NAME.map((d) => {
          return <Option value={d.officeId}>{d.officeName}</Option>;
        })}
      </Select>
      {modalStateEdit ? (
        <EditSalary modalState={modalStateEdit} setModalState={setModalStateEdit} salaryId={salaryId} salary={salary} />
      ) : null}{" "}
      <Paper>
        <DevTable
          data={data}
          columns={columns}
          defaultHiddenColumnNames={[]}
          tableName="deduction"
          selected={false}
          typicalTable={false}
          columnsExport={columnsExport}
        />
      </Paper>
    </div>
  );
}
