import { createSlice, createAsyncThunk, PayloadAction } from "@reduxjs/toolkit";
import { supabase } from "../../supabaseClient"; // Adjust the import path as needed
import { deduction } from "../../db/interfaces"; // Adjust the import path as needed

interface DeductionSliceState {
  data: deduction[];
  status: "loading" | "succeeded" | "failed" | "idle";
  error: string | null;
}

const initialState: DeductionSliceState = {
  data: [],
  status: "idle",
  error: null,
};

// Fetch all deduction records
export const fetchAllDeductions = createAsyncThunk<deduction[], void, { rejectValue: string }>(
  "deduction/fetchAllDeductions",
  async (_, thunkApi) => {
    const { data, error } = await supabase.from("deductions").select("*");
    if (error) {
      return thunkApi.rejectWithValue(error.message);
    }
    return data as deduction[];
  }
);

export const fetchDeductionsByOffice = createAsyncThunk<deduction[], string, { rejectValue: string }>(
  "workOffice/fetchDeductionsByOffice",
  async (officeId, thunkApi) => {
    const { data, error } = await supabase.from("deductions").select("*").eq("office", officeId);

    if (error) {
      return thunkApi.rejectWithValue(error.message);
    }

    return data as deduction[];
  }
);

export const fetchDeductionsByMonth = createAsyncThunk<deduction[], string, { rejectValue: string }>(
  "workOffice/fetchDeductionsByMonth",
  async (month, thunkApi) => {
    const { data, error } = await supabase.from("deductions").select("*").eq("month", month);

    if (error) {
      return thunkApi.rejectWithValue(error.message);
    }

    return data as deduction[];
  }
);

export const fetchDeductionsByOfficeAndMonth = createAsyncThunk<
  deduction[],
  { officeId: string; month: string },
  { rejectValue: string }
>("workOffice/fetchDeductionsByOfficeAndMonth", async ({ officeId, month }, thunkApi) => {
  const { data, error } = await supabase.from("deductions").select("*").eq("office", officeId).eq("month", month);

  if (error) {
    return thunkApi.rejectWithValue(error.message);
  }

  return data as deduction[];
});

// Add a new deduction record
export const addDeduction = createAsyncThunk<deduction, deduction, { rejectValue: string }>(
  "deduction/addDeduction",
  async (newDeduction, thunkApi) => {
    const { data: insertData, error: insertError } = await supabase
      .from("deductions")
      .insert(newDeduction)
      .select()
      .single();

    if (insertError) {
      return thunkApi.rejectWithValue(insertError.message);
    }

    return insertData as deduction;
  }
);

// Edit a deduction record
export const editDeduction = createAsyncThunk<deduction, deduction, { rejectValue: string }>(
  "deduction/editDeduction",
  async (updatedDeduction, thunkApi) => {
    const { data: updateData, error: updateError } = await supabase
      .from("deductions")
      .update(updatedDeduction)
      .eq("id", updatedDeduction.id)
      .select()
      .single();

    if (updateError) {
      return thunkApi.rejectWithValue(updateError.message);
    }

    return updateData as deduction;
  }
);

// Delete a deduction record
export const deleteDeduction = createAsyncThunk<string, string, { rejectValue: string }>(
  "deduction/deleteDeduction",
  async (id, thunkApi) => {
    const { error } = await supabase.from("deductions").delete().eq("id", id);

    if (error) {
      return thunkApi.rejectWithValue(error.message);
    }

    return id;
  }
);

// Real-Time Actions
export const addDeductionRealTime = (newDeduction: deduction): PayloadAction<deduction> => ({
  type: "deduction/addDeductionRealTime",
  payload: newDeduction,
});

export const editDeductionRealTime = (updatedDeduction: deduction): PayloadAction<deduction> => ({
  type: "deduction/editDeductionRealTime",
  payload: updatedDeduction,
});

export const deleteDeductionRealTime = (id: string): PayloadAction<string> => ({
  type: "deduction/deleteDeductionRealTime",
  payload: id,
});

// Slice definition
const deductionSlice = createSlice({
  name: "deduction",
  initialState,
  reducers: {
    addDeductionRealTime(state, action: PayloadAction<deduction>) {
      const exists = state.data.some((deduction) => deduction.id === action.payload.id);
      if (!exists) {
        state.data.push(action.payload);
      }
      state.error = null;
    },
    editDeductionRealTime(state, action: PayloadAction<deduction>) {
      const index = state.data.findIndex((deduction) => deduction.id === action.payload.id);
      if (index !== -1) {
        const isDifferent = JSON.stringify(state.data[index]) !== JSON.stringify(action.payload);
        if (isDifferent) {
          state.data[index] = action.payload;
        }
      }
      state.error = null;
    },
    deleteDeductionRealTime(state, action: PayloadAction<string>) {
      const exists = state.data.some((deduction) => deduction.id === action.payload);
      if (exists) {
        state.data = state.data.filter((deduction) => deduction.id !== action.payload);
      }
      state.error = null;
    },
  },
  extraReducers: (builder) => {
    builder
      // Handle fetchAllDeductions
      .addCase(fetchAllDeductions.pending, (state) => {
        state.status = "loading";
      })
      .addCase(fetchAllDeductions.fulfilled, (state, action: PayloadAction<deduction[]>) => {
        state.status = "succeeded";
        state.data = action.payload;
        state.error = null;
      })
      .addCase(fetchAllDeductions.rejected, (state, action: PayloadAction<string | undefined>) => {
        state.status = "failed";
        state.error = action.payload || "Failed to fetch deductions.";
      })

      // Handle fetchDeductionsByOffice
      .addCase(fetchDeductionsByOffice.pending, (state) => {
        state.status = "loading";
      })
      .addCase(fetchDeductionsByOffice.fulfilled, (state, action: PayloadAction<deduction[]>) => {
        state.status = "succeeded";
        state.data = action.payload;
        state.error = null;
      })
      .addCase(fetchDeductionsByOffice.rejected, (state, action: PayloadAction<string | undefined>) => {
        state.status = "failed";
        state.error = action.payload || "Failed to fetch Deductions by office.";
      })
      // Handle fetchDeductionsByMonth
      .addCase(fetchDeductionsByMonth.pending, (state) => {
        state.status = "loading";
      })
      .addCase(fetchDeductionsByMonth.fulfilled, (state, action: PayloadAction<deduction[]>) => {
        state.status = "succeeded";
        state.data = action.payload;
        state.error = null;
      })
      .addCase(fetchDeductionsByMonth.rejected, (state, action: PayloadAction<string | undefined>) => {
        state.status = "failed";
        state.error = action.payload || "Failed to fetch Deductions by office.";
      })

      // Handle fetchDeductionsByOfficeAndMonth
      .addCase(fetchDeductionsByOfficeAndMonth.pending, (state) => {
        state.status = "loading";
      })
      .addCase(fetchDeductionsByOfficeAndMonth.fulfilled, (state, action: PayloadAction<deduction[]>) => {
        state.status = "succeeded";
        state.data = action.payload;
        state.error = null;
      })
      .addCase(fetchDeductionsByOfficeAndMonth.rejected, (state, action: PayloadAction<string | undefined>) => {
        state.status = "failed";
        state.error = action.payload || "Failed to fetch Deductions by office.";
      })

      // Handle addDeduction
      .addCase(addDeduction.pending, (state) => {})
      .addCase(addDeduction.fulfilled, (state, action: PayloadAction<deduction>) => {
        state.data.push(action.payload);
        state.error = null;
      })
      .addCase(addDeduction.rejected, (state, action: PayloadAction<string | undefined>) => {
        state.error = action.payload || "Failed to add deduction.";
      })

      // Handle editDeduction
      .addCase(editDeduction.pending, (state) => {})
      .addCase(editDeduction.fulfilled, (state, action: PayloadAction<deduction>) => {
        const index = state.data.findIndex((deduction) => deduction.id === action.payload.id);
        if (index !== -1) {
          state.data[index] = action.payload;
        }
        state.error = null;
      })
      .addCase(editDeduction.rejected, (state, action: PayloadAction<string | undefined>) => {
        state.status = "failed";
        state.error = action.payload || "Failed to edit deduction.";
      })

      // Handle deleteDeduction
      .addCase(deleteDeduction.pending, (state) => {})
      .addCase(deleteDeduction.fulfilled, (state, action: PayloadAction<string>) => {
        state.data = state.data.filter((deduction) => deduction.id !== action.payload);
        state.error = null;
      })
      .addCase(deleteDeduction.rejected, (state, action: PayloadAction<string | undefined>) => {
        state.error = action.payload || "Failed to delete deduction.";
      });
  },
});

// Real-time subscription function
export const subscribeToDeductions = (dispatch: any) => {
  const subscription = supabase
    .channel("public:deductions")
    .on("postgres_changes", { event: "*", schema: "public", table: "deductions" }, (payload) => {
      const newRecord = payload.new as deduction;
      const oldRecord = payload.old as deduction;
      switch (payload.eventType) {
        case "INSERT":
          dispatch(addDeductionRealTime(newRecord));
          break;
        case "UPDATE":
          dispatch(editDeductionRealTime(newRecord));
          break;
        case "DELETE":
          dispatch(deleteDeductionRealTime(oldRecord.id ?? ""));
          break;
        default:
          break;
      }
    })
    .subscribe();

  return () => {
    subscription.unsubscribe();
  };
};

export default deductionSlice.reducer;
