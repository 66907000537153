import React, { useState, useEffect } from "react";
import Paper from "@material-ui/core/Paper";
import { Spin, Button, message, Popconfirm } from "antd";
import moment from "moment";
import { useRouteMatch } from "react-router-dom";
import { users } from "../../db/interfaces";
import { useAppDispatch, useAppSelector } from "../../hooks/reduxHooks";
import { deleteAccess, fetchAccessByUserId, subscribeToAccess } from "../../features/access/accessSlice";
import { globalUsersData } from "../../global/usersList";
import { globalOfficesData } from "../../global/officeList";
import DevTable from "../../helpers/devex-table";
import { useTranslation } from "react-i18next";

export default function FingerPrintRecord() {
  const userId = useRouteMatch<{ id: string }>().params.id;
  const { t } = useTranslation();

  const { data: accessData, status, error } = useAppSelector((store) => store.access);
  const { currentUser } = useAppSelector((state) => state.auth);

  const dispatch = useAppDispatch();

  useEffect(() => {
    dispatch(fetchAccessByUserId(userId));

    const unsubscribe = subscribeToAccess(dispatch);
    return () => {
      unsubscribe();
    };
  }, [dispatch]);

  const [defaultHiddenColumnNames] = useState([]);

  if (status == "loading") return <Spin />;

  const userData = globalUsersData[userId ?? ""] as users;
  const fullName = userData.firstName + " " + userData.lastName;
  const data: any = accessData.map((d, index) => {
    return {
      ...d,
      id: d.id,
      index: index + 1,
    };
  });
  const columnsExport = [
    { name: "index", title: "NO" },
    {
      name: "date",
      title: "Date",
      getCellValue: (r: any) => moment(r.date).format("DD.MM.YYYY  hh:mm:ss  a"),
    },
    {
      name: "operationDate",
      title: "Operation Date",
      getCellValue: (r: any) => moment(r.operationDate).format("DD.MM.YYYY  hh:mm:ss  a"),
    },
    { name: "notes", title: "Notes" },
    { name: "officeId", title: "OfficeId" },
    { name: "uid", title: "User Id" },
  ];
  const columns = [
    { name: "index", title: "NO" },
    {
      name: "date",
      title: "Date",
      getCellValue: (r: any) => moment(r.date).format("DD.MM.YYYY  hh:mm:ss  a"),
    },
    {
      name: "operationDate",
      title: "Operation Date",
      getCellValue: (r: any) => moment(r.operationDate).format("DD.MM.YYYY hh:mm:ss  a"),
    },
    { name: "notes", title: "Notes" },
    {
      name: "officeId",
      title: "OfficeId",
      getCellValue: (r: any) => globalOfficesData[r.officeId ?? ""]?.officeName,
    },
    {
      name: "action",
      title: "Delete",
      getCellValue: (r) => {
        return currentUser?.fullControlOffices?.includes(userData.office) == true ? (
          <Popconfirm
            title={t("general.areUSure")}
            onConfirm={() => {
              dispatch(deleteAccess(r.id)).unwrap();
              message.success("Deleted Successfully!");
            }}
            onCancel={() => console.log("cancel")}
            okText="Yes"
            cancelText="No"
          >
            <Button type="danger">Delete</Button>
          </Popconfirm>
        ) : null;
      },
    },
  ];
  return error ? (
    <p>{error}</p>
  ) : (
    <div>
      <div
        style={{
          padding: "1%",
          background: "#fff",
          marginBottom: "1%",
          border: "thin",
          borderRadius: "10px",
          boxShadow: "2px 2px 2px 0px #c2c2c2",
        }}
      >
        <h2>{fullName}</h2>
      </div>
      <Paper>
        <DevTable
          data={data}
          columns={columns}
          defaultHiddenColumnNames={defaultHiddenColumnNames}
          tableName="finger prints"
          selected={false}
          typicalTable={false}
          columnsExport={columnsExport}
        />
      </Paper>
    </div>
  );
}
