import React, { useState } from "react";
import { Route, RouteComponentProps } from "react-router";
import { Row, Col, Button } from "antd";
import AddNewOvertime from "./OverTimeModules/AddNewOvertime";
import OvertimeList from "./overtimeList";
import OvertimeDetails from "./overtimeDetails";

export default function OvertimeDashboard() {
  const [modalState, setModalState] = useState(false);

  return (
    <div>
      <AddNewOvertime modalState={modalState} setModalState={setModalState} />

      <div style={{ marginTop: "-1%" }}>
        <Row gutter={30} style={{ alignItems: "center" }}>
          <Col span={16}>
            <Route
              path="/overtime/:id"
              component={(props: RouteComponentProps<{ id: string }>) => (
                <OvertimeDetails key={props.match.params.id} {...props} />
              )}
            />
          </Col>
          <Col span={1}>
            <Button
              type="primary"
              shape="circle"
              size="large"
              onClick={() => {
                setModalState(true);
              }}
            >
              +
            </Button>
          </Col>
          <Col span={5}>
            <OvertimeList />
          </Col>
        </Row>
      </div>
    </div>
  );
}
