import React from "react";
import { message, Modal } from "antd";
import { useTranslation } from "react-i18next";
import { OutputData } from "@editorjs/editorjs";
import { dataToHTML } from "../Editor/constants";
import { useAppDispatch, useAppSelector } from "../../hooks/reduxHooks";
import { editCalendarEvent } from "../../features/calendar/calendarSlice";
import { addNotification } from "../../features/notifications/notificationsSlice";
import moment from "moment";

interface Props {
  setModalStateMOM: React.Dispatch<React.SetStateAction<boolean>>;
  modalStateSendMOM: boolean;
  setModalStateSendMOM: React.Dispatch<React.SetStateAction<boolean>>;
  docId: string;
  MOM: string | OutputData;
  mentions: any;
  MOMWriter: string;
  files: any[];
  downloadURLs: any[];
}

function SendMOM(props: Props) {
  const { currentUser } = useAppSelector((state) => state.auth);
  const dispatch = useAppDispatch();

  const { t } = useTranslation();
  const { modalStateSendMOM, setModalStateSendMOM, MOM, mentions, MOMWriter, files, downloadURLs, setModalStateMOM } =
    props;

  const uid = currentUser.id as string;

  return (
    <Modal
      title={t("general.addMOM")}
      okText={t("general.yes")}
      cancelText={t("general.no")}
      visible={modalStateSendMOM}
      onCancel={async () => {
        try {
          await dispatch(
            editCalendarEvent({
              id: props.docId,
              momAttach: downloadURLs,
              filesNames: files,
              MOM: dataToHTML(MOM as OutputData),
              MOMObj: MOM as any,
              MOMWriter: MOMWriter,
              sendMOM: false,
            } as any)
          );

          await mentions.map((id: string) => {
            dispatch(
              addNotification({
                notifyTo: id,
                notifyFrom: uid,
                orderId: props.docId,
                read: false,
                kind: "mention",
                content: "Someone mentioned you in Meetings!",
                postedAt: moment() as any,
              })
            );
          });
          message.success(t("general.success"));

          setModalStateSendMOM(false);
          setModalStateMOM(false);
          window.location.reload();
        } catch (e) {
          console.log(e);
          message.error(t("general.error"));
        }
      }}
      onOk={async () => {
        try {
          await dispatch(
            editCalendarEvent({
              id: props.docId,
              momAttach: downloadURLs,
              filesNames: files,
              MOM: dataToHTML(MOM as OutputData),
              MOMObj: MOM,
              MOMWriter: MOMWriter,
              sendMOM: true,
            } as any)
          )
            .then(() => {
              mentions.map((id: string) => {
                dispatch(
                  addNotification({
                    notifyTo: id,
                    notifyFrom: uid,
                    orderId: props.docId,
                    read: false,
                    kind: "mention",
                    content: "Someone mentioned you in Meetings!",
                    postedAt: moment() as any,
                  })
                );
              });
              message.success(t("general.success"));
            })
            .then(() => {
              setModalStateSendMOM(false);
              setModalStateMOM(false);
              window.location.reload();
            });
        } catch (e) {
          console.log(e);
          message.error(t("general.error"));
        }
      }}
      closable={false}
    >
      <div>Do you want to send MOM via Email?</div>
    </Modal>
  );
}

export default SendMOM;
