import React from "react";
import { DatePicker, Form, InputNumber, message, Input, Descriptions, Modal } from "antd";
const { TextArea } = Input;
import moment from "moment";
import { useFormik } from "formik";
import * as Yup from "yup";
import { useTranslation } from "react-i18next";
import { users } from "../../db/interfaces";
import { useAppDispatch, useAppSelector } from "../../hooks/reduxHooks";
import { globalUsersData } from "../../global/usersList";
import { addDelay } from "../../features/delays/delaysSlice";

interface Props {
  modalState: boolean;
  setModalState: React.Dispatch<React.SetStateAction<boolean>>;
  UID: string;
}
const projectSchema = Yup.object().shape({
  dayDate: Yup.date().required("*"),
  min: Yup.number().required("*"),
});

function DelayModel(props: Props) {
  const { currentUser } = useAppSelector((state) => state.auth);
  const dispatch = useAppDispatch();

  const { t } = useTranslation();
  const { modalState, setModalState, UID } = props;
  const uid = currentUser?.id as string;
  const data = globalUsersData[UID ?? ""] as users;
  const staffFullName = data.fullName;

  const formik = useFormik({
    initialValues: {
      dayDate: moment(),
      min: 0,
      notes: "",
    },
    validationSchema: projectSchema,
    onSubmit: async (values, form) => {
      try {
        dispatch(
          addDelay({
            month: moment().format("MMM.YYYY").toString(),
            dayDate: values.dayDate.toDate() as any,
            min: values.min,
            notes: values.notes,
            userUID: UID,
            office: data.office,
            canceledDate: null,
            canceledBy: "",
            status: "enabled",
            by: uid,
          })
        );
        message.success("Added successfully!");
        setModalState(false);
        form.resetForm();
      } catch (error) {
        message.error("Error!");
        setModalState(false);
        form.resetForm();
      }
    },
  });

  const dateFormat = "YYYY/MM/DD";

  return (
    <Modal
      title={staffFullName}
      width={600}
      visible={modalState}
      onCancel={() => setModalState(false)}
      onOk={() => formik.handleSubmit()}
    >
      <Form onSubmit={formik.handleSubmit}>
        <div>
          <div>
            <Descriptions
              size={"default"}
              layout="horizontal"
              bordered
              column={{ xxl: 6, xl: 4, lg: 3, md: 3, sm: 2, xs: 1 }}
            >
              <Descriptions.Item label={t("general.dayDate")} span={6}>
                <DatePicker
                  value={formik.values.dayDate}
                  format={dateFormat}
                  onChange={(e) => formik.setFieldValue("dayDate", e)}
                />{" "}
              </Descriptions.Item>
              <Descriptions.Item label={t("general.min")} span={6}>
                <InputNumber
                  max={1000}
                  min={0}
                  value={formik.values.min}
                  onChange={(e) => formik.setFieldValue("min", e)}
                />
              </Descriptions.Item>
              <Descriptions.Item label={t("general.notes")} span={6}>
                <TextArea
                  value={formik.values.notes}
                  autoSize={{ minRows: 3, maxRows: 5 }}
                  onChange={(e) => formik.setFieldValue("notes", e.target.value)}
                />
              </Descriptions.Item>
            </Descriptions>
          </div>
        </div>
      </Form>
    </Modal>
  );
}

export default DelayModel;
