import { createSlice, createAsyncThunk, PayloadAction } from "@reduxjs/toolkit";
import { supabase } from "../../supabaseClient"; // Adjust the import path as needed
import { PositionTask } from "../../db/interfaces"; // Adjust the import path as needed

interface PositionTasksState {
  data: PositionTask[];
  error: string | null;
  status: "loading" | "succeeded" | "failed" | "idle";
}

const initialState: PositionTasksState = {
  data: [],
  error: null,
  status: "idle",
};

// Fetch all position tasks from Supabase
export const fetchPositionTasks = createAsyncThunk<PositionTask[], void, { rejectValue: string }>(
  "positionTasks/fetchPositionTasks",
  async (_, thunkApi) => {
    const { data, error } = await supabase.from("positionTasks").select(`
        *,
        task:taskId(*),
        department:departmentId(*),
        position:positionId(*)
      `);

    if (error) {
      return thunkApi.rejectWithValue(error.message);
    }
    return data as PositionTask[];
  }
);

// Fetch all position tasks from Supabase
export const fetchAllPositionTasksByDepartmentId = createAsyncThunk<PositionTask[], string, { rejectValue: string }>(
  "positionTasks/fetchAllPositionTasksByDepartmentId",
  async (departmentId, thunkApi) => {
    const { data, error } = await supabase
      .from("positionTasks")
      .select(
        `
        *,
        task:taskId(*),
        department:departmentId(*),
        position:positionId(*)
      `
      )
      .eq("departmentId", departmentId);

    if (error) {
      return thunkApi.rejectWithValue(error.message);
    }
    return data as PositionTask[];
  }
);

// Add a new position task
export const addPositionTask = createAsyncThunk<PositionTask, PositionTask, { rejectValue: string }>(
  "positionTasks/addPositionTask",
  async (newTask, thunkApi) => {
    const { data, error } = await supabase.from("positionTasks").insert(newTask).select();

    if (error) {
      return thunkApi.rejectWithValue(error.message);
    }

    return data[0] as PositionTask;
  }
);

// Update an existing position task
export const updatePositionTask = createAsyncThunk<
  PositionTask,
  { id: string; changes: Partial<PositionTask> },
  { rejectValue: string }
>("positionTasks/updatePositionTask", async ({ id, changes }, thunkApi) => {
  const { data, error } = await supabase.from("positionTasks").update(changes).eq("id", id).select();

  if (error) {
    return thunkApi.rejectWithValue(error.message);
  }

  return data[0] as PositionTask;
});

// Delete a position task
export const deletePositionTask = createAsyncThunk<string, string, { rejectValue: string }>(
  "positionTasks/deletePositionTask",
  async (id, thunkApi) => {
    const { error } = await supabase.from("positionTasks").delete().eq("id", id);

    if (error) {
      return thunkApi.rejectWithValue(error.message);
    }

    return id;
  }
);

const positionTasksSlice = createSlice({
  name: "positionTasks",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    // Fetch position tasks
    builder.addCase(fetchPositionTasks.pending, (state) => {
      state.status = "loading";
      state.error = null;
    });
    builder.addCase(fetchPositionTasks.fulfilled, (state, action: PayloadAction<PositionTask[]>) => {
      state.data = action.payload;
      state.status = "succeeded";
    });
    builder.addCase(fetchPositionTasks.rejected, (state, action) => {
      state.status = "failed";
      state.error = action.payload || "Failed to fetch position tasks";
    });

    builder.addCase(fetchAllPositionTasksByDepartmentId.pending, (state) => {
      state.status = "loading";
      state.error = null;
    });
    builder.addCase(fetchAllPositionTasksByDepartmentId.fulfilled, (state, action: PayloadAction<PositionTask[]>) => {
      state.data = action.payload;
      state.status = "succeeded";
    });
    builder.addCase(fetchAllPositionTasksByDepartmentId.rejected, (state, action) => {
      state.status = "failed";
      state.error = action.payload || "Failed to fetch position tasks";
    });

    // Add position task
    builder.addCase(addPositionTask.pending, (state) => {
      state.status = "loading";
      state.error = null;
    });
    builder.addCase(addPositionTask.fulfilled, (state, action: PayloadAction<PositionTask>) => {
      state.data.push(action.payload);
      state.status = "succeeded";
    });
    builder.addCase(addPositionTask.rejected, (state, action) => {
      state.status = "failed";
      state.error = action.payload || "Failed to add position task";
    });

    // Update position task
    builder.addCase(updatePositionTask.pending, (state) => {
      state.status = "loading";

      state.error = null;
    });
    builder.addCase(updatePositionTask.fulfilled, (state, action: PayloadAction<PositionTask>) => {
      const index = state.data.findIndex((task) => task.id === action.payload.id);
      if (index !== -1) {
        state.data[index] = action.payload;
      }
      state.status = "succeeded";
    });
    builder.addCase(updatePositionTask.rejected, (state, action) => {
      state.status = "failed";
      state.error = action.payload || "Failed to update position task";
    });

    // Delete position task
    builder.addCase(deletePositionTask.pending, (state) => {
      state.status = "loading";

      state.error = null;
    });
    builder.addCase(deletePositionTask.fulfilled, (state, action: PayloadAction<string>) => {
      state.data = state.data.filter((task) => task.id !== action.payload);
      state.status = "succeeded";
    });
    builder.addCase(deletePositionTask.rejected, (state, action) => {
      state.status = "failed";
      state.error = action.payload || "Failed to delete position task";
    });
  },
});

export default positionTasksSlice.reducer;
