import React, { useState, useEffect } from "react";
import Paper from "@material-ui/core/Paper";
import { Spin, Button } from "antd";
import { mission } from "../../db/interfaces";
import MissionDialog from "./missionDialog";
import { globalUsersData } from "../../global/usersList";
import DevTable from "../../helpers/devex-table";
import { useAppDispatch, useAppSelector } from "../../hooks/reduxHooks";
import { fetchMissionsInBatches, subscribeToMissions } from "../../features/missions/missionsSlice";

export default function MissionsTable() {
  const { data, status } = useAppSelector((state) => state.missions);
  const dispatch = useAppDispatch();

  const [modalState, setModalState] = useState(false);
  const [mission, setMission] = useState({} as any);

  const [defaultHiddenColumnNames] = useState([]);

  useEffect(() => {
    dispatch(fetchMissionsInBatches());

    const unsubscribe = subscribeToMissions(dispatch);
    return () => {
      unsubscribe();
    };
  }, [dispatch]);

  if (status == "loading") return <Spin />;

  const columnsExport = [
    { name: "missionName", title: "Mission Name" },
    {
      name: "missionCEO",
      title: "Mission CEO",
      getCellValue: (r: mission) => {
        return r.missionCEO.map((uid, index) => `${index + 1}) ${globalUsersData[uid]?.fullName}`);
      },
    },

    { name: "missionLocation", title: "Mission Location" },
    { name: "weekendDays", title: "Weekend" },
    { name: "holidayDates", title: "Official Holidays" },
  ];
  const columns = [
    { name: "missionName", title: "Mission Name" },
    {
      name: "missionCEO",
      title: "Mission CEO",
      getCellValue: (r: mission) => {
        return r.missionCEO.map((uid, index) => (
          <>
            <b>{`${index + 1}) ${globalUsersData[uid]?.fullName}`}</b>
            <br />
          </>
        ));
      },
    },
    { name: "missionLocation", title: "Mission Location" },
    {
      name: "weekendDays",
      title: "Weekend",
      getCellValue: (r: mission) => {
        return r.weekendDays.map((day, index) => (
          <>
            <b>{`${index + 1}) ${day}`}</b>
            <br />
          </>
        ));
      },
    },
    {
      name: "holidayDates",
      title: "Official Holidays",
      getCellValue: (r: mission) => {
        return r.holidayDates.map((date, index) => (
          <>
            <b>{`${index + 1}) ${date}`}</b>
            <br />
          </>
        ));
      },
    },

    {
      name: "action",
      title: "Edit",
      getCellValue: (r: mission) => {
        return (
          <Button
            type="primary"
            onClick={() => {
              setMission(r);
              setModalState(true);
            }}
          >
            Edit
          </Button>
        );
      },
    },
  ];
  return (
    <div>
      <Button
        style={{ marginBottom: "1%" }}
        type={"primary"}
        onClick={() => {
          setModalState(true);
          setMission(undefined);
        }}
      >
        Add Mission
      </Button>
      {modalState ? <MissionDialog modalState={modalState} setModalState={setModalState} mission={mission} /> : null}
      <Paper>
        <DevTable
          data={data}
          columns={columns}
          defaultHiddenColumnNames={defaultHiddenColumnNames}
          tableName="Missions"
          selected={false}
          typicalTable={false}
          columnsExport={columnsExport}
        />
      </Paper>
    </div>
  );
}
