import React, { useEffect, useState } from "react";
import { Spin, Button, DatePicker, Select } from "antd";
import { sortBy } from "lodash";
import AddNewOvertime from "./OverTimeModules/AddNewOvertime";
import OvertimeSummary from "./overtimeSummary";
import { useAppDispatch, useAppSelector } from "../../hooks/reduxHooks";
import { fetchOvertimeByOfficeByDate, subscribeToOvertime } from "../../features/overtime/overtimeSlice";
import moment from "moment";
import { OFFICES_ID_NAME } from "../../global/officeList";
import { setFilterValue } from "../../features/filters/filtersSlice";
import { globalUsersData } from "../../global/usersList";
const { MonthPicker } = DatePicker;
const { Option } = Select;

export default function OvertimeList() {
  const { filterValues } = useAppSelector((state) => state.filters);
  const { data: overtimeData, status, error } = useAppSelector((state) => state.overtime);
  const { currentUser } = useAppSelector((state) => state.auth);
  const dispatch = useAppDispatch();

  const [modalState, setModalState] = useState(false);
  const uid = currentUser.id as string;
  const [month, setMonth] = useState(moment());
  const [officeId, setOfficeId] = useState(filterValues["officeId"] ?? "Select an office...");

  const hasAccess = currentUser.fullControlOffices?.length != 0;
  const isCEO = currentUser?.CEO;

  useEffect(() => {
    dispatch(
      fetchOvertimeByOfficeByDate({
        officeId: officeId == "Select an office..." ? currentUser?.office : officeId,
        gt: month.startOf("month").format("YYYY-MM-DD"),
        ls: month.endOf("month").format("YYYY-MM-DD"),
        byField: "overtimeDate",
      })
    );

    const unsubscribe = subscribeToOvertime(dispatch);
    return () => {
      unsubscribe();
    };
  }, [dispatch, officeId, month]);

  if (hasAccess || isCEO) {
    const valueSN =
      overtimeData == undefined
        ? null
        : overtimeData.filter(
            (e) =>
              currentUser?.fullControlOffices?.includes(e?.office) ||
              e?.employeeUID == uid ||
              e?.managerUID == uid ||
              isCEO
          );
    const sortedList = sortBy(valueSN, (item) => item.requestedAt).reverse();

    if (status == "loading")
      return (
        <div>
          <Spin size="small" />
        </div>
      );
    return (
      <div>
        <MonthPicker
          size="default"
          style={{ width: "100%", paddingBottom: "1%" }}
          placeholder="Select month"
          value={month}
          format={"MMMM-YYYY"}
          onChange={(e: any) => {
            setMonth(moment(e));
          }}
        />
        <Select
          filterOption={(inputValue, option) => {
            return (option.props.children as any).toLowerCase().indexOf(inputValue.toLowerCase()) >= 0;
          }}
          size="default"
          placeholder="Search for Office!"
          style={{ width: "100%", paddingBottom: "1%" }}
          showSearch
          value={officeId}
          onChange={(e) => {
            setOfficeId(e);
            dispatch(setFilterValue({ key: "officeId", value: e }));
          }}
        >
          {OFFICES_ID_NAME.map((d) => {
            return <Option value={d.officeId}>{d.officeName}</Option>;
          })}
        </Select>
        <AddNewOvertime modalState={modalState} setModalState={setModalState} />

        {sortedList.map((doc) => {
          const data = doc;
          const fullName = globalUsersData[data.employeeUID ?? ""]?.fullName;
          return (
            <OvertimeSummary key={doc.id} id={doc?.id as string} fullName={fullName as string} status={doc.status} />
          );
        })}
        <Button
          type="primary"
          shape="circle"
          size="large"
          onClick={() => {
            setModalState(true);
          }}
        >
          +
        </Button>
      </div>
    );
  } else {
    const valueSN = overtimeData == undefined ? null : overtimeData;
    const sortedList = sortBy(valueSN, (item) => item.requestedAt).reverse();
    if (status == "loading")
      return (
        <div>
          <Spin size="small" />
        </div>
      );
    return (
      <div>
        <AddNewOvertime modalState={modalState} setModalState={setModalState} />

        {sortedList.map((doc) => {
          const data = doc;
          const fullName = globalUsersData[data.employeeUID ?? ""]?.fullName;
          return (
            <OvertimeSummary key={doc.id} id={doc.id as string} fullName={fullName as string} status={doc.status} />
          );
        })}
        <Button
          type="primary"
          shape="circle"
          size="large"
          onClick={() => {
            setModalState(true);
          }}
        >
          +
        </Button>
      </div>
    );
  }
}
