import { supabase } from "../supabaseClient"; // Ensure you have set up your Supabase client

/**
 * Uploads a file to Supabase Storage
 * @param file The file to be uploaded
 * @param path The path in the bucket where the file will be stored (e.g., "user-images/image.png")
 * @returns The public URL of the uploaded file or an error message
 */
export const uploadFile = async (file: File, path: string) => {
  try {
    // Specify the bucket name and path
    const { error } = await supabase.storage.from("uploads").upload(path, file);

    if (error) throw error;

    // Retrieve and return the public URL for the uploaded file
    // Generate a signed URL for the uploaded file (valid for a specified duration, e.g., 1 hour)
    const { data: signedUrlData } = await supabase.storage.from("uploads").getPublicUrl(path); // URL valid for 1 hour (3600 seconds)

    return signedUrlData.publicUrl;
  } catch (error: any) {
    console.error("Error uploading file:", error.message);
    throw error;
  }
};

export const deleteFile = async (path: string) => {
  try {
    // Specify the bucket name and path for the file to delete
    const { error } = await supabase.storage.from("uploads").remove([path]);

    if (error) throw error;

    console.log(`File at ${path} successfully deleted.`);
    return true;
  } catch (error: any) {
    console.error("Error deleting file:", error.message);
    throw error;
  }
};

export const uploadTrimmedSignature = async (trimmedDataURL: string, path: string) => {
  try {
    // Convert the Data URL to a Blob
    const response = await fetch(trimmedDataURL);
    const blob = await response.blob();

    // Upload the file to Supabase Storage
    const { error: uploadError } = await supabase.storage.from("uploads").upload(path, blob, {
      contentType: "image/png", // Set the MIME type based on your data URL
    });

    if (uploadError) {
      throw new Error(uploadError.message);
    }

    // Get the public URL for the uploaded file
    const { data: publicUrlData } = supabase.storage.from("uploads").getPublicUrl(path);

    const downloadURL = publicUrlData.publicUrl;

    return downloadURL;
    // Show success message
  } catch (error: any) {
    console.error("Error saving signature:", error.message);
  }
};
