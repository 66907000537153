import React, { useState,useEffect } from "react";
import Paper from "@material-ui/core/Paper";
import {  Button, Popconfirm } from "antd";
import AddNewRoomModel from "../Meetings_Rooms/AddNewRoomModel";
import { useTranslation } from "react-i18next";
import DevTable from "../../helpers/devex-table";
import { useAppDispatch, useAppSelector } from "../../hooks/reduxHooks";
import { deleteRoom, fetchAllRooms, subscribeToRoomsList } from "../../features/roomsList/roomsListSlice";

const columnsExport = [
  { name: "roomName", title: "Room Name" },
  { name: "location", title: "Location" },
  { name: "capacity", title: "Attending Capacity" },
  { name: "contains", title: "Contains" },
];

export default function MeetingsRoomTable() {
  const { data, status } = useAppSelector((state) => state.rooms);
  const dispatch = useAppDispatch();

  const { t } = useTranslation();
  const [modalState, setModalState] = useState(false);

  useEffect(() => {
    dispatch(fetchAllRooms());

    const unsubscribe = subscribeToRoomsList(dispatch);
    return () => {
      unsubscribe();
    };
  }, [dispatch]);

  const columns = [
    { name: "id", title: "ID" },
    { name: "roomName", title: "Room Name" },
    { name: "location", title: "Location" },
    { name: "capacity", title: "Attending Capacity" },
    { name: "contains", title: "Contains" },
    {
      name: "actions",
      title: "Actions",
      getCellValue: (row: any) => {
        return (
          <Popconfirm
            title={t("general.areUSure")}
            onConfirm={() => {
              dispatch(deleteRoom(row.id));
            }}
            onCancel={() => console.log("cancel")}
            okText="Yes"
            cancelText="No"
          >
            <Button type="link">Delete</Button>
          </Popconfirm>
        );
      },
    },
  ];

  return status == "loading" ? (
    <>Loading!</>
  ) : (
    <div>
      <AddNewRoomModel modalState={modalState} setModalState={setModalState} />
      <Button style={{ margin: "1%" }} type={"primary"} onClick={() => setModalState(true)}>
        {t("general.addNewRoom")}
      </Button>
      <Paper>
        <DevTable
          data={data}
          columns={columns}
          defaultHiddenColumnNames={[]}
          tableName="Rooms"
          selected={false}
          typicalTable={false}
          columnsExport={columnsExport}
        />
      </Paper>
    </div>
  );
}
