import React, { useState, useEffect } from "react";
import Paper from "@material-ui/core/Paper";
import { Spin, Button, Popconfirm, message } from "antd";
import moment from "moment";
import { useRouteMatch } from "react-router-dom";
import { delay, users } from "../../db/interfaces";
import DelayDisableDialog from "./delayDisableDialog";
import DevTable from "../../helpers/devex-table";
import { useTranslation } from "react-i18next";
import { useAppDispatch, useAppSelector } from "../../hooks/reduxHooks";
import { deleteDelay, editDelay, fetchDelaysByUserId, subscribeToDelays } from "../../features/delays/delaysSlice";
import { globalUsersData } from "../../global/usersList";

export default function userRecord() {
  const userId = useRouteMatch<{ id: string }>().params.id;
  const { t } = useTranslation();

  const { data: delaysData, status, error } = useAppSelector((store) => store.delays);
  const { currentUser } = useAppSelector((state) => state.auth);
  const dispatch = useAppDispatch();

  const [modalState, setModalState] = useState(false);
  const [delay, setDelay] = useState({} as delay);

  const [defaultHiddenColumnNames] = useState([]);

  useEffect(() => {
    dispatch(fetchDelaysByUserId(userId));

    const unsubscribe = subscribeToDelays(dispatch);
    return () => {
      unsubscribe();
    };
  }, [dispatch]);

  if (status == "loading") return <Spin />;

  const userData = globalUsersData[userId ?? ""] as users;
  const fullName = userData?.firstName + " " + userData?.lastName;
  const data = delaysData.map((d, index) => {
    return {
      ...d,
      id: d.id,
      index: index + 1,
    };
  });

  const columnsExport = [
    { name: "index", title: "NO" },
    { name: "dayDate", title: "Date", getCellValue: (r: any) => moment(r.dayDate).format("DD.MM.YYYY") },
    { name: "min", title: "Minutes" },
    { name: "notes", title: "Notes" },
    { name: "month", title: "Month" },
  ];
  const columns = [
    { name: "index", title: "NO" },
    { name: "dayDate", title: "Date", getCellValue: (r: any) => moment(r.dayDate).format("DD.MM.YYYY") },
    { name: "min", title: "Minutes" },
    { name: "notes", title: "Notes" },
    { name: "month", title: "Month" },

    {
      name: "action",
      title: "Disable/Enable",
      getCellValue: (r: delay) => {
        return currentUser?.fullControlOffices?.includes(userData.office) == true ? (
          <Button
            type="primary"
            onClick={() => {
              if (r.status == "disabled") {
                dispatch(
                  editDelay({
                    id: r.id ?? "",
                    status: "enabled",
                  } as any)
                );
              } else {
                setDelay(r);
                setModalState(true);
              }
            }}
          >
            {r.status == "disabled" ? "Enable" : "Disable"}
          </Button>
        ) : null;
      },
    },
    {
      name: "d",
      title: "Disable/Enable",
      getCellValue: (r: delay) => {
        return currentUser?.fullControlOffices?.includes(userData.office) == true ? (
          <Popconfirm
            title={t("general.areUSure")}
            onConfirm={() => {
              dispatch(deleteDelay(r.id ?? "")).unwrap();
              message.success("Deleted Successfully!");
            }}
            onCancel={() => console.log("cancel")}
            okText="Yes"
            cancelText="No"
          >
            <Button type="danger">Delete</Button>
          </Popconfirm>
        ) : null;
      },
    },
  ];
  return error ? (
    <p>{error}</p>
  ) : (
    <div>
      {modalState ? <DelayDisableDialog modalState={modalState} setModalState={setModalState} delay={delay} /> : null}
      <div
        style={{
          padding: "1%",
          background: "#fff",
          marginBottom: "1%",
          border: "thin",
          borderRadius: "10px",
          boxShadow: "2px 2px 2px 0px #c2c2c2",
        }}
      >
        <h2>{fullName}</h2>
      </div>
      <Paper>
        <DevTable
          data={data}
          columns={columns}
          defaultHiddenColumnNames={defaultHiddenColumnNames}
          tableName="My_Delays"
          selected={false}
          typicalTable={false}
          columnsExport={columnsExport}
        />
      </Paper>
    </div>
  );
}
