import { createSlice, createAsyncThunk, PayloadAction } from "@reduxjs/toolkit";
import { supabase } from "../../supabaseClient"; // Adjust the import path as needed
import { workPermit } from "../../db/interfaces"; // Adjust the import path as needed

interface OrderSliceState {
  data: workPermit[];
  order: workPermit;
  status: "loading" | "succeeded" | "failed" | "idle";
  error: string | null;
}

const initialState: OrderSliceState = {
  data: [],
  order: {} as workPermit,
  status: "idle",
  error: null,
};

// Fetch all orders
export const fetchAllOrders = createAsyncThunk<workPermit[], void, { rejectValue: string }>(
  "orders/fetchAllOrders",
  async (_, thunkApi) => {
    const { data, error } = await supabase.from("orders").select("*");
    if (error) {
      return thunkApi.rejectWithValue(error.message);
    }
    return data as workPermit[];
  }
);

// Fetch orders by customer
export const fetchOrdersByType = createAsyncThunk<workPermit[], string, { rejectValue: string }>(
  "orders/fetchOrdersByType",
  async (orderType, thunkApi) => {
    const { data, error } = await supabase.from("orders").select("*").eq("orderType", orderType);
    if (error) {
      return thunkApi.rejectWithValue(error.message);
    }
    return data as workPermit[];
  }
);

// Fetch order by id
export const fetchOrderById = createAsyncThunk<workPermit, string, { rejectValue: string }>(
  "orders/fetchOrderById",
  async (orderId, thunkApi) => {
    const { data, error } = await supabase.from("orders").select("*").eq("id", orderId).single();
    if (error) {
      return thunkApi.rejectWithValue(error.message);
    }
    return data as workPermit;
  }
);

// Fetch orders by date
export const fetchOrdersByDate = createAsyncThunk<
  workPermit[],
  { gt: string; ls: string; byField: string },
  { rejectValue: string }
>("orders/fetchOrdersByDate", async ({ gt, ls, byField }, thunkApi) => {
  const { data, error } = await supabase.from("orders").select("*").gt(byField, gt).lt(byField, ls);
  if (error) {
    return thunkApi.rejectWithValue(error.message);
  }
  return data as workPermit[];
});

// Add a new order
export const addOrder = createAsyncThunk<workPermit, workPermit, { rejectValue: string }>(
  "orders/addOrder",
  async (newOrder, thunkApi) => {
    const { data: insertData, error: insertError } = await supabase.from("orders").insert(newOrder).select().single();
    if (insertError) {
      return thunkApi.rejectWithValue(insertError.message);
    }

    return insertData as workPermit;
  }
);

// Edit an order
export const editOrder = createAsyncThunk<workPermit, workPermit, { rejectValue: string }>(
  "orders/editOrder",
  async (updatedOrder, thunkApi) => {
    const { data: updateData, error: updateError } = await supabase
      .from("orders")
      .update(updatedOrder)
      .eq("id", updatedOrder.id)
      .select()
      .single();

    if (updateError) {
      return thunkApi.rejectWithValue(updateError.message);
    }

    return updateData as workPermit;
  }
);

// Delete an order
export const deleteOrder = createAsyncThunk<string, string, { rejectValue: string }>(
  "orders/deleteOrder",
  async (id, thunkApi) => {
    const { error } = await supabase.from("orders").delete().eq("id", id);

    if (error) {
      return thunkApi.rejectWithValue(error.message);
    }

    return id;
  }
);

// Real-Time Actions
export const addOrderRealTime = (newOrder: workPermit): PayloadAction<workPermit> => ({
  type: "orders/addOrderRealTime",
  payload: newOrder,
});

export const editOrderRealTime = (updatedOrder: workPermit): PayloadAction<workPermit> => ({
  type: "orders/editOrderRealTime",
  payload: updatedOrder,
});

export const deleteOrderRealTime = (id: string): PayloadAction<string> => ({
  type: "orders/deleteOrderRealTime",
  payload: id,
});

// Slice definition
const orderSlice = createSlice({
  name: "orders",
  initialState,
  reducers: {
    addOrderRealTime(state, action: PayloadAction<workPermit>) {
      const exists = state.data.some((order) => order.id === action.payload.id);
      if (!exists) {
        state.data = [...state.data, action.payload];
      }
      state.error = null;
    },
    editOrderRealTime(state, action: PayloadAction<workPermit>) {
      const index = state.data.findIndex((order) => order.id === action.payload.id);
      if (index !== -1) {
        const isDifferent = JSON.stringify(state.data[index]) !== JSON.stringify(action.payload);
        if (isDifferent) {
          state.data[index] = action.payload;
        }
      }
      state.error = null;

      if (state.order.id == action.payload.id) {
        state.order = action.payload;
      }
    },
    deleteOrderRealTime(state, action: PayloadAction<string>) {
      const exists = state.data.some((order) => order.id === action.payload);
      if (exists) {
        state.data = state.data.filter((order) => order.id !== action.payload);
      }
      state.error = null;
    },
  },
  extraReducers: (builder) => {
    builder
      // Handle fetchAllOrders
      .addCase(fetchAllOrders.pending, (state) => {
        state.status = "loading";
      })
      .addCase(fetchAllOrders.fulfilled, (state, action: PayloadAction<workPermit[]>) => {
        state.status = "succeeded";
        state.data = action.payload;
        state.error = null;
      })
      .addCase(fetchAllOrders.rejected, (state, action: PayloadAction<string | undefined>) => {
        state.status = "failed";
        state.error = action.payload || "Failed to fetch orders.";
      })

      // Handle fetchOrderById
      .addCase(fetchOrderById.pending, (state) => {
        state.status = "loading";
      })
      .addCase(fetchOrderById.fulfilled, (state, action: PayloadAction<workPermit>) => {
        state.status = "succeeded";
        state.order = action.payload;
        state.error = null;
      })
      .addCase(fetchOrderById.rejected, (state, action: PayloadAction<string | undefined>) => {
        state.status = "failed";
        state.error = action.payload || "Failed to fetch order.";
      })

      // Handle fetchOrdersByType
      .addCase(fetchOrdersByType.pending, (state) => {
        state.status = "loading";
      })
      .addCase(fetchOrdersByType.fulfilled, (state, action: PayloadAction<workPermit[]>) => {
        state.status = "succeeded";
        state.data = action.payload;
        state.error = null;
      })
      .addCase(fetchOrdersByType.rejected, (state, action: PayloadAction<string | undefined>) => {
        state.status = "failed";
        state.error = action.payload || "Failed to fetch orders by customer.";
      })

      // Handle fetchOrdersByDate
      .addCase(fetchOrdersByDate.pending, (state) => {
        state.status = "loading";
      })
      .addCase(fetchOrdersByDate.fulfilled, (state, action: PayloadAction<workPermit[]>) => {
        state.status = "succeeded";
        state.data = action.payload;
        state.error = null;
      })
      .addCase(fetchOrdersByDate.rejected, (state, action: PayloadAction<string | undefined>) => {
        state.status = "failed";
        state.error = action.payload || "Failed to fetch orders by date.";
      })

      // Handle addOrder
      .addCase(addOrder.pending, (state) => {})
      .addCase(addOrder.fulfilled, (state, action: PayloadAction<workPermit>) => {
        state.error = null;
      })
      .addCase(addOrder.rejected, (state, action: PayloadAction<string | undefined>) => {
        state.error = action.payload || "Failed to add order.";
      })

      // Handle editOrder
      .addCase(editOrder.pending, (state) => {})
      .addCase(editOrder.fulfilled, (state, action: PayloadAction<workPermit>) => {
        state.error = null;
      })
      .addCase(editOrder.rejected, (state, action: PayloadAction<string | undefined>) => {
        state.status = "failed";
        state.error = action.payload || "Failed to edit order.";
      })

      // Handle deleteOrder
      .addCase(deleteOrder.pending, (state) => {})
      .addCase(deleteOrder.fulfilled, (state, action: PayloadAction<string>) => {
        state.error = null;
      })
      .addCase(deleteOrder.rejected, (state, action: PayloadAction<string | undefined>) => {
        state.error = action.payload || "Failed to delete order.";
      });
  },
});

// Real-time subscription function
export const subscribeToOrders = (dispatch: any) => {
  const subscription = supabase
    .channel("public:orders")
    .on("postgres_changes", { event: "*", schema: "public", table: "orders" }, (payload) => {
      const newRecord = payload.new as workPermit;
      const oldRecord = payload.old as workPermit;

      switch (payload.eventType) {
        case "INSERT":
          dispatch(addOrderRealTime(newRecord));
          break;
        case "UPDATE":
          dispatch(editOrderRealTime(newRecord));
          break;
        case "DELETE":
          dispatch(deleteOrderRealTime(oldRecord.id as string));
          break;
        default:
          break;
      }
    })
    .subscribe();

  return () => {
    subscription.unsubscribe();
  };
};

export default orderSlice.reducer;
