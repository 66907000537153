import React, { useState, useEffect } from "react";
import { useHistory } from "react-router-dom";
import { Button, DatePicker, Icon, Drawer, Form, InputNumber, message, Select, Spin, Input, Descriptions } from "antd";
import moment from "moment";
import { useTranslation } from "react-i18next";
const { Option } = Select;
import { useFormik } from "formik";
import * as Yup from "yup";
import { workPermit } from "../../db/interfaces";
import { useAppDispatch, useAppSelector } from "../../hooks/reduxHooks";
import { globalUsersData } from "../../global/usersList";
import { addNotification } from "../../features/notifications/notificationsSlice";
import { editOrder } from "../../features/orders/ordersSlice";
import { uploadFile } from "../../db/supabase-storage";

interface Props {
  modalState: boolean;
  setModalState: React.Dispatch<React.SetStateAction<boolean>>;
  orderId: string;
  order: workPermit;
}
const projectSchema = Yup.object().shape({
  TC: Yup.number().required("*"),
  femaleChildrenNumber: Yup.number().min(0).max(20).required("*"),
  maleChildrenNumber: Yup.number().min(0).max(20).required("*"),
});


function EditOrder(props: Props) {
  const { currentUser } = useAppSelector((state) => state.auth);
  const dispatch = useAppDispatch();
  const { t } = useTranslation();

  const [IDURL, setIDURL] = useState("");
  const [permitCardURL, setPermitCardURL] = useState("");
  const [passportPhoto, setPassportPhoto] = useState("");
  const [diplomaURL, setDiplomaURL] = useState("");
  const [personalPhoto, setPersonalPhoto] = useState("");

  //-----upload status ends
  const { modalState, setModalState, order } = props;
  const [isCreate, setIsCreate] = useState(true);
  const [loading, setLoading] = useState(false);
  const [orderType, setOrderType] = useState("");
  const [firstTime, setFirstTime] = useState("");
  const history = useHistory();


  const uid = currentUser.id as string;
  const usersAdminSN = Object.values(globalUsersData)
    .filter((user) => user.adminLaw == true)
    .map((user) => user.id);

  const formik = useFormik({
    initialValues: {
      jobStartDate: moment(),
      permitExpiryDate: moment(),
      TC: "",
      firstName: "",
      lastName: "",
      fatherName: "",
      motherName: "",
      gender: "",
      birthPlace: "",
      birthDate: moment(),
      marriedStatus: "",
      nationality: "",
      femaleChildrenNumber: 0,
      maleChildrenNumber: 0,
      wifeNationality: "",
      wifeFirstName: "",
      wifeLastName: "",
      wifeTC: "",
      phone: "",
      email: "",
      residenceAddress: "",
      universityNameAddress: "",
      degree: "",
      degreeField: "",
      degreeFaculty: "",
      degreeSpecialization: "",
      passportNO: "",
      passportExpiryDate: moment(),
      passportAuth: "",
      position: "",
      salary: 0,
      cityInSyria: "",
      durationInTurkey: "",
      language: "",
      startWorkDate: moment(),
      endWorkDate: moment(),
      diplomaURL: "",
      IDURL: "",
      personalPhoto: "",
      passportPhotoURL: "",
      permitCardPhotoURL: "",
      trackingNumber: "",
    },
    validationSchema: projectSchema,
    onSubmit: async (values) => {
      try {
        await dispatch(
          editOrder({
            id: order.id,
            IDURL: IDURL,
            personalPhoto: personalPhoto,
            passportPhotoURL: passportPhoto,
            permitCardPhotoURL: permitCardURL,
            diplomaURL: diplomaURL,
            requestedAt: moment() as any,
            orderType: orderType,
            imgURL: "",
            TC: values.TC,
            firstTime: firstTime,
            permitExpiryDate: values.permitExpiryDate as any,
            jobStartDate: values.jobStartDate as any,
            firstName: values.firstName,
            lastName: values.lastName,
            fatherName: values.fatherName,
            motherName: values.motherName,
            gender: values.gender,
            birthPlace: values.birthPlace,
            birthDate: values.birthDate as any,
            marriedStatus: values.marriedStatus,
            nationality: values.nationality,
            femaleChildrenNumber: values.femaleChildrenNumber,
            maleChildrenNumber: values.maleChildrenNumber,
            wifeNationality: values.wifeNationality,
            wifeFirstName: values.wifeFirstName,
            wifeLastName: values.wifeLastName,
            wifeTC: values.wifeTC,
            phone: values.phone,
            email: values.email,
            residenceAddress: values.residenceAddress,
            universityNameAddress: values.universityNameAddress,
            degree: values.degree,
            degreeField: values.degreeField,
            degreeFaculty: values.degreeFaculty,
            degreeSpecialization: values.degreeSpecialization,
            passportNO: values.passportNO,
            passportExpiryDate: values.passportExpiryDate as any,
            passportAuth: values.passportAuth,
            position: values.position,
            salary: values.salary,
            cityInSyria: values.cityInSyria,
            durationInTurkey: values.durationInTurkey,
            language: values.language,
            trackingNumber: values.trackingNumber,
          } as any)
        );

        await usersAdminSN?.map((uid: any) => {
          const adminID = uid;
          dispatch(
            addNotification({
              notifyTo: adminID,
              notifyFrom: currentUser?.id as string,
              orderId: order.id as string,
              read: false,
              kind: "newRequest",
              content: "New order has been requested!",
              postedAt: moment() as any,
            })
          );
        });

        if (isCreate == true) {
          message.success("Your Changes has been submitted successfully");
          setModalState(false);
          setLoading(false);
          history.push(`/order/${props.orderId}`);
        } else {
          console.log("Document successfully written!");
          message.success("Your request has been changed successfully");
          history.push(`/`);
        }
      } catch (e) {
        console.log(e);
      }
    },
  });
  const [loaded, setLoaded] = useState(true);
  useEffect(() => {
    if (order && loaded) {
      formik.setValues({
        IDURL: order?.IDURL,
        personalPhoto: order?.personalPhoto,
        passportPhotoURL: order?.passportPhotoURL,
        permitCardPhotoURL: order?.permitCardPhotoURL,
        diplomaURL: order?.diplomaURL,
        TC: order?.TC,
        permitExpiryDate: moment(order?.permitExpiryDate),
        jobStartDate: moment(order?.jobStartDate),
        firstName: order?.firstName,
        lastName: order?.lastName,
        fatherName: order?.fatherName,
        motherName: order?.motherName,
        gender: order?.gender,
        birthPlace: order?.birthPlace,
        birthDate: moment(order?.birthDate),
        marriedStatus: order?.marriedStatus,
        nationality: order?.nationality,
        femaleChildrenNumber: order?.femaleChildrenNumber,
        maleChildrenNumber: order?.maleChildrenNumber,
        wifeNationality: order?.wifeNationality,
        wifeFirstName: order?.wifeFirstName,
        wifeLastName: order?.wifeLastName,
        wifeTC: order?.wifeTC,
        phone: order?.phone,
        email: order?.email,
        residenceAddress: order?.residenceAddress,
        universityNameAddress: order?.universityNameAddress,
        degree: order?.degree,
        degreeField: order?.degreeField,
        degreeFaculty: order?.degreeFaculty,
        degreeSpecialization: order?.degreeSpecialization,
        passportNO: order?.passportNO,
        passportExpiryDate: moment(order?.passportExpiryDate),
        passportAuth: order?.passportAuth,
        position: order?.position,
        salary: order?.salary,
        cityInSyria: order?.cityInSyria,
        durationInTurkey: order?.durationInTurkey,
        language: order?.language,
        trackingNumber: order?.trackingNumber,
      } as any);
      setLoaded(false);
      setFirstTime(order?.firstTime);
      setOrderType(order?.orderType);

      setIDURL(order.IDURL);
      setPermitCardURL(order.permitCardPhotoURL);
      setPassportPhoto(order.passportPhotoURL);
      setDiplomaURL(order.diplomaURL);
      setPersonalPhoto(order.personalPhoto);
    }
  }, [order, formik, loaded]);

  const enterLoading = () => {
    setLoading(true);
  };
  const dateFormat = "YYYY/MM/DD";

  const handleFileChange = async (
    e: React.ChangeEvent<HTMLInputElement>,
    setURLValue: React.Dispatch<React.SetStateAction<string>>
  ) => {
    if (e.target.files) {
      const file: File = e.target.files[0];
      if (!file) return;
      const filePath = `work-permit-documents/${uid}/${file.name}`;
      try {
        const url = await uploadFile(file, filePath);

        if (url) {
          setURLValue(url as any);
          message.success("File uploaded successfully");
        }
      } catch (error: any) {
        message.error(error.message);
      }
    }
  };

  return (
    <Drawer title="Add New Request" width={1000} visible={modalState} onClose={() => setModalState(false)}>
      <Form onSubmit={formik.handleSubmit}>
        <div>
          <div>
            <Descriptions
              size={"small"}
              layout="horizontal"
              bordered
              column={{ xxl: 6, xl: 4, lg: 3, md: 3, sm: 2, xs: 1 }}
            >
              <Descriptions.Item label={t("general.selectYourRC")} span={1}>
                <Select value={orderType} onChange={(e) => setOrderType(e)}>
                  <Option value={"AFAD Kart"}>AFAD Kart</Option>
                  <Option value={"Ikamet Kart"}>Ikamet Kart</Option>
                  <Option value={"Turk Vatandasi"}>Turk Vatandasi</Option>
                </Select>
              </Descriptions.Item>
            </Descriptions>

            {orderType != "" ? (
              <div>
                <Descriptions
                  size={"small"}
                  layout="horizontal"
                  bordered
                  column={{ xxl: 6, xl: 4, lg: 3, md: 3, sm: 2, xs: 1 }}
                >
                  <Descriptions.Item label={t("general.firstTime")} span={2}>
                    <Select
                      value={firstTime}
                      defaultValue={firstTime}
                      onChange={(e) => {
                        setFirstTime(e);
                      }}
                    >
                      <Option value={"Yes"}>Yes</Option>
                      <Option value={"No"}>No</Option>
                    </Select>
                  </Descriptions.Item>
                  {order.status == "Approved" ? (
                    <Descriptions.Item label={t("general.trackingNumber")} span={2}>
                      <Input
                        value={formik.values.trackingNumber}
                        onChange={(e) => formik.setFieldValue("trackingNumber", e.target.value)}
                      />
                    </Descriptions.Item>
                  ) : null}
                  {firstTime == "No" ? (
                    <Descriptions.Item label={t("general.permitExpiryDate")} span={2}>
                      <DatePicker
                        value={formik.values.permitExpiryDate} // defaultValue={formik.values.firstTime}
                        format={dateFormat}
                        onChange={(e) => formik.setFieldValue("permitExpiryDate", e)}
                      />
                    </Descriptions.Item>
                  ) : (
                    <Descriptions.Item label={t("general.workStartOn")} span={2}>
                      <DatePicker
                        value={formik.values.jobStartDate}
                        format={dateFormat}
                        onChange={(e) => formik.setFieldValue("jobStartDate", e)}
                      />
                    </Descriptions.Item>
                  )}
                  {/* //------------------- */}
                  <Descriptions.Item label={t("general.TC")} span={2}>
                    <Input value={formik.values.TC} onChange={(e) => formik.setFieldValue("TC", e.target.value)} />
                  </Descriptions.Item>
                  <Descriptions.Item label={t("general.firstName")} span={2}>
                    <Input
                      value={formik.values.firstName}
                      onChange={(e) => formik.setFieldValue("firstName", e.target.value)}
                    />
                  </Descriptions.Item>
                  <Descriptions.Item label={t("general.lastName")} span={2}>
                    <Input
                      value={formik.values.lastName}
                      onChange={(e) => formik.setFieldValue("lastName", e.target.value)}
                    />
                  </Descriptions.Item>
                  <Descriptions.Item label={t("general.fatherName")} span={2}>
                    <Input
                      value={formik.values.fatherName}
                      onChange={(e) => formik.setFieldValue("fatherName", e.target.value)}
                    />
                  </Descriptions.Item>
                  <Descriptions.Item label={t("general.motherName")} span={2}>
                    <Input
                      value={formik.values.motherName}
                      onChange={(e) => formik.setFieldValue("motherName", e.target.value)}
                    />
                  </Descriptions.Item>
                  <Descriptions.Item label={t("general.gender")} span={2}>
                    <Select value={formik.values.gender} onChange={(e) => formik.setFieldValue("gender", e)}>
                      <Option value={"male"}>Male</Option>
                      <Option value={"female"}>Female</Option>
                    </Select>
                  </Descriptions.Item>
                  <Descriptions.Item label={t("general.birthPlace")} span={2}>
                    <Input
                      value={formik.values.birthPlace}
                      onChange={(e) => formik.setFieldValue("birthPlace", e.target.value)}
                    />
                  </Descriptions.Item>
                  <Descriptions.Item label={t("general.birthDate")} span={2}>
                    <DatePicker
                      value={formik.values.birthDate}
                      format={dateFormat}
                      onChange={(e) => formik.setFieldValue("birthDate", e)}
                    />
                  </Descriptions.Item>
                  <Descriptions.Item label={t("general.marriedStatus")} span={2}>
                    <Select
                      value={formik.values.marriedStatus}
                      onChange={(e) => formik.setFieldValue("marriedStatus", e)}
                    >
                      <Option value={"Evli"}>Married</Option>
                      <Option value={"Bekar"}>Single</Option>
                    </Select>
                  </Descriptions.Item>
                  {orderType != "Turk Vatandasi" ? (
                    <Descriptions.Item label={t("general.nationality")} span={2}>
                      <Input
                        value={formik.values.nationality}
                        onChange={(e) => formik.setFieldValue("nationality", e.target.value)}
                      />
                    </Descriptions.Item>
                  ) : null}
                  {/* //------------------- */}
                  {orderType != "AFAD Kart" ? (
                    <Descriptions.Item label={t("general.femaleChildrenNumber")} span={2}>
                      <InputNumber
                        value={formik.values.femaleChildrenNumber}
                        onChange={(e) => formik.setFieldValue("femaleChildrenNumber", e)}
                      />
                    </Descriptions.Item>
                  ) : null}
                  {orderType != "AFAD Kart" ? (
                    <Descriptions.Item label={t("general.maleChildrenNumber")} span={2}>
                      <InputNumber
                        value={formik.values.maleChildrenNumber}
                        onChange={(e) => formik.setFieldValue("maleChildrenNumber", e)}
                      />
                    </Descriptions.Item>
                  ) : null}
                  <Descriptions.Item label={t("general.wifeNationality")} span={2}>
                    <Input
                      value={formik.values.wifeNationality}
                      onChange={(e) => formik.setFieldValue("wifeNationality", e.target.value)}
                    />
                  </Descriptions.Item>
                  <Descriptions.Item label={t("general.wifeFirstName")} span={2}>
                    <Input
                      value={formik.values.wifeFirstName}
                      onChange={(e) => formik.setFieldValue("wifeFirstName", e.target.value)}
                    />
                  </Descriptions.Item>
                  <Descriptions.Item label={t("general.wifeLastName")} span={2}>
                    <Input
                      value={formik.values.wifeLastName}
                      onChange={(e) => formik.setFieldValue("wifeLastName", e.target.value)}
                    />
                  </Descriptions.Item>
                  <Descriptions.Item label={t("general.wifeTC")} span={2}>
                    <Input
                      value={formik.values.wifeTC}
                      onChange={(e) => formik.setFieldValue("wifeTC", e.target.value)}
                    />
                  </Descriptions.Item>
                  {/* //------------------- */}
                  <Descriptions.Item label={t("general.phone")} span={2}>
                    <Input
                      value={formik.values.phone}
                      onChange={(e) => formik.setFieldValue("phone", e.target.value)}
                    />
                  </Descriptions.Item>
                  <Descriptions.Item label={t("general.email")} span={2}>
                    <Input
                      value={formik.values.email}
                      onChange={(e) => formik.setFieldValue("email", e.target.value)}
                    />
                  </Descriptions.Item>
                  {orderType != "AFAD Kart" ? (
                    <Descriptions.Item label={t("general.universityNameAddress")} span={2}>
                      <Input
                        value={formik.values.universityNameAddress}
                        onChange={(e) => formik.setFieldValue("universityNameAddress", e.target.value)}
                      />
                    </Descriptions.Item>
                  ) : null}{" "}
                  {orderType != "AFAD Kart" ? (
                    <Descriptions.Item label={t("general.degree")} span={2}>
                      <Input
                        value={formik.values.degree}
                        onChange={(e) => formik.setFieldValue("degree", e.target.value)}
                      />
                    </Descriptions.Item>
                  ) : null}
                  {orderType != "AFAD Kart" ? (
                    <Descriptions.Item label={t("general.degreeField")} span={2}>
                      <Input
                        value={formik.values.degreeField}
                        onChange={(e) => formik.setFieldValue("degreeField", e.target.value)}
                      />
                    </Descriptions.Item>
                  ) : null}
                  {orderType != "AFAD Kart" ? (
                    <Descriptions.Item label={t("general.degreeSpecialization")} span={2}>
                      <Input
                        value={formik.values.degreeSpecialization}
                        onChange={(e) => formik.setFieldValue("degreeSpecialization", e.target.value)}
                      />
                    </Descriptions.Item>
                  ) : null}
                  {/* //------------------- */}
                  {orderType == "Ikamet Kart" ? (
                    <Descriptions.Item label={t("general.passportNO")} span={2}>
                      <Input
                        value={formik.values.passportNO}
                        onChange={(e) => formik.setFieldValue("passportNO", e.target.value)}
                      />
                    </Descriptions.Item>
                  ) : null}
                  {orderType == "Ikamet Kart" ? (
                    <Descriptions.Item label={t("general.passportExpiryDate")} span={2}>
                      <DatePicker
                        value={formik.values.passportExpiryDate}
                        onChange={(e) => formik.setFieldValue("passportExpiryDate", e)}
                      />
                    </Descriptions.Item>
                  ) : null}
                  {orderType == "Ikamet Kart" ? (
                    <Descriptions.Item label={t("general.passportAuth")} span={2}>
                      <Input
                        value={formik.values.passportAuth}
                        onChange={(e) => formik.setFieldValue("passportAuth", e.target.value)}
                      />
                    </Descriptions.Item>
                  ) : null}
                  <Descriptions.Item label={t("general.position")} span={2}>
                    <Input
                      value={formik.values.position}
                      onChange={(e) => formik.setFieldValue("position", e.target.value)}
                    />
                  </Descriptions.Item>
                  <Descriptions.Item label={t("general.salary")} span={2}>
                    <InputNumber value={formik.values.salary} onChange={(e) => formik.setFieldValue("salary", e)} />
                  </Descriptions.Item>
                  {orderType == "AFAD Kart" || orderType == "Ikamet Kart" ? (
                    <Descriptions.Item label={t("general.cityInSyria")} span={2}>
                      <Input
                        value={formik.values.cityInSyria}
                        onChange={(e) => formik.setFieldValue("cityInSyria", e.target.value)}
                      />
                    </Descriptions.Item>
                  ) : null}
                  {orderType == "AFAD Kart" ? (
                    <Descriptions.Item label={t("general.degree")} span={2}>
                      <Input
                        value={formik.values.degree}
                        onChange={(e) => formik.setFieldValue("degree", e.target.value)}
                      />
                    </Descriptions.Item>
                  ) : null}
                  {orderType == "AFAD Kart" ? (
                    <Descriptions.Item label={t("general.universityNameAddress")} span={2}>
                      <Input
                        value={formik.values.universityNameAddress}
                        onChange={(e) => formik.setFieldValue("universityNameAddress", e.target.value)}
                      />
                    </Descriptions.Item>
                  ) : null}
                  {orderType == "AFAD Kart" ? (
                    <Descriptions.Item label={t("general.degreeFaculty")} span={2}>
                      <Input
                        value={formik.values.degreeFaculty}
                        onChange={(e) => formik.setFieldValue("degreeFaculty", e.target.value)}
                      />
                    </Descriptions.Item>
                  ) : null}
                  {orderType == "AFAD Kart" ? (
                    <Descriptions.Item label={t("general.degreeField")} span={2}>
                      <Input
                        value={formik.values.degreeField}
                        onChange={(e) => formik.setFieldValue("degreeField", e.target.value)}
                      />
                    </Descriptions.Item>
                  ) : null}
                  {orderType == "AFAD Kart" ? (
                    <Descriptions.Item label={t("general.durationInTurkey")} span={2}>
                      <Input
                        value={formik.values.durationInTurkey}
                        onChange={(e) => formik.setFieldValue("durationInTurkey", e.target.value)}
                      />
                    </Descriptions.Item>
                  ) : null}
                  {orderType == "AFAD Kart" ? (
                    <Descriptions.Item label={t("general.femaleChildrenNumber")} span={2}>
                      <InputNumber
                        value={formik.values.femaleChildrenNumber}
                        onChange={(e) => formik.setFieldValue("femaleChildrenNumber", e)}
                      />
                    </Descriptions.Item>
                  ) : null}
                  {orderType == "AFAD Kart" ? (
                    <Descriptions.Item label={t("general.maleChildrenNumber")} span={2}>
                      <InputNumber
                        value={formik.values.maleChildrenNumber}
                        onChange={(e) => formik.setFieldValue("maleChildrenNumber", e)}
                      />
                    </Descriptions.Item>
                  ) : null}
                  {orderType == "AFAD Kart" ? (
                    <Descriptions.Item label={t("general.language")} span={2}>
                      <Input
                        value={formik.values.language}
                        onChange={(e) => formik.setFieldValue("language", e.target.value)}
                      />
                    </Descriptions.Item>
                  ) : null}
                </Descriptions>
                <Descriptions
                  size={"small"}
                  layout="horizontal"
                  bordered
                  column={{ xxl: 6, xl: 4, lg: 3, md: 3, sm: 2, xs: 1 }}
                >
                  <Descriptions.Item label={t("general.address")} span={4}>
                    <Input
                      value={formik.values.residenceAddress}
                      onChange={(e) => formik.setFieldValue("residenceAddress", e.target.value)}
                    />
                  </Descriptions.Item>
                </Descriptions>
              </div>
            ) : null}
            <span>{t("general.attachments")}:</span>
            <Descriptions
              size={"small"}
              layout="horizontal"
              bordered
              column={{ xxl: 6, xl: 4, lg: 3, md: 3, sm: 2, xs: 1 }}
            >
              {orderType == "Ikamet Kart" ? (
                <Descriptions.Item label={t("attachments.permitCardPhoto")} span={3}>
                  <div>
                    <input type="file" onChange={(e) => handleFileChange(e, setPermitCardURL)} />
                    {permitCardURL && (
                      <div>
                        <p>File uploaded successfully!</p>
                        <a href={permitCardURL} target="_blank" rel="noopener noreferrer">
                          View File
                        </a>
                      </div>
                    )}
                  </div>
                </Descriptions.Item>
              ) : null}
              {orderType == "Ikamet Kart" ? (
                <Descriptions.Item label={t("attachments.passportPhoto")} span={3}>
                  <div>
                    <input type="file" onChange={(e) => handleFileChange(e, setPassportPhoto)} />
                    {passportPhoto && (
                      <div>
                        <p>File uploaded successfully!</p>
                        <a href={passportPhoto} target="_blank" rel="noopener noreferrer">
                          View File
                        </a>
                      </div>
                    )}
                  </div>
                </Descriptions.Item>
              ) : null}
              {orderType == "Ikamet Kart" ? (
                <Descriptions.Item label={t("attachments.diploma")} span={3}>
                  <div>
                    <input type="file" onChange={(e) => handleFileChange(e, setDiplomaURL)} />
                    {diplomaURL && (
                      <div>
                        <p>File uploaded successfully!</p>
                        <a href={diplomaURL} target="_blank" rel="noopener noreferrer">
                          View File
                        </a>
                      </div>
                    )}
                  </div>
                </Descriptions.Item>
              ) : null}
              {orderType != "Ikamet Kart" ? (
                <Descriptions.Item label={t("attachments.ID")} span={3}>
                  <div>
                    <input type="file" onChange={(e) => handleFileChange(e, setIDURL)} />
                    {IDURL && (
                      <div>
                        <p>File uploaded successfully!</p>
                        <a href={IDURL} target="_blank" rel="noopener noreferrer">
                          View File
                        </a>
                      </div>
                    )}
                  </div>
                </Descriptions.Item>
              ) : null}
              <Descriptions.Item label={t("attachments.personalPhoto")} span={3}>
                <div>
                  <input type="file" onChange={(e) => handleFileChange(e, setPersonalPhoto)} />
                  {personalPhoto && (
                    <div>
                      <p>File uploaded successfully!</p>
                      <a href={personalPhoto} target="_blank" rel="noopener noreferrer">
                        View File
                      </a>
                    </div>
                  )}
                </div>
              </Descriptions.Item>
            </Descriptions>
          </div>
        </div>
        <div>
          <Button
            style={{ margin: "1%" }}
            className="btn blue lighten-1 z-depth-0"
            onClick={() => {
              enterLoading();
            }}
            loading={loading}
            type="primary"
            htmlType="submit"
            disabled={!formik.isValid}
          >
            {t("general.save")}
          </Button>
        </div>
      </Form>
    </Drawer>
  );
}

export default EditOrder;
