import { createSlice, createAsyncThunk, PayloadAction } from "@reduxjs/toolkit";
import { supabase } from "../../supabaseClient"; // Adjust the import path as needed
import { outdoorTask } from "../../db/interfaces"; // Adjust the import path as needed

interface OutdoorTasksSliceState {
  data: outdoorTask[];
  outdoorTask: outdoorTask;
  status: "loading" | "succeeded" | "failed" | "idle";
  error: string | null;
}

const initialState: OutdoorTasksSliceState = {
  data: [],
  outdoorTask: {} as outdoorTask,
  status: "idle",
  error: null,
};

// Fetch all outdoor tasks
export const fetchAllOutdoorTasks = createAsyncThunk<outdoorTask[], void, { rejectValue: string }>(
  "outdoorTasks/fetchAllOutdoorTasks",
  async (_, thunkApi) => {
    const { data, error } = await supabase.from("outdoorTasks").select("*");
    if (error) {
      return thunkApi.rejectWithValue(error.message);
    }
    return data as outdoorTask[];
  }
);

// Fetch outdoor by and date
export const fetchOutdoorTasksByDate = createAsyncThunk<
  outdoorTask[],
  { gt: string; ls: string; byField: string },
  { rejectValue: string }
>("vacation/fetchOutdoorTasksByDate", async ({ gt, ls, byField }, thunkApi) => {
  const { data, error } = await supabase.from("outdoorTasks").select("*").gt(byField, gt).lt(byField, ls);
  if (error) {
    return thunkApi.rejectWithValue(error.message);
  }
  return data as outdoorTask[];
});

// Fetch outdoor task by ID
export const fetchOutdoorTaskById = createAsyncThunk<outdoorTask, string, { rejectValue: string }>(
  "outdoorTasks/fetchOutdoorTaskById",
  async (outdoorTaskId, thunkApi) => {
    const { data, error } = await supabase.from("outdoorTasks").select("*").eq("id", outdoorTaskId).single();
    if (error) {
      return thunkApi.rejectWithValue(error.message);
    }
    return data as outdoorTask;
  }
);

// Add a new outdoor task
export const addOutdoorTask = createAsyncThunk<outdoorTask, outdoorTask, { rejectValue: string }>(
  "outdoorTasks/addOutdoorTask",
  async (newOutdoorTask, thunkApi) => {
    const { data: insertData, error: insertError } = await supabase
      .from("outdoorTasks")
      .insert(newOutdoorTask)
      .select()
      .single();

    if (insertError) {
      return thunkApi.rejectWithValue(insertError.message);
    }

    return insertData as outdoorTask;
  }
);

// Edit an outdoor task
export const editOutdoorTask = createAsyncThunk<outdoorTask, outdoorTask, { rejectValue: string }>(
  "outdoorTasks/editOutdoorTask",
  async (updatedOutdoorTask, thunkApi) => {
    const { data: updateData, error: updateError } = await supabase
      .from("outdoorTasks")
      .update(updatedOutdoorTask)
      .eq("id", updatedOutdoorTask.id)
      .select()
      .single();

    if (updateError) {
      return thunkApi.rejectWithValue(updateError.message);
    }

    return updateData as outdoorTask;
  }
);

// Delete an outdoor task
export const deleteOutdoorTask = createAsyncThunk<string, string, { rejectValue: string }>(
  "outdoorTasks/deleteOutdoorTask",
  async (id, thunkApi) => {
    const { error } = await supabase.from("outdoorTasks").delete().eq("id", id);

    if (error) {
      return thunkApi.rejectWithValue(error.message);
    }

    return id;
  }
);

// Real-Time Actions
export const addOutdoorTaskRealTime = (newOutdoorTask: outdoorTask): PayloadAction<outdoorTask> => ({
  type: "outdoorTasks/addOutdoorTaskRealTime",
  payload: newOutdoorTask,
});

export const editOutdoorTaskRealTime = (updatedOutdoorTask: outdoorTask): PayloadAction<outdoorTask> => ({
  type: "outdoorTasks/editOutdoorTaskRealTime",
  payload: updatedOutdoorTask,
});

export const deleteOutdoorTaskRealTime = (id: string): PayloadAction<string> => ({
  type: "outdoorTasks/deleteOutdoorTaskRealTime",
  payload: id,
});

// Slice definition
const outdoorTasksSlice = createSlice({
  name: "outdoorTasks",
  initialState,
  reducers: {
    addOutdoorTaskRealTime(state, action: PayloadAction<outdoorTask>) {
      const exists = state.data.some((outdoorTask) => outdoorTask.id === action.payload.id);
      if (!exists) {
        state.data = [...state.data, action.payload];
      }
      state.error = null;
    },
    editOutdoorTaskRealTime(state, action: PayloadAction<outdoorTask>) {
      const index = state.data.findIndex((outdoorTask) => outdoorTask.id === action.payload.id);
      if (index !== -1) {
        const isDifferent = JSON.stringify(state.data[index]) !== JSON.stringify(action.payload);
        if (isDifferent) {
          state.data[index] = action.payload;
        }
      }
      state.error = null;

      if (state.outdoorTask.id === action.payload.id) {
        state.outdoorTask = action.payload;
      }
    },
    deleteOutdoorTaskRealTime(state, action: PayloadAction<string>) {
      const exists = state.data.some((outdoorTask) => outdoorTask.id === action.payload);
      if (exists) {
        state.data = state.data.filter((outdoorTask) => outdoorTask.id !== action.payload);
      }
      state.error = null;
    },
  },
  extraReducers: (builder) => {
    builder
      // Handle fetchAllOutdoorTasks
      .addCase(fetchAllOutdoorTasks.pending, (state) => {
        state.status = "loading";
      })
      .addCase(fetchAllOutdoorTasks.fulfilled, (state, action: PayloadAction<outdoorTask[]>) => {
        state.status = "succeeded";
        state.data = action.payload;
        state.error = null;
      })
      .addCase(fetchAllOutdoorTasks.rejected, (state, action: PayloadAction<string | undefined>) => {
        state.status = "failed";
        state.error = action.payload || "Failed to fetch outdoor tasks.";
      })

      // Handle fetchOutdoorTasksByDate
      .addCase(fetchOutdoorTasksByDate.pending, (state) => {
        state.status = "loading";
      })
      .addCase(fetchOutdoorTasksByDate.fulfilled, (state, action: PayloadAction<outdoorTask[]>) => {
        state.status = "succeeded";
        state.data = action.payload;
        state.error = null;
      })
      .addCase(fetchOutdoorTasksByDate.rejected, (state, action: PayloadAction<string | undefined>) => {
        state.status = "failed";
        state.error = action.payload || "Failed to fetch outdoor tasks.";
      })

      // Handle fetchOutdoorTaskById
      .addCase(fetchOutdoorTaskById.pending, (state) => {
        state.status = "loading";
      })
      .addCase(fetchOutdoorTaskById.fulfilled, (state, action: PayloadAction<outdoorTask>) => {
        state.status = "succeeded";
        state.outdoorTask = action.payload;
        state.error = null;
      })
      .addCase(fetchOutdoorTaskById.rejected, (state, action: PayloadAction<string | undefined>) => {
        state.status = "failed";
        state.error = action.payload || "Failed to fetch outdoor task.";
      })

      // Handle addOutdoorTask
      .addCase(addOutdoorTask.pending, (state) => {})
      .addCase(addOutdoorTask.fulfilled, (state, action: PayloadAction<outdoorTask>) => {
        state.error = null;
      })
      .addCase(addOutdoorTask.rejected, (state, action: PayloadAction<string | undefined>) => {
        state.error = action.payload || "Failed to add outdoor task.";
      })

      // Handle editOutdoorTask
      .addCase(editOutdoorTask.pending, (state) => {})
      .addCase(editOutdoorTask.fulfilled, (state, action: PayloadAction<outdoorTask>) => {
        state.error = null;
      })
      .addCase(editOutdoorTask.rejected, (state, action: PayloadAction<string | undefined>) => {
        state.status = "failed";
        state.error = action.payload || "Failed to edit outdoor task.";
      })

      // Handle deleteOutdoorTask
      .addCase(deleteOutdoorTask.pending, (state) => {})
      .addCase(deleteOutdoorTask.fulfilled, (state, action: PayloadAction<string>) => {
        state.error = null;
      })
      .addCase(deleteOutdoorTask.rejected, (state, action: PayloadAction<string | undefined>) => {
        state.status = "failed";
        state.error = action.payload || "Failed to delete outdoor task.";
      });
  },
});

// Real-time subscription function
export const subscribeToOutdoorTasks = (dispatch: any) => {
  const subscription = supabase
    .channel("public:outdoorTasks")
    .on("postgres_changes", { event: "*", schema: "public", table: "outdoorTasks" }, (payload) => {
      const newRecord = payload.new as outdoorTask;
      const oldRecord = payload.old as outdoorTask;

      switch (payload.eventType) {
        case "INSERT":
          dispatch(addOutdoorTaskRealTime(newRecord));
          break;
        case "UPDATE":
          dispatch(editOutdoorTaskRealTime(newRecord));
          break;
        case "DELETE":
          dispatch(deleteOutdoorTaskRealTime(oldRecord.id as string));
          break;
        default:
          break;
      }
    })
    .subscribe();

  return () => {
    subscription.unsubscribe();
  };
};

export default outdoorTasksSlice.reducer;
