import { createSlice, createAsyncThunk, PayloadAction } from "@reduxjs/toolkit";
import { supabase } from "../../supabaseClient"; // Adjust the import path as needed
import { Case } from "../../db/interfaces"; // Define the 'Case' similar to 'vacation' interface

interface CaseSliceState {
  data: Case[];
  caseDetail: Case;
  status: "loading" | "succeeded" | "failed" | "idle";
  error: string | null;
}

const initialState: CaseSliceState = {
  data: [],
  caseDetail: {} as Case,
  status: "idle",
  error: null,
};

// Fetch all cases
export const fetchAllCases = createAsyncThunk<Case[], void, { rejectValue: string }>(
  "cases/fetchAllCases",
  async (_, thunkApi) => {
    const { data, error } = await supabase.from("cases").select("*");
    if (error) {
      return thunkApi.rejectWithValue(error.message);
    }
    return data as Case[];
  }
);

// Fetch case by ID
export const fetchCaseById = createAsyncThunk<Case, string, { rejectValue: string }>(
  "cases/fetchCaseById",
  async (caseId, thunkApi) => {
    const { data, error } = await supabase.from("cases").select("*").eq("id", caseId).single();
    if (error) {
      return thunkApi.rejectWithValue(error.message);
    }
    return data as Case;
  }
);

// Fetch cases by and date
export const fetchCasesByDate = createAsyncThunk<
  Case[],
  { gt: string; ls: string; byField: string },
  { rejectValue: string }
>("vacation/fetchCasesByDate", async ({ gt, ls, byField }, thunkApi) => {
  const { data, error } = await supabase.from("cases").select("*").gt(byField, gt).lt(byField, ls);
  if (error) {
    return thunkApi.rejectWithValue(error.message);
  }
  return data as Case[];
});

// Add a new case record
export const addCase = createAsyncThunk<Case, Case, { rejectValue: string }>(
  "cases/addCase",
  async (newCase, thunkApi) => {
    const { data: insertData, error: insertError } = await supabase.from("cases").insert(newCase).select().single();

    if (insertError) {
      return thunkApi.rejectWithValue(insertError.message);
    }

    return insertData as Case;
  }
);

// Edit a case record
export const editCase = createAsyncThunk<Case, Case, { rejectValue: string }>(
  "cases/editCase",
  async (updatedCase, thunkApi) => {
    const { data: updateData, error: updateError } = await supabase
      .from("cases")
      .update(updatedCase)
      .eq("id", updatedCase.id)
      .select()
      .single();

    if (updateError) {
      return thunkApi.rejectWithValue(updateError.message);
    }

    return updateData as Case;
  }
);

// Delete a case record
export const deleteCase = createAsyncThunk<string, string, { rejectValue: string }>(
  "cases/deleteCase",
  async (id, thunkApi) => {
    const { error } = await supabase.from("cases").delete().eq("id", id);

    if (error) {
      return thunkApi.rejectWithValue(error.message);
    }

    return id;
  }
);

// Real-Time Actions
export const addCaseRealTime = (newCase: Case): PayloadAction<Case> => ({
  type: "cases/addCaseRealTime",
  payload: newCase,
});

export const editCaseRealTime = (updatedCase: Case): PayloadAction<Case> => ({
  type: "cases/editCaseRealTime",
  payload: updatedCase,
});

export const deleteCaseRealTime = (id: string): PayloadAction<string> => ({
  type: "cases/deleteCaseRealTime",
  payload: id,
});

// Slice definition
const caseSlice = createSlice({
  name: "cases",
  initialState,
  reducers: {
    addCaseRealTime(state, action: PayloadAction<Case>) {
      const exists = state.data.some((caseItem) => caseItem.id === action.payload.id);
      if (!exists) {
        state.data = [...state.data, action.payload];
      }
      state.error = null;
    },
    editCaseRealTime(state, action: PayloadAction<Case>) {
      const index = state.data.findIndex((caseItem) => caseItem.id === action.payload.id);
      if (index !== -1) {
        const isDifferent = JSON.stringify(state.data[index]) !== JSON.stringify(action.payload);
        if (isDifferent) {
          state.data[index] = action.payload;
        }
      }
      state.error = null;

      if (state.caseDetail.id === action.payload.id) {
        state.caseDetail = action.payload;
      }
    },
    deleteCaseRealTime(state, action: PayloadAction<string>) {
      const exists = state.data.some((caseItem) => caseItem.id === action.payload);
      if (exists) {
        state.data = state.data.filter((caseItem) => caseItem.id !== action.payload);
      }
      state.error = null;
    },
  },
  extraReducers: (builder) => {
    builder
      // Handle fetchAllCases
      .addCase(fetchAllCases.pending, (state) => {
        state.status = "loading";
      })
      .addCase(fetchAllCases.fulfilled, (state, action: PayloadAction<Case[]>) => {
        state.status = "succeeded";
        state.data = action.payload;
        state.error = null;
      })
      .addCase(fetchAllCases.rejected, (state, action: PayloadAction<string | undefined>) => {
        state.status = "failed";
        state.error = action.payload || "Failed to fetch cases.";
      })

      // Handle fetchCasesByDate
      .addCase(fetchCasesByDate.pending, (state) => {
        state.status = "loading";
      })
      .addCase(fetchCasesByDate.fulfilled, (state, action: PayloadAction<Case[]>) => {
        state.status = "succeeded";
        state.data = action.payload;
        state.error = null;
      })
      .addCase(fetchCasesByDate.rejected, (state, action: PayloadAction<string | undefined>) => {
        state.status = "failed";
        state.error = action.payload || "Failed to fetch cases.";
      })

      // Handle fetchCaseById
      .addCase(fetchCaseById.pending, (state) => {
        state.status = "loading";
      })
      .addCase(fetchCaseById.fulfilled, (state, action: PayloadAction<Case>) => {
        state.status = "succeeded";
        state.caseDetail = action.payload;
        state.error = null;
      })
      .addCase(fetchCaseById.rejected, (state, action: PayloadAction<string | undefined>) => {
        state.status = "failed";
        state.error = action.payload || "Failed to fetch case.";
      })

      // Handle addCase
      .addCase(addCase.pending, (state) => {})
      .addCase(addCase.fulfilled, (state, action: PayloadAction<Case>) => {
        state.error = null;
      })
      .addCase(addCase.rejected, (state, action: PayloadAction<string | undefined>) => {
        state.error = action.payload || "Failed to add case.";
      })

      // Handle editCase
      .addCase(editCase.pending, (state) => {})
      .addCase(editCase.fulfilled, (state, action: PayloadAction<Case>) => {
        state.error = null;
      })
      .addCase(editCase.rejected, (state, action: PayloadAction<string | undefined>) => {
        state.error = action.payload || "Failed to edit case.";
      })

      // Handle deleteCase
      .addCase(deleteCase.pending, (state) => {})
      .addCase(deleteCase.fulfilled, (state, action: PayloadAction<string>) => {
        state.error = null;
      })
      .addCase(deleteCase.rejected, (state, action: PayloadAction<string | undefined>) => {
        state.error = action.payload || "Failed to delete case.";
      });
  },
});

// Real-time subscription function
export const subscribeToCases = (dispatch: any) => {
  const subscription = supabase
    .channel("public:cases")
    .on("postgres_changes", { event: "*", schema: "public", table: "cases" }, (payload) => {
      const newRecord = payload.new as Case;
      const oldRecord = payload.old as Case;

      switch (payload.eventType) {
        case "INSERT":
          dispatch(addCaseRealTime(newRecord));
          break;
        case "UPDATE":
          dispatch(editCaseRealTime(newRecord));
          break;
        case "DELETE":
          dispatch(deleteCaseRealTime(oldRecord.id as string));
          break;
        default:
          break;
      }
    })
    .subscribe();

  return () => {
    subscription.unsubscribe();
  };
};

export default caseSlice.reducer;
