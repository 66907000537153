import { createSlice, createAsyncThunk, PayloadAction } from "@reduxjs/toolkit";
import { supabase } from "../../supabaseClient"; // Adjust the import path as needed
import { Tag } from "../../db/interfaces"; // Adjust the import path as needed

interface TagsState {
  tags: Tag[];
  status: "loading" | "succeeded" | "failed" | "idle";
  error: string | null;
}

const initialState: TagsState = {
  tags: [],
  status: "idle",
  error: null,
};

// Fetch tags from Supabase
export const fetchTags = createAsyncThunk<Tag[], void, { rejectValue: string }>(
  "tags/fetchTags",
  async (_, thunkApi) => {
    const { data, error } = await supabase.from("tags").select("*");

    if (error) {
      return thunkApi.rejectWithValue(error.message);
    }

    return data as Tag[];
  }
);

// Add a new tag
export const addTag = createAsyncThunk<Tag, Tag, { rejectValue: string }>("tags/addTag", async (newTag, thunkApi) => {
  const { data, error } = await supabase.from("tags").insert(newTag).select();

  if (error) {
    return thunkApi.rejectWithValue(error.message);
  }

  return data[0] as Tag;
});

// Update an existing tag
export const updateTag = createAsyncThunk<Tag, { id: string; changes: Partial<Tag> }, { rejectValue: string }>(
  "tags/updateTag",
  async ({ id, changes }, thunkApi) => {
    const { data, error } = await supabase.from("tags").update(changes).eq("id", id).select();

    if (error) {
      return thunkApi.rejectWithValue(error.message);
    }

    return data[0] as Tag;
  }
);

// Delete a tag
export const deleteTag = createAsyncThunk<string, string, { rejectValue: string }>(
  "tags/deleteTag",
  async (id, thunkApi) => {
    const { error } = await supabase.from("tags").delete().eq("id", id);

    if (error) {
      return thunkApi.rejectWithValue(error.message);
    }

    return id;
  }
);

const tagsSlice = createSlice({
  name: "tags",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    // Fetch tags
    builder.addCase(fetchTags.pending, (state) => {
      state.status = "loading";
      state.error = null;
    });
    builder.addCase(fetchTags.fulfilled, (state, action: PayloadAction<Tag[]>) => {
      state.tags = action.payload;
      state.status = "succeeded";
    });
    builder.addCase(fetchTags.rejected, (state, action) => {
      state.status = "failed";
      state.error = action.payload || "Failed to fetch tags";
    });

    // Add tag
    builder.addCase(addTag.pending, (state) => {
      state.status = "loading";
      state.error = null;
    });
    builder.addCase(addTag.fulfilled, (state, action: PayloadAction<Tag>) => {
      state.tags.push(action.payload);
      state.status = "succeeded";
    });
    builder.addCase(addTag.rejected, (state, action) => {
      state.status = "failed";
      state.error = action.payload || "Failed to add tag";
    });

    // Update tag
    builder.addCase(updateTag.pending, (state) => {
      state.status = "loading";
      state.error = null;
    });
    builder.addCase(updateTag.fulfilled, (state, action: PayloadAction<Tag>) => {
      const index = state.tags.findIndex((tag: Tag) => tag.id === action.payload.id);
      if (index !== -1) {
        state.tags[index] = action.payload;
      }
      state.status = "succeeded";
    });
    builder.addCase(updateTag.rejected, (state, action) => {
      state.status = "failed";
      state.error = action.payload || "Failed to update tag";
    });

    // Delete tag
    builder.addCase(deleteTag.pending, (state) => {
      state.status = "loading";
      state.error = null;
    });
    builder.addCase(deleteTag.fulfilled, (state, action: PayloadAction<string>) => {
      state.tags = state.tags.filter((tag: Tag) => tag.id !== action.payload);
      state.status = "succeeded";
    });
    builder.addCase(deleteTag.rejected, (state, action) => {
      state.status = "failed";
      state.error = action.payload || "Failed to delete tag";
    });
  },
});

export default tagsSlice.reducer;
