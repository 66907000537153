import React, { useState, useEffect } from "react";
import Paper from "@material-ui/core/Paper";
import { Spin, Button, Popconfirm, message } from "antd";
import { useTranslation } from "react-i18next";
import ManagePermissions from "./ManagePermissionsModel";
import AddManagePermissions from "./AddManagePermissionsModel";
import { globalMissionsData } from "../../global/missionList";
import DevTable from "../../helpers/devex-table";
import { useAppDispatch, useAppSelector } from "../../hooks/reduxHooks";
import { deleteOffice, fetchAllOffices, subscribeToOffices } from "../../features/offices/officesSlice";
import { office } from "../../db/interfaces";
import { globalUsersData } from "../../global/usersList";
import { globalOfficesData } from "../../global/officeList";
import { admins } from "../../db/admin";

const columnsExport = [
  { name: "id", title: "Id" },
  { name: "officeName", title: "Office Name" },
  { name: "location", title: "Location" },
];

export default function OfficesTable() {
  const { data: officesData, status, error } = useAppSelector((store) => store.offices);
  const { currentUser } = useAppSelector((store) => store.auth);

  const dispatch = useAppDispatch();
  const { t } = useTranslation();
  const [modalState, setModalState] = useState(false);
  const [office, setOffice] = useState({} as office);
  const [modalStatePermissions, setModalStatePermissions] = useState(false);

  useEffect(() => {
    dispatch(fetchAllOffices());
    const unsubscribe = subscribeToOffices(dispatch);
    return () => {
      unsubscribe();
    };
  }, [dispatch]);

  if (status == "loading") return <Spin />;

  const data = officesData
    .filter((o) => {
      if (admins.includes(currentUser?.id as string)) {
        return true;
      } else {
        return (
          globalMissionsData[globalOfficesData[currentUser.office].missionId]?.missionLocation ==
          globalMissionsData[o.missionId]?.missionLocation
        );
      }
    })
    .map((d) => {
      return { ...d, id: d.id };
    });

  const columns = [
    { name: "id", title: "id" },
    { name: "officeName", title: "Office Name" },
    { name: "location", title: "Location" },
    { name: "missionId", title: "Mission Name", getCellValue: (r) => globalMissionsData[r.missionId].missionName },
    {
      name: "actions",
      title: "Actions",
      getCellValue: (row: any) => {
        return (
          <Popconfirm
            title={t("general.areUSure")}
            onConfirm={() => {
              dispatch(deleteOffice(row.id)).unwrap();
              message.success("Deleted Successfully!");
            }}
            onCancel={() => console.log("cancel")}
            okText="Yes"
            cancelText="No"
          >
            <Button type="link">Delete</Button>
          </Popconfirm>
        );
      },
    },
    {
      name: "permissions",
      title: "Permissions",
      getCellValue: (row: any) => {
        return (
          <Button
            type="primary"
            onClick={() => {
              setOffice(row);
              setModalStatePermissions(true);
            }}
          >
            Manage Permissions
          </Button>
        );
      },
    },
  ];

  return error ? (
    <p>{error}</p>
  ) : (
    <div>
      {modalStatePermissions ? (
        <ManagePermissions
          modalState={modalStatePermissions}
          setModalState={setModalStatePermissions}
          office={office}
        />
      ) : null}
      <AddManagePermissions modalState={modalState} setModalState={setModalState} />
      <Button style={{ marginBottom: "1%" }} type={"primary"} onClick={() => setModalState(true)}>
        {t("general.addNewOffice")}
      </Button>
      <Paper>
        <DevTable
          data={data}
          columns={columns}
          defaultHiddenColumnNames={[]}
          tableName="Offices"
          selected={false}
          typicalTable={false}
          columnsExport={columnsExport}
        />
      </Paper>
    </div>
  );
}
