import { fetchAllDepartments } from "../features/departments/departmentsSlice";
import { RootState, store } from "../store";

const initializeDepartmentsData = async () => {
  const state: RootState = store.getState();
  const { loaded } = state.departments;

  // Only dispatch fetch if the data hasn't been loaded yet
  if (!loaded) {
    await store.dispatch(fetchAllDepartments());
  }
};

// Immediately call to ensure data is fetched
initializeDepartmentsData();

export const getDepartmentsData = () => {
  const state: RootState = store.getState();
  const { globalDepartmentsData, loaded } = state.departments;
  // if (!loaded) {
  //   console.warn("Department Departments data is not loaded yet.");
  // }

  return {
    globalDepartmentsData,
  };
};

// Initial export values, populated from the Redux store
export let globalDepartmentsData = getDepartmentsData().globalDepartmentsData;

// Subscribe to store updates and dynamically update exports when the Redux state changes
store.subscribe(() => {
  const { globalDepartmentsData: updatedGlobalDepartmentsData } = getDepartmentsData();

  // Update the exports with the latest values from the Redux store
  globalDepartmentsData = updatedGlobalDepartmentsData;
});
