import { createSlice, createAsyncThunk, PayloadAction } from "@reduxjs/toolkit";
import { supabase } from "../../supabaseClient"; // Adjust the import path as needed
import { vacation } from "../../db/interfaces"; // Adjust the import path as needed

interface VacationSliceState {
  data: vacation[];
  vacation: vacation;
  status: "loading" | "succeeded" | "failed" | "idle";
  error: string | null;
}

const initialState: VacationSliceState = {
  data: [],
  vacation: {} as vacation,
  status: "idle",
  error: null,
};

// Fetch all vacation records
export const fetchAllVacations = createAsyncThunk<vacation[], void, { rejectValue: string }>(
  "vacation/fetchAllVacations",
  async (_, thunkApi) => {
    const { data, error } = await supabase.from("vacations").select("*");
    if (error) {
      return thunkApi.rejectWithValue(error.message);
    }
    return data as vacation[];
  }
);

// Fetch vacations by Office
export const fetchVacationsByOffice = createAsyncThunk<vacation[], string, { rejectValue: string }>(
  "vacation/fetchVacationsByOffice",
  async (officeId, thunkApi) => {
    const { data, error } = await supabase.from("vacations").select("*").eq("office", officeId);
    if (error) {
      return thunkApi.rejectWithValue(error.message);
    }
    return data as vacation[];
  }
);
// Fetch vacation by id
export const fetchVacationById = createAsyncThunk<vacation, string, { rejectValue: string }>(
  "vacation/fetchVacationById",
  async (vacationId, thunkApi) => {
    const { data, error } = await supabase.from("vacations").select("*").eq("id", vacationId).single();
    if (error) {
      return thunkApi.rejectWithValue(error.message);
    }
    return data as vacation;
  }
);

export const fetchVacationByUserId = createAsyncThunk<vacation, string, { rejectValue: string }>(
  "vacation/fetchVacationByUserId",
  async (employeeUID, thunkApi) => {
    const { data, error } = await supabase.from("vacations").select("*").eq("employeeUID", employeeUID).single();
    if (error) {
      return thunkApi.rejectWithValue(error.message);
    }
    return data as vacation;
  }
);

// Fetch vacations by Office and date
export const fetchVacationsByOfficeByDate = createAsyncThunk<
  vacation[],
  { officeId: string; gt: string; ls: string; byField: string },
  { rejectValue: string }
>("vacation/fetchVacationsByOfficeByDate", async ({ officeId, gt, ls, byField }, thunkApi) => {
  const { data, error } = await supabase
    .from("vacations")
    .select("*")
    .eq("office", officeId)
    .gt(byField, gt)
    .lt(byField, ls);
  if (error) {
    return thunkApi.rejectWithValue(error.message);
  }
  return data as vacation[];
});

// Fetch vacations by and date
export const fetchVacationsByDate = createAsyncThunk<
  vacation[],
  { gt: string; ls: string; byField: string },
  { rejectValue: string }
>("vacation/fetchVacationsByDate", async ({ gt, ls, byField }, thunkApi) => {
  const { data, error } = await supabase.from("vacations").select("*").gt(byField, gt).lt(byField, ls);
  if (error) {
    return thunkApi.rejectWithValue(error.message);
  }
  return data as vacation[];
});

// Fetch vacations by user id and date
export const fetchVacationsByUserIdByDate = createAsyncThunk<
  vacation[],
  { userId: string; gt: string; ls: string; byField: string },
  { rejectValue: string }
>("vacation/fetchVacationsByUserIdByDate", async ({ userId, gt, ls, byField }, thunkApi) => {
  const { data, error } = await supabase
    .from("vacations")
    .select("*")
    .eq("employeeUID", userId)
    .gt(byField, gt)
    .lt(byField, ls);
  if (error) {
    return thunkApi.rejectWithValue(error.message);
  }
  return data as vacation[];
});

// Add a new vacation record
export const addVacation = createAsyncThunk<vacation, vacation, { rejectValue: string }>(
  "vacation/addVacation",
  async (newVacation, thunkApi) => {
    const { data: insertData, error: insertError } = await supabase
      .from("vacations")
      .insert(newVacation)
      .select()
      .single();

    if (insertError) {
      return thunkApi.rejectWithValue(insertError.message);
    }

    return insertData as vacation;
  }
);

// Edit a vacation record
export const editVacation = createAsyncThunk<vacation, vacation, { rejectValue: string }>(
  "vacation/editVacation",
  async (updatedVacation, thunkApi) => {
    const { data: updateData, error: updateError } = await supabase
      .from("vacations")
      .update(updatedVacation)
      .eq("id", updatedVacation.id)
      .select()
      .single();

    if (updateError) {
      return thunkApi.rejectWithValue(updateError.message);
    }

    return updateData as vacation;
  }
);

// Delete a vacation record
export const deleteVacation = createAsyncThunk<string, string, { rejectValue: string }>(
  "vacation/deleteVacation",
  async (id, thunkApi) => {
    const { error } = await supabase.from("vacations").delete().eq("id", id);

    if (error) {
      return thunkApi.rejectWithValue(error.message);
    }

    return id;
  }
);

// Real-Time Actions
export const addVacationRealTime = (newVacation: vacation): PayloadAction<vacation> => ({
  type: "vacation/addVacationRealTime",
  payload: newVacation,
});

export const editVacationRealTime = (updatedVacation: vacation): PayloadAction<vacation> => ({
  type: "vacation/editVacationRealTime",
  payload: updatedVacation,
});

export const deleteVacationRealTime = (id: string): PayloadAction<string> => ({
  type: "vacation/deleteVacationRealTime",
  payload: id,
});

// Slice definition
const vacationSlice = createSlice({
  name: "vacation",
  initialState,
  reducers: {
    addVacationRealTime(state, action: PayloadAction<vacation>) {
      const exists = state.data.some((vacation) => vacation.id === action.payload.id);
      if (!exists) {
        state.data = [...state.data, action.payload];
      }
      state.error = null;
    },
    editVacationRealTime(state, action: PayloadAction<vacation>) {
      const index = state.data.findIndex((vacation) => vacation.id === action.payload.id);
      if (index !== -1) {
        const isDifferent = JSON.stringify(state.data[index]) !== JSON.stringify(action.payload);
        if (isDifferent) {
          state.data[index] = action.payload;
        }
      }
      state.error = null;

      if (state.vacation.id == action.payload.id) {
        state.vacation = action.payload;
      }
    },
    deleteVacationRealTime(state, action: PayloadAction<string>) {
      const exists = state.data.some((vacation) => vacation.id === action.payload);
      if (exists) {
        state.data = state.data.filter((vacation) => vacation.id !== action.payload);
      }
      state.error = null;
    },
  },
  extraReducers: (builder) => {
    builder
      // Handle fetchAllVacations
      .addCase(fetchAllVacations.pending, (state) => {
        state.status = "loading";
      })
      .addCase(fetchAllVacations.fulfilled, (state, action: PayloadAction<vacation[]>) => {
        state.status = "succeeded";
        state.data = action.payload;
        state.error = null;
      })
      .addCase(fetchAllVacations.rejected, (state, action: PayloadAction<string | undefined>) => {
        state.status = "failed";
        state.error = action.payload || "Failed to fetch vacations.";
      })

      // Handle fetchVacationById
      .addCase(fetchVacationById.pending, (state) => {
        state.status = "loading";
      })
      .addCase(fetchVacationById.fulfilled, (state, action: PayloadAction<vacation>) => {
        state.status = "succeeded";
        state.vacation = action.payload;
        state.error = null;
      })
      .addCase(fetchVacationById.rejected, (state, action: PayloadAction<string | undefined>) => {
        state.status = "failed";
        state.error = action.payload || "Failed to fetch vacations.";
      })

      // Handle fetchVacationByUserId
      .addCase(fetchVacationByUserId.pending, (state) => {
        state.status = "loading";
      })
      .addCase(fetchVacationByUserId.fulfilled, (state, action: PayloadAction<vacation>) => {
        state.status = "succeeded";
        state.vacation = action.payload;
        state.error = null;
      })
      .addCase(fetchVacationByUserId.rejected, (state, action: PayloadAction<string | undefined>) => {
        state.status = "failed";
        state.error = action.payload || "Failed to fetch vacations.";
      })

      // Handle fetchVacationsByOffice
      .addCase(fetchVacationsByOffice.pending, (state) => {
        state.status = "loading";
      })
      .addCase(fetchVacationsByOffice.fulfilled, (state, action: PayloadAction<vacation[]>) => {
        state.status = "succeeded";
        state.data = action.payload;
        state.error = null;
      })
      .addCase(fetchVacationsByOffice.rejected, (state, action: PayloadAction<string | undefined>) => {
        state.status = "failed";
        state.error = action.payload || "Failed to fetch vacations by employee.";
      })

      // Handle fetchVacationsByOfficeByDate
      .addCase(fetchVacationsByOfficeByDate.pending, (state) => {
        state.status = "loading";
      })
      .addCase(fetchVacationsByOfficeByDate.fulfilled, (state, action: PayloadAction<vacation[]>) => {
        state.status = "succeeded";
        state.data = action.payload;
        state.error = null;
      })
      .addCase(fetchVacationsByOfficeByDate.rejected, (state, action: PayloadAction<string | undefined>) => {
        state.status = "failed";
        state.error = action.payload || "Failed to fetch vacations by employee.";
      })

      // Handle fetchVacationsByDate
      .addCase(fetchVacationsByDate.pending, (state) => {
        state.status = "loading";
      })
      .addCase(fetchVacationsByDate.fulfilled, (state, action: PayloadAction<vacation[]>) => {
        state.status = "succeeded";
        state.data = action.payload;
        state.error = null;
      })
      .addCase(fetchVacationsByDate.rejected, (state, action: PayloadAction<string | undefined>) => {
        state.status = "failed";
        state.error = action.payload || "Failed to fetch vacations by employee.";
      })

      // Handle fetchVacationsByUserIdByDate
      .addCase(fetchVacationsByUserIdByDate.pending, (state) => {
        state.status = "loading";
      })
      .addCase(fetchVacationsByUserIdByDate.fulfilled, (state, action: PayloadAction<vacation[]>) => {
        state.status = "succeeded";
        state.data = action.payload;
        state.error = null;
      })
      .addCase(fetchVacationsByUserIdByDate.rejected, (state, action: PayloadAction<string | undefined>) => {
        state.status = "failed";
        state.error = action.payload || "Failed to fetch vacations by employee.";
      })

      // Handle addVacation
      .addCase(addVacation.pending, (state) => {})
      .addCase(addVacation.fulfilled, (state, action: PayloadAction<vacation>) => {
        // state.data.push(action.payload);
        state.error = null;
      })
      .addCase(addVacation.rejected, (state, action: PayloadAction<string | undefined>) => {
        state.error = action.payload || "Failed to add vacation.";
      })

      // Handle editVacation
      .addCase(editVacation.pending, (state) => {})
      .addCase(editVacation.fulfilled, (state, action: PayloadAction<vacation>) => {
        // const index = state.data.findIndex((vacation) => vacation.id === action.payload.id);
        // if (index !== -1) {
        //   state.data[index] = action.payload;
        // }
        state.error = null;
      })
      .addCase(editVacation.rejected, (state, action: PayloadAction<string | undefined>) => {
        state.status = "failed";
        state.error = action.payload || "Failed to edit vacation.";
      })

      // Handle deleteVacation
      .addCase(deleteVacation.pending, (state) => {})
      .addCase(deleteVacation.fulfilled, (state, action: PayloadAction<string>) => {
        // state.data = state.data.filter((vacation) => vacation.id !== action.payload);
        state.error = null;
      })
      .addCase(deleteVacation.rejected, (state, action: PayloadAction<string | undefined>) => {
        state.error = action.payload || "Failed to delete vacation.";
      });
  },
});

// Real-time subscription function
export const subscribeToVacations = (dispatch: any) => {
  const subscription = supabase
    .channel("public:vacations")
    .on("postgres_changes", { event: "*", schema: "public", table: "vacations" }, (payload) => {
      const newRecord = payload.new as vacation;
      const oldRecord = payload.old as vacation;

      switch (payload.eventType) {
        case "INSERT":
          dispatch(addVacationRealTime(newRecord));
          break;
        case "UPDATE":
          dispatch(editVacationRealTime(newRecord));
          break;
        case "DELETE":
          dispatch(deleteVacationRealTime(oldRecord.id as string));
          break;
        default:
          break;
      }
    })
    .subscribe();

  return () => {
    subscription.unsubscribe();
  };
};

export default vacationSlice.reducer;
