import { createSlice, createAsyncThunk, PayloadAction } from "@reduxjs/toolkit";
import { supabase } from "../../supabaseClient"; // Adjust the import path as needed
import { meetingRoom } from "../../db/interfaces"; // Adjust the import path as needed

interface RoomListSliceState {
  data: meetingRoom[];
  room: meetingRoom;
  status: "loading" | "succeeded" | "failed" | "idle";
  error: string | null;
}

const initialState: RoomListSliceState = {
  data: [],
  room: {} as meetingRoom,
  status: "idle",
  error: null,
};

// Fetch all rooms
export const fetchAllRooms = createAsyncThunk<meetingRoom[], void, { rejectValue: string }>(
  "roomsList/fetchAllRooms",
  async (_, thunkApi) => {
    const { data, error } = await supabase.from("roomsList").select("*");
    if (error) {
      return thunkApi.rejectWithValue(error.message);
    }
    return data as meetingRoom[];
  }
);

// Fetch room by ID
export const fetchRoomById = createAsyncThunk<meetingRoom, string, { rejectValue: string }>(
  "roomsList/fetchRoomById",
  async (roomId, thunkApi) => {
    const { data, error } = await supabase.from("roomsList").select("*").eq("id", roomId).single();
    if (error) {
      return thunkApi.rejectWithValue(error.message);
    }
    return data as meetingRoom;
  }
);

// Fetch rooms by user
export const fetchRoomsByUser = createAsyncThunk<meetingRoom[], string, { rejectValue: string }>(
  "roomsList/fetchRoomsByUser",
  async (userId, thunkApi) => {
    const { data, error } = await supabase.from("roomsList").select("*").eq("user_id", userId);
    if (error) {
      return thunkApi.rejectWithValue(error.message);
    }
    return data as meetingRoom[];
  }
);

// Add a new room
export const addRoom = createAsyncThunk<meetingRoom, meetingRoom, { rejectValue: string }>(
  "roomsList/addRoom",
  async (newRoom, thunkApi) => {
    const { data: insertData, error: insertError } = await supabase.from("roomsList").insert(newRoom).select().single();

    if (insertError) {
      return thunkApi.rejectWithValue(insertError.message);
    }

    return insertData as meetingRoom;
  }
);

// Edit a room
export const editRoom = createAsyncThunk<meetingRoom, meetingRoom, { rejectValue: string }>(
  "roomsList/editRoom",
  async (updatedRoom, thunkApi) => {
    const { data: updateData, error: updateError } = await supabase
      .from("roomsList")
      .update(updatedRoom)
      .eq("id", updatedRoom.id)
      .select()
      .single();

    if (updateError) {
      return thunkApi.rejectWithValue(updateError.message);
    }

    return updateData as meetingRoom;
  }
);

// Delete a room
export const deleteRoom = createAsyncThunk<string, string, { rejectValue: string }>(
  "roomsList/deleteRoom",
  async (id, thunkApi) => {
    const { error } = await supabase.from("roomsList").delete().eq("id", id);

    if (error) {
      return thunkApi.rejectWithValue(error.message);
    }

    return id;
  }
);

// Real-Time Actions
export const addRoomRealTime = (newRoom: meetingRoom): PayloadAction<meetingRoom> => ({
  type: "roomsList/addRoomRealTime",
  payload: newRoom,
});

export const editRoomRealTime = (updatedRoom: meetingRoom): PayloadAction<meetingRoom> => ({
  type: "roomsList/editRoomRealTime",
  payload: updatedRoom,
});

export const deleteRoomRealTime = (id: string): PayloadAction<string> => ({
  type: "roomsList/deleteRoomRealTime",
  payload: id,
});

// Slice definition
const roomsListSlice = createSlice({
  name: "roomsList",
  initialState,
  reducers: {
    addRoomRealTime(state, action: PayloadAction<meetingRoom>) {
      const exists = state.data.some((room) => room.id === action.payload.id);
      if (!exists) {
        state.data = [...state.data, action.payload];
      }
      state.error = null;
    },
    editRoomRealTime(state, action: PayloadAction<meetingRoom>) {
      const index = state.data.findIndex((room) => room.id === action.payload.id);
      if (index !== -1) {
        const isDifferent = JSON.stringify(state.data[index]) !== JSON.stringify(action.payload);
        if (isDifferent) {
          state.data[index] = action.payload;
        }
      }
      state.error = null;

      if (state.room.id === action.payload.id) {
        state.room = action.payload;
      }
    },
    deleteRoomRealTime(state, action: PayloadAction<string>) {
      const exists = state.data.some((room) => room.id === action.payload);
      if (exists) {
        state.data = state.data.filter((room) => room.id !== action.payload);
      }
      state.error = null;
    },
  },
  extraReducers: (builder) => {
    builder
      // Handle fetchAllRooms
      .addCase(fetchAllRooms.pending, (state) => {
        state.status = "loading";
      })
      .addCase(fetchAllRooms.fulfilled, (state, action: PayloadAction<meetingRoom[]>) => {
        state.status = "succeeded";
        state.data = action.payload;
        state.error = null;
      })
      .addCase(fetchAllRooms.rejected, (state, action: PayloadAction<string | undefined>) => {
        state.status = "failed";
        state.error = action.payload || "Failed to fetch rooms.";
      })

      // Handle fetchRoomById
      .addCase(fetchRoomById.pending, (state) => {
        state.status = "loading";
      })
      .addCase(fetchRoomById.fulfilled, (state, action: PayloadAction<meetingRoom>) => {
        state.status = "succeeded";
        state.room = action.payload;
        state.error = null;
      })
      .addCase(fetchRoomById.rejected, (state, action: PayloadAction<string | undefined>) => {
        state.status = "failed";
        state.error = action.payload || "Failed to fetch room.";
      })

      // Handle fetchRoomsByUser
      .addCase(fetchRoomsByUser.pending, (state) => {
        state.status = "loading";
      })
      .addCase(fetchRoomsByUser.fulfilled, (state, action: PayloadAction<meetingRoom[]>) => {
        state.status = "succeeded";
        state.data = action.payload;
        state.error = null;
      })
      .addCase(fetchRoomsByUser.rejected, (state, action: PayloadAction<string | undefined>) => {
        state.status = "failed";
        state.error = action.payload || "Failed to fetch rooms by user.";
      })

      // Handle addRoom
      .addCase(addRoom.pending, (state) => {})
      .addCase(addRoom.fulfilled, (state, action: PayloadAction<meetingRoom>) => {
        state.error = null;
      })
      .addCase(addRoom.rejected, (state, action: PayloadAction<string | undefined>) => {
        state.error = action.payload || "Failed to add room.";
      })

      // Handle editRoom
      .addCase(editRoom.pending, (state) => {})
      .addCase(editRoom.fulfilled, (state, action: PayloadAction<meetingRoom>) => {
        state.error = null;
      })
      .addCase(editRoom.rejected, (state, action: PayloadAction<string | undefined>) => {
        state.status = "failed";
        state.error = action.payload || "Failed to edit room.";
      })

      // Handle deleteRoom
      .addCase(deleteRoom.pending, (state) => {})
      .addCase(deleteRoom.fulfilled, (state, action: PayloadAction<string>) => {
        state.error = null;
      })
      .addCase(deleteRoom.rejected, (state, action: PayloadAction<string | undefined>) => {
        state.status = "failed";
        state.error = action.payload || "Failed to delete room.";
      });
  },
});

// Real-time subscription function
export const subscribeToRoomsList = (dispatch: any) => {
  const subscription = supabase
    .channel("public:roomsList")
    .on("postgres_changes", { event: "*", schema: "public", table: "roomsList" }, (payload) => {
      const newRecord = payload.new as meetingRoom;
      const oldRecord = payload.old as meetingRoom;

      switch (payload.eventType) {
        case "INSERT":
          dispatch(addRoomRealTime(newRecord));
          break;
        case "UPDATE":
          dispatch(editRoomRealTime(newRecord));
          break;
        case "DELETE":
          dispatch(deleteRoomRealTime(oldRecord.id as string));
          break;
        default:
          break;
      }
    })
    .subscribe();

  return () => {
    subscription.unsubscribe();
  };
};

export default roomsListSlice.reducer;
