import * as React from "react";
import Paper from "@material-ui/core/Paper";
import {
  Scheduler,
  DayView,
  WeekView,
  MonthView,
  ViewSwitcher,
  Toolbar,
  Appointments,
  AppointmentTooltip,
  DateNavigator,
  TodayButton,
  AppointmentForm,
  AllDayPanel,
  EditRecurrenceMenu,
  Resources,
  CurrentTimeIndicator,
} from "@devexpress/dx-react-scheduler-material-ui";
import { ViewState, EditingState, IntegratedEditing, ChangeSet } from "@devexpress/dx-react-scheduler";
import { RRule } from "rrule";
import { Spin, Button, Select, Icon, Calendar, message } from "antd";
import { map } from "lodash";
import { useState } from "react";
import moment from "moment";
import { withStyles, createStyles, Theme } from "@material-ui/core/styles";
import { WithStyles } from "@material-ui/styles";
import { indigo, blue, teal } from "@material-ui/core/colors";
import { fade } from "@material-ui/core/styles/colorManipulator";
const { Option } = Select;
import { colorsRoom } from "../../db/colors";
import DeleteDialog from "./DeleteConfirmation";
import EditDialog from "./EditConfirmation";
import { useTranslation } from "react-i18next";
import { admins } from "../../db/admin";
import { Autocomplete } from "@material-ui/lab";
import TextField from "@material-ui/core/TextField";
import Editor from "../Editor/Editor";
import { API, OutputData } from "@editorjs/editorjs";
import { dataToHTML } from "../Editor/constants";
import IconButton from "@material-ui/core/IconButton";
import MomIcon from "@material-ui/icons/NoteAdd"; // Example icon, choose an appropriate one
import AddMOM from "./AddMOM";
import { useAppDispatch, useAppSelector } from "../../hooks/reduxHooks";
import { fetchCalendarEventsInRange, subscribeToCalendarEvents } from "../../features/calendar/calendarSlice";
import { fetchAllRooms } from "../../features/roomsList/roomsListSlice";
import { globalUsersData, USERS_ID_FULL_NAME } from "../../global/usersList";
import { addOrUpdateCalendarEvents } from "./AddOrUpdateCalendarEvents";
import { addNotification } from "../../features/notifications/notificationsSlice";
import { uploadFile } from "../../db/supabase-storage";

const TextEditor = (props: any) => {
  if (props.type === "multilineTextEditor") {
    return null;
  }
  return <AppointmentForm.TextEditor {...props} />;
};
const antIcon = <Icon type="loading" style={{ fontSize: 24 }} spin />;

const messages = {
  moreInformationLabel: "",
  detailsLabel: "Appointment Form",
  commitCommand: "Submit",
};
const styles = ({ palette }: Theme) =>
  createStyles({
    appointment: {
      borderRadius: 0,
      borderBottom: 0,
    },
    highPriorityAppointment: {
      borderLeft: `4px solid ${teal[500]}`,
    },
    mediumPriorityAppointment: {
      borderLeft: `4px solid ${blue[500]}`,
    },
    lowPriorityAppointment: {
      borderLeft: `4px solid ${indigo[500]}`,
    },
    weekEndCell: {
      backgroundColor: fade(palette.action.disabledBackground, 0.04),
      "&:hover": {
        backgroundColor: fade(palette.action.disabledBackground, 0.04),
      },
      "&:focus": {
        backgroundColor: fade(palette.action.disabledBackground, 0.04),
      },
    },
    weekEndDayScaleCell: {
      backgroundColor: fade(palette.action.disabledBackground, 0.06),
    },
    text: {
      overflow: "hidden",
      textOverflow: "ellipsis",
      whiteSpace: "nowrap",
    },
    content: {
      opacity: 0.7,
    },
    container: {
      width: "100%",
      lineHeight: 1.2,
      height: "100%",
    },
  });

type AppointmentContentProps = Appointments.AppointmentContentProps & WithStyles<typeof styles>;

const getAppointmentWithValidRRule = (appointment: any) => {
  const { rRule } = appointment;
  if (!rRule) {
    return appointment;
  }
  const rruleOptions = RRule.parseString(rRule);
  if (!rruleOptions.bymonthday || !rruleOptions.byweekday) {
    return appointment;
  }

  const firstMonthDay = rruleOptions.bymonthday[0];
  let weekNumber = Math.ceil(firstMonthDay / 7);
  if (firstMonthDay < 0) {
    weekNumber = -1;
  }

  const nextByWeekDay = rruleOptions.byweekday[0];
  nextByWeekDay.n = weekNumber;

  const validRRuleOptions = {
    ...rruleOptions,
    bymonthday: undefined,
    byweekday: nextByWeekDay,
  };

  const nextRule = new RRule(validRRuleOptions);
  const validRRule = nextRule.toString();

  return {
    ...appointment,
    rRule: validRRule,
  };
};

export default function MeetingCalender() {
  const { data: meetingsData, status: meetingsStatus } = useAppSelector((state) => state.calendar);
  const { data: rooms, status: roomsStatus } = useAppSelector((state) => state.rooms);

  const { currentUser } = useAppSelector((state) => state.auth);
  const dispatch = useAppDispatch();

  const [isOwner, setIsOwner] = useState(false);
  const [roomId, setRoomId] = useState("");
  const [useList, setUseList] = useState(false);
  const [modalState, setModalState] = useState(false);
  const [modalStateEdit, setModalStateEdit] = useState(false);
  const [changedFields, setChangedFields] = useState();
  const [docId, setDocId] = useState("");
  const [appointmentId, setAppointmentId] = useState("");
  const [appointment, setAppointment] = useState<Calendar>({} as Calendar);

  const [modalStateMOM, setModalStateMOM] = useState(false);
  const [rowDoc, setRowDoc] = useState({} as any);
  const [MOM, setMOM] = useState("");
  const [visible, setVisible] = useState(false);
  const [loading, setLoading] = useState(false);

  const uid = currentUser.id as string;

  React.useEffect(() => {
    dispatch(fetchAllRooms());
    dispatch(
      fetchCalendarEventsInRange({
        gt: moment().subtract(3, "months").endOf("day").format("YYYY-MM-DD"),
        ls: moment().add(3, "months").endOf("day").format("YYYY-MM-DD"),
      })
    );

    const unsubscribe = subscribeToCalendarEvents(dispatch);
    return () => {
      unsubscribe();
    };
  }, [dispatch]);

  const handleFileChange = async (e: React.ChangeEvent<HTMLInputElement>) => {
    if (e.target.files) {
      const file: File = e.target.files[0];
      if (!file) return;
      const filePath = `meetings/${uid}/${file.name}`;
      try {
        const url = await uploadFile(file, filePath);

        if (url) {
          message.success("File uploaded successfully");
          return { url, fileName: file.name };
        }
      } catch (error: any) {
        message.error(error.message);
      }
    }
  };

  const BasicLayout = ({ onFieldChange, appointmentData, ...restProps }: any) => {
    const agendaObj = appointmentData.agendaObj;
    const agenda = appointmentData.agenda;
    const uid = currentUser.id as string;
    const [uploadingAttach, setUploadingAttach] = useState(false);
    const { t } = useTranslation();

    const agendaFileNames = appointmentData.agendaFileNames == undefined ? [] : appointmentData.agendaFileNames;
    const agendaAttach = appointmentData.agendaAttach == undefined ? [] : appointmentData.agendaAttach;

    const onAgendaAttachChange = (attachName: string, URL: string) => {
      onFieldChange({ agendaFileNames: [...agendaFileNames, attachName], agendaAttach: [...agendaAttach, URL] });
    };
    const onAgendaAttachDelete = () => {
      onFieldChange({ agendaFileNames: [], agendaAttach: [] });
    };
    const onMembersChange = (event: any, newValue: any) => {
      const uids = newValue.map((option: any) => option.uid);
      onFieldChange({ members: uids });
    };

    const AGENDA_TO_EDITOR = (agenda: string, agendaObj: OutputData) => {
      if (agendaObj == undefined) {
        if (agenda == undefined) {
          const emptyAgenda: OutputData = {
            blocks: [],
          };
          return emptyAgenda;
        } else {
          const newAgenda: OutputData = {
            blocks: [
              {
                type: "paragraph",
                data: {
                  text: agenda,
                },
              },
            ],
          };
          return newAgenda;
        }
      } else {
        return agendaObj;
      }
    };

    const onChangeEditor = async (instance: API) => {
      const outputData: OutputData = await instance?.saver?.save();
      onFieldChange({ agenda: dataToHTML(outputData), agendaObj: outputData });
    };
    return (
      <>
        <AppointmentForm.BasicLayout appointmentData={appointmentData} onFieldChange={onFieldChange} {...restProps}>
          <AppointmentForm.Label text="Members" type="title" />

          <Autocomplete
            multiple
            value={USERS_ID_FULL_NAME.filter((option: any) => appointmentData?.members?.includes(option.uid as string))}
            onChange={onMembersChange}
            options={USERS_ID_FULL_NAME}
            getOptionLabel={(option) => option.fullName}
            renderInput={(params) => <TextField {...params} variant="outlined" />}
            renderOption={(props) => {
              return <li {...props}>{props.fullName}</li>;
            }}
          />
          <AppointmentForm.Label text="Agenda" type="title" />

          <Editor
            holder="Agenda"
            data={AGENDA_TO_EDITOR(agenda, agendaObj)}
            setInstance={onChangeEditor}
            minHeight={200}
          />

          {appointmentData.id == undefined || appointmentData.uid == uid ? (
            <div>
              <label
                style={{
                  background: "rgb(100 181 246)",
                  padding: "1%",
                  marginRight: "1%",
                  borderColor: "rgb(100 181 246)",
                  borderRadius: "6px",
                  color: "white",
                }}
              >
                <Icon type="upload" />
                <span style={{ padding: "2%" }}>{t("general.uploadAttach")}</span>
                {uploadingAttach ? <Spin indicator={antIcon} style={{ color: "#fff" }} /> : null}

                <input
                  hidden
                  type="file"
                  onChange={async (e) => {
                    const result = await handleFileChange(e);
                    if (result) {
                      onAgendaAttachChange(result?.fileName, result?.url);
                      setUploadingAttach(false);
                    }
                  }}
                />
              </label>
              <label
                style={{
                  background: "#fff",
                  padding: "1%",
                  marginRight: "1%",
                  border: "solid thin",
                  borderColor: "rgb(100 181 246)",
                  borderRadius: "6px",
                  color: "rgb(100 181 246)",
                }}
              >
                {`Uploaded File Number: ${agendaFileNames.length}`}
              </label>
              <Button onClick={() => onAgendaAttachDelete()} type="danger">
                Delete Attach
              </Button>
            </div>
          ) : null}
        </AppointmentForm.BasicLayout>
      </>
    );
  };

  const locations: any = rooms.map((d, index) => {
    return { text: d.roomName, id: d.id, color: colorsRoom[index] };
  });

  const resources = [
    {
      fieldName: "roomId",
      title: "Location",
      instances: locations,
      isMain: true,
    },
  ];
  const SnapDocs = useList ? meetingsData.filter((meeting) => meeting.roomId == roomId) : meetingsData;
  const data: any = SnapDocs.map((d) => {
    return {
      MOM: d?.MOM,
      MOMObj: d?.MOMObj,
      id: d.id,
      allDay: d.allDay,
      startDate: d.startDate,
      endDate: d.endDate,
      startDateNotFormatted: d.startDate,
      endDateNotFormatted: d.endDate,
      members: d.members,
      agenda: d.agenda,
      agendaObj: d.agendaObj,
      roomId: d.roomId,
      title: d.title,
      uid: d.uid,
      isOriginal: d.isOriginal,
      agendaAttach: d.agendaAttach,
      agendaFileNames: d.agendaFileNames,
      originalId: d.originalId,
    };
  });

  const commitChanges = async ({ added, changed, deleted }: ChangeSet) => {
    if (added) {
      const newAdded = getAppointmentWithValidRRule(added);

      const title = newAdded.title == undefined ? "" : newAdded.title;
      const rRule = newAdded.rRule == undefined ? "" : (newAdded.rRule as string);

      const options = RRule.parseString(rRule);
      options.dtstart = new Date(newAdded.startDate);
      const rule = new RRule(options);
      const exDate = newAdded.exDate == undefined ? "" : newAdded.exDate;
      const members = newAdded.members == undefined ? [uid] : [uid, ...newAdded.members];
      const allDay = newAdded.members == undefined ? false : newAdded.allDay;
      const agenda = newAdded.agenda == undefined ? "" : newAdded.agenda;
      const agendaObj = newAdded.agendaObj == undefined ? {} : newAdded.agendaObj;

      const MOM = newAdded.MOM == undefined ? "" : newAdded.MOM;
      const MOMObj = newAdded.MOMObj == undefined ? {} : newAdded.MOMObj;

      const agendaAttach = newAdded.agendaAttach == undefined ? [] : newAdded.agendaAttach;
      const agendaFileNames = newAdded.agendaFileNames == undefined ? [] : newAdded.agendaFileNames;

      const roomId = newAdded.roomId == undefined ? "" : newAdded.roomId;
      const isOnlyOneAppointment = newAdded.rRule == undefined ? true : false;
      const ruleDatesArray =
        newAdded.rRule == undefined
          ? [{ startDate: moment(newAdded.startDate), endDate: moment(newAdded.endDate) }]
          : rule
              .all()
              .slice(0, rule.all().length < 50 ? rule.all().length : 50)
              .map((d) => {
                const day = d.getDate();
                const month = d.getMonth();
                const year = d.getFullYear();
                const startDate: Date = new Date(newAdded.startDate);
                const endDate: Date = new Date(newAdded.endDate);
                startDate.setDate(day);
                startDate.setMonth(month);
                startDate.setFullYear(year);
                endDate.setDate(day);
                endDate.setMonth(month);
                endDate.setFullYear(year);
                return { startDate, endDate };
              });
      const lastDateQuery = moment(ruleDatesArray[ruleDatesArray.length - 1].endDate) as any;
      const yesterday = moment(newAdded.startDate).subtract(48, "hours") as any;

      await addOrUpdateCalendarEvents(
        yesterday,
        lastDateQuery,
        roomId,
        ruleDatesArray,
        title,
        rRule,
        members,
        allDay,
        agenda,
        agendaObj,
        MOM,
        MOMObj,
        uid,
        exDate,
        agendaAttach,
        agendaFileNames,
        isOnlyOneAppointment,
        setLoading
      ).then((result) => {
        console.log({ result });
        if (result.added == true && result.docId) {
          notificationAdd(members, result.docId);
        }
      });

      setLoading(false);
    }

    if (changed) {
      map(changed, async (changedFields, docId) => {
        const newChanged = getAppointmentWithValidRRule(changedFields);
        setDocId(docId);
        setChangedFields(newChanged);
        setModalStateEdit(true);
      });
    }

    if (deleted) {
      setModalState(true);
      await setAppointmentId(deleted.toString());
      const deletedAppointment = meetingsData.find((meeting) => meeting.id == deleted.toString());
      setAppointment(deletedAppointment as any);
    }
  };
  const checkOwnerForm = (data: any) => {
    const uidData = data.uid;
    if (uidData !== uid && uidData !== undefined) {
      setIsOwner(true);
    }
  };

  const AppointmentContent = withStyles(styles, { name: "AppointmentContent" })(
    ({ classes, data, ...restProps }: AppointmentContentProps) => {
      const kind = data.isOriginal == true ? <Icon type={"check-circle"} theme="twoTone" /> : <Icon type={"pushpin"} />;
      return (
        <Appointments.AppointmentContent {...restProps} data={data}>
          <div className={classes.container}>
            <div className={classes.text}>
              <b>{data.title}</b>
              <b style={{ float: "right" }}>{kind}</b>
            </div>
            <div className={classes.text}>{globalUsersData[data.uid ?? ""]?.fullName}</div>
            <div className={classes.text}>{`${rooms.find((d) => d.id == data.roomId)?.roomName}`}</div>
          </div>
        </Appointments.AppointmentContent>
      );
    }
  );

  const CustomTooltipLayout = ({ children, appointmentData, ...restProps }: any) => {
    return appointmentData?.members?.includes(uid) ? (
      <AppointmentTooltip.Header {...restProps}>
        {children}
        <IconButton
          onClick={() => {
            const newAppointmentDataForm = {
              ...appointmentData,
              startDate: appointmentData.startDateNotFormatted,
              endDate: appointmentData.endDateNotFormatted,
            };
            setRowDoc(newAppointmentDataForm);
            setMOM(newAppointmentDataForm.MOM);
            setModalStateMOM(true);
          }}
        >
          <MomIcon />
        </IconButton>
      </AppointmentTooltip.Header>
    ) : null;
  };

  const notificationAdd = (members: any[], docId: string) => {
    members.forEach((memberUid) => {
      dispatch(
        addNotification({
          notifyTo: memberUid,
          notifyFrom: uid,
          orderId: docId,
          read: false,
          kind: "calendarInvitation",
          content: "You have been invited to attend Meeting",
          postedAt: moment() as any,
        })
      );
    });
  };

  
  return (
    <div className={"calendar"}>
      {modalState ? (
        <DeleteDialog
          modalState={modalState}
          setModalState={setModalState}
          docId={appointmentId}
          appointment={appointment as any}
        />
      ) : null}
      {modalStateMOM ? (
        <AddMOM modalStateMOM={modalStateMOM} setModalStateMOM={setModalStateMOM} doc={rowDoc} MOM={MOM} />
      ) : null}

      {modalStateEdit ? (
        <EditDialog
          modalState={modalStateEdit}
          setModalState={setModalStateEdit}
          changedFields={changedFields}
          docId={docId}
        />
      ) : null}

      <Select
        style={{ width: "20%", paddingBottom: "1%" }}
        value={roomId == "" ? "Select Room" : rooms.find((d) => d.id == roomId)?.roomName}
        onChange={(e) => {
          setRoomId(e);
          setUseList(true);
        }}
      >
        {rooms.map((d, index) => {
          return (
            <Option
              style={{
                backgroundColor: colorsRoom[index],
                color: "white",
              }}
              value={d.id}
            >
              <b>{d.roomName}</b>
            </Option>
          );
        })}
      </Select>
      <Button
        style={{ marginLeft: "0.5%" }}
        type="primary"
        onClick={() => {
          setUseList(false);
          setRoomId("");
        }}
      >
        Show All
      </Button>
      <Spin
        spinning={meetingsStatus == "loading" || roomsStatus == "loading"}
        indicator={<Icon type="loading" style={{ fontSize: 100 }} spin />}
      >
        <Paper>
          <Scheduler data={data}>
            <ViewState defaultCurrentViewName="Week" />
            <EditingState onCommitChanges={commitChanges} />
            <IntegratedEditing />
            <DayView startDayHour={9} endDayHour={24} />
            <DayView displayName={"Three days"} name="ThreeDays" startDayHour={9} endDayHour={24} intervalCount={3} />
            <WeekView name="work-week" displayName="Work Week" excludedDays={[0, 6]} startDayHour={9} endDayHour={24} />
            <WeekView startDayHour={9} endDayHour={24} />
            <MonthView />
            <EditRecurrenceMenu />
            <Appointments appointmentContentComponent={AppointmentContent} />
            <Toolbar />
            <Resources data={resources} mainResourceName="roomId" />
            <AllDayPanel />
            <AppointmentTooltip
              showCloseButton
              showDeleteButton={false}
              showOpenButton
              headerComponent={CustomTooltipLayout}
              onVisibilityChange={(e) => setVisible(e)}
              visible={visible}
            />
            <AppointmentForm
              messages={messages}
              basicLayoutComponent={BasicLayout}
              textEditorComponent={TextEditor}
              onAppointmentDataChange={checkOwnerForm}
              onVisibilityChange={(v) => {
                if (v == false) {
                  setIsOwner(false);
                }
              }}
              readOnly={admins.includes(uid) ? false : isOwner}
            />

            <DateNavigator />
            <TodayButton />
            <ViewSwitcher />
            <CurrentTimeIndicator shadePreviousCells shadePreviousAppointments />
          </Scheduler>
        </Paper>
      </Spin>
    </div>
  );
}
