import React, { useState, useEffect } from "react";
import { Button, Drawer, Form, message, Select, Input } from "antd";
import moment from "moment";
const { Option } = Select;
import { useFormik } from "formik";
import * as Yup from "yup";
import { useTranslation } from "react-i18next";
import { useAppDispatch, useAppSelector } from "../../hooks/reduxHooks";
import { addBudgetLine } from "../../features/budgetLines/budgetLinesSlice";
import { globalProjectsData } from "../../global/projectList";

interface Props {
  modalState: boolean;
  setModalState: React.Dispatch<React.SetStateAction<boolean>>;
}
const projectSchema = Yup.object().shape({
  budgetLine: Yup.string().required("*"),
});

function AddNewBudgetLine(props: Props) {
  const dispatch = useAppDispatch();
  const { currentUser } = useAppSelector((state) => state.auth);
  const { t } = useTranslation();
  const { modalState, setModalState } = props;
  const [loading, setLoading] = useState(false);

  const projectsMap = Object.values(globalProjectsData).map((d) => {
    return {
      id: d.id,
      code: d.projectCode,
    };
  });
  const formik = useFormik({
    initialValues: {
      budgetLine: "",
      projectId: "",
    },
    validationSchema: projectSchema,
    onSubmit: async (values) => {
      try {
        await dispatch(
          addBudgetLine({
            budgetLine: values.budgetLine,
            projectId: values.projectId,
            by: currentUser.id as string,
            createdAt: moment() as any,
          })
        ).unwrap();
        message.success("Budget Line added successfully");
        setModalState(false);
        setLoading(false);
      } catch (error) {
        setLoading(false);
        console.error("Error writing document: ", error);
        message.error("Failed");
      }
    },
  });
  useEffect(() => {
    return () => {
      if (formik.isValid == false) {
        setLoading(false);
      }
    };
  }, [formik.isValid]);

  const enterLoading = () => {
    setLoading(true);
  };

  return (
    <Drawer
      title={t("general.addNewBudgetLine")}
      width={500}
      height={350}
      visible={modalState}
      placement={"right"}
      onClose={() => setModalState(false)}
    >
      <Form onSubmit={formik.handleSubmit}>
        <Form.Item label={t("general.budgetLine")}>
          <Input
            value={formik.values.budgetLine}
            onChange={(e) => formik.setFieldValue("budgetLine", e.target.value)}
          />
        </Form.Item>
        <Form.Item label={t("general.projectCode")}>
          <Select
            showSearch
            filterOption={(inputValue, option) => {
              return (option.props.children as any).toLowerCase().indexOf(inputValue.toLowerCase()) >= 0;
            }}
            value={formik.values.projectId}
            onChange={(e) => formik.setFieldValue("projectId", e)}
          >
            {projectsMap?.map((d) => {
              return <Option value={d.id}>{d.code}</Option>;
            })}
          </Select>
        </Form.Item>

        <div>
          <Button
            className="btn blue lighten-1 z-depth-0"
            onClick={() => {
              enterLoading();
            }}
            loading={loading}
            type="primary"
            htmlType="submit"
            disabled={!formik.isValid}
          >
            {t("general.create")}
          </Button>
        </div>
      </Form>
    </Drawer>
  );
}

export default AddNewBudgetLine;
